/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout, Main } from '../AdminTeamCreation/styles';
import AboutChallengePopup from './AboutChallengePopup';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { fethcChallengeByChallengeType, getActivityChallengeById, deleteChallengeLibrary } from '../../redux/actions';
import _ from 'lodash';
import Waiting from '../Waiting';
import { ChallengeHeading } from "../CreateEvent/style";
import { ToolTip } from "../AdminTeamCreation/styles";
import HoverButton from '../common/HoverButtonV2';
import { BackIcon, CreateChallenge } from '../../utils/icons';
import {CategoryWrapper, CategoryCard} from './styles';
import Thumbnail from './../Thumbnail';
import { ImageUrl } from "../../utils/constants";
import { DeleteIcon, EditImage } from "../../utils/icons";
import { /*OverlayTrigger,*/ Popover } from "react-bootstrap";
import DeletePopup from '../ChallengeDetailsV2/challengeLeavePopup';
import parse from 'react-html-parser';


class ChallengesChallengeLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAboutPopup:false,
      onHover: -1,
      showDeletePopup:false,
      deleteId:null,
      deleteType:null
    }
  }

  componentDidMount() {
    const { fethcChallengeByChallengeType, history } = this.props;
    const StringArray = history.location.pathname.split('/');
    if (StringArray[(StringArray.length - 1)] != '') {
      fethcChallengeByChallengeType(StringArray[(StringArray.length - 1)]);
    } else {
      fethcChallengeByChallengeType(StringArray[(StringArray.length - 2)]);
    }
  }

  componentDidUpdate(prevProps) {
    const { history, fethcChallengeByChallengeType } = this.props;
    const StringArray = history.location.pathname.split('/');
    if (history.location.pathname !== prevProps.history.location.pathname) {
      if (StringArray[(StringArray.length - 1)] != '') {
        fethcChallengeByChallengeType(StringArray[(StringArray.length - 1)]);
      } else {
        fethcChallengeByChallengeType(StringArray[(StringArray.length - 2)]);
      }
    }
  }

  redirection = () => {
    const { history } = this.props;
    history.push('/company/challenges/create-activty')
  };

  showPastEvents = (path) => {
    this.props.history.push(path);
  };

  onEnterMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  showAboutChallengePopup = (id) => {
    this.props.getChallengeDetails(id).then(()=>{this.setState({
      showAboutPopup: true
    })});
  };

  hideAboutChallengePopup = () => {
    this.setState({
      showAboutPopup: false
    })
  };

  showDeletePouup = (id, type) => {
    this.setState({
      showDeletePopup: true,
      deleteId:id,
      deleteType:type
    })
  };

  hideDeletePouup = () => {
    this.setState({
      showDeletePopup: false
    })
  };

  deleteLibraryData = () => {
    this.setState(
      {
        showDeletePopup:false
      },
      () => {
        this.props.deleteChallengeLibrary(this.state.deleteType, this.state.deleteId);
      }
    );
  };

  popover = (text) => (
    <Popover id="popover-basic"
      style={{
        width: "100%",
        padding: "9px 14px",
        background: "#FFFFFF",
        border: "1px solid #002F47",
        boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
        borderRadius: "6px",
        zIndex:"400"
      }}  
    >
      <div  style={{
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#002F47",
        textAlign: "center",
        zIndex:"400"
      }}>{parse(text)}
      </div>
    </Popover> );

  renderHeading = (name) => (
    <ChallengeHeading>
      <ToolTip
        margin={"auto 15px auto 0"}
        activeValue={"transparent"}
        height="40px"
        width="55px"
      >
        <HoverButton
          title={this.props.t("Back")}
          width="24px"
          height="24px"
          svgPath={BackIcon()}
          onClick={this.props.history.location.pathname.includes("/company/challenges/categories")?() => this.props.history.push('/company/challenges/categories'):() => this.props.history.push('/challenges/categories')}
          backgroundColor={"#005C870D"}
          backgroundColorActive={"#005C87"}
        />
      </ToolTip>
      {name} {this.props.t("Challenges You can Launch")} 
      {this.props.role=="ADMIN"&&<ToolTip
        margin={"auto 15px auto auto"}
        activeValue={"transparent"}
        height="40px"
        width="55px"
      >
        <HoverButton
          title={this.props.t("Create Challenge")}
          width="24px"
          height="24px"
          svgPath={<CreateChallenge/>}
          onClick={this.props.history.location.pathname.includes("/company/challenges/categories")?() => this.props.history.push('/company/challenges/create-activty'):() => this.props.history.push('/challenges/create-activty') }
          backgroundColor={"#005C870D"}
          backgroundColorActive={"#005C87"}
        />
      </ToolTip>}
    </ChallengeHeading>
  );

  render() {
    const { challengeByType, challengeDetails, userName, history, role } = this.props;
    const { showAboutPopup, onHover, showDeletePopup, deleteId} = this.state;
    if (_.isNull(challengeByType)|| _.isUndefined(challengeByType)) {
      return <Waiting />
    }
    const StringArray = history.location.pathname.split('/');
    const challengeName = StringArray[(StringArray.length - 1)]=="new_group"?"Group":StringArray[(StringArray.length - 1)]=="group"?"Classic":StringArray[(StringArray.length - 1)]=="survivor"?"Survivor":"Team";
    return (
      <Layout>
        <Main>
          {this.renderHeading(challengeName)}
          <CategoryWrapper>
            {challengeByType && challengeByType.length ? challengeByType.map((category, index) => (
              <CategoryCard key={index} background={"white"} margin={(index+1)%3===0?"0 0px 25px 0":"0 25px 25px 0"}>
                <div className="image" onMouseEnter={() => this.onEnterMouse(category.id)} onMouseLeave={() => this.onLeaveMouse()}>
                  <Thumbnail
                    src={ImageUrl +"/"+category.image}
                    alt={"recommended card"}
                    height={"250px"}
                  />
                  {onHover === category.id &&
                    <div className='imageBackground'>
                      <div className="viewMore" onClick={history.location.pathname.includes("/company/challenges/")?() => history.push(`/company/challenges/activity-launch/${category.id}`):() => history.push(`/challenges/activity-launch/${category.id}`)} /*onClick={() => this.showAboutChallengePopup(category.id)}*/ >{this.props.t("Launch a Challenge")}</div>
                    </div>
                  }
                </div>
                <div className='wrapperMain'>
                  <div className="categoryNameWrapper">
                    <div className="categoryName">{this.props.t(category.title)}</div>
                    <div className="tooltipIcon">
                      <div /*overlay={this.popover(category.body)}*/ onClick={() => this.showAboutChallengePopup(category.id)} style={{cursor:"pointer"}}>
                        <img src={ImageUrl+"/ChallengeDetailsScreen/toolTipIcon.png"} width="22px" height="22px"/>
                      </div>
                    </div>
                    <div className='activityIcon'>
                      {category.event_type=="Total Calories"?<img src={ImageUrl+'/'+"ChallengeDetailsScreen/Calories.svg"} />:category.event_type=="Total Steps"?<img src={ImageUrl+'/'+"ChallengeDetailsScreen/Steps.svg"} />:<img src={ImageUrl+'/'+"ChallengeDetailsScreen/Distance.svg"} />}
                    </div>
                    {/* <div className="challengeCount">{category.count}</div> */}
                  </div>
                  {role=="ADMIN"&&<div className='adminCards'>
                    <HoverButton
                      title={this.props.t("Edit Challenge")}
                      width="22px"
                      height="22px"
                      svgPath={<EditImage/>} 
                      onClick={history.location.pathname.includes("/company/challenges/categories/")?() => history.push(`/company/challenges/activity-edit/${category.id}`):() => history.push(`/challenges/activity-edit/${category.id}`)}
                      backgroundColor={"#005C870D"}
                      backgroundColorActive={"#005C87"}
                    />
                    <HoverButton
                      title={this.props.t("Delete Challenge")}
                      width="20px"
                      height="24px"
                      svgPath={<DeleteIcon/>} 
                      onClick={() => this.showDeletePouup(category.id,category.challenge_type)}
                      backgroundColor={"#005C870D"}
                      backgroundColorActive={"#005C87"}
                    />
                  </div>}
                </div>
                <div>

                </div>
              </CategoryCard>)): null}
          </CategoryWrapper>
          {showAboutPopup &&<AboutChallengePopup show={showAboutPopup} challengeData={challengeDetails} userName={userName} hideAboutChallengePopup={this.hideAboutChallengePopup} history={history}/>}
          {showDeletePopup&& 
          <DeletePopup
            deleteId={deleteId}
            showConfirmPopupDelete={showDeletePopup}
            showHideDeletePopup={this.hideDeletePouup}
            deleteData={this.deleteLibraryData}
            popupTitle={this.props.t("Delete Challenge")}
            popupHeading={this.props.t("Are you sure you want to delete this Challenge?")}
            showDeleteLibrary={true}
          />}
        </Main>
      </Layout>
    );
  }
}

ChallengesChallengeLibrary.propTypes = {
  history: PropTypes.object,
  role: PropTypes.string,
  fethcChallengeByChallengeType: PropTypes.func,
  challengeByType: PropTypes.array,
  location: PropTypes.object.isRequired,
  getChallengeDetails: PropTypes.func,
  challengeDetails: PropTypes.array,
  userName: PropTypes.string,
  deleteChallengeLibrary: PropTypes.func,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  challengeByType: state.challenges.challengeByType,
  challengeDetails: state.challenges.activityDetailsById,
  userName: state.profileData.firstName
})

const mapDispatchToProps = (dispatch) => ({
  fethcChallengeByChallengeType: (type) => dispatch(fethcChallengeByChallengeType(type)),
  getChallengeDetails: (id) => dispatch(getActivityChallengeById(id)),
  deleteChallengeLibrary: (type, id) => dispatch(deleteChallengeLibrary(type, id))
})  

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChallengesChallengeLibrary));
