import React, { useEffect, useState } from "react";
import { CoreValuCard, CoreValuContainer } from "./styles";
import { Border } from "../../EventDetails/styles";
import { fetchApi } from "../../../utils/methods";
import { GetCompanyExemplifies } from "../../../redux/constants/apiConstants";
import LazyImage from "../../common/LazyImage/LazyImage";
import { ImageUrl } from "../../../utils/constants";
import {
  AdminDeleteIcon,
  RecognitionDeleteIcon,
  RecognitionRestoreIcon,
  RecognitionTickIcon,
  RecognitionWarningIcon,
  RestoreIcon,
} from "../../../utils/icons";
import CommonModal from "../../common/CommonModal/CommonModal";
import { ManagerIconContainer } from "../../Manager/styles";

export const ArchiveLibraryTab = () => {
  const [coreValues, setCoreValues] = useState([]);
  const [isArchive, setIsArchive] = useState(false);
  const [selectedCoreValues, setSelectedCoreValues] = useState([]);
  const [restoreModal, setRestoreModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    GetCompanyCoreValues(1);
  }, []);

  const GetCompanyCoreValues = async (companyId) => {
    const res = await fetchApi(GetCompanyExemplifies + "/" + companyId, "GET");

    if (res.status === "success") {
      setCoreValues(res.data);
    }
  };

  const SelectCoreValue = (id) => {
    const allCoreValues = [...selectedCoreValues];
    if (allCoreValues.includes(id)) {
      let index = allCoreValues.findIndex((item) => item === id);
      if (index > -1) {
        allCoreValues.splice(index, 1);
      }
    } else {
      allCoreValues.push(id);
    }

    setSelectedCoreValues(allCoreValues);
  };

  
  const handleRestoreCancel = () => {
    setRestoreModal(false);
  };

  const handleRestoreConfirm = () => {
    setRestoreModal(false);
  };

  
  const handleDeleteCancel = () => {
    setDeleteModal(false);
  };

  const handleDeleteConfirm = () => {
    setDeleteModal(false);
  };



  return (
    <>
      <CoreValuContainer>
        <div className="top-bar-container">
          <div className="header">Core Values</div>
          {isArchive ? (
            <div className="archive-active">
              <div onClick={()=>setRestoreModal(true)}> {RecognitionRestoreIcon()}</div>
              <div
                onClick={()=>setDeleteModal(true)}
                style={{ margin: "0px 16px" }}
              >
                {RecognitionDeleteIcon()}
              </div>
              <div
                className="cancel-archive"
                onClick={() => setIsArchive(false)}
              >
                Cancel
              </div>
            </div>
          ) : (
            <div className="archive" onClick={() => setIsArchive(true)}>
              Restore/Delete
            </div>
          )}
        </div>
        <Border style={{ border: "1px solid #EFEFEF", margin: "20px 0px" }} />

        <div className="core-values">
          {coreValues.map((coreValue, index) => (
            <>
              <div className="border-btm">
                <CoreValuCard
                  key={index}
                  selected={
                    selectedCoreValues.includes(coreValue.id) ? true : false
                  }
                >
                  <div className="core-value-row">
                    <div className="col-one">
                      <div
                        className="icon"
                        style={{ background: coreValue?.color }}
                      >
                        <LazyImage
                          style={{ width: "24px" }}
                          src={ImageUrl + "/" + coreValue?.white_icon}
                        />
                      </div>
                      <div className="title">{coreValue?.core_value}</div>
                    </div>
                    <div className="action">
                      <ManagerIconContainer>
                        {isArchive ? (
                          <div
                            className="archive-action"
                            onClick={() => SelectCoreValue(coreValue.id)}
                          >
                            {selectedCoreValues.includes(coreValue.id)
                              ? RecognitionTickIcon()
                              : null}
                          </div>
                        ) : null}
                      </ManagerIconContainer>
                      {/* {isArchive ? <div className="archive-action"></div> : <div className="eye-icon">{RecognitionLaunchIcon("#F37A65")}</div>} */}
                      {/* <div className="pencil-icon">{RecognitionPencilIcon()}</div> */}
                    </div>
                  </div>
                </CoreValuCard>
              </div>
            </>
          ))}
        </div>
        { restoreModal && <CommonModal
          show={restoreModal}
          icon={RecognitionWarningIcon("#B1AA56")}
          title="Restore Core Values"
          description="Once restored, they will appear in the Inactive tab. Would you like to restore these core values now? "
          onCancel={handleRestoreCancel}
          onConfirm={handleRestoreConfirm}
          cancelText="Cancel"
          confirmButtonText="Restore Now"
          buttonIcon={RestoreIcon()}
          modalHeight="204px"
          confirmButtonWidth="148px" 
        />
        }
        { deleteModal && <CommonModal
          show={deleteModal}
          icon={RecognitionWarningIcon("#C13D4D")}
          title="Delete Core Values"
          description="Are you sure you want to delete these core value?"
          subDescription="This action cannot be undone, and the core value will be permanently removed from the system."
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          cancelText="Cancel"
          confirmButtonText="Delete Permanently"
          buttonIcon={AdminDeleteIcon()}
          confirmButtonWidth="198px" 
          modalHeight="244px"
        />
        }


      </CoreValuContainer>
    </>
  );
};
