import React, { Component } from "react";
import PropTypes from "prop-types";
import { FitnessCard, ChampionsCardVideo } from "../AdventureCategories/styles";
import { VideoPlayer, ButtonLearning } from "../Content/DailyDashboard/styles";
import Thumbnail from "../Thumbnail";
import { ImageUrlNew } from "../../utils/constants";
import { CircleContainer, Circle, Tick } from "../Journeys/styles";
import { withTranslation } from "react-i18next";

class RecommendedVideos extends Component {
  constructor() {
    super();
    this.state = {
      onHover: -1,
      save: 0,
    };
  }

  onEnterMouse = (id) => {
    this.setState({
      onHover: id,
    });
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1,
    });
  };

  saveImage = () => {
    this.setState({
      save: !this.state.save,
    });
  };

  render() {
    const {
      video,
      showDescription,
      history,
      grid,
      margin,
      bottom,
      width,
      onClickFavoriteRibbon,
      t,
    } = this.props;
    const { onHover } = this.state;
    return (
      <ChampionsCardVideo
        grid={grid}
        margin={margin}
        bottom={bottom}
        width={width}
      >
        { video?.favorite_status ? (
          <img
            src="/public/images/NewDashboardV2/ribbonColor.png"
            onClick={() => {
              onClickFavoriteRibbon(
                "fitness",
                video.id,
                0,
                video?.favorite_status ? 0 : 1,
                0
              );
              this.saveImage();
            }}
          />
        ) : (
          <img
            src="/public/images/NewDashboardV2/ribbon.png"
            onClick={() => {
              onClickFavoriteRibbon(
                "fitness",
                video.id,
                0,
                video?.favorite_status ? 0 : 1,
                0
              );
              this.saveImage();
            }}
          />
        )}
        <FitnessCard videoCSS={grid} height={"250px"}>
          <VideoPlayer>
            <div
              onMouseEnter={() => this.onEnterMouse(video.id)}
              onMouseLeave={() => this.onLeaveMouse()}
            >
              <Thumbnail
                src={`${ImageUrlNew}/${video.thumbnail_image}`}
                alt={video.title}
                onClick={() =>
                  !showDescription &&
                  history.push(`/education/videos-library/${video.id}`)
                }
              />
              {onHover === video.id && (
                <ButtonLearning>
                  <div
                    className="getStarted"
                    onClick={() =>
                      !showDescription &&
                      history.push(`/education/videos-library/${video.id}`)
                    }
                  >
                    {" "}
                    {t("Get Moving")}
                  </div>
                </ButtonLearning>
              )}
            </div>
          </VideoPlayer>
          <div style={{ display: "flex", width: "100%", height: "70px" }}>
            <div className="content" style={{ margin: "21px ​auto" }}>
              <div
                className="workName"
                onClick={() =>
                  !showDescription &&
                  history.push(`/education/videos-library/${video.id}`)
                }
              >
                {this.props.t(video.title)}
              </div>
              {showDescription && (
                <div className="description">{this.props.t(video.description)}</div>
              )}
            </div>
            <CircleContainer style={{ margin: "auto", "margin-right": "15px" }}>
              <div className="flex">
                <Circle borderColor="#9c9c9c" checked={video.view_status}>
                  <Tick checked={video.view_status} />
                </Circle>
              </div>
              <div className="quiz">{t("Watched")}</div>
            </CircleContainer>
          </div>
        </FitnessCard>
      </ChampionsCardVideo>
    );
  }
}

RecommendedVideos.propTypes = {
  video: PropTypes.object,
  showDescription: PropTypes.bool,
  history: PropTypes.object.isRequired,
  grid: PropTypes.number,
  link: PropTypes.string,
  margin: PropTypes.string,
  bottom: PropTypes.string,
  width: PropTypes.string,
  onClickFavoriteRibbon: PropTypes.func,
  t: PropTypes.func,
};

export default (withTranslation()(RecommendedVideos));
