/* eslint-disable no-console,no-undef, camelcase */
import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ImageUrl } from '../../../utils/constants';
import InputGoalV2 from '../../InputGoalsV2';
import { TaskToCompleteSidebar, Header, Arrow, SubContainer, ImageContainer, DataContainer, StyledModal, InitiativesContainer, ButtonContainer, ConfirmButton, CalendarWrapper, CalendarImg, CalendarHead, CalendarPicker, CalendarContainer, TimerButton, CalendarHeader } from './Styles';
import { FetchSurveyQuestions, setSurveyQuestion, postUserInitiatives } from '../../../redux/actions';
import { staticSurvey } from '../../../../mockData';
import { Modal } from "react-bootstrap";
import Calendar from 'react-calendar';
const ConfirmVisitPopup = React.lazy(() => import('./ConfirmVisitPopup'));
const ThankyouPopup = React.lazy(() => import('./ThankyouPopup'));
import moment from "moment";
import { withTranslation } from 'react-i18next';
import LazyImage from '../../common/LazyImage/LazyImage';
import Waiting from '../../Waiting';
import BiometricDetails from '../../BiometricConfirmPopup/BiometricDetails';
import { Border } from '../../Journeys/styles';
import { CongratulationsEmojiIcon, GreatJobIcon, closeIcon } from '../../../utils/icons';
import ConfirmDetailsModal from './ConfirmDetailsModal';
import parse from 'react-html-parser';

class TaskToComplete extends Component {
  constructor() {
    super();
    this.state = {
      modalName: '',
      showModal: '',
      loader: false,
      showPDFModal: false,
      buttonText: '',
      pdfBase64: '',
      showPopup: false,
      currentDate: new Date(),
      showCalendar: false,
      confirmVisit: false,
      showThankyou: false,
      showConfirmVisitModal: false,
      isConfirmDetails: false,
      initiativeData: [],
      biometricPopupStatus: false
    };
  }

  showBiometricSubmitStatusPop = () => {
    this.setState((prevState) => ({biometricPopupStatus: !prevState.biometricPopupStatus}))
  }

  showModal = (name) => {
    this.setState({
      modalName: name,
    });
  };

  hideModal = () => {
    this.setState({
      modalName: ''
    });
  };

  hideInitiativeModal = () => {
    this.setState((prev) => ({
      showPopup: !prev.showPopup,
    }))
  };

  showInitiativeModal = (data) => {
    this.setState((prev) => ({
      showPopup: !prev.showPopup, initiativeData: data
    }))
  };

  showIsConfirmDetails = () => {
    this.setState((prev) => ({
      isConfirmDetails: !prev.isConfirmDetails,
    }))
    this.hideConfirmVisitModal();
  }

  hideIsConfirmDetails = () => {
    this.setState((prev) => ({
      isConfirmDetails: !prev.isConfirmDetails,
    }))
  }

  redirectSurveyAssessment = (id) => {
    const { fetchSurveyQuestions, history } = this.props;
    fetchSurveyQuestions(id, history, this.successFunc)
  };
  successFunc =()=>{
    this.props.history.push({pathname:`/survey`, state:{surveyCategory:"challengeSurvey"}})

  }
  redirectStaticSurvey = () => {
    const { setSurveyQuestion, history } = this.props;
    setSurveyQuestion(staticSurvey, history, 1)
  }

  onChange = (value, event) => {
    event.preventDefault();
    this.setState({
      currentDate: value
    })
  }

  showCalendar = () => {
    this.setState({ showCalendar: true },
      () => { this.hideInitiativeModal() })
  }

  showConfirmPopup = () => {
    this.setState((prev) => ({
      showCalendar: !prev.showCalendar, confirmVisit: !prev.confirmVisit
    }))
  }

  hideCalendar = () => {
    this.setState((prev) => ({
      showCalendar: !prev.showCalendar,
    }))
  }

  hideConfirmPopup = () => {
    this.setState((prev) => ({
      confirmVisit: !prev.confirmVisit,
    }))
  }

  showThankyouPopup = () => {
    const { postUserInitiatives } = this.props;
    const { initiativeData, currentDate } = this.state;
    let initiativeDate = moment(currentDate).format("YYYY-MM-DD");
    const initiativesData = {
      initiative_id: initiativeData.initiative_id,
      date: initiativeDate
    };
    postUserInitiatives(initiativesData);
    this.setState((prev) => ({
      confirmVisit: !prev.confirmVisit, showThankyou: !prev.showThankyou,
    }))
  }

  hideThankyouPopup = () => {

    this.setState((prev) => ({
      showThankyou: !prev.showThankyou,
    }))
  }

  showConfirmVisitModal = () => {
    this.setState((prev) => ({
      showConfirmVisitModal: !prev.showConfirmVisitModal,
    }))
    this.hideInitiativeModal()
  }

  hideConfirmVisitModal = () => {
    this.setState((prev) => ({
      showConfirmVisitModal: !prev.showConfirmVisitModal,
    }))
  }

  userInitiativePopup = () => {
    const { showPopup, initiativeData } = this.state;
    const { t } = this.props;
    return (
      <StyledModal show={showPopup} width={initiativeData.about ? "600px" : "400px"} height={"480"}>
        <div className={'modal-container'}>
          <div onClick={() => this.hideInitiativeModal()} style={{ margin: '14px 0px 0px -14px', cursor: 'pointer' }}>
            {closeIcon()}
            {/* <img src="/public/images/NewDashboardV2/crossIconNew.png" alt="close" /> */}
          </div>
          <div style={{ color: '#005C87', marginTop: '0px', textDecoration: 'none', fontSize: '18px'}}>
          Recommended To Complete
            {/* {initiativeData.name} */}
          </div>
        </div>
        <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>
        <Modal.Body style={{padding:"24px"}}>
          <InitiativesContainer>
            <div className='icon-name'>
              <div className={'initiative-new-icon'}><img src={`${ImageUrl}/${initiativeData.logo}`} alt="logo" /></div>
              <div className='name'>{initiativeData.name}</div>
            </div>

            {initiativeData.about ? <><div className={'ini-name'}>
              {/* {initiativeData.name} */}
              About the initiative
            </div>
            <div className='about'>
              {parse(initiativeData.about)}
            </div>
            </>: null
            }
            {/* <div className={'details'}>
              <div className={'name'}>
                {initiativeData.name}
              </div>
              <div className='about'>
                {initiativeData.about}
              </div>
            </div> */}
            {/* <div className={'details'}>
              {t("by clicking the button below")}.
            </div> */}
            <ButtonContainer>
              <ConfirmButton onClick={/* () => this.showCalendar() */ () => this.showConfirmVisitModal()} margin="30px auto 5px auto" width="352px" style={{ background: "#007AB1" }}>{t("Confirm Details")}</ConfirmButton>
            </ButtonContainer>
          </InitiativesContainer>
        </Modal.Body>
      </StyledModal>)
  }

  logWorkoutCalendar = () => {
    const { currentDate, showCalendar, initiativeData } = this.state;
    const today = new Date();
    // const month = new Date().getMonth();
    // const monthValue = month<3 ?0: (month>2 && month<6)? 3 : (month>5 && month<9) ? 6 :9;
    const disablePrevYear = new Date(new Date().getFullYear(), 0, 1);
    const { t } = this.props;
    return (
      <StyledModal show={showCalendar} width={"480px"} transform="1">
        <CalendarHeader>
          <div>{t("Select Date")}</div>
          <div>
            <div onClick={() => this.hideCalendar()}><img src="/public/images/NewDashboardV2/new_close_icon.png" alt="close" /></div>
          </div>
        </CalendarHeader>
        <CalendarWrapper padding="25px">
          <CalendarImg>
            <img src="/public/images/NewDashboardImages/calendarIcon.png" alt="Calendar Icon" />
          </CalendarImg>
          <CalendarHead>{this.props.t("Select The Day Of Your")} {this.props.t(initiativeData.name)}</CalendarHead>
          <CalendarPicker>
            <CalendarContainer>
              <Calendar
                onChange={(value, event) => this.onChange(value, event)}
                value={currentDate}
                maxDate={today}
                minDate={disablePrevYear}
              />
            </CalendarContainer>
          </CalendarPicker>
          <TimerButton>
            <div onClick={() => this.showConfirmPopup()}>{t("Submit")}</div>
          </TimerButton>
        </CalendarWrapper>
      </StyledModal>
    )
  }

  // After Confirm Details
  confirmDetails = () => {
    const { isConfirmDetails, initiativeData } = this.state;
    const { t } = this.props;
    return (
      <StyledModal show={isConfirmDetails} width={"400px"} height={"380"}>
        <div>
          <div style={{ color: '#005C87', marginTop: '20px', textDecoration: 'none', display: 'flex', justifyContent: 'center' }}>
            {GreatJobIcon()}<span style={{ color: '#005C87', fontFamily: 'Rubik-Medium', fontSize: '18px', lineHeight: '24px', marginLeft: '10px' }}>{t("Great job!")}</span>
          </div>
        </div>
        <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>
        <Modal.Body>
          <InitiativesContainer>
            <div className='text-style'>
              <div style={{ fontFamily: "Rubik", fontSize: '18px', lineHeight: '24px', textAlign: "center", fontWeight: '400', color: '#005C87' }}>
                {t("You completed your")}&nbsp;
                <div>
                  {initiativeData.name}
                </div>
              </div>
            </div>
            <div className={'initiative-icon'} style={{ marginTop: '25px' }}>
              <img src={`${ImageUrl}/${initiativeData.logo}`} alt="logo" />
            </div>
            
            <div className='text-style' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ fontFamily: "Rubik-Medium", fontSize: '18px', lineHeight: '24px', color: '#005C87' }}><span style={{ marginRight: '5px' }}>{t("Congratulations!")}</span> {CongratulationsEmojiIcon()}&nbsp;</div>
              <div style={{ fontFamily: "Rubik", fontSize: '18px', lineHeight: '24px', textAlign: "center", fontWeight: '400', color: '#005C87' }}>
                {initiativeData.points === 0 ? `You have done a great job` : `You earned +${initiativeData.points} Points`}
              </div>
            </div>
            <ButtonContainer>
              <ConfirmButton onClick={() => this.hideIsConfirmDetails()} margin="30px auto 5px auto" width="352px" style={{ background: "#007AB1" }}>{t("Thank You!")}</ConfirmButton>
            </ButtonContainer>
          </InitiativesContainer>
        </Modal.Body>
      </StyledModal>)
  }

  render() {
    const { modalName, showPopup, showCalendar, confirmVisit, showThankyou, currentDate, initiativeData, showConfirmVisitModal, isConfirmDetails } = this.state;
    const { user, month, SetDailyGoal, inputGoals, history, listOfThingToComplate, padding, userInitiatives, name, t, showBiometric } = this.props;
    const { sync_fitness_device: fitnessDevice, monthly_goals: monthlyGoals, first_wba_completed: hra, upload_physician_form: physicianForm, survey } = listOfThingToComplate;
    let dateData = moment(currentDate).format('MMM DD, YYYY');
    return (
      listOfThingToComplate && userInitiatives && userInitiatives.length ?
        <TaskToCompleteSidebar conneceFitnessDevice>
          {fitnessDevice || monthlyGoals || hra || physicianForm || survey || survey?.length || userInitiatives?.length>0?
            <Header paddingTop={padding === 1 ? "0px" : "0px"}>
              <div className="text">
                <span>{t('Recommended to complete')}</span>
              </div>
            </Header> : null
          }
          {userInitiatives && userInitiatives.length && userInitiatives.map((userInitiative, index) =>{
            const shouldRender =
    (userInitiative.type === "BIOMETRIC" && showBiometric) || 
    userInitiative.type !== "BIOMETRIC";

            if (!shouldRender) return null;

            return (<SubContainer id={userInitiative.name} cursor={(userInitiative.is_taken && userInitiative.type != "BIOMETRIC" ) || userInitiative.type == "EDUCATION" || userInitiative.type == "SOCIAL"?"":"pointer"}   onClick={userInitiative.type === "SURVEY" && userInitiative.is_taken===0 ? () =>this.redirectSurveyAssessment(userInitiative.survey_id) : userInitiative.type === "HRA" && userInitiative.is_taken===0? () => history.push('/wellness-assessment') : userInitiative.type === "SYNC_DEVICE" && userInitiative.is_taken===0? this.props.displayDeviceModal : userInitiative.type === "BIOMETRIC" && userInitiative.is_taken===0 ? () => history.push(`/profile/biometric/${user.uid}/multi-step/physician-form`): userInitiative.type === "BIOMETRIC" && userInitiative.is_taken===1 ? () => this.showBiometricSubmitStatusPop() :userInitiative.type === "GOALS" && userInitiative.is_taken===0?() => this.showModal('monthlyGoals'): (userInitiative.type === "OTHER") && userInitiative.is_taken===0? () => this.showInitiativeModal(userInitiative):null} key={index}>
              <ImageContainer>
                <LazyImage src={`${ImageUrl}/${userInitiative.logo}`} alt="logo" />
              </ImageContainer>
              <DataContainer color={userInitiative.points_color}>
                <div>
                  {t(userInitiative.name)}
                </div>
                <div id={index+"Completed-on"}>
                  {userInitiative.is_taken ?userInitiative.date? `${t("Completed on")} ${moment(userInitiative.date).format('MMM DD, YYYY')}`:t("Completed") : `+${userInitiative.points} ${t("pts")}`}
                </div>
              </DataContainer>
              {(userInitiative.is_taken===0 || userInitiative.type == "BIOMETRIC" ) && userInitiative.type != "EDUCATION" && userInitiative.type != "SOCIAL" ? <Arrow>
                <img src="/public/images/NewDashboardV2/arrow_right.svg" />
              </Arrow> : null}
            </SubContainer>)})
          }
          <InputGoalV2
            showModal={modalName === 'monthlyGoals'}
            onHide={this.hideModal}
            SetDailyGoal={SetDailyGoal}
            inputGoals={inputGoals}
            userName={user.fname}
            month={month}
          />
          {showPopup && this.userInitiativePopup()}
          {showCalendar && this.logWorkoutCalendar()}
          {this.state.biometricPopupStatus && 
          <BiometricDetails
            showModal={this.state.biometricPopupStatus}
            closeModal={this.showBiometricSubmitStatusPop}
          />
          }
          {isConfirmDetails && this.confirmDetails()}
          <Suspense fallback={<Waiting/>}>
            {confirmVisit && <ConfirmVisitPopup confirmVisit={confirmVisit} hideConfirmPopup={this.hideConfirmPopup} showThankyouPopup={this.showThankyouPopup} currentDate={dateData} initiativeData={initiativeData} name={name} />}
            {showThankyou && <ThankyouPopup hideThankyouPopup={this.hideThankyouPopup} showThankyou={showThankyou} initiativeData={initiativeData} />}
            {showConfirmVisitModal && <ConfirmDetailsModal showConfirmVisitModal={showConfirmVisitModal} hideConfirmVisitModal={this.hideConfirmVisitModal} showIsConfirmDetails={this.showIsConfirmDetails} hideIsConfirmDetails={this.hideIsConfirmDetails} initiativeData={initiativeData} name={name} getUserCompanyItitiatives={this.props.getUserCompanyItitiatives} />}
          </Suspense>
        </TaskToCompleteSidebar> : null

    );
  }
}

TaskToComplete.propTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  month: PropTypes.string,
  fullMonth: PropTypes.string,
  SetDailyGoal: PropTypes.func.isRequired,
  inputGoals: PropTypes.object,
  listOfThingToComplate: PropTypes.object,
  displayDeviceModal: PropTypes.func,
  fetchSurveyQuestions: PropTypes.func,
  setSurveyQuestion: PropTypes.func,
  padding: PropTypes.number,
  userInitiatives: PropTypes.array,
  name: PropTypes.string,
  postUserInitiatives: PropTypes.func,
  t: PropTypes.func,
  getUserCompanyItitiatives: PropTypes.func,
  showBiometric:PropTypes.number
}

const mapStateToProps = (state) => ({
  user: state.profileData.user,
});
const mapDispatchToProps = (dispatch) => ({
  fetchSurveyQuestions: (id, history, successFunc) => dispatch(FetchSurveyQuestions(id, history, successFunc)),
  setSurveyQuestion: (id, history, staticSurveyStatus) => dispatch(setSurveyQuestion(id, history, staticSurveyStatus)),
  postUserInitiatives: (initiativesData) => dispatch(postUserInitiatives(initiativesData))

});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TaskToComplete));
