import React, { useEffect, useState } from "react";
import { CoreValuCard, CoreValuContainer } from "./styles";
import { Border } from "../../EventDetails/styles";
import { fetchApi } from "../../../utils/methods";
import { GetCompanyExemplifies } from "../../../redux/constants/apiConstants";
import LazyImage from "../../common/LazyImage/LazyImage";
import { ImageUrl } from "../../../utils/constants";
import {
  ArchiveIcon,
  RecognitionArchiveIcon,
  RecognitionLaunchIcon,
  RecognitionTickIcon,
  RecognitionWarningIcon,
} from "../../../utils/icons";
import PropTypes from "prop-types";
import CommonModal from "../../common/CommonModal/CommonModal";
import { ManagerIconContainer } from "../../Manager/styles";

export const InActiveLibraryTab = ({ history }) => {
  const [coreValues, setCoreValues] = useState([]);
  const [isArchive, setIsArchive] = useState(false);
  const [selectedCoreValues, setSelectedCoreValues] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    window.console.log("removed!");
    setShowModal(false);
  };
  useEffect(() => {
    GetCompanyCoreValues(1);
  }, []);

  const GetCompanyCoreValues = async (companyId) => {
    const res = await fetchApi(GetCompanyExemplifies + "/" + companyId, "GET");

    if (res.status === "success") {
      setCoreValues(res.data);
    }
  };
  const SelectCoreValue = (id) => {
    const allCoreValues = [...selectedCoreValues];
    if (allCoreValues.includes(id)) {
      let index = allCoreValues.findIndex((item) => item === id);
      if (index > -1) {
        allCoreValues.splice(index, 1);
      }
    } else {
      allCoreValues.push(id);
    }

    setSelectedCoreValues(allCoreValues);
  };
  return (
    <>
      <CoreValuContainer>
        <div className="top-bar-container">
          <div className="header">Core Values</div>
          {isArchive ? (
            <div className="archive-active">
              <div className="archive-btn" onClick={()=>setShowModal(true)}>
                <span className="archive-btn-icon">
                  {RecognitionArchiveIcon()}
                </span>
                Yes, Archive
              </div>
              <div
                className="cancel-archive"
                onClick={() => setIsArchive(false)}
              >
                Cancel
              </div>
            </div>
          ) : (
            <div className="archive" onClick={() => setIsArchive(true)}>
              Archive
            </div>
          )}
        </div>
        <Border
          style={{
            border: "1px solid #EFEFEF",
            margin: isArchive ? "12px 20px 20px 0px" : "20px 0px",
          }}
        />

        <div className="core-values">
          {coreValues.map((coreValue, index) => (
            <>
              <div className="border-btm">
                <CoreValuCard
                  key={index}
                  selected={
                    selectedCoreValues.includes(coreValue.id) ? true : false
                  }
                >
                  <div className="core-value-row">
                    <div className="col-one">
                      <div
                        className="icon"
                        style={{ background: coreValue?.color }}
                      >
                        <LazyImage
                          style={{ width: "24px" }}
                          src={ImageUrl + "/" + coreValue?.white_icon}
                        />
                      </div>
                      <div className="title">{coreValue?.core_value}</div>
                    </div>
                    <div className="action">
                      {isArchive ? (
                        <ManagerIconContainer>
                          <div
                            className="archive-action"
                            onClick={() => SelectCoreValue(coreValue.id)}
                          >
                            {selectedCoreValues.includes(coreValue.id)
                              ? RecognitionTickIcon()
                              : null}
                          </div>
                        </ManagerIconContainer>
                      ) : (
                        <div
                          className="eye-icon"
                          onClick={() =>
                            history.push({
                              pathname: `/company/recognition/core-value/launch`,
                              state: {
                                title: coreValue.core_value,
                                image: coreValue?.white_icon,
                                description: coreValue?.description,
                                points: coreValue?.point,
                                color: coreValue?.color,
                                status: coreValue?.active_status,
                                id: coreValue?.id,
                                type: "launch",
                              },
                            })
                          }
                        >
                          <ManagerIconContainer>{RecognitionLaunchIcon("#F37A65")}</ManagerIconContainer>
                        </div>
                      )}
                      {/* <div className="pencil-icon">{RecognitionPencilIcon()}</div> */}
                    </div>
                  </div>
                </CoreValuCard>
              </div>
            </>
          ))}
        </div>

        { showModal && <CommonModal
          show={showModal}
          icon={RecognitionWarningIcon("#B1AA56")}
          title="Archive Core Values"
          description="Are you sure you want to archive these core value?"
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          cancelText="Cancel"
          confirmButtonText="Yes, Archive"
          buttonIcon={ArchiveIcon()}
          modalHeight="184px"
          confirmButtonWidth="138px" 
        />
        }
      </CoreValuContainer>
    </>
  );
};

InActiveLibraryTab.propTypes = {
  history: PropTypes.object.isRequired,
};
