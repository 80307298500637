import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import LazyImage from "../../common/LazyImage/LazyImage";
import { ImageUrl } from "../../../utils/constants";
import { connect } from "react-redux";
import {
  StyledModal,
  StyledTitle,
  StyledBody,
  DetailInitiativeWrapper,
  CircleNew,
} from "./styles";
import { RecognitionCloseIcon } from "../../../utils/icons";
import CircularProgressBar from "../../HealthTabV2/HealthTabUpdated/circularProgressBar";

class CoreValuePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initiative: null,
      printCSV: false,
      isloading: false,
      initiativeReportData: [],
    };
  }

  componentDidMount() {
    if (this.props.detailInitiative) {
      this.setState({ initiative: this.props.detailInitiative });
    }
  }

  // Use componentDidUpdate to update the initiative if the prop changes
  componentDidUpdate(prevProps) {
    if (prevProps.detailInitiative !== this.props.detailInitiative) {
      this.setState({ initiative: this.props.detailInitiative });
    }
  }

  showDetailInitiative = () => {
    const { initiative } = this.state;

    if (!initiative) return null;
    return (
      <React.Fragment>
        <div className="row-one">
          <div className="col-one">
            <LazyImage
              div={'image'}
              background={initiative.color}
              style={{ width: "24px", height: "24px" }}
              src={ImageUrl + "/" + initiative.white_icon}
            />
            <div className="details">
              <div>
                <span className="name">{initiative.core_value}</span>
              </div>
              <div style={{ marginTop: "10px" }}>
                <span className="points">
                  {"Active"}
                </span>
                <span className="points"> |  +{initiative?.point} {initiative?.point > 0 ? "pts" : "pt"}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row-two">
          <div style={{marginRight:"20px"}}>
            <span
              className="user-count"
            >
              {initiative.total_user_received_count} Users Received
            </span>{" "}
          </div>
          <div>
            <div className="circularPercentage">
              <CircleNew width="170px" height="170px">
                <CircularProgressBar
                  strokeWidth="40"
                  sqSize="160"
                  // percentage={initiative?.user_received_percentage}
                  percentage={50}
                  color="#B1AA56"
                  // textValue="Average Score"
                  hideText={true}
                  strokeShape={"butt"}
                />
              </CircleNew>
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  };

  render() {
    const { showModal, onClose /*detailInitiative*/ } = this.props;
    return (
      <StyledModal
        transform={1}
        show={showModal}
        newWidth={"400px"}
        newBg={"newBg"}
        // height="50px"
        display="none"
      >
        <Modal.Header
          closeButton
          onHide={() => onClose()}
          style={{ background: "white" }}
        >
          <StyledTitle>Core Value Info </StyledTitle>
          <div
            onClick={() => onClose()}
            style={{cursor:"pointer"}}
          >
            {RecognitionCloseIcon()}
          </div>
        </Modal.Header>
        <StyledBody>
          <DetailInitiativeWrapper background={this.state?.initiative?.color}>
            {this.state.initiative && this.showDetailInitiative()}
          </DetailInitiativeWrapper>
        </StyledBody>
      </StyledModal>
    );
  }
}

CoreValuePopup.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  detailInitiative: PropTypes.object,
  companyId: PropTypes.number,
};

const mapStateToProps = (state) => ({
  userId: state.profileData.userId,
});

export default connect(mapStateToProps, null)(CoreValuePopup);
