import styled from "styled-components";

const CommonDeptLocContainer = styled.div`
position:absolute;
top:50px;
width: 400px;
max-height: 472px;
overflow-y: auto;
border-radius: 4px;
border: 1px solid #EFEFEF;
background: #FEFEFE;
padding: 12px;
z-index: 100;

.dept-loc-conteiner{
    display:flex;
}

.all-department{
    margin-right:24px;
}

.department-title{
    display:flex;
    align-items:center;
}

.department-row{
    display:flex;
    align-items:center;
    margin:8px 0px;
}
.dept-name{
    color:  #092F41;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
margin-left:16px;
}
`

const CheckBoxContainer = styled.div`
    display:flex;
    align-items:center;

    .archive-action{
  border-radius: 4px;
  

border:${({selected}) => selected ? '1px solid #F37A65' : '1px solid #7C7C7C'};
background:  #F8F8F8;
width:20px;
height:20px;
position:relative;
cursor:pointer
>svg{
    position:absolute;
    right:2px;
    top:3px;
}
}
`
export {CommonDeptLocContainer, CheckBoxContainer}