import React, { useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import PropTypes from "prop-types";
import {
  AvatarContainer,
  Column,
  DataColumn,
  Divider,
  RecognitionCard,
  RecognitionHeader,
  ScrollableContainer,
  TableRow,
  Title,
  ShowMore,
  NameInitials
} from "./styles";
import LazyImage from "../common/LazyImage/LazyImage";
import { ImageUrl } from "../../utils/constants";
import { getInitials } from "../../utils/methods";
import { DownloadReportIcon } from "../../utils/icons";


function RecognitionCommonCard({
  title,
  data,
  showMoreThreshold = 5,
  lastColumnName,
  setSelectedMember,
  setSelectedCard,
  cardType,
  setShowRecognitionPopUp,
  setShowReportPopUp
}) {
  const [showAllRows, setShowAllRows] = useState(false);
  const location = useLocation();
  const isDashboardRoute = location.pathname.includes("dashboard");

  const renderTableRows = () => {
    if (!data || data.length === 0) {
      return (
        <div style={{ textAlign: "center", marginTop: "16px" }}>
          No data available
        </div>
      );
    }

    const rowsToShow = showAllRows ? data : data.slice(0, showMoreThreshold);

    return rowsToShow.map((member, index) => (
      <React.Fragment key={index}>
        <TableRow isCursor={!isDashboardRoute  ? "pointer" : "default"}  isWidth={"574px"} onClick={!isDashboardRoute ? ()=>{setSelectedMember(member); setSelectedCard({type:cardType?.type,title:title,subtype:cardType?.subtype});setShowRecognitionPopUp(true)}: null }>
          <DataColumn>
            <AvatarContainer isProfile={true}>
              <DataColumn>
                <AvatarContainer isProfile={true}>
                  {member?.profile_image !== "profile-Image/default.png" ? 
                    (<LazyImage
                      style={{
                        width: "48px",
                        height: "48px",
                        borderRadius: "48px",
                        border: "1px solid #DCDCDC",
                      }}
                      onError={() => window.console.error("Image failed to load")}
                      src={`${ImageUrl}/${member?.profile_image}`}
                    />) :
                    (
                      <NameInitials>
                        {getInitials(member?.full_name)}
                      </NameInitials>
                    )}
                </AvatarContainer>
              </DataColumn>
            </AvatarContainer>
            {member?.full_name}
          </DataColumn>
          <DataColumn isWidth={"260px"}>
            {member?.total_recognitions}
          </DataColumn>
        </TableRow>
        {index==4?<Divider isMargin={"8px 0px"} />: index !== rowsToShow.length - 1 && <Divider isMargin={"8px 0px"} />}
      </React.Fragment>
    ));
  };

  return (
    <RecognitionCard height>
      <div className="header">
        <Title>{title}</Title>
        {!isDashboardRoute? <div className="download-icon" onClick={()=>{setSelectedCard({type:cardType?.type,title:title,subtype:cardType?.subtype});setShowReportPopUp(true)}}>{DownloadReportIcon()}</div>: null}
      </div>
      <Divider isMargin={"0px"}/>
      <RecognitionHeader>
        <Column>Employees</Column>
        <Column isWidth={"301px"} isLast>{lastColumnName}</Column>
      </RecognitionHeader>
      <ScrollableContainer
        showAllRows={showAllRows}
        height="380px"
      >
        {renderTableRows()}
        {data?.length > showMoreThreshold && !showAllRows && (
          <ShowMore
            onClick={() => setShowAllRows(true)}
            style={{
              cursor: "pointer",
              marginTop: "10px",
              textAlign: "center",
              color: "#184A61",
            }}
          >
            Show More
          </ShowMore>
        )}
      </ScrollableContainer>
    </RecognitionCard>
  );
}

RecognitionCommonCard.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      profile_image: PropTypes.string,
      full_name: PropTypes.string.isRequired,
      total_recognitions: PropTypes.number.isRequired,
    })
  ).isRequired,
  showMoreThreshold: PropTypes.number,
  lastColumnName: PropTypes.string,
  setSelectedCard: PropTypes.func,
  setSelectedMember: PropTypes.func,
  cardType: PropTypes.number,
  setShowRecognitionPopUp: PropTypes.func,
  setShowReportPopUp: PropTypes.func,
};

export default RecognitionCommonCard;
