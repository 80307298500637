import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { ManagerRightArrowIcon } from "../../utils/icons";
import { NameInitials } from "../Manager/styles";
import { getInitials } from "../../utils/methods";
import { useHistory } from "react-router-dom";


const TeamMemberCardV1 = styled.div`
  display: flex;
  align-items: center;
  ${'' /* background-color: #fff8ee; */}
  background-color: ${({ isBgColor }) => isBgColor ? isBgColor : '#fff8ee'}};
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;

  ${'' /* &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  } */}
`;
const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 40px;
  object-fit: cover;
  border: 1px solid #DCDCDC;
  margin-right: 15px;
`;
const MemberName = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #184A61;
  font-family: 'Lato';
`;
const ArrowIcon = styled.div`
  margin-left: auto;
`;


function TeamMemberCard( {name,image,bgColor,memberUid} ){
  const history = useHistory();
  const handleRedirect = (id) => {
    history.push(`/manager/people/${id}/personal-profile`)
  };



  return(  
    <TeamMemberCardV1 isBgColor={bgColor} onClick={()=> handleRedirect(memberUid)}>
      {  image !== "profile-Image/default.png" ?
        (<Avatar src={image}  />):
        ( <NameInitials>
          {getInitials(name)}
        </NameInitials>
        )
      }
      <MemberName>{name}</MemberName>
      <ArrowIcon>{ManagerRightArrowIcon()}</ArrowIcon>
    </TeamMemberCardV1>);
}

TeamMemberCard.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  bgColor: PropTypes.object.isRequired,
  memberUid: PropTypes.number.isRequired,
}

export default TeamMemberCard;