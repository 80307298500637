/* eslint-disable no-console,no-undef, camelcase, no-useless-escape */
import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import Datetime from 'react-datetime';
import 'isomorphic-fetch';
import { toast } from 'react-toastify';

import {
  ImageInput, ImageContentContainer, InputContainer, CustomMenuItem, StyledDateTime,
  TitleContainer, FieldTitle, ImageContainer, DateIconContainer, RemovePhotoButton, AmigosContainer, TopContainer,
  InviteContainer, ScrollableContainer, InviteAmigo, CustomCheckbox, NameContainer, ButtonContainer, LocationContainer
} from '../CreateEvent/styles';
import { ActivityTypeContainer, ActivityDropdown } from '../CreateChallengeForm/styles';
import { WeekHeader, WeekDivision, FirstPart, DateDashboard, WeekBox, SecondPart, ButtonWrapper, RepeatContainer, Text } from './styles';

import Waiting from '../Waiting';

import {
  getAllCompanies, saveChallengeData, getUserCompanyDetails, editChallengeAction,
  getChallengeSubcategory, editBehaviourChallenge, getSurveyLibraries
} from '../../redux/actions';

import { getOrientation, resetOrientation, convertDateInTimezone, checkImage, getPermissionStatus, fetchApi } from '../../utils/methods';
import { ImageUrl, imageErrorMessage } from '../../utils/constants';
import { /*challengeLevel,*/ updatedTierPoints, showChallengeEmailNotificationInfo } from '../../../mockData';
import { CheckMark } from '../AddWellnessInitiative/styles';
import { isNull } from 'lodash';
import { RadioButtonContainer } from '../CreateRecipe/styles';
import RadioButton from "../CustomRadioButton";
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import CommonDropdown from '../common/CommonDropDown/CommonDropdown';
import CommonInputBox from '../common/CommonInputBox/CommonInputBox';
import CommonTextArea from '../common/CommonTextArea/CommonTextArea';
import LazyImage from '../common/LazyImage/LazyImage';
import CommonButton from '../common/CommonButton/CommonButton';
import { GetCompanyInfo } from '../../redux/constants/apiConstants';
const challengeLengthList = [{ text: '7 days', week: 1 }, { text: '28 days', week: 4 }];
class BehaviorForm extends Component {
  constructor(props) {
    super();
    let updatedTiers = updatedTierPoints;
    const { survivorChallengeData } = props;
    this.state = {
      title: '',
      date: moment().format('MM/DD/YYYY'),
      endDate: '',
      description: '',
      imageName: '',
      activityType: 'Health',
      challengeLength: 1,
      amigosToInvite: [],
      openDateTimePicker: '',
      imgSrc: '',
      companies: [],
      location: [],
      dashboard: 'first',
      activeIndex: null,
      behaviorArr: [],
      activeWeek: 0,
      showDateTimeInput: '',
      isEditChallenge: false,
      cliked: false,
      repeatAboutDay: [],
      repeatImage: [],
      apiChecker: false,
      subcategory: "5K",
      level: "Beginner",
      subcategoryId: 1,
      storedBehaviorArr: [],
      introductionVideo: '',
      videoTitle: '',
      emailOption: {
        "launch_challenge_email": 1,
        "challenge_reminder_email": 1,
        "challenge_complete_email": 1
      },
      companiesPermission: false,
      locationPermission: false,
      isSpouse: false,
      isDependent: false,
      isEmployee: false,
      maxLength: 0,
      selectedOption: 1,
      surveyId: null,
      surveyType: '',
      challengePointKey: 'Tier 1: 0 points',
      challengePoints: updatedTiers,
      challengePointValue: _.get(survivorChallengeData, 'challenge_point', '10'),
      companyInfo:null
    }
  }

  challengeLevel= [
    "Beginner",
    "Intermediate",
    "Advanced"
  ]

  componentDidMount() {
    this.props.getChallengeSubcategory();
    this.fillInitialProps();
    this.fillBehavior();
    this.props.getSurveyLibraries();
    const companiesPermission = getPermissionStatus("Show companies in challenge", this.props.userPermissions);
    const locationPermission = getPermissionStatus("Show locations in challenge", this.props.userPermissions);
    if (companiesPermission) {
      if (!this.props.allCompanies) {
        this.props.fetchAllCompanies();
      }
    } else {
      this.props.fetchCompanyForUser();
      this.setState({
        companies: this.props.userCompany && [this.props.userCompany.id]
      })
    }
    this.setState({
      companiesPermission: companiesPermission,
      locationPermission: locationPermission
    })
    document.addEventListener('mousedown', this.handleClick);
    this.getCompanyInfo(this.props.userCompany.id);
  }

  getCompanyInfo = async(companyId)=>{
    let apiUrl = GetCompanyInfo + "/" + companyId;
    try {
      const res = await fetchApi(apiUrl , "GET");
      if (res.status === "success") {
        this.setState({companyInfo: res?.data?.companyInfo?.[0]})

      } else {
        toast.error(res.error);
      }
    } catch (error) {
      window.console.log(error);
    }

  }

  fillInitialProps = () => {
    const { survivorChallengeData, isEditChallenge, createChallengeById,
      behaviorChallengeDetailsById, editLibrary, surveyLibraries } = this.props;
    let tierPointsIndex = this.state.challengePoints.findIndex((data) => data.value == _.get(survivorChallengeData, 'challenge_point', '10'));
    if (survivorChallengeData || isEditChallenge) {
      let Array = survivorChallengeData.imgSrc.split('/');
      let ImageName = Array[Array.length - 1];
      let makeArray = [];
      for (let index = 0; index < survivorChallengeData.behaviorArr.length; index++) {
        let obj = { ...survivorChallengeData.behaviorArr[index] }
        obj.video = survivorChallengeData.behaviorArr[index].video_url;
        makeArray.push(obj);
      }
      let surveyIndex = surveyLibraries && surveyLibraries.length > 0 ? surveyLibraries.findIndex((data) => data.id === survivorChallengeData.surveyId) : -1;
      this.setState({
        title: survivorChallengeData.title,
        description: survivorChallengeData.description,
        challengeLength: survivorChallengeData.challengeLength,
        date: survivorChallengeData.date,
        isEditChallenge: isEditChallenge,
        imageName: ImageName,
        activityType: survivorChallengeData.activityType,
        behaviorArr: makeArray,
        imgSrc: survivorChallengeData.imgSrc,
        companies: survivorChallengeData.companies,
        apiChecker: (convertDateInTimezone(survivorChallengeData.activeDate)._d < moment()._d && convertDateInTimezone(survivorChallengeData.finishDate)._d >= moment()._d) ? true : false,
        subcategory: survivorChallengeData.subcategory,
        level: survivorChallengeData.level,
        location: survivorChallengeData.locations ? survivorChallengeData.locations : [],
        isSpouse: survivorChallengeData.isSpouse,
        isDependent: survivorChallengeData.isDependent,
        isEmployee: survivorChallengeData.isEmployee,
        emailOption: {
          "launch_challenge_email": survivorChallengeData.launch_challenge_email,
          "challenge_reminder_email": survivorChallengeData.challenge_reminder_email,
          "challenge_complete_email": survivorChallengeData.challenge_complete_email
        },
        surveyId: survivorChallengeData.surveyId,
        selectedOption: surveyIndex === -1 ? 1 : 0,
        surveyType: surveyLibraries && surveyLibraries.length > 0 ? surveyLibraries[surveyIndex === -1 ? 0 : surveyIndex].survey_name : '',
        challengePointKey: updatedTierPoints[tierPointsIndex == -1 ? 0 : tierPointsIndex].key,
        challengePointValue: tierPointsIndex == -1 ? 10 : _.get(survivorChallengeData, 'challenge_point', '5'),
      })
    }
    if (createChallengeById || editLibrary) {
      let Array = behaviorChallengeDetailsById.image.split('/');
      let ImageName = Array[Array.length - 1];
      let makeArray = [];
      for (let index = 0; index < behaviorChallengeDetailsById.behavior_challenge_details.length; index++) {
        let obj = { ...behaviorChallengeDetailsById.behavior_challenge_details[index] }
        obj.video = behaviorChallengeDetailsById.behavior_challenge_details[index].video_url;
        if (editLibrary) {
          obj.imageUpdated = 0;
          obj.has_video = 0;
          delete obj.video_url;
        }
        makeArray.push(obj);
      }
      let surveyIndexLibrary = surveyLibraries.findIndex((data) => data.id === behaviorChallengeDetailsById.survey_id);
      this.setState({
        behaviorArr: makeArray,
        title: behaviorChallengeDetailsById.title,
        activityType: behaviorChallengeDetailsById.event_type == "Stress" ? "Mental Health" : behaviorChallengeDetailsById.event_type == "Workplace" ? "Workplace Wellness" :behaviorChallengeDetailsById.event_type,
        challengeLength: behaviorChallengeDetailsById.behavior_challenge_details.length / 7,
        imageName: ImageName,
        imgSrc: behaviorChallengeDetailsById.image,
        description: behaviorChallengeDetailsById.body,
        subcategory: behaviorChallengeDetailsById.subcategory || "5K",
        level: behaviorChallengeDetailsById.level || "Beginner",
        introductionVideo: behaviorChallengeDetailsById.introduction_video ? behaviorChallengeDetailsById.introduction_video : "",
        videoTitle: behaviorChallengeDetailsById.video_title ? behaviorChallengeDetailsById.video_title : "",
        surveyId: behaviorChallengeDetailsById.survey_id,
        selectedOption: surveyIndexLibrary === -1 ? 1 : 0,
        surveyType: surveyLibraries.length > 0 ? surveyLibraries[surveyIndexLibrary === -1 ? 0 : surveyIndexLibrary].survey_name : '',
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.state.companiesPermission && this.props.userCompany !== prevProps.userCompany) {
      this.setState({
        companies: this.props.userCompany && [this.props.userCompany.id]
      })
    }
    if (prevProps.behaviorChallengeDetailsById !== this.props.behaviorChallengeDetailsById || prevProps.surveyLibraries !== this.props.surveyLibraries) {
      this.fillInitialProps();
      this.fillBehavior();
    }
  }

  selectSpouseDependent = (stateName) => {
    this.setState((prev) => ({
      [stateName]: !prev[stateName]
    }))
  };

  fillBehavior = () => {
    const { isEditChallenge, createLibrary } = this.props;
    if (!isEditChallenge) {
      if (this.state.behaviorArr.length === 0 && createLibrary) {
        let arr = []
        for (let index = 0; index < this.state.challengeLength * 7; index++) {
          let obj = {
            image: '',
            imageName: '',
            tip: '',
            video: '',
            video_title: '',
            about_the_day: '',
            has_video: 0
          };
          arr.push(obj);
        }
        this.setState({
          behaviorArr: arr
        })
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  onChangeLocation = (id) => {
    const { location } = this.state;
    if (location.includes(id)) {
      let index = location.findIndex((item) => item === id);
      if (index > -1) {
        location.splice(index, 1);
      }
    } else {
      location.push(id);
    }
    this.setState({
      location: location
    })
  }

  selectAllLocation = (e, locationDetails) => {
    e.preventDefault();
    const { location } = this.state;
    if (location.length == locationDetails.length) {
      this.setState({
        location: []
      })
    }
    else {
      let arr = [];
      for (let index = 0; index < locationDetails.length; index++) {
        arr.push(locationDetails[index].id);
      }
      this.setState({
        location: arr
      })
    }
  }

  handleClick = (e) => {
    if (this.datePickerWrapper && !this.datePickerWrapper.contains(e.target)) {
      this.setState({
        openDateTimePicker: ''
      });
    }
  };

  setDatePickerWrapper = (node) => {
    this.datePickerWrapper = node;
  };

  onChange = (e) => {
    let file = e.target.files[0];
    if (checkImage(e.target.files)) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      const array = document.getElementById('behavior-challenge-file').value.split("\\");
      reader.onloadend = () => {
        this.setState({
          imgSrc: reader.result,
          imageName: array[array.length - 1],
        });
        getOrientation(file, (or) => {
          resetOrientation([reader.result], or, (baseImage) => {
            this.setState({
              imgSrc: baseImage
            });
          });
        });
      };
    } else {
      toast.error(imageErrorMessage);
      document.getElementById('behavior-challenge-file').value = '';
    }
  };

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  removePhoto = () => {
    document.getElementById('behavior-challenge-file').value = '';
    this.setState({
      imgSrc: '',
      imageName: '',
    });
  };

  onSelect = (name, value) => {
    if (name == 'challengeLength') {
      const { behaviorArr, repeatAboutDay, storedBehaviorArr } = this.state;
      let arr = [];
      let storedArr = storedBehaviorArr;
      repeatAboutDay.sort();
      for (let index = repeatAboutDay.length - 1; index >= 0; index--) {
        if (repeatAboutDay[index] >= value) {
          repeatAboutDay.pop();
        } else {
          break;
        }
      }
      if (behaviorArr.length < value * 7 && storedBehaviorArr.length < value * 7) {
        arr = behaviorArr;
        for (let index = behaviorArr.length; index < value * 7; index++) {
          let obj = {
            image: '',
            imageName: '',
            tip: '',
            video: '',
            video_title: '',
            about_the_day: '',
            has_video: 0
          };
          arr.push(obj);
        }
      } else {
        if (value * 7 <= storedBehaviorArr.length) {
          for (let index = 0; index < value * 7; index++) {
            arr.push(storedBehaviorArr[index]);
          }
        } else {
          for (let index = 0; index < value * 7; index++) {
            arr.push(behaviorArr[index]);
          }
          storedArr = storedBehaviorArr.length >= behaviorArr.length ? storedBehaviorArr : behaviorArr;
        }
      }
      this.setState({
        [name]: value,
        behaviorArr: arr,
        activeWeek: 0,
        storedBehaviorArr: storedArr
      });
    } else {
      this.setState({
        [name]: value,
      }, () => this.onSelect('challengeLength', value === "Running" ? this.state.challengeLength <= 4 ? this.state.challengeLength : 1 : this.state.challengeLength >= 4 ? 4 : 1));
    }
  };

  changeDate = (e, stateName) => {
    const { showDateTimeInput } = this.state;
    if (showDateTimeInput === 'date') {
      this.setState({
        date: moment(e._d).format('MM/DD/YYYY'),
        time: moment(e._d).format('hh:mm a'),
        endDate: moment(e._d).format('MM/DD/YYYY'),
        endTime: '11:59 pm',
        openDateTimePicker: '',
      });
    } else if (stateName.includes('ate')) {
      this.setState({
        openDateTimePicker: '',
        [stateName]: moment(e._d).format('MM/DD/YYYY'),
      });
    } else {
      this.setState({
        [stateName]: moment(e._d).format('hh:mm a'),
        openDateTimePicker: stateName
      });
    }
  };

  showDatePicker = (value) => {
    if (this.state.openDateTimePicker === value) {
      this.setState({
        openDateTimePicker: ''
      });
    } else {
      this.setState({
        openDateTimePicker: value
      });
    }
  };

  changeDisablebutton = (bool) => {
    this.setState({
      cliked: bool
    })
  }

  onSubmit = (e) => {
    e.preventDefault();
    console.log("hey")
    this.changeDisablebutton(true);
    const { title, date, description, imgSrc, activityType, challengeLength, location, behaviorArr,
      companies, imageName, apiChecker, level, subcategoryId, emailOption, locationPermission, isSpouse, isEmployee, isDependent, introductionVideo, videoTitle, surveyId, selectedOption, challengePointValue } = this.state;
    const { createChallenge, saveChallengeData, /*editChallengeAction*/ isEditChallenge,
      challengeId, createLibrary, createBehaviorLibrary, createChallengeById, survivorChallengeData, editLibrary, history, editBehaviourChallenge, companyDetails } = this.props;
    const challengeType = 'behavior';
    let endDate = moment(moment(date).add((challengeLength * 7) - 1, 'days')._d).format('MM/DD/YYYY');
    let has_video = false;
    let i = 0;
    if (!isEditChallenge) {
      while (i < behaviorArr.length) {
        if (behaviorArr[i].video && behaviorArr[i].video.trim() != '') {
          has_video = true;
          i = behaviorArr.length;
        } else {
          i = i + 1;
        }
      }
    }

    const challenge = {
      title: title.trim(),
      body: description.trim(),
      image: imgSrc,
      category: activityType == "Mental Health" ? "Stress" : activityType == "Workplace Wellness" ? "Workplace" :activityType,
      challenge_type: challengeType,
      days: behaviorArr,
      has_video: has_video,
      is_seven_days_challenge: challengeLength === 1 ? 1 : 0,
      // challenge_point: challengeLength === 1 ? 100 : 300,
      subcategory_id: activityType === "Running" ? subcategoryId : null,
      level: activityType === "Running" ? level : '',
      challenge_length: challengeLength * 7,
      introduction_video: introductionVideo ? introductionVideo : "",
      video_title: videoTitle ? videoTitle : "",
      survey_id: selectedOption === 1 ? null : surveyId,
      challenge_point: challengePointValue,
    };

    if (locationPermission) {
      challenge.company_city_states = location;
      challenge.challenge_for_spouse = isSpouse ? 1 : 0;
      challenge.challenge_for_dependent = isDependent ? 1 : 0
      if (this.props.locationDetails && this.props.locationDetails.length > 0) {
        if(location.length > 0){
          challenge.is_for_employee = 1
        }
        else{
          challenge.is_for_employee = 0
        }
      }
      else {
        if (companyDetails['spouse_or_dependent']) {
          challenge.is_for_employee = isEmployee ? 1 : 0
        }
        else {
          challenge.is_for_employee = 1
        }
      }
    }

    if (createLibrary) {
      createBehaviorLibrary(challenge, this.changeDisablebutton, history?.location?.pathname?.includes("/company/challenges/")?'/company/challenges/categories':'/challenges/categories');
    } else if (editLibrary) {
      challenge.imageUpdated = this.checkBase64(imgSrc) ? 1 : 0;
      challenge.behavior_challenge_id = challengeId;
      editBehaviourChallenge(challenge, history, this.changeDisablebutton, history?.location?.pathname?.includes("/company/challenges/")?'/company/challenges/categories':'/challenges/categories');
    }
    else if (createChallengeById) {
      challenge.companies = companies;
      challenge.end_date = moment(endDate).format('YYYY-MM-DD') + ' ' + moment('11:59:59 PM', "hh:mm:ss a").format("HH:mm:ss");
      challenge.start_date = moment(date).format('YYYY-MM-DD') + ' ' + moment('12:00:00 AM', "hh:mm:ss a").format("HH:mm:ss");
      challenge.behavior_challenge_id = challengeId;
      challenge.convert_image = !this.checkBase64(imgSrc);
      saveChallengeData({ ...challenge, imageName, ...emailOption });
      createChallenge({ ...challenge, ...emailOption }, this.changeDisablebutton, history?.location?.pathname?.includes("/company/challenges/")?'/company/challenges/categories':'/challenges/categories');
    } else if (isEditChallenge) {
      challenge.companies = companies;
      challenge.end_date = moment(endDate).format('YYYY-MM-DD') + ' ' + moment('11:59:59 PM', "hh:mm:ss a").format("HH:mm:ss");
      challenge.start_date = moment(date).format('YYYY-MM-DD') + ' ' + moment('12:00:00 AM', "hh:mm:ss a").format("HH:mm:ss");
      challenge.id = challengeId;
      challenge.event_type = activityType;
      challenge.behavior_challenge_id = survivorChallengeData.behavior_challenge_id;
      challenge.imageUpdated = this.checkBase64(imgSrc) ? 1 : 0;
      if (apiChecker) {
        challenge.is_challenge_started_or_over = 1;
      }
      console.log("challenge", challenge);
      // editChallengeAction({ ...challenge, ...emailOption }, this.changeDisablebutton);
    }
  };

  onCheckboxChange = (element) => {
    const { companies } = this.state;
    if (companies.includes(element)) {
      let index = companies.findIndex((item) => item === element);
      if (index > -1) {
        companies.splice(index, 1);
      }
    } else {
      companies.push(element);
    }
    this.setState({
      companies: companies
    })
  };

  selectAllCompanies = (e) => {
    e.preventDefault();
    let invitedCompanies = [];
    const { allCompanies } = this.props;
    if (this.state.companies.length === allCompanies.length) {
      invitedCompanies = [];
    } else {
      allCompanies.map((company) => {
        invitedCompanies.push(company.id);
      });
    }
    this.setState({
      companies: invitedCompanies
    })
  };

  getDailyImage = (e, index) => {
    let { behaviorArr, repeatImage, activeWeek } = this.state;
    let dubArr = behaviorArr;
    let file = e.target.files[0];
    if (checkImage(e.target.files)) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      const array = document.getElementById(`daily-file${index}`).value.split("\\");
      reader.onloadend = () => {
        getOrientation(file, (or) => {
          resetOrientation([reader.result], or, (baseImage) => {
            dubArr[index].image = baseImage;
            dubArr[index].imageName = array[array.length - 1];
            if (this.props.editLibrary) {
              dubArr[index].imageUpdated = 1
            }
            if (repeatImage.includes(activeWeek)) {
              let index = repeatImage.findIndex((number) => number == activeWeek);
              repeatImage.splice(index, 1);
            }
            this.setState({
              behaviorArr: dubArr,
              repeatImage
            });
            document.getElementById(`daily-file${index}`).value = '';
          });
        });
      };
    } else {
      toast.error(imageErrorMessage);
      document.getElementById(`daily-file${index}`).value = '';
    }
  }

  getVideo = (e, index) => {
    let { behaviorArr } = this.state;
    let dubArr = behaviorArr;
    dubArr[index].video = e.target.value;
    if (this.props.editLibrary) dubArr[index].has_video = 1;
    this.setState({
      behaviorArr: dubArr
    })
  }

  getTip = (e, index) => {
    let { behaviorArr } = this.state;
    let dubArr = behaviorArr;
    dubArr[index].tip = e.target.value
    this.setState({
      behaviorArr: dubArr
    })
  }

  getVideoTitle = (e, index) => {
    let { behaviorArr } = this.state;
    let dubArr = behaviorArr;
    dubArr[index].video_title = e.target.value;
    this.setState({
      behaviorArr: dubArr
    })
  }

  getAboutDay = (e, index) => {
    let { behaviorArr, repeatAboutDay, activeWeek } = this.state;
    let dubArr = behaviorArr;
    dubArr[index].about_the_day = e.target.value;
    this.setState({
      behaviorArr: dubArr
    });
    if (!e.target.value.trim()) {
      if (repeatAboutDay.includes(activeWeek)) {
        let index = repeatAboutDay.findIndex((number) => number == activeWeek);
        repeatAboutDay.splice(index, 1);
      }
      this.setState({
        repeatAboutDay
      })
    }
  }

  removeDailyPhoto = (index) => {
    let { behaviorArr, repeatImage, activeWeek } = this.state;
    let dubArr = behaviorArr;
    document.getElementById(`daily-file${index}`).value = '';
    dubArr[index].image = '';
    dubArr[index].imageName = '';
    if (this.props.editLibrary) dubArr[index].imageUpdated = 0;
    if (repeatImage.includes(activeWeek)) {
      let index = repeatImage.findIndex((number) => number == activeWeek);
      repeatImage.splice(index, 1);
    }
    this.setState({
      behaviorArr: dubArr,
      repeatImage
    })
  }

  checkBase64(image) {
    if (image) {
      if (image.includes('base64')) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  isNumberKey = (evt) => {
    let charCode = (evt.which) ? evt.which : event.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) evt.preventDefault();
  };

  changeActiveWeek = (index) => {
    window.scrollTo(0, this.weekRef.offsetTop)
    this.setState({
      activeWeek: index
    });
  }

  getWeekDashboard = () => {
    let weekDiv = [];
    for (let index = 0; index < this.state.challengeLength; index++) {
      weekDiv.push(
        <WeekDivision
          key={index}
          grid={this.state.challengeLength}
          active={this.state.activeWeek === index}
          onClick={() => this.changeActiveWeek(index)}>
          <div>
            {`${this.props.t("Week")} ${index + 1}`}
          </div>
        </WeekDivision>
      )
    }
    return weekDiv;
  }

  checkMark = (keyValue) => {
    const { activeWeek, repeatAboutDay, repeatImage, behaviorArr } = this.state;
    let startIndex = activeWeek * 7 + 1;
    let endIndex = activeWeek * 7 + 7
    if (keyValue == 'image') {
      if (repeatImage.includes(activeWeek)) {
        let index = repeatImage.findIndex((number) => number == activeWeek);
        repeatImage.splice(index, 1);
      } else {
        repeatImage.push(activeWeek);
        for (let index = startIndex; index < endIndex; index++) {
          behaviorArr[index]['image'] = behaviorArr[startIndex - 1]['image'];
          behaviorArr[index]['imageName'] = behaviorArr[startIndex - 1]['imageName'];
          if (this.props.editLibrary) behaviorArr[index]['imageUpdated'] = this.checkBase64(behaviorArr[startIndex - 1]['image']) ? 1 : 0;
        }
      }
      this.setState({
        repeatImage,
        behaviorArr
      })
    } else {
      if (repeatAboutDay.includes(activeWeek)) {
        let index = repeatAboutDay.findIndex((number) => number == activeWeek);
        repeatAboutDay.splice(index, 1);
      } else {
        repeatAboutDay.push(activeWeek);
        for (let index = startIndex; index < endIndex; index++) {
          behaviorArr[index]['about_the_day'] = behaviorArr[startIndex - 1]['about_the_day']
        }
      }
      this.setState({
        repeatAboutDay,
        behaviorArr
      })
    }
  }

  getRepeatContainer = (text, value, keyValue, stateValue) => {
    const { activeWeek } = this.state;
    let checked = false;
    checked = stateValue.includes(activeWeek) ? true : false;
    let emptyValue = !value || !value.trim();
    return (
      <RepeatContainer>
        <CheckMark
          disabled={emptyValue}
          onClick={() => !(!isNull(value) && !value.trim()) && this.checkMark(keyValue)}
          checked={checked && !emptyValue}
        />
        <span>{text}</span>
      </RepeatContainer>
    )
  }
  getChallengeLengthContainer = () => {
    const { challengeLength, isEditChallenge } = this.state;
    const { createLibrary, createChallengeById } = this.props;
    return (
      challengeLengthList.length && challengeLengthList.map((data, index) => (<RepeatContainer key={index} marginRight={1} marginTop={1}>
        <CheckMark
          onClick={() => (createLibrary || editLibrary) && this.onSelect('challengeLength', data.week)}
          checked={challengeLength === data.week}
          disabled={createChallengeById || isEditChallenge}
        />
        <span>{data.text}</span>
      </RepeatContainer>
      ))
    )
  };

  getDateDashboard = () => {
    const { behaviorArr, activeWeek, repeatAboutDay, repeatImage, isEditChallenge } = this.state;
    const { createChallengeById, t } = this.props;
    return [1, 2, 3, 4, 5, 6, 7].map((item, index) => (
      <TitleContainer key={index}>
        <FieldTitle fullWidth="100%">
          <span className="day">
            {`${t("Day")} ${item}`}
          </span>
        </FieldTitle>
        <FirstPart>
          <FieldTitle marginTop="20px">{t("About the Day")}:</FieldTitle>
          {item === 1 && !createChallengeById && !isEditChallenge &&
            this.getRepeatContainer(t('Repeat About the Day'), behaviorArr[activeWeek * 7 + index].about_the_day, 'about_the_day', repeatAboutDay)}
          <CommonTextArea
            placeholder={t("Write about the day here...")}
            rows="3"
            name="about-day"
            value={behaviorArr[activeWeek * 7 + index].about_the_day || ''}
            onChange={(e) => this.getAboutDay(e, activeWeek * 7 + index)}
            data-gramm_editor="false"
            disabled={createChallengeById || isEditChallenge}
            disableArea={createChallengeById || isEditChallenge}
          />
          <FieldTitle marginTop="20px">{t("Video Link")}:</FieldTitle>
          <CommonInputBox
            placeholder="http://"
            name="videos"
            type="url"
            pattern="https?://.+"
            onChange={(e) => this.getVideo(e, activeWeek * 7 + index)}
            value={behaviorArr[activeWeek * 7 + index].video || ''}
            disabled={createChallengeById || isEditChallenge}
          />
          <FieldTitle marginTop="20px">{t("Video Title")}:</FieldTitle>
          <CommonInputBox
            placeholder={t("Video Title...")}
            name="videos"
            type="url"
            onChange={(e) => this.getVideoTitle(e, activeWeek * 7 + index)}
            value={behaviorArr[activeWeek * 7 + index].video_title || ''}
            disabled={createChallengeById || isEditChallenge}
          />
          <FieldTitle marginTop="20px">{t("Daily Tip")}:</FieldTitle>
          <CommonTextArea
            placeholder={t("Write daily tip here...")}
            rows="3"
            name="daliy-tip"
            value={behaviorArr[activeWeek * 7 + index].tip || ''}
            onChange={(e) => this.getTip(e, activeWeek * 7 + index)}
            data-gramm_editor="false"
            disabled={createChallengeById || isEditChallenge}
            disableArea={createChallengeById || isEditChallenge}
            maxLength="178"
          />
          <Text><p>{behaviorArr[activeWeek * 7 + index].tip.length}/178 {t("characters")}</p></Text>
        </FirstPart>
        <SecondPart>
          <FieldTitle marginTop="20px" >{t("Challenge Image")}:</FieldTitle>
          {item === 1 && !createChallengeById && !isEditChallenge && this.getRepeatContainer(t('Repeat Challenge Image'), behaviorArr[activeWeek * 7 + index].image, 'image', repeatImage)}
          {behaviorArr[activeWeek * 7 + index].image ?
            <div className="imageWrapperClass">
              <img
                src={this.checkBase64(behaviorArr[activeWeek * 7 + index].image)
                  ? behaviorArr[activeWeek * 7 + index].image
                  : `${ImageUrl}/${behaviorArr[activeWeek * 7 + index].image}`
                }
              />
              <div className="behaviorImage">{behaviorArr[activeWeek * 7 + index].imageName}</div>
            </div>
            :
            <div className="imageWrapperClass">
              <div className="behaviorImage">{t("Browse for the image")}</div>
            </div>
          }
          {!createChallengeById && !isEditChallenge &&
            <div className="buttonWrapper">
              <div>
                <ImageInput htmlFor={`daily-file${activeWeek * 7 + index}`} lineHeight={1}>
                  {t('browse')}
                  <input
                    id={`daily-file${activeWeek * 7 + index}`}
                    type="file"
                    name="daily-user"
                    multiple={false}
                    onChange={(e) => this.getDailyImage(e, activeWeek * 7 + index)}
                    onClick={(e) => e.target.files[0] && this.getDailyImage(e, activeWeek * 7 + index)}
                    accept=".jpeg, .png, .jpg"
                  />
                </ImageInput>
              </div>
              <div>
                {behaviorArr[activeWeek * 7 + index].image &&
                  <RemovePhotoButton
                    color="#aaaaaa" float={1}
                    addMargin onClick={() => this.removeDailyPhoto(activeWeek * 7 + index)}
                  >
                    {t("Remove photo")}
                  </RemovePhotoButton>
                }
              </div>
            </div>
          }
        </SecondPart>
      </TitleContainer>
    ))
  }

  checkBehaviorArr = (behaviorArr, createChallengeById) => {
    let checkWeek = false;
    if (!createChallengeById) {
      for (let index = 0; index < behaviorArr.length;) {
        let getVideo = isNull(behaviorArr[index].video) ? '' : behaviorArr[index].video;
        let getVideoTitle = isNull(behaviorArr[index].video_title) ? '' : behaviorArr[index].video_title;
        if (!behaviorArr[index].about_the_day.trim() || !behaviorArr[index].tip.trim() || ((getVideo.trim() !== '' || getVideoTitle.trim() !== '') && (getVideoTitle.trim() === '' || getVideo.trim() === '')) || ((getVideo.trim() === '') || (getVideoTitle.trim() === '')) && behaviorArr[index].image === '') {
          checkWeek = true;
          index = behaviorArr.length;
        } else {
          if (!getVideo) {
            checkWeek = false;
            index++;
          } else {
            if (getVideo.trim().match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)) {
              checkWeek = false;
              index++;
            } else {
              checkWeek = true;
              index = behaviorArr.length;
            }
          }
        }
      }
    }
    return checkWeek;
  }

  checkDisableFunction = () => {
    const { title, date, description, imageName, imgSrc, activityType,
      challengeLength, behaviorArr, companies, locationPermission, location, isEmployee, isSpouse, isDependent, introductionVideo, videoTitle } = this.state;
    const { createChallengeById, isEditChallenge, locationDetails, companyDetails } = this.props;
    if (!title.trim() || !description.trim() || !imageName || !imgSrc || !activityType || !challengeLength || (introductionVideo.trim().match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) && !videoTitle)) {
      return true;
    } else if (createChallengeById || isEditChallenge) {
      if (!date || companies.length <= 0) {
        return true;
      } else if (locationPermission) {
        if (locationDetails && locationDetails.length > 0 && location.length < 1 && !(isSpouse || isDependent)) {
          return true;
        }
        if (locationDetails && locationDetails.length === 0 && companyDetails['spouse_or_dependent']) {
          if (!(isSpouse || isDependent || isEmployee)) {
            return true;
          }
        }
      } else {
        return this.checkBehaviorArr(behaviorArr, true);
      }
    } else {
      return this.checkBehaviorArr(behaviorArr, false);
    }
  }
  getChallengeLevel = () => {
    const { level, isEditChallenge } = this.state;
    const { createLibrary, createChallengeById, editLibrary } = this.props;
    return (
      this.challengeLevel.map((data, index) => (<RepeatContainer key={index} marginRight={1} marginTop={1}>
        <CheckMark
          onClick={() => createLibrary || editLibrary && this.onSelect('level', data)}
          checked={level === data}
          disabled={createChallengeById || isEditChallenge}
        />
        <span>{data}</span>
      </RepeatContainer>
      ))
    )
  };
  onChangeSubCategories = (name, value, id) => {
    this.setState({
      [name]: value,
      subcategoryId: id
    });
  }
  onChangeEmail = (key) => {
    const { emailOption } = this.state;
    emailOption[key] = emailOption[key] === 0 ? 1 : 0;
    this.setState({ emailOption: emailOption });
  }
  toggleRadioButton = (e) => {
    this.setState({ selectedOption: parseInt(e.target.value,10) }, () => { });
  };
  onSelectSurveyType = (name, value, id) => {
    this.setState({
      [name]: value,
      surveyId: id
    });
  };

  onSelectPoints = (name, value, key) => {
    this.setState({
      [name]: value,
      challengePointKey: key 
    });
  };

  render() {
    const { title, date, description, imageName, imgSrc, showDateTimeInput, activityType,
      openDateTimePicker, challengeLength, behaviorArr, isEditChallenge, cliked, activeWeek,
      companies, subcategory, emailOption, locationPermission, companiesPermission, isSpouse, isEmployee, isDependent, location, introductionVideo, videoTitle, selectedOption, surveyType, surveyId, challengePointKey, challengePoints, challengePointValue, companyInfo } = this.state;
    const { allCompanies, userCompany, survivorChallengeData, createLibrary,
      createChallengeById, runningSubCategories, isLoading, editLibrary, locationDetails, companyDetails,
      surveyLibraries, t } = this.props;

    const activityDropdown = ['Health', 'Fitness', 'Nutrition', 'Mental Health', 'Lifestyle', 'Workplace Wellness', /*'Running'*/];
    const challengeLengthDropdown = [1, 2, 3, 4, 5, 6, 7, 8];
    let disabledFunctions = false;
    let disabledCompaniesAndLocations = isEditChallenge;
    const disableEmailOptionStatus = isEditChallenge;
    if (isEditChallenge) {
      disabledFunctions = (convertDateInTimezone(survivorChallengeData.activeDate)._d < moment()._d && convertDateInTimezone(survivorChallengeData.finishDate)._d >= moment()._d) ? true : false
    }
    let emailOptionKeys = Object.keys(emailOption);

    // const yesterday = Datetime.moment().subtract(23, "hour");
    // const valid = (currentDate) =>
    //   showDateTimeInput === "date"
    //     ? currentDate.isAfter(Datetime.moment())
    //     : currentDate.isAfter(yesterday);
    const yesterday = Datetime.moment().subtract(23, "hour");
    const valid = (currentDate) => showDateTimeInput === 'date' ? currentDate.isAfter(Datetime.moment()) : currentDate.isAfter(yesterday);

    if (isLoading || (companiesPermission && !allCompanies) || (locationPermission && !userCompany)) {
      return <Waiting />
    }
    return (
      <div>
        <TitleContainer padding="20px 30px">
          <FirstPart>
            <FieldTitle>{t("Challenge Title")}:</FieldTitle>
            <CommonInputBox
              placeholder={t("Type title here...")}
              name="title"
              onChange={this.onChangeInput}
              value={title}
              disabled={disabledFunctions}
            />
          </FirstPart>
        </TitleContainer>
        <ImageContainer>
          <FirstPart>
            <FieldTitle className="fullSpan">{t("Challenge Image")}:</FieldTitle>
            <div>
              {imageName &&
                <div className="imageWrapperClass">
                  {(isEditChallenge || createChallengeById || editLibrary) ?
                    <img src={this.checkBase64(imgSrc) ? imgSrc : `${ImageUrl}/${imgSrc}`} />
                    :
                    <img src={imgSrc} />
                  }
                  <ImageContentContainer>
                    <div>{imageName}</div>
                    <RemovePhotoButton color="#aaaaaa" addMargin onClick={this.removePhoto} disabled={disabledFunctions}>{t("Remove photo")}</RemovePhotoButton>
                  </ImageContentContainer>
                </div>
              }
              <ImageInput htmlFor="behavior-challenge-file" showAtBottom={imageName !== ''} disabled={disabledFunctions}>
                {imageName ? t('browse new') : t('browse')}
                <input
                  id="behavior-challenge-file"
                  type="file"
                  name="user"
                  multiple={false}
                  onChange={(e) => this.onChange(e)}
                  onClick={(e) => e.target.files[0] && this.onChange(e)}
                  accept=".jpeg, .png, .jpg"
                  disabled={disabledFunctions}
                />
              </ImageInput>
            </div>
          </FirstPart>
        </ImageContainer>
        <TitleContainer padding="20px 30px">
          <FirstPart>
            <FieldTitle>{t("About the Challenge")}:</FieldTitle>
            <CommonTextArea
              placeholder={t("Write about challenge here")}
              rows="8"
              name="description"
              value={description}
              onChange={this.onChangeInput}
              data-gramm_editor="false"
            />
          </FirstPart>
        </TitleContainer>
        {(createLibrary || editLibrary) || (surveyId && (createChallengeById || isEditChallenge)) ? <ImageContainer>
          <FirstPart>
            <FieldTitle>{t("Add a survey")}:</FieldTitle>
            <RadioButtonContainer justifyContent={"start"}>
              <RadioButton id="1" handler={!(isEditChallenge) && this.toggleRadioButton} value={0} isChecked={selectedOption === 0} label={t('Yes')} challengeLeaderBoard={true} />
              <RadioButton id="2" handler={!(isEditChallenge) && this.toggleRadioButton} value={1} isChecked={selectedOption === 1} label={t('No')} challengeLeaderBoard={true} />
            </RadioButtonContainer>

          </FirstPart>
        </ImageContainer> : null}
        {selectedOption === 0 ? <ImageContainer>
          <FirstPart>
            <ActivityTypeContainer width="100%" noChildWidth={1}>
              <FieldTitle>{t("Select a survey")}:</FieldTitle>
              <ActivityDropdown
                disabled={createChallengeById || isEditChallenge}
                title={<div className="title">{surveyLibraries.length === 0 ? t('No libraries') : surveyType == '' ? t('Select a library') : surveyType}</div>}
                id={"surveyType"}>
                {
                  surveyLibraries && surveyLibraries.length > 0 && surveyLibraries.map((list) => (
                    <CustomMenuItem key={list.id} active={surveyType == list.survey_name} onClick={() => this.onSelectSurveyType('surveyType', list.survey_name, list.id)}>{t(list.survey_name)}</CustomMenuItem>
                  ))
                }
              </ActivityDropdown>
            </ActivityTypeContainer>
          </FirstPart>
        </ImageContainer> : null}

        <TitleContainer padding="20px 30px">
          <FirstPart>
            <FieldTitle>{t("Introduction Video")}</FieldTitle>
            <CommonInputBox
              placeholder={t("Enter video introduction URL here...")}
              name="introductionVideo"
              type="url"
              pattern="https?://.+"
              onChange={this.onChangeInput}
              value={introductionVideo}
              disabled={disabledFunctions}
            />
          </FirstPart>
        </TitleContainer>
        <TitleContainer padding="20px 30px">
          <FirstPart>
            <FieldTitle>{t("Introduction Video Title")}</FieldTitle>
            <CommonInputBox
              placeholder={t("Enter introduction video title here...")}
              name="videoTitle"
              type="url"
              onChange={this.onChangeInput}
              value={videoTitle}
              disabled={disabledFunctions}
            />
          </FirstPart>
        </TitleContainer>
        <ImageContainer>
          <FirstPart>
            {/* <ActivityTypeContainer width="100%" noChildWidth={1}>
              <FieldTitle>{t("Challenge Type")}:</FieldTitle>
              <ActivityDropdown
                title={t(activityType)}
                id="dropdown-basic"
                disabled={isEditChallenge || createChallengeById}
              >
                {activityDropdown.map((item, index) => (
                  <CustomMenuItem
                    eventKey={index}
                    key={index}
                    onSelect={() => this.onSelect('activityType', item)}
                    active={activityType === item}
                  >
                    {t(item)}
                  </CustomMenuItem>
                ))}
              </ActivityDropdown>
            </ActivityTypeContainer> */}
            <CommonDropdown
              type={'challangeDropdown'}
              labelText={t("Challenge Type")}
              title={t(activityType)}
              id="dropdown-basic"
              disabled={isEditChallenge || createChallengeById}
              data={activityDropdown}
              onSelect={this.onSelect}
              onSelectParameter={['activityType']}
              active={activityType}
              itemValue={false}
              activeValue={false}
              displayValue={false}
              t={t}
              isChallengeWellness
            />
          </FirstPart>
        </ImageContainer>
        {activityType === "Running" &&
          <div>
            <ImageContainer>
              <FirstPart>
                <ActivityTypeContainer width="100%" noChildWidth={1}>
                  <FieldTitle>{t("Challenge SubCategories:")}</FieldTitle>
                  <ActivityDropdown
                    title={t(subcategory)}
                    id="dropdown-basic"
                    disabled={isEditChallenge || createChallengeById}
                  >
                    {runningSubCategories.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        onClick={() => this.onChangeSubCategories('subcategory', item.subcategory, item.id)}
                        active={subcategory === item.subcategory}
                      >
                        {t(item.subcategory)}
                      </CustomMenuItem>
                    ))}
                  </ActivityDropdown>
                </ActivityTypeContainer>
              </FirstPart>
            </ImageContainer>
            <ImageContainer>
              <FirstPart>
                <ActivityTypeContainer width="100%" noChildWidth={1} display={1}>
                  <FieldTitle>{t("Level")}:</FieldTitle>
                  <div>
                    {this.getChallengeLevel()}
                  </div>
                </ActivityTypeContainer>
              </FirstPart>
            </ImageContainer>
          </div>
        }

        <ImageContainer>
          <FirstPart>
            {/* <ActivityTypeContainer width="50%" display={1}>
              <FieldTitle>{t("Challenge Length")}:</FieldTitle>
              <ActivityDropdown
                title={`${challengeLength} ${t("Weeks")}`}
                id="dropdown-basic"
                disabled={isEditChallenge || createChallengeById}
              >
                {challengeLengthDropdown.map((item, index) => (
                  <CustomMenuItem
                    eventKey={index}
                    key={index}
                    onSelect={() => this.onSelect('challengeLength', item)}
                    active={challengeLength === item}
                  >
                    {`${t(item)} ${t("Weeks")}`}
                  </CustomMenuItem>
                ))}
              </ActivityDropdown>
            </ActivityTypeContainer> */}
            <CommonDropdown
              type={'challangeDropdown'}
              labelText={t("Challenge Length")}
              title={`${challengeLength} ${t("Weeks")}`}
              id="dropdown-basic"
              disabled={isEditChallenge || createChallengeById || editLibrary}
              data={challengeLengthDropdown}
              onSelect={this.onSelect}
              onSelectParameter={['challengeLength']}
              active={challengeLength}
              itemValue={false}
              activeValue={false}
              displayValue={false}
              valueString={"Weeks"}
              t={t}
              isChallengeWellness
            />
            {!createLibrary && !editLibrary &&
              <DateDashboard>
                <div className="flexable">
                  <span>{t("From")}</span>
                  <InputContainer disabled={disabledFunctions}>
                    <div ref={(node) => openDateTimePicker === 'date' && this.setDatePickerWrapper(node)}>
                      <StyledDateTime
                        open={openDateTimePicker === 'date'}
                        inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true, disabled: disabledFunctions }}
                        dateFormat="MM/DD/YYYY"
                        closeOnSelect={true}
                        closeOnTab={true}
                        timeFormat={false}
                        value={date}
                        onChange={(e) => !disabledFunctions && this.changeDate(e, 'date')}
                        isValidDate={valid}
                        disabled={disabledFunctions}
                      />
                      <DateIconContainer noTop={1} onClick={() => !disabledFunctions && this.showDatePicker('date')} disabled={disabledFunctions}>
                        <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                      </DateIconContainer>
                    </div>
                  </InputContainer>
                </div>
              </DateDashboard>
            }
          </FirstPart>
        </ImageContainer>
        <ImageContainer>
          <FirstPart>
            {/* <ActivityTypeContainer>
              <FieldTitle>{t("Challenge Point Value")}:</FieldTitle>
              <ActivityDropdown
                title={t(challengePointKey)}
                id="dropdown-basic"
              >
                {
                  challengePoints.map((item, index) => (
                    <CustomMenuItem
                      eventKey={index}
                      key={`${item.key}-${index}`}
                      onSelect={() => this.onSelectPoints('challengePointValue', item['value'], item['key'])}
                      active={challengePointValue == item['value']}
                    >
                      {t(item['key'])}
                    </CustomMenuItem>
                  ))
                }
              </ActivityDropdown>
            </ActivityTypeContainer> */}
            <CommonDropdown
              type={'challangeDropdown'}
              labelText={t("Challenge Point Value")}
              title={t(challengePointKey)}
              id="dropdown-basic"
              data={challengePoints}
              onSelect={this.onSelectPoints}
              onSelectParameter={['challengePointValue', 'value', 'key']}
              active={challengePointValue}
              itemValue={true}
              activeValue={true}
              displayValue={true}
              valueIndex={2}
              t={t}
              isChallengeWellness
            />
          </FirstPart>
        </ImageContainer>
        {(createLibrary || editLibrary) ? null :
          <TitleContainer>
            <FieldTitle>{t("Email Notifications: Select email option for this Challenge")} </FieldTitle>
            <LocationContainer>
              {
                emailOptionKeys.map((item, index) => (
                  <InviteAmigo key={index} width={'220px'}>
                    <CustomCheckbox importantBottom={1} disabled={disableEmailOptionStatus}>
                      <input
                        type="checkbox"
                        checked={emailOption[item] === 1}
                        onChange={() => !disableEmailOptionStatus && this.onChangeEmail(item)}
                      />
                      <CheckMark checked={emailOption[item] === 1} disabled={disableEmailOptionStatus} />
                    </CustomCheckbox>
                    <NameContainer onClick={() => !disableEmailOptionStatus && this.onChangeEmail(item)} disabled={disableEmailOptionStatus}>
                      <span>{t("Challenge")}{item == 'launch_challenge_email' ? t(' Creation') : item == 'challenge_reminder_email' ? t(' Reminder') : t(' Completion')} </span>
                    </NameContainer>
                    <div className="showInfoText">{showChallengeEmailNotificationInfo[index]}</div>
                  </InviteAmigo>
                ))
              }
            </LocationContainer>
          </TitleContainer>
        }
        {
          (companiesPermission) && (createChallengeById || isEditChallenge) &&
          <AmigosContainer>
            <TopContainer>
              <FieldTitle>{t("Invite Companies:")}</FieldTitle>
              <ButtonContainer>
                {/* <SaveButton color="#159fc9" onClick={(e) => this.selectAllCompanies(e)} disabled={disabledCompaniesAndLocations}>
                  <i className="fas fa-check" />
                  {
                    companies.length === allCompanies.length ? t('deselect all companies') : t('select all companies')
                  }
                </SaveButton> */}
                <CommonButton styles={{color:"#159fc9"}} onClick={(e) => this.selectAllCompanies(e)}
                  btnType={"rounded"}
                  disabled={disabledCompaniesAndLocations}
                  icon={<i className="fas fa-check" />}
                  title={companies.length === allCompanies.length ? t('deselect all companies') : t('select all companies')}
                />
              </ButtonContainer>
            </TopContainer>
            <InviteContainer bgColor="white">
              <ScrollableContainer>
                {
                  allCompanies.map((company, index) => (
                    <InviteAmigo key={index}>
                      <CustomCheckbox importantBottom={1} disabled={disabledCompaniesAndLocations}>
                        <input
                          type="checkbox"
                          checked={companies.includes(company.id)}
                          onChange={() => !disabledCompaniesAndLocations && this.onCheckboxChange(company.id)}
                        />
                        <CheckMark checked={companies.includes(company.id)} disabled={disabledCompaniesAndLocations} />
                      </CustomCheckbox>
                      <NameContainer onClick={() => !disabledCompaniesAndLocations && this.onCheckboxChange(company.id)}>
                        <span>{t(company.company_name)}</span>
                      </NameContainer>
                    </InviteAmigo>
                  ))
                }
              </ScrollableContainer>
            </InviteContainer>
          </AmigosContainer>
        }
        {
          (createChallengeById || isEditChallenge) && (locationPermission) && (locationDetails && locationDetails.length > 0 || companyDetails['spouse_or_dependent']) ?
            <AmigosContainer>
              <TopContainer>
                <FieldTitle> {t("Company Locations:")}</FieldTitle>
                <ButtonContainer>
                  {locationDetails && locationDetails.length > 0 ?
                    // <SaveButton color="#159fc9" onClick={(e) => this.selectAllLocation(e, locationDetails)} disabled={disabledCompaniesAndLocations}>
                    //   <i className="fas fa-check" />
                    //   {
                    //     location.length === locationDetails.length ? t('deselect all locations') : t('select all locations')
                    //   }
                    // </SaveButton> 
                    <CommonButton styles={{color:"#159fc9"}} onClick={(e) => this.selectAllLocation(e, locationDetails)}
                      btnType={"rounded"}
                      disabled={disabledCompaniesAndLocations}
                      icon={<i className="fas fa-check" />}
                      title={location.length === locationDetails.length ? t('deselect all locations') : t('select all locations')}
                    />
                    : null
                  }
                </ButtonContainer>
              </TopContainer>
              <InviteContainer>
                <ScrollableContainer>
                  <LocationContainer minHeight={locationDetails && locationDetails.length}>
                    {
                      locationDetails && locationDetails.length > 0 ? locationDetails.map((loc, index) => (

                        <InviteAmigo key={index}>
                          <CustomCheckbox importantBottom={1} disabled={disabledCompaniesAndLocations}>
                            <input
                              type="checkbox"
                              checked={location.includes(loc.id)}
                              onChange={() => !disabledCompaniesAndLocations && this.onChangeLocation(loc.id)}
                            />
                            <CheckMark checked={location.includes(loc.id)} disabled={disabledCompaniesAndLocations} />
                          </CustomCheckbox>
                          <NameContainer onClick={() => !disabledCompaniesAndLocations && this.onChangeLocation(loc.id)}>
                            <span>{t(loc.location)}</span>
                          </NameContainer>
                        </InviteAmigo>
                      )) : null
                    }
                  </LocationContainer>
                  {companyDetails['spouse_or_dependent'] ?
                    <div style={{ display: 'flex' }}>
                      {companyInfo?.show_spouse ?<InviteAmigo>
                        <CustomCheckbox importantBottom={1} disabled={disabledCompaniesAndLocations}>
                          <input
                            type="checkbox"
                            checked={isSpouse}
                            onChange={() => !disabledCompaniesAndLocations && this.selectSpouseDependent('isSpouse')}
                          />
                          <CheckMark checked={isSpouse} disabled={disabledCompaniesAndLocations} />
                        </CustomCheckbox>
                        <NameContainer onClick={() => !disabledCompaniesAndLocations && this.selectSpouseDependent('isSpouse')}>
                          <span>{t('Spouse')}</span>
                        </NameContainer>
                      </InviteAmigo>:null}
                      {companyInfo?.show_dependent ?<InviteAmigo>
                        <CustomCheckbox importantBottom={1} disabled={disabledCompaniesAndLocations}>
                          <input
                            type="checkbox"
                            checked={isDependent}
                            onChange={() => !disabledCompaniesAndLocations && this.selectSpouseDependent('isDependent')}
                          />
                          <CheckMark checked={isDependent} disabled={disabledCompaniesAndLocations} />
                        </CustomCheckbox>
                        <NameContainer onClick={() => !disabledCompaniesAndLocations && this.selectSpouseDependent('isDependent')}>
                          <span>{t('Dependent')}</span>
                        </NameContainer>
                      </InviteAmigo>:null}
                      {locationDetails && locationDetails.length === 0 &&
                        <InviteAmigo>
                          <CustomCheckbox importantBottom={1} disabled={disabledCompaniesAndLocations}>
                            <input
                              type="checkbox"
                              checked={isEmployee}
                              onChange={() => !disabledCompaniesAndLocations && this.selectSpouseDependent('isEmployee')}
                            />
                            <CheckMark checked={isEmployee} disabled={disabledCompaniesAndLocations} />
                          </CustomCheckbox>
                          <NameContainer onClick={() => !disabledCompaniesAndLocations && this.selectSpouseDependent('isEmployee')}>
                            <span>{t('Employee')}</span>
                          </NameContainer>
                        </InviteAmigo>
                      }
                    </div> : null
                  }
                </ScrollableContainer>
              </InviteContainer>
            </AmigosContainer> : null
        }
        <WeekBox>
          <div className="wrapHeader" ref={(weekRef) => this.weekRef = weekRef}>
            <WeekHeader>
              {this.getWeekDashboard()}
            </WeekHeader>
          </div>
          <div>
            {behaviorArr.length == challengeLength * 7 &&
              this.getDateDashboard()
            }
          </div>
          {challengeLength - 1 > activeWeek &&
            <ButtonWrapper margin="20px 0px" paddingLeft="40px">
              <ImageInput onClick={() => this.changeActiveWeek(activeWeek + 1)}>
                {t("Next Week")}
              </ImageInput>
            </ButtonWrapper>
          }
        </WeekBox>
        <ButtonWrapper>
          {/* <SaveButton
            disabled={cliked || this.checkDisableFunction()}
            onClick={this.onSubmit}
            title={editLibrary || isEditChallenge ? t("Edit Challenge") : t("Create Challenge")}
          /> */}
          <CommonButton
            btnType={"rounded"}
            disabled={cliked || this.checkDisableFunction()}
            onClick={this.onSubmit}
            title={editLibrary || isEditChallenge ? t("Edit Challenge") : t("Create Challenge")}
            addMargin
            styles={{width:'158px', height:'35px',padding:'0px',marginBottom:'55px'}}
          />
        </ButtonWrapper>
      </div>
    )
  }
}

BehaviorForm.propTypes = {
  history: PropTypes.object.isRequired,
  survivorChallengeData: PropTypes.object,
  saveChallengeData: PropTypes.func,
  allCompanies: PropTypes.array,
  fetchAllCompanies: PropTypes.func.isRequired,
  role: PropTypes.string,
  fetchCompanyForUser: PropTypes.func.isRequired,
  userCompany: PropTypes.object,
  locationDetails: PropTypes.array,
  challengeId: PropTypes.number,
  editChallengeAction: PropTypes.func,
  isEditChallenge: PropTypes.bool,
  createChallenge: PropTypes.func,
  createLibrary: PropTypes.bool,
  createBehaviorLibrary: PropTypes.func,
  behaviorChallengeDetailsById: PropTypes.object,
  createChallengeById: PropTypes.bool,
  getChallengeSubcategory: PropTypes.func,
  runningSubCategories: PropTypes.array,
  isLoading: PropTypes.bool,
  editLibrary: PropTypes.bool,
  editBehaviourChallenge: PropTypes.func,
  userPermissions: PropTypes.array,
  companyDetails: PropTypes.object,
  surveyLibraries: PropTypes.array,
  getSurveyLibraries: PropTypes.func,
  t: PropTypes.func

};

const mapStateToProps = (state) => ({
  allCompanies: state.challenges.allCompanies,
  userCompany: state.profileData.userCompany,
  locationDetails: state.register.locationDetails,
  runningSubCategories: state.challenges.challengeSubcategory,
  isLoading: state.challenges.isLoading,
  userPermissions: state.profileData.userPermissions,
  companyDetails: state.profileData.companyDetails,
  surveyLibraries: state.surveys.surveyLibraries,
});

const mapDispatchToProps = (dispatch) => ({
  saveChallengeData: (data) => dispatch(saveChallengeData(data)),
  fetchAllCompanies: () => dispatch(getAllCompanies()),
  fetchCompanyForUser: () => dispatch(getUserCompanyDetails()),
  editChallengeAction: (data) => dispatch(editChallengeAction(data)),
  getChallengeSubcategory: () => dispatch(getChallengeSubcategory()),
  editBehaviourChallenge: (obj, history, enableButton, path) => dispatch(editBehaviourChallenge(obj, history, enableButton, path)),
  getSurveyLibraries: () => dispatch(getSurveyLibraries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BehaviorForm));
