
import { TopDataGraphMain } from '../commonStyles';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TrackChart from './MultiGaugeGraph';

const HighchartsComponent = ({
  chartTitle = "Lowest Performing Departments",
  totalRecognitions = "102",
  // data=[  { size: 160, strokeWidth: 15, progress: 70, color: "#E4888C", backgroundColor: "#F8C5C7", margin: 5 },
  //   { size: 120, strokeWidth: 15, progress: 50, color: "#FFB347", backgroundColor: "#FFE0B2", margin: 5 },
  //   { size: 80, strokeWidth: 15, progress: 30, color: "#6FA1FF", backgroundColor: "#D6E5FF", margin: 5 },]
  data = [
    { category: "Finance", value: 90, color: '#92A4DA' },
    { category: "Customer Success", value: 60, color: '#E3B78E' },
    { category: "Sales & Marketing", value: 50, color: '#E4888C' }
  ],
  chartHeight = 260,
  chartWidth = 260,
  id="container"
}) => {
  useEffect(() => {}, [data]);
  // useEffect(() => {
  //   Highcharts.chart(id || "container", {
  //     // colors: data.flatMap((item) => item.color), 
  //     chart: {
  //       type: 'column',
  //       inverted: true,
  //       polar: true,
  //       height: chartHeight,
  //       width: chartWidth
  //     },
  //     title: {
  //       text: null
  //     },
  //     subtitle: {
  //       text: null
  //     },
  //     tooltip: {
  //       outside: true
  //     },
  //     pane: {
  //       size: '100%',
  //       innerSize: '20%',
  //       endAngle: 360
  //     },
  //     xAxis: {
  //       tickInterval: 1,
  //       labels: {
  //         align: 'right',
  //         useHTML: true,
  //         allowOverlap: true,
  //         step: 1,
  //         y: 3,
  //         style: {
  //           fontSize: '13px'
  //         }
  //       },
  //       lineWidth: 0,
  //       gridLineWidth: 0,
  //       // categories: data.map((item) => item.category)
  //       categories: ['', '', '']
  //     },
  //     yAxis: {
  //       lineWidth: 0,
  //       tickInterval: 0,
  //       reversedStacks: false,
  //       endOnTick: false,
  //       showLastLabel: false,
  //       showFirstLabel: false,
  //       gridLineWidth: 0
  //     },
  //     plotOptions: {
  //       column: {
  //         stacking: 'normal',
  //         borderWidth: 5,
  //         pointPadding: 0,
  //         groupPadding: 0.15,
  //         borderRadius: 12
  //       }
  //     },
  //     series: data.map((item, index) => ({
  //       name: item.category,
  //       data: item.value,
  //       showInLegend: false,
  //       color: item.color[index]
 
  //     })),
  //     credits: {
  //       enabled: false
  //     }
  //   });
 
 
  // }, [data, chartHeight, chartWidth]);

  return (
    <TopDataGraphMain>
      <div className="heading">{chartTitle}</div>
      <div className="values-data">
        <div className="value">{totalRecognitions}</div>
        <div className="text">Total Recognitions</div>
      </div>
      <div className="low-performing-section">
        <div className="text-changes">
          {data.map((item, index) => (
            <div className="name" key={index}>
              <div
                className="circle"
                style={{ background: item.color }}
              ></div>
              <div className="text">{item.category}</div>
            </div>
          ))}
        </div>
        {/* <Container id={id || "container"}/> */}
        {/* <div style={{marginRight: "33px",marginTop: "40px"}}> 
          <CircularProgressBar segments={data} ringSpacing={1}/>
        </div> */}
        <TrackChart id={id || "container"} chartHeight={chartHeight} chartWidth={chartWidth} data={data}/>
      </div>
    </TopDataGraphMain>
  );
};

HighchartsComponent.propTypes = {
  chartTitle: PropTypes.string,
  totalRecognitions: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      value: PropTypes.arrayOf(PropTypes.number).isRequired,
      color: PropTypes.string.isRequired
    })
  ),
  chartHeight: PropTypes.number,
  chartWidth: PropTypes.number,
  id: PropTypes.string
};

export default HighchartsComponent;
