import React, {Component} from 'react';
import {checkImage, checkMultipleImage, fetchApi, getOrientation, resetOrientation} from "../../utils/methods";
import {toast} from "react-toastify";
import {imageErrorMessage, ImageUrl, WellnessLevels} from "../../utils/constants";
import Waiting from "../Waiting";
import {AmigosContainer, ButtonContainer, CheckMark, CustomCheckbox, CustomMenuItem, FieldTitle, FormContainer, ImageContainer, ImageContentContainer, ImageInput, InviteAmigo, InviteContainer, Layout, LocationContainer, NameContainer, PageTitle, RemovePhotoButton, SaveButton, ScrollableContainer, StyledInput, TitleContainer, TopContainer} from "../CreateEvent/styles";
import {
  AddButton,
  CustomInputIngredients,
  FieldTitleIngredients,
  IngredientButtonContainer,
  MultipleImageContainer,
  ShowIngredientContent,
  ShowDirectionContent,
  TimeContainer,
  ColonSeparator,
  RadioButtonContainer
} from "../CreateRecipe/styles";
import {ActivityDropdown, ActivityTypeContainer} from "../CreateChallengeForm/styles";
// import {mealTypes, skillLevel} from "../../../mockData.json";
import PropTypes from "prop-types";
import {editRecipe, getRecipeCategories} from "../../redux/actions";
import {connect} from "react-redux";
import RadioButton from "../CustomRadioButton";
import {FirstPart} from "../BehaviorChallenge/styles";
import { withTranslation } from 'react-i18next';
import CommonInputBox from '../common/CommonInputBox/CommonInputBox';
import CommonTextArea from '../common/CommonTextArea/CommonTextArea';
import CommonInputTime from '../common/CommonInputTime/CommonInputTime';
import { GetQuestionByCategoryId, GetWellnessGoals } from '../../redux/constants/apiConstants';
let fileArray = [];
class EditRecipe extends Component{
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      imgSrc: '',
      imageUpdated: 0,
      imageName: '',
      level:'',
      Blurb: '',
      mealType: '',
      cookTimeHours: "00",
      cookTimeMins: "00",
      prepTimeHours: "00",
      prepTimeMins: "00",
      video:'',
      recipeCategorieslist: [],
      servings: '',
      showContent:'',
      ingredientsContent: '',
      stepCount: 0,
      Ingredients: [],
      directions: [],
      imageFiles:[],
      editedContent: [],
      editSteps:[],
      description: '',
      recipeId: '',
      storedImageId: [],
      selectedOption: 0,
      selectedThemeOption: 0,
      themeName: '',
      themeMonth: '',
      themeId: null,
      wellnessGoals:[],
      wellness_goal:null,
      wbaQuestions:[],
      showWbaQue:false,
      question:null,
      levels:null,
    };
  }

  mealTypes= [
    "Breakfast",
    "Lunch",
    "Dinner",
    "Snack",
    "Dessert"
  ]

  skillLevel= [
    "Easy - Sous Chef",
    "Medium - Head Chef",
    "Hard - Executive Chef"
  ]

  componentDidMount() {
    const{getRecipeCategories} = this.props;
    getRecipeCategories();
    this.navigateToRecipes();
  }

  onDropDownSelect = (key, item) => {
    this.setState({
      [key]: item
    },()=>{key === "wellness_goal" && this.getWBAQuestions(item.id)});
  };

  storeId = (recipeImages) => {
    let arr = [];
    recipeImages.map((data) => {
      arr.push(data['id']);
    });
    return arr;
  };

  setCategoryId = (categoryTags) => {
    let arr = [];
    categoryTags.map((data)=>{
      arr.push(data.id);
    });
    return arr;
  };

  setDirections = (directions) => {
    directions.map((data)=>{
      data['step'] = data['steps']; // Assign new key
      delete data['steps'];
    });
    return directions;
  };

  setIngredient = (ingredients) => {
    let arr = [];
    ingredients.map((data)=>{
      arr.push(data['ingredient_name']);
    });
    return arr;
  };

  navigateToRecipes = () => {
    const { location, history, editRecipeDetails:{recipe_details: recipeDetails} } = this.props;
    const { pathname } = location;
    const arr = pathname.split('/');
    if(!recipeDetails) {
      history.push(`/education/recipes/${arr[arr.length-2]}`);
    } else{
      const{editRecipeDetails:{recipe_details:{id, cook_time_option: cookTimeOption, name, recipe_image: image, title, description, servings, video_url: videoUrl,  prep_time: prepTime, cook_time: cookTime, meal_type: mealType, level},category_tags: categoryTags,  ingredients, directions, recipe_images: recipeImages, theme_id: themeId}, /* themes, */ themesHeaderAll} = this.props;
      // let findThemeIndex = themes.findIndex((v) => v.id === themeId);
      let findThemeIndex = themesHeaderAll.findIndex((v) => v.id === themeId);
      let preptime = this.getTime(prepTime);
      let cooktime = !cookTimeOption && this.getTime(cookTime);
      window.console.log("recipeDetails",recipeDetails)
      this.setState({
        title: name,
        imgSrc: image || '',
        imageName: image ? image.split("/")[1] : '',
        level:level,
        Blurb: title,
        mealType: mealType,
        cookTimeHours: !cookTimeOption && cooktime && cooktime.split(':')[0],
        cookTimeMins: !cookTimeOption && cooktime && cooktime.split(':')[1],
        prepTimeHours: preptime && preptime.split(':')[0],
        prepTimeMins: preptime && preptime.split(':')[1],
        prepTime: `${this.getTime(prepTime)}`,
        cookTime: !cookTimeOption && `${this.getTime(cookTime)}`,
        video:videoUrl,
        recipeCategorieslist: this.setCategoryId(categoryTags),
        servings: servings,
        Ingredients: this.setIngredient(ingredients),
        directions: this.setDirections(directions),
        imageFiles:recipeImages,
        description: description,
        recipeId: id,
        storedImageId: this.storeId(recipeImages),
        stepCount: directions && directions.length,
        selectedOption: cookTimeOption,
        themeId: themeId,
        // themeMonth: findThemeIndex !== -1 ? themes[findThemeIndex].month : '',
        // themeName: findThemeIndex !== -1 ? themes[findThemeIndex].theme_text : '',
        themeMonth: findThemeIndex !== -1 ? themesHeaderAll[findThemeIndex].month : '',
        themeName: findThemeIndex !== -1 ? themesHeaderAll[findThemeIndex].theme_text : '',
        selectedThemeOption: findThemeIndex !== -1 ? 1 : 0,
        selectedQuestionId:recipeDetails.question_id,
        selectedWellnessGoal:recipeDetails.wellness_goal_id,
        selectedLevel:recipeDetails.levels
      },()=>{    this.getWellnessGoals();})
    }
  };

  onChangeInput = (e) => {
    if(e.target.value !== ' ') {
      if(e.target.name === 'servings'){
        this.setState({
          [e.target.name]: e.target.value.replace(/[&\\#,+()$~%@^!_.'":*?<>{}]/g, "")
        });
      }
      else if(e.target.name === 'cookTimeMins' || e.target.name === 'cookTimeHours' ||  e.target.name === 'prepTimeMins' || e.target.name === 'prepTimeHours'){
        let value = (e.target.name === 'cookTimeMins' || e.target.name === 'prepTimeMins') ? Math.min(e.target.value , 59) :  Math.min(e.target.value , 23);
        this.setState({
          [e.target.name]: value,
        });
      } else
      {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  onChange = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if (e.target.files[0]) {
      if (checkImage(fileArr)) {
        if ((file?.size / 1000000) <= 20) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document.getElementById('recipe-upload-file').value.split("\\");
          reader.onloadend = () => {
            this.setState({
              imgSrc: reader.result,
              imageName: array[array.length - 1],
              imageUpdated: 1
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  imgSrc: baseImage
                });
              });
            });
          };
        } else {
          toast.error('Please select image file less than 20MB');
          document.getElementById('recipe-upload-file').value = '';
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById('recipe-upload-file').value = '';
      }
    }
  };

  removePhoto = () => {
    document.getElementById('recipe-upload-file').value = '';
    this.setState({
      imgSrc: '',
      imageName: '',
    });
  };

  onSelectTheme = (value) => {
    this.setState({
      themeName: value.theme_text,
      themeMonth: value.month,
      themeId: value.id,
    });
  };

  removeRecipeImages = (index) => {
    const{imageFiles} = this.state;
    if(imageFiles.length > 0){
      imageFiles.splice(index,1);
    }
    this.setState({
      imageFiles: imageFiles
    });
  };

  onSelectTypes = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChangeCategory = (id) => {
    const { recipeCategorieslist } = this.state;
    if(recipeCategorieslist.includes(id)) {
      let index = recipeCategorieslist.findIndex((item) => item === id);
      if(index > -1) {
        recipeCategorieslist.splice(index, 1);
      }
    } else {
      recipeCategorieslist.push(id);
    }
    this.setState({
      recipeCategorieslist: recipeCategorieslist
    })
  };

  selectAllCategories = (e, recipeCategories) => {
    e.preventDefault();
    const { recipeCategorieslist } = this.state;
    if(recipeCategorieslist.length === recipeCategories.length) {
      this.setState({
        recipeCategorieslist: []
      })
    }
    else {
      let arr = [];
      recipeCategories && recipeCategories.map((data) => {
        arr.push(data.id);
      });
      this.setState({
        recipeCategorieslist: arr
      })
    }
  };

  onChangeDirections = (e) => {
    if(e.target.value !== ' ') {
      this.setState({showContent: e.target.value})
    } else{
      this.setState({showContent: e.target.value.replace(/^\s\s*/, '').replace(/\s\s*$/, '')})
    }
  };

  disableEdit = (index) => {
    const{directions, editSteps} = this.state;
    if(directions && (directions[index]['description'] !== '')) {
      directions.map((data, index) => {
        editSteps[index] = false;
      });
      this.setState({editSteps: editSteps, editedContent: []})
    }
  };

  onEditDirections = (e, index) => {
    const{directions, editedContent} = this.state;
    if(e.target.value !== ' ') {
      directions[index]['description'] = e.target.value;
      editedContent[index] = e.target.value;
      this.setState({editedContent: editedContent, directions: directions})
    }
  };

  editDirection = (directionIndex) => {
    const{editedContent, directions, editSteps} = this.state;
    directions.map((data, index)=>{
      editedContent[index] = data['description'];
      editSteps[index] = false;
    });
    editSteps[directionIndex] = true;
    this.setState({ editSteps: editSteps, editedContent: editedContent});
  };

  storeDirections = () => {
    const{showContent, directions, stepCount} = this.state;
    let arr = [...directions];
    let obj = {};
    obj['step'] = stepCount+1;
    obj['description'] = showContent.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
    arr.push(obj);
    this.setState({directions: arr, showContent: '', stepCount: stepCount+1});
  };

  removeDirections = (directionId) => {
    const{directions} = this.state;
    if(directions.length > 0) {
      let index = directions.findIndex((item) => item.step === directionId);
      if(index > -1) {
        directions.splice(index, 1);
      }
    }
    let directionsArray = directions;
    directions.map((data, index) => {
      directionsArray[index]['step'] = index+1;
    });
    this.setState({directions: directionsArray, stepCount: directionsArray.length});
  };

  onChangeIngredients = (e) => {
    if(e.target.value !== ' ') {
      this.setState({ingredientsContent: e.target.value})
    }
  };

  storeIngredients = () => {
    const{ingredientsContent, Ingredients} = this.state;
    let arr = [];
    arr = [...Ingredients];
    arr.push(ingredientsContent);
    this.setState({Ingredients: arr, ingredientsContent: ''});
  };

  removeIngredients = (index) => {
    const{Ingredients} = this.state;
    Ingredients.splice(index, 1);
    this.setState({Ingredients: Ingredients});
  };

  onChangeMultiple = (e, click) => {
    const{imageFiles} = this.state;
    let fileArr = [...e.target.files];
    if ((this.state.imageFiles.length + fileArr.length) < 7 && fileArr.length < 7) {
      fileArr.map((imageFile) => {
        if (checkMultipleImage(imageFile)) {
          if ((imageFile?.size / 1000000) <= 20) {
            let reader = new FileReader();
            reader.readAsDataURL(imageFile);
            const fileName = imageFile.name;
            let obj = {imageName: '', image: '', imageUpdated: 0};
            reader.onloadend = () => {
              getOrientation(imageFile, (or) => {
                resetOrientation([reader.result], or, (baseImage) => {
                  let existIndex = imageFiles.findIndex((data) => data['imageName'] == fileName);
                  if(existIndex == -1) {
                    obj['imageName'] = fileName;
                    obj['image'] = baseImage;
                    obj['imageUpdated'] = 1;
                    fileArray.push(obj);
                    let dubImageFiles = [...imageFiles, ...fileArray];
                    this.setState({imageFiles: dubImageFiles});
                  }
                });
              });
            };
          } else {
            toast.error('Please select image file less than 20MB');
            document.getElementById('multiple-recipe-upload-file').value = '';
          }
        } else {
          toast.error(imageErrorMessage);
          document.getElementById('multiple-recipe-upload-file').value = '';
        }
      });
      fileArray = [];
    } else {
      if(click) {
        toast.error('Please select maximum images of 6');
      }
    }
  };

  getminutes = (mins, hour) => {
    let hours = parseInt(hour,10) || 0;
    let minutes = parseInt(mins,10);
    let totalTime = 0;
    if(hours === 0 && minutes > 0){
      totalTime = minutes;
    }else {
      totalTime = (hours * 60) + minutes;
    }
    return totalTime;
  };

  toggleRadioButton = (e) => {
    this.setState({selectedOption: parseInt(e.target.value,10)}, ()=>{});
  };
  toggleThemeRadioButton = (e) => {
    this.setState({selectedThemeOption: parseInt(e.target.value,10)}, ()=>{});
  };

  getTime = (prepTime) => {
    let hours = 0;
    let min;
    let totalTime = '';
    if(prepTime > 59){
      hours =  (prepTime / 60).toString().split(".")[0];
      min = prepTime % 60;
      totalTime = `${hours < 10 ? `0${hours}` : hours}:${min < 10 ? `0${min}` : min}`;
    } else{
      min = prepTime;
      totalTime = `00:${min < 10 ? `0${min}` : min}`;
    }
    return totalTime;
  };

  editRecipeDetails = (e) => {
    e.preventDefault();
    const {imageUpdated, storedImageId, recipeId, title, imgSrc, Blurb, servings, mealType, level, description, video, recipeCategorieslist, Ingredients, directions, imageFiles, cookTimeMins, cookTimeHours, prepTimeHours, prepTimeMins, selectedOption, themeId, wellness_goal, question, levels} = this.state;
    const{editRecipe, history} = this.props;

    let obj = {};
    let dubImageFiles = [];
    imageFiles && imageFiles.length > 0 ? imageFiles.map((data, index)=>{
      let obj = {};
      obj['image'] = data['image'];
      obj['imageUpdated'] = data['imageUpdated'] || 0;
      obj['id'] = storedImageId[index] || "";
      dubImageFiles.push(obj)
    }) : [];
    obj['name'] = title;
    obj['image'] = imgSrc;
    obj['title'] = Blurb;
    obj['description'] = description;
    obj['servings'] = servings;
    obj['meal_type'] = mealType;
    obj['level'] = level;
    obj['prep_time'] = this.getminutes(prepTimeMins, prepTimeHours);
    obj['cook_time'] = !selectedOption ? this.getminutes(cookTimeMins, cookTimeHours) : 'Refrigerate overnight or at least 4 hours';
    obj['video_url'] = video;
    obj['category_tags'] = recipeCategorieslist;
    obj['ingredients'] = Ingredients;
    obj['directions'] = directions;
    obj['recipe_images'] = dubImageFiles;
    obj['recipe_id'] = recipeId;
    obj['imageUpdated'] = imageUpdated;
    obj['cook_time_option'] = selectedOption;
    obj['theme_id'] = themeId;
    obj['wellness_goal_id'] = wellness_goal && wellness_goal.id ? wellness_goal.id : null;
    obj['question_id'] = question && question.id ? question.id : null;
    obj['levels'] = levels && levels.level ? levels.level : null;
    editRecipe(obj, history);
  };


  getWellnessGoals = async()=>{
    try {
      const res = await fetchApi(`${GetWellnessGoals}`, "GET",null, {});
      if(res){
        this.setState({wellnessGoals:res.data});

        
        if(this.state.selectedWellnessGoal){
          const selectedWellnessGoal = res.data.find((wellnessGoal) => wellnessGoal.id === this.state.selectedWellnessGoal);
          this.setState({wellnessGoals:res.data, wellness_goal:selectedWellnessGoal});

        }else{
          this.setState({wellnessGoals:res.data});

        }

        if(this.state.selectedQuestionId){
          this.getWBAQuestions(this.state.selectedWellnessGoal);
        }


        if(this.state.selectedLevel){
          const selectedLevel = WellnessLevels.find((wellnessLevel) => wellnessLevel.level === this.state.selectedLevel);
          this.setState({levels:selectedLevel})

        }

      }
      if (res.message) {
        toast.error(res.message);
      }
      
    }catch(error){
      toast.error(error.message);
    }
  }

  getWBAQuestions = async(categoryId)=>{
    try {
      const res = await fetchApi(`${GetQuestionByCategoryId + "/" + categoryId}`, "GET",null, {});
      window.console.log("wba que res.data",res.data)
      if(res.data.length > 0){
        this.setState({wbaQuestions:res.data, showWbaQue:true})
        if(this.state.selectedQuestionId){
          const selectedQuestion = res.data.find((question) => question.id === this.state.selectedQuestionId);
          this.setState({wbaQuestions:res.data, showWbaQue:true, question:selectedQuestion})

        }else{
          this.setState({wbaQuestions:res.data, showWbaQue:true})
        }

      }else{
        this.setState({wbaQuestions:[], showWbaQue:false})

      }
      if (res.message) {
        toast.error(res.message);
      }
      
    }catch(error){
      toast.error(error.message);
    }
  }

  render() {
    const{title, imgSrc, imageName, Blurb, servings, mealType, level, video, recipeCategorieslist, Ingredients, ingredientsContent, showContent, directions, imageFiles, editSteps, editedContent, description, imageUpdated, cookTimeMins, cookTimeHours, prepTimeHours, prepTimeMins, selectedOption, selectedThemeOption, themeName, themeMonth} = this.state;
    const{recipeCategories, recipeLoading, /* themes, */ themesHeaderAll, t, selectedRecipeCategories} = this.props;
    if(!recipeCategories || recipeLoading){
      return <Waiting />;
    }
    
    return (
      <Layout>
        <PageTitle>{t("Edit Recipe")}</PageTitle>
        <div style={{float: 'left', width: '100%'}}>
          <FormContainer onSubmit={this.editRecipeDetails}>
            <TitleContainer>
              <FieldTitle>{t("Recipe Title")}<span style={{color: "red"}}>*</span> :</FieldTitle>
              <StyledInput
                placeholder={t("Type title here...")}
                name="title"
                onChange={this.onChangeInput}
                value={title}
                maxLength="100"
                type="text"
              />
            </TitleContainer>
            <ImageContainer>
              <FieldTitle>{t("Main Recipe Picture")}<span style={{color: "red"}}>*</span> :</FieldTitle>
              <div>
                { imageName &&
                <div>
                  <img src={imageUpdated ? imgSrc : `${ImageUrl}/${imgSrc}`}/>
                  <ImageContentContainer>
                    <div>{imageName}</div>
                    <RemovePhotoButton color="#aaaaaa" type="button" onClick={this.removePhoto}>{t("Remove photo")}</RemovePhotoButton>
                  </ImageContentContainer>
                </div>
                }
                <ImageInput htmlFor="recipe-upload-file" showAtBottom={imageName !== ''}>
                  {imageName ? t('browse new') : t('browse')}
                  <input
                    id="recipe-upload-file"
                    type="file"
                    name="user"
                    accept=".jpeg, .png, .jpg"
                    multiple={false}
                    onChange={(e) => this.onChange(e)}
                    onClick={(e) => e.target.files[0] && this.onChange(e)}
                  />
                </ImageInput>
              </div>
            </ImageContainer>
            <TitleContainer>
              <FieldTitle>{t("Amped with")}<span style={{color: "red"}}>*</span> :</FieldTitle>
              <StyledInput
                placeholder="Type Amped with here..."
                name="Blurb"
                onChange={this.onChangeInput}
                value={Blurb}
                maxLength="100"
                type="text"
              />
            </TitleContainer>
            <TitleContainer>
              <FieldTitle>{t("Servings")}<span style={{color: "red"}}>*</span> :</FieldTitle>
              <StyledInput
                placeholder={t("Type servings here...")}
                name="servings"
                onChange={this.onChangeInput}
                value={servings}
                maxLength="100"
                type="text"
              />
            </TitleContainer>
            <TitleContainer>
              <FieldTitle>{t("Prep Time")}<span style={{color: "red"}}>*</span> : </FieldTitle>
              <TimeContainer>
                <CommonInputTime
                  placeholder="00"
                  name="prepTimeHours"
                  onChange={this.onChangeInput}
                  value={prepTimeHours}
                  type="number"
                  min="0"
                  max="23"
                  border={0}
                  pattern="\d{2}"
                />
                {/* <CommonTextArea
                  placeholder="00"
                  name="prepTimeHours"
                  onChange={this.onChangeInput}
                  value={prepTimeHours}
                  type="number"
                  min="0"
                  max="23"
                  border={0}
                  pattern="\d{2}"
                /> */}
                <ColonSeparator>:</ColonSeparator>
                <CommonInputTime
                  placeholder="00"
                  name="prepTimeMins"
                  onChange={this.onChangeInput}
                  value={prepTimeMins}
                  type="number"
                  min="0"
                  max="59"
                  border={1}
                  pattern="\d{2}"
                />
              </TimeContainer>
            </TitleContainer>
            <TitleContainer>
              <FieldTitle>{t("Cook Time")}<span style={{color: "red"}}>*</span> :</FieldTitle>
              <RadioButtonContainer>
                <RadioButton id="1" handler={this.toggleRadioButton} value={0} isChecked={selectedOption == 0} label={t('Input Cook Time')} challengeLeaderBoard={true}/>
                <RadioButton id="2" handler={this.toggleRadioButton} value={1} isChecked={selectedOption == 1} label={t('Refrigerate Overnight or at least 4 hours')} challengeLeaderBoard={true}/>
              </RadioButtonContainer>
              {
                selectedOption === 0 ? <TimeContainer>
                  <CommonInputTime
                    placeholder="00"
                    name="cookTimeHours"
                    onChange={this.onChangeInput}
                    value={cookTimeHours}
                    type="number"
                    min="0"
                    max="23"
                    border={0}
                    pattern="\d{2}"
                  />
                  <ColonSeparator>:</ColonSeparator>
                  <CommonInputTime
                    placeholder="00"
                    name="cookTimeMins"
                    onChange={this.onChangeInput}
                    value={cookTimeMins}
                    type="number"
                    min="0"
                    max="59"
                    border={1}
                    pattern="\d{2}"
                  />
                </TimeContainer> : null
              }
            </TitleContainer>
            <TitleContainer>
              <ActivityTypeContainer>
                <FieldTitle>{t("Meal Type")}<span style={{color: "red"}}>*</span> :</FieldTitle>
                <ActivityDropdown
                  title={mealType}
                  id="dropdown-meal"
                  onSelect={(index,/*item*/) =>{this.onSelectTypes('mealType', this.mealTypes[index]);}}
                >
                  {
                    this.mealTypes.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        onSelect={() => this.onSelectTypes('mealType', item)}
                        active={mealType === item}
                      >
                        {item}
                      </CustomMenuItem>
                    ))
                  }
                </ActivityDropdown>
              </ActivityTypeContainer>
            </TitleContainer>
            <TitleContainer>
              <ActivityTypeContainer>
                <FieldTitle>{t("Skill Level")}<span style={{color: "red"}}>*</span> :</FieldTitle>
                <ActivityDropdown
                  title={level}
                  id="dropdown-level"
                >
                  {
                    this.skillLevel.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        /* onSelect */ onClick={() => this.onSelectTypes('level', item)}
                        active={level === item}
                      >
                        {item}
                      </CustomMenuItem>
                    ))
                  }
                </ActivityDropdown>
              </ActivityTypeContainer>
            </TitleContainer>

            <TitleContainer key={"wellness_goal"}>
              <FieldTitle>{"Wellness Goal"}</FieldTitle>
              <ActivityDropdown
                title={this.state["wellness_goal"] && this.state["wellness_goal"].name && this.state["wellness_goal"].name || "Select Wellness Goal"}
                id={`dropdown-${"wellness_goal"}`}
              >
                {
                  this.state.wellnessGoals?.map((item, index) => (
                    <CustomMenuItem
                      eventKey={index}
                      key={index}
                      /* onSelect */ onClick={() => this.onDropDownSelect("wellness_goal", item)}
                      active={this.state["wellness_goal"] && this.state["wellness_goal"].id && this.state["wellness_goal"].id === item.id}
                    >
                      {item.name}
                    </CustomMenuItem>
                  ))
                }
              </ActivityDropdown>
            </TitleContainer>

            {this.state.showWbaQue && <TitleContainer key={"sub_level"}>
              <FieldTitle>{t("Sub Level (WBA Questions)")}</FieldTitle>
              <ActivityDropdown
                title={this.state["question"] && this.state["question"].question && this.state["question"].question || "Select Journey Category"}
                id={`dropdown-${"sub_level"}`}
              >
                {
                  this.state.wbaQuestions.map((item, index) => (
                    <CustomMenuItem
                      eventKey={index}
                      key={index}
                      /* onSelect */ onClick={() => this.onDropDownSelect("question", item)}
                      active={this.state["question"] && this.state["question"].id && this.state["question"].id === item.id}
                    >
                      {item.question}
                    </CustomMenuItem>
                  ))
                }
              </ActivityDropdown>
            </TitleContainer>}

            <TitleContainer key={"level"}>
              <FieldTitle>{t("Level")}</FieldTitle>
              <ActivityDropdown
                title={this.state["levels"] && this.state["levels"].level && this.state["levels"].level || "Select Level"}
                id={`dropdown-${"level"}`}
              >
                {
                  WellnessLevels.map((item, index) => (
                    <CustomMenuItem
                      eventKey={index}
                      key={index}
                      /* onSelect */ onClick={() => this.onDropDownSelect("levels", item)}
                      active={this.state["levels"] && this.state["levels"].id && this.state["levels"].id === item.id}
                    >
                      {item.level}
                    </CustomMenuItem>
                  ))
                }
              </ActivityDropdown>
            </TitleContainer>

            <AmigosContainer>
              <TopContainer>
                <FieldTitle>{t("Category Tags – select all the apply")}<span style={{color: "red"}}>*</span> :</FieldTitle>
                {selectedRecipeCategories && selectedRecipeCategories.length > 0 &&
                <ButtonContainer>
                  <SaveButton color="#159fc9" onClick={(e) => this.selectAllCategories(e, selectedRecipeCategories)}>
                    <i className="fas fa-check"/>
                    {
                      recipeCategorieslist.length === selectedRecipeCategories.length ? t('deselect all categories') : t('select all categories')
                    }
                  </SaveButton>
                </ButtonContainer>
                }
              </TopContainer>
              <InviteContainer>
                <ScrollableContainer>
                  <LocationContainer minHeight={selectedRecipeCategories && selectedRecipeCategories.length}>
                    {
                      selectedRecipeCategories && selectedRecipeCategories.length > 0 ? selectedRecipeCategories.map((tag, index) => (

                        <InviteAmigo key={index}>
                          <CustomCheckbox>
                            <input
                              type="checkbox"
                              checked={recipeCategorieslist.includes(tag['id'])}
                              onChange={() => this.onChangeCategory(tag['id'])}
                            />
                            <CheckMark checked={recipeCategorieslist.includes(tag['id'])} />
                          </CustomCheckbox>
                          <NameContainer onClick={() => this.onChangeCategory(tag['id'])}>
                            <span>{tag['recipe_tag']}</span>
                          </NameContainer>
                        </InviteAmigo>

                      )) : null
                    }
                  </LocationContainer>
                </ScrollableContainer>
              </InviteContainer>
            </AmigosContainer>

            <TitleContainer>
              <FieldTitle marginTop="20px">{t("Recipe Video Link")}<span style={{color: "red"}}>*</span> :</FieldTitle>
              <CommonInputBox 
                placeholder="http://"
                name="video"
                type="url"
                pattern="https?://.+"
                onChange={this.onChangeInput}
                value={video}
              />
            </TitleContainer>
            <TitleContainer>
              <FieldTitleIngredients>{t("Ingredients")}<span style={{color: "red"}}>*</span> :</FieldTitleIngredients>
              <CustomInputIngredients
                placeholder={t("Type Ingredients here...")}
                name="Ingredients"
                onChange={this.onChangeIngredients }
                value={ingredientsContent}
                type="text"
              />
              { ingredientsContent.replace(/^\s\s*/, '').replace(/\s\s*$/, '') && <IngredientButtonContainer><AddButton onClick={this.storeIngredients}>{"Add Ingredients"}</AddButton></IngredientButtonContainer>}
              {Ingredients.map((data,index) => (
                <ShowIngredientContent key={index}>
                  <div>
                    <div>{data}</div>
                    <i className="fa fa-times" aria-hidden="true" onClick={() => this.removeIngredients(index)}/>
                  </div>
                </ShowIngredientContent>
              ))}
            </TitleContainer>
            <TitleContainer>
              <FieldTitleIngredients>{t("Directions")}<span style={{color: "red"}}>*</span> :</FieldTitleIngredients>
              <CustomInputIngredients
                placeholder={t("Type Directions here...")}
                name="Directions"
                onChange={this.onChangeDirections}
                value={showContent}
                type="text"
              />
              { showContent.replace(/^\s\s*/, '').replace(/\s\s*$/, '') && <IngredientButtonContainer><AddButton onClick={this.storeDirections}>{t("Add Direction")}</AddButton></IngredientButtonContainer>}
              {directions.map((data, index) => (
                <ShowDirectionContent key={index}>
                  <div>{`step ${data['step']}`}</div>
                  {editSteps[index] ?
                    <div>
                      <CustomInputIngredients
                        placeholder="Type Directions here..."
                        name="editDirections"
                        onChange={(e)=>this.onEditDirections(e, index)}
                        value={editedContent[index]}
                        onMouseLeave={() => this.disableEdit(index)}
                        type="text"
                      />
                    </div>
                    :
                    <div>
                      <div onClick={()=>this.editDirection(index)}>{data['description']}</div>
                      <i className="fa fa-times" aria-hidden="true" onClick={() => this.removeDirections(data['step'])}/>
                    </div>
                  }
                </ShowDirectionContent>
              ))}
            </TitleContainer>
            <ImageContainer>
              <FieldTitle>{t("Upload Other Images")} :</FieldTitle>
              <div>
                { imageFiles && imageFiles.length > 0 ?
                  imageFiles.map((data, index) => (
                    <MultipleImageContainer key={index}>
                      <img src={data['imageUpdated'] ? data['image'] : `${ImageUrl}/${data['image']}`}/>
                      <ImageContentContainer>
                        <div>{data.imageName || data.image}</div>
                        <RemovePhotoButton color="#aaaaaa" type="button" onClick={() => this.removeRecipeImages(index)}>{t("Remove photo")}</RemovePhotoButton>
                      </ImageContentContainer>
                    </MultipleImageContainer>
                  )) : null
                }
                {imageFiles.length < 6 ? <ImageInput htmlFor="multiple-recipe-upload-file">
                  {imageFiles.length > 0 ? t('browse new') : t('browse')}
                  <input
                    id="multiple-recipe-upload-file"
                    type="file"
                    name="images"
                    accept=".jpeg, .png, .jpg"
                    multiple={true}
                    onChange={(e) => this.onChangeMultiple(e,1)}
                    onClick={(e) => e.target.files[0] && this.onChangeMultiple(e,0)}
                  />
                </ImageInput> : null
                }
              </div>
            </ImageContainer>
            <TitleContainer>
              <FieldTitle>{t("Recipe Description")}<span style={{color: "red"}}>*</span> :</FieldTitle>
              <CommonTextArea
                placeholder={t("Write your description here...")}
                rows="8"
                name="description"
                value={description}
                onChange={this.onChangeInput}
                data-gramm_editor="false"
                type="text"
              />
            </TitleContainer>
            <ImageContainer>
              <FirstPart>
                <FieldTitle>{t("Add a Theme")}:</FieldTitle>
                <RadioButtonContainer justifyContent={"start"}>
                  <RadioButton id="11" handler={this.toggleThemeRadioButton} value={1} isChecked={selectedThemeOption == 1} label={t('Yes')} challengeLeaderBoard={true}/>
                  <RadioButton id="12" handler={this.toggleThemeRadioButton} value={0} isChecked={selectedThemeOption == 0} label={t('No')} challengeLeaderBoard={true}/>
                </RadioButtonContainer>
              </FirstPart>
            </ImageContainer>
            {selectedThemeOption === 1 ? <TitleContainer addMargin><ActivityTypeContainer width="100%" noChildWidth={1}>
              <FieldTitle>{t("Select a theme")}:</FieldTitle>
              <ActivityDropdown
                title={<div className="title">{themesHeaderAll.length === 0 ? t('No Themes Available') : (themeName === '' && themeMonth === '') ? t('Select a theme') : `${themeMonth}, ${themeName}`}</div>}
                id={"surveyType"}
              >{
                  themesHeaderAll && themesHeaderAll.length > 0 && themesHeaderAll.map((list) => (
                    <CustomMenuItem key={list.id} active={themeName == list.theme_text} /* onSelect */ onClick={() => this.onSelectTheme(list)}>{list.month}, {list.theme_text}</CustomMenuItem>
                  ))
                }
              </ActivityDropdown>
            </ActivityTypeContainer></TitleContainer> : null}
            <ImageContainer>
              <SaveButton type="submit" disabled={!description || !title || !imgSrc || !imageName || !Blurb || !servings || !mealType || !level || !video || recipeCategorieslist.length <= 0 || Ingredients.length <=0 || directions.length <= 0 } addMargin>{t("Save Recipe")}</SaveButton>
            </ImageContainer>
          </FormContainer>
        </div>
      </Layout>
    );
  }
}
EditRecipe.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  recipeCategories: PropTypes.array,
  selectedRecipeCategories: PropTypes.array,
  getRecipeCategories:PropTypes.func,
  editRecipeDetails:PropTypes.object,
  editRecipe: PropTypes.func,
  recipeLoading: PropTypes.bool,
  themes: PropTypes.array,
  themesHeaderAll: PropTypes.array,
  t: PropTypes.func
};
const mapStateToProps = (state) => ({
  recipeCategories: state.education.recipeCategories,
  selectedRecipeCategories: state.education.recipeCategories,
  recipeLoading: state.education.recipeLoading
});
const mapDispatchToProps = (dispatch) => ({
  getRecipeCategories: () => dispatch(getRecipeCategories()),
  editRecipe: (data, history) => dispatch(editRecipe(data, history))
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditRecipe));
