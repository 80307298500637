import React, { useState, useEffect } from "react";
import { Divider, MemberCard, MemberInfo, MemberName, RowContainer, ScrollableContainer, TeamCard, TeamCardAvatar, Title, ShowMore, MemberLeftContent } from "./styles";
import { GetTeamMembersDetails } from "../../redux/constants/apiConstants";
import { toast } from "react-toastify";
import { fetchApi } from "../../utils/methods";
import LazyImage from "../common/LazyImage/LazyImage";
import { ImageUrl } from "../../utils/constants";
import { ManagerRightArrowIcon } from "../../utils/icons";
import { useHistory } from "react-router-dom";


function TeamMembersCard() {
  const [teamMembersData, setTeamMembersData] = useState([]);
  const [showAllRows, setShowAllRows] = useState(false);
  const [totalTeamMember, setTotalTeamMember] = useState();

  const history = useHistory();

  const handleRedirect = (id) => {
    // window.console.log("Redirecting to:", `/manager/company/people/${id}/personal-profile`);
    history.push(`/manager/people/${id}/personal-profile`)
  };


  useEffect(() => {
    fetchTeamMembersData();
  }, []);

  const fetchTeamMembersData = async () => {
    const apiUrl = `${GetTeamMembersDetails}`;

    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        setTeamMembersData(res.data);
        setTotalTeamMember(res.data.length);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  const chunkArray = (array, size) =>
    array.reduce((acc, _, index) => {
      if (index % size === 0) acc.push(array.slice(index, index + size));
      return acc;
    }, []);

  const rows = chunkArray(teamMembersData, 4);

  if (teamMembersData.length === 0) {
    return null;
  }

  window.console.log("rows dataaa is",rows)


  return (
    <TeamCard>
      <Title>Team Members ({totalTeamMember})</Title>
      <Divider></Divider>
      <ScrollableContainer
        height="300px"
        showAllRows={showAllRows} /*style={{ minHeight: '300px' }}*/
      >
        {rows.map((row, rowIndex) => (
          <React.Fragment key={rowIndex}>
            <RowContainer  key={`row-${rowIndex}`} >
              {row.map((member) => (
                <MemberCard key={member.id} isBackground={member.bg_color} onClick={()=> handleRedirect(member.uid)}>
                  <MemberLeftContent>
                    <TeamCardAvatar>
                      <LazyImage
                        style={{
                          width: "48px",
                          height: "48px",
                          borderRadius: "48px",
                        }}
                        onError={() =>
                          window.console.error("Image failed to load")
                        }
                        src={`${ImageUrl}/${member.profile_image}`}
                      />
                    </TeamCardAvatar>
                    <MemberInfo>
                      <MemberName>{member.full_name}</MemberName>
                    </MemberInfo>
                  </MemberLeftContent>
                  <div style={{marginRight:"4px"}}>{ManagerRightArrowIcon()}</div>
                  
                </MemberCard>
              ))}
            </RowContainer>
            <Divider isMargin={"8px 0px"} />
            {rowIndex == 2 && teamMembersData?.length > 12 && !showAllRows && (
              <ShowMore onClick={() => setShowAllRows(true)} height="64px">
                Show More
              </ShowMore>
            )}
          </React.Fragment>
        ))}
        {/* {teamMembersData.length > 12 && !showAllRows && (
          <ShowMore
            onClick={() => setShowAllRows(true)}
            style={{
              cursor: "pointer",
              marginTop: "10px",
              textAlign: "center",
              color: "#184A61",
            }}
          >
            Show More
          </ShowMore>
        )} */}
      </ScrollableContainer>
    </TeamCard>
  );
}

export default TeamMembersCard;
