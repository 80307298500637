import React, { useEffect, useState } from 'react';
import {  HeaderContainer, RecognitionsContainer, StyledBody, StyledModal } from './style';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { fetchApi, getInitials, getStartAndEndDates } from '../../../utils/methods';
import {  GetAcknowledgmentGiver, GetLeadingSupporter, GetMembersRecognition, GetRecognitionGiver,  } from '../../../redux/constants/apiConstants';
import { Border } from '../../common/Manager/style';
import { closeIcon } from '../../../utils/icons';
import LazyImage from '../../common/LazyImage/LazyImage';
import { ImageUrl } from '../../../utils/constants';
import { NameInitials, ShowMore } from '../styles';

const RecognitionPopup = ({showModal,headerText,onClose,timeInterval,member,selectedCard}) => {

  const[Recognitions,setRecognitions] = useState([]);
  const[RecognitionData,setRecognitionData] = useState([]);
  //   const[TotalCount,setTotalCount] = useState([]);

  useEffect(()=>{
    if(selectedCard.type === 1 && selectedCard.subtype === 1){
      fetchPraisedMembers();
    }else if(selectedCard.type === 1 && selectedCard.subtype === 2){
      fetchRecognitionGivers();
    }else if(selectedCard.type === 2 && selectedCard.subtype === 1){
      fetchLeadingSupporter();
    }else if(selectedCard.type === 2 && selectedCard.subtype === 2){
      fetchAcknowledgmentGiver();
    }
  },[]);


  const fetchPraisedMembers = async() => {
    // fetch top performers
    const { startDate, endDate } = getStartAndEndDates(timeInterval);
    const res = await fetchApi(GetMembersRecognition + "/" + member.uid + "/" + startDate + "/" + endDate,"GET");
    if(res.status === "success"){
      setRecognitions(res.data.shoutout_count.data);
      setRecognitionData(res.data.shoutout_count.data.slice(0,5));
    }
  } 

  const fetchRecognitionGivers = async() => {
    // fetch top performers
    const { startDate, endDate } = getStartAndEndDates(timeInterval);
    const res = await fetchApi(GetRecognitionGiver + "/" + member.uid + "/" + startDate + "/" + endDate,"GET");
    if(res.status === "success"){
      setRecognitions(res.data.shoutout_count.data);
      setRecognitionData(res.data.shoutout_count.data.slice(0,5));
    }
  } 

  const fetchLeadingSupporter = async() => {
    // fetch top performers
    const { startDate, endDate } = getStartAndEndDates(timeInterval);
    const res = await fetchApi(GetLeadingSupporter + "/" + member.uid + "/" + startDate + "/" + endDate,"GET");
    if(res.status === "success"){
      setRecognitions(res.data.shoutout_count.data);
      setRecognitionData(res.data.shoutout_count.data.slice(0,5));
    }
  } 

  const fetchAcknowledgmentGiver = async() => {
    // fetch top performers
    const { startDate, endDate } = getStartAndEndDates(timeInterval);
    const res = await fetchApi(GetAcknowledgmentGiver + "/" + member.uid + "/" + startDate + "/" + endDate,"GET");
    if(res.status === "success"){
      setRecognitions(res.data.shoutout_count.data);
      setRecognitionData(res.data.shoutout_count.data.slice(0,5));
    }
  } 

  return (
    <>
      <StyledModal
        show={showModal}
        onHide={onClose}
        dialogClassName={"info-popup"}
        backdropClassName="drop-back"
        style={{ top: "-20%" }}
      >
        <Modal.Header>
          <HeaderContainer>
            <div>
              <span className='header-text'>{headerText}</span>
            </div>
            <div style={{cursor:"pointer"}} onClick={onClose}>{closeIcon("#00ABB6",14,14)}</div>
            
          </HeaderContainer>
        </Modal.Header>
        <div style={{padding:"0px 16px"}}><Border style={{margin:"20px 0px",padding:"0px 16px"}}/></div>
        <StyledBody>
          <RecognitionsContainer
            style={{ maxHeight: "485px", overflowY: "scroll" }}
          >
            <div className='top-card'>
              <div className='col-one'>
                
                {member?.profile_image !== "profile-Image/default.png" ? <LazyImage style={{width:"60px",height:"60px",borderRadius:"50px"}} src={ImageUrl + "/" + member.profile_image}/> : 
                  <NameInitials>{getInitials(member?.full_name)}</NameInitials>
                }
                <div className='mid-col'>
                  <div>
                    <span className='user-name'>{member.full_name}</span>
                  </div>
                  <div>
                    <span className='user-name'>Recognitions Received</span>
                  </div>
                </div>
              </div>
              <div className='total-count'>
                <span>
                  {member.total_recognitions}
                </span>
              </div>

            </div>

            {RecognitionData.map((recognition, index) => 
              <div key={index} className='recognition-card'>
                <div className='col-one'>
                  <LazyImage style={{width:"30px",height:"30px",borderRadius:"50px"}} src={ ImageUrl + "/" + recognition.icon}/>
                  <span className='name'>{recognition.name}</span>
                </div>
                <div className='total-count'>
                  <span>
                    {recognition.count}
                  </span>
                </div>
              </div>
            )}
            {Recognitions.length > 5 && <ShowMore
              onClick={() =>{Recognitions.length === RecognitionData.length ? setRecognitionData(Recognitions.slice(0,5)) : setRecognitionData(Recognitions);}}
              style={{
                cursor: "pointer",
                marginTop: "10px",
                textAlign: "center",
                color: "#184A61",
              }}
            >
              {Recognitions.length === RecognitionData.length ? "Show Less" :"Show More"}
            </ShowMore>}
          </RecognitionsContainer>
        </StyledBody>
      </StyledModal>
    </>
  )
}

RecognitionPopup.propTypes = {
  showModal: PropTypes.bool.isRequired,
  headerText: PropTypes.string,
  onClose: PropTypes.func,
  timeInterval: PropTypes.string,
  member: PropTypes.object,
  selectedCard: PropTypes.object,

}

export default RecognitionPopup