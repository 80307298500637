import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RenderWellnessQuestions, RenderQuestionContainer, QuestionContainer, CheckMarkContainer, CheckMark, OptionContainer } from "../../containers/WellnessAssessment/WellbeingDashboard/styles";
import { withTranslation } from 'react-i18next';

class QuestionComponent extends Component {
  renderOptions = (options, section) => {
    const { color, saveData, addedAnswers } = this.props;
    return options.map((answer, index) => (
      <OptionContainer
        key={index}
        id={`question_${section.question_id}_option_${answer.answer_id}`} // Unique ID for options
        onClick={() => saveData(section.question_id, answer.answer_id, section.is_multiple_answers, section.point_status ? answer.point : 0)}
        isActive={addedAnswers && addedAnswers.find((obj) => obj === answer.answer_id)}
        bgColor={color}
      >
        <div>
          <CheckMarkContainer id={`checkmark_${section.question_id}_option_${answer.answer_id}`}> {/* Unique ID for checkmark */}
            <CheckMark bgColor={color} />
          </CheckMarkContainer>
        </div>
        <div id={`option_text_${section.question_id}_option_${answer.answer_id}`}> {/* Unique ID for option text */}
          {this.props.t(answer.answer)}
        </div>
      </OptionContainer>
    ));
  };

  renderQuestions = (questionList, questionNo) => (
    <RenderWellnessQuestions id={`question_${questionList.question_id}`}>
      <RenderQuestionContainer>
        <QuestionContainer id={`question_text_${questionList.question_id}`}>
          <div>{`Q${questionNo}. ${this.props.t(questionList.question)}`}</div>
        </QuestionContainer>
        {this.renderOptions(questionList.answers, questionList)}
      </RenderQuestionContainer>
    </RenderWellnessQuestions>
  );

  render() {
    const { section, questionNo } = this.props;
    return (
      <div id={`question_container_${section?.question_id}`}> {/* Unique ID for the question container */}
        {this.renderQuestions(section, questionNo)}
      </div>
    );
  }
}

QuestionComponent.propTypes = {
  section: PropTypes.object.isRequired,
  addedAnswers: PropTypes.string,
  saveData: PropTypes.func.isRequired,
  color: PropTypes.string,
  questionNo: PropTypes.number,
  t: PropTypes.func
};

export default ((withTranslation())(QuestionComponent));
