/* eslint-disable no-undef,no-console, camelcase */
import { toast } from 'react-toastify';
import { http } from '../../http';
import { getRewardPoints } from '../index';
import * as actionTypes from '../actionTypes';
import { EditChallengeAPI, GetChallenges, CreateChallenge, GetChallengeById, JoinOrLeaveChallenge, AddCommentOnChallenge,
  GetCommentsOnChallengeById, GetChallengeCompetitors, AddCommentReplyOnChallenge, ActiveAndFeaturedChallenges,
  GetCompanyHealthDetails, GetTeamChallengeCompetitors, GetAllCompany, updateDailyTaskAPI, CreateBehaviorChallengeAPI,
  getBehvaiorChallengeById, getUserListForChallenges, getUserLastSyncTime, GetDownloadCSVStatus, 
  GetBehaviorChallengesByCategory, UpdateChallengeLiveStatus, GetBehaviorChallengeDetails, 
  GetUserBehaviorChallenges, AddUserChallenge, GetActivityChallenges, GetActivityChallengeById, 
  UpdateActivityChallengeLiveStatus, AddChallengeLibrary, EditChallengeLibrary, GetChallengeSubcategory, 
  GetChallengeLibraryBySubcategory, GetUserActivityChallenges, GetACMEActiveAndFeaturedChallenges, 
  GetACMEChallengeDetailsById, GetACMEChallengeCompetitors, GetACMEChallengeCommentsById, 
  GetACMEUserListForChallenges, GetGroupChallengeCompetitors, EditBehaviorChallenge, GetUserFriends, 
  InviteUserForJoiningChallenge,UserRecommendedChallenges, GetAdminChallenges, GetChallengeParticipantsCount, TeamChallenges, GetTeamChallengeDetails, DeleteChallengeById,
  GetChallengeTypeCount, GetChallengeByChallengeType, DeleteChallengeLibrary, GetBehaviorChallengeByCategory, UserRecommendedWellnessChallenges} from '../../constants/apiConstants';

import { TimeZone, CompanyIdForACME } from '../../../utils/constants';
import {getActiveChallenge} from '../wellnessDashboardActions';
import {setSelectedYear} from '../companyDashboard';
import { getCompanyLocation, getCompanyDepartment} from '../index';

const challengesData = (res) => (dispatch) => dispatch({ type: actionTypes.GET_CHALLENGES_DATA, response: res });

const errorOccurred = (error) => (dispatch) => dispatch({ type: actionTypes.FAILED_GET_CHALLENGES_DATA, error });

const networkError = (error) => (dispatch) => dispatch({type: actionTypes.NETWORK_ERROR, networkError: error});

const emptyChallengeDetails = () => (dispatch) => dispatch({type: actionTypes.EMPTY_CHALLENGE_DETAILS});

const getChallenges = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetChallenges, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
      } else {
        dispatch(challengesData(res[0]));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const createBehaviorLibrary = (data, onHide, path) => {
  const ChallengeData = {
    ...data,
    time_zone: TimeZone
  };
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.CHALLENGE_LOADING});
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.post(CreateBehaviorChallengeAPI, ChallengeData, config);
      if(data.challenge_type === 'survivor' || data.challenge_type === 'behavior') {
        onHide();
      }
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_CREATE_CHALLENGE, createChallengeError: res.message});
      } else {
        toast.success(res[0]);
        dispatch({type: actionTypes.SUCCESSFULLY_CREATED_CHALLENGE, successResponse: res[0]});
        setTimeout(()=>{
          window.location.replace(path);
        }, 2000)
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getBehvaiorChallengeByIdAction = (id, liveChallenge=false) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
 
  return async (dispatch) => {
    try {
      const res = await http.get(`${getBehvaiorChallengeById}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
        window.location.replace('/challenges/show-all');
      } else {
        dispatch({ type: actionTypes.FETCH_BEHAVIOR_CHALLENGE_BY_ID, data: res, liveChallengeStatus: liveChallenge});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.replace('/challenges/show-all');
    }
  }
}

const getUserRecommendedChallenges = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(UserRecommendedChallenges, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({type: actionTypes.USER_RECOMMENDED_CHALLENGE, userRecommendedChallengeData: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getUserRecommendedWellnessChallenges = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(UserRecommendedWellnessChallenges, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({type: actionTypes.USER_RECOMMENDED_WELLNESS_CHALLANGE, userRecommendedWellnessChallengeData: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};


const createChallenge = (data, onHide, path) => {
  const ChallengeData = {
    ...data,
    time_zone: TimeZone
  };
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.CHALLENGE_LOADING});
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.post(CreateChallenge, ChallengeData, config);
      if(data.challenge_type === 'survivor' || data.challenge_type === 'behavior') {
        onHide();
      }
      if(res.message) {
        setLoading && setLoading(false)
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_CREATE_CHALLENGE, createChallengeError: res.message});
      } else {
        toast.success(res[0]);
        dispatch({type: actionTypes.SUCCESSFULLY_CREATED_CHALLENGE, successResponse: res[0]});
        window.location.replace(path);
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getChallengeDetails = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch, getState) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    dispatch({type: actionTypes.EMPTY_CHALLENGE_DETAILS});
    const isDummy = getState().challenges.isDummyChallenge;
    const endPoint = isDummy ? GetACMEChallengeDetailsById : GetChallengeById;
    try {
      if(parseInt(id,10)>=0){
        const res = await http.get(`${endPoint}/${id}`, config);
        if(res.message) {
          // toast.error(res.message);
          dispatch({type: actionTypes.FAILED_GET_CHALLENGE_DETAILS, getChallengeError: res.message});
          if(window.location.pathname.includes("/company")){
            window.location.replace('/company/challenges');
          }
          else{
            window.location.replace('/challenges');
          }
        } else {
          if(res[0].is_team_challenge){
            dispatch(getTeamChallengeDetailsById(id))
          }
          dispatch({type: actionTypes.GET_CHALLENGE_DETAILS, challengeDetails: res[0]});

        }
      } else {
        if(window.location.pathname.includes("/company")){
          window.location.replace('/company/challenges');
        }
        else{
          window.location.replace('/challenges');
        }
      }
    } catch(error) {
      dispatch(networkError(error.message));
      if(window.location.pathname.includes("/company")){
        window.location.replace('/company/challenges');
      }
      else{
        window.location.replace('/challenges');
      }
      // toast.error(error.message);
      //window.location.reload();
    }
  }
};

const getTeamChallengeDetailsById = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    dispatch({type: actionTypes.EMPTY_TEAM_CHALLENGE_DETAILS});
    // const endPoint = GetTeamChallengeDetails;
    try {
      if(parseInt(id,10)>=0){
        const res = await http.get(`${GetTeamChallengeDetails}/${id}`, config);
        if(res.message) {
          // toast.error(res.message);
          dispatch({type: actionTypes.FAILED_GET_TEAM_CHALLENGE_DETAILS, getTeamChallengeError: res.message});
          window.location.replace('/challenges');
        } else {
          dispatch({type: actionTypes.GET_TEAM_CHALLENGE_DETAILS, teamChallengeDetails: res});
        }
      } else {
        window.location.replace('/challenges');
      }
    } catch(error) {
      dispatch(networkError(error.message));
      window.location.replace('/challenges');
      // toast.error(error.message);
      //window.location.reload();
    }
  }
};

const joinOrLeaveChallenge = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.CHALLENGE_LOADING});
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.post(JoinOrLeaveChallenge, data, config);
      if(res.message) {
        toast.error(res.message);
        if(window.location.pathname.includes("/company")){
          window.location.replace(`/company/challenges`)
        }
        else{
          window.location.replace(`/challenges`)
        }
        dispatch({type: actionTypes.JOIN_LEAVE_CHALLENGE_FAILED, joinOrLeaveError: res.message});
      } else {
        toast.success(res[0]);
        dispatch({type: actionTypes.JOIN_LEAVE_CHALLENGE_SUCCESS, success: res[0]});

        if(data.is_joined === 1) {
          dispatch(getRewardPoints());
        }
        dispatch(getChallenges());
        dispatch(getChallengeDetails(data.challenge_id));
        dispatch(featuredChallengesAPI());
        dispatch(getFeaturedChallenges());
        dispatch(getUserListOfChallenges(data.challenge_id));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getCommentsOnChallenge = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch, getState) => {
    dispatch({type: actionTypes.CHALLENGE_LOADING});
    const isDummy = getState().challenges.isDummyChallenge;
    const endPoint = isDummy ? GetACMEChallengeCommentsById : GetCommentsOnChallengeById;
    try {
      const res = await http.get(`${endPoint}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch({type: actionTypes.FAILED_GET_COMMENTS_ON_CHALLENGE, commentError: res.message});
      } else {
        dispatch({type: actionTypes.GET_COMMENTS_ON_CHALLENGE, comments: res});
      }
    } catch(error) {
      dispatch(networkError(error.message));
      toast.error(error.message);
      //window.location.reload();
    }
  }
};

const addCommentOnChallenge = (commentData) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.post(AddCommentOnChallenge, commentData, config);
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_ADD_COMMENT_ON_CHALLENGE, postCommentError: res.message});
      } else {
        dispatch({type: actionTypes.ADD_COMMENT_ON_CHALLENGE, commentSuccess: res[0]});
        dispatch(getCommentsOnChallenge(commentData.challenge_id));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      //window.location.reload();
    }
  }
};

const saveChallengeData = (challenge) => (dispatch) => dispatch({type: actionTypes.SAVE_CHALLENGE_DATA, challengeData: challenge});

const getChallengeData = () => (dispatch) => dispatch({type: actionTypes.GET_CHALLENGE_DATA});

const getActiveTab = () => (dispatch) => dispatch({ type: actionTypes.GET_ACTIVE_TAB});

const setActiveTab = (name) => (dispatch) => dispatch({ type: actionTypes.SET_ACTIVE_TAB, activeTab: name});

const getChallengeCompetitors = (challengeId, challengeType, pageLimit='25', pageNumber='1', selectedOption = 'all', cb) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch, getState) => {
    const isDummy = getState().challenges.isDummyChallenge;
    const endPoint = isDummy ? GetACMEChallengeCompetitors : GetChallengeCompetitors;
    try {
      const res = await http.get(`${endPoint}/${challengeId}/${challengeType}/${pageNumber}/${pageLimit}?response_type=${selectedOption}`, {headers: {AUTHTOKEN: AuthToken}});
      if(res.message) {
        dispatch({type: actionTypes.FAILED_GET_CHALLENGE_COMPETITORS, challengeCompetitorsError: res[0].message});
      } else {
        cb(false);
        if(res.response){dispatch({type: actionTypes.GET_CHALLENGE_COMPETITORS, challengeCompetitors: res['response'], pageNumber: pageNumber, count: res['count']});}else if(res.team_details){
          dispatch({type: actionTypes.GET_CHALLENGE_COMPETITORS, challengeCompetitors: res.team_details.team_challenge_leaderboard.participant_team, pageNumber: pageNumber, count: res['count']});

        }
        
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      //window.location.reload();
    }
  }
};

const addCommentReplyOnChallenge = (commentId, reply, challengeId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const data = {
      comment_id: commentId,
      reply: reply
    };
    try {
      const res = await http.post(AddCommentReplyOnChallenge, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_ADD_COMMENT_REPLY_ON_CHALLENGE, replyError: res.message});
      } else {
        dispatch({type: actionTypes.ADD_COMMENT_REPLY_ON_CHALLENGE, replySuccess: res[0]});
        dispatch(getCommentsOnChallenge(challengeId));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      //window.location.reload();
    }
  }
};

const featuredChallengesAPI = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch, getState) => {
    dispatch({type: actionTypes.CHALLENGE_LOADING});
    const companyId = getState().profileData.userCompany.id;
    let endpoint = companyId === CompanyIdForACME ? GetACMEActiveAndFeaturedChallenges : ActiveAndFeaturedChallenges
    try {
      const res = await http.get(endpoint, {headers: { AUTHTOKEN: AuthToken }})
      if(res) {
        dispatch({ type: actionTypes.SET_FEATURED_CHALLENGE, featuredChallenges: res['featured_challenges'], activeChallengeCompany: res['active_challenges'], upcomingChallenge: res['upcoming_challenges'], teamChallenges:[]})
      }
    }
    catch(error) {
      dispatch(networkError(error.message));
      toast.error(error.message);
    }
  }
};

const teamChallengesAPI = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch, getState) => {
    dispatch({type: actionTypes.CHALLENGE_LOADING});
    const companyId = getState().profileData.userCompany.id;
    let endpoint = companyId === CompanyIdForACME ? TeamChallenges : TeamChallenges;
    try {
      const res = await http.get(endpoint, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        // dispatch({ type: actionTypes.SET_FEATURED_CHALLENGE, featuredChallenges: res.challenge_invitation,teamChallenges: res.challenge_invitation,activeChallengeCompany:[],upcomingChallenge:[]})
      }
    }
    catch(error) {
      dispatch(networkError(error.message));
      toast.error(error.message);
    }
  }
};

const getFeaturedChallenges = () => (
  (dispatch) => {
    dispatch({ type: actionTypes.GET_FEATURED_CHALLENGE }) 
  }
);

const getCompanyHealthDetails = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetCompanyHealthDetails, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch({type: actionTypes.FAILED_GET_COMPANY_HEALTH_DETAILS, healthError: res})
      } else {
        dispatch({type: actionTypes.GET_COMPANY_HEALTH_DETAILS, companyHealth: res})
      }
    }
    catch(error) {
      dispatch(networkError(error.message));
      toast.error(error.message);
    }
  }
};

const getTeamChallenge = (challengeId, challengeType) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTeamChallengeCompetitors}/${challengeId}/${challengeType}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch({type: actionTypes.FAILED_GET_TEAM_CHALLENGE, teamChallengeError: res[0].message});
      } else {
        dispatch({type: actionTypes.GET_TEAM_CHALLENGE, teamChallenge: res.team_details});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      //window.location.reload();
    }
  }
};

const getAllCompanies = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetAllCompany, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_ALL_COMPANIES, allCompanies: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const editChallengeAction = (obj, enableButton) => {
  const editChallengeData = {
    ...obj,
    time_zone: TimeZone
  };
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.put(EditChallengeAPI, editChallengeData, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0])
        window.location.replace(`/challenges/${obj.id}`)
      }
      if(enableButton) {
        enableButton();
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}

const updateDailyTask = (obj) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.put(`${updateDailyTaskAPI}/${obj.id}`, obj, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch(getChallengeDetails(obj.id));
        dispatch(getActiveChallenge())
      } else {
        toast.success(res[0]);
        dispatch(getChallengeDetails(obj.id));
        dispatch(getActiveChallenge())
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      // window.location.reload();
    }
  }
};

const changeWeekDashboard = (num) => (dispatch) => dispatch({ type: actionTypes.CHANGE_WEEK_DASHBOARD, value: num});
const getUserListOfChallenges= (challengeId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch, getState) => {
    const isDummy = getState().challenges.isDummyChallenge;
    const endPoint = isDummy ? GetACMEUserListForChallenges : getUserListForChallenges;
    try {
      const res = await http.get(`${endPoint}/${challengeId}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_USER_LIST_OF_CHALLENGE, challengeAttendees: res[0]})
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};
const getBuddiesListOfChallenges = (challengeId, status) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch, getState) => {
    const isDummy = getState().challenges.isDummyChallenge;
    const endPoint = isDummy ? GetACMEUserListForChallenges : getUserListForChallenges;
    try {
      const res = await http.get(`${endPoint}/${challengeId}/${status}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_BUDDIES_LIST_OF_CHALLENGES, amigosList: res})
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}
const getUserLastSync = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${getUserLastSyncTime}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_USER_LAST_SYNC, syncTime: res.last_sync})
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};
const getUserOverviewDetails = (challengeId, challengeType, userId, pageNumber='1', pageLimit = '25') => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch, getState) => {
    const isDummy = getState().challenges.isDummyChallenge;
    const endPoint = isDummy ? GetACMEChallengeCompetitors : GetChallengeCompetitors;
    try {
      const res = await http.get(`${endPoint}/${challengeId}/${challengeType}/${pageNumber}/${pageLimit}?uid=${userId}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_USER_OVERVIEW_DETAILS, userChallengeDetails: res})
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};
const getChallengeCsvData = (challengeId, challengeType, selectedOption = 'all') => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch, getState) => {
    const isDummy = getState().challenges.isDummyChallenge;
    const endPoint = isDummy ? GetACMEChallengeCompetitors : GetChallengeCompetitors;
    try {
      const res = await http.get(`${endPoint}/${challengeId}/${challengeType}?response_type=${selectedOption}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_CHALLENGE_CSV, challengeCsvData: res[0]})
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};
const getChallengeDownloadCSVStatus = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetDownloadCSVStatus}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_CHALLENGE_DOWNLOAD_CSV_STATUS, challengeCSVStatus: res})
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getBehaviorChallengesByCategory = (category) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetBehaviorChallengesByCategory}/${category}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.FETCH_BEHAVIOR_CHALENGE_DATA_BY_CATEGORY , categoryName: category,behaviourCategotyData:
           res['behavior_challenges']});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const updateChallengeLiveStatus = (obj) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.put(UpdateChallengeLiveStatus, obj, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        if (res.message) {
          toast.error(res.message);
        } else {
          toast.success(res[0]);
          dispatch(getBehaviorChallengeDetails());
        }
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getBehaviorChallengeDetails = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetBehaviorChallengeDetails, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.FETCH_BEHAVIOR_CHALLENGE_DETAILS , behaviourChallengeDetails: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getUserBehaviorChallenges = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.CHALLENGE_LOADING});
    try {
      const res = await http.get(GetUserBehaviorChallenges, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.GET_USER_BEHAVIOR_CHALLENGES , userBehaviorChallenge: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const addUserLiveChallenge = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(AddUserChallenge, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        dispatch(setChallengeInviteStatus(true, res['challenge_id']));
        toast.success(res.message);
        // history.push(`/challenges/${res['challenge_id']}`);
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const UpdateUserLiveChallengeStatus = () => (dispatch) => dispatch({ type: actionTypes.UPDATE_LIVE_CHALLENGES_STATUS});

const getActivityChallenges = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetActivityChallenges, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.FETCH_ACTIVITY_CHALLENGES_LIBRARY , activityChallengeLibraries: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getActivityChallengeById = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetActivityChallengeById}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.FETCH_ACTIVITY_CHALLENGE_BY_ID, activityDetailsById: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const updateActivityChallengeLiveStatus = (obj) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.put(UpdateActivityChallengeLiveStatus, obj, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        if (res.message) {
          toast.error(res.message);
        } else {
          toast.success(res[0]);
          dispatch(getActivityChallenges());
        }
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const createActivityChallenge = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.CHALLENGE_LOADING});
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.post(AddChallengeLibrary, data, config);
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_CREATE_CHALLENGE, createChallengeError: res.message});
      } else {
        toast.success(res[0]);
        dispatch({type: actionTypes.SUCCESSFULLY_CREATED_CHALLENGE, successResponse: res[0]});
        window.location.replace('/challenges/show-all');
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const createActivityChallengeV2 = (data, path) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.CHALLENGE_LOADING});
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.post(AddChallengeLibrary, data, config);
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_CREATE_CHALLENGE, createChallengeError: res.message});
      } else {
        toast.success(res[0]);
        dispatch({type: actionTypes.SUCCESSFULLY_CREATED_CHALLENGE, successResponse: res[0]});
        window.location.replace(path);
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const editChallengeLibrary = (obj) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.put(EditChallengeLibrary, obj, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0])
        window.location.replace('/challenges/show-all');
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}

const editChallengeLibraryV2 = (obj, path) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.put(EditChallengeLibrary, obj, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0])
        window.location.replace(path);
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}

const getChallengeSubcategory = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.CHALLENGE_LOADING});
    try {
      const res = await http.get(GetChallengeSubcategory, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.FETCH_CHALLENGE_SUBCATEGORY, challengeSubcategory: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getChallengeLibraryBySubcategory = (subCategory, runningStatus= false) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.CHALLENGE_LOADING});
    try {
      const res = await http.get(`${GetChallengeLibraryBySubcategory}/${subCategory}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.FETCH_CHALLENGE_DETAILS_BY_SUBCATEGORY, challengelibrarySubCategories: res, runningStatus: runningStatus, runningSubCategory: subCategory});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getUserActivityChallenges = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.CHALLENGE_LOADING});
    try {
      const res = await http.get(GetUserActivityChallenges, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.GET_USER_ACTIVITY_CHALLENGES, userActivityChallenges: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const setUserLiveChallengeStatus = (challenge) => (dispatch) => dispatch({ type: actionTypes.SET_USER_LIVE_CHALLENGES_STATUS, activityChallenge: challenge});

const toggleDummyChallenges = (bool) => (dispatch) => dispatch({type: actionTypes.TOGGLE_DUMMY_CHALLENGES, isDummyChallenge: bool})
const setChallengeInviteStatus = (bool, challengeId) => (dispatch) => dispatch({type: actionTypes.SET_CHALLENGE_INVITE_STATUS, inviteAmigoStatus: bool, challengeId: challengeId})

const editBehaviourChallenge = (obj, history, enableButton, path) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.put(EditBehaviorChallenge, obj, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        res && res[0] ? toast.success(res[0]) : toast.error("Error");
        dispatch(getBehaviorChallengeDetails());
        history.push(path);
      }
      if(enableButton) {
        enableButton(false);
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const getGroupChallengeCsvData = (challengeId, challengeType) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const endPoint = GetGroupChallengeCompetitors;
    try {
      const res = await http.get(`${endPoint}/${challengeId}/${challengeType}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_CHALLENGE_CSV, challengeCsvData: res[0]})
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}
const getInviteAmigosList = (id, challengeId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.INVITE_LOADING});
    try {
      const res = await http.get(`${GetUserFriends}/${id}/${challengeId}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_INVITE_AMIGO_LIST, inviteAmigosList: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const inviteUserForJoiningChallenge = (data, userId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(InviteUserForJoiningChallenge, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch(getInviteAmigosList(userId, data.challenge_id))
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getActivityChallengeByIdAction = (id, liveChallenge=false) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetActivityChallengeById}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
        window.location.replace('/challenges/show-all');
      } else {
        dispatch({ type: actionTypes.ACTIVITY_CHALLENGE_BY_ID, activityChallengeById: res[0], liveChallengeStatus: liveChallenge});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.replace('/challenges/show-all');
    }
  }
}

const getCompany = () => (dispatch) => dispatch({type: actionTypes.GET_COMPANY});

const getAllCompletedChallenges = (year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetChallenges}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
      } else {
        dispatch(challengesData(res[0]));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getAdminCompletedChallenges = (year, companyId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetAdminChallenges}/${companyId}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
      } else {
        dispatch(challengesData(res[0]));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const setCompany = (id, name, companyStartDate, showBiometricStatus) => (dispatch) => {
  const year = new Date().getFullYear();
  dispatch(setSelectedYear(year));
  dispatch({type: actionTypes.SET_COMPANY, id, name, companyStartDate, showBiometricStatus});
  dispatch(getCompanyLocation(id, true));
  dispatch(getCompanyDepartment(id, true));
};

const getChallengeParticipation = (year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetChallengeParticipantsCount}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({type: actionTypes.GET_CHALLENGE_PARTICIPANTS_COUNT, participantCount: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const deleteChallengeById = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.delete(`${DeleteChallengeById}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      }
      else {
        toast.success(res[0]);
        dispatch({type: actionTypes.DELETE_CHALLENGE, deleteChallenge: res});
        if(window.location.pathname.includes("/company")){
          window.location.replace(`/company/challenges`)
        }
        else{
          window.location.replace(`/challenges`)
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
}

const fethcChallengeByChallengeType = (type) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetChallengeByChallengeType}/${type}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({type: actionTypes.GET_CHALLENGE_TYPE_BY_CHALLENGE, challengeByType: res});
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const fetchChallengeTypeCount = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetChallengeTypeCount, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({type: actionTypes.ACTIVITY_CHALLENGE_TYPE, activityChallengeType: res});
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const deleteChallengeLibrary = (type, id, category) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.delete(`${DeleteChallengeLibrary}/${type}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      }
      else {
        toast.success(res[0]);
        dispatch({type: actionTypes.DELETE_CHALLENGE_LIBRARY, deleteChallengeLibrary: res});
        if(type === "behaviour"){
          dispatch(fetchBehaviorChallengeByCategory(category))
        }
        dispatch(fethcChallengeByChallengeType(type));
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
}

const createChallengeV2 = (data, path, callBack) => {
  const ChallengeData = {
    ...data,
    time_zone: TimeZone
  };
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.CHALLENGE_LOADING});
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.post(CreateChallenge, ChallengeData, config);
      if(res.message) {
        callBack(false);
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_CREATE_CHALLENGE, createChallengeError: res.message});
      } else {
        callBack(true);
        toast.success(res[0]);
        dispatch({type: actionTypes.SUCCESSFULLY_CREATED_CHALLENGE, successResponse: res[0]});
        window.location.replace(path);
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const fetchBehaviorChallengeByCategory = (type) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.BEHAVIOR_CHALLENGE_TYPE, loading: true});
    try {
      const res = await http.get(`${GetBehaviorChallengeByCategory}/${type}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({type: actionTypes.BEHAVIOR_CHALLENGE_TYPE, behaviorChallengeByType: res, loading:false});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch({type: actionTypes.BEHAVIOR_CHALLENGE_TYPE, loading: false});
    }
  }
};

const editLiveChallenge = (obj) => {
  const editChallengeData = {
    ...obj,
    time_zone: TimeZone
  };
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.put(EditChallengeAPI, editChallengeData, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0])
        window.location.replace(`/challenges/${obj.id}`)
      }
      // if(enableButton) {
      //   enableButton();
      // }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}

export { editChallengeAction, featuredChallengesAPI, getFeaturedChallenges, getChallenges, createChallenge, getChallengeDetails, joinOrLeaveChallenge, addCommentOnChallenge,
  getCommentsOnChallenge, getActiveTab, setActiveTab, getChallengeData, saveChallengeData, getChallengeCompetitors, addCommentReplyOnChallenge,
  getCompanyHealthDetails, getTeamChallenge, getAllCompanies, updateDailyTask, changeWeekDashboard, createBehaviorLibrary, getBehvaiorChallengeByIdAction, getUserListOfChallenges, getUserLastSync, getUserOverviewDetails, getChallengeCsvData, getChallengeDownloadCSVStatus, getBehaviorChallengesByCategory, updateChallengeLiveStatus, getBehaviorChallengeDetails, getUserBehaviorChallenges, addUserLiveChallenge, UpdateUserLiveChallengeStatus, emptyChallengeDetails, getActivityChallenges, getActivityChallengeById, updateActivityChallengeLiveStatus, createActivityChallenge, editChallengeLibrary, getChallengeSubcategory, getChallengeLibraryBySubcategory, getUserActivityChallenges, setUserLiveChallengeStatus, toggleDummyChallenges,  getGroupChallengeCsvData, editBehaviourChallenge, setChallengeInviteStatus, getInviteAmigosList, inviteUserForJoiningChallenge,  getBuddiesListOfChallenges  ,getUserRecommendedChallenges,
  getUserRecommendedWellnessChallenges ,getActivityChallengeByIdAction, getCompany, getAllCompletedChallenges, setCompany, getAdminCompletedChallenges, getChallengeParticipation, teamChallengesAPI, deleteChallengeById,
  fethcChallengeByChallengeType, fetchChallengeTypeCount, editChallengeLibraryV2, createActivityChallengeV2, deleteChallengeLibrary, createChallengeV2, fetchBehaviorChallengeByCategory, editLiveChallenge
};
