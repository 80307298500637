/* eslint-disable no-console,no-undef, camelcase, no-constant-condition */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  getOrientation,
  resetOrientation,
  checkImage,
  fetchApi,
} from "../../../utils/methods";
import { imageErrorMessage } from "../../../utils/constants";
import { withTranslation } from "react-i18next";
import {
  Layout,
  Container,
  InputContainer,
  StyledInputV2,
  StyledLabel,
  ButtonContainer,
  EditorContainer
} from "../../CreateEvent/style";
import StepsHeading from "./stepNames";
import { Main } from "../../AdminTeamCreation/styles";
import Editor from "../../CKEditor";
import CommonDropdown from "../../common/CommonDropDown/CommonDropdown";
import CommonButton from "../../common/CommonButton/CommonButton";
import { AdminCloseIcon, AdminHeartIcon, AdminLeftArrowIcon, AdminRightArrowIcon, AdminWhiteHeartIcon, LaunchIcon, RecognitionCloseIcon, RecognitionTickIcon } from "../../../utils/icons";
import { AddCompanyExemplifiesValues, GetCoreValueIcons,SubmitCoreValue } from "../../../redux/constants/apiConstants";
import { Border } from "../../Journeys/styles";
import { withRouter } from 'react-router-dom';
import { ImageUrl } from "../../../utils/constants";
import { ColorBox, ColorGrid, Heading, IconGrid, IconWrapper, ModalFooter, StyledModalV1 } from "./styles";

class CreateCoreValue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description:"",
      imageName: "",
      eventPointKey: null,
      eventPointValue: null,
      colorCode:null,
      //   status: props.history?.location?.state?.status,
      recognitionId: null,
      color: "",
      so:false,
      step:1,
      buttonStatus: false,
      selectedColor:null,
      icon:null,
      // iconData:[],
      selectedIcon: null,
      showModal: false,
      iconColor:null,
      coreValueIcons:null,
      isModalOpen:false,
      selectedColorV1:null,
      pointsArray : [
        { value: 5, text: "5 Pts" },
        { value: 10, text: "10 Pts" },
        { value: 15, text: "15 Pts" },
        { value: 20, text: "20 Pts" },
        { value: 25, text: "25 Pts" },
        { value: 30, text: "30 Pts" },
        { value: 35, text: "35 Pts" },
        { value: 40, text: "40 Pts" },
        { value: 45, text: "45 Pts" },
        { value: 50, text: "50 Pts" }
      ],
      colors : [
        { name: "Red", colorCode: "#F37A65" },
        { name: "Blue", colorCode: "#92A4DA" },
        { name: "Maroon", colorCode: "#DA6C74" },
        { name: "Green", colorCode: "#75B084" },
        { name: "Orange", colorCode: "#DA9B6C" },
        { name: "Purple", colorCode: "#BE7D9C" },
        { name: "Yellow", colorCode: "#B1AA56" },
        { name: "Teal", colorCode: "#DA92C8" },
      ],
      iconData: Array(50).fill({ icon: "AdminCloseIcon" })
    };
  }



 
  handleModal = () => {
    this.setState({isModalOpen:true})
  }

  handleCloseColorModal = () => {
    this.setState({isModalOpen:false})
  }

  handleCloseModal = () => {
    this.setState({showModal:false})
  }


  onSelectColor = (color) => {
    this.setState({selectedColorV1: color});
  };


  handleIconClick = (index) => {
    window.console.log("hiii",index)
    this.setState({ selectedIcon: index });

  };



  handleIconSelect = (icon) => {
    this.setState({ selectedIcon: icon, isModalOpen: false }); // Set selected icon and close modal
  };


  componentDidMount() {
    this.getCoreValueIcons();
  }

  onChange = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if (e.target.files[0]) {
      if (checkImage(fileArr)) {
        if (file?.size / 1000000 <= 20) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document
            .getElementById("event-upload-file")
            .value.split("\\");
          reader.onloadend = () => {
            this.setState({
              imgSrc: reader.result,
              imageName: array[array.length - 1],
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  imgSrc: baseImage,
                });
              });
            });
          };
        } else {
          toast.error("Please select image file less than 20MB");
          document.getElementById("event-upload-file").value = "";
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById("event-upload-file").value = "";
      }
    }
  };


  backgroundColorName = [
    {
      id: 1,  
      name: 'Light Pink',
      colorCode: "#F4AAA9"
     
    },
    {
      id: 2,   
      name: 'Pink',
      colorCode: "#DC94B5"
    },
    {
      id: 3,   
      name: 'Purple',
      colorCode: "#AF87C1"
    },
    {
      id: 4,   
      name: 'Blue',
      colorCode: "#6781C3"
    },
    {
      id: 5,   
      name: 'Sky Blue',
      colorCode: "#85C0EA"
    },
    {
      id: 6,   
      name: 'Peacock Blue',
      colorCode: "#007AB1"
    },
    {
      id: 7,   
      name: 'Dark Green',
      colorCode: "#006F88"
    },
    {
      id: 8,   
      name: 'Green',
      colorCode: "#76AB78"
    },
    {
      id: 9,   
      name: 'Yellow',
      colorCode: "#F1C977"
    },
    {
      id: 10,   
      name: 'Orange',
      colorCode: "#FFBF73"
    },
  ]; 



  removePhoto = () => {
    this.setState({
      imgSrc: "",
      imageName: "",
    });
  };

  onSelectPoints = (name) => {
    this.setState({
      [name]: name.value,
      eventPointKey: name.key,
      eventPointValue: name.value,
    });
  };


  onSelectColors = (name) => {
    window.console.log("name",name)
    this.setState({
      selectedColor:name
    });
  };


  
  submitCoreValues = async () => {
    const {title,selectedColor,selectedIcon,eventPointValue,description}=this.state
    const {companyInfo}= this.props; 
   
    const payload = {
      company_id: companyInfo?.id,
      title: title,
      about: description,
      white_icon: selectedIcon.white_icon,
      blue_icon: selectedIcon.blue_icon,
      color_code:selectedColor.colorCode,
      point: eventPointValue    };
   
    window.console.log('Payload:', payload);
   
   
    try {
      const response = await fetchApi(SubmitCoreValue, 'POST', payload);
      if (response.status === 'success') {
        window.console.log("response",response)
        toast.success(response.data[0]);
        this.props.history.push({pathname:`/company/recognition`,state:{activeTab:6}});
     
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      window.console.log(error);
    }
  };
   

  saveData = () => {
    const {
      title,
      description,
      eventPointValue,
      recognitionId,
    //   status
    } = this.state;
    const {companyInfo}= this.props;
    const RecognitionDetails = {
      company_id: companyInfo?.id,
      is_deleted: [],
      recognitions: [
        {
          core_value: title,
          description: description,
          id: recognitionId,
          point: eventPointValue,
        //   status: status
        }
      ]
    };
    
    this.PostRecognition(RecognitionDetails);
  };

  PostRecognition = async(payload)=>{
    try{
      const res = await fetchApi(AddCompanyExemplifiesValues, "POST",payload);
      if(res?.data?.message){
        toast.error(res.data.message);
        this.setState({
          buttonStatus: false,
        });
      }else{
        toast.success(res.data[0]);
        this.props.history.push(`/company/recognition/addcorevalues`);
      }
    }catch(error){
      toast.error(error)
    }
  }



  getCoreValueIcons = async()=>{
    let apiUrl = GetCoreValueIcons;
    try {
      const res = await fetchApi(apiUrl , "GET");
      window.console.log("resssis for api",res)
      if (res.status === "success") {
        window.console.log("hiii")
        // this.setState({iconData: res.data})

      } else {
        toast.error(res.error);
      }
    } catch (error) {
      window.console.log(error);
    }
  }


  onChangeDescription = (evt) => {
    const newContent = evt.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, "");
    trimmedString.length === 0 ||
    trimmedString.length === 7 ||
    trimmedString == "<p></p><p></p>"
      ? this.setState({ description: "" })
      : this.setState({ description: newContent });
  };

  updateStep = (value) => {
    this.setState({ step: value, buttonClickScrollTop: true });
  };

  nextStep = (value) => {
    this.setState({
      step: value,
      buttonClickScrollTop: true,
      buttonStatus: false,
    });
  };

  redirect = () => {
    this.props.history.goBack();
  };





  renderHeading = () => (
    <Heading color={"#184A61"}>
      <div className="close-icon" onClick={this.redirect}>
        {RecognitionCloseIcon('#F37A65')}
      </div>
      {this.props.t("Create Core Value ")}
    </Heading>
  );

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };


  toggleModal = () => {
    this.setState((prevState) => ({ showModal: !prevState.showModal }));
  };

  stepOneForm = () => {
    const {
      title,
      description,
      step,
      //   iconColor,
      showModal,
      // iconData,
      //   icon,
      selectedIcon,
      // selectedColor,
    } = this.state;
    const { t } = this.props;

    window.console.log("selectedIcon",selectedIcon)
    return (
      <Layout>
        <div className="main" >
          {this.renderHeading()}
          <Container color="#005C87">
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="create-core-value" padding="20px 20px 265px 20px"/>
            </div>
            <div className="form">
              {/* <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName" style={{color:"#005c87"}}>{this.props.t("Create your custom company core value")}</div>
              </div> */}
              <div className="formBody">
                <InputContainer>
                  <StyledLabel color={"#184A61"} margin="0px">{this.props.t("Title of the core value")}</StyledLabel>
                  <StyledInputV2
                    placeholder={t("Enter text")}
                    name="title"
                    value={title}
                    maxLength="25"
                    style={{background:"white"}}
                    pColor={"rgba(0, 92, 135, 0.30)"}
                    color="#005c87"
                    onChange= { this.onChangeInput}
                    border={"1px solid rgba(0, 122, 177, 0.30)"}
                  />
                </InputContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>
                {/* <InputContainer>
                  <StyledLabel color={"#005c87"}>{this.props.t("Core Value icon")}{<span>*</span>}</StyledLabel>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    {selectedIcon && (
                      <img
                        src={`${ImageUrl}/${selectedIcon.blue_icon}`} 
                        alt="Icon"
                        style={{ width: '24px', height: '24px', marginRight: '8px' }} 
                      />
                    )}


                    <StyledInputV2
                      placeholder={t("Select icon")}
                      name="icon"
                      // value={icon}
                      maxLength="100"
                      //   value={selectedIcon ? selectedIcon.blue_icon : ""} 
                      style={{background:"white"}}
                      pColor={"#649bb3"}
                      color="#005c87"
                      onClick={this.toggleModal} 
                      readOnly 
                      // onChange= { this.onChangeInput}
                      border={"1px solid rgba(0, 122, 177, 0.30)"}
                    />

                  </div>
                </InputContainer> */}
                <InputContainer>
                  <StyledLabel color={"#184A61"}>
                    {this.props.t("Core value icon")}
                   
                  </StyledLabel>
                
                  <div style={{ position: 'relative', display: 'flex', width:"100%" }}>
                    {selectedIcon && (
                      <img
                        src={`${ImageUrl}/${selectedIcon}`} 
                        alt="Icon"
                        style={{
                          position: 'absolute',
                          left: '10px', 
                          top: '50%',
                          transform: 'translateY(-50%)', 
                          width: '36px', 
                          height: '36px', 
                          pointerEvents: 'none', 
                        }}
                      />
                    )}
                
                    <StyledInputV2
                      placeholder={selectedIcon ?  "" : this.props.t("Select icon")}
                      name="icon"
                      maxLength="100"
                      value={""}
                      style={{
                        background: "white",
                        // paddingLeft: selectedIcon ? '12px' :'35px', 
                        width:"100%"
                      }}
                      pColor={"rgba(0, 92, 135, 0.30)"}
                      color="#184A61"
                      onClick={this.toggleModal}
                      readOnly
                      border={"1px solid rgba(0, 122, 177, 0.30)"}
                    />
                  </div>
                </InputContainer>
                
                {showModal && (
                  
                  <StyledModalV1 show={showModal} onHide={this.handleCloseModal} width="600px" height="424px">
                    <div className="modal-content-padding">
                      <div className="header-wrapper">
                        <div className="heading">{t("Choose Core Value Icon")}</div>
                        <div className="close-icon" onClick={this.handleCloseModal}>
                          {AdminCloseIcon()}
                        </div>
                      </div>
                      <Border style={{background:'#EFEFEF',margin:'16px 0px'}}/>
                      <IconGrid>
                        {this.state.iconData && this.state.iconData.length > 0 ? (
                          this.state.iconData.map((icon, index) => (
                            <IconWrapper 
                              key={index}
                              isSelected={this.state.selectedIcon === index} 
                              onClick={() => this.handleIconClick(index)}>
                              {this.state.selectedIcon === index
                                ? AdminWhiteHeartIcon() 
                                : AdminHeartIcon()}
                            </IconWrapper>
                          ))
                        ) : (
                          <p>No icons available</p>
                        )}
                      </IconGrid>
                      <Border style={{background:'#EFEFEF',margin:'16px 0px'}}/>
                      <ModalFooter>
                        <button className="confirm-btn" onClick={this.handleCloseModal}>
                          <div> {("Select Icon")}</div>
                          <div className="icon"> {RecognitionTickIcon("white")}</div> 
                        </button>
                      </ModalFooter>
                    </div>
                  </StyledModalV1>
                )}

                {/* <CommonDropdown
                  tileContainerStyle={{ width: "100%",padding:"0px 0px 20px 0px" }}
                  dropdownStyle={{ top: "90px" }}
                  labelText={this.props.t("Background color")}
                  // isRequired={true}
                  dropdownOpen={this.state.so}
                  placeholder={this.props.t("Select background color for the icon")}
                  title={
                    selectedColor?.name
                  }
                  id="dropdown-recurring"
                  onClick={() => this.setState({ so: !this.state.so })}
                  data={this.backgroundColorName}
                  onSelect={this.onSelectColors}
                  active={selectedColor?.colorCode}
                  onSelectParameter={["name", "colorCode", "colorKey"]}
                  //   valueString={"name"}
                  itemValue={true}
                  valueIndex={0}
                  labelMargin={"0px"}
                />        */}

                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>
                <InputContainer>
                  <StyledLabel color={"#184A61"} margin={"0px"}>{this.props.t("Background color")}</StyledLabel>
                  <StyledInputV2
                    placeholder={t("Select background color for the icon")}
                    name="color"
                    value={ this.state.selectedColorV1?.name}
                    maxLength="100"
                    style={{background:"white"}}
                    pColor={"rgba(0, 92, 135, 0.30)"}
                    color="#005c87"
                    // onChange= { this.onChangeInput}
                    onClick={this.handleModal}
                    border={"1px solid rgba(0, 122, 177, 0.30)"}
                  />
                </InputContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>
          
                <StyledModalV1 show={this.state.isModalOpen} onHide={this.handleCloseColorModal} width="800px" height="240px">
                  <div className="modal-content-padding">
                    <div className="header-wrapper">
                      <div className="heading">{t("Choose Background Color For Icon")}</div>
                      <div className="close-icon" onClick={this.handleCloseColorModal}>
                        {AdminCloseIcon()}
                      </div>
                    </div>
                    <Border style={{background:'#EFEFEF',margin:'16px 0px'}}/>
                    <ColorGrid>
                      {this.state.colors.map((color) => (
                        <ColorBox
                          key={color.colorCode}
                          style={{ background: color.colorCode }}
                          onClick={() => this.onSelectColor(color)}
                        />
                      ))}
                    </ColorGrid>
                    <Border style={{background:'#EFEFEF',margin:'16px 0px'}}/>
                    <ModalFooter>
                      <button className="confirm-btn" onClick={this.handleCloseColorModal}>
                        <div> {("Select Color")}</div>
                        <div className="icon"> {RecognitionTickIcon("white")}</div> 
                      </button>
                    </ModalFooter>
                  </div>
                </StyledModalV1>

               
                {/* <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/> */}
                <InputContainer>
                  <StyledLabel style={{marginTop:"0px"}} color={"#005c87"}>{this.props.t("About the core value")}</StyledLabel>
                  <EditorContainer>
                    <Editor
                      content={description}
                      onChange={this.onChangeDescription}
                    />
                  </EditorContainer>
                </InputContainer>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  stepTwoForm = () => {
    const {
      step,
      eventPointValue,
      pointsArray
    } = this.state;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color={"#005c87"}>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="create-core-value" padding="20px 20px 265px 20px"/>
            </div>
            <div className="form">
              {/* <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">
                  {this.props.t("How would you like to customize your core value?")}
                </div>
              </div> */}
              <div className="formBody">
                <CommonDropdown
                  tileContainerStyle={{ width: "100%",padding:"20px 0px 20px 0px" }}
                  dropdownStyle={{ top: "90px" }}
                  labelText={this.props.t("Core value point")}
                  // isRequired={true}
                  dropdownOpen={this.state.so}
                  placeholder={this.props.t("Select Point Value")}
                  title={
                    eventPointValue?eventPointValue+" Points":+"No Points"
                  }
                  id="dropdown-recurring"
                  onClick={() => this.setState({ so: !this.state.so })}
                  data={pointsArray}
                  onSelect={this.onSelectPoints}
                  active={eventPointValue}
                  onSelectParameter={["eventPointValue", "value", "key"]}
                  valueString={"Points"}
                  itemValue={true}
                  valueIndex={1}
                  labelMargin={"0px"}
                />        
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };




  render() {
    const {
      // title,
      //   imgSrc,
      step,
      buttonStatus,
      // selectedColor,
      // selectedIcon,
      // eventPointValue,
      // description
    } = this.state;
    return (
      <React.Fragment>
        {step === 1
          ? this.stepOneForm()
          : step === 2
            ? this.stepTwoForm()
            : null}
        {step === 1 ? (
          <ButtonContainer>
            <Main>
              <CommonButton
                btnType={"squareIcon"}
                onClick={() => this.updateStep(2)}
                // disabled={!title || !selectedColor || !selectedIcon || !description}
                title={this.props.t("Next")}
                styles={{color:"#F37A65", border:"#F37A65",padding:"16px 20px", width:"auto", background:"#F37A65",fontFamily:"Lato",fontSize:"16px"}}
                nextIcon={AdminRightArrowIcon()}
              />
            </Main>
          </ButtonContainer>
        ) :
          step === 2 && (
            <ButtonContainer>
              <div className="wrapper">
                <CommonButton
                  btnType={"squareIcon"}
                  styles={{color:"white",textColor:"#184A61",border:"#F37A65",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center"}}
                  onClick={() => this.nextStep(1)}
                  title={this.props.t("Back")}
                  icon={AdminLeftArrowIcon()}
                />
                <CommonButton
                  btnType={"squareIcon"}
                  // disabled={!eventPointValue}
                  styles={{color:"#F37A65", marginAuto:'auto',display:"flex",border:"#F37A65",width:"202px"}}
                  onClick={!buttonStatus? () => this.submitCoreValues():''}
                  title={this.props.t("Create Core Value")}
                  nextIcon={LaunchIcon()}
                />
              </div>
            </ButtonContainer>
          )}
      </React.Fragment>
    );
  }
}

CreateCoreValue.propTypes = {
  history: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  t: PropTypes.func,
  companyInfo: PropTypes.object
};

export default (withRouter(withTranslation()(CreateCoreValue)));
