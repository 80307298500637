import React, { useEffect, useState } from 'react';
import { MainContainer } from './../Recognition/RecognitionActivity/styles';
import PropTypes from "prop-types";
import { PerformanceMain, YearlyCard } from './styles';
import { Table,ScrollableContainer } from '../common/commonStyles';
import { generateMembers } from '../../utils/methods';
import { ImageUrl } from '../../utils/constants';
const dummyData = [
  { month: 'January', members: generateMembers() },
  { month: 'February', members: generateMembers() },
  { month: 'March', members: generateMembers() },
  { month: 'April', members: generateMembers() },
  { month: 'May', members: generateMembers() },
  { month: 'June', members: generateMembers() },
  { month: 'July', members: generateMembers() },
  { month: 'August', members: generateMembers() },
  { month: 'September', members: generateMembers() },
  { month: 'October', members: generateMembers() },
  { month: 'November', members: generateMembers() },
  { month: 'December', members: generateMembers() },
];
const QuarterlyDummyData = [
  { month: 'January-March | Q1', members: generateMembers() },
  { month: 'April-June | Q2', members: generateMembers() },
  { month: 'July-September | Q3', members: generateMembers() },
  { month: 'October-December | Q4', members: generateMembers() }
];
const Performance = ({selectedFilter}) => {
  const [showAll, setShowAll] = useState({}); // Initialize as an empty object
  const [data, setData] = useState(dummyData);
  useEffect(() => {
    if(selectedFilter === "Quarterly"){
      setData(QuarterlyDummyData)
    }else{
      setData(dummyData)
    }
  },[selectedFilter])

  const handleShowMore = (month) => {
    setShowAll((prevState) => ({
      ...prevState,
      [month]: true, // Set to true to show all rows for the month
    }));
  };
  return (
    <MainContainer margin>
      <PerformanceMain>
        {selectedFilter === "Yearly"?  data.map((yearData, index) => (
          <div className='yearly' style={{ display: "block" }} key={index}>
            <YearlyCard>
              <div className='profile'>
                <img src={ImageUrl + yearData.members[0].image} alt={'no-image'} />
              </div>
              <div className='name'>{"Nathan"}</div>
              <div className='count'>{"200"}</div>
              <div className='department'>{"Product"}</div>
              <div className='location'>{"New york"}</div>
            </YearlyCard>
          </div>
        )) : data.map((monthData, index) => (
          <div className={selectedFilter === "Quarterly" ? 'quarterly' :'month'} style={{ display: "block" }} key={index}>
            <Table padding="8px 0" insight>
              <div className="heading">{monthData.month}</div>
              <div className="type">
                <div className="name">Employee</div>
                <div className="value">Total</div>
              </div>
              <ScrollableContainer
                height={showAll[monthData.month] ? "380px" : "330px"}
                showAllRows={showAll[monthData.month]}
              >
                {monthData.members
                  .slice(0, showAll[monthData.month] ?  monthData.members.length : 5)
                  .map((data, memberIndex) => (
                    <div className="item" key={memberIndex}>
                      <div className="profile">
                        <img src={ImageUrl + data.image} alt={data.name} />
                      </div>
                      <div className="name">{data.name}</div>
                      <div className="value">{data.count}</div>
                    </div>
                  ))}
              </ScrollableContainer>
              {monthData.members.length > 6 && !showAll[monthData.month] && (
                <div className="showmore">
                  <div
                    className="button"
                    onClick={() => handleShowMore(monthData.month)}
                  >
                    Show More
                  </div>
                </div>
              )}
            </Table>

          </div>
        ))}
      </PerformanceMain>
    </MainContainer>
  );
};
Performance.propTypes = {
  getColor: PropTypes.func.isRequired,
  selectedFilter:PropTypes.string
};
export default Performance;