import React, { useState } from 'react';
import { MainContainer, TopContainerWrapper } from './../Recognition/RecognitionActivity/styles';
import PropTypes from "prop-types";
import { TabBottomLine, TabWrapper, Heading, ManagerCard, PerformanceMain, BorderBottom, ScrollableContainer } from './styles';
import { Table } from '../common/commonStyles';
import { ImageUrl } from "../../utils/constants";
import BarChart from '../common/Manager/BarChart';
import { generateMembers } from '../../utils/methods';
import { AdminInsightsIcon, AdminRightArrowIcon, RecognitionCoreValueIcon, RecognitionOverviewIcon } from '../../utils/icons';

const dummyData = [
  {
    "full_name": "Ajay Sharma",
    "total_recognitions": 19,
    image:"profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Arpit Malviya",
    "total_recognitions": 14,
    image:"profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Eshwar Deshmukh",
    "total_recognitions": 9,
    image:"profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Dhairya Vora",
    "total_recognitions": 1,
    image:"profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Ajay Sharma",
    "total_recognitions": 19,
    image:"profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Arpit Malviya",
    "total_recognitions": 14,
    image:"profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Eshwar Deshmukh",
    "total_recognitions": 9,
    image:"profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Dhairya Vora",
    "total_recognitions": 1,
    image:"profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Eshwar Deshmukh",
    "total_recognitions": 9,
    image:"profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Dhairya Vora",
    "total_recognitions": 1,
    image:"profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Eshwar Deshmukh",
    "total_recognitions": 9,
    image:"profile-Image/Avatars/Avatar-5.png"
  }
]

const dummyOverviewData = [
  { month: 'Growth', members: generateMembers(), icon:"",bg:""},
  { month: 'Well Done', members: generateMembers(), icon:"",bg:""},
  { month: 'Quality', members: generateMembers(), icon:"", bg:"" },
  { month: 'Gratitude', members: generateMembers(), icon:"", bg:"" },
  { month: 'Loyalty', members: generateMembers(), icon:"", bg:"" },
  { month: 'More with Less', members: generateMembers(), icon:"", bg:"" },
  { month: 'Passion', members: generateMembers(), icon:"", bg:"" },
  { month: 'Growth', members: generateMembers(), icon:"", bg:"" },
  { month: 'Collaboration', members: generateMembers(), icon:"", bg:"" }
];

const Insights = ({ getColor, selectedFilter }) => {
  const [activeTab, setActiveTab] = useState('Managers');
  const [showTopRecogntion, setShowTopRecogntion] = useState(false);
  const [showEliteRecogntion, setShowEliteRecogntion] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const topdepartment = [
    { name: 'Element 1', value: 70, image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#FFF3ED" },
    { name: 'Element 2', value: 60 , image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#FFF3ED"},
    { name: 'Element 3', value: 50 , image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#F0F7FF"},
    { name: 'Element 4', value: 40 , image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#FEF2F3"},
    { name: 'Element 5', value: 30 , image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#F0F7FF"},
    { name: 'Element 6', value: 20 , image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#FEF2F3"},
    { name: 'Element 7', value: 70, image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#FFF3ED" },
    { name: 'Element 8', value: 60 , image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#FFF3ED"},
    { name: 'Element 9', value: 50 , image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#F0F7FF"},
    { name: 'Element 10', value: 40 , image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#FEF2F3"},
    { name: 'Element 11', value: 30 , image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#F0F7FF"},
    { name: 'Element 12', value: 20 , image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#FEF2F3"},
    { name: 'Element 13', value: 30 , image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#F0F7FF"},
    { name: 'Element 14', value: 20 , image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#FEF2F3"},
  ];
  

  const displayedTopDepartments = showTopRecogntion ? topdepartment : topdepartment.slice(0, 5);

  const displayedEliteDepartments = showEliteRecogntion ? topdepartment : topdepartment.slice(0, 5);

  const toggleShowTopRecognition = () => {
    setShowTopRecogntion(!showTopRecogntion);
  };

  const toggleShowEliteRecognition = () => {
    setShowEliteRecogntion(!showEliteRecogntion);
  };

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const handleShowMore = (month) => {
    setShowAll((prevState) => ({
      ...prevState,
      [month]: true, // Set to true to show all rows for the month
    }));
  };

  function getColorByIndex(index) {
    // Define an array of colors or generate colors dynamically
    const colors = ["#75B084","#92A4DA","#BE7D9C", "#DA92C8", "#DA9B6C", "#75B084"];
  
    // Return the color based on the index, repeating if necessary
    return colors[index % colors.length];
  }

  const RecognitionOverviewCard = (data ) => {
    window.console.log("hi");
    
    return (
      <>{data.map((monthData, index) => (
        <div className={selectedFilter === "Quarterly" ? 'quarterly' :'month'} style={{ display: "block" }} key={index}>
          <Table padding="8px 0" insight headingIconBg={getColorByIndex(index)}>
            <div className='heading'>
              <span className='icon'>{RecognitionCoreValueIcon()}</span>
              {monthData.month}
            </div>
            <div className='type'>
              <div className='name'>Employees</div>
              <div className='value'>Received</div>
            </div>
            <ScrollableContainer
              height={showAll[monthData.month] ? "380px" : "330px"}
              showAllRows={showAll[monthData.month]}
            >
              {monthData.members.slice(0, showAll[monthData.month] ? monthData.members.length : 5).map((data, memberIndex) => (
                <div className='item' key={memberIndex}>
                  <div className='profile'>
                    <img src={ImageUrl + data.image} alt={data.name} />
                  </div>
                  <div className='name'>{data.name}</div>
                  <div className='value'>{data.count}</div>
                </div>
              ))}
            </ScrollableContainer>
            {monthData.members.length > 6 && !showAll[monthData.month] && (
              <div className="showmore">
                <div
                  className="button"
                  onClick={() => handleShowMore(monthData.month)}
                >
                  Show More
                </div>
              </div>
            )}
          </Table>
        </div>
      ))}
      </>
    )
  }

  const managerView = () => {
    const topdepartmentChunks = chunkArray(topdepartment, 4);
    const displayedChunks = showAll ? topdepartmentChunks : topdepartmentChunks.slice(0, 3);
    return(
      <>
        <BarChart total={2} widthType={"full"} data={dummyData} id={"top"} heading={"Top Performing Managers"}/>
        <Heading>
          <div className='icon'>
            <img src={ImageUrl + "/admin-recognition/icons/star.svg"} />
          </div>
            Woliba Insights
        </Heading>
        <Table paddingM={showAll && topdepartment.length > 12&&"16px 8px 8px 16px"} padding="8px 0" insight margin="20px 0 20px 0" height={showAll && topdepartment.length > 12?"290px":''} scroll={showAll && topdepartment.length}>
          <div className='heading'>
          Managers (25)
          </div>
          <div className='manager-wrap'>
            {displayedChunks.map((chunk, chunkIndex) => (
              <div key={chunkIndex} className='wrap'>
                {chunk.map((data, index) => (
                  <ManagerCard key={index} background={data.color}>
                    <div className='profile'>
                      <img src={data.image} />
                    </div>
                    <div className='name'>{data.name}</div>
                    <div className='arrow'>
                      {AdminRightArrowIcon("#F37A65")}
                      {/* <img src={ImageUrl + "/admin-recognition/arrow.svg"} /> */}
                    </div>
                  </ManagerCard>
                ))}
                <div style={{height:"1px", width:"100%", background:"#EFEFEF"}}></div>
              </div>
            ))}
          </div>
          {!showAll && topdepartment.length > 12 && (
            <div className='showmore'>
              <div className='button' onClick={() => setShowAll(true)}>Show More</div>
            </div>
          )}
        </Table>
        <div className='top-performing' style={{display:"block"}}>
          <Table padding="8px 0" insight>
            <div className="heading">Most Recognized Managers</div>
            <div className="type">
              <div className="name">Managers</div>
              <div className="value">Received</div>
            </div>
            <ScrollableContainer
              height={showTopRecogntion ? "380px" : "330px"}
              showAllRows={showTopRecogntion}
            >
              {displayedTopDepartments.map((data, index) => (
                <div className="item" key={index}>
                  <div className="profile">
                    <img src={data.image} alt={data.name} />
                  </div>
                  <div className="name">{data.name}</div>
                  <div className="value">{data.value}</div>
                </div>
              ))}
            </ScrollableContainer>
            {topdepartment?.length > 5 && !showTopRecogntion && (
              <div className="showmore">
                <div className="button" onClick={toggleShowTopRecognition}>
                  Show More
                </div>
              </div>
            )}
          </Table>

        </div>
        <div className='low-performing' style={{display:"block"}}>
          <Table padding="8px 0" insight>
            <div className="heading">Most Givers of Recognition</div>
            <div className="type">
              <div className="name">Managers</div>
              <div className="value">Received</div>
            </div>
            <ScrollableContainer
              height={showEliteRecogntion ? "380px" : "330px"}
              showAllRows={showEliteRecogntion}
            >
              {displayedEliteDepartments.map((data, index) => (
                <div className="item" key={index}>
                  <div className="profile">
                    <img src={data.image} alt={data.name} />
                  </div>
                  <div className="name">{data.name}</div>
                  <div className="value">{data.value}</div>
                </div>
              ))}
            </ScrollableContainer>
            {topdepartment?.length > 5 && !showEliteRecogntion && (
              <div className="showmore">
                <div className="button" onClick={toggleShowEliteRecognition}>
                  Show More
                </div>
              </div>
            )}
          </Table>
          
        </div>
        <BarChart total={2} widthType={"full"} data={dummyData} heading={"Least Recognized Managers"} style={{marginTop:"20px"}}/>
      </>)
  }

  const emlpoyeeView = () => {
    // const topdepartmentChunks = chunkArray(topdepartment, 4);
    // const displayedChunks = showAll ? topdepartmentChunks : topdepartmentChunks.slice(0, 3);
    window.console.log("hi");
    return(
      <>
        <BarChart total={2} widthType={"full"} data={dummyData} id={"top"} heading={"Top 10 Performing Employees | Overall"}/>
        <Heading>
          <div className='icon'>
            {AdminInsightsIcon()}
          </div>
            Woliba Insights
        </Heading>

        <div className='top-performing' style={{display:"block"}}>
          <Table padding="8px 0" insight>
            <div className="heading">  Most Recognized Employees</div>
            <div className="type">
              <div className="name">Employees</div>
              <div className="value">Received</div>
            </div>
            <ScrollableContainer
              height={showTopRecogntion ? "380px" : "330px"}
              showAllRows={showTopRecogntion}
            >
              {displayedTopDepartments.map((data, index) => (
                <div className="item" key={index}>
                  <div className="profile">
                    <img src={data.image} alt={data.name} />
                  </div>
                  <div className="name">{data.name}</div>
                  <div className="value">{data.value}</div>
                </div>
              ))}
            </ScrollableContainer>
            {topdepartment?.length > 5 && !showTopRecogntion && (
              <div className="showmore">
                <div className="button" onClick={toggleShowTopRecognition}>
                  Show More
                </div>
              </div>
            )}
          </Table>
        </div>
        <div className='low-performing' style={{display:"block"}}>
          <Table padding="8px 0" insight>
            <div className="heading">Most Givers of Recognition</div>
            <div className="type">
              <div className="name">Employees</div>
              <div className="value">Gave</div>
            </div>
            <ScrollableContainer
              height={showEliteRecogntion ? "380px" : "330px"}
              showAllRows={showEliteRecogntion}
            >
              {displayedEliteDepartments.map((data, index) => (
                <div className="item" key={index}>
                  <div className="profile">
                    <img src={data.image} alt={data.name} />
                  </div>
                  <div className="name">{data.name}</div>
                  <div className="value">{data.value}</div>
                </div>
              ))}
            </ScrollableContainer>
            {topdepartment?.length > 5 && !showEliteRecogntion && (
              <div className="showmore">
                <div className="button" onClick={toggleShowEliteRecognition}>
                  Show More
                </div>
              </div>
            )}
          </Table>
        </div>
        <BarChart total={2} widthType={"full"} data={dummyData} heading={"Employees Requiring Improvement"} style={{marginTop:"20px"}}/>

        <Heading>
          <div className='icon'>
            {RecognitionOverviewIcon()}
            {/* <img src={ImageUrl + "/admin-recognition/icons/star.svg"} /> */}
          </div>
          Recognition Overview
        </Heading>
        <PerformanceMain>
          {RecognitionOverviewCard(dummyOverviewData)}
        </PerformanceMain>
      </>)
  }

  return (
    <MainContainer margin>
      <TopContainerWrapper style={{ flexWrap: "wrap" }}>
        <TabWrapper>
          <TabBottomLine active={activeTab === 'Managers'} background={getColor} onClick={() => setActiveTab('Managers')}>
          Managers
            <BorderBottom active={activeTab === 'Managers'} background={getColor} />
          </TabBottomLine>
          <TabBottomLine active={activeTab === 'Employees'} background={getColor} onClick={() => setActiveTab('Employees')}>
          Employees
            <BorderBottom active={activeTab === 'Employees'} background={getColor} />
          </TabBottomLine>
        </TabWrapper>
        {activeTab === "Managers" ?  managerView() : null}
        {activeTab === "Employees" ?  emlpoyeeView() : null}
      </TopContainerWrapper>
    </MainContainer>
  );
};

Insights.propTypes = {
  getColor: PropTypes.func.isRequired,
  selectedFilter:PropTypes.string
};

export default Insights;