/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StepsWrapper, Steps } from './styles';
import { withTranslation } from 'react-i18next';
import { Border } from '../../EventDetails/styles';

class StepsHeading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: 1,
      stepsSurvey:[
        {
          name: 'About The Survey',
          showArrow: true,
          number: 1
        },
        {
          name: 'Questions to Ask',
          showArrow: false,
          number: 2
        },
        {
          name: 'Launch a Survey',
          showArrow: false,
          number: 3
        }
      ]

    }
  }


  stepsHeadingQuiz = [
    {
      name: 'About Quiz',
      showArrow: true,
      number: 1
    },
    {
      name: 'Customize Quiz',
      showArrow: true,
      number: 2
    },
    {
      name: 'Invite Users',
      showArrow: true,
      number: 3
    },
    {
      name: 'Launch Quiz',
      showArrow: false,
      number: 4
    }
  ];


  stepsHeadingEvent = [
    {
      name: 'About Event',
      showArrow: true,
      number: 1
    },
    {
      name: 'Customize Event',
      showArrow: true,
      number: 2
    },
    {
      name: 'Invite Users',
      showArrow: true,
      number: 3
    },
    {
      name: 'Launch Event',
      showArrow: false,
      number: 4
    }
  ];

  stepsHeadingOndemand = [
    {
      name: 'About Event',
      showArrow: true,
      number: 1
    },
    {
      name: 'Create Event',
      showArrow: false,
      number: 2
    }
  ];

  stepsFitnessExercise = [
    {
      name: 'About The Exercise',
      showArrow: true,
      number: 1
    },
    {
      name: 'Set Exercise Focus area and age Groups',
      showArrow: false,
      number: 2
    }
  ];

  stepsFitnessWorkout = [
    {
      name: 'About The Workout',
      showArrow: true,
      number: 1
    },
    {
      name: 'Add Exercise',
      showArrow: false,
      number: 2
    }
  ];

  stepsLaunchCompanyInitiative = [
    {
      name: 'About Initiative',
      showArrow: true,
      number: 1
    },
    {
      name: 'Customize Initiative',
      showArrow: true,
      number: 2
    },
    {
      name: 'Invite Users',
      showArrow: true,
      number: 3
    },
    {
      name: 'Launch Initiative',
      showArrow: false,
      number: 4
    }
  ];

  stepsHeadingHealthProgram = [
    {
      name: 'About Program',
      showArrow: true,
      number: 1
    },
    {
      name: 'Create Program',
      showArrow: false,
      number: 2
    }
  ];

  surveyLibrary = [
    {
      name: 'About Survey',
      showArrow: true,
      number: 1
    },
    {
      name: 'Customize Survey',
      showArrow: false,
      number: 2
    }
  ];

  stepsSurvey=[
    {
      name: 'About Survey',
      showArrow: true,
      number: 1
    },
    {
      name: 'Customize Survey',
      showArrow: true,
      number: 2
    },
    {
      name: 'Invite Users',
      showArrow: true,
      number: 3
    },
    {
      name: 'Launch Survey',
      showArrow: false,
      number: 4
    }
  ]

  stepsHeadingCoreValue = [
    {
      name: 'About Core Value',
      showArrow: true,
      number: 1
    },
    {
      name: 'Customize Core Value',
      showArrow: false,
      number: 2
    }
  ];


  stepsHeadingCreateCoreValue = [
    {
      name: 'About Core Value',
      showArrow: true,
      number: 1
    },
    {
      name: 'Customize Core Value',
      showArrow: false,
      number: 2
    }
  ];


  


  render() {
    const { stepCount,type } = this.props;
    const heading =  type==="quiz" ?this.stepsHeadingQuiz:type=== "create-core-value" ? this.stepsHeadingCreateCoreValue : type==="core-value"?this.stepsHeadingCoreValue: type==="event" ? this.stepsHeadingEvent: type==="exercise" ? this.stepsFitnessExercise : type==="workout" ? this.stepsFitnessWorkout : type==="initiative" ? this.stepsLaunchCompanyInitiative : type==="health-program" ? this.stepsHeadingHealthProgram : type==="survey-library" ? this.surveyLibrary :  type==="survey" ? this.stepsSurvey  : type === "create-coach" ? [{
      name: 'About Coach',
      showArrow: true,
      number: 1
    },{
      name: 'Create Coach',
      showArrow: false,
      number: 2
    }] : this.stepsHeadingOndemand;
    return (
      <StepsWrapper>
        {heading.map((step, index) =>
          (<Steps key={index} active={index+1==stepCount?true:false} completed={index+1<stepCount?true:false}>
            <div className="wrapper">
              <div className="number">{step.number}</div>
              <div className="heading"style={{color: index+1<=stepCount ? "#184A61" : "#BDBDBD"}}>{this.props.t(step.name)}</div>
            </div>
            {step.showArrow &&<Border style={{border:"1px solid #EFEFEF",margin:"20px 0px",width:"100%"}}/>}
          </Steps>))}
      </StepsWrapper>
    );
  }
}

StepsHeading.propTypes = {
  history: PropTypes.object,
  stepCount: PropTypes.number,
  type: PropTypes.string,
  isLaunch :PropTypes.bool,
  t: PropTypes.func
};

export default ((withTranslation())(StepsHeading));