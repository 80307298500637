import React, { useEffect } from 'react';
import { fetchApi } from '../../../utils/methods';
import { GetLowPerformers, GetTopPerformers } from '../../../redux/constants/apiConstants';
import BarChart from '../../common/Manager/BarChart';
import { StyledContainer } from './style';


const TopLowPerformer = () => {
  const[lowPerformers,setLowPerformers] = React.useState([]);
  const[topPerformers,setTopPerformers] = React.useState([]);
  const [totalTopPerformers,setTotalTopPerformers] = React.useState(0);
  const [totalLowPerformers,setTotalLowPerformers] = React.useState(0);

  useEffect(()=>{
    fetchTopPerformers();
    fetchLowPerformers();
  },[]);

  const fetchTopPerformers = async() => {
    // fetch top performers
    const res = await fetchApi(GetTopPerformers + "/5","GET");
    if(res.status === "success"){
      setTopPerformers(res.data.topPerformingMembers);
      setTotalTopPerformers(res.data.recognitionCount);

    }
  } 

  const fetchLowPerformers = async() => {
    // fetch top performers
    const res = await fetchApi(GetLowPerformers + "/5","GET");
    if(res.status === "success"){

      setLowPerformers(res.data.lowPerformingMembers);
      setTotalLowPerformers(res.data.totalRecognition);

    }
  } 


  return (
    <>
      <StyledContainer marginTop={(totalTopPerformers=== 0 && totalLowPerformers === 0) ? "20px":"40px"}>
        <div style={{width:"50%"}}>{totalTopPerformers > 0 ? <BarChart data={topPerformers} heading='Top Performing Team Members | Overall' total={totalTopPerformers} countKey='total_recognitions' imageKey='profile_image' id={1}/>:null}</div>
        <div style={{width:"50%",marginLeft:"20px"}}>{totalLowPerformers > 0 ?<BarChart data={lowPerformers} heading='Low Performing Team Members | Overall' total={totalLowPerformers} countKey='total_recognitions' imageKey='profile_image' id={2} circleColor={"#E12133"} circleBorderColor={"#FC6D7B"} barColor={"#FEA3AC"}/>:null}</div>
      </StyledContainer>
    </>
  )
}

export default TopLowPerformer