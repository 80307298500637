/* eslint-disable camelcase*/
import React,{ Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';

import { SocialFeedsV2 } from '../../components';

import { getSocialPosts, acceptOrRejectFriendRequest,
  getWellnessAssessment, managePostLikedUsers} from '../../redux/actions';
import { GetTeamMembersDetails } from '../../redux/constants/apiConstants'; 
import SkeletonLoder from '../../components/common/skeletonLoder';
import { fetchApi } from '../../utils/methods';
import { toast } from 'react-toastify';

class Social extends Component {
  
  constructor() {
    super();
    this.state = {
      filterType: 'Company',
      limit: 10,
      teamMemberData:[]
    }
  }



  
  fetchTeamMembersData = async () => {
    const apiUrl = `${GetTeamMembersDetails}`;

    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {

        this.setState({ teamMemberData: res.data });
        // setTeamMembersData(res.data);
        // setTotalTeamMember(res.data.length);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  componentDidMount() {
    
    const { fetchSocialPosts, getWellnessAssessment,location } = this.props;
    // const { filterType, limit } = this.state;
    const { limit } = this.state;

    // Check if the current route contains "manager" and update the filterType
    const filterType = location.pathname.includes('manager') ? 'Manager' : 'Company';
    // Update the state and fetch data accordingly
    this.setState({ filterType }, () => {
      fetchSocialPosts(this.state.filterType, limit, 1);
    });

    // fetchSocialPosts(filterType,limit, 1);
    getWellnessAssessment();
    this.fetchTeamMembersData();
  }






  socialCallBack = () => {
    const { filterType } = this.state;
    const { fetchSocialPosts } = this.props;
    fetchSocialPosts(filterType, this.state.limit, 1);
  }
  // callBack = (index) => {
  //   const { fetchSocialPosts } = this.props;
  //   let setFilterState = '';
  //   switch (index) {
  //   case 0: {
  //     fetchSocialPosts("Company", 1);
  //     setFilterState = "Company";
  //   }
  //     break;
  //   case 1: {
  //     fetchSocialPosts("My Amigos", 1);
  //     setFilterState = "My Amigos";
  //   }
  //     break;
  //   case 2: {
  //     fetchSocialPosts("Recognition", 1);
  //     setFilterState = "Recognition";
  //   }
  //     break;
  //   }
  //   this.setState({
  //     filterType: setFilterState,
  //   });
  // }
  setActiveTab = (value) => {
    const { fetchSocialPosts } = this.props;
    this.setState({
      filterType: value
    }, () => fetchSocialPosts(this.state.filterType, this.state.limit, 1));
  };

  loadMoreFeeds = (pageNumber) => {
    const { filterType, limit } = this.state;
    const { fetchSocialPosts } = this.props;
    fetchSocialPosts(filterType,limit, pageNumber)
  };
  updateFeedsLike = (storedFeeds) => {
    managePostLikedUsers(storedFeeds);
  }
  render() {
    const { socialPosts, errorMessage, history, acceptOrRejectFriendRequest, networkError,
      wellnessAssessment, role, companyId, user } = this.props;
    const {teamMemberData}= this.state;
    const latestMember = teamMemberData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];
  

    if((!socialPosts  && !errorMessage) ||
      isUndefined(wellnessAssessment) && !networkError) {
      return <div style={{width:'1246px',margin:'auto', display:'flex', gap:'25px'}}>
        <div>
          <SkeletonLoder width={'320px'} height={'444px'} style={{margin:'25px 0px'}}/>
        </div>
        <div>
          <SkeletonLoder width={'556px'} height={'129px'} style={{margin:'35px 0px 25px 0px'}}/>
          <SkeletonLoder width={'556px'} height={'444px'} style={{margin:'25px 0px'}}/>
          <SkeletonLoder width={'556px'} height={'444px'} style={{margin:'25px 0px'}}/>
          <SkeletonLoder width={'556px'} height={'444px'} style={{margin:'25px 0px'}}/>
          <SkeletonLoder width={'556px'} height={'444px'} style={{margin:'25px 0px'}}/>
        </div>
        <div>
          <SkeletonLoder width={'320px'} height={'370px'} style={{margin:'35px 0px 25px 0px'}}/>
          <SkeletonLoder width={'320px'} height={'165px'} style={{marginBottom:'25px'}}/>
          <SkeletonLoder width={'320px'} height={'370px'} style={{marginBottom:'25px'}}/>
          <div style={{width:'320px', marginBottom:'25px'}}>
            <SkeletonLoder width={'200px'} height={'25px'}></SkeletonLoder>
            <SkeletonLoder width={'100%'} height={'50px'} style={{margin:'15px 0px 25px 0px'}}/>
          </div>
        </div>
      </div> 
    }
    return (
      <Switch>
        <Route
          render={() =>
            <SocialFeedsV2
              history={history}
              feeds={socialPosts}
              acceptOrRejectFriendRequest={acceptOrRejectFriendRequest}
              setActiveTab={this.setActiveTab}
              loadMoreFeeds={(pageNumber) => this.loadMoreFeeds(pageNumber)}
              iswbaTaken={get(wellnessAssessment, 'is_wba_taken', 0) === 1}
              role={role}
              activeTab={companyId === 1 ? this.state.filterType : "Company"}
              enableTrigger={true}
              companyId={companyId}
              // callBack = {this.callBack}
              updateFeedsLike={this.updateFeedsLike}
              socialCallBack={this.socialCallBack}
              user={user}
              latestMember={latestMember}
            />
          }
        />
      </Switch>
    )
  }
}

Social.propTypes = {
  socialPosts: PropTypes.object,
  errorMessage: PropTypes.string,
  fetchSocialPosts: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  acceptOrRejectFriendRequest: PropTypes.func.isRequired,
  requestAccepted: PropTypes.string,
  networkError: PropTypes.string,
  getWellnessAssessment: PropTypes.func.isRequired,
  wellnessAssessment: PropTypes.object,
  role: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired,
  managePostLikedUsers: PropTypes.func,
  user: PropTypes.object,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  socialPosts: state.social.response,
  errorMessage: state.social.errorMessage,
  requestAccepted: state.social.requestAccepted,
  networkError: state.social.networkError,
  wellnessAssessment: state.profileData.wellnessAssessment
});

const mapDispatchToProps = (dispatch) => ({
  fetchSocialPosts: (filterType, limit, pageNumber) => dispatch(getSocialPosts(filterType, limit, pageNumber)),
  acceptOrRejectFriendRequest: (acceptData) => dispatch(acceptOrRejectFriendRequest(acceptData)),
  getWellnessAssessment: () => dispatch(getWellnessAssessment()),
  managePostLikedUsers: (res) => dispatch(managePostLikedUsers(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Social));
