import { Modal } from "react-bootstrap";
import styled from "styled-components";

const Heading = styled.div`
display: flex;
width: 100%;
background: white;
border-radius:6px;
font-family: Lato;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 133.333% */
color: ${({ color }) => color ? color : '#184A61'};
height:56px;
padding-left:0px;
align-items: center;
margin-bottom: 25px;

.close-icon{
    margin:0px 12px;
    cursor:pointer;
}
`;

const ShowImage = styled.div`
  width:100%;
  display:flex;
  .image{
    display:flex;
    width: 290px;
    height: 182px;
    border-radius:6px;
    background: ${({background}) => background ? background : "#002f47"};
    >img{
      width:36px;
      height:36px;
      margin:auto;
    }
  }
`

const StepsWrapper = styled.div`
display: block;
width: 100%;
padding: ${({ padding }) => padding ? padding : '12px' };
background:white;
border-radius: 6px;
`;

const Steps = styled.div`
display: flex;
width: 100%;
flex-wrap:wrap;
.wrapper{
    width:100%;
    display:flex;
.number{
  width:40px;
  height:40px;
  background: ${({ active, activeColor, completed }) => activeColor? '#85C0EA' :active ? "#75B084" : completed ? "#DDEEDF"  : '#F8F8F8'};
  margin-right: 20px; 
  font-family: 'Lato';
    font-size: 18px;
    font-weight: 700;
    line-height: 24px; 
  color: ${({ active, activeColor, completed }) => activeColor?'white': active ? "white" :completed ? "#184A61" : '#BDBDBD'};
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius:50%;
}
.heading{
    font-family: 'Lato';
    font-size: 18px;
    font-weight: 700;
    line-height: 24px; 
    color: ${({ active,activeColor }) => activeColor ? "#184A61": active ? "#BDBDBD" : '#184A61'};
    align-items: center;
    display: flex;
}
}
.arrow{
    width:100%;
    display:flex;
    .active{
        width: 40px;
        height:40px;
        display:flex;
        justify-content:center;
        margin:15px 0px;
        >img{
            width: 32px;
            height: 36px;
            ${'' /* transform: rotate(90deg); */}
        }
    }
}
`;

const StyledModal = styled(Modal)`
	.modal-dialog{
        
		display: flex;
    justify-content: center;
    transform: ${({ transform }) => transform && 'translate(0, 80px) translate(0, -90%) !important'};
		.modal-content{
      width: ${({ newWidth }) => newWidth ? newWidth : '100%'};
	  ${'' /* height: auto; */}
	  height: ${({ newHeight }) => newHeight ? newHeight : 'auto'};
	  box-shadow: none;
	  position: absolute;
	  border-radius: 12px;
      padding:16px;
			.modal-header {
                padding:0px !important;
                padding-bottom:20px !important;
				border-bottom: ${({ noBorder }) => noBorder ? noBorder : '1px solid #EFEFEF'};
                background: linear-gradient(0deg, #8AD3F7 0%, #52A8ED 100%);
				border-radius: 6px 6px 0 0;
				height: ${({ height }) => height ? height : ''};
				>button{
				 display: ${({ display }) => display ? display : ''};
				}
				.modal-container {
					width: 100%;
					display: block;
				   }
                   color:  #184A61;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px; 
			}
			.modal-header .close {
				margin-top: -2px;
				outline: none;
				display: none;
			.cross_img {
				float: right;
				> img {
					width: 24px;
					height: 24px;
					cursor: pointer;
				}
			}
		}
	}
`;

const StyledTitle = styled.span`
color:  #184A61;
font-family: Lato;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 133.333% */
>svg{
	display:flex;
	margin:auto 0px auto auto;
}
`;

const StyledBody = styled(Modal.Body)`
 width: 100%;
 padding:0px !important;
 font-family:Lato;
`;

const DetailInitiativeWrapper = styled.div`
width:100%;
display:flex;
flex-wrap:wrap;
margin-top:20px;
.row-one{
	display:flex;
	width:100%;
	justify-content:space-between;
	align-items:center;
    border-radius: 8px;
background:  #F8F8F8;
border-radius: 8px;
padding:12px 8px;
min-width:368px;
min-height:92px;
    .col-one{
        display:flex;
        .image{
      width:60px;
	  height:60px;
	  background: ${({ background }) => background ? background : '#002f47'};
	  border-radius:50%;
	  display:flex;
	  >img{
		width:36px;
		height:36px;
		border-radius:6px;
		margin:auto;
	  }
	}
    }
	.details{
		display:flex;
		flex-direction:column;
		align-items:start;
		justify-content:center;
		margin-left:10px;

		.name{
			font-size:16px; // 18px;
			color:#005C87;
			line-height:24px;
            color:#184A61;
		}
		.points{
            color:  #184A61;
           font-size: 14px;
           font-style: normal;
           font-weight: 400;
           line-height: 20px; 
		}
	}
}
  .row-two{
	margin-top:24px;
	display:flex;
	justify-content:center;
	width:100%;
    align-items:center

    .circularGraphPercent{
      background: rgba(0, 92, 135, 0.05);
      padding: 25px 60px;
      width:100%;
      .value{
        font-family: Rubik-medium;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color:#005C87;
        margin-top:10px;
      } 
      .average{
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color:#005C87;
        padding-left:5px; 
        margin-top:10px;
      }
    }

	.user-count{
    color:  #184A61;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
	}

	.text{
        color: #184A61;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
margin-right:20px;
	}

	.percent{
		font-weight:500;
		font-size:40px;
		line-height:20px;
		${'' /* color:rgba(0, 92, 135, 1); */}
	}
  }
}

`;

const CircleNew = styled.div`
  width: ${({ width }) => width ? width : '136px'};
  height: ${({ height }) => height ? height : '136px'};
  border-radius: 50%;
padding: 10px;
display: flex;
justify-content: center;
align-items: center;
margin: auto;
`;

const ManageAnniversariesContainer = styled.div`

width:100%;
${'' /* display:flex;
flex-wrap:wrap; */}
margin-top:20px;

.first-text{
  color: #184A61;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
margin-top:20px;
line-height: 20px; 
}

.second-text{
  margin-top:16px;
  color:  #CE7841;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
}

.toggle-container{
  margin-top:20px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  align-items:center;
}

.icon{
  display:flex; 
  align-items:center;
  .name{
  margin-left:16px;
  color:  #184A61;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}
}

`

const ToggleButton = styled.div`
  ${'' /* position: absolute; */}
  width: 39px;
  height: 20px;
  ${'' /* right: 25px; */}
  ${"" /* top:15px; */}
  .switch {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #b3cfdb;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 23px;
    width: 40px;
    height: 24px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 19px;
    left: 1px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #76ab78;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
  }

  / Rounded sliders / .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;






//Color popup
export const StyledModalV1 = styled(Modal)`

.modal-dialog {
    max-width: ${({ width }) => width || "800px"};
    width: ${({ width }) => width || "800px"}; 
  }

  .modal-content {
    min-height: ${({ height }) => height || "240px"}; 
  }
  .modal-content-padding {
    padding: 16px;
    text-align: center;
  }

  .header-wrapper{
    display: flex;
    justify-content: space-between; 
    align-items: center;  
  }

  .heading{
    color: #184A61;
    font-family: 'Lato';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; 
    text-align: center;   
    flex: 1;       
  }

  .close-icon {
    cursor: pointer;
    text-align: right;
  }
`;

export const ColorGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 16px;
  margin-top: 16px;
`;

// Individual color boxes
export const ColorBox = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
  }
`;

// Footer for modal buttons
export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;

  .confirm-btn {
    padding: 12px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-family:'Lato';
    width:200px;
    display:flex;
    justify-content:center;
    align-items: center; 
    gap: 8px;
  }
  .confirm-btn {
    background: #f37a65;
    color: white;
  }

  .icon{
  width: ${({ isWidth }) => (isWidth ? isWidth : "14px")};
  Height: ${({ isHeight }) => (isHeight ? isHeight : "10px")};
  display:flex;
  justify-content:center;
  align-items:center;
  }
`;

const IconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
`;



const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  margin: 8px;
  background-color: ${(props) => (props.isSelected ? '#F37A65' : 'transparent')}; 

  img {
    width: 36px;
    height: 36px;
    filter: ${(props) => (props.isSelected ? 'brightness(0) invert(1)' : 'none')}; 
  }
`;


export {Steps,IconGrid,IconWrapper, StepsWrapper, ShowImage, Heading, StyledModal, StyledTitle, StyledBody, DetailInitiativeWrapper, CircleNew, ManageAnniversariesContainer,ToggleButton};