import React from 'react';
import PropTypes from 'prop-types';
import { Border, Button, ButtonContainer, CalendarHeader, CalendarWrapper, ConfirmVisitText, StyledModal } from './style';
import { AdminCloseIcon } from '../../../utils/icons';

const CommonModal = ({
  show,
  icon,
  buttonIcon,
  title,
  description,
  onCancel,
  onConfirm,
  cancelText,
  subDescription,
  confirmButtonText,
  modalWidth = "400px",
  modalHeight,
  confirmButtonWidth,
}) => (
  <StyledModal show={show} width={modalWidth} height={modalHeight}>
    <div className="modal-content-padding">
      <CalendarHeader>
        <div className="left-content">
          <div>{icon}</div>
          <div>{title}</div>
        </div>
        <div className="close-icon" onClick={onCancel}>
          {AdminCloseIcon()}
        </div>
      </CalendarHeader>
      <Border/>
      <CalendarWrapper>
        <ConfirmVisitText>{description}</ConfirmVisitText>
        {subDescription ? <ConfirmVisitText isColor="#C13D4D">{subDescription}</ConfirmVisitText> :null}
        <ButtonContainer>
          <Button
            isWidth="100px"
            isHeight="40px"
            background="transparent"
            color="#184A61"
            backgroundHover="none"
            onClick={onCancel}
          >
            {cancelText}
          </Button>
          <Button
            isWidth={confirmButtonWidth}
            isHeight="40px"
            background="#F37A65"
            color="white"
            onClick={onConfirm}
          >{buttonIcon}
            <div className="btn-text">{confirmButtonText}</div>
          </Button>
        </ButtonContainer>
      </CalendarWrapper>
    </div>
  </StyledModal>
);


CommonModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title:PropTypes.string, 
  confirmText: PropTypes.string, 
  description: PropTypes.string.isRequired,
  subDescription: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  cancelText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  modalWidth: PropTypes.string,
  modalHeight: PropTypes.string,
  icon: PropTypes.node.isRequired, 
  buttonIcon:PropTypes.node.isRequired, 
  confirmButtonWidth:PropTypes.string, 
};
  


export default CommonModal;
