import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  AvatarContainer,
  Divider,
  Heading,
  ModalContent,
  ProfileContainer,
  RadioButton,
  TemplateContainer,
  // TemplateImage,
  TemplateOption,
  Button,
  NoteText,
  CharacterCount,
  Label,
  RadioButtonWrapper,
  StyledModal,
  LeftContent,
  FieldTitle,
  NameInitials,
  ManagerIconContainer,
} from "./styles";
import LazyImage from "../common/LazyImage/LazyImage";
import {
  ManagerArrowIcon,
  ManagerCloseIcon,
  ManagerCrossIcon,
  ManagerDisabledArrowIcon,
  // ManagerDropDownIcon,
  ManagerEmojiIcon,
  ManagerLeftArrowIcon,
  ManagerPostIcon,
} from "../../utils/icons";
import {TitleContainer } from "../PeerRecognition/styles";
import { toast } from "react-toastify";
import {
  GetCompanyExemplifiesValues,
  GetTeamMembersData,
  GetTemplatesWithUserData,
  AddInspirationPost,
} from "../../redux/constants/apiConstants";
import { fetchApi, getInitials } from "../../utils/methods";
import { ImageUrl } from "../../utils/constants";
import Image from "../Image";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import SocialFeedsEmojiPicker from "../SocialFeedsEmojiPicker";
import {
  UserContainer,
  UserDropdownContainer,
  ProfilePicture,
  DropDownStyledImage,
  ListDropdown /*ShowTaggedUser, Cross*/,
} from "../SocialFeedsV2/styles";
import styled from "styled-components";
import { Select, MenuItem, InputLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import ToastMessage from "./ToastMessage";
// import { matches } from "lodash";

const StyledInputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px 16px;
  margin: 12px 24px 0px 24px;
  border: 1px solid ${({ hasError }) => (hasError ? "red" : "#BDBDBD")};
  border-radius: 4px;
  gap:8px;
  min-height:48px;
`;

const StyledInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  padding: 8px;
  cursor: ${({ cursor }) => (cursor ? "not-allowed" : "")};

  ::placeholder {
    opacity: 0.3;
  }
`;

const ShowTaggedUser = styled.div`
  display: flex;
  align-items: center;
  ${'' /* margin-right: 8px; */}
  padding: 4px;
  gap: 4px;
  background-color: #fefefe;
  border: 1px solid #efefef;
  border-radius: 40px;
  width: auto;
  height: 32px;
  > div {
    color: #184a61;
    font-family: Lato;
    ${'' /* font-size: 12px; */}
    font-size: ${({ isSize }) => (isSize ? isSize : "12px")};
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;

const Cross = styled.div`
  cursor: pointer;
  ${'' /* margin-left: 4px; */}
  width: 16px;
  height: 16px;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const RecognitionModal = ({
  show,
  onClose,
  modalData,
  recognitionType,
  loggedInUser,
  fetchMyTaskData,
}) => {
  const [page, setPage] = useState(1);
  const [recipient, setRecipient] = useState("");
  const [exemplifiesData, setExemplifiesData] = useState([]);
  const [charColor, setCharColor] = useState("#BDBDBD");
  const [templateData, setTemplateData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [displayEmoji, setDisplayEmoji] = useState();
  false;
  const [emojiData, setEmojiData] = useState(null);
  const [emojiId, setEmojiId] = useState(null);
  const [emojiNative, setEmojiNative] = useState(null);
  const [emojiSkin, setEmojiSkin] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [focused, setFocused] = useState(false);
  const [searchUser, searchUserName] = useState("");
  const [getSearchUser, setSearchUserData] = useState([]);
  const [getUserId, setUserIdData] = useState([]);
  const [exemplifyId, setexemplifyid] = useState([null]);
  const [exemplifyName, setexemplifyName] = useState(null);
  const [description, setDescription] = useState("");
  const [position, setPosition] = useState({ start: 0, end: 0 });
  const maxChars = 3000;
  const emojiref = useRef(null);
  const myref = useRef(null);
  const textareaRef = useRef(null);
  const [showSuggestor, setShowSuggestor] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showToast, setShowToast] = useState(true);
  const[selectedCoreValue,setSelectedCoreValue]=useState(null);

  // window.console.log("exemplifiesData", exemplifyName);
  window.console.log(" are", emojiData, emojiId, emojiNative,exemplifyName, emojiSkin);

  const handleTemplateSelect = (id) => {
    setSelectedTemplate(id);
  };

  const handleEmojiClick = (event) => {
    if (
      !isNull(emojiref) &&
      !isUndefined(emojiref) &&
      !(emojiref && emojiref.current && emojiref.current.contains(event.target))
    ) {
      setDisplayEmoji(false);
    }
  };

  const handleClick = (event) => {
    if (
      !isNull(myref) &&
      !isUndefined(myref) &&
      !isNull(myref.current) &&
      !isUndefined(myref.current) &&
      !(myref && myref.current && myref.current.contains(event.target))
    ) {
      // this.setState({ showConnectDevice: false });
    }
  };
  window.console.log("-",showToast)
  useEffect(() => {
   
    const timer = window.setTimeout(() => setShowToast(false), 3000); // Auto-hide after 3 seconds
    return () => window.clearTimeout(timer);
  }, []);


  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("mousedown", handleEmojiClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("mousedown", handleEmojiClick);
    };
  }, []);

  const handleSelect = (id, coreValue) => {
    setSelectedValue(coreValue);
    setexemplifyid(id);
    setexemplifyName(coreValue);
  };

  const handleChange = (event) => {
    const index = event.target.value;
    const selectedData = exemplifiesData?.[index];
    setSelectedCoreValue(index)
    if (selectedData) {
      handleSelect(selectedData.id, selectedData.core_value);
    }
  };

  useEffect(() => {
    if (description.length >= 3000) {
      setCharColor("#F3384A");
    } else {
      setCharColor("#BDBDBD");
    }
  }, [description]);

  useEffect(() => {
    fetchTemplateData();
    getTeamMemberDetails();
    getCompanyExemplifiesValues();
  }, []);


 
  useEffect(() => {
    if (modalData) {
      const userData = {uid:modalData.member_id,fullname:modalData.full_name,profile_image:modalData.profile_image}
      setSearchUserData((prevUsers) => {
        const isAlreadyPresent = prevUsers.some((user) =>user.uid === modalData.member_id);
        return isAlreadyPresent ? prevUsers : [...prevUsers, userData];
      });

      setUserIdData((prevUsers) => {
        const isAlreadyPresent = prevUsers.some((user) =>user.uid === modalData.member_id);
        return isAlreadyPresent ? prevUsers : [...getUserId, modalData.member_id];
      });
    }
  }, [modalData]);


  

  const fetchTemplateData = async () => {
    const apiUrl = `${GetTemplatesWithUserData}/${recognitionType}/${modalData?.member_id}`;

    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        setTemplateData(res.data.templates);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  const getCompanyExemplifiesValues = async () => {
    const apiUrl = `${GetCompanyExemplifiesValues}`;

    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        setExemplifiesData(res.data);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  const getTeamMemberDetails = async (search) => {
    const company_id = 1;
    const limit = 10;
    const name = search ? search : searchUser;
    const postData = {
      company_id: company_id,
      name: name,
      limit: limit,
    };
    const apiUrl = `${GetTeamMembersData}`;

    try {
      const res = await fetchApi(apiUrl, "POST", postData);
      // window.console.log("reciepeinet statte res is =------",res)
      if (res.status === "success") {
        setRecipient(res?.data?.response);
        setFilteredUsers(res?.data?.response);
        // setShowSuggestor(true);

      } else {
        toast.error(res.message);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  const showEmojiPicker = () => {
    setDisplayEmoji((prev) => !prev);
  };

  const hideEmojiPicker = () => {
    setDisplayEmoji(false);
  };

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "inherit";
      textarea.style.height = `${textarea.scrollHeight}px`;
      if (textarea.scrollHeight > 500) {
        textarea.style.height = "500px";
      }
    }
  };

  const addDescription = (e) => {
    const text = e.target.value;
    // window.console.log("typinnnnng text is",text)
    // Allow user to remove characters if they exceed the limit
    if (text.length <= 3000) {
      let position = getPosition(e.target);
      setPosition(position);
      setDescription(text);
      adjustHeight();


      // const match = text.match(/@(\w*)$/);
      // if (match) {
      //   const searchTerm = match[1];
      //   getTeamMemberDetails (searchTerm); // Call API to fetch suggestions
      // } else {
      //   setShowSuggestor(false); // Hide suggestor if @mention is not being typed
      // }
    } else {
      const position = getPosition(e.target);
      setPosition(position);
      setDescription(text.slice(0, 3500));
      adjustHeight();
    }


    const match = text.match(/@(\w*)$/);
    if (match) {
      const searchTerm = match[1];
      getTeamMemberDetails (searchTerm); // Call API to fetch suggestions
      setShowSuggestor(true)
    } else {
      setShowSuggestor(false); // Hide suggestor if @mention is not being typed
    }
  
  
  } ;
    

  window.console.log("modalData",modalData)


  //   const mentionMatch = text.slice(0, position.start).match(/@(\w*)$/); // Match `@` followed by letters
  //   window.console.log("mention match",mentionMatch)
  //   if (mentionMatch) {
  //     const searchTerm = mentionMatch[1].toLowerCase();
  //     window.console.log("recipient",recipient)
  //     const matches = recipient.filter((user) =>
  //       user.fullname.toLowerCase().includes(searchTerm)
  //     );

  //     window.console.log("searchTerm",searchTerm)
  //     window.console.log("recipient",recipient)
  //     window.console.log("matches",matches)
  
  
  //     setFilteredUsers(matches);
  //     setShowSuggestor(matches.length > 0);
  //   } else {
  //     setShowSuggestor(false); // Hide dropdown if no valid `@` pattern
  //   }
  // };

  const getPosition = (el) => {
    let start = 0,
      end = 0,
      normalizedValue,
      range,
      textInputRange,
      len,
      endRange;

    if (
      typeof el.selectionStart == "number" &&
      typeof el.selectionEnd == "number"
    ) {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = document.selection.createRange();

      if (range && range.parentElement() == el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, "\n");
        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());
        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart("character", -len);
          start += normalizedValue.slice(0, start).split("\n").length - 1;

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd("character", -len);
            end += normalizedValue.slice(0, end).split("\n").length - 1;
          }
        }
      }
    }

    return {
      start: start,
      end: end,
    };
  };

  const addInspirationV2 = (e) => {
    // Create the new description with the added text
    const newText =
      description.slice(0, position.start) +
      e +
      description.slice(position.end);

    // Update the position and description state
    setPosition((prevPosition) => ({
      start: prevPosition.start + e.length,
      end: prevPosition.end + e.length,
    }));
    setDescription(newText);
  };

  const onEmojiSelect = (emoji, id, native, skin) => {
    // Update the state with the selected emoji data
    setEmojiData(emoji);
    setEmojiId(id);
    setEmojiNative(native);
    setEmojiSkin(skin);

    addInspirationV2(native);
  };

  const handleNext = () => {
    if (page === 1) setPage(2);
  };

  const handleBack = () => {
    if (page === 2) setPage(1);
  };

  const handlePost = () => {
    let recognitionPostDetails = {
      post_images: [],
      description: description,
      shoutout: { exemplifies: getUserId },
      exemplify_id: recognitionType === "birthday" ? 19 : recognitionType === "anniversary" ? 20  : exemplifyId,
      title: "",
      // template_id:  recognitionType === "birthday" ? 1 : recognitionType === "anniversary" ? 5  : null ,
      template_id:selectedTemplate,
      due_Date: modalData.due_date,
    };

    window.console.log("recognitionPostDetails",recognitionPostDetails)
    createPost(recognitionPostDetails);
    onClose();
  };

  const onChangeInput = (e) => {
    searchUserName(e.target.value);
    getTeamMemberDetails(e.target.value);
  };

  const addUser = (newUser) => {

    // Check if the newUser already exists in the array based on uid
    const userExists = getSearchUser?.some((user) => user.uid === newUser.uid);

    if (!userExists) {
      // If the user does not exist, add the new user to the array
      setSearchUserData([...getSearchUser, newUser]);
      setUserIdData([...getUserId, newUser.uid]); // Correctly store the uid in array form
    }

    searchUserName("");
  };

  const addUserText = (user) => {
    const updatedDescription = description.replace(/@(\w*)$/, `@${user.fullname} `);
    setDescription(updatedDescription);
    setShowSuggestor(false);
    textareaRef.current.focus();
  };


  const removeUserByUid = (uid) => {
    const updatedUsers = getSearchUser.filter((user) => user.uid !== uid);
    setSearchUserData(updatedUsers);
    const updatedUserIds = updatedUsers.map((user) => user.uid);
    setUserIdData(updatedUserIds); 
  };

  // window.console.log("getSearchUser----",getSearchUser,'---------',recipient)

  const createPost = async (payload) => {
    const postData = {
      ...payload,
      title: "",
    };
    try {
      const res = await fetchApi(AddInspirationPost, "POST", postData);
      if (res.data.message) {
        toast.error(res.data.message);
      } else {
        // toast.success(res.data[0]);
        toast(
          <ToastMessage
            title="Successfully Posted"
            message="Your post has been successfully posted on your social feed."
          />,
          {
            position: "top-right",
            autoClose: 3000, 
            closeButton: false, 
            hideProgressBar: true,
            pauseOnHover: true,
            draggable: true,
            zIndex:900000,
            style: {
              right: "165px", 
              top: "46px", 
              marginRight:'20px',
              backgroundColor: "transparent", 
              boxShadow: "none", 
              width:'510px',
            },
          },
          fetchMyTaskData()
        );
      }
    } catch (error) {
      // toast.error(error)
    }
  };

  return (
    <StyledModal show={show} onHide={onClose} centered width="800px">
      <ModalContent /*isHeight={recognitionType === "recognition" ?  "800px" : "565px"}*/
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding:"0px 24px"
          }}
        >
          <Heading>
            {recognitionType === "recognition"
              ? "Give a Recognition"
              : recognitionType === "birthday"
                ? "Celebrating a Birthday"
                : "Celebrating a Work Anniversary"}
          </Heading>
          <ManagerIconContainer isWidth={'32px'} isHeight={'32px'} style={{ cursor: "pointer" }} onClick={onClose}>
            {ManagerCloseIcon()}
          </ManagerIconContainer>
        </div>
        <Divider isMargin={"16px 0px"}></Divider>
        <ProfileContainer>
          <AvatarContainer isProfile={true}>
            <LazyImage
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "40px",
                border: "1px solid #DCDCDC",
              }}
              onError={() => window.console.error("Image failed to load")}
              src={`${ImageUrl}/${loggedInUser?.profile_image}`}
            />
          </AvatarContainer>
          <div>{`${loggedInUser?.fname} ${loggedInUser?.lname}`}</div>
        </ProfileContainer>

        {page === 1 && (
          <>
            {recognitionType === "birthday" ||
            recognitionType === "anniversary" ? (
                <>
                  <FieldTitle marginTop={"24px"} isFont={"Lato"}>
                    {"Select Template"}
                  </FieldTitle>
                  <TemplateContainer>
                    {templateData?.map((template, index) => (
                      <TemplateOption key={template.id}>
                        <LazyImage
                          style={{
                            width: "224px",
                            height: "224px",
                            borderRadius: "4px",
                            border: "1px solid #DCDCDC",
                          }}
                          onError={() =>
                            window.console.error("Image failed to load")
                          }
                          src={`${ImageUrl}/${template?.template_url}`}
                        />
                        <RadioButtonWrapper>
                          <RadioButton
                            type="radio"
                            name="template"
                            checked={selectedTemplate === template.id}
                            onChange={() => handleTemplateSelect(template.id)}
                          />
                          <Label>Option {index + 1}</Label>
                        </RadioButtonWrapper>
                      </TemplateOption>
                    ))}
                  </TemplateContainer>
                  <Divider isPadding={"0px 24px"} isWidth={"94%"} isMargin={"16px 24px"}></Divider>
  
                  <Button onClick={handleNext} disabled={!selectedTemplate}>
                    Next{" "}
                    <ManagerIconContainer isWidth={'16px'} isHeight={'16px'}>
                      {!selectedTemplate
                        ? ManagerDisabledArrowIcon()
                        : ManagerArrowIcon()}
                    </ManagerIconContainer>
                  </Button>
  
                  {/* <Main style= {{display:"flex", justifyContent:"center"}}>
                    <CommonButton
                      btnType={"squareIcon"}
                      onClick={handleNext}
                      disabled={!selectedTemplate}
                      title={("Next")}
                      styles={{color:"#00ABB6",border:"white", fontFamily:"Lato"}}
                      nextIcon={ManagerArrowIcon()}
                    />
                  </Main> */}
                </>
              ) : (
                <>
                  <TitleContainer
                    paddingV={"12px 24px 0px 24px"}
                    padding="0px !important"
                    heightV="240px"
                    margin="24px 0px 0px 0px"
                    manager
                  >
                    <FieldTitle isFont={"Lato"} manager color={"#184A61"}>
                      {"Add Recognition"}
                    </FieldTitle>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{
                        // marginTop: "12px",
                        borderRadius: "4px",
                        padding: "14px 16px",
                        "  & .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: focused ? "#00ABB6" : "#BDBDBD",
                          },
                          "&:hover fieldset": {
                            borderColor: "#BDBDBD",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#00ABB6",
                          },
                        },
                      }}
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                    >
                      <InputLabel
                        id="select-recognition-label"
                        sx={{
                          fontFamily: "Lato",
                          fontSize: "16px",
                          marginLeft:"24px",
                          marginTop:"10px",
                          color: focused ? "#00ABB6" : "#BDBDBD",
                          "&.Mui-focused": {
                            color: "#00ABB6",
                          },
                        }}
                      >
                        Select recognition
                      </InputLabel>
                      <Select
                        labelId="select-recognition-label"
                        id="select-recognition"
                        value={selectedCoreValue}
                        // value={selectedValue}
                        // onChange={(e) => handleChange(exemplifiesData?.[e.target.selectedIndex]?.id, exemplifiesData?.[e.target.selectedIndex]?.core_value)}
                        // value={exemplifiesData?.findIndex(
                        //   (item) => item.core_value === selectedValue || ""
                        // )}
                        // displayEmpty
                        onChange={handleChange}
                        label="Select recognition"
                        sx={{
                          color: "#184A61",
                          width: "100%",
                          height:"48px",
                          padding:"14px 16px",
                          "& .MuiSelect-icon": {
                            color: "#00ABB6",
                            top: "50%",
                            right: "12px", 
                            transform: "translateY(-50%)"
                            // fontSize: "16px",
                            // width: "16px",
                            // height: "16px",
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 200,
                              width: 300,
                              "&::-webkit-scrollbar": {
                                width: "4px",
                                height: "100px",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#BDBDBD",
                                borderRadius: "5.5px",
                              },
                              "&::-webkit-scrollbar-track": {
                                backgroundColor: "white",
                              },
                            },
                          },
                          MenuListProps: {
                            sx: {
                              paddingTop: 0,
                              paddingBottom: 0,
                            },
                          },
                        }}
                      >
                        {(Array.isArray(exemplifiesData)
                          ? exemplifiesData
                          : []
                        ).map((item, index) => (
                          <MenuItem
                            key={index}
                            value={index}
                            sx={{
                              color: "#184A61",
                              padding: "14px 16px",
                              "&:hover": { backgroundColor: "light blue" },
                            }}
                          >
                            {item.core_value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TitleContainer>
  
                  <Divider isMargin={"24px"} isWidth={"94%"}></Divider>
                  <FieldTitle isFont={"Lato"} color={"#184A61"}>
                    {"Who would you like to recognize?"}
                  </FieldTitle>

                  <UserContainer
                    showSuggestor={searchUser}
                    isManagerMargin="12px"
                    width="350px"
                  >
                    {recipient.length > 0 &&
                    recipient?.map((user, index) => (
                      <UserDropdownContainer
                        key={index}
                        index={index}
                        // onMouseEnter={() => this.handleMouseHover(index)}
                        currentSelection={searchUser}
                        // onClick={addUser(index)}
                      >
                        <ProfilePicture>
                          <DropDownStyledImage width="100%" left height="30px">
                            <div className="middle">
                              {user?.profile_image !== "profile-Image/default.png" ? (
                                <Image
                                  image={user?.profile_image}
                                  alt={user?.fname}
                                />):(
                                <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center',margin: '5px 10px'}}>  
                                  <NameInitials isWidth={"40px"} isHeight={"40px"} isRadius={"50%"} >
                                    {getInitials(user?.fullname)}
                                  </NameInitials>
                                </div>
                              )}
                            </div>
                            <ListDropdown
                              index={index}
                              currentSelection={searchUser}
                              onClick={() => addUser(user)}
                            >
                              {user?.fullname}
                            </ListDropdown>
                          </DropDownStyledImage>
                        </ProfilePicture>
                      </UserDropdownContainer>
                    ))}
                  </UserContainer>
                  <StyledInputContainer hasError={getSearchUser?.length > 10}>
                    {getSearchUser.length > 0 &&
                    getSearchUser.map((data, index) => (
                      <ShowTaggedUser key={index}>
                        {data?.profile_image !== "profile-Image/default.png" ? (
                          <img
                            src={`${ImageUrl}/${data?.profile_image}`}
                            style={{
                              borderRadius: "40px",
                              width: "24px",
                              height: "24px",
                              border: "1px solid #DCDCDC",
                            }}
                            alt="profile"
                          />):(
                          <NameInitials isWidth={"24px"} isHeight={"24px"} isRadius={"40px"} isSize={"10px !important"}>
                            {getInitials(data?.fullname)}
                          </NameInitials>
                        )
                        }
                        <div>{data?.fullname}</div>
                        <Cross
                          onClick={() =>
                            data?.uid && removeUserByUid(data?.uid)
                          }
                        >
                          {ManagerCrossIcon()}
                        </Cross>
                      </ShowTaggedUser>
                    ))}
                    <StyledInput
                      placeholder={getSearchUser?.length > 0 ? "" : "Enter names"}
                      name="title"
                      onChange={getSearchUser?.length > 20 ? null : onChangeInput}
                      value={searchUser}
                      maxLength="100"
                      cursor={getSearchUser?.length > 20}
                    />
                  </StyledInputContainer>
                  <NoteText hasError={getSearchUser?.length > 10}>
                  (You can enter the names of up to 10 individuals)
                  </NoteText>
                  <Divider isMargin={"24px 24px 16px 24px"} isWidth={"94%"}></Divider>

                  <Button onClick={handleNext} disabled={!selectedValue || !getSearchUser?.length ||getSearchUser?.length > 10 }>
                  Next{" "}
                    <div>
                      {" "}
                      {!selectedValue
                        ? ManagerDisabledArrowIcon()
                        : ManagerArrowIcon()}
                    </div>
                  </Button>
                </>
              )}
          </>
        )}
        {page === 2 && (
          <>
            <LeftContent>
              <div>
                <textarea
                  placeholder={"Write here. You can also include @mentions."}
                  rows="3"
                  name=""
                  onChange={(e) => addDescription(e)}
                  value={description}
                  data-gramm_editor="false"
                  className="CustomTextAreaField"
                  // onScroll={onScroll}
                  spellCheck="false"
                  ref={textareaRef}
                  onInput={(e) => {
                    addDescription(e);
                  }}
                  onClick={(e) => addDescription(e)}
                />
                
                <UserContainer   
                  showSuggestor={showSuggestor}
                  isManagerMargin="12px"
                  width="350px">
                  {filteredUsers.map((user, index) => (
                    <UserDropdownContainer key={index} onClick={() => addUserText(user)}>
                      <ProfilePicture>
                        {/* {user.profile_image !== "profile-Image/default.png" ? (
                          <DropDownStyledImage width="100%" left height="30px">
                            <img src={user.profile_image} alt={user.fullname} />
                          </DropDownStyledImage>
                        ) : (
                          <NameInitials>{getInitials(user.fullname)}</NameInitials>
                        )} */}
                        <DropDownStyledImage width="100%" left height="30px">
                          <div className="middle">
                            {user?.profile_image !== "profile-Image/default.png" ? (
                              <Image
                                image={user?.profile_image}
                                alt={user?.fname}
                              />):(
                              <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center',margin: '5px 10px'}}>  
                                <NameInitials isWidth={"40px"} isHeight={"40px"} isRadius={"50%"}>
                                  {getInitials(user?.fullname)}
                                </NameInitials>
                              </div>
                            )}
                          </div>

                          <ListDropdown  index={index}
                          // currentSelection={searchUser}
                            onClick={() => addUserText(user)}>{user.fullname}</ListDropdown>
                        </DropDownStyledImage>

                      </ProfilePicture>
                    </UserDropdownContainer>
                  ))}
                </UserContainer>

              </div>
            </LeftContent>
          
            <div
              style={{
                width: "752px",
                height: "32px",
                display: "flex",
                justifyContent: "space-between",
                margin:"16px 24px 0px 24px"
              }}
            >
              <ManagerIconContainer onClick={showEmojiPicker}>{ManagerEmojiIcon()}</ManagerIconContainer>
              <CharacterCount style={{ color: charColor }}>
                {description.length}/{maxChars} | C
              </CharacterCount>
            </div>
            <Divider isMargin={"16px 24px"} isWidth={"94%"}></Divider>
            <div style={{ margin: "auto", display: "flex", gap: "16px" }}>
              <Button
                isBg={true}
                onClick={handleBack}
                color="red"
                hoverColor="#5a6268"
              >
                <ManagerIconContainer isWidth={'16px'} isHeight={'16px'}>{ManagerLeftArrowIcon()}</ManagerIconContainer> Back
              </Button>
              <Button onClick={handlePost}  disabled={description.length > 3000}  /*disabled={!description}*/>
                Post{" "}
                <ManagerIconContainer isWidth={'16px'} isHeight={'16px'}>
                  {ManagerPostIcon()}
                </ManagerIconContainer>
              </Button>
            </div>
            {displayEmoji ? (
              <SocialFeedsEmojiPicker
                selectEmoji={onEmojiSelect}
                emojiRef={emojiref}
                hidePicker={hideEmojiPicker}
              />
            ) : null}
          </>
        )}
      </ModalContent>
    </StyledModal>
  );
};

RecognitionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  modalData: PropTypes.func.isRequired,
  recognitionType: PropTypes.func.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  fetchMyTaskData: PropTypes.func.isRequired,
};

export default RecognitionModal;
