import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { PersonDetailCard, StyledTabsNew } from './style';
import { Tab } from "react-bootstrap";
import Overview from './overview';
import { getUserDetails, DeleteEmployeeAPI, getUserRoles, getCompanyLocation, getCompanyDepartment, deleteUserAPI, getUserRole, getUserCompanyDetails, fetchUserProfileReward, fetchUserCompanyDetails, fetchWellnessInterest, getUserFriends, fetchUserConnectedDevices, fetchUserShoutOutCount, fetchUserInitiativesPeoplePage } from '../../redux/actions';
import { connect } from 'react-redux';
const AddRolesPopup = React.lazy(() => import('../AddRolesPopup'));
const DeleteUserPopUp = React.lazy(() => import('../DeletePostPopup/deleteUserPopupProfile'));
const EditUserDetailsPopup = React.lazy(() => import('../PeopleHomeV2/EditUserDetailsPopup'));
import PeopleAcheivementData from './acheivement';
import { ImageUrl } from '../../utils/constants';
import Waiting from '../Waiting';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { EmployeeIcon } from '../../utils/icons';

class PersonalProfile extends Component {
  constructor() {
    super();
    this.state = {
      selectedTab: 1,
      id: "",
      showRolesPopup: false,
      userId: "",
      showDeleteUserPopUp: false,
      showUserPopUp: false
    };
  }

  setActiveTab = (active) => {
    window.console.log("active",active)
    this.setState({ selectedTab: Number(active) })
  }

  componentDidMount() {
    // window.console.log("companyInfo",this.props.companyInfo)
    const { match, fetchUserRole, fetchUserCompany, getUserDetails, getUserRoles, fetchUserProfileReward, fetchUserCompanyDetails, fetchWellnessInterest, getUserFriends, fetchUserConnectedDevices, fetchUserShoutOutCount, fetchUserInitiativesPeoplePage } = this.props;

    this.setState({
      id: parseInt(match?.params?.id,10),
      userId: parseInt(match?.params?.id,10),
    });

    getUserDetails(parseInt(match?.params?.id,10));
    getUserRoles(parseInt(match?.params?.id,10));
    getCompanyLocation(parseInt(match?.params?.id,10));
    getCompanyDepartment(parseInt(match?.params?.id,10));
    fetchUserRole();
    fetchUserCompany();
    fetchUserProfileReward(match.params.id);
    fetchUserCompanyDetails(match.params.id);
    fetchWellnessInterest(match.params.id);
    getUserFriends(match.params.id);
    fetchUserConnectedDevices(match.params.id);
    fetchUserShoutOutCount(match.params.id);
    fetchUserInitiativesPeoplePage(match.params.id);
  }
  deleteUserAction = (userId) => {
    const { deleteEmployeeAPI, history } = this.props;
    let obj = {};
    obj['user_uid'] = userId;
    deleteEmployeeAPI(obj, history);
    this.setState({
      selectedEmployee: null
    })
    this.onToggleDeletePopup();
  };
  userModal = () => {
    this.setState((prevState) => ({
      showUserPopUp: !prevState.showUserPopUp
    }));
  };
  fetchEmployee = () => {
    const { match } = this.props;
    this.props.getUserDetails(parseInt(match?.params?.id,10))
  }

  onToggleDeletePopup = () => {
    this.setState((prev) => ({ showDeleteUserPopUp: !prev.showDeleteUserPopUp }));
  };

  onToggleRolesPopup = (id) => {
    this.setState((prev) => ({ showRolesPopup: !prev.showRolesPopup }), () => { this.state.showRolesPopup && this.props.getUserDetails(parseInt(id,10)) });
  };

  fallBack = (e) => {
    e.target.src = '/public/images/default.png'
  }

  deleteUserPop = () => {
    const { userId } = this.state;
    const { deleteUserAPI, history } = this.props;
    let obj = {};
    obj['uid'] = userId;
    obj['inactive'] = 1;
    deleteUserAPI(obj, history);
    this.onToggleDeletePopup();
  };

  render() {
    
    const { setUserDetails, userRolesList, role, userCompany, history, userProfileReward, userProfileCompany, userWellnessInterest, friendList, userConnectedDevice, shoutOutData, userInitiativesData,  } = this.props;
    const { showRolesPopup, userId, id, showDeleteUserPopUp, showUserPopUp } = this.state;
    const { location } = this.props;

    // Check if the current route contains "manager"
    const isManagerRoute = location.pathname.includes('manager');
    window.console.log("isManagerRoute",isManagerRoute)
    if (_.isUndefined(setUserDetails) || _.isNull(setUserDetails)) {
      return <Waiting />
    }
    let userRoleCount = 0;
    if (userRolesList && userRolesList.length > 0) {
      userRolesList.map((role) => {
        if (role.status === 1) {
          userRoleCount = userRoleCount + 1;
        }
      })
    }
    let userRoleLast = 0;
    const companyId = userCompany.id ? userCompany.id : 1;
    const userCompanyId = setUserDetails && setUserDetails.user ? setUserDetails.user.company_id : null;
    return (
      <PersonDetailCard isManagerRoute={isManagerRoute ? true : false}>
        <div className="rewards">
          {userProfileReward && userProfileReward.reward && userProfileReward.reward.tier === "Bronze" ? <img className='flag' src='/public/images/Rewards/bronze.png' alt="reward" /> :
            userProfileReward && userProfileReward.reward && userProfileReward.reward.tier === "Silver" ? <img className='flag' src='/public/images/Rewards/silver.png' alt="reward" /> :
              userProfileReward && userProfileReward.reward && userProfileReward.reward.tier === "Gold" ? <img className='flag' src='/public/images/Rewards/gold.png' alt="reward" /> : ""
          }
        </div>
        <div className='card-wrapper'>
          <div className='profile-img-section'>
            <img onError={this.fallBack} src={`${ImageUrl}/${setUserDetails?.user?.profile_image}`} />
          </div>
          <div className='profile-contents'>
            <div className='username-location-wrapper'>
              <div className='user-name'>{setUserDetails && setUserDetails?.user?.fname} {setUserDetails && setUserDetails?.user?.lname}
                {setUserDetails && setUserDetails.is_champion ? <div className="wellness-champion">
                  Wellness Champion
                </div>:null}
              </div>
              <div className='icons-section'>
                {
                  setUserDetails && setUserDetails?.user?.department ?
                    <div className='place-icon'>
                      <img className='flag' src='/public/images/placeIcon.png' />
                      <span className='text'>{setUserDetails?.user?.department}</span>
                    </div>
                    : ""
                }
                {setUserDetails && setUserDetails?.user?.location && (<div className='location'>
                  <span>
                    <img className='flag2' src='/public/images/locationIcon.png' />
                  </span>
                  <span className='text'>{setUserDetails && setUserDetails?.user?.location}</span>
                </div>)}
                {userRoleCount ?<div className='location'>
                  <span>
                    <img className='userRoleIcon' src='/public/images/PeoplePage/userRole.png' />
                  </span>
                  {userRolesList && userRolesList.length > 0 && userRolesList.map((role) => {
                    userRoleLast = role.status === 1 ? userRoleLast + 1 : userRoleLast + 0;
                    return (
                      role.status === 1 && <span className='text'>{role.name} {userRoleLast < userRoleCount ? "/":null}</span>)
                  }
                  )}
                </div>:null}
                {setUserDetails && setUserDetails.user && setUserDetails.user.employee_id &&(<div className='location' style={{display:"flex"}}>
                  <span>
                    {EmployeeIcon()}
                  </span>
                  <span style={{marginLeft:"10px"}}>Employee ID: {(setUserDetails && setUserDetails.user.employee_id)? setUserDetails.user.employee_id : "NA"}</span>
                </div>)}
              </div>
            </div>

            <div className='points-section-wrapper'>
              <div className='points-section'>
                <div className='my-points'>
                  <div className='my-points-txt'>My Points</div>
                  <div className='my-points-val'>{userProfileReward && userProfileReward.reward && userProfileReward.reward.total}</div>
                </div>
                <div className='my-buddies'>
                  <div className='my-buddies-txt'>My Buddies</div>
                  <div className='my-buddies-val'>{friendList && friendList.length}</div>
                </div>
                <div className='my-buddies' style={{width:"auto", paddingRight:"10px"}}>
                  <div className='my-buddies-txt'>In Team</div>
                  <div className='my-buddies-val' style={{fontSize:"18px"}}>{setUserDetails && setUserDetails.user && setUserDetails.user.team_name || "-"}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='buttons-wrapper'>
            <button onClick={() => this.onToggleRolesPopup(id)} className='user-role-btn'>User Role</button>
            <button onClick={() => this.onToggleDeletePopup()} className='remove-role-btn'>Remove User</button>
          </div>
        </div>
        <StyledTabsNew defaultActiveKey={1} id="styledTabs" onSelect={this.setActiveTab} style={{ borderRadius: '0px' }}>
          <Tab eventKey={1} title="Overview" tabClassName="first-tab">
            {this.state.selectedTab === 1 ?
              <Overview isManagerRoute={isManagerRoute ? true : false} userModal={this.userModal} setUserDetails={setUserDetails} role={role} userWellnessInterest={userWellnessInterest} userConnectedDevice={userConnectedDevice} shoutOutData={shoutOutData} userInitiativesData={userInitiativesData} companyBranding={this.props.companyBranding}/>
              : " "
            }
          </Tab>
          <Tab eventKey={2} title="Achievement" tabClassName="first-tab">
            {this.state.selectedTab === 2 ?
              <PeopleAcheivementData setUserDetails={setUserDetails} userId={userId} selectdUserAuth={setUserDetails&& setUserDetails.auth_token} history={history} userProfileCompany={userProfileCompany}/>
              : " "
            }
          </Tab>
        </StyledTabsNew>
        <DeleteUserPopUp
          showModal={showDeleteUserPopUp}
          onClose={this.onToggleDeletePopup}
          deleteUser={this.deleteUserAction}
          deleteUserAdmin={this.deleteUserPop}
          uid={userId}
          fname={setUserDetails?.user?.fname}
          lname={setUserDetails?.user?.lname}
          role={role}
          companyId={companyId}
          userCompanyId={userCompanyId}
        />
        {
          showRolesPopup && <Suspense fallback={<Waiting/>}><AddRolesPopup showModal={showRolesPopup} closeModal={this.onToggleRolesPopup}
            employee={setUserDetails} userRolesList={userRolesList} companyId={userProfileCompany && userProfileCompany.company_details && userProfileCompany.company_details.id} />
          </Suspense>
        }
        {
          showUserPopUp ? <Suspense fallback={<Waiting/>}><EditUserDetailsPopup showUserModal={showUserPopUp} onClose={this.userModal} employee={setUserDetails} locationDetails={this.props.locationDetails} departmentDetails={this.props.departmentDetails} companyInfo={this.props.companyInfo}/></Suspense> : null
        }
      </PersonDetailCard>
    );
  }
}

PersonalProfile.propTypes = {
  history: PropTypes.object,
  getUserDetails: PropTypes.func,
  setUserDetails: PropTypes.array,
  getUserRoles: PropTypes.func,
  userRolesList: PropTypes.array,
  deleteEmployeeAPI: PropTypes.func,
  locationDetails: PropTypes.array,
  departmentDetails: PropTypes.array,
  match: PropTypes.object,
  fetchUserRole: PropTypes.func,
  role: PropTypes.string.isRequired,
  deleteUserAPI: PropTypes.func,
  fetchUserCompany: PropTypes.func.isRequired,
  userCompany: PropTypes.object,
  fetchUserProfileReward: PropTypes.func,
  userProfileReward: PropTypes.array,
  fetchUserCompanyDetails: PropTypes.func,
  userProfileCompany: PropTypes.array,
  fetchWellnessInterest: PropTypes.func,
  userWellnessInterest: PropTypes.array,
  getUserFriends: PropTypes.func,
  friendList: PropTypes.array,
  fetchUserConnectedDevices: PropTypes.func,
  fetchUserShoutOutCount: PropTypes.func,
  fetchUserInitiativesPeoplePage: PropTypes.func,
  userConnectedDevice: PropTypes.array,
  shoutOutData: PropTypes.array,
  userInitiativesData: PropTypes.array,
  companyInfo:PropTypes.object,
  companyBranding:PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  setUserDetails: state.peopleHome.setUserDetails,
  userRolesList: state.peopleHome.userRolesList,
  locationDetails: state.companyDashboard.companyPeopleLocation,
  departmentDetails: state.companyDashboard.companyPeopleDepartment,
  role: state.auth.role,
  userCompany: state.profileData.userCompany,
  userProfileReward: state.peopleHome.userProfileReward,
  userProfileCompany: state.peopleHome.userProfileCompany,
  userWellnessInterest: state.peopleHome.userWellnessInterest,
  friendList: state.social.friendList,
  userConnectedDevice: state.peopleHome.userConnectedDevice,
  shoutOutData: state.peopleHome.shoutOutData,
  userInitiativesData: state.peopleHome.userInitiativesData,
  companyBranding: state.wellnessDashboard.companyBrandingDetails,
})

const mapDispatchToProps = (dispatch) => ({
  getUserDetails: (id) => dispatch(getUserDetails(id)),
  getUserRoles: (userId) => dispatch(getUserRoles(userId)),
  deleteEmployeeAPI: (obj, history) => dispatch(DeleteEmployeeAPI(obj, history)),
  getCompanyLocation: (id) => dispatch(getCompanyLocation(id)),
  getCompanyDepartment: (id) => dispatch(getCompanyDepartment(id)),
  deleteUserAPI: (obj, history) => dispatch(deleteUserAPI(obj, history)),
  fetchUserRole: () => dispatch(getUserRole()),
  fetchUserCompany: () => dispatch(getUserCompanyDetails()),
  fetchUserProfileReward:(uid) => dispatch(fetchUserProfileReward(uid)),
  fetchUserCompanyDetails:(uid) => dispatch(fetchUserCompanyDetails(uid)),
  fetchWellnessInterest:(uid) => dispatch(fetchWellnessInterest(uid)),
  getUserFriends:(uid) => dispatch(getUserFriends(uid)),
  fetchUserConnectedDevices:(uid) => dispatch(fetchUserConnectedDevices(uid)),
  fetchUserShoutOutCount:(uid) => dispatch(fetchUserShoutOutCount(uid)),
  fetchUserInitiativesPeoplePage:(uid) => dispatch(fetchUserInitiativesPeoplePage(uid))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PersonalProfile))

// export default connect(mapStateToProps, mapDispatchToProps)((PersonalProfile));