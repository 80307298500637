import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Highcharts from 'highcharts';



const TrackChart = ({id, chartHeight, chartWidth, data}) => {
  useEffect(() => {
    // Access the Highcharts instance and trigger the render event to place icons

    const series = data.map((item, index) => {
      const radii = [
        { radius: '105%', innerRadius: '88%' },
        { radius: '80%', innerRadius: '63%' },
        { radius: '55%', innerRadius: '38%' }
      ];
    
      const icons = [
        { icon: 'filter', iconColor: '#303030' },
        { icon: 'comments-o', iconColor: '#ffffff' },
        { icon: 'commenting-o', iconColor: '#303030' }
      ];
    
      return {
        name: item.category,
        data: [{
          color: item.color,
          radius: radii[index].radius,
          innerRadius: radii[index].innerRadius,
          y: item.value // Finds the first non-zero value in the array
        }],
        custom: icons[index]
      };
    });
    Highcharts.chart(id || "container", {
      chart: {
        type: 'solidgauge',
        height: chartHeight,
        width: chartWidth
        // events: {
        //     render: function () {
        //         renderIcons(this); // Call the renderIcons function when the chart is rendered
        //     }
        // }
      },

      title: {
        text: '',
        // style: {
        //   fontSize: '24px'
        // }
      },

      tooltip: {
        borderWidth: 0,
        backgroundColor: 'none',
        shadow: false,
        style: {
          fontSize: '16px'
        },
        valueSuffix: '%',
        pointFormat: '{series.name}<br>' +
                '<span style="font-size: 1em; color: {point.color}; ' +
                'font-weight: bold">{point.y}</span>',
        positioner: function () {
          return {
            x: 10,
            y: -10
          };
        }
      },

      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [{
          outerRadius: '105%',
          innerRadius: '88%',
          backgroundColor: '#EFEFEF',
          borderWidth: 0
        }, {
          outerRadius: '80%',
          innerRadius: '63%',
          backgroundColor: '#EFEFEF',
          borderWidth: 0
        }, {
          outerRadius: '55%',
          innerRadius: '38%',
          backgroundColor: '#EFEFEF',
          borderWidth: 0
        }]
      },

      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true
        }
      },

      series: [...series],
      credits: {
        enabled: false
      }
    });
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  return <div id={id || "container"}  />;
};

TrackChart.propTypes = {
  chartTitle: PropTypes.string,
  totalRecognitions: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      value: PropTypes.arrayOf(PropTypes.number).isRequired,
      color: PropTypes.string.isRequired
    })
  ),
  chartHeight: PropTypes.number,
  chartWidth: PropTypes.number,
  id: PropTypes.string
};

export default TrackChart;
