import styled from 'styled-components';
import { Modal } from 'react-bootstrap';


export const StyledModal = styled(Modal)`
  /* Modal styles here */
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  .modal-content-padding {
  padding: 16px; 
  top:70px;
}
`;

export const CalendarHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center; 
  justify-content: space-between;
  background: none;
  margin-bottom: 20px; 
  color: #184A61;
  font-family: 'Lato';
  font-size: 18px;
  font-weight: 700;

  .left-content {
    display: flex;
    align-items: center; 
    gap: 10px;
  }

  .close-icon {
    cursor: pointer;
    text-align: right;
  }
`;


export const Border = styled.div`
 border: 1px solid #EFEFEF;
`;

export const CalendarWrapper = styled.div`
  ${'' /* padding: 25px; */}
`;

export const ConfirmVisitText = styled.div`
  font-family: "Lato";
  font-size: 16px;
  color:${({isColor}) => isColor ? isColor : '#184A61'};
  text-align: center;
  margin: 20px 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content:center;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const Button = styled.div`
  min-width:${({isWidth}) => isWidth ? isWidth : '352px'};
  height:${({isHeight}) => isHeight ? isHeight : '48px'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lato';
  padding: 12px 16px;
  font-size: 18px;
  border: ${({ border }) => (border ? "1px solid #007AB1" : "none")};
  background: ${({ background }) => background || "#007AB1"};
  color: ${({ color }) => color || "#fff"};
  cursor: pointer;
  border-radius: 6px;
  :hover {
    background: ${({ backgroundHover }) => backgroundHover || ""};
    color: ${({ colorHover }) => colorHover || ""};
  }

  .btn-text{
    margin-left:8px;
  }
`;
