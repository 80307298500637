import styled, { css } from "styled-components";

// const BoldFont = 'Rubik-Bold';
// const MediumFont = 'Rubik-Regular';


const Button = styled.button`
  background-color: ${({styles}) => styles && styles.color ? styles.color : '#007AB1'};
  border: ${({styles}) => styles &&  styles.color ? `1px solid ${styles.color}` : '1px solid #007AB1'};
  color: white;
  white-space: nowrap;
  padding: ${({ styles }) => styles && styles.padding ? '0' : '7px 10px'};
  width: ${({ styles }) => styles && styles.width ? '158px' : 'auto'};
  height: ${({ styles }) => styles && styles.height ? '35px' : 'auto'};
  font-size: 12px;
  line-height: 1.428571429;
  border-radius:${({styles}) => styles && styles.borderRadius ? styles.borderRadius : '30px'};
  ${'' /* font-family: ${({styles}) => styles && styles.fontWeight === 'medium' ? MediumFont : BoldFont}; */}
  margin-top: 10px;
  margin-bottom: ${({styles}) => styles && styles.marginBottom ? '55px' : '0'};
  float: ${({styles}) => styles && styles.float ? styles.float : 'left'};
  font-family:Lato;
  &:focus, &:active, &:hover {
    outline: none;
  }
  
  > i {
    margin-right: 5px;
  }

  ${({ disabled }) => disabled === true && css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `}
  
  @media only screen and (max-width: 767px) {
    margin-bottom: 15px;
  }
  
  ${({ addMargin }) => addMargin && css`
    @media (max-width: 500px) {
      margin-bottom: 5px;
    }
  `}
`;

const RequestButton = styled.div`
width: ${({ styles }) => styles && styles.width ? styles.width : '160px' };
height: ${({ styles }) => styles && styles.height ? styles.height : '40px' };
display: flex;
${'' /* font-family: Rubik-Medium; */}
font-family:Lato;
font-size: 18px;
line-height: 24px;
/* background: #FD7175; */
background: ${({ styles }) => styles && styles.background ? styles.background : "#FD7175"};
margin: ${({ margin }) => margin ? margin : "auto"};
color: #fff;
justify-content: center;
align-items: center;
cursor: ${({ styles }) => styles && styles.cursor ? styles.cursor : "auto"};
border-radius: ${({ styles }) => styles && styles.borderRadius ? styles.borderRadius : "6px"};
box-shadow: ${({ styles }) => styles && styles.boxShadow ? styles.boxShadow : "none"};

${({ disabled }) => disabled === true && css`
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed !important;
  `}
 `;

const EventButton = styled.button`
  margin:auto;
  width: 200px;
  width: ${({ styles }) => styles && styles.width ? styles.width : '200px'};
  background: ${({ disabled, styles, challengeDisabled }) => challengeDisabled ? "#005C874D": disabled ? "#005C874D" : styles && styles.color ? styles.color: '#007AB1'};
  border: ${({ disabled ,styles, challengeDisabled }) => challengeDisabled ? "#005C874D": disabled ? "#9C9C9C" : styles && styles.border ? `1px solid ${styles.border}`: '1px solid #007AB1'};
  float: ${({styles}) => styles && styles.float ? styles.float : "right"};
  padding: 10px;
  font-size: ${({styles}) => styles && styles.fontSize ? styles.fontSize : '18px'};
  color: ${({ styles }) =>  styles && styles.textColor? styles.textColor:'white'};
  margin-left: ${({ styles }) => styles && styles.marginAuto ? "auto" : styles && styles.marginLeft ? '0px' : '0px'};
  margin-right: ${({ styles }) => styles && styles.marginRight ? '15px' : '0px'};
  border-radius: 4px;
  ${'' /* font-family:"Rubik-Medium"; */}
  font-family:Lato;
  &:first-child {
  font-size: ${({styles}) => styles && styles.fontSize ? styles.fontSize : '18px'};
  }
  
  >img {
    margin-left: 10px;
    font-size: 12px;
    width:10px;
    height:15px;
  }
  
  ${({ color }) => color && css`
    > i {
      margin-left: 0;
      margin-right: 5px;
    }
  `}
  @media (max-width: 600px) {
    width: 90%;
    margin-top:5px;
   background-color:${({ color }) => color && "transparent"};
   border:${({ color }) => color && "none"}
  }

`;

export {Button, RequestButton, EventButton}