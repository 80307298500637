/* eslint-disable react/no-unknown-property */
import React, { Component } from 'react'
import { InitativeCard } from '../PersonalProfile/style';
import PropTypes from 'prop-types';
import moment from 'moment';
// import {getLastSyncDetails} from "../../utils/methods";
import { ActivityBar, ActivityBarFill, ActivityBarFillV2, ActivityBarV2, ActivityContainer, CountDiv, OverviewCard, OverviewCardV2, ShowPoints, WbaPoints } from './styles';
import { isNull, isUndefined } from 'lodash';
import Waiting from '../Waiting';
import BiometricDetails from '../BiometricConfirmPopup/BiometricDetails';
import { withTranslation } from 'react-i18next';

class HealthAndWellness extends Component {

  constructor(props) {
    super(props);
    this.state = {
      biometricPopupStatus: false
    }
  }

  showBiometricSubmitStatusPop = () => {
    this.setState((prevState) => ({biometricPopupStatus: !prevState.biometricPopupStatus}))
  }

  getWellbeingPercentage = (value) => {
    if (value) {
      return `${(value / 40) * 100}%`
    }
    else {
      return '0%';
    }
  }

  getTotalWellbeingPercentage = (value) => {
    if (value) {
      return `${(value / 200) * 100}%`
    }
    else {
      return '0%';
    }
  }

  getTotalWellbeingAssesment = (wellnessAssessment) => {
    if (wellnessAssessment && !isNull(wellnessAssessment.lifestyle && wellnessAssessment.fitness && wellnessAssessment.nutrition && wellnessAssessment.stress && wellnessAssessment.workplace)) {
      let total = wellnessAssessment.lifestyle + wellnessAssessment.fitness + wellnessAssessment.nutrition + wellnessAssessment.stress + wellnessAssessment.workplace
      const wellbeingTotalAssesmentData = [
        {
          // title: 'Lifestyle',
          fill1: this.getTotalWellbeingPercentage(total),
          fillColor1: '#ffffff',
          backgroundFill1: 'rgba(255, 255, 255, 0.15)',
          imgUrl: '/public/images/userrlifestyle.png',
          width: '13px',
          label: total,
        },
      ]
      return (
        <React.Fragment>
          {wellbeingTotalAssesmentData && wellbeingTotalAssesmentData.length > 0 && wellbeingTotalAssesmentData.map((item,index) => (
            <WbaPoints style={{ background: "#0D4270", alignItems: 'center' }} key={index}>
              <div style={{ width: '25%', display: 'flex', alignItems: 'center', color: 'white', fontFamily: 'rubik-medium', fontSize: '16px', lineHeight: '20px' }}>
                <div>Total</div>
              </div>
              <div>
                <ActivityContainer bloodPressure="5px 0 2px 0" width={'95%'} style={{ marginBottom: '10px' }}>
                  <ActivityBarV2 fillColor={item.fillColor1} backgroundFill={item.backgroundFill1}>
                    <ActivityBarFillV2 width={item.fill1} fillColor={item.fillColor1} >
                    </ActivityBarFillV2>
                  </ActivityBarV2>
                  <ShowPoints style={{ color: "#ffffff", width: '70px', marginTop: '2px' }}>
                    {wellnessAssessment.lifestyle + wellnessAssessment.fitness + wellnessAssessment.nutrition + wellnessAssessment.stress + wellnessAssessment.workplace}/200
                  </ShowPoints>
                </ActivityContainer>
              </div>
            </WbaPoints>))}
        </React.Fragment>
      )
    }
  }

  getWellbeingAssesment = (wellnessAssessment) => {
    const wellbeingAssesmentData = [
      {
        title: 'Lifestyle',
        fill1: wellnessAssessment && !isNull(wellnessAssessment.lifestyle) ? this.getWellbeingPercentage(wellnessAssessment.lifestyle) : 0,
        fillColor1: 'rgb(157, 135, 169)',
        backgroundFill1: 'rgba(157, 135, 169, 0.15)',
        imgUrl: '/public/images/userrlifestyle.png',
        width: '13px',
        label: wellnessAssessment && !isNull(wellnessAssessment.lifestyle) ? wellnessAssessment.lifestyle : 0,
      },
      {
        title: 'Physical Activity',
        fill1: wellnessAssessment && !isNull(wellnessAssessment.fitness) ? this.getWellbeingPercentage(wellnessAssessment.fitness) : 0,
        fillColor1: 'rgb(105, 194, 255)',
        backgroundFill1: 'rgba(105, 194, 255, 0.15)',
        imgUrl: '/public/images/userrfitness.png',
        width: '20px',
        label: wellnessAssessment && !isNull(wellnessAssessment.fitness) ? wellnessAssessment.fitness : 0
      },
      {
        title: 'Nutrition',
        fill1: wellnessAssessment && !isNull(wellnessAssessment.nutrition) ? this.getWellbeingPercentage(wellnessAssessment.nutrition) : 0,
        fillColor1: 'rgb(159, 201, 137)',
        backgroundFill1: 'rgba(159, 201, 137, 0.15)',
        imgUrl: '/public/images/userrNutrition.png',
        width: '13px',
        label: wellnessAssessment && !isNull(wellnessAssessment.nutrition) ? wellnessAssessment.nutrition : 0
      },
      {
        title: 'Emotional Wellness',
        fill1: wellnessAssessment && !isNull(wellnessAssessment.stress) ? this.getWellbeingPercentage(wellnessAssessment.stress) : 0,
        fillColor1: 'rgb(255, 153, 134)',
        backgroundFill1: 'rgba(255, 153, 134, 0.15)',
        imgUrl: '/public/images/userremotional_wellness.png',
        width: '13px',
        label: wellnessAssessment && !isNull(wellnessAssessment.stress) ? wellnessAssessment.stress : 0
      },
      {
        title: 'WorkPlace Wellness',
        fill1: wellnessAssessment && !isNull(wellnessAssessment.workplace) ? this.getWellbeingPercentage(wellnessAssessment.workplace) : 0,
        fillColor1: 'rgb(246, 180, 121)',
        backgroundFill1: 'rgba(246, 180, 121, 0.15)',
        imgUrl: '/public/images/userrworkplace.png',
        width: '21px',
        label: wellnessAssessment && !isNull(wellnessAssessment.workplace) ? wellnessAssessment.workplace : 0
      }
    ];

    return (
      <InitativeCard>
        <div className='company-initative'>
          <div className='heading'>
            {this.props.t("My")} {moment().format('YYYY')} {this.props.t("WBA Report")}&nbsp;
            <div className='button-container'>
              <button style={{ minWidth: '170px', background: '#69c2ff' }} onClick={() => this.props.userModal()} className='detail-button'>{this.props.t("View Comparison")}</button>
              {/* <button onClick={() => this.props.history.push('/wellness-assessment/score')} className='detail-button'>View Full HRA</button> */}
            </div>
          </div>
          <hr className='horizontal-line2' />
          <div className='fitness-wrapper'>
            {wellbeingAssesmentData && wellbeingAssesmentData.length > 0 && wellbeingAssesmentData.map((item, index) => (
              <WbaPoints key={index} id={item.title} marginRight={(index + 1) % 3 === 0 ? "0px" : "25px"}>
                <div>
                  <img src={item.imgUrl} backgroundFill={item.backgroundFill1} />
                </div>
                <div>
                  <div>{this.props.t(item.title)}</div>
                  <CountDiv style={{ color: '#69C2FF' }}>
                    <ActivityContainer key={index} bloodPressure="5px 0 2px 0" width={'95%'}>
                      <ActivityBar fillColor={item.fillColor1} backgroundFill={item.backgroundFill1}>
                        <ActivityBarFill width={item.fill1} fillColor={item.fillColor1}>
                        </ActivityBarFill>
                      </ActivityBar>
                      <ShowPoints>
                        {this.props.t(item.label)}/40
                      </ShowPoints>
                    </ActivityContainer>
                  </CountDiv>
                </div>
              </WbaPoints>))}
            {this.getTotalWellbeingAssesment(wellnessAssessment)}
          </div>
        </div>
      </InitativeCard>
    )
  };

  render() {
    const { history, wellnessGoals, health, userBiometric, wellnessAssessment } = this.props;
    if (isNull(wellnessAssessment) && isUndefined(wellnessAssessment)) {
      return <Waiting />
    }
    return (
      <div>
        {this.getWellbeingAssesment(wellnessAssessment)}
        <OverviewCard>
          <div className='view-card'>
            <div className='prof-details'>
              <div className='name-in'>{this.props.t("My")} {moment().format('YYYY')} {this.props.t("Biometric Screening Report")}</div>
              <div><button style={{ minWidth: '170px', background: '#69c2ff',marginRight:"0px" }} onClick={() => this.showBiometricSubmitStatusPop()} className='detail-button'>{this.props.t("View Report")}</button>
              </div>
              {/* <button onClick={() => history.push('/settings')} className='user-edit-btn'>Edit Profile</button> */}
            </div>
            <hr className='horizontal-line' />
            <div className='emp-detail'>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Height")} : </div>
                {userBiometric && !isNull(userBiometric.height) && <div className='prof-data'>{userBiometric.height}</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("HDL Cholesterol")} : </div>
                {userBiometric && !isNull(userBiometric.cholestrol_hdl) && <div className='prof-data'>{userBiometric.cholestrol_hdl} mg/dl</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Weight")} :  </div>
                {userBiometric && !isNull(userBiometric.weight) && <div className='prof-data'>{userBiometric.weight} Lbs</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("LDL Cholesterol")} : </div>
                {userBiometric && !isNull(userBiometric.cholestrol_ldl) && <div className='prof-data'>{userBiometric.cholestrol_ldl} mg/dl</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Waist")} : </div>
                {userBiometric && !isNull(userBiometric.waist_circumference) && <div className='prof-data'>{userBiometric.waist_circumference}</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Triglycerides")} : </div>
                {userBiometric && !isNull(userBiometric.triglycerides) && <div className='prof-data'>{userBiometric.triglycerides} mg/dl</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("BMI")} : </div>
                {userBiometric && !isNull(userBiometric.bmi) && <div className='prof-data'>{userBiometric.bmi}</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Glucose")} : </div>
                {userBiometric && !isNull(userBiometric.glucose) && <div style={{ color: '#0D4270' }} className='color'>{userBiometric.glucose} mg/dl</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Systolic Blood Pressure")} : </div>
                {userBiometric && !isNull(userBiometric.blood_pressure_sys) && <div className='prof-data'>{userBiometric.blood_pressure_sys} mm/Hg</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Diastolic Blood Pressure")} : </div>
                {userBiometric && !isNull(userBiometric.blood_pressure_dia) && <div style={{ color: '#0D4270' }} className='green'>{userBiometric.blood_pressure_dia} mm/Hg</div>}
              </div>
            </div>
          </div>
        </OverviewCard>
        <OverviewCardV2>
          <div className='view-card'>
            <div className='prof-details'>
              <div className='name-in'>{this.props.t("My")} {moment().format('YYYY')} {this.props.t("Fitness Goal")}</div>
              <button onClick={() => history.push('/settings')} className='user-edit-btn'>{this.props.t("Edit Goal")}</button>
            </div>
            <hr className='horizontal-line' />
            <div className='emp-detail'>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Health")}: </div>
                {wellnessGoals?.other_goals[0] && wellnessGoals?.other_goals?.length > 0 ? <div className='prof-data'>{wellnessGoals?.other_goals[0]?.name}</div> : <div style={{ color: '#0D4270' }}>-</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Performance")}: </div>
                {wellnessGoals?.top_goals[0] && wellnessGoals?.top_goals?.length > 0 ? <div className='prof-data'>{wellnessGoals?.top_goals[0]?.name}</div> : <div style={{ color: '#0D4270' }}>-</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Behavioral")}: </div>
                {wellnessGoals?.other_goals[1] && wellnessGoals?.other_goals?.length > 0 ? <div className='prof-data'>{wellnessGoals?.other_goals[1]?.name}</div> : <div style={{ color: '#0D4270' }}>-</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Racing and Competition")}: </div>
                {wellnessGoals?.other_goals[2] && wellnessGoals?.other_goals?.length > 0 ? <div className='prof-data'>{wellnessGoals?.other_goals[2]?.name}</div> : <div style={{ color: '#0D4270' }}>-</div>}
              </div>
            </div>

          </div>
        </OverviewCardV2>
        <OverviewCardV2 flex height labelWidth fieldWidth>
          <div className='view-card'>
            <div className='prof-details'>
              <div className='name-in'>{this.props.t("My General Health Information")}</div>
              <button onClick={() => history.push('/settings')} className='user-edit-btn'>{this.props.t("Edit Info")}</button>
            </div>
            <hr className='horizontal-line' />
            <div className='emp-detail'>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("How Many Days Per Week Do You Exercise?")} </div>
                {health && health[0] && health[0].exercise_day_per_week && health[0].exercise_day_per_week.length > 0 ? <div className='prof-data'>{this.props.t(health[0].exercise_day_per_week)}</div> : <div style={{color: '#0D4270'}}>-</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Do You Smoke?")} </div>
                {health && health[0] && health[0].do_you_smoke && health[0].do_you_smoke.length > 0 ? <div className='prof-data'>{this.props.t(health[0].do_you_smoke)}</div> : <div style={{color: '#0D4270'}}>-</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Physical Exam Frequency")}: </div>
                {health && health[0] && health[0].physical_exam_frequency && health[0].physical_exam_frequency.length > 0 ? <div className='prof-data'>{this.props.t(health[0].physical_exam_frequency)}</div> : <div style={{color: '#0D4270'}}>-</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Average Sleep Per Night?")} </div>
                {health && health[0] && health[0].average_sleep_per_night && health[0].average_sleep_per_night.length > 0 ? <div className='prof-data'>{this.props.t(health[0].average_sleep_per_night)}</div> : <div style={{color: '#0D4270'}}>-</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Average Water Intake Per Day?")} </div>
                {health && health[0] && health[0].average_water_intake && health[0].average_water_intake.length > 0 ? <div className='prof-data'>{this.props.t(health[0].average_water_intake)}</div> : <div style={{color: '#0D4270'}}>-</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("How Often Do You Experience Pain In Your Body?")} </div>
                {health && health[0] && health[0].pain_experience && health[0].pain_experience.length > 0 ? <div className='prof-data'>{this.props.t(health[0].pain_experience)}</div> : <div style={{color: '#0D4270'}}>-</div>}
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("How Many Prescription Drugs Do You Take Daily?")} </div>
                {health && health[0] && health[0].prescription_intake && health[0].prescription_intake.length > 0 ? <div className='prof-data'>{this.props.t(health[0].prescription_intake)}</div> : <div style={{color: '#0D4270'}}>-</div>}
              </div>
            </div>
          </div>
        </OverviewCardV2>

        {this.state.biometricPopupStatus && 
          <BiometricDetails
            showModal={this.state.biometricPopupStatus}
            closeModal={this.showBiometricSubmitStatusPop}
          />
        }
      </div>
    )
  }
}

HealthAndWellness.propTypes = {
  userModal: PropTypes.func,
  user: PropTypes.object,
  interest: PropTypes.object,
  wellnessInitiatives: PropTypes.array,
  userRecognitionCount: PropTypes.array,
  history: PropTypes.object.isRequired,
  wellnessGoals: PropTypes.object,
  health: PropTypes.array,
  userBiometric: PropTypes.array,
  wellnessAssessment: PropTypes.array,
  t: PropTypes.func
};

export default ((withTranslation())(HealthAndWellness));