/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import map from 'lodash/map';
import _ from 'lodash';
import { Flexbox, GridGroup, GroupButton, ActivityFlex, SplitFitnessSection, FitnessCommonContainer, FitnessChampionsCard, TitleContainer, Title, FitnessNewUserCard, FitnessEmployeeGrid, FitnessTabContainer, FitnessContainer, CardContainer, GroupActivityValue, /*ProfileLogoContainer,*/MinutesActiveDescription, FitnessUserCardContainer, 
  CurrentYear,BarLine ,NewCardContainer, NewBoxShadow,Row1,ImgContainer, BoxData, NewTotalData, NewImgContainer, CustomDropDown } from './styles';
import {NoData } from '../EngagementSection/styles';
import { HeaderContainer, HeaderTitle, HeaderImage } from '../HealthTab/styles';
import UserCard from '../UserCardV3';
import VerticalBarChart from '../VerticalBarChart';
import HighChatV2 from '../HighChatV2';
import PieChart from '../PieChart';
import { getUnit, getActivityDate } from '../../utils/methods';
import { FetchGroupActivitySummary } from '../../redux/actions';
import Waiting from '../Waiting';
import { ImageUrl } from '../../utils/constants';
import { DropdownItem } from 'react-bootstrap';
import LazyImage from '../common/LazyImage/LazyImage';
const monthData = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const weekData = ["Q1", "Q2", "Q3", "Q4"];

class FitnessTab extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      activeButton: 'Yearly',
      activeYear:new Date().getFullYear(),
      groupActivitySummaryData: monthData, 
      weekButton: false,
      monthButton: false,
      yearButton: true,
      activeIndex: 0,
      companyId: '',
      getDate: monthData[0],
      getYear: 0,
      displayLogo: false,
      selectedIndex: 0,
      selectedIndex1: null,
      selectedValue: null,
      options: ["2019","2018","2017"],
      showMore: false,
      yearData: props.yearList,
      currentYear: new Date().getFullYear(),
      value: "Monthly",
      year:'year'
    }
  }

  componentDidMount() {
    this.fetchAPIForActivity();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if(prevState.getDate !== this.state.getDate) {
  //     this.fetchAPIForActivity();
  //   }
  // }

  UNSAFE_componentWillReceiveProps() {
    const { companyId } = this.props;
    if(companyId != this.state.companyId) {
      this.fetchAPIForActivity();
      this.setState({yearData:this.props.yearList, getYear: this.props.yearList.length - 1}, ()=>{
        this.getGroupSummary(weekData, monthData, this.state.yearData)
      })
    }
  }

  fetchAPIForActivity = () => {
    const { fetchGroupActivitySummary, companyId } = this.props;
    const {yearData} = this.state;
    let obj = {};
    if(this.state.yearButton) {
      obj = getActivityDate('year',yearData[this.state.getYear]);
    }
    else {
      obj = getActivityDate(this.state.getDate,yearData[this.state.getYear]);
    }
    fetchGroupActivitySummary(companyId, obj.startDate, obj.endDate)
    this.setState({companyId})
  };

  getGroupSummary = (WeekData, monthData,yearData) => {
    const { activeButton } = this.state;
    switch(activeButton) {
    case 'Quarterly':
      this.setState({
        groupActivitySummaryData: WeekData,
        weekButton: true,
        monthButton: false,
        yearButton: false,
        getDate: weekData[0],
        activeIndex: 0
      }, () => {
        this.fetchAPIForActivity();
      });
      break;
    case 'Monthly':
      this.setState({
        groupActivitySummaryData: monthData,
        weekButton: false,
        monthButton: true,
        yearButton: false,
        activeIndex: 0,
        getDate: monthData[0]
      }, () => {
        this.fetchAPIForActivity();
      })

      break;
    case 'Yearly':
      this.setState({
        groupActivitySummaryData: yearData,
        weekButton: false,
        monthButton: false,
        yearButton: true,
        getDate: yearData[this.state.getYear],
        activeIndex: 0
      }, () => {
        this.fetchAPIForActivity();
      });
      break;
 
    }
  };

  changeActiveButton = (value, weekData, monthData, yearData) => {
    this.setState({
      activeButton: value
    }, () => {
      this.getGroupSummary(weekData, monthData, yearData)
    })
  };

  changeActiveYear = (value, yearData,index) => {
    this.setState({
      activeYear: value,
      activeButton: "Yearly",
      getYear:index,
      weekButton: false,
      monthButton: false,
      yearButton: true,
    
      getDate: yearData[this.state.getYear]

    }, () => {
      this.fetchAPIForActivity();
    })
  };
  
  getModelLogo = (url) => (
    <HeaderImage src={`/images/CompanyDashBoardV2/${url}`} />
  );

  selectTitle = (e)  => {
    this.setState({value : e.target.value}); 
  }
  showUsersCard = (userData) => {
    const {showMore} = this.state;
    const slicedUserData = userData?.slice(0, 6);
    if (showMore === true) {
      return userData.map((data, index) => (
        <FitnessChampionsCard key={index} isBigImage  newWidth={"newWidth"} newPadding={"newPadding"}>
          <div>
            <UserCard data={data}/>
          </div>
        </FitnessChampionsCard>
      ))
    }

    else {
      return slicedUserData?.map((data, index) => (
        <FitnessChampionsCard key={index} isBigImage newWidth={"newWidth"} newPadding={"newPadding"}>
          <div>
            <UserCard data={data}/>
          </div>
        </FitnessChampionsCard>
      ))
    }

  };

  showMoreCards = () => {
    this.setState ((prev) => ({showMore: !prev.showMore}))
  };

  renderHeader = (title) => (
    <HeaderContainer>
      <div>
        {this.getModelLogo("modal-logo.png")}
        <HeaderTitle>{title}</HeaderTitle>
      </div>
    </HeaderContainer>
  );

  renderFitnessHeader = (title, bgColor, textColor, url, displayLogo=true) => {
    const{ selectedYearInfo } = this.props;
    return <TitleContainer bgColor={"#0D4270"} padding={"0px"} borderRadius={"6px 6px 0 0"} height={"44px"}>
      {displayLogo && this.getModelLogo("running-icon.png")}
      <Title   textTransform={1} textColor={"#fff"} padding={displayLogo ? "3px" : ""}>{title} </Title>
      <CurrentYear>
        <p className={'current-year'}>{(title === "MINUTES ACTIVE ") ? selectedYearInfo :""}</p>
      </CurrentYear>
    </TitleContainer>
  };

  renderFitnessHeaderNew = (title, bgColor, textColor, url, displayLogo=true) => {
    const{ selectedYearInfo } = this.props;
    return <TitleContainer bgColor={"#0D4270"} padding={"0px"} borderRadius={"6px 6px 0 0"} height={"44px"}>
      {displayLogo && this.getModelLogo("running-icon.png")}
      <Title   textTransform={1} textColor={"#fff"} padding={displayLogo ? "3px" : ""}>{title} </Title>
      <CurrentYear>
        <p className={'current-year'}>{(title === "MINUTES ACTIVE ") ? selectedYearInfo :""}</p>
      </CurrentYear>
    </TitleContainer>
  };

  renderFitnessDevicesHeader = (title) => (
    <TitleContainer bgColor={"#0D4270"} padding={"0px 0 0px 0px"} borderRadius={"6px 6px 0 0"}  height={'44px'} >
      <Title textTransform={1} textColor={"#FFFFFF"} className='title-wellness' >{title} </Title>
      <CurrentYear />
    </TitleContainer>
  );

  newRenderFitnessDevicesHeader = (title) => (
    <TitleContainer bgColor={"rgb(243,246,249)"} padding={"0px 0 0 0"} borderRadius={"6px 6px 0 0"}>
      <Title textTransform={1} textColor={"#0D4270"}   paddingTop={'25px'}>{title} </Title>
      <CurrentYear />
    </TitleContainer>
  )

  renderMostActiveUserTitle = (data, title) => (
    <TitleContainer bgColor={"#0D4270"} borderRadius={"6px 6px 0px 0px"} padding={"0px 0 0px 0px"} height={'44px'}>
      <Title textColor={'#fff'}>{title}</Title>
    </TitleContainer>
  );

  showMostActiveUsers = (data, title) => (
    <div>
      { this.renderMostActiveUserTitle(data, title) }
      <CardContainer float={1} textAlign padding = {'0px'}>
        { isEmpty(data) ? <NoData>No most active users found</NoData> : this.showUsersCard(data) }
      </CardContainer>
    </div>
  );

  showLeastActiveUser = (data, title) => (
    <div>
      { this.renderHeader(title) }
      <FitnessCommonContainer float={1} textAlign padding="20px 0px 20px 0px">
        { isEmpty(data) ? 'No least active users found' : this.showUsersCard(data) }
      </FitnessCommonContainer>
    </div>
  );

  showTopFitnessActivity = (topFitnessDetail, title) => {
    if(_.isUndefined(topFitnessDetail) || _.isNull(topFitnessDetail) || isEmpty(topFitnessDetail)) {
      return(
        <div>
          { this.renderFitnessHeader(title, "#1C4667", "#fff", "arrow-white.png", true, "#fff") }
          <FitnessCommonContainer textAlign>
            {
              'No top activities'
            }
          </FitnessCommonContainer>
        </div>
      );
    }

    else {
      const xAxisLabels = map(topFitnessDetail, 'name');
      const data = [{
        name: '',
        data: topFitnessDetail.map((c) => Number(c.percentage)),
        showInLegend: false,
        color: '#0C99C1'
      }];
      return(
        <div>
          { this.renderFitnessHeader(title, "#1C4667", "#fff", "arrow-white.png", true, "#fff") }
          <FitnessCommonContainer textAlign>
            {
              isEmpty(topFitnessDetail) && _.isNull(topFitnessDetail) &&  _.isUndefined(topFitnessDetail)
                ? 'No top activities' :
                <Flexbox>
                  <VerticalBarChart
                    seriesData={data}
                    xAxisLabel={xAxisLabels}
                  />
                </Flexbox>
            }
          </FitnessCommonContainer>
        </div>
      );
    }

  };

  showTopFitnessDevice = (data, title) => (
    <div>
      {this.renderFitnessDevicesHeader(title)}
      <FitnessCommonContainer float={1} padding="0px 20px 0px 15px" solidTop={1}>
        { isEmpty(data) ? <NoData>No top devices found</NoData> : this.showFitnessDetail(data) }
      </FitnessCommonContainer>
    </div>
  );

  showFitnessGoals = (data, title) => {
    if (_.isUndefined(data) || _.isNull(data) || isEmpty(data) || data.length === 0) {
      return (
        <div id="fitness-goals-empty">
          {this.renderFitnessDevicesHeader(title, 1)}
          <FitnessCommonContainer textAlign>
            {'No top Wellness goals'}
          </FitnessCommonContainer>
        </div>
      );
    } else {
      const goalData = [];
      const xAxisLabels = [];
      const goalColors = ["#FD7175", "#69C2FF", "#F6B479", "#9FC989", "#9D87A9"];
      data.map((goal, index) => {
        xAxisLabels.push(goal.name);
        goalData.push({
          "name": goal.name,
          "y": parseInt(goal.percentage, 10),
          "color": goalColors[index]
        });
      });
      let finalData = [{
        name: '',
        data: goalData,
        showInLegend: false,
        color: '#DE9444'
      }];
      return (
        <div id="fitness-goals-container" style={{ height: '100%' }}>
          {this.renderFitnessDevicesHeader(title, 1)}
          <FitnessCommonContainer textAlign height={1}>
            {isEmpty(data) && data.length === 0 ? <NoData>No top Wellness goals</NoData> : <HighChatV2 data={finalData} xAxisLabels={xAxisLabels} container="fitnessGoal" />}
          </FitnessCommonContainer>
        </div>
      );
    }
  }

  getPieChart = (pieChartData) => (
    pieChartData.map((data, index) => (
      <FitnessCommonContainer float={1} key={index}>
        <PieChart pieValue={data.value} bgColor="#93bf3d" title={data.title} containerId={data.id}/>
      </FitnessCommonContainer>
    ))
  );

  renderWeeklyExcerciseDetails = (data) => (
    data.map((item, index) => (
      <FitnessUserCardContainer key={index}>
        <FitnessNewUserCard width="24px"  height={"74px"} borderNone={index === data.length - 1}>
          <FitnessEmployeeGrid color="#333333" gridWidth="65%" paddingLeft={1}>
            <div className="dayInterval">{item.title}</div>
          </FitnessEmployeeGrid>
          <FitnessEmployeeGrid gridWidth="72px" textAlign={1} color="#159fc9" position={'contents'}>
            <BoxData bgColor={item.color}  marginTop= "0px">{item.value}%</BoxData>
          </FitnessEmployeeGrid>
        </FitnessNewUserCard>
      </FitnessUserCardContainer>
    ))
  );

  toggleRadioBtn = (index) => {
    this.setState({
      selectedIndex: index,
      selectedValue: this.state.options[index],
      options: this.state.options
    });
  };

  showWeeklyExercise = (pieChartData, title) => {
    const {displayLogo} = this.state;
    if(_.isUndefined(pieChartData) || _.isNull(pieChartData) || isEmpty(pieChartData)) {
      return(
        <div>
          {this.renderFitnessHeaderNew(title, "#1C4667", "#fff;", "down-arrow.png", displayLogo)}
          <FitnessCommonContainer textAlign>
            {
              'No Weekly Exercise'
            }
          </FitnessCommonContainer>
        </div>
      );
    }
    else {
      const pieData = [
        {
          value: pieChartData.exercise01,
          title: "0 to 1 day",
          id: 'pieChart1',
          color:"rgba(246,180,121,0.15)"
        },
        {
          value: pieChartData.exercise23,
          title: "2 to 3 days",
          id: 'pieChart2',
          color:"rgba(105,194,255,0.15)"
        },
        {
          value: pieChartData.exercise45,
          title: "4 to 5 days",
          id: 'pieChart3',
          color:"rgba(157,135,169,0.15)"
        },
        {
          value: pieChartData.exercise67,
          title: "6 to 7 days",
          id: 'pieChart4',
          color:"rgba(253,113,117,0.15)"
        }
      ];
      return(
        <div>
          {this.renderFitnessHeaderNew(title, "#1C4667", "#fff;", "down-arrow.png", displayLogo)}
          {this.renderWeeklyExcerciseDetails(pieData)}
        </div>
      );
    }
  };

  showDailyActiveMinutes = (data, title) => {
    const {displayLogo} = this.state;
    if(_.isUndefined(data) || _.isNull(data) || isEmpty(data)) {
      return(
        <div>
          {this.renderFitnessHeader(title, "#1C4667", "#fff;", "down-arrow.png", displayLogo)}
          <MinutesActiveDescription>
            <p>
              How many total minutes per day are you active? (eg. chores, walking the dog, playing with the kids)
            </p>
          </MinutesActiveDescription>
          <FitnessCommonContainer textAlign>
            {
              'No Daily Active Minutes'
            }
          </FitnessCommonContainer>
        </div>
      );
    }
    else {
      const pieData = [
        {
          value: data["less_than_15_percentage"],
          title: "Less Than 15 Minutes",
          id: 'pieChart1',
          color:"rgba(246,180,121,0.15)"
        },
        {
          value: data["15_to_30_percentage"],
          title: "15 - 30 Minutes",
          id: 'pieChart2',
          color:"rgba(105,194,255,0.15)"
        },
        {
          value: data["30_to_45_percentage"],
          title: "30 - 45 Minutes",
          id: 'pieChart3',
          color:"rgba(157,135,169,0.15)"
        },
        {
          value: data["45_plus_percentage"],
          title: "45+ Minutes",
          id: 'pieChart4',
          color:"rgba(253,113,117,0.15)"
        }
      ];
      return(
        <div>
          {this.renderFitnessHeader(title, "#1C4667", "#fff;", "down-arrow.png", displayLogo)}
          <MinutesActiveDescription>
            <p>
              How many total minutes per day are you active? (eg. chores, walking the dog, playing with the kids)
            </p>
          </MinutesActiveDescription>
          {this.renderWeeklyExcerciseDetails(pieData)}
        </div>
      );
    }
  };

  showGroupActivityValue = (image,name, steps, colories) => (
    <FitnessCommonContainer float={1} display={1} borderBottom={1} newPadding={1} newHeight={1}>
      <div className="SubSectionNew">
        <FitnessEmployeeGrid gridWidth="100%" groupActivity={1}>
          <GroupButton bgColor='none' textColor=' #0D4270' fontSize='13px' capitalize={1} noSpace={1} newPadding={1} justifycontent={"center"}>
            { image}&nbsp;&nbsp;&nbsp;<span className={"units"}>{ name }</span>
          </GroupButton>
        </FitnessEmployeeGrid>
        <FitnessEmployeeGrid gridWidth="100%" groupActivity={1} color="#0D4270">
          <div className="step">{getUnit(Math.round(steps))} <span className={"units"}>{ name }</span></div>
        </FitnessEmployeeGrid>
        <FitnessEmployeeGrid gridWidth="100%" groupActivity={1} color="#0D4270">
          <div className="values">{getUnit(parseFloat(colories))} <span className={"units"}>{ name }</span></div>
        </FitnessEmployeeGrid>
        <FitnessEmployeeGrid gridWidth="22%" groupActivity={1} color="#0D4270" />
      </div>
    </FitnessCommonContainer>
  );
  
  getBgColor = (value, activeButton) => {
    if(value === activeButton) {
      return '#FD7175';
    }
    else {
      return false;
    }
  };

  getButtonColor = (value, activeButton) => {
    if(value === activeButton) {
      return '#fff';
    }
    else {
      return "#9c9c9c";
    }
  }

  getButtonBgColor = (value, activeButton) => {
    if(value === activeButton) {
      return '#fff';
    }
    else {
      return '#0D5783'
    }
  };

  getColor = (value, activeButton) => {
    if(value === activeButton) {
      return '#1E76AB';
    }
    else {
      return '#fff';
    }
  };

  changeActiveIndex = (index, data) => {
    if(this.state.yearButton) {
      this.setState({
        getYear: index
      }, () => {
        this.fetchAPIForActivity();
      })
    }
    this.setState({
      activeIndex: index,
      getDate: data
    }, () => {
      this.fetchAPIForActivity();
    })
  };

  getActivityList = () => {
    const { groupActivitySummaryData, activeIndex } = this.state;
    return groupActivitySummaryData.map((data, index) => (
      <GridGroup grid={groupActivitySummaryData.length} key={index}>
        {!this.state.yearButton && <GroupButton 
          bgColor={() => this.getBgColor(index, activeIndex)}
          capitalize={1} 
          textColor={() => this.getButtonColor(index, activeIndex)}
          padding="4px 7px"
          fontSize="14px"
          Align={1}
          radius="3px"
          fontFamily="Rubik"
          onClick={() => this.changeActiveIndex(index, data)}
          style={{padding:"4px 7px"}}
        >
          {data}
        </GroupButton>}
      </GridGroup>
    ))
  };

  groupActivitySummary = (title,weekData, monthData, yearData) => {
    const { activeButton,activeYear } = this.state;
    const { summaryData,employeeCount} = this.props;
    return (
      <div className="boxShadow">
        <div className="card-top">
          <div className="groupActivity">
            {title}
          </div>
          <div className="emp_count">
            {employeeCount} Employees
          </div>
          <div className="button_cls">
            <CustomDropDown newWidth={'newWidth'}
              inputpadding={"0px"}
              value={this.state.year}
              style={{ color: "white", marginRight:"15px",maxHeight:"39px", display:"flex" }}
              title={activeYear}
              marginRight={"15px"}
            >
              {yearData.map((list, index) => (
                <DropdownItem
                  key={index}
                  newWidth={'newWidth'}
                  value={list}
                  id={3}
                  bgColor={() => this.getButtonBgColor('year', activeButton)}
                  textColor={() => this.getColor('Yearly', activeButton)}
                  onClick={() => this.changeActiveYear(list, yearData, index)}>
                  {list}
                </DropdownItem>
              ))}
            </CustomDropDown>
            <CustomDropDown newWidth={'newWidth'}
              value={this.state.value}
              style={{ color: "white", maxHeight:"39px", display:"flex" }}
              title={activeButton}
              marginLeft={"15px"}
              inputpadding={"0px"}
            >
              <DropdownItem
                newWidth={'newWidth'}
                value="Monthly"
                id={1}
                bgColor={() => this.getButtonBgColor('month', activeButton)}
                textColor={() => this.getColor('Monthly', activeButton)}
                onClick={() => this.changeActiveButton('Monthly', weekData, monthData, yearData)}>
                   Monthly
              </DropdownItem>
              <DropdownItem
                newWidth={'newWidth'}
                value="Quarterly" 
                id={2}  
                bgColor={() => this.getButtonBgColor('week', activeButton)}
                textColor={() => this.getColor('Quarterly', activeButton)}
                onClick={() => this.changeActiveButton('Quarterly', weekData, monthData, yearData)}>
                 Quarterly
              </DropdownItem> 
              <DropdownItem
                newWidth={'newWidth'}
                value="Yearly" 
                id={3}  
                onClick={() => this.changeActiveButton('Yearly', weekData, monthData, yearData)}>
                 Yearly
              </DropdownItem> 
            </CustomDropDown>
          </div>
        </div>
        <FitnessCommonContainer float={1} textAlign={1} padding="0px">
          <ActivityFlex bgColor="#F3F6F9">
            { this.getActivityList() }
          </ActivityFlex>
        </FitnessCommonContainer>
        <FitnessCommonContainer float={1} display={1} padding="8px" color="#3a3a3a">
          <div className="border">
            <FitnessEmployeeGrid gridWidth="100%" newPadding="10px" marginTop="-45px" padding={"25px"} bgColor={"transparent"}>
              Category
            </FitnessEmployeeGrid>
            <FitnessEmployeeGrid gridWidth="100%" groupActivity={1} newPadding={"newPadding"} marginTop="-45px" padding={"25px"} bgColor={"transparent"} color="#3a3a3a">
              User Daily Average
            </FitnessEmployeeGrid>
            <FitnessEmployeeGrid gridWidth="100%" groupActivity={1} newPadding={"newPadding"} marginTop="-45px" padding={"25px"} bgColor={"transparent"} color="#3a3a3a">
              Group Total
            </FitnessEmployeeGrid>
            <FitnessEmployeeGrid gridWidth="22%" groupActivity={1} />
          </div>
        </FitnessCommonContainer>
        <GroupActivityValue>
          { this.showGroupActivityValue(<LazyImage src={ImageUrl + '/images/CompanyDashBoardV2/step.svg'}/>,'Steps', summaryData['average_steps'], summaryData['steps'])}
          { this.showGroupActivityValue(<LazyImage src={ImageUrl + '/images/CompanyDashBoardV2/caloriesNew.svg'}/>,'Calories', summaryData['average_calories'], summaryData['calories'])}
          { this.showGroupActivityValue(<LazyImage src={ImageUrl + '/images/CompanyDashBoardV2/distanceNew.svg'}/>,'Miles', summaryData['average_distance'], summaryData['distance'])}
          <FitnessCommonContainer padding="0 0 14px" float={1} borderTop/>
        </GroupActivityValue>
      </div>
    );
  };

  showFitnessDetail = (data) => (
    data.map((fitness, index) => {
      const Source = fitness.device_name.replace(/ /g, '-');
      return(
        <FitnessNewUserCard key={index} width="24px" height={"76px"} borderNone={index === data.length - 1}>
          <FitnessEmployeeGrid  gridWidth="auto" minWidth={1} borderRadius={1} isBigImage={fitness.device_name === 'garmin'} 
            position={'relative'} bottom={'24px'} right={'0px'}>
            <LazyImage className="device-images" src={`${ImageUrl}/images/Logo/${Source}.png`} alt={fitness.device_name}  style={{width: "50px", height: "auto","margin-right":"20px"}}/>
          </FitnessEmployeeGrid>
          <FitnessEmployeeGrid color="#333333" gridWidth="65%" paddingLeft={1}>
            <div className="deviceName">{fitness.display_name}
              <span>{fitness.user_count} Users</span>
            </div>
          </FitnessEmployeeGrid>
          <FitnessEmployeeGrid gridWidth="72px" gridHeight="38px" textAlign={1} color="#159fc9" borderRadius="6px"  
            bgColor={fitness.color}>
            <div className="fitnessDevice">{fitness.percentage}%</div>
          </FitnessEmployeeGrid>
        </FitnessNewUserCard>
      )
    })
  );
  newFitnessData = (data) => (
    data.map((details, index) => (
      <NewCardContainer key={index}>
        <Row1 padding={1} display={'flex'} style={{float:"unset", paddingTop:"10px"}}>
          <ImgContainer  bgColor={details.color} color={details.text_color}>
            <LazyImage src={`${ImageUrl}/${details.interest_icon}`}/>
          </ImgContainer>
          <div className="textdata">
            <p>{details.name}</p>
          </div>
        </Row1>
        <Row1 padding={1} style={{float:"unset"}}>
          <NewImgContainer color={details.percentage_color }>
            <BarLine color={details.text_color } newWidth={`${details.percentage}%`}>
              &nbsp;&nbsp;
            </BarLine>
          </NewImgContainer>
        </Row1>
        <Row1 paddingTop={'paddingTop'} style={{float:"unset"}}>
          <NewTotalData  style={{float: "left"}} color={details.text_color }>{details.percentage}%</NewTotalData>
        </Row1>
        <Row1 paddingTop={'paddingTop'} style={{float:"unset"}}>
          <div className="totaldata">100%</div>
        </Row1>
      </NewCardContainer>
        
    ))
  );



  newShowTopFitnessDevice = (data, title) => (
    <div>
      {this.newRenderFitnessDevicesHeader(title)}
      <FitnessCommonContainer float={1} padding="0px" solidTop={1} newWidth="100%">
        { isEmpty(data) ? <NoData>No top devices found</NoData> : this.newFitnessData(data) }
      </FitnessCommonContainer>
    </div>
  );


  render() {
    const { summaryData,mostActiveUsers,topDevices,employeeCount, 
      fitnessGoals, topActivities, activeMinutes, fitnessTabLoading, weekExercise} = this.props;

    if(!summaryData ||  !employeeCount || fitnessTabLoading || !this.props.yearList) {
      return <Waiting />
    }
    return(

      <FitnessTabContainer style={{width:"1246px",margin:"auto",float:"none"}}>
        <FitnessContainer>
          <SplitFitnessSection gridWidth="43%" height={'350px'}>
            <div className='boxShadow'>
              { this.showFitnessGoals(fitnessGoals, "Top 5 Wellness goals")}
            </div>
          </SplitFitnessSection>
          <SplitFitnessSection gridWidth="57%" PaddingRight={0}  marginLeft={1} mbotton={'0px'}>
            { this.groupActivitySummary('Group Activity Summary', weekData, monthData, this.state.yearData)}
          </SplitFitnessSection>
        </FitnessContainer>
        <FitnessContainer >
          <SplitFitnessSection gridWidth="65%" PaddingRight={1} ShowActiveUserSection={1}>
            <div>
              { this.showMostActiveUsers(mostActiveUsers, 'Most Active Users')}
            </div>
            <NewBoxShadow>
              {this.newShowTopFitnessDevice(topActivities ,"Top 15 Fitness Activities")}
            </NewBoxShadow>
          </SplitFitnessSection>
          <SplitFitnessSection gridWidth="35%" ShowActiveUserSection={1}>
            <div className='boxShadow'>
              { this.showTopFitnessDevice(topDevices, "Top 5 Fitness Devices")}
            </div>
            <div className='boxShadow'>
              { this.showWeeklyExercise(weekExercise, 'Weekly Exercise') }
            </div>
            <div className="boxShadow">
              {this.showDailyActiveMinutes(activeMinutes, 'Minute Active')}
            </div>
          </SplitFitnessSection>
        </FitnessContainer>
      </FitnessTabContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  summaryData: state.companyDashboard.groupActivitySummary,
  employeeCount: state.companyDashboard.employeeCount
});
const mapDispatchToProps = (dispatch) => ({
  
  fetchGroupActivitySummary: ( companyId, startDate, endDate) => dispatch(FetchGroupActivitySummary( companyId, startDate, endDate)),
});

FitnessTab.propTypes = {
  mostActiveUsers: PropTypes.array,
  leastActiveUsers: PropTypes.array,
  topDevices: PropTypes.array,
  fitnessGoals: PropTypes.array,
  topActivities: PropTypes.array,
  weekExercise: PropTypes.object,
  fetchGroupActivitySummary: PropTypes.func,
  companyId: PropTypes.number,
  summaryData: PropTypes.object,
  activeMinutes : PropTypes.object,
  selectedYearInfo: PropTypes.number,
  fitnessTabLoading: PropTypes.bool,
  yearList: PropTypes.array,
  employeeCount: PropTypes.number 
};

export default connect(mapStateToProps, mapDispatchToProps)(FitnessTab);
