import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { ImageUrlNew } from '../../utils/constants';
import { CircleContainer, Circle, Tick } from '../Journeys/styles';
import { withTranslation } from 'react-i18next';
import { CardNew, EducateContent, ChampoinsCardTest} from './styles';
import Waiting from '../Waiting';
const Thumbnail = React.lazy(() => import('../Thumbnail'));

class ArticleCard extends Component {
  constructor() {
    super();
    this.state = {
      onHover: -1,
      save: 0,
    }
  }

  onEnterMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  saveImage = () => {
    this.setState({
      save: !this.state.save,
    })
  }

  getQuizForArticle = (data) => {
    const { history } = this.props;
    history.push(`/education/adventure/${data.category_name.toLowerCase()}/article/${data.id}/quiz`);
  };

  render() {
    const { data, history, margin, width, bottom, onClickFavoriteRibbon, newIndex, journeyPage, showArticle, key, t} = this.props;
    const { onHover } = this.state;
    return (
      <ChampoinsCardTest margin={margin} width={width} bottom={bottom} key={key}>
        {data?.favorite_status ?
          <img src="/public/images/NewDashboardV2/ribbonColor.png" onClick={() => {onClickFavoriteRibbon("article", data.id, 0, data?.favorite_status ? 0 : 1,0);this.saveImage()}} /> :
          <img src="/public/images/NewDashboardV2/ribbon.png" onClick={() => {onClickFavoriteRibbon("article", data.id, 0, data?.favorite_status ? 0 : 1,0);this.saveImage()}} />
        }
        <CardNew recommended={1} >
          <div onMouseEnter={() => this.onEnterMouse(data.id)} onMouseLeave={() => this.onLeaveMouse()}>
            <Suspense fallback={<Waiting/>}>
              <Thumbnail
                src={`${ImageUrlNew}/${data.image}`}
                alt={data.category_name}
              />
            </Suspense>
            {onHover === data.id &&
              <div className='educateImageBackground'>
                <div className="getStarted" onClick={() => journeyPage ? showArticle(data, (key === newIndex)) : history.push(`/education/adventure/${data?.['category_name']?.toLowerCase()}/article/${data['id']}`)} >{t("Start Learning")}</div>
              </div>
            }
          </div>
          <div className='content'>
            <EducateContent>
              <div className="workEducation">
                <div className='workName'>
                  <span >{data.title}</span>
                </div>
              </div>
              <div className="workEducationCheck">
                <CircleContainer marginRight={1}>
                  <div className="flex">
                    <Circle checked={data.read_status}>
                      <Tick checked={data.read_status} />
                    </Circle>
                  </div>
                  <div className="quiz">
                    {t("Article")}
                  </div>
                </CircleContainer>
                {data.quiz_available &&
                  <CircleContainer style={{"marginLeft":"15px"}}>
                    <div className="flex">
                      <Circle  checked={data.quiz_status} >
                        <Tick checked={data.quiz_status} />
                      </Circle>
                    </div>
                    <div className="quiz">
                      {t("Quiz")}
                    </div>
                  </CircleContainer>
                }
              </div>
            </EducateContent>

          </div>
        </CardNew>
      </ChampoinsCardTest>
    );
  }
}

ArticleCard.propTypes = {
  data: PropTypes.object,
  history: PropTypes.object.isRequired,
  margin: PropTypes.string,
  width: PropTypes.string,
  bottom: PropTypes.string,
  onClickFavoriteRibbon: PropTypes.func,
  showArticle: PropTypes.func,
  journeyPage: PropTypes.number,
  newIndex: PropTypes.number,
  key: PropTypes.number,
  t: PropTypes.func
};

export default (withTranslation()(ArticleCard));