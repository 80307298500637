import React, { lazy, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  MainContainer,
  RecogntionTopCard
} from "../Recognition/CompanyAnalytics/styles";
import { ImageUrl } from "../../utils/constants";
import { commaSeperator } from "../../utils/methods";
import {
  TopChallengesWrapper,
} from "../CompanyEvents/styles";
const TopDataGraph = lazy(() => import('../common/Analytics/TopThreeDataGraph'));
import { Table } from '../common/commonStyles';
import {Heading, ScrollableContainer} from './styles'
import BarChart from '../common/Manager/BarChart';
import MonthlyBarChart from "../common/Manager/MonthlyChart/MonthlyBarChart";

const BarGraphData = [
  {
    "full_name": "Ajay Sharma",
    "total_recognitions": 19,
    image:"profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Arpit Malviya",
    "total_recognitions": 14,
    image:"profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Eshwar Deshmukh",
    "total_recognitions": 9,
    image:"profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Dhairya Vora",
    "total_recognitions": 1,
    image:"profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Dhairya Vora",
    "total_recognitions": 1,
    image:"profile-Image/Avatars/Avatar-5.png"
  }
]

const CompanyAnalytics = () => {
  const value=70;
  const [showEliteRecogntion, setShowEliteRecogntion] = useState(false);
  const [showTopRecogntion, setShowTopRecogntion] = useState(false);
  const [showEliteCelebration, setShowEliteCelebration] = useState(false);
  const [showTopCelebration, setShowTopCelebration] = useState(false);

  const topDepartment = () => {
    const dataArray = [
      { name: 'Element 1', color: '#FFF', value: 70, width: 204, background: '#92A4DA' },
      { name: 'Element 2', color: '#FFF', value: 40, width: 160, background: '#B1AA56' },
      { name: 'Element 3', color: '#FFF', value: 20, width: 96, background: '#DA9B6C' },
    ];
    return (
      <TopDataGraph data={dataArray} heading={'Top Performing Departments'} total={807} totalSubText='Total Recognitions' />
    );
  };

  const topLocation = () => {
    const dataArray = [
      { name: 'Element 1', color: '#FFF', value: 70, width: 204, background: '#C96BAE' },
      { name: 'Element 2', color: '#FFF', value: 40, width: 160, background: '#B1AA56' },
      { name: 'Element 3', color: '#FFF', value: 20, width: 96, background: '#75B084' },
    ];
    return (
      <TopDataGraph data={dataArray} heading={'Top Performing Location'} total={607} totalSubText='Total Recognitions' />
    );
  };

  const showRecognitionDetails = () => {
    const EngagementEmployeeData = [
      {
        title: "Total Social Post",
        value: "1",
        img: "/admin-recognition/icons/SocialBox.svg",
        text: "posted",
        color: "rgba(133, 192, 234, 1)",
        total: 100,
        imageBackground: "#DA92C8",
        subText: "Posts",
        info: "",
        average:
          50 +
          " % User" +
          (value > 1
            ? "s posted"
            : "posted"),
      },
      {
        title: "Recognition Received",
        titleNew: "",
        value: "2",
        img: "/admin-recognition/icons/TotalRecognitionimg.svg",
        text: "received recognition",
        color: "rgba(220, 148, 181, 1)",
        total: 70,
        average:
        value +
          " % User" +
          (value > 1
            ? "s received recognition"
            : "received recognition"),
        imageBackground: "#91C49D",
        subText: "People Received",
        info: "",
      },
      {
        title: "Recognition Given",
        value: "3",
        img: "/admin-recognition/icons/PeerRecognitionimg.svg",
        text: "gave recognition",
        color: "rgba(118, 171, 120, 1)",
        total: 80,
        average:
          10 +
          " % User" +
          (value > 1
            ? "s gave recognition"
            : "gave recognition"),
        imageBackground: "#BE7D9C",
        subText: "People Gave",
        info: "",
      },
      {
        title: "Exemplifies Company Values",
        titleNew: "Value",
        value: "4",
        img: "/admin-recognition/icons/Exempifiesimg.svg",
        text: "received value",
        color: "rgba(175, 135, 193, 1)",
        total: 707,
        average:
          25 +
          " % User" +
          (value > 1
            ? "s received value"
            : "received value"),
        imageBackground: "#B1AA56",
        subText: "People Exemplified",
        info: "",
      },
    ];
    return EngagementEmployeeData.map((details, index) => (
      <RecogntionTopCard key={index} background={details.imageBackground}>
        <div className="image">
          <img src={ImageUrl + details.img} />
        </div>
        <div className="title">{details.title}</div>
        <div className="value">{commaSeperator(details?.total)}</div>
        <div className="percent-user">{details.average}</div>
      </RecogntionTopCard>
    ));
  };

  const topdepartment = [
    { name: 'Element 1', value: 70, image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#FFF3ED" },
    { name: 'Element 2', value: 60 , image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#FFF3ED"},
    { name: 'Element 3', value: 50 , image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#F0F7FF"},
    { name: 'Element 4', value: 40 , image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#FEF2F3"},
    { name: 'Element 5', value: 30 , image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#F0F7FF"},
    { name: 'Element 6', value: 20 , image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#FEF2F3"},
    { name: 'Element 7', value: 70, image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png", color:"#FFF3ED" },
  ];

  const toggleShowEliteRecognition = (value) => {
    if(value=="celebration"){
      setShowEliteCelebration(!showEliteCelebration)
    }
    else{
      setShowEliteRecogntion(!showEliteRecogntion);
    }
  };

  const toggleShowTopRecognition = (value) => {
    if(value=="celebration"){
      setShowTopCelebration(!showTopCelebration)
    }
    else{
      setShowTopRecogntion(!showTopRecogntion)
    }
  };

  const bestPerformer = (value)=>{
    const displayedTopDepartments = showTopRecogntion ? topdepartment : topdepartment.slice(0, 5);

    const displayedEliteDepartments = showEliteRecogntion ? topdepartment : topdepartment.slice(0, 5);
    return(
      <>
        <div className='top-performing' style={{display:"block"}} >
          <Table padding="8px 0" insight>
            <div className="heading">October | Monthly</div>
            {value !== "celebration" && (
              <div className="type">
                <div className="name">Employee</div>
                <div className="value">Total</div>
              </div>
            )}
            <ScrollableContainer
              height={showTopRecogntion ? "380px" : "330px"}
              showAllRows={showTopRecogntion}
            >
              {displayedTopDepartments.map((data, index) => (
                <div className="item" key={index}>
                  <div className="profile">
                    <img src={data.image} alt={data.name} />
                  </div>
                  <div className="name">{data.name}</div>
                  <div className="value">{data.value}</div>
                </div>
              ))}
            </ScrollableContainer>
            {topdepartment.length > 5 && !showTopRecogntion && (
              <div className="showmore">
                <div className="button" onClick={toggleShowTopRecognition}>
                  Show More
                </div>
              </div>
            )}
          </Table>

        </div>
        <div className='low-performing' style={{display:"block"}}>
          <Table padding="8px 0" insight>
            <div className="heading">October-December | Quarterly</div>
            {value !== "celebration" && (
              <div className="type">
                <div className="name">Employee</div>
                <div className="value">Total</div>
              </div>
            )}
            <ScrollableContainer
              height={showEliteRecogntion ? "380px" : "330px"}
              showAllRows={showEliteRecogntion}
            >
              {displayedEliteDepartments.map((data, index) => (
                <div className="item" key={index}>
                  <div className="profile">
                    <img src={data.image} alt={data.name} />
                  </div>
                  <div className="name">{data.name}</div>
                  <div className="value">{data.value}</div>
                </div>
              ))}
            </ScrollableContainer>
            {topdepartment.length > 5 && !showEliteRecogntion && (
              <div className="showmore">
                <div className="button" onClick={toggleShowEliteRecognition}>
                  Show More
                </div>
              </div>
            )}
           
          </Table>

        </div>
      </>
    )
  }

  const celebrations = (value)=>{
    const displayedTopDepartments = showTopCelebration ? topdepartment : topdepartment.slice(0, 5);

    const displayedEliteDepartments = showEliteCelebration ? topdepartment : topdepartment.slice(0, 5);
    return(
      <>
        <div className='top-performing' style={{display:"block"}} >
          <Table padding="8px 0" insight>
            <div className="heading">Work Anniversaries</div>
            {value !== "celebration" && (
              <div className="type">
                <div className="name">Employee</div>
                <div className="value">Total</div>
              </div>
            )}
            <ScrollableContainer
              height={showTopCelebration ? "380px" : "330px"}
              showAllRows={showTopCelebration}
            >
              {displayedTopDepartments.map((data, index) => (
                <div className="item" key={index}>
                  <div className="profile">
                    <img src={data.image} alt={data.name} />
                  </div>
                  <div className="name">{data.name}</div>
                  <div className="value">{data.value}</div>
                </div>
              ))}
            </ScrollableContainer>
            {topdepartment.length > 5 && !showTopCelebration && (
              <div className="showmore">
                <div className="button"  onClick={()=>toggleShowTopRecognition("celebration")}>
                  Show More
                </div>
              </div>
            )}
           
          </Table>
          
        </div>
        <div className='low-performing' style={{display:"block"}}>
          <Table padding="8px 0" insight>
            <div className="heading">Birthdays</div>
            {value !== "celebration" && (
              <div className="type">
                <div className="name">Employee</div>
                <div className="value">Total</div>
              </div>
            )}
            <ScrollableContainer
              height={showEliteCelebration ? "380px" : "330px"}
              showAllRows={showEliteCelebration}
            >
              {displayedEliteDepartments.map((data, index) => (
                <div className="item" key={index}>
                  <div className="profile">
                    <img src={data.image} alt={data.name} />
                  </div>
                  <div className="name">{data.name}</div>
                  <div className="value">{data.value}</div>
                </div>
              ))}
            </ScrollableContainer>
            {topdepartment.length > 5 && !showEliteCelebration && (
              <div className="showmore">
                <div className="button" onClick={()=>toggleShowEliteRecognition("celebration")}>
                  Show More
                </div>
              </div>
            )}
           
          </Table>

        </div>
      </>
    )
  }

  return (
    <>
      <MainContainer margin>
        <TopChallengesWrapper margin="0 0 0px 0">
          {showRecognitionDetails()}
        </TopChallengesWrapper>
        <div className="main-top-low-card" style={{margin:"20px 0px"}}>
          <div className='top-performing' style={{display:"block"}} >
            <BarChart heading="Top Performing Managers" data={BarGraphData} id={1}/>
          </div>
          <div className='low-performing' style={{display:"block"}} >
            <BarChart total="260" heading="Top Performing Employees" data={BarGraphData} id={2}/>
          </div>
        </div>
        <div className="main-top-low-card">
          <div className='top-performing'>{topDepartment()}</div>
          <div className='top-performing'>{topLocation()}</div>
        </div>
        <Heading>
          <div className='icon'>
            <img src={ImageUrl + "/admin-recognition/top-performer.svg"} />
          </div>
          Best Performers
        </Heading>
        <div className="main-top-low-card" style={{marginTop:"20px"}}>
          {bestPerformer()}
        </div>
        <Heading>
          <div className='icon'>
            <img src={ImageUrl + "/admin-recognition/celebration.svg"} />
          </div>
          Quarterly Celebrations
        </Heading>
        <div className="main-top-low-card" style={{marginTop:"20px"}}>
          {celebrations("celebration")}
        </div>
   
        
      </MainContainer>
      <div style={{marginTop:"20px"}}><MonthlyBarChart total="12" heading="Monthly Breakdown"/></div>
    </>
  );
};

CompanyAnalytics.propTypes = {
  selectedYearInfo: PropTypes.number
};

const mapStateToProps = (state) => ({
  selectedYearInfo: state.companyDashboard.selectedYearInfo
});

export default connect(mapStateToProps, null)(CompanyAnalytics);