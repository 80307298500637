import styled from "styled-components";

export const BarChartContainer = styled.div`
  width: 100%;
  height: 100%;
  background:rgba(254, 254, 254, 0.60);
  border: none;
  box-sizing: border-box;
  border-radius: 12px;
  padding:16px;
  ${'' /* width:50%; */}

  .heading{
    font-family: 'Lato';
    font-size: 18px;
    font-style: normal;
    color: #184A61;
    font-weight: 700;
    line-height: 24px;
  }

  .total-row{

  }

  .total{
    color:  #184A61;
font-family: 'Lato';
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 68px; 
  }

  .total-text{
    color:  #DCDCDC;
font-family: 'Lato';
font-size: 40px;
font-style: normal;
${'' /* font-weight: 700; */}
line-height: 68px;
margin-left: 10px;
  }

  .compare-row{
    display:flex;
    ${'' /* justify-content:start; */}
    align-items:center;
  }

  .stat{
    display:flex;
    justify-content:center;
    align-items:center;
padding: 4px;
${'' /* gap: 4px; */}
border-radius: 200px;
border: 1px solid  #75B084;
background:  #418856;
color:  #FEFEFE;
font-family: 'Lato';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
${'' /* margin-left: 8px; */}

.icon{
  padding:2px;
  display:flex;
}
  }

  .text{
    color:  #092F41;
text-align: center;
font-family: 'Lato';
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px; 
margin-left: 8px;


  }
`;

export const Border = styled.div`
 width: 100%;
 margin: 20px 0px;
 border-bottom: 1px solid #EFEFEF;
`;