import styled from 'styled-components';
// import {StyledTabs} from '../CompanyDashBoardV2/styles';
const BoldFont = 'Rubik-Medium';
const MediumFont = 'Rubik-Regular'; 

import { Modal } from 'react-bootstrap';
const StyledModal = styled(Modal)`
  
  .modal-dialog {
    width: auto;
    right: 0px;
    margin: 0px;
    position: absolute;
  }
  .modal-open {
    overflow: hidden;
}
`

const NotificationWrapper =  styled.div`
overflow: hidden;
position: absolute;
top: 0;
border-radius: 6px;
z-index: 1999;
box-shadow: 1px 1.732px 12px rgb(0 0 0 / 18%);
background-color: #fff;
width: 350px;
width: 375px;
min-height: 100%;
right:0px;
.wrapper-class{
  position: relative;
  overflow: hide;
  height: 100vh;
  overflow: hidden;
    top: 65px;
}
.scroll-hide {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -17px; /* Increase/Decrease this value for cross-browser compatibility */
  // overflow-y: scroll;

}
  .read{
    background-color: #fff; 
  }

  @media (max-width: 350px) {
    width: 100%;
  }
`;
const TitleContainer = styled.div`
width: 100%;
min-height: 70px;
height: 15%;
background: linear-gradient(180deg, #52A8ED 0%, #8AD3F7 100%);
padding: 35px 15px 0px 15px;
// text-transform: uppercase;
color: #FFFFFF;
font-family: 'Rubik-Medium';
font-size: 16px;
line-height: 24px;
overflow: hidden;
    position: absolute;
    height: 35px;
`;
const UserImage = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
padding: 15px;
border-bottom: 1px solid rgba(156, 156, 156, 30%);
> div:first-child {
  width: auto;
  > img {
    width: 112px;
    height: 112px;
    border-radius: 6px;
  }
}
> div: last-child {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 5px 15px;
  gap: 10px;
  > div: first-child {
    color: #0D4270;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
  }
  > div: nth-child(2){
    color: #C6C6C6;
    color: #9c9c9c;
    font-family: Rubik-Regular;
    font-size: 16px;
    line-height: 24px;
  }
  > button {
    background: #EAF6FF;
    border-radius: 3px;
    outline: none;
    border: none;
    height: 50px;
    width: 80%;
    color: rgba(105, 194, 255, 1);
    font-family:Rubik-Medium;
    // align-self: flex-start;
  }
}
`;
const WellnessIntereset = styled.div`
padding: 15px;
border-bottom: 1px solid rgba(156, 156, 156, 30%);
> div: first-child {
  display: flex;
  justify-content: space-between;
  color: #0D4270;
  font-family: Rubik;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 15px;
  >p{
    color: #0D4270;
    font-family: Rubik;
    font-size: 18px;
    line-height: 24px;
    display: inline;
    margin: 0px;
  }
  >span{
    margin-right: 15px;
    >a{
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #7DC5FF;
      padding-left: 120px;
      cursor: pointer;
      text-decoration: none;
    }
  }
}
> div: last-child {
  display: flex;
  justify-content: flex-start;
  > div{
    width: 40px;
    height: 40px;
    background: linear-gradient(360deg, #1E6481 0%, #0D425C 60.94%, #022C43 100%);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    > img {
      width: 70%;
      height: 70%;
    }
  }
}
`;
const Content = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
min-height: 50px;
margin-bottom: 10px;
cursor: pointer;
> div: first-child {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  // background-color: #F9F9F9;
  > img {
    width: 45%;
    width: 40px;
    height: 40px;
  }
}
>div: last-child {
  width: 100%;
  margin: auto;
  > div: first-child {
    font-size: 16px;
    line-height: 16px;
    font-family: 'Rubik';
    color: #0D4270;
    &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }
  > div: last-child {
    font-size: 14px;
    line-height: 14px;
    color: #CCCCCC;
    font-family: 'Rubik-Regular';
    color: #9c9c9c;
  }
}
&:last-child{
  margin-bottom: 0px;
}

.add-slack{
  font-size: 16px;
    line-height: 16px;
    font-family: 'Rubik';
    color: #0D4270;
    cursor:pointer;
    text-decoration: none;
    a{
      font-size: 16px;
    line-height: 16px;
    font-family: 'Rubik';
    color: #0D4270;
    cursor:pointer;
    text-decoration: none;

    }
}
`;
const WellnessInitiative = styled.div`
width: 100%;
padding: 15px;
> div: first-child {
  color: #0D4270;
  font-family: Rubik;
  font-size: 18px;
  line-height: 24px;
}
`;

const WellnessInitiativeRow = styled.div`
  display: flex;
  padding: 15px 0;
  border-bottom: 2px solid #F6F6F8;
  > div {
    width: 100%;
    float: left
    .fixedIcon {
      >svg {
        max-width: 23px;
        width: 100%;
        overflow: hidden;
        height: 100%;
        float: left;
      }
      >img {
        max-width: 50px;
        width: 100%;
        overflow: hidden;
        height: 50px;
        float: left
        margin-right: 5px;
      }
    }
    .secondGrid {
      padding-right: 5px;
      padding-left: 5px;
      word-break: break-all;
      float: left;
      width: calc(100% - 80px);
    }
    .thirdGrid {
      font-family: ${BoldFont};
      font-size: 15px;
      float: right;
    }
  }
    
    > div {
      > div {
        font-size: 12px;
        color: rgb(153,153,153);
      }
    }
  }

`;
const InitiativeTitle = styled.span`
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0px;
  color: ${({ color }) => color ? color : 'rgb(51,51,51)'};
  font-family: ${BoldFont};
	color: #282C37;
`;

const WellnessPoint = styled.div`
  height: 20px;
  border-radius: 3.072px;
  display: flex;
  justify-content: flex-start;
  color:${({color}) => color};
  font-family: ${MediumFont};
  font-size: 13px;
  line-height: 17px;
`;
const TickWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 100%;
  }
`;
const Container = styled.div`
width: 100%;
height: 100%;
padding: 15px;
border-bottom: 1px solid #F3F3F3;
`;
const LocationContainer = styled.div`
  color: #C6C6C6;
  color: #9c9c9c;
  font-family: Rubik-Regular;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  > div: first-child {
    > img {
      width: 14px;
      margin-right: 7px;
    }
  }
`;

const AboutCard = styled.div`
  .about-user-section{
    width: 100%;
    background: #FFFFFF;
    border-radius: 6px;
    margin: 0px 0px 25px;
  }
  .title-text{
    padding: 17px 25px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .user-edit-btn{
      background: #FD7175;  
      width: 150px;
      height: 40px;
      border-radius: 3px;
      color: white;
      border:none;
      font-size:18px;
      font-family: "Rubik-Medium";
      align-items: center;
      display: flex;
      margin: auto;
      margin-right: 0px;
      text-align: center;
      justify-content: center;
   }
  }
  .about-user-details{
    padding: 25px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #0D4270;
  }
  .horizontal-line{
    height: 1px;
    background: rgba(156, 156, 156, 0.4);
    color: #9C9C9C;
    margin-top: unset;
    margin-bottom: unset;
    border: 0px;
  }  
`;

const WellnessCard = styled.div`
  .wellness-interest{
    margin: 0px 0px 25px;
    max-width: 1246px;
    background-color:#FFFFFF;
    border-radius: 6px;
  }
  .wellness-title{
    padding: 17px 25px;
    display: flex;
    .user-edit-btn{
      background: #FD7175;  
      min-width: 150px;
      height: 40px;
      border-radius: 3px;
      color: white;
      border:none;
      font-size:18px;
      font-family: "Rubik-Medium";
      align-items: center;
      display: flex;
      margin: auto;
      margin-right: 0px;
      text-align: center;
      justify-content: center;
   }
  }
  .title-text{
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #0D4270;    
    margin-right: 25px;
    display: flex;
    align-items: center;
  }
  .wellness-count{
    font-family: 'Rubik-Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;    
    color: #FD7175;
    display: flex;
    align-items: center;
  }
  .interest-card-wrapper{
    padding:25px;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  .horizontal-line{
    background: rgba(156,156,156,0.4);
    height: 1px;
    margin-top: unset;
    margin-bottom: unset;
    border: 0px;
  } 
  .interest-card{
    width: calc((100% - 75px) / 4);
    height: 60px;
    border: 1px solid #9C9C9C;
    border-radius: 7px;
    margin-bottom: 20px;
  }

  .icon-wrapper{
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .interest-card{
  display:flex;
  }
  .interest-icon{
    height: 40px;
    width: 40px;
    display: flex;
    background: #0D4270;
    align-items: center;
    border-radius: 6px;
    >img{
      width: 25px;
      height: 25px;
      margin: auto;
    }
  }
  .interest-name{
    display: flex;
    align-items: center;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0D4270;
  }
`;

const StyledModalV2 = styled(Modal)`
	.modal-dialog{
		display: flex;
    justify-content: center;
    transform: ${({transform}) => transform && 'translate(0, 50vh) translate(0, -50%) !important'};
		.modal-content{
			width: 100%;
      border-radius: 10px;
      border: none;
			.modal-header {
				border-bottom: ${({noBorder}) => noBorder ? noBorder : '1px solid #e5e5e5'};
        background: linear-gradient( 
          180deg,#52A8ED 0%,#8AD3F7 100%);

				> div {
					display: flex;
					justify-content: center;

					> p{
						font-size: 20px;
						font-family: ${BoldFont};
						margin-top: 10px 0 0 0;
					}
				}
			}
			.modal-header .close {
				//margin-top: -2px;
				outline: none;
        float: right;
        margin-top: -21px;
        margin-right: 2px;
       color: white;
       font-size: 44px;
        opacity: 0.9;
			}
		}
	}
  @media (max-width: 500px) and (min-width: 320px) {
    .modal-dialog {
			width: 100%;

			.modal-content{
				width: 90%;
				margin-left: -6%;
			}
    }
  }
`;

const StyledModalV3 = styled(Modal)`
	.modal-dialog{
		display: flex;
    justify-content: center;
    transform: ${({transform}) => transform && 'translate(0, 50vh) translate(0, -50%) !important'};
		width: 1026px;
    .modal-content{
			width: 100%;
      border-radius: 10px;
      border: none;
      width: 1026px;
			.modal-header {
				border-bottom: ${({noBorder}) => noBorder ? noBorder : '1px solid #e5e5e5'};
        background: linear-gradient( 
          180deg,#52A8ED 0%,#8AD3F7 100%);

				> div {
					display: flex;
					justify-content: center;

					> p{
						font-size: 20px;
						font-family: ${BoldFont};
						margin-top: 10px 0 0 0;
					}
				}
			}
			.modal-header .close {
				//margin-top: -2px;
				outline: none;
        float: right;
        margin-top: -21px;
        margin-right: 2px;
       color: white;
       font-size: 44px;
        opacity: 0.9;
			}
		}
	}
  @media (max-width: 500px) and (min-width: 320px) {
    .modal-dialog {
			width: 100%;

			.modal-content{
				width: 90%;
				margin-left: -6%;
			}
    }
  }
`;

const StyledBody = styled(Modal.Body)`
  padding: 20px 25px 0px 25px;
`;

const WbaCardContainer = styled.div`
  width: 100%
  display: flex;
  justify-content: space-between,
`;

const FirstRowCard = styled.div`
  width: 40%
  text-align: center;
`;

const FirstRowCardV2 = styled.div`
  // width: 40%
  text-align: center;
`;

const LastImprovementRow = styled.div`
  width: 20%
  text-align: center;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  .first{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #0D4270;
    text-align: center;
    margin-bottom: 47px !important;
  }
  .second{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #9D87A9;
    margin-bottom: 60px;
  }
  .third{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #69C2FF;
    margin-bottom: 60px;
    // margin-top: 20px !important;
  }
  .fourth{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #9FC989;
    margin-bottom: 60px;
    // margin-top: 20px !important;
  }
  .fifth{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #F8A887;
    margin-bottom: 60px;
    // margin-top: 10px !important;
  }
  .sixth{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #F6B479;
    margin-bottom: 45px;
    // margin-top: 20px;
  }
  .seventh{
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: white;
    // margin-bottom: 60px;
    // margin-top: 20px;
  }

`;

const TopInfo = styled.div`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #9C9C9C;
`;

const AboutTextBox = styled.textarea`
  width: 100%;
  height: 219px;
  background: #FFFFFF;
  border: 1px solid rgba(156, 156, 156, 0.4);
  border-radius: 6px;
  margin-top: 25px;
  margin-bottom: 25px; 
  background: rgba(156, 156, 156, 0.1);
  border: 1px solid rgba(156, 156, 156, 0.4);
  border-radius: 3px;
  font-family: Rubik-medium;
  padding: 13px 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0D4270;
  width: 100%;
  outline: none;           
`;

const SaveAboutButton = styled.div`
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-top: 1px solid #e5e5e5;
    .savebutton{
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: #FD7175;
      border-radius: 3px;
      width: 200px;
      height: 50px;
      font-family: Rubik-medium;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: rgb(255, 255, 255);
    }
`;

const OverviewCard = styled.div`
    max-width: 1246px;
    height: 325px;
    background-color:#FFFFFF;
    border-radius: 6px;
    margin-bottom: 25px;
    .detail-button{
    min-width: 150px;
    height: 40px;
    background: #FD7175;
    border-radius: 3px;
    margin-right: 25px;
    border: none;
    color: #ffffff;
    font-family:Rubik-Medium;
    font-size: 18px;
    line-height: 24px;
   }
.viewcard{
    display:flex;  
    justify-content:space-between;
    
  }
   .prof-details{
      width:100%;
      display:flex;
      justify-content:space-between;
      padding: 0px 25px 0px 25px;
      height:74px;
      align-items:center;
   }
   .name-in{
    display: flex;
    font-family:"Rubik-Medium";
      font-style: normal;
      align-items: center;
      color: #0D4270;
      font-size: 18px;
      line-height: 24px;
   }
    .horizontal-line{
        background: rgba(156, 156, 156, 0.4);
        margin-bottom: 25px;
        margin-top: 0px;
        height: 1px
    }
    .user-edit-btn{
      background: #FD7175;  
      width: 150px;
      height: 40px;
      border-radius: 3px;
      color: white;
      border:none;
      font-size:18px;
      font-family: "Rubik-Medium";
      align-items: center;
      display: flex;
      margin: auto;
      margin-right: 0px;
      text-align: center;
      justify-content: center;
   }
   .emp-detail{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display:flex;
    flex-wrap:wrap;
    display: flex;
    flex-direction: row;
    padding: 0px 25px;
    
    .fieldNames{
        width:50%;
        display: flex;
        height: 40px;
        .color{
          color:#FD7175;
          font-family: 'Rubik-Medium';
          font-size: 16px;
          line-height: 20px;
        }
        .green{
          color: #9FC989;
          font-family: 'Rubik-Medium';
          font-size: 16px;
          line-height: 20px;
        }
        .prof-data{
          color:#0D4270;
          font-family: 'Rubik-Medium';
          font-size: 16px;
          line-height: 20px;
        }  
    }
   }
   .label-name-emp{
    width:41%;
    color: #9C9C9C;
   } 
`;

const NewProgressPointsV2 = styled.div`
width: 70%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
// flex-wrap: wrap;
// margin: auto;
margin: auto 39px;
border-radius: 20px;
`;

const InitativeCard = styled.div`
.company-initative{
   width:100%;
   display: flex;
   background: #FFFFFF;
   flex-direction: column;
   margin-bottom:25px;
   border-radius: 6px;
}
.heading{
   color:#0D4270;
   font-family: 'Rubik-Medium';
   font-size: 18px;
   line-height:24px;
   margin-top: 25px;
   margin-left: 25px;
   display: flex;
   .total{
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;
    color: #FD7175
   }
}
.horizontal-line2{
  margin-top: 25px;
  margin-bottom: 25px;
  background: rgba(156, 156, 156, 0.4);
  width: 100%;
  height:1px;
  border: 0px;
}
.fitness-wrapper{
   display:flex;
   padding: 0px 0px 0px 25px;
   width:100%;
   flex-wrap:wrap;
   >div{
    .syncing-title{
      width: auto;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display:flex;
      // margin: auto;
      margin-left: 15px;
      // margin-bottom: 45px;
      // margin-top: 15px;
      flex-direction:column;
      color: #0D4270;
      // margin: auto;
      margin-left: 15px;
    }
    .points{
      // height: 20px;
      // left: 547px;
      // top: 1337px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      // margin-right: -73px;
      // margin-top: 11px;
    }
   }
}
.syncing-fitness{
  width: calc((100% - 50px) / 3);
  height: 80px;
  border: 1px solid #9C9C9C;
  border-radius: 7px;
  display:flex;
  cursor: pointer;
  margin-bottom: 25px;
  .syncing-title{
    width: auto;
    height: 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display:flex;
    margin: auto;
    margin-left: 15px;
    margin-bottom: 45px;
    margin-top: 15px;
    flex-direction:column;
   
  }
  .syncing-title2{
    width: 187px;
    height: 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display:flex;
    margin: auto;
    margin-left: 15px;
    margin-bottom: 45px;
    margin-top: 15px;
    flex-direction:column;
  }
  .points{
    // height: 20px;
    // left: 547px;
    // top: 1337px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #F8A887;
    // margin-right: -73px;
    // margin-top: 11px;
  }
  .right-mark{
    width: 24px;
    height: 24px;
    margin-top: 28px;
    margin-bottom: 28px;
    margin-right: 10px;
  }
  .points2{
    width: 205px;
    height: 20px;
    left: 547px;
    top: 1337px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #F8A887;
    margin-right: -73px;
    margin-top: 11px;
  }
  .points3{
    width: 68px;
    height: 20px;
    left: 547px;
    top: 1337px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #F8A887;
    margin-right: -73px;
    margin-top: 11px;
  }
}
.rectangle-org{
  width: 60px
  height:60px
  border-radius: 4.2px;
  display:flex;
  margin: auto;
  margin-left: 10px;
  margin-right: 0px;
  >img{
    width:100%;
    height:100%
  }

}
.square-org{
  width: 60px
  height:60px
  background: linear-gradient(180deg, #FD7175 0%, #FF4C51 100%);
  border-radius: 4.2px;
  display:flex;
  margin: auto;
  margin-left: 10px;
  margin-right: 0px;
}
.cylinder-org{
  width: 60px
  height:60px
  background: linear-gradient(180deg, #C0ED98 0%, #96C26E 100%);
  border-radius: 4.2px;
  display:flex;
  margin: auto;
  margin-left: 10px;
  margin-right: 0px;
}
`;

const NewTotalPoints = styled.div`
width: calc((100% - 75px)/3);
display: flex;
background: #FFFFFF;
border: 1px solid rgba(156, 156, 156, 0.4);
border-radius: 6px;
margin-bottom: 20px;
margin-right: 25px;
height: 80px;
>div:first-child {
  width: 40%;
  padding: 10px;
  >img{
    width: 60px;
    height: 60px;
  }
}
>div:last-child {
    width: 200%;
    padding: 10px;
  >div:first-child{
    font-family: 'Rubik-Regular'
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0D4270;
    //padding: 15px;
    text-transform: none;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }
  >div:last-child{
    font-family: Rubik-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 28px;
    //color: #32DBC6; 
    //padding: 15px;
    text-align: left;
    padding-top: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
   >span {
    font-size: 14px;
    line-height: 28px;
    font-family: 'Rubik-Regular';
    color: #0D4270;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
   } 
  }
 .step_cls {
   color: red;
 }
}
`;

const NewTotalPointsV2 = styled.div`
width: calc((100% - 75px) / 4);
display: flex;
background: #FFFFFF;
border: 1px solid rgba(156, 156, 156, 0.4);
border-radius: 6px;
margin-bottom: 20px;
margin-right: ${({marginRight}) => marginRight ? marginRight : '0px'};
height: 80px;
>div:first-child {
  width: 40%;
  padding: 10px;
  >img{
    width: 60px;
    height: 60px;
    background: linear-gradient(180deg, #1E6481 0%, #0D425C 60.94%, #022C43 100%);
    border-radius: 6px;
  }
}
>div:last-child {
    width: 200%;
    padding: 10px;
  >div:first-child{
    font-family: 'Rubik-Regular'
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0D4270;
    //padding: 15px;
    text-transform: none;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }
  >div:last-child{
    font-family: Rubik-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 28px;
    //color: #32DBC6; 
    //padding: 15px;
    text-align: left;
    padding-top: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
   >span {
    font-size: 14px;
    line-height: 28px;
    font-family: 'Rubik-Regular';
    color: #0D4270;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
   } 
  }
 .step_cls {
   color: red;
 }
}
`;


const CountDiv = styled.div`

font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 28px;
//color: #32DBC6; 
color: ${({color}) => color ? 'color' : 'red'}; 
//padding: 15px;
text-align: left;
padding-top: 6px;
>span {
font-size: 14px;
line-height: 28px;
font-family: 'Rubik-Regular';
color: #0D4270;
}
`;

const WellnessCardV2 = styled.div`
width: calc((100% - 75px) / 4);
height: 60px;
border: 1px solid #9C9C9C;
border-radius: 7px;
margin-bottom: 15px;
display: flex;
margin-right: ${({marginRight}) => marginRight ? marginRight : '25px'};
  .wellness-interest{
    margin: 10px 0px;
    max-width: 1246px;
    background-color:#FFFFFF;
    border-radius: 6px;
  }
  .wellness-title{
    padding: 25px;
  }
  .title-text{
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #0D4270;    
    margin-right: 25px;
  }
  .wellness-count{
    font-family: 'Rubik-Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;    
    color: #FD7175
  }
  .interest-card-wrapper{
    padding:25px;
    padding-bottom: 7px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .horizontal-line{
    border: 1px solid rgba(156, 156, 156, 0.4);
    margin-top: unset;
    margin-bottom: unset;
  } 
  .interest-card{
    width: 23.5%;
    height: 60px;
    border: 1px solid #9C9C9C;
    border-radius: 7px;
    margin-bottom: 20px;
  }

  .icon-wrapper{
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .interest-card{
  display:flex;
  }
  .interest-icon{
    height: 40px;
    width: 40px;
    display: flex;
    background: #0D4270;
    align-items: center;
    border-radius: 6px;
    >img{
      width: 25px;
      height: 25px;
      margin: auto;
    }
  }
  .interest-name{
    display: flex;
    align-items: center;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0D4270;
  }
`;

const OverviewCardV2 = styled.div`
    max-width: 1246px;
    height:  ${({height}) => height ? '414px' : '189px'};
    background-color:#FFFFFF;
    border-radius: 6px;
    margin-bottom: 25px;
.viewcard{
    display:flex;  
    justify-content:space-between;
    
  }
   .prof-details{
      width:100%;
      display:flex;
      justify-content:space-between;
      padding: 0px 25px 0px 25px;
      height:74px;
   }
   .name-in{
    display: flex;
    font-family:"Rubik-Medium";
      font-style: normal;
      align-items: center;
      color: #0D4270;
      font-size: 18px;
      line-height: 24px;
   }
    .horizontal-line{
        background: rgba(156, 156, 156, 0.4);
        margin-bottom: 25px;
        margin-top: 0px;
        height: 1px
    }
    .user-edit-btn{
      background: #FD7175;  
      min-width: 150px;
      height: 40px;
      border-radius: 3px;
      color: white;
      border:none;
      font-size:18px;
      font-family: "Rubik-Medium";
      align-items: center;
      display: flex;
      margin: auto;
      margin-right: 0px;
      text-align: center;
      justify-content: center;
   }
   .emp-detail{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display:flex;
    flex-wrap:wrap;
    display: flex;
    flex-direction: ${({flex}) => flex ? 'column' : 'row'};
    padding: 0px 25px;
    
    .fieldNames{
        width: ${({fieldWidth}) => fieldWidth ? '75%' : '50%'};
        display: flex;
        height: 40px;
        .color{
          color:#FD7175;
          font-family: 'Rubik-Medium';
          font-size: 16px;
          line-height: 20px;
        }
        .green{
          color: #9FC989;
          font-family: 'Rubik-Medium';
          font-size: 16px;
          line-height: 20px;
        }
        .prof-data{
          color:#0D4270;
          font-family: 'Rubik-Medium';
          font-size: 16px;
          line-height: 20px;
        }  
    }
   }
   .label-name-emp{
    width: ${({labelWidth}) => labelWidth ? '65%' : '40%'};
    color: #9C9C9C;
   } 
`;

const WbaPoints = styled.div`
width: calc((100% - 55px)/3);
display: flex;
background: #FFFFFF;
border: 1px solid rgba(156, 156, 156, 0.4);
border-radius: 6px;
margin-bottom: 20px;
margin-right: ${({marginRight}) => marginRight ? marginRight : '0px'};
height: 80px;
>div:first-child {
  width: 40%;
  padding: 10px;
  >img{
    width: 60px;
    height: 60px;
  }
}
>div:last-child {
    width: 200%;
    padding: 10px;
  >div:first-child{
    font-family: 'Rubik-Regular'
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0D4270;
    //padding: 15px;
    text-transform: none;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }
  >div:last-child{
    font-family: Rubik-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 28px;
    //color: #32DBC6; 
    //padding: 15px;
    text-align: left;
    padding-top: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
   >span {
    font-size: 14px;
    line-height: 28px;
    font-family: 'Rubik-Regular';
    color: #0D4270;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
   } 
  }
 .step_cls {
   color: red;
 }
}
`;

const ActivityBar = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: 15px;
    border-radius: 20px;
    background-color: ${(props) => props.backgroundFill ? props.backgroundFill : 'rgba(86, 192, 36, 0.3)'};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: ${(props) => props.margin ? props.margin : '5px 0px'}
`;

const ActivityBarFill = styled.div`
    // width: ${(props)=> props.width ? props.width : '0%'};
    width: ${(props) => props.width ? (parseFloat(props.width) > 100 ? '100%' : props.width) : '0%'};
    height: 100%;
    border-radius: 20px;
    background-color: ${(props) => props.fillColor ? props.fillColor : '#000'};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 0px 0px;
    position: absolute;
    top: 0px;
    > span {
     cursor : pointer;
     color: #fff;
     font-size: 11px;
     font-family: ${BoldFont};
     display: inline-block;
   }
`;

const ActivityContainer = styled.div`
    width: ${({width}) => width ? width : '100%'};
    font-size: 13px;
    margin: ${({bloodPressure}) => bloodPressure ? bloodPressure : '15px 0px'};
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .chartTitle {
        color: #000;
        font-family: ${BoldFont}
        display: inline-block;
        margin-right: 5px;
    }
    .sliderTitle {
      font-family: ${BoldFont};
      font-size: 14px;
      color: #282C37;
    }
    .chartSubTitle {
        color: #C9CACC;
        font-family: ${MediumFont};
        display: inline-block;
    }
    
    @media (max-width: 1175px) and (min-width:1024px) {
      width: ${({width}) => width ? '85%'  : '100%'};
    }
    
    @media (max-width: 1023px) and (min-width:950px) {
      width: ${({width}) => width ? '80%'  : '100%'};
    }
    
    @media (max-width: 949px) and (min-width:850px) {
      width: ${({width}) => width ? '85%'  : '100%'};
    }
    @media (max-width: 500px) {
       width: ${({width}) => width ? '100%'  : '100%'};
    }
`;

const ShowPoints = styled.div`
  width: 43px;
  height: 20px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #0D4270;
  margin-left: 10px;
`;

const ActivityBarV2 = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: 15px;
    border-radius: 20px;
    background-color: ${(props) => props.backgroundFill ? props.backgroundFill : 'rgba(86, 192, 36, 0.3)'};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: ${(props) => props.margin ? props.margin : '5px 0px'}
`;

const ActivityBarFillV2 = styled.div`
    width: ${(props)=> props.width ? props.width : '0%'};
    height: 100%;
    border-radius: 20px;
    background-color: ${(props) => props.fillColor ? props.fillColor : '#000'};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 15px 0px 12px;
    position: absolute;
    top: 0px;
    > span {
     cursor : pointer;
     color: #fff;
     font-size: 11px;
     font-family: ${BoldFont};
     display: inline-block;
   }
`;

const ContainerV2 = styled.div`
  width: 100%;
  display:flex;
  justify-content:space-between;
  .text{
    text-align: left;
    font-family: Rubik-Medium;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: rgb(255, 255, 255);
  }
  .image{
    width:20px;
    height:20px;
    display:flex;
    margin:auto 0px auto auto;
    cursor:pointer;
    >img{
      width:20px;
      height:20px;
    }
  }
`;

const YearDisplay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`;

const WbaPointsV2 = styled.div`
width: 365px;
display: flex;
background: #FFFFFF;
border: 1px solid rgba(156, 156, 156, 0.4);
border-radius: 6px;
margin-bottom: 20px;
margin-right: ${({marginRight}) => marginRight ? marginRight : '0px'};
height: 80px;
>div:first-child {
  width: 40%;
  padding: 10px;
  >img{
    width: 60px;
    height: 60px;
  }
}
>div:last-child {
    width: 200%;
    padding: 10px;
  >div:first-child{
    font-family: 'Rubik-Regular'
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0D4270;
    //padding: 15px;
    text-transform: none;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }
  >div:last-child{
    font-family: Rubik-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 28px;
    //color: #32DBC6; 
    //padding: 15px;
    text-align: left;
    padding-top: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
   >span {
    font-size: 14px;
    line-height: 28px;
    font-family: 'Rubik-Regular';
    color: #0D4270;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
   } 
  }
 .step_cls {
   color: red;
 }
}
`;

const InitativeCardV2 = styled.div`
width: calc((100% - 50px) / 3);
height: 80px;
border: 1px solid #9C9C9C;
border-radius: 7px;
display:flex;
// cursor: pointer;
margin-bottom: 15px;
margin-right: ${({marginRight}) => marginRight ? marginRight : '25px'};
.syncing-title-value{
  .points-value{
    color: ${({color}) => color ? color : '#0D4270'};
  }
}
.syncing-title-value{
  width: auto;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  // margin-bottom: 45px;
  // margin-top: 15px;
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
  flex-flow: column;
  margin: 0px;
  padding-right: 5px;
  margin-left: 15px;
}
.company-initative{
   width:100%;
   display: flex;
   background: #FFFFFF;
   flex-direction: column;
   margin-bottom:25px;
}
.heading{
   color:#0D4270;
   font-family: 'Rubik-Medium';
   font-size: 18px;
   line-height:24px;
   margin-top: 25px;
   margin-left: 25px;
}
.horizontal-line2{
  margin-top: 25px;
  margin-bottom: 25px;
  background: rgba(156, 156, 156, 0.4);
  height: 1px;
  border: 0px;
  width: 100%;
}
.fitness-wrapper{
   display:flex;
   justify-content: space-between;
   padding: 0px 25px 0px 25px;
   width:100%;
   flex-wrap:wrap;
}
.syncing-fitness{
  width: calc((100% - 50px) / 3);
  height: 80px;
  border: 1px solid #9C9C9C;
  border-radius: 7px;
  display:flex;
  cursor: pointer;
  margin-bottom: 25px;
  .syncing-title-value{
    width: auto;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    // margin-bottom: 45px;
    // margin-top: 15px;
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column;
    margin: 0px;
    padding-right: 5px;
    margin-left: 15px;
  }
  .syncing-title2{
    width: 187px;
    height: 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display:flex;
    margin: auto;
    margin-left: 15px;
    margin-bottom: 45px;
    margin-top: 15px;
    flex-direction:column;
  }
  .points-value{
    height: 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #F8A887;
    margin-top:0px;
  }
  .points2{
    width: 205px;
    height: 20px;
    left: 547px;
    top: 1337px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #F8A887;
    margin-right: -73px;
    margin-top: 11px;
  }
  .points3{
    width: 68px;
    height: 20px;
    left: 547px;
    top: 1337px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #F8A887;
    margin-right: -73px;
    margin-top: 11px;
  }
}
.rectangle-org{
  width: 60px
  height:60px
  border-radius: 4.2px;
  display:flex;
  margin: auto;
  margin-left: 10px;
  margin-right: 0px;
  >img{
    width:100%;
    height:100%
  }
}
.right-mark{
  margin: auto;
  margin-right: 10px;
}
.square-org{
  width: 60px
  height:60px
  background: linear-gradient(180deg, #FD7175 0%, #FF4C51 100%);
  border-radius: 4.2px;
  display:flex;
  margin: auto;
  margin-left: 10px;
  margin-right: 0px;
}
.cylinder-org{
  width: 60px
  height:60px
  background: linear-gradient(180deg, #C0ED98 0%, #96C26E 100%);
  border-radius: 4.2px;
  display:flex;
  margin: auto;
  margin-left: 10px;
  margin-right: 0px;
}
`;

const TabButton = styled.div`
 display:flex;
 height:40px;
 min-width:100px;
 padding: 0px 10px;
 margin-right:15px; 
 align-items: center;
 font-family: ${({active}) => active ? "Rubik-Medium" : 'Rubik'};
 font-size: 16px;
 line-height: 20px;
 color: ${({active}) => active ? "white" : 'rgb(156, 156, 156)'};
 background: ${({active}) => active ? "#69c2ff" : 'transparent'};
 border: ${({active}) => active ? "none" : '1px solid rgb(156, 156, 156)'};
 border-radius:3px;
 cursor:pointer;
 justify-content: center;
`;

export {
  NotificationWrapper, TitleContainer, UserImage, WellnessIntereset, Content, WellnessInitiative, WellnessInitiativeRow, ActivityContainer, ActivityBarFill, ActivityBar, ShowPoints,
  InitiativeTitle, WellnessPoint, TickWrapper, Container, LocationContainer, StyledModal, AboutCard, WellnessCard, StyledModalV2, StyledBody, TopInfo, AboutTextBox, SaveAboutButton, OverviewCard, NewProgressPointsV2, NewTotalPoints, CountDiv, InitativeCard, WellnessCardV2, NewTotalPointsV2, OverviewCardV2, WbaPoints, ActivityBarFillV2, ActivityBarV2, StyledModalV3, WbaCardContainer, FirstRowCard, LastImprovementRow, ContainerV2, YearDisplay, FirstRowCardV2, WbaPointsV2, InitativeCardV2, TabButton
}