import PropTypes from 'prop-types';
import React from 'react'
import { useEffect } from 'react'
import { BarChartContainer, Border } from './style'
import VerticalBarChart from './VerticalBarChart';
import { ImageUrl } from '../../../utils/constants';
// import { getInitials } from '../../../utils/methods';

const BarChart = ({heading="Top Performing Managers | Overall",total=0,totalText="Total Recognitions",data=[],imageKey="image",countKey="total_recognitions",id=1,circleColor,circleBorderColor,barColor,widthType="half",style}) => {

  useEffect(()=>{
  },[]);



  const graphRender = (data) => {

    const topActivities = data.map((item, answerIndex) => ({
      y: Math.round(item[countKey]),
      color:  getColorByIndex(answerIndex), // You can define getColorByIndex function
      image: ImageUrl + "/" + item[imageKey]
    }));
    // const xAxisLabels = data.map((answer) => ImageUrl + "/" + answer[imageKey]);
    const xAxisLabels = data.map((answer) => {
      const imagePath = answer[imageKey];
      return {
        imagePath:  ImageUrl + "/" + imagePath,
        fullName: answer.full_name,
      };
    });
    const yAxisLabels = ['25%', '50%', '75%', '100%'];
    const dataValue = [{
      name: '',
      data: topActivities,
      showInLegend: false,
      color: '#DE9444'
    }];
    return (
      <div className="bargraph" style={{...style}}>
        <VerticalBarChart
          id={`${id} ${1}`}
          seriesData={dataValue}
          xAxisLabel={xAxisLabels}
          yAxisVisible={yAxisLabels}
          fillColor={1}
          cultureSurvey={1}
          width={"8px"}
          circleColor={circleColor}
          circleBorderColor={circleBorderColor}
          barColor={barColor}
          widthType={widthType}
        />
      </div>)
  }

  function getColorByIndex(index) {
    // Define an array of colors or generate colors dynamically
    const colors = ["rgba(128, 213, 152, 1)"];
  
    // Return the color based on the index, repeating if necessary
    return colors[index % colors.length];
  }
  return (
    <>
      <BarChartContainer style={{...style}}>
        <div className='heading'>{heading}</div>
        <Border/>

        <div className='total-row'>
          <span className='total'>{total}</span>
          <span className='total-text'>{totalText}</span>
        </div>
        <div>{graphRender(data, id || total)}</div>
          
      </BarChartContainer>
    </>
  )
}

BarChart.propTypes = {
  heading: PropTypes.string,
  total: PropTypes.number,
  totalText: PropTypes.string,
  data: PropTypes.array,
  widthType:PropTypes.string,
  id:PropTypes.any,
  imageKey:PropTypes.string,
  countKey: PropTypes.string,
  circleColor: PropTypes.string,
  circleBorderColor: PropTypes.string,
  barColor: PropTypes.string,
  style: PropTypes.object
}

export default BarChart;