import React, { Component } from "react";
import {
  Layout,
  Container,
  CardContainer,
  NoData,
} from "./styles";
// import { BASE_URL } from "../../utils/config";
import BodyMass from "./bodyMass";
import BloodPressure from "./bloodPressure";
import Cholesterol from "./cholesterol";
import Glucose from "./glucose";
// import TriglyceridesComponent from "./triglycerides";
import { toast } from "react-toastify";
// import Waiting from "../Waiting";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { GetTopHealthRisksAndConcerns } from "../../redux/constants/apiConstants";
import {
  fetchBiometricTabApi,
  fetchReportTabAction,
  showBiometricStatus,
} from "../../redux/actions";
import YearDropdown from "../common/YearDropdown/YearDropdown";
import TopHealthData from "./TopHealthData";
import TopHealthCards from "./TotalHealthCards";
import { fetchApi } from "../../utils/methods";
import { GetCompanyBiometricDetails } from '../../redux/constants/apiConstants';
import SkeletonLoder from "../common/skeletonLoder";

const goodCholesterolLabel = [
  {
    name: "Normal",
    range: "[0 - 99]",
    color: "#76AB78",
  },
  {
    name: "Pre-Diabetes",
    range: "[100 - 125]",
    color: "#F1C977",
  },
  {
    name: "Diabetic",
    range: "[126+]",
    color: "#F4AAA9",
  },
];



const goodNonCholesterolLabel = [
  {
    name: "Normal",
    range: "[0 - 139]",
    color: "#76AB78",
  },
  {
    name: "Pre-Diabetes",
    range: "[140 - 199]",
    color: "#F1C977",
  },
  {
    name: "Diabetic",
    range: "[200+]",
    color: "#F4AAA9",
  },
];

const Triglycerides = [
  {
    name: "Optimal",
    range: "[<=100]",
    color: "#85C0EA",
  },
  {
    name: "Normal",
    range: "[101 - 149]",
    color: "#76AB78",
  },
  {
    name: "Borderline High",
    range: "[150-199]",
    color: "#F1C977",
  },
  {
    name: "High",
    range: "[200-499]",
    color: "#AF87C1",
  },
  {
    name: "At Risk",
    range: "[500+]",
    color: "#F4AAA9",
  },
];

class BiometricTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: props?.companyInfo?.id,
      menuIsOpen: false,
      topHealthRisks:[],
      topHealthConcerns:[],
      totalPeopleCount:0,
      biomatric_taken_users_count: 0,
      biomatric_taken_users_percentage: 0,
      company_users: 0,
      one_year_health_cost: 0,
      three_year_health_cost: 0,
      loadingBiometricDetails:true
    };
    this.dropdownRef = React.createRef();
  }
  componentDidMount() {
    const { fetchBiometricTabApi, selectedYearInfo, fetchBiometricStatus } =
      this.props;
    fetchBiometricTabApi(this.state.companyId, selectedYearInfo);
    fetchBiometricStatus(this.state.companyId, selectedYearInfo);
    document.addEventListener("mousedown", this.handleClickOutside);
    this.fetchTopHealthRisksAndConcerns();
    this.getCompanyBiometricDetails();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ menuIsOpen: false });
    }
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      menuIsOpen: !prevState.menuIsOpen,
    }));
  };

  getSystolicBloodPressureData = (data) => {
    const bloodPressureSystolic = [
      {
        id : 'Systolic',
        title: 'Normal',
        value: '< 120',
        width: `${data['normal']}%`,
        height: '173px',
        fillColor: '#76AB78',
        backgroundFill: 'rgba(130,203,243,0.3)',
        needleFillColor: 'rgba(14,113,142,0.4)',
        percentage: data['normal']
      },
      {
        title: 'Pre-Hypertension',
        value: '120 - 129',
        width: `${data['pre_hypertension']}%`,
        height: '173px',
        fillColor: '#F1C977',
        backgroundFill: 'rgba(33, 153, 193, 0.3)',
        needleFillColor: 'rgba(14,113,142,0.4)',
        percentage: data['pre_hypertension']
      },
      {
        title: 'Stage 1 Hypertension',
        value: '130 - 139',
        width: `${data['stage_1_hypertension']}%`,
        height: '118px',
        fillColor: '#85C0EA',
        backgroundFill: 'rgba(255,151,118,0.2)',
        needleFillColor: 'rgba(96,191,121,0.4)',
        percentage: data['stage_1_hypertension']
      },
      {
        title: 'Stage 2 Hypertension',
        value: '140-180',
        width: `${data['stage_2_hypertension']}%`,
        height: '62px',
        fillColor: '#AF87C1',
        backgroundFill: 'rgba(255,151,118,0.2)',
        needleFillColor: 'rgba(235,113,75,0.4)',
        percentage: data['stage_2_hypertension']
      },
      {
        title: 'Hypertensive Crisis',
        value: '>180',
        width: `${data['hypertensive_crisis']}%`,
        height: '6px',
        fillColor: '#F4AAA9',
        backgroundFill: 'rgba(255,151,118,0.2)',
        needleFillColor: 'rgba(235,113,75,0.4)',
        percentage: data['hypertensive_crisis']
      }
    ];

    const averagePercentage = 
      {
        average_point:`${data['average_points']}%`
      }
    
    return <BloodPressure marginRight isStyle={true} bloodPressureData = {bloodPressureSystolic} averagePercentage= {averagePercentage}/>
  
  };

  getDiastolicBloodPressureData = (data) => {
    const bloodPressureDiastolic = [
      {
        id: "Diastolic",
        title: "Normal",
        value: "< 80",
        width: `${data["normal"]}%`,
        height: "173px",
        fillColor: "#76AB78",
        backgroundFill: "rgba(130,203,243,0.3)",
        needleFillColor: "rgba(14,113,142,0.4)",
        percentage: data["normal"],
      },
      {
        title: "Pre-Hypertension",
        value: "80 - 85",
        width: `${data["pre_hypertension"]}%`,
        height: "173px",
        fillColor: "#F1C977",
        backgroundFill: "rgba(33, 153, 193, 0.3)",
        needleFillColor: "rgba(14,113,142,0.4)",
        percentage: data["pre_hypertension"]
      },
      {
        title: "Stage 1 Hypertension",
        value: "86 - 89",
        width: `${data["stage_1_hypertension"]}%`,
        height: "118px",
        fillColor: "#85C0EA",
        backgroundFill: "rgba(255,151,118,0.2)",
        needleFillColor: "rgba(96,191,121,0.4)",
        percentage: data["stage_1_hypertension"]
      },
      {
        title: "Stage 2 Hypertension",
        value: "90-120",
        width: `${data["stage_2_hypertension"]}%`,
        height: "62px",
        fillColor: "#AF87C1",
        backgroundFill: "rgba(255,151,118,0.2)",
        needleFillColor: "rgba(235,113,75,0.4)",
        percentage: data["stage_2_hypertension"]
      },
      {
        title: "Hypertensive Crisis",
        value: ">120",
        width: `${data["hypertensive_crisis"]}%`,
        height: "6px",
        fillColor: "#F4AAA9",
        backgroundFill: "rgba(255,151,118,0.2)",
        needleFillColor: "rgba(235,113,75,0.4)",
        percentage: data["hypertensive_crisis"]
      },
    ];
    const averagePercentage = 
      {
        average_point:`${data['average_points']}%`
      }
    
    return <BloodPressure isStyle={true} bloodPressureData={bloodPressureDiastolic}  averagePercentage= {averagePercentage} />;
  };

  getFastingUserData = (data, container) => ( 
    <Glucose
      marginLeft="0px"
      marginRight="14px"
      childNumber="thirdChild"
      headerTitle="Glucose Fasting"
      payload={goodCholesterolLabel}
      chartData={this.getChartData(data, container)}
      averagePercentage={this.getAverageData(data)}
      isHealthData={true}
      isGlucose={true}
      averagePoint={data?.average_points}
    />
  );

  getnonFastingUserData = (data, container) => (
    <Glucose
      marginLeft="14px"
      marginRight="14px"
      childNumber="fourthChild"
      headerTitle="Glucose Non Fasting"
      payload={goodNonCholesterolLabel}
      chartData={this.getChartData(data, container)}
      averagePercentage={this.getAverageData(data)}
      isHealthData={true}
      isGlucose={true}
      averagePoint={data?.average_points}
    />
  );



  getTriglyceridesData = (data, container) => (
    <Glucose
      marginLeft="14px"
      marginRight="0"
      childNumber="fourthChild"
      headerTitle="Triglycerides"
      payload={Triglycerides}
      averagePercentage={this.getAverageTriglyceridData(data)}
      chartData={this.getChartDataTriglycerides(data, container)}
      isTriglyceridesData={true}
    />
  );




  getChartData = (data, container) => {
    const goodCholesterolPayload = [
      { y: data["normal"], color: "#76AB78", name: "Normal", value: "1" },
      {
        y: data["pre_diabetes"],
        color: "#F1C977",
        name: "Pre Diabetes",
        value: "2",
      },
      { y: data["diabetic"], color: "#F4AAA9", name: "Diabetic", value: "3" },
    ];
    return {
      data: goodCholesterolPayload,
      container: container,
    };
  };

  getAverageData = (data) => {
    const averageData = [
      {average_point : data["average_points"]},
    ];
    return {
      data: averageData,
    };
  };

  getAverageTriglyceridData = (data) => {
    const transformedDataArray = data.map((data) => {
      const averageData = [
        {average_point : data["average_points"]},
      ];
      return {
        data: averageData,
      };
    });
    return {
      data: transformedDataArray[0],
    };
  };


  getChartDataTriglycerides = (dataArray, container) => {
  
    const transformedDataArray = dataArray.map((data) => {
      const Triglycerides = [
        { y: data.normal, color: "#76AB78", name: "Normal", value: "1" },
        { y: data.borderline_high, color: "#F1C977", name: "Borderline High", value: "2" },
        { y: data.high, color: "#AF87C1", name: "High", value: "3" },
        { y: data.optimal, color: "#85C0EA", name: "Optimal", value: "4" },
        { y: data.very_high, color: "#F4AAA9", name: "Very High", value: "5" },
      ];

      return Triglycerides;
    });
    return {
      data: transformedDataArray[0],
      container: container,
    };
  };

  //function for transforming object's key names
  // transformKeyName = (key) => { 
  //   {return key
  //     .split('_')
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(' ');
  //   }};

  fetchTopHealthRisksAndConcerns = async () => {
    const { selectedYearInfo } =
    this.props;

    const apiUrl =  GetTopHealthRisksAndConcerns + "/" + this.state.companyId + "/" +  selectedYearInfo;
    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        // window.console.log("res of the fetchTopHealthRisksAndConcerns api is---",res)

        // const transformedData = Object.keys(res.data).reduce((acc, key) => {
        //   const newKey = this.transformKeyName(key);
        //   acc[newKey] = res.data[key];
        //   return acc;
        // }, {});
        

        // Fetching the first three and last three health metrics
        const keys = Object.keys(res.data);
        window.console.log("keys",keys)
        const firstThree = keys.slice(0, 3).reduce((acc, key) => {
          acc[key] = res.data[key];
          return acc;
        }, {});
  
        const lastThree = keys.slice(-3).reduce((acc, key) => {
          acc[key] = res.data[key];
          return acc;
        }, {});

        // Calculating the total count
        const totalPeopleCount = res.data.reduce((sum, item) => sum + item.count, 0);

        this.setState({
          topHealthRisks: firstThree,
          topHealthConcerns: lastThree,
          totalAffectedUsers:totalPeopleCount
        });
      
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      window.console.log(error);
    }
  };






  async getCompanyBiometricDetails(){
    const {biometricShowandHideStatus} = this.props;
    this.setState({loadingBiometricDetails:true})
    const result = await fetchApi(`${GetCompanyBiometricDetails}/${this.props?.companyInfo?.id}/${this.props.selectedYearInfo}`, "GET");

    this.setState({
      biomatric_taken_users_count: biometricShowandHideStatus? biometricShowandHideStatus?.data?.biomatric_taken_users_count: result?.data?.biomatric_taken_users_count,
      biomatric_taken_users_percentage:  biometricShowandHideStatus? biometricShowandHideStatus?.data?.biomatric_taken_users_percentage:result?.data?.biomatric_taken_users_percentage,
      company_users: biometricShowandHideStatus?biometricShowandHideStatus?.data?.company_users:result?.data?.company_users,
      one_year_health_cost:  biometricShowandHideStatus?biometricShowandHideStatus?.data?.one_year_health_cost:result?.data?.one_year_health_cost,
      three_year_health_cost: biometricShowandHideStatus? biometricShowandHideStatus?.data?.three_year_health_cost:result?.data?.three_year_health_cost,
      loadingBiometricDetails:false
    })
    // window.console.log("line 19 result :- ", result);
  }

  componentDidUpdate(prevProps) {

    const { fetchBiometricTabApi, selectedYearInfo, fetchBiometricStatus } =
    this.props;

    if (prevProps.selectedYearInfo !== this.props.selectedYearInfo) {
      this.fetchTopHealthRisksAndConcerns();
      this.getCompanyBiometricDetails();
      fetchBiometricTabApi(this.state.companyId, selectedYearInfo);
      fetchBiometricStatus(this.state.companyId, selectedYearInfo);
    }
  }



  renderYearDropdown = (yearList, selectedYearInfo) => (
    <div style={{ display: "flex", float: "right" /* marginTop: '10px' */ }}>
      <YearDropdown
        background={"#FFFFFF"}
        refOutside={this.dropdownRef}
        toggleMenu={this.toggleMenu}
        title={
          selectedYearInfo && (
            <div
              className="title"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                color: 'rgba(0, 122, 177, 1)',
                fontSize: '16px',
                fontFamily: 'Rubik-Medium'
              }}
            >
              <div>{selectedYearInfo}</div>{" "}
              <div style={{ marginRight: "3px" }}>
                <svg
                  width="12"
                  height="20"
                  viewBox="0 0 12 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    // position: 'relative', top: '9px', right: '35px',
                    transform: this.state.menuIsOpen ? "rotate(90deg)" : "none",
                  }}
                >
                  <path
                    d="M12 10C12 10.3585 11.8559 10.7169 11.5684 10.9901L2.51663 19.5897C1.94083 20.1368 1.00725 20.1368 0.431681 19.5897C-0.143894 19.0429 -0.143894 18.1561 0.431681 17.609L8.44122 10L0.43196 2.39098C-0.143614 1.84394 -0.143614 0.957264 0.43196 0.410484C1.00753 -0.136826 1.94111 -0.136826 2.51691 0.410484L11.5687 9.00992C11.8563 9.28333 12 9.64172 12 10Z"
                    fill='rgba(0, 122, 177, 1)' /*"#0D4270"*/
                  />
                </svg>
              </div>
            </div>
          )
        }
        labelStyle={{ margin: "5px 10px 0px 0px" }}
        data={yearList}
        onSelect={() => {this.updateDropdown(); }}
        style={{
          background: "rgba(247, 245, 235, 0.5)",
          lineHeight: "20px",
          color: "rgba(0, 92, 135, 0.6)",
          padding: "0px",
          border: "1px solid rgba(0, 92, 135, 0.3)"
        }}
      />
    </div>
  );

  updateDropdown = (year) => {
    const { fetchReportTabAction, fetchBiometricTabApi, fetchBiometricStatus } =
      this.props;
    const { companyId } = this.state;
    fetchBiometricTabApi(companyId, year);
    fetchReportTabAction(companyId, year);
    fetchBiometricStatus(companyId, year);
    // window.console.log("line 396 year :- ", year);
  };



  render() {
    const {
      goodCholesterolPayload,
      badCholesterolPayload,
      // loading,
      systolicBloodPressurePayload,
      diastolicBloodPressurePayload,
      fastingDataPayload,
      nonFastingDataPayload,
      // obeseBodyMass,
      // biometricStatus,
      triglyceridesPayload,
      selectedYearInfo,
      averageBiometricPayload,
      // companyInfo
      goodCholesterolAverage,
      badCholesterolAverage
    } = this.props;
    const{biomatric_taken_users_count, loadingBiometricDetails}=this.state;
    let fastDataobj={};
    let count=0;
    let countDiff=0;
    let countAdd=0;
    if(fastingDataPayload&& nonFastingDataPayload){
      count=fastingDataPayload?.diabetic+fastingDataPayload?.normal+fastingDataPayload?.pre_diabetes+nonFastingDataPayload?.diabetic+nonFastingDataPayload?.normal+nonFastingDataPayload?.pre_diabetes
    }
    if(count>100){
      countDiff=count-100;
    }
    else if(count<100){
      countAdd=100-count;
    }
    if(count>100 && countDiff>0){
      fastDataobj={
        average_points:fastingDataPayload?.average_points,
        diabetic:fastingDataPayload?.diabetic,
        normal:fastingDataPayload?.normal-countDiff,
        pre_diabetes:fastingDataPayload?.pre_diabetes
      }
    }
    else if(count<100 && countAdd>0){
      fastDataobj={
        average_points:fastingDataPayload?.average_points,
        diabetic:fastingDataPayload?.diabetic+countAdd,
        normal:fastingDataPayload?.normal,
        pre_diabetes:fastingDataPayload?.pre_diabetes
      }
    }
    else{
      fastDataobj={
        average_points:fastingDataPayload?.average_points,
        diabetic:fastingDataPayload?.diabetic,
        normal:fastingDataPayload?.normal,
        pre_diabetes:fastingDataPayload?.pre_diabetes
      }
    }


    // if ((!biometricStatus.show_tab && companyInfo?.id==18)||(biomatric_taken_users_count<=9)) {
    //   return (
    //     <React.Fragment>
    //       {/* {this.renderYearDropdown(yearList, selectedYearInfo)} */}
    //       <NoData>
    //         {biometricStatus.tab_message&&companyInfo?.id==18
    //           ? `${biometricStatus.tab_message}`
    //           :biomatric_taken_users_count? `You currently have ${biomatric_taken_users_count} biometrics submitted`: null}
    //         <br />
    //         Minimum {companyInfo?.id==18?20:10} users should have completed the Biometric to view the
    //         aggregate biometric reports.
    //       </NoData>
    //     </React.Fragment>
    //   );
    // }

    window.console.log("biomatric_taken_users_count",biomatric_taken_users_count)

    if (
      loadingBiometricDetails
     
    ) {
      return <SkeletonLoder width={"1248px"} height={"500px"}/>;
    }else if (biomatric_taken_users_count<=9) {
      return (
        <React.Fragment>
          {/* {this.renderYearDropdown(yearList, selectedYearInfo)} */}
          <NoData>
            {biomatric_taken_users_count? `You currently have ${biomatric_taken_users_count} biometrics submitted`: null}
            <br />
            Minimum 10 users should have completed the Biometric to view the aggregate biometric reports.
          </NoData>
        </React.Fragment>
      );
    }
    return (
      <Layout padding="10px 0px 30px 0px" style={{ float: "left", width: "100%" }}>
        {
          <div>
            <CardContainer isHeight={'180px'} isMargin={'0px'}>
              <TopHealthCards selectedYearInfo={selectedYearInfo} totalAffectedUsers={this.state.totalAffectedUsers} companyInfo={this.props?.companyInfo} averageBiometricPayload={averageBiometricPayload}></TopHealthCards>
            </CardContainer>
            <CardContainer  isMargin={'40px'}>
              <TopHealthData
                marginLeft="0"
                marginRight="14px"
                headerTitle='Top Health Risks'
                childNumber="firstChild"
                payload={this.state.topHealthRisks}
                isTopRisks={true}
              />
              <TopHealthData
                marginLeft="14px"
                marginRight="0"
                headerTitle='Top Health Concerns'
                childNumber="secondChild"
                payload={this.state.topHealthConcerns}
                isTopRisks={false}
              />
            </CardContainer>
            <Container isMargin={'40px'}>
              {systolicBloodPressurePayload ? this.getSystolicBloodPressureData(systolicBloodPressurePayload) : <SkeletonLoder width={"610px"} height={"434px"}/>}
              {diastolicBloodPressurePayload ? this.getDiastolicBloodPressureData(
                diastolicBloodPressurePayload
              ): <SkeletonLoder width={"610px"} height={"434px"}/>}
            </Container>

            <CardContainer isMargin={'40px'}>
              {badCholesterolPayload ? <Cholesterol
                marginLeft="0"
                marginRight="14px"
                headerTitle='Low-Density Lipoproteins <span> (LDL) Bad Cholesterol <span>'
                childNumber="firstChild"
                payload={badCholesterolPayload}
                averagePercentage={badCholesterolAverage}
                isCholesterol={false}
              /> : <SkeletonLoder width={"397"} height={"415px"}/>}
              {goodCholesterolPayload? <Cholesterol
                headerTitle='High-Density Lipoprotein <span>(HDL) Good Cholesterol</span>'
                marginLeft="14px"
                marginRight="0"
                childNumber="secondChild"
                payload={goodCholesterolPayload}
                averagePercentage={goodCholesterolAverage}
                isCholesterol={true}
              />: <SkeletonLoder width={"397"} height={"415px"}/>}
              {/* {this.getFastingUserData(fastingDataPayload, "goodCholesterol")}
              {this.getnonFastingUserData(
                nonFastingDataPayload,
                "badCholesterol"
              )} */}
            </CardContainer>
            <CardContainer isHeight={'280px'} isMargin={'40px'}>
              {fastingDataPayload ? this.getFastingUserData(fastDataobj, "goodCholesterol") : <SkeletonLoder width={"397"} height={"415px"}/>}
              {nonFastingDataPayload ? this.getnonFastingUserData(
                nonFastingDataPayload,
                "badCholesterol"
              ): <SkeletonLoder width={"397"} height={"415px"}/>}
              {triglyceridesPayload ? this.getTriglyceridesData(triglyceridesPayload, "TRIGLYCERIDES"): <SkeletonLoder width={"397"} height={"415px"}/>}
              {/* <TriglyceridesComponent /> */}
            </CardContainer>
            {/* <CardContainer>
              {this.getTriglyceridesData(triglyceridesPayload, "TRIGLYCERIDES")}
            </CardContainer> */}

            <CardContainer isMargin={'40px'}>
              <BodyMass isStyle={true} />
            </CardContainer>
          </div>
        }
      </Layout>
    );
  }
}

BiometricTab.propTypes = {
  goodCholesterolPayload: PropTypes.array,
  badCholesterolPayload: PropTypes.array,
  goodCholesterolAverage:PropTypes.array,
  badCholesterolAverage:PropTypes.array,
  averageBiometricPayload: PropTypes.array,
  bodyMassPayload: PropTypes.array,
  triglyceridesPayload: PropTypes.array,
  // topHealthPayload: PropTypes.array,
  loading: PropTypes.bool,
  systolicBloodPressurePayload: PropTypes.object,
  diastolicBloodPressurePayload: PropTypes.object,
  fastingDataPayload: PropTypes.object,
  nonFastingDataPayload: PropTypes.object,
  overWeightBodyMass: PropTypes.number,
  obeseBodyMass: PropTypes.number,
  companyInfo: PropTypes.object,
  selectedYearInfo: PropTypes.number,
  fetchBiometricTabApi: PropTypes.func,
  fetchReportTabAction: PropTypes.func,
  yearList: PropTypes.array,
  fetchBiometricStatus: PropTypes.func,
  biometricStatus: PropTypes.object,
  averageGoodCholesterol: PropTypes.string,
  averageBadCholesterol: PropTypes.func,
  biometricShowandHideStatus: PropTypes.object
};

const mapStateToProps = (state) => ({
  goodCholesterolPayload: state.companyDashboard.goodCholesterolDetails,
  badCholesterolPayload: state.companyDashboard.badCholesterolDetails,
  badCholesterolAverage: state.companyDashboard.badCholesterolAveragePercentage,
  goodCholesterolAverage: state.companyDashboard.goodCholesterolAveragePercentage,
  averageBiometricPayload: state.companyDashboard.averageBiometricDetail,
  bodyMassPayload: state.companyDashboard.bodyMassDetails,
  triglyceridesPayload: state.companyDashboard.triglyceridesDetails,
  // topHealthPayload: state.companyDashboard.topHealthDetails,
  systolicBloodPressurePayload:
    state.companyDashboard.systolicBloodPressureDetails,
  diastolicBloodPressurePayload:
    state.companyDashboard.diastolicBloodPressureDetails,
  fastingDataPayload: state.companyDashboard.fastingDataDetails,
  nonFastingDataPayload: state.companyDashboard.nonFastingDetails,
  overWeightBodyMass: state.companyDashboard.overWeightBodyMass,
  // obeseBodyMass: state.companyDashboard.obeseBodyMass,
  biometricStatus: state.companyDashboard.showBiometricStatus,
  averageGoodCholesterol: state.companyDashboard.averageGoodCholesterol,
  averageBadCholesterol: state.companyDashboard.averageBadCholesterol,
});
const mapDispatchToProps = (dispatch) => ({
  fetchBiometricTabApi: (companyId, year) =>
    dispatch(fetchBiometricTabApi(companyId, year)),
  fetchReportTabAction: (id, year) => dispatch(fetchReportTabAction(id, year)),
  fetchBiometricStatus: (id, year) => dispatch(showBiometricStatus(id, year)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BiometricTab);