import { Dropdown, DropdownButton, DropdownItem } from "react-bootstrap";
import styled, { css } from 'styled-components';
import { CustomDropDown } from "../../PeopleHomeV2/styles";

const BoldFont = 'Rubik-Bold';

const TitleContainer = styled.div`
  float: left;
  width: ${({ tileContainerStyle }) => tileContainerStyle && tileContainerStyle.width ? tileContainerStyle.width : '100%'};
  padding: ${({ tileContainerStyle }) => tileContainerStyle && tileContainerStyle.padding ? tileContainerStyle.padding : '15px 0px 10px 0px'};
  margin: ${({ tileContainerStyle }) => tileContainerStyle && tileContainerStyle.margin ? tileContainerStyle.margin : "0px"};
  position: relative;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  > div {
    float: left;
    width: 100%;
    position: relative;
  }
  .main{
    display: inline-block;
    width: 100%;
    >span{
      font-size: 12px;
    }
  }
  .outerDiv{
    display: flex;
    >div:first-child{
      width: 70%;
    }
    >div:last-child{
      width: 30%;
      display: flex;
      .padding{
        padding-top: 10px;
      }
      >div:first-child{
        height: 100%;
        width: 20%;
        >label{
          margin: 0;
        }
      }
      >div:last-child{
        width: 80%;
      }
    }
  }

  ${({ addMargin }) => addMargin && css`
    > div {
      margin-top: 10px;
    }
  `}

  @media (max-width: 550px) {
    padding: 30px 15px 30px;
  }

  svg {
    position: absolute;
    top: 15px;
    right: 0;
  }

  .dropdown-menu {
    display: ${({ display }) => display ? display : ""};
    width:${({ tileContainerStyle }) => tileContainerStyle && tileContainerStyle.width ? "100%" : "50%"};
    height: ${({ heightV }) => heightV ? heightV : "200px"};
    overflow-y: auto;
    position:relative;
 >.active>a:hover {color:black}
  }

  .dropdown-menu>.active>a{
    ${'' /* background:#0D4270; */}
  }
  @media (max-width: 1000px){
    width: 100%;
  }
`;

const StyledLabel = styled.label`
  float: left;
  display: block;
  font-size: 16px;
  color: #184A61;
  line-height: 21px;
  font-family: Lato;
  font-weight: 400;
  position: relative;
  pointer-events: none;
  margin:${({ labelMargin }) => labelMargin ? labelMargin : '25px 0px 0px 0px'};
  margin-bottom:15px;
  display: flex;
  width: ${({ width }) => width ? width : '100%'};
  >span{
    font-size: 18px;
    color:#F6797D;
  }
  .charCount{
    display:flex;
    margin:auto;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #9c9c9c;
    margin-right:0px;
  }
  .radio{  width:auto;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0D4270;
      display:flex;
      justify-content:flex-start;
      align-items:center;
      flex-direction:row;
      margin: auto;
    margin-right: 0px;
    }
`;

const DropdownArrow = styled.img`
float: right;
top:${({ dropdownStyle }) => dropdownStyle && dropdownStyle.top ? dropdownStyle.top : dropdownStyle && dropdownStyle.drop ? "67px" : " 60px"};
position: absolute;
right: 15px;
display:block;
z-index:5
`;

const ActivityDropdown = styled(DropdownButton)`
  padding: 0px 20px;
  width: 100%;
  text-align: left;
  margin-top: 0px;
  margin: ${({ margin }) => margin};
  text-transform: capitalize;
  height: 50px;
  color: ${({notActive}) =>notActive? '#649bb3' : '#184A61'};
  font-size:16px;
  font-family: ${({notActive}) =>notActive? 'rubik' : 'rubik-medium'};
  border: 1px solid #afcbd3;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  background:#faf9f4;
  >span{
    display:none
  }
  &:active,  &:focus, &:active:focus {
    outline: none;
    background-color: #faf9f4;
    box-shadow: none;
    // color: #9c9c9c;
    color: #184A61;
    font-size:16px;
    font-family: 'rubik-medium';
    border: 1px solid #afcbd3;
  }
`;

const NewActivityDropdownCommon = styled(Dropdown)`
  button{
    width: 100%;
    padding: ${({padding}) =>padding? padding : '0px 20px 0px 12px'};
    width: 100%;
    text-align: left;
    margin-top:  ${({isManagerMargin}) =>isManagerMargin? isManagerMargin : '12px'};
    text-transform: capitalize;
    ${'' /* height: 50px;
     */}
     height: ${({height}) =>height? height : '50px'};
    color: ${({notActive}) =>notActive? '#649bb3' : '#184A61'};
    font-size: 16px;
    ${'' /* font-family: ${({notActive}) =>notActive? 'rubik' : 'rubik'}; */}
    font-family:Lato;
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
    border: ${({border, isManagerMargin}) =>isManagerMargin?"1px solid #BDBDBD": border? '1px solid #BDBDBD' : '1px solid rgba(0, 122, 177, 0.30)'};
    background: white;
    ::after{
      display: none;
    }
    &:hover, &:active, &:active:focus{
      border: ${({border, isManagerMargin}) =>isManagerMargin?"1px solid #BDBDBD": border? '1px solid #BDBDBD' : '1px solid rgba(0, 122, 177, 0.30)'};
      background: white;
      color: #184A61;
      font-size: 16px;
      ${'' /* font-family: rubik-regular; */}
      font-family:Lato;
    }
    &:focus{
      background-color: ${ ({createEventUI}) => createEventUI && '#e6e6e6'};
      box-shadow: ${ ({createEventUI}) => createEventUI && 'inset 0 3px 5px rgba(0,0,0,.125)'};
      color: ${ ({createEventUI}) => createEventUI && '#333'};
      border-color: ${ ({createEventUI}) => createEventUI && '#adadad'};
      font-size: ${ ({createEventUI}) => createEventUI && '16px'};
      ${'' /* font-family: ${ ({createEventUI}) => createEventUI && 'rubik-medium'}; */}
      font-family:Lato;
      height: ${ ({createEventUI}) => createEventUI && '50px'};
    }
  }
  button.show{
    color: #184A61;
    background-color: white;;
    border-color: #184A61;
  }
  .dropdown-menu{
    width: 70%;
    flex-direction: column;
    top: 100% !important;
    left: 0 !important;
    right: auto !important;
    bottom: auto !important;
    position: absolute !important;
    z-index: 15 !important;
    transform: none !important;
    
    .dropdown-item{
      color: #184A61;
      ${'' /* font-family: Rubik-Regular; */}
      font-family:Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      border-bottom:1px solid rgba(0, 92, 135, 0.10);
      border-spacing:10px;
      padding:8px !important;
      margin: ${({margin}) =>margin? margin : '0px 12px'};
      ${'' /* padding: 3px 0px 3px 15px; */}
      background: none;
      text-transform:capitalize;
      &:hover{
        color: #184A61;
        font-family: Rubik-medium;
        background: #f0f9fe;
      }
      &:active{
        color: rgba(0, 92, 135, 0.6);
        font-family: Rubik-medium;
        background: rgba(0, 92, 135, 0.05);
      }
    }
    .dropdown-item .active{
      background: rgba(0, 92, 135, 0.05) !important;
    }
    .dropdown-item > img{
      width: 16px;
      height: 16px;
      float: right;
      margin: auto 15px auto auto;
      display: none;
    }
    .dropdown-item:hover > img{
      display: flex;
    }
    .dropdown-item:active > img{
      display: flex;
    }
    .dropdown-item.active{
      background: rgba(0, 122, 177, 0.05);
    }
  }
  .dropdown-menu.show{
    display:${ ({disabled}) => disabled && 'none'};
    width: 100%;
    margin-top: 4px;
    margin-left: ${({marginleft}) =>marginleft? marginleft : ''};
    width: ${({widthdropdown}) =>widthdropdown? "auto" : '100%'};
    border: 1px solid rgba(0, 122, 177, 0.30);
    ${'' /* inset:100% auto auto 0px; */}
    ${'' /* position: unset !important; */}
    ${'' /* transform: unset !important; */}
    ${'' /* top: 100%; 
    left: 0;
    right: auto;
    bottom: auto; 
    position: absolute;  */}
    .dropdown-item{
      background-color: ${ ({createEventUI}) => createEventUI && '#fff' };
      min-width: ${ ({createEventUI}) => createEventUI && '160px'};
      padding: ${ ({createEventUI}) => createEventUI && '3px 20px'};
      margin: ${ ({createEventUI}) => createEventUI && '2px 0 0'};
      font-size: ${ ({createEventUI}) => createEventUI && '14px'};
      text-align: ${ ({createEventUI}) => createEventUI && 'left'};
      list-style: ${ ({createEventUI}) => createEventUI && 'none'};
      border-radius: ${ ({createEventUI}) => createEventUI && '4px'};
      &:hover{
        background-color: ${ ({createEventUI}) => createEventUI && '#f0f9fe'};
      }
    }

    .dropdown-item:hover {
      ${'' /* color: #262626; */}
      text-decoration: none;
      font-family: 'Rubik-Regular';
    }
    
    .dropdown-item.active{
      ${'' /* background: rgb(13, 66, 112); */}
      ${'' /* color: #fff; */}
      text-decoration: none;
      outline: 0;
      border-radius: 0px;
    }
  }
`

const CustomMenuItem = styled(DropdownItem)`
  width: 100% !important;
  text-transform: capitalize;
  > a {
    &:active, &:hover, &:focus {
      outline: none;
      color:#184A61;
      background-color:rgba(105,194,255,0.1)!important;
      border-radius:6px !important;
    }
  }
`;

const ActivityTypeContainer = styled.div`
  width: ${({ width }) => width ? width : '40% !important'};
  padding: 0;
  float: ${({float}) => float ? float : 'none'};
  margin: ${({margin}) => margin ? margin : '0px'};
  > span {
    width: ${({ width }) => width && '100%' };
  }
  > div {
    width: ${({ noChildWidth }) => noChildWidth ? '100%' : '95%' };
    max-width: ${({ noChildWidth }) => noChildWidth ? 'unset' : '310px' };
    margin-right:  ${({ noChildWidth }) => noChildWidth ? '0px' : '5%' };
    display: ${({ display }) => display ? 'flex' : 'block'};
    float: left;
  }

  @media (max-width: 550px) {
    ${({ challengeMode }) => challengeMode && css`
      margin-top: 15px;
    `}
  }
  
  @media (max-width: 640px) {
    width: 100% !important;
    margin-top: 0
  }
  .dropdown{
    margin-top: 25px;
    margin-top: ${({ marginTop }) => marginTop ? '0px' : '25px'};
  }

  .dropdown-menu {
    width: 100%;
    height: 280px;
    overflow: scroll;
  }
  .dropdown > .dropdown-menu {
    position: relative !important;
    transform: none !important;
    .dropdown-item.active{
      color: #fff;
      text-decoration: none;
      background-color: #337ab7;
      outline: 0;
    }
    .dropdown-item:hover{
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }
  .dropdown:hover{
    background-color: transparent;
  }
  .dropdown > button{
    padding: 12px 10px;
    width: 100%;
    text-align: left;
    ${'' /* margin-top: 15px; */}
    color: rgb(153, 153, 153);
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    outline: none;
    background-color: white;
    border-color: rgb(204, 204, 204);
    box-shadow: none;
    &::after{
      position: absolute;
      right: 10px;
      top: 35px;
    }
    &:active{
      color: rgb(153, 153, 153);
      background-color: white;
      border-color: rgb(204, 204, 204);
      box-shadow: none;
    }
  }
  .dropdown-menu .show{
    position: absolute;
    transform: translate(0px, 2px);
    .dropdown-item:hover, .dropdown-item:active{
      color: #fff;
      text-decoration: none;
      background-color: #337ab7;
      outline: 0;
    }
  }
  .dropdownn{
    border: 10px solid yellow;
  }
  .dropdown:hover{
    background-color: none !important;
  }
`;

const FieldTitle = styled.span`
  font-size: 14px;
  margin-bottom : 15px;
  letter-spacing: 0px;
  line-height: 24px;
  color: ${({color}) => color ? color :'rgb(51,51,51)'};
  font-family: ${BoldFont};
  float: left;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  .day {
    font-size: 18px;
    color: #159fc9;
  }
`;

const ActivityDropdownOne = styled(DropdownButton)`
  padding: 12px 0px;
  width: 100%;
  text-align: left;
  color: rgb(153,153,153);
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  > span {
    float: right;
    top: 20px;
    position: absolute;
    right: 10px;
    height:100px;
    overflex:scroll;
  }

  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    background-color: white;
    border-color: #ccc;
    box-shadow: none;
    color: rgb(153,153,153);
  }
`;

const CustomMenuItemOne = styled(DropdownItem)`
  width: 100%;
  text-transform: capitalize;
  > a {
    &:active, &:hover, &:focus {
      outline: none;
    }
  }
`;

const NewLabel = styled.label`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;
text-align: center;
color: #9C9C9C;
display: flex;
margin: auto;
margin-right: 10px;
`;

const YearDropDown = styled(CustomDropDown)`
  float: right;
  align-items: center;
  margin-left: 60px;
  padding: ${({inputpadding}) => inputpadding ? inputpadding : '9px 29px'};
  width: 90px;
  height: 40px;
  background-color: rgba(105, 194, 255, 0.1);
  border-radius: 6px;
  box-shadow: none;
  margin-top: ${({role}) => (role === 'ADMIN') ? '0px' : '0px'};
  margin-right: 15px;
  padding: 10px 13px 10px 13px;
  .open>.dropdown-men{
    margin-top: -40px;
  }
  @media (max-width: 760px) and (min-width: 651px) {
     margin-top: ${({role}) => (role === 'ADMIN') ? '-62px' : ''};
  }
  @media (max-width: 650px){
    margin-top: ${({role}) => (role === 'ADMIN') ? '-54px' : '-36px'};
  }
  @media (max-width: 500px){
    margin-left: 0px;
    margin-top: -40px;
  }
  .caret{
    color: #69C2FF;
    float: left;
    margin-left :13px;
  }
`;

export { ActivityDropdown,CustomMenuItem,TitleContainer,StyledLabel , DropdownArrow, ActivityTypeContainer, FieldTitle, ActivityDropdownOne, CustomMenuItemOne, NewLabel, YearDropDown, NewActivityDropdownCommon}