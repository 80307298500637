
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ManagerDashboard from '../../components/Manager/ManagerDashboard';
import MyTasksCard from '../../components/Manager/MyTasksCard';
import TeamMembersCard from '../../components/Manager/TeamMembersCard';
import RecognitionCardContainer from '../../components/Manager/RecognitionCardContainer';
import Social from '../Social';
import { PersonalProfile } from '../../components';
import { connect } from 'react-redux';




class Manager extends Component {
  constructor(props) {
    super(props);
  
  }

  componentDidMount() {
    // const { managerId } = this.state;
  }

  managerDashboard = () => {
    window.console.log("");
    return <ManagerDashboard />;
  };

  myTasksCard = () => {
    window.console.log("");
    return <MyTasksCard />;
  };

  teamMembersCard = () => {
    window.console.log("");
    return <TeamMembersCard />;
  };

  recognitionCardContainer = () => {
    window.console.log("");
    return <RecognitionCardContainer />;
  };


  socialFeed = () => {
    const { history,role, user, userCompany,} = this.props;
    window.console.log("");
    return <Social  history={history} user={user} role={role} companyId={userCompany.id}/>;
  };
  
  history = () => {
    window.console.log("hello");
    return <div style={{ width: '1246px', padding: '25px', margin: 'auto'}}><RecognitionCardContainer /></div>;
  };

  personalProfile = () => {
    const { history, companyInfo, teamsCount,role } = this.props;
    return (<PersonalProfile history={history} companyInfo={companyInfo} teamsCount={teamsCount} role={role}/>)
  }

  render() {
    return (
      <>
        <Switch>
          <Route path="/manager/dashboard" exact render={this.managerDashboard} />
          <Route path="/manager/tasks" exact render={this.myTasksCard} />
          <Route path="/manager/team-members" exact render={this.teamMembersCard} />
          <Route path="/manager/recognitions" exact render={this.recognitionCardContainer} />
          <Route path="/manager/social-feed" exact render={this.socialFeed} />
          <Route path="/manager/history" exact render={this.history} />
          <Route path="/manager/people/:id/personal-profile" exact render={this.personalProfile} />
        </Switch>
      </>
    );
  }

}


Manager.propTypes = {
  history: PropTypes.object,
  companyInfo: PropTypes.object,
  teamsCount: PropTypes.array,
  role: PropTypes.string,
  user: PropTypes.object,
  userCompany: PropTypes.object,
}


const mapStateToProps = (state) => ({
  teamsCount: state.adminTeamCreation.teamsCount,
  employeeCount: state.companyDashboard.employeeCount,
  user: state.profileData.user,
  userCompany: state.profileData.userCompany,
});


export default connect(mapStateToProps)(Manager);
