import React, { useEffect, useState } from 'react';
import { MainContainer } from './../Recognition/RecognitionActivity/styles';
import PropTypes from "prop-types";
import { BorderBottom, PerformanceMain, ScrollableContainer, TabBottomLine, TabWrapper, /*YearlyCard*/ } from './styles';
import { Table } from '../common/commonStyles';
import { ImageUrl } from '../../utils/constants';
import { generateMembers } from '../../utils/methods';

const dummyData = [
  { month: 'January', members: generateMembers() },
  { month: 'February', members: generateMembers() },
  { month: 'March', members: generateMembers() },
  { month: 'April', members: generateMembers() },
  { month: 'May', members: generateMembers() },
  { month: 'June', members: generateMembers() },
  { month: 'July', members: generateMembers() },
  { month: 'August', members: generateMembers() },
  { month: 'September', members: generateMembers() },
  { month: 'October', members: generateMembers() },
  { month: 'November', members: generateMembers() },
  { month: 'December', members: generateMembers() },
];

const QuarterlyDummyData = [
  { month: 'January-March | Q1', members: generateMembers() },
  { month: 'April-June | Q2', members: generateMembers() },
  { month: 'July-September | Q3', members: generateMembers() },
  { month: 'October-December | Q4', members: generateMembers() }
];



const Anniversaries = ({ getColor, selectedFilter }) => {
  const [showAll, setShowAll] = useState({}); // Initialize as an empty object
  const [activeTab, setActiveTab] = useState('Work');
  const [data, setData] = useState(dummyData);

  useEffect(() => {

    if(selectedFilter === "Quarterly"){
      setData(QuarterlyDummyData)
    }else{
      setData(dummyData)
    }


  },[selectedFilter])

  const handleShowMore = (month) => {
    setShowAll((prevState) => ({
      ...prevState,
      [month]: !prevState[month], // Toggle the state for the specific month
    }));
  };

  return (
    <MainContainer margin>
      <TabWrapper>
        <TabBottomLine active={activeTab === 'Work'} background={getColor} onClick={() => setActiveTab('Work')}>
        Work
          <BorderBottom active={activeTab === 'Work'} background={getColor} />
        </TabBottomLine>
        <TabBottomLine active={activeTab === 'Birthdays'} background={getColor} onClick={() => setActiveTab('Birthdays')}>
            Birthdays
          <BorderBottom active={activeTab === 'Birthdays'} background={getColor} />
        </TabBottomLine>
      </TabWrapper>
      <PerformanceMain>
        {data.map((monthData, index) => (
          <div className={selectedFilter === "Quarterly" ? 'quarterly' :'month'} style={{ display: "block" }} key={index}>
            <Table padding="8px 0" insight>
              <div className="heading">{monthData.month}</div>
            
              {/* Scrollable container for managing the scrolling */}
              <ScrollableContainer
                height={showAll[monthData.month] ? "380px" : "330px"} // Adjust height based on showAll state
                showAllRows={showAll[monthData.month]}
              >
                {monthData.members
                  .slice(0, showAll[monthData.month] ? monthData.members.length : 5)
                  .map((data, memberIndex) => (
                    <div className="item" key={memberIndex}>
                      <div className="profile">
                        <img src={ImageUrl + data.image} alt={data.name} />
                      </div>
                      <div className="name">{data.name}</div>
                      <div className="value">{data.count}</div>
                    </div>
                  ))}
              </ScrollableContainer>
            
              {/* Show More / Show Less button */}
              {monthData.members.length > 5 &&  !showAll[monthData.month] &&  (
                <div className="showmore">
                  <div
                    className="button"
                    onClick={() => handleShowMore(monthData.month)}
                  >
                   Show More
                  </div>
                </div>
              )}
            </Table>

          </div>
        ))}
      </PerformanceMain>
    </MainContainer>
  );
};

Anniversaries.propTypes = {
  getColor: PropTypes.func.isRequired,
  selectedFilter: PropTypes.string
};

export default Anniversaries;

