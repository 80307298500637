import React, { useState, lazy } from 'react';
import { MainContainer, TopContainerWrapper } from './../Recognition/RecognitionActivity/styles';
import PropTypes from "prop-types";
const TopDataGraph = lazy(() => import('../common/Analytics/TopThreeDataGraph'));
import LowPerformingGraph from '../common/Analytics/LowPerformingGraph';
import { TabBottomLine, TabWrapper, Heading, BorderBottom, ScrollableContainer } from './styles';
import { Table } from '../common/commonStyles';
import { StarIcon } from '../../utils/icons';
// import CircularProgressBar from '../common/Analytics/CircularGraph';

// const segments = [
//   { value: 70, color: "#E4888C", backgroundColor: "#EFEFEF" }, // Outer ring
//   { value: 50, color: "#FFB347", backgroundColor: "#EFEFEF" }, // Middle ring
//   { value: 30, color: "#6FA1FF", backgroundColor: "#EFEFEF" }, // Inner ring
// ];



const Analytics = ({ getColor }) => {
  const [activeTab, setActiveTab] = useState('Department');
  const [showTopRecogntion, setShowTopRecogntion] = useState(false);
  const [showEliteRecogntion, setShowEliteRecogntion] = useState(false);

  const topDepartment = () => {
    const dataArray = [
      { name: 'Product', color: '#FFF', value: 70, width: 204, background: '#92A4DA' },
      { name: 'Quality Assurance', color: '#FFF', value: 40, width: 160, background: '#B1AA56' },
      { name: 'Tech', color: '#FFF', value: 20, width: 96, background: '#DA9B6C' },
    ];
    return (
      <TopDataGraph data={dataArray} heading={'Highest Performing Departments'} total={807} totalSubText='Total Recognitions' />
    );
  };

  const topdepartment = [
    { name: 'Product', value: 70 },
    { name: 'Quality Assurance', value: 60 },
    { name: 'Tech', value: 50 },
    { name: 'Sales', value: 40 },
    { name: 'Marketing', value: 30 },
    { name: 'HR', value: 20 },
  ];

  const displayedTopDepartments = showTopRecogntion ? topdepartment : topdepartment.slice(0, 5);

  const displayedEliteDepartments = showEliteRecogntion ? topdepartment : topdepartment.slice(0, 5);

  const toggleShowTopRecognition = () => {
    setShowTopRecogntion(!showTopRecogntion);
  };

  const toggleShowEliteRecognition = () => {
    setShowEliteRecogntion(!showEliteRecogntion);
  };

  const DepratmentView = () => {
    window.console.log("hi")

    return(
      <>
        <div className='top-performing'>{topDepartment()}</div>
        <div className='low-performing'><LowPerformingGraph id={`${"test"} ${1}`}/></div>
        <Heading>
          <div className='icon'>
            {StarIcon()}
          </div>
          Departments Spotlight
        </Heading>
        <div className='top-performing' style={{display:"block"}}>
          <Table insight>
            <div className="heading">Highest Performing</div>
            <div className="type">
              <div className="name">Departments</div>
              <div className="value">Received</div>
            </div>
            <ScrollableContainer
              height={showTopRecogntion ? "380px" : "330px"}
              showAllRows={showTopRecogntion}
            >
              {displayedTopDepartments.map((data, index) => (
                <div className="item" key={index}>
                  <div className="name">{data.name}</div>
                  <div className="value">{data.value}</div>
                </div>
              ))}
            </ScrollableContainer>
            {topdepartment.length > 5 && !showTopRecogntion && (
              <div className="showmore">
                <div className="button" onClick={toggleShowTopRecognition}>
                  Show More
                </div>
              </div>
            )}
           
          </Table>

        </div>
        <div className='low-performing' style={{display:"block"}}>
          <Table insight>
            <div className="heading">Lowest Performing</div>
            <div className="type">
              <div className="name">Departments</div>
              <div className="value">Received</div>
            </div>
            <ScrollableContainer
              height={showEliteRecogntion ? "380px" : "330px"}
              showAllRows={showEliteRecogntion}
            >
              {displayedEliteDepartments.map((data, index) => (
                <div className="item" key={index}>
                  <div className="name">{data.name}</div>
                  <div className="value">{data.value}</div>
                </div>
              ))}
            </ScrollableContainer>
            {topdepartment.length > 5 && !showEliteRecogntion && (
              <div className="showmore">
                <div className="button" onClick={toggleShowEliteRecognition}>
                  Show More
                </div>
              </div>
            )}
          </Table>

        </div>
      </>
    )
  }

  const topLocations = () => {
    const dataArray = [
      { name: 'Pune', color: '#FFF', value: 70, width: 204, background: '#92A4DA' },
      { name: 'New York', color: '#FFF', value: 40, width: 160, background: '#B1AA56' },
      { name: 'Paris', color: '#FFF', value: 20, width: 96, background: '#DA9B6C' },
    ];
    return (
      <TopDataGraph data={dataArray} heading={'Highest Performing Locations'} total={807} totalSubText='Total Recognitions' />
    );
  };

  const topLocationsData = [
    { name: 'Pune', value: 70 },
    { name: 'New York', value: 60 },
    { name: 'Mumbai', value: 50 },
    { name: 'Paris', value: 40 },
    { name: 'England', value: 30 },
    { name: 'Maxico', value: 20 },
  ];

  const displayedTopLocations = showTopRecogntion ? topLocationsData : topLocationsData.slice(0, 5);

  const displayedEliteLocations = showEliteRecogntion ? topLocationsData : topLocationsData.slice(0, 5);

  const LocationView = () => {
    window.console.log("hi")

    return(
      <>
        <div className='top-performing'>{topLocations()}</div>
        <div className='low-performing'>
          <LowPerformingGraph   
            chartTitle = "Lowest Performing Locations"
            totalRecognitions = "102"
            data = {[
              { category: "Pune", value: 50,color: '#92A4DA'},
              { category: "New York", value: 60, color: '#E3B78E' },
              { category: "Paris", value: 70, color: '#E4888C' }
            ]}
            // data={segments}
            chartHeight = {300}
            chartWidth = {300}
            id={`${"tesr"} ${1}`}/>

          {/* <CircularProgressBar segments={segments} ringSpacing={8} /> */}
              
        </div>
        <Heading>
          <div className='icon'>
            {StarIcon()}
          </div>
          Locations Spotlight
        </Heading>
        <div className='top-performing' style={{display:"block"}}>
          <Table>
            <div className="heading">Highest Performing</div>
            <div className="type">
              <div className="name">Locations</div>
              <div className="value">Received</div>
            </div>
            <ScrollableContainer
              height={showTopRecogntion ? "380px" : "330px"}
              showAllRows={showTopRecogntion}
            >
              {displayedTopLocations.map((data, index) => (
                <div className="item" key={index}>
                  <div className="name">{data.name}</div>
                  <div className="value">{data.value}</div>
                </div>
              ))}
            </ScrollableContainer>
            {topLocationsData?.length > 5 && !showTopRecogntion && (
              <div className="showmore">
                <div className="button" onClick={toggleShowTopRecognition}>
                  Show More
                </div>
              </div>
            )}
          </Table>
        </div>
        <div className='low-performing' style={{display:"block"}}>
          <Table>
            <div className="heading">Lowest Performing</div>
            <div className="type">
              <div className="name">Locations</div>
              <div className="value">Received</div>
            </div>
            <ScrollableContainer
              height={showEliteRecogntion ? "380px" : "330px"}
              showAllRows={showEliteRecogntion}
            >
              {displayedEliteLocations.map((data, index) => (
                <div className="item" key={index}>
                  <div className="name">{data.name}</div>
                  <div className="value">{data.value}</div>
                </div>
              ))}
            </ScrollableContainer>
            {topLocationsData?.length > 5 && !showEliteRecogntion && (
              <div className="showmore">
                <div className="button" onClick={toggleShowEliteRecognition}>
                  Show More
                </div>
              </div>
            )}
          </Table>
        </div>
      </>
    )
  }

  return (
    <MainContainer margin>
      <TopContainerWrapper style={{ flexWrap: "wrap" }}>
        <TabWrapper>
          <TabBottomLine active={activeTab === 'Department'} background={getColor} onClick={() => setActiveTab('Department')}>
            Department
            <BorderBottom active={activeTab === 'Department'} background={getColor} />
          </TabBottomLine>
          <TabBottomLine active={activeTab === 'Location'} background={getColor} onClick={() => setActiveTab('Location')}>
            Location
            <BorderBottom active={activeTab === 'Location'} background={getColor} />
          </TabBottomLine>
          
        </TabWrapper>

        {activeTab === 'Department' ? <DepratmentView /> : null}
        {activeTab === 'Location' ? <LocationView /> : null}

      </TopContainerWrapper> 
    </MainContainer>
  );
};

Analytics.propTypes = {
  getColor: PropTypes.func.isRequired,
};

export default Analytics;