import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { CustomDropdownItem, CustomMenu, CustomToggle, CustomToggleWrapper, DropdownWrapper } from "./style";
import { FilterAdminDropIcon } from "../../../utils/icons";
import PropTypes from "prop-types";


export const CommonFilterDropdown = ({
  options = [], // Dropdown options array (e.g., ["Monthly", "Quarterly", "Yearly"])
  selectedOption, // State for selected option
  setSelectedOption, // State setter for selected option
  isRight = "-18px", // Customize right alignment
  isTop = "18px", // Customize top alignment
  dropdownWidth = "136px", // Customize dropdown width
  dropdownHeight = "144px", // Customize dropdown height
  customStyles = {}, // Allow additional custom styles
}) => {
  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };



  return (
    <DropdownWrapper>
      <Dropdown onSelect={handleSelect} style={{ cursor: "pointer",border:"1px solid #BDBDBD",padding:"14px 16px",borderRadius:"4px", ...customStyles }}>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
          <CustomToggleWrapper>
            {selectedOption}
            <span className="dropdown-arrow">{FilterAdminDropIcon()}</span>
          </CustomToggleWrapper>
        </Dropdown.Toggle>
        <CustomMenu
          align="end"
          isTop={isTop}
          isRight={isRight}
          dropdownWidth={dropdownWidth}
          dropdownHeight={dropdownHeight}
        >
          {options.map((option) => (
            <CustomDropdownItem
              key={option}
              eventKey={option}
              active={selectedOption === option}
            >
              {option}
            </CustomDropdownItem>
          ))}
        </CustomMenu>
      </Dropdown>
    </DropdownWrapper>
  );
};

CommonFilterDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired, // Array of options
  selectedOption: PropTypes.string.isRequired, // Currently selected option
  setSelectedOption: PropTypes.func.isRequired, // Function to update the selected option
  isRight: PropTypes.string, // Custom right alignment value
  isTop: PropTypes.string, // Custom top alignment value
  dropdownWidth: PropTypes.string, // Custom width for the dropdown
  dropdownHeight: PropTypes.string, // Custom height for the dropdown
  customStyles: PropTypes.object, // Additional custom styles
};
