import React, {Component} from 'react';
import {ContactUsSection, GetButton} from "./styles";
import {isValidEmail} from "../../utils/methods";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { subscribeNewsLetter } from "../../redux/actions/authActions";
import {ToastContainer} from "react-toastify";
class ContactUs extends Component{
  constructor(){
    super();
    this.state = {
      email: ''
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { subscribeNewsLetter, history } = this.props;
    const { email } = this.state;
    let payload = {
      email
    };
    if(email.trim() !== '' && isValidEmail(email)) {
      subscribeNewsLetter(history, payload);
      this.setState({
        email: ''
      })
    }
  };

  render(){
    const {email} = this.state;
    return(
      <ContactUsSection>
        <p>Stay in the loop with our monthly newsletter</p>
        <div>
          <form onSubmit={this.onSubmit}>
            <input
              type='text'
              placeholder='Your company email'
              name="email"
              value={email}
              onChange={(e) => this.setState({email: e.target.value})}
            />
            <GetButton
              onClick={this.onSubmit}
              disabled={email.trim() === '' || !isValidEmail(email.trim())}
            >
                        Sign up
              <i className="fas fa-arrow-right" />
            </GetButton>
          </form>
        </div>
        <ToastContainer/>
      </ContactUsSection>
    )
  }
}

ContactUs.propTypes = {
  history: PropTypes.object.isRequired,
  subscribeNewsLetter: PropTypes.func.isRequired
}; 

const mapDispatchToProps = (dispatch) => ({
  subscribeNewsLetter: (history, payload) => dispatch(subscribeNewsLetter(history, payload))
});

export default connect(null, mapDispatchToProps)(ContactUs);
