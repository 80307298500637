import React, { Component } from 'react';
import { GraphLayout, ScoreContainer, ContragsText, WBAGraphContainer, GraphDataContainer, Labels, DoneButton} from './styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { UpdateHraFormStatus } from '../../redux/actions';
import BarChart from './WBABarChart';

const colors = ["#C464AC", "#4798D9", "#8BBD53", "#E48D8B", "#F5E3A3"]
const categories = ['Lifestyle', 'Fitness', 'Nutrition', 'Stress', 'Workplace'];
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import Waiting from '../Waiting';
import { withTranslation } from 'react-i18next';
class HraGraphResults extends Component {
  getWellbeingPercentage = (value) => {
    if (value) {
      return parseInt((value / 28) * 100,10);
    }
    else {
      return 0;
    }
  };
  showGraph = () =>{
    const {wellnessAssessment: wbaData} = this.props;
    if (isUndefined(wbaData) || isNull(wbaData)) {
      return <Waiting />
    }

    const data = [Number(wbaData?.data?.lifestyle_percentage), Number(wbaData?.data?.fitness_percentage), Number(wbaData?.data?.nutrition_percentage), Number(wbaData?.data?.stress_percentage), Number(wbaData?.data?.workplace_percentage)]
    return <BarChart colors={colors} data={data} categories={categories}/>
  }
  showWellbeingAssesmentScore = () => (
    <ScoreContainer id="wellbeing-assessment-score">
      {this.props.companyDetails && this.props.companyDetails.id === 11 ? <ContragsText id="congrats-text-1">
        {`${this.props.t("Congrats on completing your assessment! You've completed")} `}<strong>{'½'}</strong> {this.props.t("tasks required for your medical premium reduction")}
      </ContragsText> : <ContragsText id="congrats-text-2">
        {`${this.props.t("Congrats on completing your assessment! You've earned")} `}<strong>{this.props?.wellnessAssessment?.points || '500'}</strong> {this.props.t("Wellness points")}!
        <div>{this.props.t("Wellness score: ")} 
          <strong>{Number(this.props?.wellnessAssessment?.fitness)  + Number(this.props?.wellnessAssessment?.lifestyle) + Number(this.props?.wellnessAssessment?.nutrition) + Number(this.props?.wellnessAssessment?.stress) + Number(this.props?.wellnessAssessment?.workplace)}</strong>
        </div>
      </ContragsText>}
      <WBAGraphContainer id="wba-graph-container">
        <GraphDataContainer id="graph-data-container">{this.showGraph()}</GraphDataContainer>
        <div id="labels-container">
          {
            categories.map((data, index) => (
              <Labels key={index} bgColor={colors[index]} id={`label-${index}`}>
                <div />
                <span>{this.props.t(data)}</span>
              </Labels>
            ))
          }
        </div>
      </WBAGraphContainer>
      <DoneButton id="done-button" onClick={() => this.props.history.push("/portal") }>{this.props.t("Done")}</DoneButton>
    </ScoreContainer>
  )
  
  render() {
    return (
      <GraphLayout id="graph-layout">
        {this.showWellbeingAssesmentScore()}
      </GraphLayout>
    );
  }
}
HraGraphResults.propTypes = {
  wellnessAssessment: PropTypes.object,
  history: PropTypes.object,
  // UpdateHraFormStatus: PropTypes.func,
  companyDetails: PropTypes.object,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  wellnessAssessment: state.profileData.wellnessAssessment,
  companyDetails: state.profileData.companyDetails
});
// const mapDispatchToProps = (dispatch) => ({
//   UpdateHraFormStatus: () => dispatch(UpdateHraFormStatus())
// });
export default connect(mapStateToProps, null)(withTranslation()(HraGraphResults));