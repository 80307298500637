import React, { useEffect, useState } from "react";
import { FilterDropIcon, RecognitionCountIcon, TeamCountIcon } from "../../utils/icons";
import Dropdown from "react-bootstrap/Dropdown";
import {
  Card,
  CenteredText,
  CustomDropdownItemV1,
  CustomMenuV1,
  CustomToggleWrapper,
  DropdownWrapper,
  FilterWrapper,
  VerticalBar,
} from "./styles";
import { FetchRecognitionTeamCount } from "../../redux/constants/apiConstants";
import { toast } from "react-toastify";
import { fetchApi, getStartAndEndDates } from "../../utils/methods";
// import moment from "moment";
import styled from "styled-components";
import { useSelector } from "react-redux";
import MyTasksCard from "./MyTasksCard";
import TeamMembersCard from "./TeamMembersCard";
import RecognitionCardContainer from "./RecognitionCardContainer";
import TopLowPerformer from "./Dashboard/TopLowPerformer";

const CustomToggle = styled.div`
  display: flex;
  align-items: center;
  width: 89px;
  height: 20px;
  padding: 0 5px;
  font-size: 16px;
  cursor: pointer;
  color: #184a61;
  &::after {
    display: none !important;
  }
`;

function ManagerDashboard() {
  const { user } = useSelector((state) => state.profileData);
  const [recognitionData, setRecognitionData] = useState([]);
  const [timeInterval, setTimeInterval] = useState("Monthly");

  useEffect(() => {
    fetchRecognitionData();
  }, [timeInterval]);

  const fetchRecognitionData = async () => {
    const { startDate, endDate } = getStartAndEndDates(timeInterval);
    const apiUrl = `${FetchRecognitionTeamCount}/${startDate}/${endDate}`;

    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        setRecognitionData(res.data);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      window.console.log(error);
    }
  };


  const handleSelect = (eventKey) => {
    setTimeInterval(eventKey);
  };

  const showTime = (uname) => {
    let today = new Date();
    let curHr = today.getHours();
    // const{company_branding}=this.props.companyBranding;
    return (
      <div>
        <span className="time">
          {curHr < 12
            ? "Good Morning,"
            : curHr < 18
              ? "Good Afternoon,"
              : "Good Evening,"}
        </span>
        &nbsp;
        <span className="name">{uname}</span>
      </div>
    );
  };

  return (
    <div style={{maxWidth:"1246px",padding:"25px",margin:"auto",width:"100%"}}>
      <CenteredText id="good-morning-text">
        {showTime(user && user.fname)}
      </CenteredText>
      <Card id="card">
        <FilterWrapper>
          <div
            style={{
              height: "32px",
              width: "92px",
              marginRight: "28px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <DropdownWrapper>
              <Dropdown onSelect={handleSelect} style={{ cursor: "pointer" }}>
                <Dropdown.Toggle
                  as={CustomToggle}
                  variant="success"
                  id="dropdown-basic"
                  style={{ color: "#184A61", border: "none" }}
                >
                  <CustomToggleWrapper>
                    {timeInterval}
                    <span className="dropdown-arrow">{FilterDropIcon()}</span> 
                  </CustomToggleWrapper>
                </Dropdown.Toggle>
                <CustomMenuV1 align="end" isTop={"18px"} isRight={"-48px"}>
                  {["Monthly", "Quarterly", "Yearly"].map((interval) => (
                    <CustomDropdownItemV1
                      key={interval}
                      eventKey={interval}
                      active={timeInterval === interval}
                    >
                      {interval}
                    </CustomDropdownItemV1>
                  ))}
                </CustomMenuV1>
              </Dropdown>
            </DropdownWrapper>
          </div>
          <VerticalBar></VerticalBar>
          <div
            style={{
              height: "32px",
              minWidth: "220px",
              marginLeft: "28px",
              marginRight: "28px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "32px",
                width: "32px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                marginRight:'4px'
              }}
            >
              {RecognitionCountIcon()}
            </div>{" "}
            {recognitionData.recognition_count === 0
              ? "No"
              : recognitionData.recognition_count}{" "}
            Recognition Received
          </div>
          <div
            style={{
              height: "32px",
              minWidth: "166px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "32px",
                width: "32px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight:'4px'
              }}
            >
              {TeamCountIcon()}
            </div>{" "}
            {recognitionData.team_members_count === 0
              ? "No"
              : recognitionData.team_members_count}{" "}
            Team Members
          </div>
        </FilterWrapper>
      </Card>
      <MyTasksCard fetchRecognitionData={fetchRecognitionData}/>
      <TopLowPerformer/>
      <TeamMembersCard/>
      <RecognitionCardContainer/>
    </div>
  );
}

export default ManagerDashboard;
