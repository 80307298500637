import styled, { css } from 'styled-components';
import { Modal } from "react-bootstrap";

const Buttons = styled.button`
  width: 250px;
  height: 50px;
  border-radius: 4px;
  background: #69c2ff;
  border: none;
  font-size: 12px;
  color: rgb(255, 255, 255);
  font-family: Rubik-Medium;
  text-align: center;
  text-transform: uppercase;
  padding: 0px;
  opacity: 1;
  outline: none;
  display: flex;
  justify-content: space-between;
  > img {
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    margin: auto;
    margin-left: 5px;
  }
  > p {
    margin: auto;
    margin-left: 0px;
    width: 180px;
    height: 18px;
    font-family: "Rubik-Medium";
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
  }
  > span {
    margin-right: 10px;
  }
`;

const TabBottomLine = styled.div`
color:  #184A61;
text-align: center;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
padding-bottom:8px;
${'' /* border-bottom: ${({active, background}) => active ?`2px solid ${background}` : 'rgba(243, 122, 101, 1)'}; */}
${'' /* border-radius: 0 0 2px 2px; */}
margin-right: 24px;
cursor:pointer;
`;

const TabWrapper = styled.div`
width:100%;
display:flex;
margin-bottom:20px
`;

const Heading = styled.div`
width:100%;
display:flex;
color: #184A61;
font-family: Lato;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 36px;
margin:20px 0;
align-items: center;
.icon{
  width:24px;
  height:24px;
  margin-right:12px;
  display: flex;
  >img{
    width:24px;
    height:24px;
  }
}
`;

const ManagerCard = styled.div`
width:298px;
padding: 8px;
border-radius: 6px;
display:flex;
background: ${({background}) => background ?background : 'white'};
.profile{
  width: 48px;
  height: 48px;
  margin-right: 12px;
  border-radius: 50%;
  border: 1px solid #DCDCDC;
  >img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.name{
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display:flex;
  align-items:center;
  color: #184A61;
}
.arrow{
  display:flex;
  margin: auto 0 auto auto
}

`;



export const ScrollableContainer = styled.div`
  max-height: 400px;
  max-height: ${({ height }) => (height ? height : "400px")};
  overflow-y: ${({ showAllRows }) => (showAllRows ? "auto" : "hidden")};

  ::-webkit-scrollbar {
    width: 4px;
    height: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 4px;
  }
`;

const PerformanceMain = styled.div`
display:flex;
width:100%;
flex-wrap:wrap;
gap:20px;
.month{
  width:400px;
  display:flex;
  flex-wrap:wrap;
}
.quarterly{
  width:614px;
  display:flex;
  flex-wrap:wrap;
}
.yearly{
  width:288px;
  display:flex;
  flex-wrap:wrap;
}
`;

const YearlyCard = styled.div`
display:flex;
width:100%;
flex-wrap:wrap;
background:white;
border-radius:6px;
padding:16px;
.profile{
  width:100%;
  height:80px;
  margin-bottom:16px;
  justify-content: center;
  display: flex;
  >img{
    width:80px;
    height:80px;
    border-radius:50%;
  }
}
.name{
  width:100%;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #184A61;
  display:flex;
  justify-content:center;
  align-items:center;
}
.count{
font-family: Lato;
font-size: 32px;
font-weight: 700;
line-height: 52px;
color:#092F41;
display:flex;
justify-content:center;
align-items:center;
width:100%;
margin:8px 0;
}
.department{
font-family: Lato;
font-size: 16px;
font-weight: 400;
line-height: 20px;
text-align: center;
color: #184A61;
display:flex;
justify-content:center;
align-items:center;
width:100%;
}
.location{
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #184A61;
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
  margin-top:8px;
}

`;

const ModalTitle = styled.div`
display:flex;
width:100%;
justify-content:space-between;
align-items:center;
.title{
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #184A61
}
.close{
  display:flex;
  cursor:pointer;
}
`;

const Border = styled.div`
  background: ${({background}) => background ?background : '#EFEFEF'};
  margin: ${({margin}) => margin ?margin : '0'};
  width:100%;
  display:flex;
  height:1px;
`;

const DropdownHeading = styled.div`
width:100%;
display:flex;
justify-content:space-between;
margin-bottom:12px;
.name{
  width:364px;
  display:flex;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color:#184A61;
}
`;




//styles for download popup

export const Divider = styled.div`
  height: 1px;
  width: ${({ isWidth }) => (isWidth ? isWidth : "100%")};
  padding: ${({ isPadding }) => (isPadding ? isPadding : "")};
  background-color: var(--Bg-Color-for-Light-theme-400, #efefef);
  margin: ${({ isMargin }) => isMargin || "0px 0px 10px 0px"};
`;


export const PopupHeading = styled.div`
  color: var(--Font-Color-on-Light-theme-500, #184a61);
  font-family: "Lato";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
`;

export const StyledModal = styled(Modal)`
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 100% !important;
    }
  }
  .modal-dialog {
    width: ${({ width }) => (width ? width : "485px")};
    border-radius: 8px;
    // background: linear-gradient(180deg, #FFFFFF 0%,rgba(255,255,255,0) 100%), #FFFFFF;
    // box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    transform: translate(0, 50vh) translate(0, -50%);
    margin: 80px auto;
    border: none;
    @media (min-width: 576px) {
      .modal-dialog {
        max-width: 100% !important;
      }
    }
    .modal-content {
      height: 100%;
      width: 100%;
      border: none;
      background-color: ${({ bgcolor }) =>
    bgcolor || !bgcolor ? "transparent" : "#fff"};
    }
    .close {
      margin-top: -30px;
    }
    ${
  "" /* @media (max-height: 1200px) {
      width: 55%;
    } */}
    @media (max-height: 530px) {
      transform: none !important;
      margin: 30px auto;
    }
    @media (max-width: 766px) {
      width: 95%;
    }
  }
`;

export const ModalContent = styled.div`
  // height: auto;
  // max-height: ${({ isHeight }) => (isHeight ? isHeight : "488px")};
  width: 800px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 12px;
  padding: 16px 0px;
`;


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${'' /* margin: 16px 0 8px 0; */}
  padding:0px 24px;
`;

export const FieldTitle = styled.span`
  font-size: ${({isFont}) => isFont ? '16px' :'14px'};
  margin-bottom : ${({ mb }) => mb? mb:0};
  letter-spacing: 0px;
  line-height: 24px;
  color: ${({color}) => color ? color :'#005c87'};
  font-family: ${({isFont}) => isFont ? isFont :'Rubik-Regular'};
  ${'' /* font-family: "Rubik-Regular"; */}
  float: ${({manager}) => manager ? '' :'left'};
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  ${'' /* padding:0px 24px; */}
  .day {
    font-size: 18px;
    color: #159fc9;
  }
  .important{
    color:rgb(253, 113, 117);
    font-size: 18px;
  }
`;



export const CustomCheckbox = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #7C7C7C; 
  border-radius: 4px; 
  background-color: white;
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: white; 
    border-color: #F37A65; 
  }

  &:checked::after {
    content: ""; 
    color: #F37A65; 
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px; /* Adjust based on your PNG dimensions */
    height: 12px; /* Adjust based on your PNG dimensions */
    background-image: url('/public/images/checked.png'); 
    background-repeat: no-repeat;
    background-size: contain;
    transform: translate(-50%, -50%);
  }

 
`;



export const Button = styled.button`
  width: 200px;
  height: 40px;
  padding: 12px 16px;
  gap: 8px;
  border-radius: 4px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  background-color: ${({ isBg, disabled }) =>
    disabled ? "#EFEFEF" : isBg ? "#ffffff" : "#F37A65"};
  color: ${({ isBg, disabled }) =>
    disabled ? "#989898" : isBg ? "#184A61" : "#fff"};
  font-size: 16px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border: ${({ isBg, disabled }) =>
    disabled ? "none" : isBg ? "1px solid #F37A65" : "none"};
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ isBg, disabled }) =>
    disabled ? "none" : isBg ? "none" : "#EA6149"};
  }
`;


export const AdminIconContainer = styled.div`
  width: ${({ isWidth }) => (isWidth ? isWidth : "32px")};
  Height: ${({ isHeight }) => (isHeight ? isHeight : "32px")};
  display:flex;
  justify-content:center;
  align-items:center;
`;



export const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top:12px;
  gap: 60px; 

  .RadioButtonContainer {
  display: flex;
  align-items: center;
  gap: 16px; 
  }
  

  .label{
    color: var(--Font-Color-on-Light-theme-600, #092f41);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
  }
`;

export const RadioButton = styled.input`
  cursor: pointer;
  width: 20px;
  height: 20px;
  appearance: none;
  border: 1px solid #7c7c7c;
  border-radius: 50%;
  position: relative;
  outline: none;
  

  &:checked {
    background-color: white;
    border-color: #F37A65;
  }

  &:checked::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: #F37A65;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const TitleContainer = styled.div`
  width: ${({ width }) => width ? width : '100%'};
  padding: ${({ padding }) => padding ? padding : '30px 38px 30px 40px' };
  margin: ${({ margin }) => margin};
  > div {
    width: 100%;
    position: relative;
    padding: ${({ isPadding }) => isPadding ? isPadding : '12px 10px' };
    ${'' /* padding: 12px 10px; */}
    width: 100%;
    text-align: left;
    margin-top:: ${({ isPadding }) => isPadding ? "20px" : '15px' };
    ${'' /* margin-top: 15px; */}
    color: rgb(153,153,153);
    text-transform: capitalize;
    &:active, &:hover, &:focus, &:active:focus {
      outline: none;
      background-color: white;
      border-color: #ccc;
      box-shadow: none;
      color: rgb(153,153,153);
    }
    .dropdown-menu{
      width : 100%;
      height: 200px;
      height: ${({ heightV }) => heightV ? heightV : "200px"};
      overflow: ${({ scroll }) => scroll ? scroll : "hidden scroll"};
      z-index: 9999991;
      top: 90%;
      ::-webkit-scrollbar {
        width: 7px;
        height: 45px;
      }
      ::-webkit-scrollbar-thumb {
        background: #005C87;
        cursor: pointer;
        border-radius: 2.5px;
      }
      
      ::-webkit-scrollbar-track {
        background: #005C874D;
        border-radius: 2.5px;
      }
    }

    .css-8ycrlo-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
      width: ${({ isWidth }) => isWidth ? isWidth : '100%'};
    }
    >ul{
      width : 100%;
      >li{
        padding: 3px 20px;
        margin-top: 5px;
        >a{
          background: #F9F9F9;
          border-radius: 3px;
          height: 50px;
          width: 100%;
          display: flex;
          align-items: center;
          border: 1px solid rgba(156, 156, 156, 0.4);
          border-radius: 6px;
          >div:first-child{
            width: 10%;
            display: flex;
            flex-direction: column;
          }
          >div:nth-child(2){
            width: 90%;
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #005c87;
          }
        }
      }
    }
  }

  ${({ addMargin }) => addMargin && css`
    > div {
      margin-top: 10px;
    }
  `}

  @media (max-width: 550px) {
    padding: 30px 15px 30px;
  }

  svg {
    position: absolute;
    top: ${({ manager }) => manager ? "20px" : '15px' };
    right: ${({ manager }) => manager ? "25px" : '0px' };
  }

  .dropdown-menu {
    width: 50%;
  }
  @media (max-width: 1000px){
    width: 100%;
  }


  .dropdown-menu>.active>a{
    background:rgba(247, 245, 235, 0.50)
  }
`;

const BorderBottom = styled.div`
  width: 100%;
  height:2px;
  border-radius: 5px;
  background: ${({background, active}) => active ? background : '#F8F8F8'};
  margin-top:8px;
  ${'' /* border-bottom: ${({active, background}) => active ?`2px solid ${background}` : 'rgba(243, 122, 101, 1)'}; */}
`

export {
  Buttons, TabBottomLine, TabWrapper, Heading, ManagerCard, PerformanceMain, YearlyCard, ModalTitle, Border, DropdownHeading, BorderBottom
};
