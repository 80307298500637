/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { authenticatedUser } from '../../redux/actions';
import { toast } from 'react-toastify';

class RestrictedRoute extends Component {

  constructor(props) {
    super(props);
    this.shownModules = {};  // Store the shown toasts here
  }

  componentDidMount() {
    this.props.authenticatedUser();

  }


  renderMergedProps = (component, rest) => React.createElement(component, rest);

  // Define the feature checks by matching parts of the URL
  isFeatureEnabled = (path) => {
    const { companyBranding } = this.props;
    const companySettings = companyBranding?.company_branding;

    // List of routes (substrings) mapped to the companyBranding feature toggle
    const routeFeatureMap = [
      { match: 'health', key: 'show_biometric', module: "Health data module" },
      { match: 'challenges', key: 'show_challenge', module: "Challenges module" },
      { match: 'education', key: 'show_education', module: "Wellness resources module" },
      { match: 'people', key: 'show_employee_directory', module: "People page" },
      { match: 'events', key: 'show_events', module: "Events module" },
      { match: 'recognition', key: 'show_recognition', module: "Recognition module" },
      { match: 'social', key: 'social_feed', module: "Social feed module" },
      { match: 'community', key: 'social_feed', module: "Community module" },
      { match: 'rewards', key: 'rewards', module: "Rewards module" },
      { match: 'portal', key: 'user_dashboard', module: "User dashboard" },
      { match: 'leaderboard', key: 'leaderboard', module: "Leaderboard module" },
      { match: 'survey', key: 'show_survey', module: "Survey module" },
    ];

    // Find the feature that matches the current path
    const matchingFeature = routeFeatureMap.find((route) =>
      path?.includes(route.match)
    );

    // If no matching feature, assume it's allowed by default
    if (!matchingFeature) return true;

    if (companySettings && companySettings?.[matchingFeature.key] !== 1) {
      // Check if the toast for this module has already been shown
      if (companySettings && !this.shownModules[matchingFeature.key]) {
        window.console.log(`You don't have the subscription for ${matchingFeature.module}`);
        toast.error(`You don't have the subscription for ${matchingFeature.module}`);
        this.shownModules[matchingFeature.key] = true;  // Mark the module as shown
      }
      return false;
    }

    return true;
  };

  render() {
    const { component, authenticated, ...rest } = this.props;
    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    return (
      <Route
        {...rest}
        render={(props) => {
          const { location: { pathname } } = props;
          const StringArray = pathname.split('/')[1];
          const finalPage = StringArray === 'company' ? pathname.split('/')[2] : StringArray;

          // Check if the user is authenticated and the feature is enabled
          if (authenticated || AuthToken) {
            // Check if the feature corresponding to the path is enabled
            if (this.isFeatureEnabled(finalPage)) {
              return this.renderMergedProps(component, { ...rest, ...props });
            } else {
              // Redirect to /portal if the feature is disabled
              return <Redirect to="/portal" />;
            }
          }

          // Redirect to /user if not authenticated
          return (
            <Redirect
              to={{
                pathname: '/user',
                state: { from: props.location },
              }}
            />
          );
        }}
      />
    )
  }
}

RestrictedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  authenticatedUser: PropTypes.func.isRequired,
  authenticated: PropTypes.string.isRequired,
  companyBranding: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.token,
  companyBranding: state.wellnessDashboard.companyBrandingDetails,
});

const mapDispatchToProps = (dispatch) => ({
  authenticatedUser: () => dispatch(authenticatedUser()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RestrictedRoute));
