import React from 'react'
import { useEffect } from 'react'
import useOutsideClick from '../../../hooks/useOutSideClick';
import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CheckBoxContainer, CommonDeptLocContainer } from './style';
import { Border } from '../../RecogntionAdmin/styles';
import { RecognitionTickIcon } from '../../../utils/icons';

const DeptLoc = ({departmentDetails, locationDetails, isDropdownOpen, setIsDropdownOpen}) => {
  const [selectedDepartments, setSelectedDepartments]= useState([]);
  const [selectedLocations, setSelectedLocations]= useState([]);

  const ref = useOutsideClick(() => {
    setIsDropdownOpen(false);
  });

  useEffect(() => {
    window.console.log(isDropdownOpen);
  },[]);

  const SelectDepartment = (id)=>{
    const allDepartments = [...selectedDepartments];
    if (allDepartments.includes(id)) {
      let index = allDepartments.findIndex((item) => item === id);
      if (index > -1) {
        allDepartments.splice(index, 1);
      }
    } else {
      allDepartments.push(id);
    }

    setSelectedDepartments(allDepartments)
  }
  const selectAllDepartments = () => {
    if (selectedDepartments.length == departmentDetails?.length) {
      setSelectedDepartments([]);
    } else {
      let arr = [];
      for (let i = 0; i < departmentDetails?.length; i++) {
        arr.push(departmentDetails[i].id);
      }
      setSelectedDepartments(arr);
    }
  };
  

  const SelectLocation = (id)=>{
    const allLocations = [...selectedLocations];
    if (allLocations.includes(id)) {
      let index = allLocations.findIndex((item) => item === id);
      if (index > -1) {
        allLocations.splice(index, 1);
      }
    } else {
      allLocations.push(id);
    }

    setSelectedLocations(allLocations)
  }

  const selectAllLocations = () => {
    if (selectedLocations.length == locationDetails?.length) {
      setSelectedLocations([]);
    } else {
      let arr = [];
      for (let i = 0; i < locationDetails?.length; i++) {
        arr.push(locationDetails[i].id);
      }
      setSelectedLocations(arr);
    }
  };
  return (
    <>{isDropdownOpen ? 
      <CommonDeptLocContainer ref={ref}>
        <div className='dept-loc-conteiner'>
          <div className="all-department">
            <div className="department-title">
              <CheckBoxContainer selected={selectedDepartments.length === departmentDetails.length ? true : false}  onClick={()=>selectAllDepartments()}>
                <div className="archive-action">{selectedDepartments.length === departmentDetails.length ?  RecognitionTickIcon() : null}</div>
              </CheckBoxContainer>
              <span className='dept-name'>All Departments</span>
            </div>
            <Border style={{border:"1px solid #EFEFEF",margin:"8px 0px"}}/>
            <div className="department-list">
              {departmentDetails.map((department, index)=>(
                <div key={index + department.id} className="department-row" onClick={()=>SelectDepartment(department.id)}>
                  <CheckBoxContainer selected={selectedDepartments.includes(department.id) ? true : false}>
                    <div className="archive-action" >{selectedDepartments.includes(department.id) ?  RecognitionTickIcon() : null}</div>
                  </CheckBoxContainer>
                  <span className='dept-name'>{department.department}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="all-location">
            <div className="department-title">
              <CheckBoxContainer selected={selectedLocations.length === locationDetails.length ? true : false} onClick={()=>selectAllLocations()}>
                <div className="archive-action" >{selectedLocations.length === locationDetails.length ?  RecognitionTickIcon() : null}</div>
              </CheckBoxContainer>
              <span className='dept-name'>All Locations</span>
            </div>
            <Border style={{border:"1px solid #EFEFEF",margin:"8px 0px"}}/>
            <div className="department-list">
              {locationDetails.map((location, index)=>(
                <div key={index + location.id} className="department-row" onClick={()=>SelectLocation(location.id)}>
                  <CheckBoxContainer selected={selectedLocations.includes(location.id) ? true : false}>
                    <div className="archive-action">{selectedLocations.includes(location.id) ?  RecognitionTickIcon() : null}</div>
                  </CheckBoxContainer>
                  <span className='dept-name'>{location.location}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </CommonDeptLocContainer>
      : null}</>
  )
}

DeptLoc.propTypes = {
  departmentDetails: PropTypes.array,
  locationDetails: PropTypes.array,
  isDropdownOpen: PropTypes.bool, 
  setIsDropdownOpen: PropTypes.func
}

const mapStateToProps = (state) => ({
  departmentDetails: state.companyDashboard.companyPeopleDepartment,
  locationDetails: state.companyDashboard.companyPeopleLocation,
})

export default connect(mapStateToProps, null)((DeptLoc));