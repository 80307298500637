import React, { useState } from 'react';
import { MainContainer } from './../Recognition/RecognitionActivity/styles';
import PropTypes from "prop-types";
import {  BorderBottom, TabBottomLine, TabWrapper, /*YearlyCard*/ } from './styles';
import { ActiveLibraryTab } from './library/ActiveLibraryTab';
import { InActiveLibraryTab } from './library/InactiveLibraryTab';
import { ArchiveLibraryTab } from './library/ArchiveLibraryTab';

// const dummyData = [
//   { month: 'January', members: generateMembers() },
//   { month: 'February', members: generateMembers() },
//   { month: 'March', members: generateMembers() },
//   { month: 'April', members: generateMembers() },
//   { month: 'May', members: generateMembers() },
//   { month: 'June', members: generateMembers() },
//   { month: 'July', members: generateMembers() },
//   { month: 'August', members: generateMembers() },
//   { month: 'September', members: generateMembers() },
//   { month: 'October', members: generateMembers() },
//   { month: 'November', members: generateMembers() },
//   { month: 'December', members: generateMembers() },
// ];

// function generateMembers() {
//   return Array.from({ length: 6 }, (_, index) => ({
//     name: `Member ${index + 1}`,
//     image: `https://via.placeholder.com/150?text=Member+${index + 1}`,
//     count: Math.floor(Math.random() * 100),
//   }));
// }

const Library = ({ getColor, history }) => {
//   const [showAll, setShowAll] = useState({}); // Initialize as an empty object
  const [activeTab, setActiveTab] = useState('Active');

  //   const handleShowMore = (month) => {
  //     setShowAll((prevState) => ({
  //       ...prevState,
  //       [month]: !prevState[month], // Toggle the state for the specific month
  //     }));
  //   };

  return (
    <MainContainer margin>
      <TabWrapper>
        <TabBottomLine active={activeTab === 'Active'} background={getColor} onClick={() => setActiveTab('Active')}>
            Active
          <BorderBottom active={activeTab === 'Active'} background={getColor} />
        </TabBottomLine>
        <TabBottomLine active={activeTab === 'Inactive'} background={getColor} onClick={() => setActiveTab('Inactive')}>
            Inactive
          <BorderBottom active={activeTab === 'Inactive'} background={getColor} />
        </TabBottomLine>
        <TabBottomLine active={activeTab === 'Archive'} background={getColor} onClick={() => setActiveTab('Archive')}>
            Archive
          <BorderBottom active={activeTab === 'Archive'} background={getColor} />
        </TabBottomLine>
      </TabWrapper>
      {activeTab === "Active" ? <ActiveLibraryTab history={history}/> : null}
      {activeTab === "Inactive" ? <InActiveLibraryTab history={history}/> : null}
      {activeTab === "Archive" ? <ArchiveLibraryTab history={history}/> : null}
    </MainContainer>
  );
};

Library.propTypes = {
  getColor: PropTypes.func.isRequired,
  history:PropTypes.object.isRequired
};

export default Library;

