import { toast } from "react-toastify";

import { http } from '../../http';
import * as actionTypes from "../actionTypes";

import { getCompanyLocation, getCompanyDepartment, /*getCompanyBrandingDetails*/} from '../index';

import {
  getWorkInFreeTimeAPI, getMinutesExerciseAPI, getStandByWorkAPI, getWorkInterferesAPI, getvaluablePartAPI,
  getDecisionMakingAPI,
  getCommunicateWithTeamAPI, SetReportTabAPI, getAlcoholIntakeAPI, getBurnoutFrequencyAPI, getAddedSugarIntakeAPI,
  getFallSickLastYearAPI,
  getPainLocationAPI, getDailyAverageSleepAPI, getMedicalDiagnosisAPI, getDailyWaterIntakeAPI,
  getWellbeingAssessmentAPI, getPrescriptionDrungs, getExperiencePainAPI, getPhysicalExamAPI,
  getDoYouSmokeAPI, GetCompanyBlockDetailsAPI, GetCompanyUserCount, GetWellnessChampions, GetCompanies,
  GetTopChallenges, GetNewUsers,
  GetTopActiveUsers, GetTopFiveFitnessGoals, GetTopFiveDevices, GetTopInterests,
  GetExercisePerWeek, GetTopWellnessInitiatives,
  GetGroupActivitySummary, GetEnrolledUsers, BodyMass, BadCholesterol, GoodCholesterol, Triglycerides,
  AverageBiometricData, GetEligibleEmployee,
  GetUserBirthday, GetMinutesActiveDetails, GetSystolicBloodPressure, GetDiastolicBloodPressure, GetFastingUser,
  GetNonFastingUser, getHealthLimitingPerformance, RecognitionDetails, EmployeesOfTheMonthDetails,
  MostSocialUsersDetails, GetHurdlesToEatHealthyDetails, GetMostStressCauseDetails, GetEatFruitAndVegetableDetails,
  GetEatFoodWithAddedSugarDetails, GetFinanceStressDetails, GetMinutesRelaxDetails, GetHighlyProcessedFoodDetails,
  GetEatingHealthyDetails,GetCompanyInitiatives,AddCompanyInitiatives,
  GetRecognitionForDepartments, GetRecognitionForLocations, GetRecognitionForAllDepartments,
  GetRecognitionForAllLocations, GetRecognitionActivity, GetTopRecognizedRecognition, GetTopReceiversRecognition,  LaunchSurvey, CompanyCreate,
  GetViewMonthlyTaskIcons, AddMonthlyTask, GetAdminMonthlyTasks, GetUserTaskStatus, GetFitnessReport, GetAllWellnessAdmin, getCompanyRecognitions, ShowBiometricStatus, CompanyRewardPrograms,RewardsReport
  ,GetChallengesAndWellnessCount ,GetCompanyEducationDetails, UserLanguage,UpdateLanguage,GetTopEvents,GetUpcomingThemes, DailyTipsByCategory, GetDailyTipCount, GetDailyTipById, DeleteDailyTip, EditDailyTip, AddDailyTip, GetDailyTipData, GetDailyTipAndQuoteCount, Languages, EmployeesOfTheMonthlylyDetails, EmployeesOfTheMonthByQuarterlyDetails, GetEmployeeSeprateRecognitionMonthly, UpdateCompanyDetails, GetCompanyInfo} from "../../constants/apiConstants";
// import {getProfileCompanyDetailsById} from '../userProfileActions';  

const networkError = (error) => (dispatch) => dispatch({type: actionTypes.NETWORK_ERROR, networkError: error});

const setCompany = (id, name, companyStartDate, showBiometricStatus) => (dispatch) => {
  const year = new Date().getFullYear();
  const yearV2 = new Date().getFullYear() - 1;
  dispatch(setSelectedYear(year));
  dispatch(setSelectedYearV2(yearV2))
  dispatch({type: actionTypes.SET_COMPANY, id, name, companyStartDate, showBiometricStatus});
  const selectedCompanyJSON = localStorage.getItem('selectedCompany');
  const localCompanyInfo = JSON.parse(selectedCompanyJSON);
  dispatch(getCompanyLocation(localCompanyInfo?.id ? localCompanyInfo?.id : id, true));
  dispatch(getCompanyDepartment(localCompanyInfo?.id ? localCompanyInfo?.id: id, true));

  // dispatch(setDefaultTab());
  dispatch(fetchReportTabAction(id, year));
};

const setCompanyV2 = (id, name, companyStartDate, showBiometricStatus) => (dispatch) => {
  const year = new Date().getFullYear();
  const yearV2 = new Date().getFullYear() - 1;
  dispatch(setSelectedYear(year));
  dispatch(setSelectedYearV2(yearV2))
  dispatch({type: actionTypes.SET_COMPANY, id, name, companyStartDate, showBiometricStatus});
  dispatch(getCompanyLocation(id, true));
  dispatch(getCompanyDepartment(id, true));

  // dispatch(setDefaultTab());
  // dispatch(fetchReportTabAction(id, year));
};

const FetchEmployeeCount = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyUserCount}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.SET_ENGAGEMENT_EMPLOYEE_COUNT, count:res.count});
    } catch(error) {
      toast.error(error.message);
    }
  }
}

const getCompany = () => (dispatch) => dispatch({type: actionTypes.GET_COMPANY});

const getAllCompanies = (year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetCompanies, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_GET_COMPANIES, companiesError: res.message});
      } else {
        dispatch({type: actionTypes.GET_COMPANIES, companies: res[0]});
        const id = res[0] && res[0][0].id;
        const name = res[0] && res[0][0].company_name;
        const companyStartDate = res[0] && res[0][0].company_start_date;
        const showBiometricStatus = res[0] && res[0][0].show_biometric;
        dispatch(setCompany(id, name, companyStartDate, showBiometricStatus));
        dispatch(setSelectedYear(year));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getWellnessChampions = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetWellnessChampions}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_GET_WELLNESS_CHAMPION, wellnessChampionsError: res.message});
      } else {
        dispatch({type: actionTypes.GET_WELLNESS_CHAMPION, wellnessChampions: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getWellnessAdmin = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetAllWellnessAdmin}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_GET_WELLNESS_ADMIN, wellnessAdminError: res.message});
      } else {
        dispatch({type: actionTypes.GET_WELLNESS_ADMIN, wellnessAdmin: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getFitnessReport = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.CSV_BUTTON_DISABLED});
    try {
      const res = await http.get(`${GetFitnessReport}/${id}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message){
        toast.error(res.message);
      }else{
        dispatch({type: actionTypes.GET_FITNESS_REPORT, fitnessUsers: res});
      }
    }catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getTopChallenges = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopChallenges}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_TOP_CHALLENGES, topChallenges: res});
      }
    } catch(error) {
      // toast.error(error.message);
      dispatch(networkError(error.message));
      // window.location.reload();
    }
  }
};


const getTopEvents = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopEvents}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_TOP_EVENTS, topEvents: res});
      }
    } catch(error) {
      // toast.error(error.message)
      dispatch(networkError(error.message));
      // window.location.reload();
    }
  }
};

const getNewUsers = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetNewUsers}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_NEW_USERS, newUsers: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const  getUpcomingThemes= () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetUpcomingThemes}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_UPCOMING_THEMES, UpcomingThemes: res });
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getMostActiveUsers = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopActiveUsers}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_MOST_ACTIVE_USERS, mostActiveUsers: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      // dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getTopFiveFitnessGoals = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopFiveFitnessGoals}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_TOP_FIVE_FITNESS_GOALS, fitnessGoals: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getTopFiveDevices = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopFiveDevices}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_TOP_FIVE_DEVICES, topDevices: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getTopFitnessActivities = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopInterests}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_TOP_FITNESS_ACTIVITIES, topActivities: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getExercisePerWeek = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetExercisePerWeek}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_EXERCISE_PER_WEEK, weekExercise: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getDailyActiveMinutes = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.FITNESS_TAB_LOADING_TRUE});
    try {
      const res = await http.get(`${GetMinutesActiveDetails}/${id}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_MINUTES_ACTIVE_DETAILS, activeMinutes: res});
        dispatch({type: actionTypes.FITNESS_TAB_LOADING_FALSE});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}

const getTopWellnessInitiatives = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopWellnessInitiatives}/${id}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_TOP_WELLNESS_INITIATIVES, wellnessInitiatives: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const  getCompanyInitiatives= (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyInitiatives}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_COMPANY_INITIATIVES, companyInitiatives: res });
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const  addCompanyInitiatives= (addInitiative, id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.post(AddCompanyInitiatives,addInitiative, config);
      if (res.message) {
        toast.error(res.message);
      }
      else {
        toast.success(res[0]);
        dispatch(getTopWellnessInitiatives(id, year));
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const FetchGroupActivitySummary = (companyId, startDate, endDate) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetGroupActivitySummary}/${companyId}/${startDate}/${endDate}`, {headers: { AUTHTOKEN: AuthToken }})
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.GET_GROUP_ACTIVITY_SUMMARY, data: {steps: 0, distance: 0, calories: 0, ['average_calories']: 0, ['average_distance']: 0, ['average_steps']: 0}})
      }
      else {
        dispatch({type: actionTypes.GET_GROUP_ACTIVITY_SUMMARY, data: res})
      }
    } catch(error) {
      toast.error(error.message);
      // dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const FetchCompanyBlockDetails = (fromDate) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyBlockDetailsAPI}/${fromDate}`,{headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({ type: actionTypes.SET_COMPANY_BLOCK_DETAILS, data: res});
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const fetchDoYouSmokeDetails = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${getDoYouSmokeAPI}/${id}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({ type: actionTypes.FETCH_DO_YOU_SMOKE, smokeData: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const fetchPhysicalExamDetails = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${getPhysicalExamAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({ type: actionTypes.FETCH_PHYSICAL_EXAM, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const fetchExperiencePainDetails = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${getExperiencePainAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({ type: actionTypes.FETCH_EXPERIENCE_PAIN, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const fetchPrescriptionDrugs = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${getPrescriptionDrungs}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({ type: actionTypes.FETCH_PRESCRIPTION_DRUGS, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const fetchWellbeingAssessmentReports = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.HEALTH_TAB_LOADING});
    try {
      const res = await http.get(`${getWellbeingAssessmentAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.HEALTH_TAB_LOADING_FALSE});
        dispatch({ type: actionTypes.FETCH_WELLBEING_ASSESSMENT_REPORTS, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const fetchDailyWaterIntake = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${getDailyWaterIntakeAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({ type: actionTypes.FETCH_DAILY_WATER_INTAKE, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const fetchMedicalDiagnosis = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.HEALTH_TAB_LOADING});
    try {
      const res = await http.get(`${getMedicalDiagnosisAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.HEALTH_TAB_LOADING_FALSE});
        dispatch({ type: actionTypes.FETCH_MEDICAL_DIAGNOSIS, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const fetchDailyAverageSleep = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${getDailyAverageSleepAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({ type: actionTypes.FETCH_DAILY_AVERAGE_SLEEP, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const fetchPainLocation = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.HEALTH_TAB_LOADING});
    try {
      const res = await http.get(`${getPainLocationAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.HEALTH_TAB_LOADING_FALSE});
        dispatch({ type: actionTypes.FETCH_PAIN_LOCATION, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const fetchSickLastYear = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.HEALTH_TAB_LOADING});
    try {
      const res = await http.get(`${getFallSickLastYearAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.HEALTH_TAB_LOADING_FALSE});
        dispatch({ type: actionTypes.FETCH_SICK_LAST_YEAR, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const fetchSugarIntake = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.HEALTH_TAB_LOADING});
    try {
      const res = await http.get(`${getAddedSugarIntakeAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.HEALTH_TAB_LOADING_FALSE});
        dispatch({ type: actionTypes.FETCH_SUGAR_INTAKE, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const fetchBurnoutFrequency = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.HEALTH_TAB_LOADING});
    try {
      const res = await http.get(`${getBurnoutFrequencyAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.HEALTH_TAB_LOADING_FALSE});
        dispatch({ type: actionTypes.FETCH_BURNOUT_FREQUENCY, data: res })

      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const fetchAlcoholIntake = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.HEALTH_TAB_LOADING});
    try {
      const res = await http.get(`${getAlcoholIntakeAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.HEALTH_TAB_LOADING_FALSE});
        dispatch({ type: actionTypes.FETCH_ALCOHOL_INTAKE, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}


const fetchReportTabAction = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${SetReportTabAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({ type: actionTypes.SET_REPORT_TAB, data: res });
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  } 
}
/*wellbeing Action Creators*/
const fetchHurdlesInEatingData = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.WELLBEING_LOADING});
    try {
      const res = await http.get(`${GetHurdlesToEatHealthyDetails}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.WELLBEING_LOADING_FALSE});
        dispatch({ type: actionTypes.FETCH_HURDLE_IN_EATING, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const fetchStressCausingFactors = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.WELLBEING_LOADING});
    try {
      const res = await http.get(`${GetMostStressCauseDetails}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.WELLBEING_LOADING_FALSE});
        dispatch({ type: actionTypes.FETCH_STRESS_CAUSING_FACTORS, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const fetchFrequecyOfEatingFruit = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.WELLBEING_LOADING});
    try {
      const res = await http.get(`${GetEatFruitAndVegetableDetails}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.WELLBEING_LOADING_FALSE});
        dispatch({ type: actionTypes.FETCH_FREQUENCY_FRUIT_EATING, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const fetchEatingAndDrinkingFoodAddSugar = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.WELLBEING_LOADING});
    try {
      const res = await http.get(`${GetEatFoodWithAddedSugarDetails}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.WELLBEING_LOADING_FALSE});
        dispatch({ type: actionTypes.FETCH_EATING_DRINKING_ADDEDSUGAR, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const fetchStressAboutYourFinances = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.WELLBEING_LOADING});
    try {
      const res = await http.get(`${GetFinanceStressDetails}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.WELLBEING_LOADING_FALSE});
        dispatch({ type: actionTypes.FETCH_STRESS_ABOUT_YOUR_FINANCES, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const fetchRelaxingData = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.WELLBEING_LOADING});
    try {
      const res = await http.get(`${GetMinutesRelaxDetails}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.WELLBEING_LOADING_FALSE});
        dispatch({ type: actionTypes.FETCH_RELAXING_DATA, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const fetchHighlyProcessedFoodDetails = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.WELLBEING_LOADING});
    try {
      const res = await http.get(`${GetHighlyProcessedFoodDetails}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.WELLBEING_LOADING_FALSE});
        dispatch({ type: actionTypes.FETCH_HIGHLY_PROCESSED_FOOD_DETAILS, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const fetchEatingHealthyDetails = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.WELLBEING_LOADING});
    try {
      const res = await http.get(`${GetEatingHealthyDetails}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.WELLBEING_LOADING_FALSE});
        dispatch({ type: actionTypes.FETCH_EATING_HEALTHY_DETAILS, data: res })
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
/*wellBeing Action Creator completed*/

// const setDefaultTab = () => (dispatch) => dispatch({ type: actionTypes.SET_DEFAULT_REPORT_TAB })

const fetchReportsAPIAction = (id, year) => (dispatch) => {
  dispatch(setSelectedYear(year));
  dispatch(fetchDoYouSmokeDetails(id));
  dispatch(fetchPhysicalExamDetails(id, year));
  dispatch(fetchExperiencePainDetails(id, year));
  dispatch(fetchPrescriptionDrugs(id, year));
  dispatch(fetchWellbeingAssessmentReports(id, year));
  dispatch(fetchDailyWaterIntake(id, year));
  dispatch(fetchMedicalDiagnosis(id, year));
  dispatch(fetchDailyAverageSleep(id, year));
  dispatch(fetchPainLocation(id, year));
  dispatch(fetchSickLastYear(id, year));
  dispatch(fetchSugarIntake(id, year));
  dispatch(fetchAlcoholIntake(id, year));
}

const fetchDashBoardAPI = (id, year) => (dispatch) => {
  dispatch(setSelectedYear(year));
  dispatch(getTopChallenges(id));
  dispatch(getTopEvents(id));
  dispatch(getUpcomingThemes());
  dispatch(getNewUsers(id));
  dispatch(getTopWellnessInitiatives(id, year));
  dispatch(getCompanyInitiatives(id));
  dispatch(getEligibleEmployee(id));
  // dispatch(getUserBirthday(id));
  dispatch(FetchEmployeeCount(id));
  dispatch(getChallengesAndWellnessCount(id));
  dispatch(getCompanyEducationDetails(id, year));

}

const fetchChampionAPI = (id) => (dispatch) => {
  dispatch(getWellnessChampions(id));
}

const fetchAdminAPI = (id) => (dispatch) => {
  dispatch(getWellnessAdmin(id));
}

const fetchFitnessAPI = (id, year) => (dispatch) => {
  dispatch(setSelectedYear(year));
  dispatch(getMostActiveUsers(id));
  dispatch(getTopFiveFitnessGoals(id));
  dispatch(getTopFiveDevices(id));
  dispatch(getTopFitnessActivities(id));
  dispatch(getExercisePerWeek(id));
  dispatch(getDailyActiveMinutes(id, year));
}
const fetchCommunicateWithTeamAction = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.LIFE_STYLE_TAB_LOADING});
    try {
      const res = await http.get(`${getCommunicateWithTeamAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.LIFE_STYLE_TAB_LOADING_FALSE});
        dispatch({ type: actionTypes.SET_COMMUNICATION_TEAM, data: res });
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  } 
} 

const fetchDecisionMakingAction = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.LIFE_STYLE_TAB_LOADING});
    try {
      const res = await http.get(`${getDecisionMakingAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.LIFE_STYLE_TAB_LOADING_FALSE});
        dispatch({ type: actionTypes.SET_DECISION_MAKING, data: res });
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  } 
} 

const fetchValuablePartAction = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.LIFE_STYLE_TAB_LOADING});
    try {
      const res = await http.get(`${getvaluablePartAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.LIFE_STYLE_TAB_LOADING_FALSE});
        dispatch({ type: actionTypes.SET_VALUABLE_PART_OF_TEAM, data: res });
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  } 
} 

const fetchWorkInterferesAction = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.LIFE_STYLE_TAB_LOADING});
    try {
      const res = await http.get(`${getWorkInterferesAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.LIFE_STYLE_TAB_LOADING_FALSE});
        dispatch({ type: actionTypes.SET_WORK_INTERFERES, data: res });
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  } 
} 

const fetchStandByWorkAction = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.LIFE_STYLE_TAB_LOADING});
    try {
      const res = await http.get(`${getStandByWorkAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.LIFE_STYLE_TAB_LOADING_FALSE});
        dispatch({ type: actionTypes.SET_STAND_BY_WORK, data: res });
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  } 
} 

const fetchMinutesExercieAction = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.LIFE_STYLE_TAB_LOADING});
    try {
      const res = await http.get(`${getMinutesExerciseAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.LIFE_STYLE_TAB_LOADING_FALSE});
        dispatch({ type: actionTypes.SET_MINUTES_EXERCISE, data: res });
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  } 
} 

const fetchWorkInFreeTimeAction = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.LIFE_STYLE_TAB_LOADING});
    try {
      const res = await http.get(`${getWorkInFreeTimeAPI}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.LIFE_STYLE_TAB_LOADING_FALSE});
        dispatch({ type: actionTypes.SET_WORK_IN_FREE_TIME, data: res });
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  } 
}

const getEnrolledUsersAPI = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.CSV_BUTTON_DISABLED});
    try {
      const res = await http.get(`${GetEnrolledUsers}/${id}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res){
        dispatch({type: actionTypes.GET_ENROLLED_USERS, enrolledUsers: res});
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getEligibleEmployee = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetEligibleEmployee}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res){
        dispatch({type: actionTypes.GET_ELIGIBLE_EMPLOYEE, eligibleEmployee: res});
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getChallengesAndWellnessCount = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetChallengesAndWellnessCount}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res){
        dispatch({type: actionTypes.GET_CHALLENGES_AND_WELLNESS_COUNT, challengesWellnessCountKey: res});
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};



const getCompanyEducationDetails = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyEducationDetails}/${id}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res){
        dispatch({type: actionTypes.GET_COMPANY_EDUCATION_DETAILS, companyEducationDetailsKey: res});
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getUserBirthday = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetUserBirthday}/${id}`, {headers: {AuthToken: AuthToken}});
      if(res){
        dispatch({type: actionTypes.GET_USER_BIRTHDAY, userBirthday: res});
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getHealthLimitingPerformanceFactor = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.LIFE_STYLE_TAB_LOADING});
    try {
      const res = await http.get(`${getHealthLimitingPerformance}/${id}/${year}`, {headers: {AuthToken: AuthToken}});
      if(res){
        dispatch({type: actionTypes.LIFE_STYLE_TAB_LOADING_FALSE});
        dispatch({type: actionTypes.SET_HEALTH_LIMITING_PERFORMANCE, healthLimiting: res});
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const fetchLifeStyleTabAPI = (id, year) => (dispatch, getState) => {
  dispatch(setSelectedYear(year));
  if(getState().companyDashboard.showReportTab) {
    dispatch(fetchCommunicateWithTeamAction(id, year));
    dispatch(fetchDecisionMakingAction(id,year));
    dispatch(fetchValuablePartAction(id, year));
    dispatch(fetchWorkInterferesAction(id, year));
    dispatch(fetchStandByWorkAction(id, year));
    dispatch(fetchMinutesExercieAction(id, year));
    dispatch(fetchWorkInFreeTimeAction(id, year));
    dispatch(getHealthLimitingPerformanceFactor(id, year)),
    dispatch(fetchBurnoutFrequency(id, year));
  }
};

// const fetchTopHealthData = (id, year) => {
//   const AuthToken = localStorage.getItem('AUTH_TOKEN');
//   return async (dispatch) => {
//     dispatch({type: actionTypes.Top_Health_FETCH});
//     try {
//       const res = await http.get(`${GetTopHealthRisksAndConcerns}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
//       // window.console.log("here is the new api response",res)
//       if(!res.message) {
//         dispatch({ type: actionTypes.Top_Health_PAYLOAD, data: res });
//       }
//     }
//     catch (error) {
//       toast.error(error.message);
//       dispatch(networkError(error.message));
//     }
//   }
// };


const fetchBodyMassData = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.BODY_MASS_FETCH});
    try {
      const res = await http.get(`${BodyMass}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({ type: actionTypes.BODY_MASS_PAYLOAD, data: res, averageBodyMass:res?.average_points });
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchBadCholesterolData = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.BAD_CHOLESTEROL_FETCH});
    try {
      const res = await http.get(`${BadCholesterol}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({ type: actionTypes.BAD_CHOLESTEROL, data: res });
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchGoodCholesterolData = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.GOOD_CHOLESTEROL_FETCH});
    try {
      const res = await http.get(`${GoodCholesterol}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      window.console.log("line 1167 :- ", res);
      if(!res.message) {
        dispatch({ type: actionTypes.GOOD_CHOLESTEROL, data: res });
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchTriglyceridesData = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.TRIGLYCERIDES_PAYLOAD_FETCH});
    try {
      const res = await http.get(`${Triglycerides}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({ type: actionTypes.TRIGLYCERIDES_PAYLOAD, data: res });
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchAverageBiometricData = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.AVERAGE_BIOMETRIC_DATA_FETCH});
    try {
      const res = await http.get(`${AverageBiometricData}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({ type: actionTypes.AVERAGE_BIOMETRIC_DATA, data: res });
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchSystolicBPData = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    //dispatch({type: actionTypes.AVERAGE_BIOMETRIC_DATA_FETCH});
    try {
      const res = await http.get(`${GetSystolicBloodPressure}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({ type: actionTypes.GET_SYSTOLIC_BLO0DPRESSURE, data: res });
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchDiastolicBPData = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    //dispatch({type: actionTypes.AVERAGE_BIOMETRIC_DATA_FETCH});
    try {
      const res = await http.get(`${GetDiastolicBloodPressure}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({ type: actionTypes.GET_DIASTOLIC_BLO0DPRESSURE, data: res });
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchFastingUserData = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    //dispatch({type: actionTypes.AVERAGE_BIOMETRIC_DATA_FETCH});
    try {
      const res = await http.get(`${GetFastingUser}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({ type: actionTypes.GET_FASTING_USER, data: res });
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchNonFastingUserData = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    //dispatch({type: actionTypes.AVERAGE_BIOMETRIC_DATA_FETCH});
    try {
      const res = await http.get(`${GetNonFastingUser}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({ type: actionTypes.GET_NON_FASTING_USER, data: res });
      }
    }
    catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchBiometricTabApi = (id, year) => (dispatch) => {
  dispatch(setSelectedYear(year));
  dispatch(fetchSystolicBPData(id, year));
  dispatch(fetchDiastolicBPData(id, year));
  dispatch(fetchAverageBiometricData(id, year));
  dispatch(fetchGoodCholesterolData(id, year));
  dispatch(fetchBadCholesterolData(id, year));
  dispatch(fetchTriglyceridesData(id, year));
  dispatch(fetchBodyMassData(id, year));
  // dispatch(fetchTopHealthData(id, year));
  dispatch(fetchFastingUserData(id, year));
  dispatch(fetchNonFastingUserData(id, year));
};
const setSelectedYear = (year) => (dispatch) =>{ dispatch({type: actionTypes.SET_SELECTED_YEAR, selectedYearInfo: year}) };
const setSelectedYearV2 = (yearV2) => (dispatch) =>{ dispatch({type: actionTypes.SET_SELECTED_YEARV2, selectedYearInfoV2: yearV2}) };

/*wellBeing Action Dispatcher*/
const fetchWellbeingAPI = (id, year) => (dispatch) => {
  dispatch(setSelectedYear(year));
  dispatch(fetchHurdlesInEatingData(id, year));
  dispatch(fetchStressCausingFactors(id, year));
  dispatch(fetchFrequecyOfEatingFruit(id, year));
  dispatch(fetchEatingAndDrinkingFoodAddSugar(id, year));
  dispatch(fetchStressAboutYourFinances(id, year));
  dispatch(fetchRelaxingData(id, year));
  dispatch(fetchHighlyProcessedFoodDetails(id, year));
  dispatch(fetchEatingHealthyDetails(id, year));
};

const getRecognitionDetails = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${RecognitionDetails}/${id}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {

        dispatch({type: actionTypes.GET_RECOGNITION_DETAILS, recognitionDetails: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getEmployeesOfTheMonthDetails = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${EmployeesOfTheMonthDetails}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_EMPLOYEES_OF_THE_MONTH, employeesOfTheMonthDetails: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getMostSocialUsersDetails = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${MostSocialUsersDetails}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_MOST_SOCIAL_USERS, mostSocialUsersDetails: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getRecognitionActivityDetails = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetRecognitionActivity}/${id}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_RECOGNITION_ACTIVITY_DETAILS, getRecognitionActivityDetails: res});
      }
    } catch(error) { 
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getTopRecognizedRecognitionDetails = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try{
      const res = await http.get(`${GetTopRecognizedRecognition}/${id}/${year}`, {headers: {AUTHTOKEN: AuthToken}});
      if(res.message){
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_TOP_RECOGNIZED_RECOGNITION_DETAILS, getTopRecognizedRecognitionDetails: res});
      }
    } catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getTopReceiversRecognitionDetails = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try{
      const res = await http.get(`${GetTopReceiversRecognition}/${id}/${year}`, {headers: {AUTHTOKEN : AuthToken}});
      if(res.message){
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_TOP_RECEIVERS_RECOGNITION_DETAILS, getTopReceiversRecognitionDetails: res});
      }
    }catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getRecognitionForDepartments = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.RECOGNITION_LOADING});
    try {
      const res = await http.get(`${GetRecognitionForDepartments}/${id}/${year}`, {headers: { AUTHTOKEN: AuthToken }})
      if(res.message) {
        toast.error(res.message);
      }else{
        dispatch({type: actionTypes.GET_RECOGNITION_FOR_DEPARTMENT, getRecognitionDepartmentDetails: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getRecognitionForlocations = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.RECOGNITION_LOADING});
    try {
      const res = await http.get(`${GetRecognitionForLocations}/${id}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      }else{
        dispatch({type: actionTypes.GET_RECOGNITION_FOR_LOCATIONS, getRecognitionLocationDetails: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getRecognitionForAllDepartments = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.IS_LOADING});
    try {
      const res = await http.get(`${GetRecognitionForAllDepartments}/${id}/${year}`, {headers: { AUTHTOKEN: AuthToken}});
      if(res.message){
        toast.error(res.message);
      }else{
        dispatch({type: actionTypes.GET_RECOGNITION_FOR_ALL_DEPARTMENTS, getRecognitionForAllDepartmentsDetails: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getRecognitionForAllLocations = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.IS_LOADING});
    try {
      const res = await http.get(`${GetRecognitionForAllLocations}/${id}/${year}`, {headers: { AUTHTOKEN: AuthToken}});
      if(res.message){
        toast.error(res.message);
      }else{
        dispatch({type: actionTypes.GET_RECOGNITION_FOR_ALL_LOCATIONS, getRecognitionForAllLocationsDetails: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getEmployeesOfTheMonthlylyDetails = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${EmployeesOfTheMonthlylyDetails}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_EMPLOYEES_OF_THE_MONTHLYLY, employeesOfTheMonthlylyDetails: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getEmployeesOfTheMonthByQuarterlyDetails = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${EmployeesOfTheMonthByQuarterlyDetails}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_EMPLOYEES_OF_THE_QUARTER, employeesOfTheMonthByQuarterlyDetails: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchRecognitionTabApi = (id, year) => (dispatch) =>{
  dispatch(getRecognitionDetails(id, year));
  dispatch(getEmployeesOfTheMonthDetails(id));
  dispatch(getEmployeesOfTheMonthlylyDetails(id));
  dispatch(getEmployeesOfTheMonthByQuarterlyDetails(id));
  dispatch(getMostSocialUsersDetails(id));
  dispatch(getRecognitionActivityDetails(id, year));
  dispatch(getTopRecognizedRecognitionDetails(id, year));
  dispatch(getTopReceiversRecognitionDetails(id, year));
  dispatch(GetCompanyRecognitions(id, year));
  dispatch(getRecognitionForDepartments(id, year));
  dispatch(getRecognitionForlocations(id, year));
};
const launchSurveyByType = (surveyDetails) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.post(LaunchSurvey, surveyDetails, config);
      if (res.message) {
        toast.error(res.message);
      }
      else {
        toast.success(res[0]);
        window.location.replace('/company/engagement');
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}
const getViewMonthlyTaskIcons = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetViewMonthlyTaskIcons, {headers: { AUTHTOKEN: AuthToken}});
      if(res.message){
        toast.error(res.message);
      }else{
        dispatch({type: actionTypes.GET_VIEW_MONTHLY_TASK_ICONS, monthlyTaskIcons: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const addMonthlyTask = (taskDetails, month, year, companyId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.post(AddMonthlyTask, taskDetails, config);
      if (res.message) {
        toast.error(res.message);
      }
      else {
        toast.success(res[0]);
        dispatch(getAdminMonthlyTasks(month, year, companyId));
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}
const getAdminMonthlyTasks = (month, year, companyId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.IS_LOADING});
    try {
      const res = await http.get(`${GetAdminMonthlyTasks}/${month}/${year}/${companyId}`, {headers: { AUTHTOKEN: AuthToken}});
      if(res.message){
        toast.error(res.message);
      }else{
        dispatch({type: actionTypes.GET_ADMIN_MONTHLY_TASKS, adminMonthlyTasks: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getUserTaskStatus = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.IS_LOADING});
    try {
      const res = await http.get(`${GetUserTaskStatus}/${id}`, {headers: { AUTHTOKEN: AuthToken}});
      if(res.task_status === 1){
        toast.error(res.message);
        dispatch({type: actionTypes.GET_USER_TASK_STATUS, deleteTaskStatus: res});
      }else{
        dispatch({type: actionTypes.GET_USER_TASK_STATUS, deleteTaskStatus: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const showBiometricStatus = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try{
      const res = await http.get(`${ShowBiometricStatus}/${id}/${year}`, {headers: {AUTHTOKEN: AuthToken}});
      if(!res.message) {
        dispatch({ type: actionTypes.SHOW_BIOMETRIC_STATUS, showBiometricStatus: res });
      }
    }catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const GetCompanyRecognitions = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${getCompanyRecognitions}/${id}/${year}`, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        dispatch({type: actionTypes.GET_COMPANY_RECOGNITIONS, getCompanyRecognitions: res});
      }
    }catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const createCompany = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.post(CompanyCreate, data, config);
      if (res.message) {
        toast.error(res.message);
      }
      else {
        toast.success(res[0]);
        window.location.replace('/company/admin');
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}

const getCompanyRewardPrograms = (id ,selectedYear) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.get(`${CompanyRewardPrograms}/${id}/${selectedYear}`, config);
      if(res.message){
        toast.error(res.message);
      }else{
        dispatch({type: actionTypes.GET_COMAPANY_REWARD_PROGRAMS, companyRewardPrograms: res});
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const getRewardsReport = (typeId, companyId, selectedYear) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.get(`${RewardsReport}/${typeId}/${companyId}/${selectedYear}`, config);
      if(res.message){
        toast.error(res.message);
      }else{
        dispatch({type: actionTypes.GET_REWARDSREPORT_REPORT, rewardsReport: res});
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}
const  getUserLanguage= () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(UserLanguage, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_USER_LANGUAGE, userLanguage: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const  putUpdateLanguage = (obj) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.put(UpdateLanguage,obj,{headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        // dispatch( getUserLanguage());
        window.location.reload();
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchDailyTipByCategory = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(DailyTipsByCategory, data, {headers: { AUTHTOKEN: AuthToken }});
   
      if(res.message) {
        toast.error(res.message);
      }else{
        dispatch({ type: actionTypes.GET_TIP_BY_CATEGORY, dailyTipData: res});
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const fetchTipCategoryCount = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetDailyTipCount, {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.TIP_CATEGORY_COUNT, tipCategoryCount: res});
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const fetchDailyTipById = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetDailyTipById}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.GET_TIP_BY_ID, dailytipData: res[0]});
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const deleteDailyTip = (id, obj) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.delete(`${DeleteDailyTip}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch(fetchDailyTipByCategory(obj));
        dispatch(fetchTodayDailyTip());
        dispatch(fetchTipCategoryCount());
        toast.success(res[0]);
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const  editDailyTipAPI = (obj, data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.put(EditDailyTip,obj,{headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch(fetchDailyTipByCategory(data));
        dispatch(fetchTodayDailyTip());
        toast.success(res[0]);
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const addDailyTipAPI = (obj, data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.post(AddDailyTip,obj, config);
      if (res.message) {
        toast.error(res.message);
      }
      else {
        dispatch(fetchDailyTipByCategory(data));
        dispatch(fetchTipCategoryCount());
        toast.success(res[0]);
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchTodayDailyTip = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetDailyTipData, {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.GET_TODAY_TIP, todayTipData: res});
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const fetchDailyTipAndQuoteCount = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetDailyTipAndQuoteCount}/${id}/${year}` , {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.TIP_QUOTE_COUNT, tipQuoteCount: res});
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const fetchLanguages = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(Languages , {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.GET_LANGUAGES, getLanguages: res});
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const fetchEmployeeSeprateRecognitionMonthly = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetEmployeeSeprateRecognitionMonthly}/${id}/${year}` , {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.GET_RECOGNITION_MONTHLY, monthlyRecognitionData: res});
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const  updateCompanyInfo = (obj) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.put(UpdateCompanyDetails,obj, config);
      if (res.message) {
        toast.error(res.message);
        // dispatch(getCompanyBrandingDetails(obj.id));
        // dispatch(getProfileCompanyDetailsById(obj.id));
        dispatch(fetchCompanyInfo(obj.id));
        // window.location.reload();
      }
      else {
        toast.success(res[0]);
        dispatch({ type: actionTypes.COMPANY_INFO_UPDATE, getLanguages: res});
        // dispatch(getCompanyBrandingDetails(obj.id));
        // dispatch(getProfileCompanyDetailsById(obj.id));
        dispatch(fetchCompanyInfo(obj.id));
        // window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
};

const fetchCompanyInfo = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyInfo}/${id}` , {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.SET_COMPANY_LOGO, logoDetails: res.companyInfo?.[0] });
      dispatch({ type: actionTypes.GET_COMPANY_INFORMATION, companyInfoData: res});
    } catch(error) {
      toast.error(error.message);
    }
  }
};

export { fetchLifeStyleTabAPI, fetchDashBoardAPI, fetchReportsAPIAction, fetchReportTabAction, fetchAlcoholIntake, 
  fetchBurnoutFrequency, fetchSugarIntake, fetchSickLastYear, fetchPainLocation, fetchDailyAverageSleep, fetchMedicalDiagnosis, 
  fetchDailyWaterIntake, fetchWellbeingAssessmentReports, fetchPrescriptionDrugs, fetchExperiencePainDetails, fetchPhysicalExamDetails, 
  fetchDoYouSmokeDetails, FetchCompanyBlockDetails, FetchGroupActivitySummary, getWellnessChampions, getAllCompanies, setCompany, 
  getTopChallenges, getCompany, getEnrolledUsersAPI, fetchBiometricTabApi, getEligibleEmployee, getUserBirthday, fetchFitnessAPI,
  fetchChampionAPI,fetchRecognitionTabApi, fetchWellbeingAPI,  getRecognitionForAllDepartments, getRecognitionForAllLocations,
  setSelectedYear, launchSurveyByType, getViewMonthlyTaskIcons, addMonthlyTask, getAdminMonthlyTasks, getUserTaskStatus, getFitnessReport, 
  getWellnessAdmin, fetchAdminAPI, showBiometricStatus, createCompany,getTopEvents,getUpcomingThemes,addCompanyInitiatives,getCompanyRewardPrograms,getCompanyInitiatives,getRewardsReport,getChallengesAndWellnessCount,getCompanyEducationDetails, getUserLanguage,putUpdateLanguage, fetchDailyTipByCategory, fetchTipCategoryCount, fetchDailyTipById, deleteDailyTip, editDailyTipAPI, addDailyTipAPI, fetchTodayDailyTip, fetchDailyTipAndQuoteCount, getRecognitionDetails, getRecognitionActivityDetails, fetchLanguages, FetchEmployeeCount, setSelectedYearV2, getTopWellnessInitiatives, setCompanyV2, fetchEmployeeSeprateRecognitionMonthly, updateCompanyInfo, fetchCompanyInfo};