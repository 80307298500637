/* eslint-disable react/no-unknown-property,no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { SidebarGrid, HamBurgerContainer, MainSideBarLinks, Navigation, StyledLink, StyledSvg, SidebarCard } from './style';
import { uploadProfile, getRewardPoints, getCompanyBrandingDetails } from '../../redux/actions';
import Loading from '../Loading';
import { withTranslation } from 'react-i18next';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import Waiting from '../Waiting';
import { HamBurgerContainer, MainSideBarLinks, Navigation, SidebarCard, SidebarGrid, StyledLink, StyledSvg } from '../SideBarV3/style';
import { ManagerFeedIcon, ManagerHistoryIcon, ManagerRecognitionIcon } from '../../utils/icons';

const managerNavigationArray = [
  {
    name: 'Recognition',
    id: 'company-dashboard-nav',
    active: 'dashboard',
    route: '/manager/dashboard',
    // width: '12px',
    // height: '12px',
    svg: ManagerRecognitionIcon(),
    value: "recognition"
  },
  {
    name: 'Social Feed',
    id: 'company-dashboard-nav1',
    active: 'social',
    route: '/manager/social-feed',
    // width: '18px',
    // height: '20px',
    svg: ManagerFeedIcon(),
    value: "feed",
  },
  {
    name: 'History',
    id: 'company-dashboard-nav2',
    active: 'history',
    route: '/manager/history',
    // width: '18px',
    // height: '20px',
    value: "history",
    svg: ManagerHistoryIcon(),
  },
];



class SideBarV4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: 'Training Amigo',
      totalPoints: 80,
      showSideBarMenu: false,
      imgSrc: '',
      hide: false,
      colorInfo: this.props.colorInfo2,
      hoverState: null,
      hoverStateAdmin: null
    };
  }

  getSideTabStatus = (route) => {
    window.console.log("route",route)
    let findIndex = managerNavigationArray.findIndex((data) => data.route === route);
    return findIndex === -1;
  }
 
  showSideBarMenuOptions = () => {
    this.setState((prev) => ({
      showSideBarMenu: !prev.showSideBarMenu
    }))
  };

  renderNavigation = (currentLocation, getColor, hideContent, colorInfo) => (
    <div>
      {
        this.renderCompanyNavigation(currentLocation, getColor, hideContent, colorInfo) 
      }
    </div>
  );

  changeRoute = (value) => {
    const { history } = this.props;
    this.setState({
      showSideBarMenu: false
    });
    history.push(value);
  };


  renderCompanyNavigation = (CurrentLocation, getColor, hideContent) => (
    <Navigation>
      {managerNavigationArray.map((navigator, index) => (
        // navigator.condition ?
        (<li id={navigator.id} key={index}>
          <StyledLink commonDashboard onClick={() => this.changeRoute(navigator.route)} onMouseEnter={() => this.showCardAdmin(navigator.value)} onMouseLeave={() => this.hideCardAdmin()}
            active={CurrentLocation.includes(navigator.active) ? 1 : 0}
            bgColor={"rgba(0, 171, 182, 1)"}
            colour={ 'white'} hideContent={hideContent ? 1 : 0}
            width={"0px"} bottom={"0px"} left={"100px"} color={getColor ? getColor : "#0D4270"}
          >
            <span className="iconLinkContainer">
             
              <StyledSvg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                {navigator.svg}
              </StyledSvg>
              {hideContent ? null : <span>{navigator.name}</span>}
            </span>
            {hideContent && (this.state.hoverStateAdmin === navigator.value) ? <SidebarCard  width="0px" bottom="0px" left="100px" commonDashboard colour={getColor ? "getColor" : "#00ABB6"}><div className="arrow">...</div><div className="name">{this.props.t(navigator.name)}</div></SidebarCard> : null}
            <div dashboard={1} />
          </StyledLink>
        </li>
        )
        //   :
        //   (
        //     <li id={navigator.id} key={index}>
        //       <StyledLink commonDashboard onClick={() => this.changeRoute(navigator.route)} onMouseEnter={() => this.showCardAdmin(navigator.value)} onMouseLeave={() => this.hideCardAdmin()}
        //         active={CurrentLocation.includes(navigator.active) ? 1 : 0}
        //         bgColor={getColor ? this.hexToRGBA(getColor, 0.1) : ''}
        //         colour={getColor ? getColor : "#0b4170"} hideContent={hideContent ? 1 : 0}>
        //         <span className="iconLinkContainer">
        //           {
        //             navigator.name === 'Admin Panel' ?
        //               <svg className="sc-iQtOjA dGlpBQ" width="22" height="22" viewBox="0 0 16 18" fill="none">
        //                 <path
        //                   d="M13.6875 17H11.5C10.7751 17 10.1875 16.4344 10.1875 15.7367V11.9469H5.8125V15.7367C5.8125 16.4344 5.22488 17 4.5 17H2.31251C1.58763 17 1.00001 16.4344 1.00001 15.7367V7.64763C0.998858 7.3276 1.12395 7.01909 1.35001 6.7844L7.7025 1.11234C7.8703 0.962553 8.1297 0.962553 8.2975 1.11234L14.6281 6.76334C14.6353 6.76482 14.6428 6.76482 14.65 6.76334C15 7 15.0011 7.30655 15 7.62658V15.7367C15 16.4344 14.4124 17 13.6875 17Z"
        //                   stroke="#9C9C9C" />
        //               </svg>
        //               :
        //               navigator.name === 'Social Feed' ?
        //                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        //                   <path d="M15 4.43C15 4.63 15.12 4.82 15.31 4.9L17.97 6.02L19.04 8.68C19.12 8.87 19.3 8.99 19.5 8.99C19.7 8.99 19.89 8.87 19.96 8.68L21.02 6.02L23.68 4.96C23.87 4.88 23.99 4.7 23.99 4.5C23.99 4.3 23.87 4.11 23.68 4.04L21.02 2.98L19.96 0.32C19.81 -0.06 19.18 -0.06 19.03 0.32L17.97 2.97L15.32 3.97C15.13 4.04 15 4.22 15 4.43ZM18.53 3.83C18.66 3.78 18.76 3.68 18.82 3.55L19.5 1.85L20.18 3.55C20.23 3.68 20.33 3.78 20.46 3.83L22.16 4.51L20.46 5.19C20.33 5.24 20.23 5.34 20.18 5.47L19.5 7.17L18.82 5.47C18.77 5.35 18.67 5.25 18.55 5.19L16.84 4.47L18.53 3.83ZM14.51 9.49L13.98 7.38C13.92 7.16 13.72 7 13.5 7C13.28 7 13.07 7.16 13.02 7.38L12.49 9.48L10.39 9.96C10.17 10.01 10.01 10.21 10 10.44C10 10.67 10.15 10.87 10.37 10.93L12.49 11.5L13.02 13.61C13.08 13.83 13.28 13.99 13.5 13.99C13.72 13.99 13.93 13.83 13.98 13.61L14.51 11.5L16.62 10.97C16.84 10.91 17 10.71 17 10.49C17 10.27 16.84 10.06 16.62 10.01L14.51 9.48V9.49ZM13.98 10.62C13.8 10.66 13.66 10.8 13.62 10.98L13.5 11.44L13.38 10.98C13.34 10.8 13.2 10.67 13.03 10.62L12.56 10.49L12.97 10.4C13.15 10.36 13.35 10.17 13.39 9.98L13.49 9.56L13.61 10.02C13.65 10.2 13.79 10.34 13.97 10.38L14.43 10.5L13.97 10.62H13.98ZM23.5 8C23.22 8 23 8.22 23 8.5V19.5C23 19.59 23 19.69 22.99 19.78L20.09 16.88C18.94 15.73 17.07 15.73 15.92 16.88L15.34 17.46C14.6 18.2 13.4 18.2 12.66 17.46L7.08 11.88C5.93 10.73 4.06 10.73 2.91 11.88L0.99 13.8V4.5C0.99 2.57 2.56 1 4.49 1H15.5C15.78 1 16 0.78 16 0.5C16 0.22 15.78 0 15.5 0H4.5C2.02 0 0 2.02 0 4.5V19.5C0 21.98 2.02 24 4.5 24H19.5C21.98 24 24 21.98 24 19.5V8.5C24 8.22 23.78 8 23.5 8ZM19.5 23H4.5C2.57 23 1 21.43 1 19.5V15.21L3.62 12.59C4.38 11.83 5.62 11.83 6.37 12.59L11.95 18.17C13.08 19.3 14.91 19.3 16.04 18.17L16.62 17.59C17.38 16.83 18.62 16.83 19.37 17.59L22.7 20.92C22.16 22.15 20.93 23.01 19.5 23.01V23ZM7 5C7.55 5 8 5.45 8 6C8 6.55 7.55 7 7 7C6.45 7 6 6.55 6 6C6 5.45 6.45 5 7 5Z" fill="#00ABB6"/>
        //                 </svg>
        //                 : null
        //           }
        //           {!hideContent ? <span
        //             className={navigator.name === 'People' || navigator.name === 'Wellbeing' ? 'companyDashboardLabel' : ""}>{navigator.name}</span> : null}
        //           {hideContent && (this.state.hoverStateAdmin === navigator.value) ? <SidebarCard width={"0px"} bottom={"0px"} left={"100px"} colour={getColor ? getColor : "#0D4270"} commonDashboard><div className="arrow">...</div><div className="name">{this.props.t(navigator.name)}</div></SidebarCard> : null}
                  
        //         </span>
        //         <div dashboard={1} />
        //       </StyledLink>
        //     </li>
          
      ))}
    </Navigation>
  );


  hexToRGBA = (hex, alpha) => {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  showCard = (value) => {
    this.setState({
      hoverState: value
    });
  }

  showCardAdmin = (value) => {
    this.setState({
      hoverStateAdmin: value
    });
  }

  hideCard = () => {
    this.setState({
      hoverState: null
    });
  }

  hideCardAdmin = () => {
    this.setState({
      hoverStateAdmin: null
    });
  }

 
  render() {
    const { response, networkError, pathValue, reward, hideContent, colorInfo2 } = this.props;
    if ((!response || !reward) && !networkError) {
      return <Loading />
    }
    if (isUndefined(colorInfo2) || isNull(colorInfo2)) {
      return <Waiting />
    }
    const { showSideBarMenu } = this.state;
    const CurrentLocation = pathValue;
    window.console.log("pathvalue",pathValue)
    const Visibility = hideContent;
    // const trainingCompleted = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    let navIndex =  -1;
    // let getColor = navIndex !== -1 && colorInfo?.color_branding[navIndex]?.color_code;
    let getColor = navIndex !== -1 && '';

    return (
      <SidebarGrid manager color={getColor || 'rgb(11, 65, 112)'}  style= {{backgroundColor:"white"}}  hideContent={hideContent ? 1 : 0}>
        <HamBurgerContainer  onClick={this.showSideBarMenuOptions} showCompany={CurrentLocation.includes('manager')}>
          <i className="fa fa-bars" />
        </HamBurgerContainer>
        <MainSideBarLinks smallDevices>
          {showSideBarMenu && this.renderNavigation(CurrentLocation, getColor, Visibility, colorInfo2)}
        </MainSideBarLinks>
        <MainSideBarLinks>
          {this.renderNavigation(CurrentLocation, getColor, Visibility, colorInfo2)}
        </MainSideBarLinks>
      </SidebarGrid>
    );
  }
}

SideBarV4.propTypes = {
  history: PropTypes.object.isRequired,
  response: PropTypes.object,
  networkError: PropTypes.string,
  uploadProfile: PropTypes.func.isRequired,
  pathValue: PropTypes.string.isRequired,
  fetchRewardPoints: PropTypes.func.isRequired,
  reward: PropTypes.object,
  showReportTab: PropTypes.bool,
  role: PropTypes.string,
  userPermissions: PropTypes.array,
  colorData: PropTypes.object,
  hideContent: PropTypes.bool,
  hideTab: PropTypes.object,
  colorInformation: PropTypes.object,
  fetchCompanyBrandingDetails: PropTypes.func.isRequired,
  currentCompanyId: PropTypes.number,
  colorInfo2: PropTypes.object,
  t: PropTypes.func,
  billingTabStatus: PropTypes.number,
  userCompany: PropTypes.object
};

const mapStateToProps = (state) => ({
  reward: state.wellnessDashboard.reward,
  showReportTab: state.companyDashboard.showReportTab,
  colorInfo2: state.wellnessDashboard.companyBrandingDetails,
  billingTabStatus: state.stripeData.billingTabStatus,
  hideTab: state.wellnessDashboard.companyBrandingDetails,
  userCompany: state.profileData.userCompany,
 
});

const mapDispatchToProps = (dispatch) => ({
  uploadProfile: (profile) => dispatch(uploadProfile(profile)),
  fetchRewardPoints: () => dispatch(getRewardPoints()),
  fetchCompanyBrandingDetails: (id) => dispatch(getCompanyBrandingDetails(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SideBarV4));
