/* eslint-disable no-console,no-undef, camelcase, no-useless-escape */
import React, {Component} from 'react';
import { MainSurveyContainer, SurveySideMenu, SurveyTitle, SurveyQuestionsContainer, CategoriesContainer,QuestionContainer, RenderQuestionContainer, OptionContainer, CheckMarkContainer, CheckMark, SubmitSurvey, RenderSurveyQuestions, ButtonContainer, TextAreaContainer} from './styles';
import {submitSurvey} from '../../redux/actions';
import {connect} from 'react-redux';
import Waiting from '../Waiting';
import PropTypes from 'prop-types';
import {emptySurveyQuestion} from '../../redux/actions/surveyActions';
import { toast } from 'react-toastify';
import { isNull } from 'lodash';
import CommonTextArea from '../common/CommonTextArea/CommonTextArea';
class UserSurvey extends Component{
  constructor(props) {
    super(props);
    this.state={
      selectedOption: [],
      setCategoryName: '',
      surveyQuestions: null,
      categoryQuestions: null,
      setCategoryIndex: 0,
      cultureSurveyCategory: [],
      disableCategories: [],
      sideMenu: [],
      totalQuestions: 0,
      questionIndex: 0
    }
  }

  componentDidMount() {
    const {history, surveyQuestions} = this.props;
    if(!surveyQuestions){history.push('/portal')}
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {surveyQuestions, hasSections} = nextProps;
    let arr = [];
    let sideMenuDetails = [];
    let surveyCategory = [];
    if(surveyQuestions) {
      if(hasSections){
        const disableCategories = new Array(this.props.surveyQuestions.length).fill(0);
        surveyQuestions.map((category, index)=>{
          surveyQuestions[index]['data'].map((question) => {
            let obj={};
            obj['question_id'] = question.id;
            obj['answer_id'] = question.question_type === 4 ? null : '';
            obj['description'] = question.question_type === 4 ? '' : null;
            arr.push(obj);
          });
          sideMenuDetails.push({categoryName: category.title, noOfQuestions: category.data.length})
          surveyCategory.push(category.title);
        });
        this.setState({selectedOption : arr, cultureSurveyCategory: surveyCategory, disableCategories: disableCategories, setCategoryName: sideMenuDetails[0]['categoryName'], sideMenu: sideMenuDetails, totalQuestions: arr.length}, () => this.disabled())
      } else {
        surveyQuestions.map((question) => {
          let obj={};
          obj['question_id'] = question.id;
          obj['answer_id'] = question.question_type === 4 ? null : '';
          obj['description'] = question.question_type === 4 ? '' : null;
          arr.push(obj)
        })
        this.setState({selectedOption : arr, totalQuestions: arr.length})
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.setCategoryIndex !== this.state.setCategoryIndex){
      if (typeof(this.takeSurvey) !== 'undefined' && this.takeSurvey !== null) {
        setTimeout(() => {
          this.takeSurvey && this.takeSurvey.scrollIntoView({block: 'start', behavior: 'smooth'});
        }, 100);
      }
    }
    const {history, surveyQuestions} = this.props;
    if(!surveyQuestions){history.push('/portal')}
  }

  renderSurveySideMenu = (surveyQuestions) => {
    const {setCategoryIndex, sideMenu, disableCategories} = this.state;
    return sideMenu.map((surveyCategory,index) =>
      <CategoriesContainer id={`survey-category-${index}`} active={setCategoryIndex === index} disabled={disableCategories[index]} key={index} onClick={()=>{this.setState({setCategoryIndex : index, setCategoryName: surveyCategory.categoryName}, ()=>{this.renderQuestions(surveyQuestions)})}}>
        <div>
          <div>
            <span>{surveyCategory.categoryName.toLowerCase()}</span>
          </div>
          <div>
            {`${surveyCategory.noOfQuestions} ${surveyCategory.noOfQuestions > 1 ? "questions" : "question"}`}
          </div>
        </div>
      </CategoriesContainer>
    )
  };

  selectOptions = (questionIndex, optionIndex ) => {
    let duplicateQuestion = this.state.selectedOption;
    const index = duplicateQuestion.findIndex((questionId) => questionId.question_id === questionIndex);
    duplicateQuestion[index]['answer_id'] = optionIndex;
    this.setState({selectedOption: duplicateQuestion})
  };

  getSelectedOptionBackground = (option) => {
    switch(option.toString().toLowerCase()){
    case "strongly disagree" : {
      return '#C85738'
    }
    case "disagree" : {
      return '#D5743C'
    }
    case "neutral" : {
      return '#DE9444'
    }
    case "agree": {
      return '#94BF3E'
    }
    case "strongly agree": {
      return '#589622'
    }
    case "no":{
      return '#C85738'
    }
    case "yes": {
      return '#589622'
    }
    default: break;
    }

  };

  renderOptions = (options, questionIndex) => {
    const {selectedOption} = this.state;
    return options.map((item, index) => (<OptionContainer
      id={`question-${questionIndex}-option-${index}`}
      key={`${index}-${item.id}`}
      onClick={() => this.selectOptions(questionIndex, item.id)}
      isActive={selectedOption && selectedOption.length > 1 && (selectedOption.some((option) => option['answer_id'] === item.id))}
      bgColor={this.getSelectedOptionBackground(item.answer)}
    >
      <div>
        <CheckMarkContainer>
          <CheckMark bgColor={this.getSelectedOptionBackground(item.answer)}/>
        </CheckMarkContainer>
      </div>
      <div>{item.answer}</div>
    </OptionContainer>
    ))
  };
  onChangeInput = (e, questionIndex) => {
    let duplicateQuestion = this.state.selectedOption;
    const index = duplicateQuestion.findIndex((questionId) => questionId.question_id === questionIndex);
    duplicateQuestion[index]['description'] = e.target.value;
    this.setState({selectedOption: duplicateQuestion})
  }
  showTextArea = (questionIndex) => {
    const {selectedOption} = this.state;
    let duplicateQuestion = selectedOption;
    const index = duplicateQuestion.length && duplicateQuestion.findIndex((questionId) => questionId.question_id === questionIndex);
    return <TextAreaContainer>
      <CommonTextArea
        placeholder="Write your description here..."
        rows="4"
        name="description"
        value={selectedOption.length && selectedOption[index].description || ''}
        onChange={(e) => this.onChangeInput(e, questionIndex)}
        data-gramm_editor="false"
        type="text"
      />
    </TextAreaContainer>
  }
  renderQuestions = (questionList) => {
    const{hasSections} = this.props;
    const categoryQuestions = hasSections ? questionList[this.state.setCategoryIndex].data : [questionList[this.state.questionIndex]];
    return categoryQuestions.map((data, index) => (
      <RenderSurveyQuestions id={`survey-question-${data.id}`} key={data.id} width={hasSections}>
        <RenderQuestionContainer width={hasSections}>
          <QuestionContainer width={hasSections}>
            <div>{`Question ${hasSections ? index+1 : this.state.questionIndex + 1 }`}</div>
            <div>{data.question}</div>
          </QuestionContainer>
          {data.question_type !== 4 ? this.renderOptions(data.options, data.id) : this.showTextArea(data.id)}
        </RenderQuestionContainer>
      </RenderSurveyQuestions>
    )
    )
  };

  disabled = () => {
    const{setCategoryIndex, disableCategories} = this.state;
    let disabledCategory = disableCategories;
    disabledCategory[setCategoryIndex] = 0;
    disabledCategory.map((data, index)=>{
      if(index > setCategoryIndex){
        disabledCategory[index] = 1;
      }
    });
    this.setState({disableCategories: disabledCategory})
  };

  onNext = () => {
    const{cultureSurveyCategory} = this.state;
    const {surveyQuestions} = this.props;
    let currentCategoryIndex = this.state.setCategoryIndex + 1;
    let categoryName = cultureSurveyCategory[currentCategoryIndex];
    this.setState({setCategoryName : categoryName, setCategoryIndex: currentCategoryIndex }, ()=>{
      this.renderQuestions(surveyQuestions);
      this.disabled();
    })
  };

  onSubmit = () => {
    const {selectedOption} = this.state;
    const {submitSurvey, launchSurveyDetails, history, emptySurveyQuestion, user, staticSurveyStatus} = this.props;
    let obj = {};
    obj['survey_id'] = launchSurveyDetails.id;
    obj['data'] = selectedOption;
    if(user.company_id === 18 && staticSurveyStatus === 1) {
      toast.success('Survey submitted successfully');
      history.push('/portal');
    }else{
      submitSurvey( obj, history );
    }
    emptySurveyQuestion();
  };
  onBack = () => {
    const{cultureSurveyCategory} = this.state;
    const {surveyQuestions} = this.props;
    let currentCategoryIndex = this.state.setCategoryIndex - 1;
    let categoryName = cultureSurveyCategory[currentCategoryIndex];
    this.setState({setCategoryName : categoryName, setCategoryIndex: currentCategoryIndex }, ()=>{
      this.renderQuestions(surveyQuestions);
    })
  };
  onBackNext = (action) => {
    const {surveyQuestions} = this.props;
    const{questionIndex} = this.state;
    this.setState({questionIndex: action ? questionIndex+1 : questionIndex-1}, ()=> {this.renderQuestions(surveyQuestions);});
  };

  render() {
    const{selectedOption, setCategoryIndex, totalQuestions, questionIndex} = this.state;
    const {surveyQuestions, hasSections, isLoading, launchSurveyDetails} = this.props;
    if(isLoading) {
      return <Waiting />
    }
    let isDisabled = true;
    const ButtonLabel = surveyQuestions && (hasSections ? this.state.setCategoryIndex === surveyQuestions.length-1 : questionIndex === surveyQuestions.length-1) ? 'Submit' : 'Continue';
    let selectedQuestion = selectedOption && selectedOption.length > 1 && selectedOption.filter((item)=>item.answer_id !== '');
    if(!hasSections && selectedOption.length){isDisabled = selectedOption.length && (selectedOption[questionIndex]['answer_id'] === '' || (!isNull(selectedOption[questionIndex]['description']) && selectedOption[questionIndex]['description'].trim() === '')) ? true : false;}
    if(hasSections && surveyQuestions && (this.state.setCategoryIndex === surveyQuestions.length-1) && selectedQuestion && (selectedQuestion.length  < totalQuestions)) {
      isDisabled = true;
    }
    let isSectionCompleted = hasSections ?  surveyQuestions && surveyQuestions[setCategoryIndex].data.map((item)=>{
      for(let index= 0; index < selectedOption.length ; index++) {
        if(selectedOption && selectedOption.length > 0 &&  selectedOption[index]['question_id'] == item.id && (selectedOption[index]['answer_id'] == '' || (!isNull(selectedOption[questionIndex]['description']) && selectedOption[index]['description'].trim() == ''))){
          return false
        }
      }
      return true
    }) : null;
    if(isSectionCompleted && !isSectionCompleted.includes(false)) {
      isDisabled = false;
    }
    return (
      <div ref={(takeSurvey) => this.takeSurvey = takeSurvey} id="user-survey-container">
        <SurveyTitle id="survey-title">
          <div id="survey-name">{`${launchSurveyDetails['survey_name']}`.toLowerCase()}</div>
          <div id="survey-label">Survey</div>
        </SurveyTitle>
        <MainSurveyContainer id="main-survey-container">
          {hasSections ?<SurveySideMenu id="survey-side-menu">
            { this.renderSurveySideMenu(surveyQuestions)}
          </SurveySideMenu>: null}
          <SurveyQuestionsContainer width={hasSections} id="survey-questions-container">
            {surveyQuestions && surveyQuestions.length ? this.renderQuestions(surveyQuestions) : null}
            <ButtonContainer justify={(setCategoryIndex || questionIndex) === 0} margin={hasSections} id="survey-button-container">
              {(setCategoryIndex !== 0 || questionIndex !== 0)? <SubmitSurvey id="survey-back-button" onClick={hasSections ? this.onBack : () => this.onBackNext(0)}>Back</SubmitSurvey> : null}
              <SubmitSurvey id="survey-submit-button" disabled={isDisabled} onClick={surveyQuestions && (hasSections ? this.state.setCategoryIndex === surveyQuestions.length-1 : questionIndex === surveyQuestions.length-1) ? this.onSubmit : hasSections ? this.onNext : () => this.onBackNext(1)}>{ButtonLabel}</SubmitSurvey>
            </ButtonContainer>
          </SurveyQuestionsContainer>
        </MainSurveyContainer>
      </div>
    );
  }
}

UserSurvey.propTypes = {
  submitSurvey: PropTypes.func,
  surveyQuestions: PropTypes.array,
  choices: PropTypes.array,
  hasSections: PropTypes.number,
  history: PropTypes.object,
  isLoading: PropTypes.bool,
  launchSurveyDetails: PropTypes.object,
  emptySurveyQuestion:PropTypes.func,
  user: PropTypes.object,
  staticSurveyStatus: PropTypes.number
};

const mapStateToProps = (state) => ({
  surveyQuestions: state.surveys.surveyQuestions,
  hasSections: state.surveys.hasSections,
  launchSurveyDetails: state.surveys.LaunchSurveyDetails,
  isLoading: state.surveys.isLoading,
  user: state.profileData.user,
  staticSurveyStatus:  state.surveys.staticSurveyStatus
});

const mapDispatchToProps = (dispatch) => ({
  submitSurvey: (surveyData, history) => dispatch(submitSurvey(surveyData, history)),
  emptySurveyQuestion: () => dispatch(emptySurveyQuestion())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSurvey);