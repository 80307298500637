import dayjs from "dayjs";
import React, { useState } from "react";
import styled from "styled-components";
import { CalendarNextArrow, CalendarPrevArrow, DownloadReportIcon, ResetIcon } from "../../../utils/icons";
import { Border } from "../Manager/style";
import PropTypes from 'prop-types';

// Styled Components
const Container = styled.div`
  font-family: Arial, sans-serif;
  padding: 16px 24px;
  ${'' /* max-width: 400px; */}
  ${'' /* background-color: #fff; */}
  border-radius: 10px;
  .calendar-container{
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    min-height:339px;

  }
`;

const CalendarHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  background-color: #fff;

  .title{
    color:  #184A61;
text-align: center;
min-width:140px;

/* Primary - Lato/Sub Heading */
font-family: 'Lato';
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 133.333% */

  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  background-color: #fff;

  .day-name{
    color:  #BDBDBD;
    text-align: center;
    font-family: 'Lato';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    margin-bottom:16px;
    padding: 4px 8px;
  }
`;

const Day = styled.div`
  padding: 4px 8px;
  margin: 4px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.isSelected
      ? "#00ABB6"
      : props.isInRange
        ? "#F8F8F8"
        : "transparent"};
  color: ${(props) => (props.isSelected ? "#fff" : "#184A61")};
  cursor: pointer;
  text-align: center;
font-family: 'Lato';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */


  ${'' /* &:hover {
    background-color: #f0f8ff;
  } */}
`;

const Button = styled.span`
  padding: 8px 12px;
  ${'' /* background-color: ${(props) => (props.primary ? "#39c0ed" : "#e0e0e0")}; */}
  ${'' /* color: ${(props) => (props.primary ? "#fff" : "#333")}; */}
  color:  #184A61;

/* Primary - Lato/Caption */
font-family: "Lato";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 133.333% */
  ${'' /* border-radius: 40px; */}
${'' /* border: 1px solid  #EFEFEF; */}
background:  #FEFEFE;
  cursor: pointer;
  margin: 5px;

  ${'' /* &:hover {
    background-color: ${(props) => (props.primary ? "#1d8cb6" : "#d6d6d6")};
  } */}
`;

const DateInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 14px;
  width: 100%;
  color:#184A61;
  font-family:"Lato";
  font-size: 16px;

  /* Disable calendar appearance */
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`;

const BottomButtons = styled.div`
display:flex;
justify-content:center;
align-items:center;
`

const ResetButton = styled.div`
  border: none;
  background: none;
  display:flex;
  align-items:center;

  ${'' /* color: #39c0ed; */}
  cursor: pointer;
  ${'' /* text-decoration: underline; */}

  &:hover {
    color: #1d8cb6;
  }

  .reset-text{
    color:  #184A61;
font-family: "Lato";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
margin-left:12px;

  }
`;

const SelectedRangeContainer = styled.div`
.label{
  color: #184A61;
font-family: 'Lato';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
${'' /* margin-bottom:12px; */}
}
.input-container {
    display: flex;
    width: 100%;
    margin-top:12px;

    .input-one{
        width:50%;
    }
    .input-two{
        width:50%;
        margin-left:16px;
    }
}
`

const PresetButton = styled.button`
  padding: 8px 12px;
  color:  #184A61;
font-family: 'Lato';
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; 
${'' /* background:  #FEFEFE; */}
  cursor: pointer;
  margin: 5px;
  border-radius: 40px;
border: 1px solid #EFEFEF;
background-color: ${(props) => (props.primary ? "#e0e0e0" : "#FEFEFE")};
`

const DownloadButton = styled.button`
display: flex;
height: 40px;
padding: 12px 16px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 4px;
background:${({disabled}) => disabled ? "#EFEFEF" : '#00ABB6'};
color: ${({disabled}) => disabled ? "#989898" : '#FEFEFE'};
font-family: 'Lato';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
margin-left:16px;
border:none;
`

// Helper Functions
const getDaysInMonth = (year, month) => {
  const date = new Date(year, month, 1);
  const days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

const PresetDateRanges = [    
  { label: "Today", days: 0 },
  { label: "Last 7 days", days: 7 },
  { label: "Last 30 days", days: 30 },
  { label: "Last 90 days", days: 90 },
  { label: "Last 180 days", days: 180 },
]

const CustomDateRangePicker = ({DownloadReport}) => {
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [inputStartDate, setInputStartDate] = useState(null);
  const [inputEndDate, setInputEndDate] = useState(null);
  const [selectedDays, setSelectedDays] = useState(null)

  const days = getDaysInMonth(currentYear, currentMonth);

  // Handle Month Navigation (Previous/Next)
  const handleMonthChange = (direction) => {
    let newMonth = currentMonth + direction;
    let newYear = currentYear;

    if (newMonth < 0) {
      newMonth = 11; // December of the previous year
      newYear -= 1;
    } else if (newMonth > 11) {
      newMonth = 0; // January of the next year
      newYear += 1;
    }

    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
  };

  const handleDayClick = (day) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(day);
      setEndDate(null);
      setInputStartDate(dayjs(day).format("DD/MM/YYYY"));
    } else if (day >= startDate) {
      setEndDate(day);
      setInputEndDate(dayjs(day).format("DD/MM/YYYY"));
    } else {
      setEndDate(startDate);
      setInputEndDate(dayjs(startDate).format("DD/MM/YYYY"));
      setStartDate(day);
      setInputStartDate(dayjs(day).format("DD/MM/YYYY"));
    }
  };

  

  //   const handleToday = () => {
  //     setStartDate(today);
  //     setEndDate(today);
  //   };


  const formatToDDMMYYYY = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleLastNDays = (days) => {
    const pastDate = new Date();
    pastDate.setDate(today.getDate() - days);
    const convertedEndDate = formatToDDMMYYYY(pastDate);
    const convertedStartDate = formatToDDMMYYYY(today);
    setStartDate(pastDate);
    setEndDate(today);
    setInputStartDate(convertedEndDate);
    setInputEndDate(convertedStartDate);
    setSelectedDays(days)
  };


  

  const resetDates = () => {
    setStartDate(null);
    setEndDate(null);
    setInputStartDate("");
    setInputEndDate("");
    setSelectedDays(null)
  };

  const isDateInRange = (date) => {
    if (!startDate || !endDate) return false;
    return date >= startDate && date <= endDate;
  };

  const isSameDay = (date1, date2) =>date1 && date2 && date1?.toDateString() === date2?.toDateString();

  const validateDate = (date) => {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Format: DD/MM/YYYY
    return dateRegex.test(date);
  };

  const convertStringToDate = (dateString) => {
    // Validate the date format using a regex
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Format: DD/MM/YYYY
    if (!dateRegex.test(dateString)) {
      throw new Error('Invalid date format. Please use DD/MM/YYYY.');
    }
  
    // Extract day, month, and year from the string
    const [day, month, year] = dateString.split('/').map(Number);
  
    // Create a Day.js object and validate the date
    const parsedDate = dayjs(`${year}-${month}-${day}`);
    if (!parsedDate.isValid()) {
      throw new Error('Invalid date value. Please check the input.');
    }
  
    return parsedDate.toDate(); // Convert to JavaScript Date
  };

  return (
    <Container>

      {/* Calendar Header */}
      <div className="calendar-container">
        <CalendarHeader>

          <Button onClick={() => handleMonthChange(-1)}>{CalendarPrevArrow()}</Button>
          <span className="title">
            {new Date(currentYear, currentMonth).toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </span>
          <Button onClick={() => handleMonthChange(1)}>{CalendarNextArrow()}</Button>
        </CalendarHeader>
   
        {/* Calendar Grid */}
        <Grid>
          {/* Weekday Headers */}
          {["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map((day) => (
            <div className="day-name" key={day}>
              {day}
            </div>
          ))}
   
          {/* Calendar Days */}
          {days.map((day) => (
            <Day
              key={day}
              onClick={() => handleDayClick(day)}
              isSelected={
                isSameDay(day, startDate) || isSameDay(day, endDate)
              }
              isInRange={isDateInRange(day)}
            >
              {day.getDate()}
            </Day>
          ))}
        </Grid>
      </div>

      {/* Selected Range */}
      <SelectedRangeContainer>
        <p style={{ marginTop: "10px" }}>
          <span className="label">Selected Range</span>
          {/* Start Date Input */}
          <div className="input-container">
            <div className="input-one">
              <DateInput
                type="text"
                placeholder="DD/MM/YYYY"
                value={inputStartDate}
                onChange={(e) => {
                  const value = e.target.value;
                  window.console.log("value",value);
                  
                  setInputStartDate(value);
                  if (validateDate(value)) {
                    window.console.log("if")
                    setStartDate(convertStringToDate(value));
                  }
                }}
              />
            </div>
            
            {/* End Date Input */}
            <div className="input-two">
              <DateInput
                type="text"
                placeholder="DD/MM/YYYY"
                value={inputEndDate}
                onChange={(e) => {
                  const value = e.target.value;
                  window.console.log("value",value)
                  setInputEndDate(value);
                  if (validateDate(value)) {
                    window.console.log("if");
                    setEndDate(convertStringToDate(value));
                  }
                }}
              />
            </div>
          </div>
        </p>
      </SelectedRangeContainer>

      {/* Preset Buttons */}
      <div style={{ marginTop: "10px" }}>
        {PresetDateRanges.map((item) => (
          <PresetButton primary={selectedDays === item.days} key={item.days} onClick={() => handleLastNDays(item.days)}>
            {item.label}
          </PresetButton>
        ))}

      </div>

      <div style={{ background:"#F8F8F8"}}><Border style={{margin:"16px 0px"}}/></div>

      {/* Reset and Download Buttons */}
      <BottomButtons>
        <ResetButton onClick={resetDates}>
          <span> {ResetIcon()}</span>
          <span className="reset-text">Reset Dates</span></ResetButton>
        <DownloadButton disabled={!startDate || !endDate} 
          onClick={()=>{const StartDate = dayjs(startDate).format("YYYY-MM-DD"); const EndDate = dayjs(endDate).format("YYYY-MM-DD"); DownloadReport(StartDate,EndDate);}}>
          {DownloadReportIcon( !startDate || !endDate ? "#989898" : "white")} Download</DownloadButton>
      </BottomButtons>

    </Container>
  );
};

CustomDateRangePicker.propTypes = {
  DownloadReport: PropTypes.func,
}

export default CustomDateRangePicker;
