import styled from "styled-components";

const CoreValuContainer = styled.div`
border-radius: 12px;
background:  rgba(254, 254, 254, 0.60);
padding: 16px 16px 8px 16px;

.top-bar-container{
  display:flex;
  justify-content:space-between;
}

.header{
  color:  #184A61;
font-family: Lato;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px;
}

.archive{
  color:  #184A61;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
cursor:pointer;
}

.core-values{
  display:flex;
  ${'' /* margin:8px; */}
  flex-wrap:wrap;

}

.core-values > div:nth-child(3n) {
  margin-right: 0 !important; /* Remove margin-right for every 3rd item */
}

.core-values > div:nth-child(3n + 1) {
  margin-left: 0 !important; /* Remove margin-left for every first item of a row */
}


.border-btm{
  border-bottom:1px solid #EFEFEF;
  padding-bottom:20px;
  margin-bottom:20px;
  margin:4px;
}


.archive-active{
  display:flex;
  align-items:center;
  color:  #184A61;
  cursor:pointer;

/* Primary - Lato/Body-Text */
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */

  .archive-btn{
    border-radius: 4px;
border: 1px solid  #F37A65;
background: #F8F8F8;
margin-right:20px;
padding:1px 12px;
display:flex;
justify-content:center;
align-items:center;
height:32px;
cursor:pointer;

.archive-btn-icon{
  margin-right:8px;
}
  }

  .cancel-archive{
    cursor:pointer;
  }
}
`

const CoreValuCard = styled.div`
border-radius: 12px;
background:  #F8F8F8;
padding: 16px 16px 8px 16px;
max-width:400px;
min-width:400px;
display: flex;
padding: 8px 4px 8px 8px;
align-items: center;
gap: 12px;
flex: 1 0 0;
min-height:64px;


.core-value-row{
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:100%;

  .col-one{
    display:flex;
  align-items:center;
  ${'' /* justify-content:space-between; */}
    
  }
  .icon{
    margin-right:12px;
    border-radius:50%;
    padding:6px;
    width:48px;
    height:48px;
    display:flex; 
    align-items:center;
    justify-content:center;
  }

  .title{
    color:  #184A61;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
  }

  .action{
    display:flex;
    align-items:center;

    .archive-action{
  border-radius: 4px;
  

border:${({selected}) => selected ? '1px solid #F37A65' : '1px solid #7C7C7C'};
background:  #F8F8F8;
width:20px;
height:20px;
position:relative;
cursor:pointer
>svg{
    position:absolute;
    right:2px;
    top:3px;
}
}
  }

  .eye-icon{
    ${'' /* margin-right:16px; */}
    cursor:pointer;
  }

  .pencil-icon{
    cursor:pointer;
  }
  
}

.header{
  color:  #184A61;
font-family: Lato;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px;
}
`

export {CoreValuContainer, CoreValuCard};