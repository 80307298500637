/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TopChallengesWrapper, ChallengeCompletedWrapper, ChallengeCompletedCardMain, ChallengeCompletedTop,ChallengeCompletedCard, TopTenCardWrapper, TopTenParticipantWrapper, TopTenParticipantTop, TopTenParticipantHeading, TopTenParticipantContent, ChallengesWrapper, ActiveChallengesWrapper, OverviewWrapper, GlobalChallengesWrapper, LaunchchallengeCards, LaunchchallengeButton, TopCardEngagement, ChallengeCardWrapper } from "./styles";
import { commaSeperator } from "../../utils/methods";
import { ChallengeHeadermain, ChallengeHeader, SeeAllButton } from '../ChallengeDashboardV2/styles';
import UpComingChallengesSocial from '../ContentV3/UpComingChallenges/challengeSocialfeed';
import NewSingleChallenge from '../ChallengeDashboardV2/DashboardCards/NewSingleChallenge';
import { ImageUrl } from "../../utils/constants";
import { connect } from "react-redux";
import isNull from 'lodash/isNull';
import CSVList from "../AttendeesModal/CSVList";
import Waiting from '../Waiting';
import isUndefined from 'lodash/isUndefined';
import LazyImage from '../common/LazyImage/LazyImage';
import {ProgressBar} from '../HealthTabV2/HealthTabUpdated/styles';
import { CreateChallenge } from '../../utils/icons';
import { ToolTipNew } from "../AdminTeamCreation/styles";
import { OverlayTrigger, Popover } from "react-bootstrap";

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      printParticipantCSV: false,
      updatedPartcipantCSV: [],
      topChallengetype: 0,
      removeCSV: 0,
      onHover: null
    }
  }

  infoDetails = (text) =>{
    const popover = (value) => (
      <Popover
        id="popover-basic"
        style={{
          width: "auto",
          padding: "0px 0px",
          background: "#FFFFFF",
          border: "1px solid #005c87",
          boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
          borderRadius: "6px",
        }}
      >
        <div
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#005c87",
            textAlign: "center",
          }}
        >
          {value}
        </div>
      </Popover>
    );
    return(
      <ToolTipNew
        margin={"auto 15px auto auto"}
        width="24px"
        height="24px"
        transparent={1}
      >
        <OverlayTrigger
          placement="bottom"
          overlay={popover(text)}
        >
          <div
            style={{width:"24px", height:"24px"}}
          >
            <img src={ImageUrl + "/social-feed/info.png"} alt={"engagement-employee"}/>
          </div>
        </OverlayTrigger>
      </ToolTipNew>)}

  showEngagementEmployeeDetail = (overviewDetails) => {
    const EngagementEmployeeData = [
      {
        title: "Challenge",
        titleNew: "Engagement",
        value: "1",
        img: "/images/AdminChallenges/engagement.png",
        text: "Users participated out of",
        color: "#85C0EA",
        width: overviewDetails.challenge_engagement_percentage,
        userParticpated:overviewDetails.challenge_engagement_count,
        imageBackground: "#85C0EA26",
        subText: "Participation rate",
        totalUser:overviewDetails.user_enrolled_count,
        info:"We display wins in health where the average score exceeds Mark of 75%."
      },
      {
        title: "Total Challenge",
        titleNew: "Completed",
        value: "2",
        img: "/images/AdminChallenges/completed.png",
        text: "Challenge Launched Per Users",
        color: "#F1C977",
        average:overviewDetails.total_challenge_average.toFixed(1),
        width: overviewDetails.total_challenge_completed,
        imageBackground: "#F1C97726",
        subText: "Challenges Completed",
        info:"We display wins in health where the average score exceeds Mark of 75%."
      },
      {
        title: "Wellness Challenge",
        titleNew: "Activities",
        value: "3",
        img: "/images/AdminChallenges/wellnessActivity.png",
        text: "Activities Per User",
        color: "#AF87C1",
        average:overviewDetails.behavior_challenge_percentage.toFixed(1),
        width: overviewDetails.behavior_challenge_count,
        imageBackground: "#AF87C126",
        subText: "Activities",
        info:"We display wins in health where the average score exceeds Mark of 75%."
      },
      {
        title: "Total Steps Walked",
        titleNew: "By Your Company",
        value: "4",
        img: "/ChallengeDetailsScreen/new_steps.svg",
        text: "Steps Per Users",
        color: "#76AB78",
        average: commaSeperator(overviewDetails.average_total_steps.toFixed(1)),
        width: commaSeperator(overviewDetails.total_steps_company),
        imageBackground: "#76AB7826",
        subText: "Steps",
        info:"We display wins in health where the average score exceeds Mark of 75%."
      }
    ];
    return EngagementEmployeeData.map((details, index) => (
      <TopCardEngagement key={index} background={details.imageBackground} color={details.color}>
        <div className='first-child'>
          <div className='image'>
            <img src={ImageUrl + details.img} alt={"engagement-employee"}/>
          </div>
          <div className='title'>{details.title}<br/>{details.titleNew}</div>
          <div className='infoIcon'>
            {/* {this.infoDetails(details.info)} */}
            {/* <img src={ImageUrl + "/social-feed/info.png"} alt={"engagement-employee"}/> */}
          </div>
        </div>
        {index===0?<div className='progressCard'>
          <span className='percent'>{details.width}%</span>
          <span className='percentText'>{details.subText}</span>
        </div>:
          <div className='challengeData'>
            <span className='value'>{details.width}</span>
            <span className='valueText'>{details.subText}</span>
          </div>}
        {index!==0&&<div className='borderCard'/>}
        {index===0&&<div className='progressBar'>
          <ProgressBar background={"#85C0EA26"} backgroundActive={"#005C87"} width={details.width}>
            <div className="progress"></div>
          </ProgressBar>
        </div>}
        {index===0?<div className='last-child'>
          <span className='totalParticipant'> {details.userParticpated} </span>
          <span className='participateText'>{details.text}</span>
          <span className='totalParticipant'>{details.totalUser}</span>
        </div>:
          <div className='last-child'>
            <span className='participateText'>{"Avg"}</span>
            <span className='totalParticipant'> {details.average} </span>
            <span className='participateText'>{details.text}</span>
          </div>}
      </TopCardEngagement>
    ))
  };

  printActionParticipants = (bool, challengetype) => {
    window.console.log("challengetype", challengetype);
    window.console.log("challengetype type", typeof(challengetype));
    this.setState({ topChallengetype: challengetype })
    this.printAction(bool);
  }

  printActionLedaers = (bool, challengetype) => {
    this.setState({ topChallengetype: challengetype })
    this.printActionLeader(bool);
  }

  printAction = (bool) => {
    const { challengeTopParticpantsData } = this.props;
    let updatedPartcipantCSV = challengeTopParticpantsData && challengeTopParticpantsData.length > 0 &&
      challengeTopParticpantsData.map((particpants) => {
        let obj = {};
        obj['NAME'] = particpants['name'];
        obj['EMAIL'] = particpants['mail'];
        obj['DEPARTMENT'] = particpants['department'];
        obj['LOCATION'] = particpants['location'];
        obj['TEAM NAME'] = particpants['team_name'];
        obj['ACTIVITY CHALLENGE COUNT'] = particpants['activity_challenge_count'];
        obj['BEHAVIOR CHALLENGE COUNT'] = particpants['behavior_challenge_count'];
        obj['TOTAL CHALLENGE COUNT'] = particpants['total_challenge_count'];
        return obj;
      })
    this.setState({ printParticipantCSV: bool, updatedPartcipantCSV: updatedPartcipantCSV })
  };

  printActionLeader = (bool) => {
    const { challengeTopLeadersData } = this.props;
    let topLeaderboard = [];
    if (!isNull(challengeTopLeadersData) && challengeTopLeadersData.length && challengeTopLeadersData) {
      topLeaderboard = challengeTopLeadersData[0];
    }
    let updatedPartcipantCSV = topLeaderboard && topLeaderboard.length > 0 &&
      topLeaderboard.map((leaders) => {
        let obj = {};
        obj['NAME'] = leaders['name'];
        obj['EMAIL'] = leaders['mail'];
        obj['ACTIVITY CHALLENGE POINTS'] = leaders['activity_points'];
        obj['BEHAVIOR CHALLENGE POINTS'] = leaders['behavior_points'];
        obj['TOTAL CHALLENGE POINTS'] = leaders['total_points'];
        return obj;
      })
    this.setState({ printParticipantCSV: bool, updatedPartcipantCSV: updatedPartcipantCSV })
  };

  challengesCompleted = () => {
    const { activityChallengeData, behaviorChallengeData } = this.props;
    const ActivityChallengeData = [
      {
        title: "4+ Completed",
        percent: activityChallengeData['four_plus'],
        background: "rgba(157, 135, 169, 0.15)",
        value: "1",
      },
      {
        title: "3 Completed",
        percent: activityChallengeData['three'],
        background: "rgba(159, 201, 137, 0.15)",
        value: "2",
      },
      {
        title: "2 Completed",
        percent: activityChallengeData['two'],
        background: "rgba(105, 194, 255, 0.15)",
        value: "3",
      },
      {
        title: "1 Completed",
        percent: activityChallengeData['one'],
        background: "rgba(253, 113, 117, 0.15)",
        value: "4",
      },
      {
        title: "0 Completed",
        percent: activityChallengeData['zero'],
        background: "rgba(246, 180, 121, 0.15)",
        value: "5",
      }
    ];

    const BehaviorChallengeData = [
      {
        title: "4+ Completed",
        percent: behaviorChallengeData['four_plus'],
        background: "rgba(157, 135, 169, 0.15)",
        value: "1",
      },
      {
        title: "3 Completed",
        percent: behaviorChallengeData['three'],
        background: "rgba(159, 201, 137, 0.15)",
        value: "2",
      },
      {
        title: "2 Completed",
        percent: behaviorChallengeData['two'],
        background: "rgba(105, 194, 255, 0.15)",
        value: "3",
      },
      {
        title: "1 Completed",
        percent: behaviorChallengeData['one'],
        background: "rgba(253, 113, 117, 0.15)",
        value: "4",
      },
      {
        title: "0 Completed",
        percent: behaviorChallengeData['zero'],
        background: "rgba(246, 180, 121, 0.15)",
        value: "5",
      }
    ];

    return (
      <ChallengeCompletedWrapper>
        <div>
          <ChallengeCompletedCardMain>
            <ChallengeCompletedTop>
              <LazyImage src={ImageUrl + "/images/AdminChallenges/activitychallenge.png"} />
              <div id='acitivity-challenge-completed'>
                Activity Challenge Completed
              </div>
            </ChallengeCompletedTop>
            {ActivityChallengeData.map((details, index) =>
              <ChallengeCompletedCard id={index+"activity"} border={index === 0 ? "" : "1px solid #005C874D"} key={index} background={details.background}>
                <div>
                  <div id="acitivity-title">{details.title}</div>
                  <div>
                    <div id="acitivity-title-percentage">{details.percent}%</div>
                  </div>
                </div>
              </ChallengeCompletedCard>)}
          </ChallengeCompletedCardMain>
        </div>
        <div>
          <ChallengeCompletedCardMain>
            <ChallengeCompletedTop>
              <LazyImage src={ImageUrl + "/images/AdminChallenges/behaviorchallenge.png"} />
              <div id='behavior-challenge-completed'>
                Behavior Challenge Completed
              </div>
            </ChallengeCompletedTop>
            {BehaviorChallengeData.map((details, index) =>
              <ChallengeCompletedCard id={index+"behavior"} border={index === 0 ? "" : "1px solid #005C874D"} key={index} background={details.background}>
                <div>
                  <div id='behavior-challenge-title'>{details.title}</div>
                  <div>
                    <div id='behavior-challenge-percentage'>{details.percent}%</div>
                  </div>
                </div>
              </ChallengeCompletedCard>)}
          </ChallengeCompletedCardMain>
        </div>
        <div>
          <ChallengeCompletedCardMain>
            <GlobalChallengesWrapper>
              Coming Soon...
            </GlobalChallengesWrapper>
          </ChallengeCompletedCardMain>
        </div>
      </ChallengeCompletedWrapper>)
  }

  redirection = (path) => {
    const { history } = this.props;
    history.push(`/${path}`)
  }

  launchChallengeCard = () => {
    const { user, topChallenges } = this.props;
    return (
      topChallenges.length===0?<LaunchchallengeCards>
        <div className='image'>
          <LazyImage src={ ImageUrl + "/ChallengeDetailsScreen/NoChallengeImage.png"} />
        </div>
        <div className='text'>
          <p>Hey <span>{user.fname}</span>, Your Company doesn’t have any Active Company Challenges.</p>
          <p>Launch a company challenge today for your employees!</p>
        </div>
        <div>
          <LaunchchallengeButton>
            {CreateChallenge("white")}
            <div onClick={() => { this.redirection('company/challenges/categories') }} >
              Launch A Challenge
            </div>
          </LaunchchallengeButton>
        </div>
      </LaunchchallengeCards>:null
    )
  }

  topTenParticipants = (particpantsData) => {
    const slicedParticipantData = particpantsData.length > 9 ? particpantsData.slice(0, 10) : particpantsData.slice(0, particpantsData.length);
    return (
      <TopTenParticipantWrapper>
        <TopTenParticipantTop>
          <div>Top Challenge Participants</div>
          <LazyImage src={ImageUrl + "/ChallengeDetailsScreen/download.png"} onClick={() => this.printActionParticipants(true, 1)} />
        </TopTenParticipantTop>
        <TopTenParticipantHeading>
          <div>Name</div>
          <div>Activity</div>
          <div>Behavior</div>
          <div>Total</div>
        </TopTenParticipantHeading>
        {slicedParticipantData.map((participant, index) => (
          <TopTenParticipantContent border={index === 0 ? '' : '1px solid #005C874D'} key={index}>
            <div>
              <div>
                <div>
                  <LazyImage div={"profile"} onError={this.fallBack} src={`${ImageUrl}/${participant['profile_image']}`} />
                  <div>
                    <div><p> {participant['name']}</p></div>
                    {participant['is_champion'] && <div>
                      <img src={"/public/images/AdminChallenges/Subtract.png"} />
                      <span>Wellness Champion</span>
                    </div>}
                  </div>
                </div>
              </div>
              <div>{participant['activity_challenge_count']}</div>
              <div>{participant['behavior_challenge_count']}</div>
              <div>{participant['total_challenge_count']}</div>
            </div>
          </TopTenParticipantContent>))}
      </TopTenParticipantWrapper>
    )
  }

  topTenLeaders = (leadersData) => {
    const sliceLeaderData = leadersData.length > 9 ? leadersData.slice(0, 10) : leadersData.slice(0, leadersData.length);
    return (
      <TopTenParticipantWrapper>
        <TopTenParticipantTop>
          <div>Top Challenge Leaders</div>
          <LazyImage src={ImageUrl + "/ChallengeDetailsScreen/download.png"} onClick={() => this.printActionLedaers(true, 2)} />
        </TopTenParticipantTop>
        <TopTenParticipantHeading>
          <div>Name</div>
          <div>Activity Pts</div>
          <div>Behavior Pts</div>
          <div>Total Pts</div>
        </TopTenParticipantHeading>
        {sliceLeaderData.map((leader, index) => (
          <TopTenParticipantContent border={index === 0 ? '' : '1px solid #005C874D'} key={index}>
            <div>
              <div>
                <div>
                  <LazyImage div={"profile"} onError={this.fallBack} src={`${ImageUrl}/${leader['profile_image']}`} />
                  <div>
                    <div><p> {leader['name']}</p></div>
                    {leader['is_champion'] && <div>
                      <img src={"/public/images/AdminChallenges/Subtract.png"} />
                      <span>Wellness Champion</span>
                    </div>}
                  </div>
                </div>
              </div>
              <div>{leader['activity_points']}</div>
              <div>{leader['behavior_points']}</div>
              <div>{leader['total_points']}</div>
            </div>
          </TopTenParticipantContent>
        ))}
      </TopTenParticipantWrapper>)
  }

  ActiveChallengesCard = () => {
    const { history, toggleDummyChallenges, topChallenges  } = this.props;
    const slicedUserData = topChallenges .slice(0, 2);
    return (
      <ActiveChallengesWrapper>
        <ChallengeHeadermain>
          <div>
            <ChallengeHeader color="1" colorValue={"#005c87"}>{"Active Company Challenges"}</ChallengeHeader>
          </div>
          <div />
        </ChallengeHeadermain>
        {
          slicedUserData?.map((challenge, index) =>
            <NewSingleChallenge challenge={challenge} history={history} key={index} toggleDummyChallenges={toggleDummyChallenges} margin={index} adminChallenge={'1'} />
          )
        }
      </ActiveChallengesWrapper>)
  }

  onEnterMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  renderChallenges = (challenge) => {
    const { history, toggleDummyChallenges, adminChallengeActive, hideShowChallenge } = this.props;
    challenge = challenge && challenge.sort((a,b) => new Date(a.start_date) - new Date(b.start_date));
    const slicedUserData = challenge && challenge.slice(0, 3);
    const hasLessThanFourChallenges = slicedUserData?.length < 3;

    return (
      <ChallengeCardWrapper>
        <ChallengeHeadermain style={{width:"100%"}}>
          <div>
            <ChallengeHeader style={{color:"#005c87", lineHeight:"24px"}}>{("Active Company Challenges")}</ChallengeHeader>
          </div>
          <div>
            {adminChallengeActive && adminChallengeActive.length > 3 ? <SeeAllButton color={"color"} onClick={()=>hideShowChallenge()}>{('See All >')}</SeeAllButton> : ''}
          </div>
        </ChallengeHeadermain>
        <div className="siglneCardmain"> 
          {
            slicedUserData?.map((challenge, index) =>
              <NewSingleChallenge onEnterMouse={this.onEnterMouse} onLeaveMouse={this.onLeaveMouse} onHover={this.state.onHover} challenge={challenge} history={history} key={index} toggleDummyChallenges={toggleDummyChallenges} margin={index} adminChallenge={1}/>
            )
          }
          {hasLessThanFourChallenges && <div style={{width:"399px", height:"334px", border: "3px dashed #005C874D",borderRadius:"6px", display:"flex", alignItems:'center', justifyContent:"center"}}>
            <LaunchchallengeButton width="240px">
              <div onClick={() => { this.redirection('company/challenges/categories') }} >
                + Launch A Challenge
              </div>
            </LaunchchallengeButton>
          </div>}
        </div>
      </ChallengeCardWrapper>
    )
  };


  fallBack = (e) => {
    e.target.src = "/public/images/default.png";
  };

  render() {
    const { history, challengeTopLeadersData, challengeTopParticpantsData, /*challengeOverviewData,*/ topChallenges, adminChallengeOverviewData, adminChallengeActive} = this.props;
    let topLeaderboard = [];
    const { topChallengetype, updatedPartcipantCSV, printParticipantCSV } = this.state;
    if (!isNull(challengeTopLeadersData) && challengeTopLeadersData.length && challengeTopLeadersData) {
      topLeaderboard = challengeTopLeadersData[0];
    }
    if (isUndefined(topChallenges)) {
      return <Waiting />;
    }
    const fileTitle = topChallengetype === 1 ? "Challenge Participants" : "Challenge Leaders";
    const csvHeaders = topChallengetype === 1 ?
      [
        { label: "Name", key: "NAME" },
        { label: "Email", key: "EMAIL" },
        { label: "Department", key: "DEPARTMENT" },
        { label: "Location", key: "LOCATION" },
        { label: "Team", key: "TEAM NAME" },
        { label: "Activity Challenge Count", key: "ACTIVITY CHALLENGE COUNT" },
        { label: "Behavior Challenge Count", key: "BEHAVIOR CHALLENGE COUNT" },
        { label: "Total Challenge Count", key: "TOTAL CHALLENGE COUNT" },
      ] :
      [
        { label: "Name", key: "NAME" },
        { label: "Email", key: "EMAIL" },
        { label: "Activity Challenge Points", key: "ACTIVITY CHALLENGE POINTS" },
        { label: "Behavior Challenge Points", key: "BEHAVIOR CHALLENGE POINTS" },
        { label: "Total Challenge Points", key: "TOTAL CHALLENGE POINTS" },
      ]
    return (
      <OverviewWrapper>
        <TopChallengesWrapper>
          {this.showEngagementEmployeeDetail(adminChallengeOverviewData)}
        </TopChallengesWrapper>
        {this.launchChallengeCard()}
        {this.renderChallenges(adminChallengeActive)}
        {this.challengesCompleted()}
        <TopTenCardWrapper>
          {!isNull(challengeTopParticpantsData) && challengeTopParticpantsData.length > 0 ? <div>{this.topTenParticipants(challengeTopParticpantsData)}</div> : null}
          {topLeaderboard.length > 0 ? <div>{this.topTenLeaders(topLeaderboard)}</div> : null}
        </TopTenCardWrapper>
        {
          <ChallengesWrapper>
            {/* <div>{topChallenges.length > 0 &&  (this.ActiveChallengesCard())}</div> */}
            <div><UpComingChallengesSocial history={history} padding={"4"} adminChallenge/></div>
          </ChallengesWrapper>}
        {printParticipantCSV && topChallengetype === 1 && updatedPartcipantCSV ? printParticipantCSV && <CSVList Title={fileTitle} removeCSVData={this.printAction} headers={csvHeaders} data={updatedPartcipantCSV} /> : topChallengetype === 2 && updatedPartcipantCSV && printParticipantCSV && <CSVList Title={fileTitle} removeCSVData={this.printActionLeader} headers={csvHeaders} data={updatedPartcipantCSV} />}
      </OverviewWrapper>
    )
  }
}

Overview.propTypes = {
  history: PropTypes.object,
  toggleDummyChallenges: PropTypes.func,
  challengeTopLeadersData: PropTypes.array,
  challengeTopParticpantsData: PropTypes.array,
  challengeOverviewData: PropTypes.array,
  employeeCount: PropTypes.number,
  activityChallengeData: PropTypes.array,
  behaviorChallengeData: PropTypes.array,
  user: PropTypes.object,
  topChallenges: PropTypes.array,
  adminChallengeOverviewData: PropTypes.array,
  adminChallengeActive: PropTypes.array,
  hideShowChallenge: PropTypes.func
}

const mapStateToProps = (state) => ({
  employeeCount: state.companyDashboard.employeeCount,
});

export default connect(mapStateToProps, null)(Overview);