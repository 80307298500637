/* eslint-disable no-console,no-undef, camelcase, no-useless-escape */
import React, { Component } from "react"; 
import { withRouter } from 'react-router-dom'; 
import { 
  MainSurveyContainer, 
  SurveyTitle, 
  SurveyQuestionsContainer, 
  TextAreaContainer, 
  OptionContainer, 
  YesNoWrapper, 
  YesNoContainer, 
  McqContainer, 
  McqWrapper, 
  ToggleWrapper, 
  RenderSurveyQuestions, 
  RenderQuestionContainer, 
  QuestionContainer, 
  ButtonContainer, 
  StepIndicator, 
  StepWrapper, 
  ScaleSlider, 
  ScaleSliderContainer, 
  ScaleLabels, 
  ScaleRuler, 
  TextAboveThumb, 
  CheckMarkContainer, 
  CheckMark, 
} from "./styles"; 
import PropTypes from "prop-types"; 
import CommonTextArea from "../common/CommonTextArea/CommonTextArea"; 
import { FetchSurveyDetails, SubmitSurveyResponse } from "../../redux/constants/apiConstants"; 
import Waiting from "../Waiting"; 
import { toast } from "react-toastify"; 
import { fetchApi } from "../../utils/methods"; 
import SkeletonLoder from "../common/skeletonLoder"; 
import CommonButton from "../common/CommonButton/CommonButton"; 
import { Arrows, NextArrows } from "../../utils/icons"; 
import { t } from "i18next"; 

class UserSurveyV1 extends Component { 
  constructor(props) { 
    super(props); 
    this.state = { 
      selectedOption: [], 
      selectedYesNoOption: [], 
      selectedMCQ : {}, 
      textDescription:"", 
      putDescriptionPayload:[], 
      textDescriptionArr:[], 
      followUpDescription:"", 
      followUpPutDescriptionPayload:[], 
      followUpDescriptionArr:[], 
      opinionValues:{}, 
      surveyQuestions: null, 
      handlingFollowUp: true, 
      followUpAnswers: [], 
      setCategoryIndex: 0, 
      surveyData: [], 
      totalQuestions: 0, 
      currentQuestionIndex: 0, 
      questionIndex: 0, 
      activeIndex: 0, 
      scaleValue: 0, 
      survey_Id: null, 
      surveyPoints: 0, 
      surveyCategory: "", 
      isToggle : true, 
      payload:{}, 
      isOptionSelected:false, 
      isShortDesc : false, 
      isFollowUp:false, 
      redirectToPortal: false, 
      isMultipleresponse:0, 
      finalPayload : [], 
      isLoading:false 
    }; 
  } 

  componentDidMount() { 
    const {history, location } = this.props; 
    const surveyId = location && location.state && location.state.surveyId && location.state.surveyId; 
    if (!surveyId || surveyId === undefined ) { 
      history.push("/portal"); 
    } else { 
      this.fetchSurveyData(surveyId); 
      this.setState({ 
        survey_Id : surveyId 
      }); 
    } 
  } 

  fetchSurveyData = async(surveyId) => { 
    this.setState({isLoading:true}); 
    try { 
      const res = await fetchApi(FetchSurveyDetails + surveyId,"GET"); 

      window.console.log("res",res) 

      if(res?.data?.message){ 
        toast.error(res.data.message) 
      }else{ 
        if(res?.data?.status != "active"){ 
          toast.error("Survey is not active"); 
          this.props.history.push("/portal"); 
        }else{ 
          this.setState({ 
            surveyPoints : res?.data?.points, 
            surveyCategory: res?.data?.category_name, 
            surveyData: res?.data?.survey_questions, 
            totalQuestions: res?.data?.survey_questions?.length, 
          },()=>{this.setState({isLoading:false})}); 
        } 
      } 
    } catch (error) { 
      toast.error(error); 
      this.setState({isLoading:false}) 
    } 
  }; 

  mapToUserAnswers() { 
    const { surveyData } = this.state; 
    const userAnswers = []; 

    surveyData.forEach((item) => { 
      const { id: question_id, question_type_id, question_type_name, selectedAnswer, selectedAnswerId, question_answers } = item; 

      if (question_type_name === "short-answer") { 
        userAnswers.push({ 
          question_id: question_id, 
          question_type_id: question_type_id, 
          answer_id: null, 
          skip_question: !selectedAnswer ? 1 : 0, 
          answer: selectedAnswer || null, 
          anonymous_respondent: 1, 
        }); 
      } else if (selectedAnswerId && Array.isArray(selectedAnswerId)) { 
        selectedAnswerId.forEach((answer_id) => { 
          const answer = question_answers.find((ans) => ans.id === answer_id); 

          userAnswers.push({ 
            question_id: question_id, 
            question_type_id: question_type_id, 
            answer_id: answer_id, 
            skip_question: 0, 
            answer: answer?.answer || null, 
            anonymous_respondent: 1, 
          }); 
        }); 
      } 
    }); 

    return userAnswers; 
  } 

  postSurveyResponse = async()=>{ 
    const payload = { 
      survey_id: this.state.survey_Id, 
      user_answers: this.mapToUserAnswers(), 
    }; 
    console.log("payload",payload) 
    this.setState({buttonstatus:true}) 
    try{ 
      const res = await fetchApi(SubmitSurveyResponse, "POST",payload); 
      console.log("testing data",res); 
      if(res?.data?.message){ 
        toast.error(res?.data?.message); 
        this.setState({buttonstatus:false}) 
      }else{ 
        toast.success(res?.data?.[0]); 
        this.props.history.push({ 
          pathname: '/portal', 
          state: { completedSurveyPoint: this.state.surveyPoints , surveyCategory: this.state.surveyCategory } 
        }); 
      } 
    }catch(error){ 
      toast.error(error) 
    } 
  } 

  handlePayloadUpdate = () => { 
    const newPayload = { 
      survey_id: this.state.survey_Id, 
      answers: this.state.finalPayload, 
    }; 
    this.postSurveyResponse(newPayload); 
  }; 

  componentDidUpdate(prevProps, prevState) { 
    if (prevState.activeIndex !== this.state.activeIndex) { 
      if (typeof this.takeSurvey !== "undefined" && this.takeSurvey !== null) { 
        setTimeout(() => { 
          this.takeSurvey && 
            this.takeSurvey.scrollIntoView({ 
              block: "start", 
              behavior: "smooth", 
            }); 
        }, 100); 
      } 
    } 
  } 

  getSelectedOptionBackground = (option) => { 
    switch (option.toString().toLowerCase()) { 
    case "1": { 
      return "#f4aaa9"; 
    } 
    case "2": { 
      return "#af87c1 "; 
    } 
    case "3": { 
      return "#f1c977"; 
    } 
    case "4": { 
      return "#85c0ea"; 
    } 
    case "5": { 
      return "#76ab78";  
    } 
    case "no": { 
      return "#f4aaa9"; 
    } 
    case "yes": { 
      return "#76ab78"; 
    } 
    default: 
      break; 
    } 
  }; 

  selectOptions = (questionIndex, answerId, points,answer) => { 
    const updatedSelectedOption = { ...this.state.selectedOption }; 
    updatedSelectedOption[questionIndex] = answerId; 
    const payload = { 
      question_id: questionIndex, 
      answer_id: [answerId], 
      points: points, 
    } 

    const surveyData = [...this.state.surveyData]; 
    surveyData[this.state.activeIndex].selectedAnswer = answer.answer; 
    surveyData[this.state.activeIndex].selectedAnswerId = [answerId]; 
    this.setState({ selectedOption: updatedSelectedOption, payload : payload, isOptionSelected:true,surveyData: surveyData }); 
  }; 

  renderOptions = (options, questionIndex) => { 
    const { selectedOption } = this.state; 
    return options?.map((item, index) => ( 
      <OptionContainer 
        key={`${index}-${item.id}`} 
        onClick={() => this.selectOptions(questionIndex, item.id, item.points,item)} 
        isActive={selectedOption[questionIndex] === item.id} 
        bgColor={this.getSelectedOptionBackground(item.points)} 
        id={`option-${questionIndex}-${index}`} // Added ID 
      > 
        <div> 
          <CheckMarkContainer> 
            <CheckMark bgColor={this.getSelectedOptionBackground(item.points)}/> 
          </CheckMarkContainer> 
        </div> 
        <div className="option">{item.answer}</div> 
      </OptionContainer> 
    )); 
  }; 

  handleYesNoClick = (questionIndex, answerId, points,answer) => { 
    this.setState({ selectedYesNoOption: answerId }); 
    const updatedSelectedYesNo = {...this.state.selectedYesNoOption}; 
    updatedSelectedYesNo[questionIndex] = answerId; 
    const payload = { 
      question_id: +questionIndex, 
      answer_id: [answerId], 
      points: points, 
    } 
    const surveyData = [...this.state.surveyData]; 
    surveyData[this.state.activeIndex].selectedAnswer = answer.answer; 
    surveyData[this.state.activeIndex].selectedAnswerId = [answerId]; 
    this.setState({ selectedYesNoOption: updatedSelectedYesNo, payload : payload, isOptionSelected:true,surveyData:surveyData }); 
  }; 

  renderYesNo = (options, questionIndex) => ( 
    <YesNoWrapper style={{ display: "flex" }}> 
      {options?.map((item, index) => ( 
        <div key={`${index}-${item.id}`}> 
          <YesNoContainer 
            onClick={() => 
              this.handleYesNoClick(questionIndex, item.id, item.points,item) 
            } 
            isActive={ 
              this.state.selectedYesNoOption[questionIndex] === item.id 
            } 
            bgColor={this.getSelectedOptionBackground(item.answer)} 
            id={`yes-no-${questionIndex}-${index}`} // Added ID 
          > 
            {item.answer} 
          </YesNoContainer> 
        </div> 
      ))} 
    </YesNoWrapper> 
  ); 

  toggleOptionSelection = (questionIndex, answerId, points, optionsData,accept_multiple_responses) => { 
    const { selectedMCQ } = this.state; 
    const existingSelection = selectedMCQ[questionIndex]; 
    if (existingSelection) { 
      let updatedSelection; 

      if (accept_multiple_responses) { 
        updatedSelection = existingSelection.includes(answerId) 
          ? existingSelection.filter((id) => id !== answerId) 
          : [...existingSelection, answerId]; 
      } else { 
        updatedSelection = [answerId]; 
      } 

      const updatedMCQ = { ...selectedMCQ }; 
      updatedMCQ[questionIndex] = updatedSelection; 
      const answerIdsString = updatedSelection; 
      const selectedPoints = updatedSelection.reduce((totalPoints, selectedId) => { 
        const selectedOption = optionsData.find((option) => option.id === selectedId); 
        return totalPoints + (selectedOption ? selectedOption.points : 0); 
      }, 0); 

      const payload = { 
        question_id: questionIndex, 
        answer_id: answerIdsString, 
        points: selectedPoints 
      }; 
      const surveyData = [...this.state.surveyData]; 
      surveyData[this.state.activeIndex].selectedAnswer = answerIdsString; 
      surveyData[this.state.activeIndex].selectedAnswerId = answerIdsString; 
      this.setState({ selectedMCQ: updatedMCQ, payload: payload, isOptionSelected: true,surveyData:surveyData },()=>{ 
        if(this.state.selectedMCQ[questionIndex].length){ 
          this.setState({isOptionSelected:true}) 
        }else{ 
          this.setState({isOptionSelected:false}) 
        } 
      }); 
    } else { 
      const newSelection = [answerId]; 

      const updatedMCQ = { ...selectedMCQ }; 
      updatedMCQ[questionIndex] = newSelection; 
      const selectedPoints = newSelection.reduce((totalPoints, selectedId) => { 
        const selectedOption = optionsData.find((option) => option.id === selectedId); 
        return totalPoints + (selectedOption ? selectedOption.points : 0); 
      }, 0); 
      const payload = { 
        question_id: questionIndex, 
        answer_id: newSelection, 
        points : selectedPoints 
      }; 
      const surveyData = [...this.state.surveyData]; 
      surveyData[this.state.activeIndex].selectedAnswer = newSelection; 
      surveyData[this.state.activeIndex].selectedAnswerId = newSelection; 
      this.setState({ selectedMCQ: updatedMCQ,payload: payload,isOptionSelected:true,surveyData:surveyData },()=>{ 
        if(this.state.selectedMCQ[questionIndex].length){ 
          this.setState({isOptionSelected:true}) 
        }else{ 
          this.setState({isOptionSelected:false}) 
        }}); 
    } 
  }; 

  renderMultipleChoices = (options, questionIndex, accept_multiple_responses) => { 
    const { selectedMCQ } = this.state; 
    const isTwoColumns = options.length > 5; 
    const existingSelection = selectedMCQ[questionIndex]; 
    const backgroundColors = ['#af87c1', '#76ab78', '#f1c977', '#85c0ea', '#f4aaa9']; 
    const firstFiveOptions = options.slice(0, 5); 
    const remainingOptions = options.slice(5); 

    return ( 
      <McqWrapper isTwoColumns={isTwoColumns}> 
        {firstFiveOptions?.map((item, index) => { 
          const isSelected = existingSelection && existingSelection.includes(item.id); 
          const colorIndex = index % backgroundColors.length; 
          const label = isTwoColumns ? String.fromCharCode(65 + index % 6) : String.fromCharCode(65 + index); 
          return ( 
            <McqContainer 
              key={`${index}-${item.id}`} 
              onClick={() => this.toggleOptionSelection(questionIndex, item.id, item.points, options,accept_multiple_responses)} 
              isActive={isSelected} 
              bgColor={backgroundColors[colorIndex]} 
              isTwoColumns={isTwoColumns} 
              id={`mcq-option-${questionIndex}-${index}`} // Added ID 
            > 
              <div> 
                <CheckMarkContainer> 
                  <CheckMark bgColor={backgroundColors[colorIndex]}/> 
                </CheckMarkContainer> 
              </div> 
              <div>{label}. {item.answer}</div> 
            </McqContainer> 
          ); 
        })} 
        {remainingOptions?.map((item, index) => { 
          const isSelected = existingSelection && existingSelection.includes(item.id); 
          const colorIndex = index % backgroundColors.length; 
          const label = String.fromCharCode(65 + 5 + index); 
          return ( 
            <McqContainer 
              key={`${index}-${item.id}`} 
              onClick={() => this.toggleOptionSelection(questionIndex, item.id, item.points, options,accept_multiple_responses)} 
              isActive={isSelected} 
              bgColor={backgroundColors[colorIndex]} 
              isTwoColumns={isTwoColumns} 
              id={`mcq-option-${questionIndex}-${index + 5}`} // Added ID 
            > 
              <div> 
                <CheckMarkContainer> 
                  <CheckMark bgColor={backgroundColors[colorIndex]}/> 
                </CheckMarkContainer> 
              </div> 
              <div>{label}. {item.answer}</div> 
            </McqContainer> 
          ); 
        })} 
      </McqWrapper> 
    ); 
  }; 

  handleChange = (event, questionIndex,answerId,points,item) => { 
    const value = event.target.value; 
    const payload = { 
      question_id: questionIndex, 
      answer_id: [answerId], 
      points: points, 
    }; 

    const surveyData = [...this.state.surveyData]; 
    surveyData[this.state.activeIndex].selectedAnswer = item?.answer; 
    surveyData[this.state.activeIndex].selectedAnswerId = [answerId]; 

    this.setState((prevState) => ({ 
      opinionValues: { 
        ...prevState.opinionValues, 
        [questionIndex]: value, 
      }, 
      payload: payload, 
      isOptionSelected: true, 
      surveyData:surveyData 

    })); 
  }; 

  renderOpinionScale = (options,questionIndex) => { 
    const value = this.state.opinionValues[questionIndex] || 0; 
    let thumbText; 
    if(value > 0 && value <= 5){ 
      thumbText = "Got It!" 
    } 
    else if(value > 5 && value <= 7){ 
      thumbText = "Thank You!" 
    } 
    else if(value > 7 && value <= 10){ 
      thumbText = "Pleasured!" 
    } 
    else{ 
      thumbText= null 
    } 
    return ( 
      <ScaleSliderContainer> 
        <TextAboveThumb value={value}><p>{thumbText || ""}</p></TextAboveThumb> 
        <ScaleSlider 
          type="range" 
          min="0" 
          max="10" 
          step="1" 
          value={value} 
          onChange={(e) => this.handleChange(e,questionIndex,options?.[e.target.value]?.id,options?.[e.target.value]?.points,options[e.target.value])} 
          id={`scale-slider-${questionIndex}`} // Added ID 
        /> 
        <ScaleLabels> 
          {Array.from({ length: 11 }, (_, i) => ( 
            <React.Fragment key={i}> 
              <ScaleRuler 
                key={i} 
                style={{ color: i === value ? "#005c87": i <= value? i <= 5? "#f4aaa9": i <= 7? "#f1c977": "#76ab78": "#333"}}> 
                <p style={{height:'30px', border:"1px solid #b3cfdb"}} /> 
                <p style={{color:'#005c87'}}>{options[i]?.answer}</p> 
              </ScaleRuler> 
            </React.Fragment> 
          ))} 
        </ScaleLabels> 
      </ScaleSliderContainer> 
    ); 
  }; 

  toggle = () => { 
    this.setState({isToggle : !this.state.isToggle}) 
  }; 

  onChangeInput = (e, questionIndex) => { 
    const { activeIndex } = this.state; 
    const { value } = e.target; 
    const upadtedtextDesArr = {...this.state.textDescriptionArr}; 
    upadtedtextDesArr[questionIndex] = value; 
    const transformedValue = { 
      question_id: questionIndex , 
      answer: [{ value :value  }], 
    }; 
    const payloadArr = [] 
    payloadArr.push(transformedValue); 

    const surveyData = [...this.state.surveyData]; 
    surveyData[activeIndex]["selectedAnswer"] = value; 
    surveyData[activeIndex]["selectedAnswerId"] = null; 

    this.setState({ textDescription: value, textDescriptionArr : upadtedtextDesArr, putDescriptionPayload : payloadArr,surveyData: surveyData }); 
  } 

  renderShortAnswer = (options, questionIndex) => ( 
    <TextAreaContainer 
      onClick={() => {}} 
      id={`short-answer-${questionIndex}`} // Added ID 
    > 
      <CommonTextArea 
        placeholder="Comment are optional. You may choose to leave this blank." 
        rows="4" 
        name="description" 
        value={ this.state.textDescriptionArr[questionIndex] || ""} 
        onChange={(e) => this.onChangeInput(e, questionIndex)} 
        data-gramm_editor="false" 
        type="text" 
        maxLength="250" 
        style={{ 
          width: "1095px", 
          height: "200px", 
          borderRadius: "6px", 
          backgroundColor: "#F7F5EB", 
          color: "#005c87", 
          placeholderColor: "#649bb3", 
          float: "none", 
        }} 
      /> 
      <ToggleWrapper> 
        <p> 
          {this?.state?.textDescriptionArr?.[questionIndex]?.length || 0}/250 {"Words"} 
        </p> 
      </ToggleWrapper> 
    </TextAreaContainer> 
  ) 

  onFollowUpChangeInput = (e, questionIndex, followUp_questionId) => { 
    const { value } = e.target; 
    const upadtedFollowUpDesArr = {...this.state.followUpDescriptionArr}; 
    upadtedFollowUpDesArr[questionIndex] = value; 
    const transformedValue = { 
      question_id: followUp_questionId , 
      answer: [{ value :value , points: 0 }], 
    }; 
    const payloadArr = [] 
    payloadArr.push(transformedValue) 

    this.setState({ followUpDescription: value, followUpDescriptionArr : upadtedFollowUpDesArr, followUpPutDescriptionPayload : payloadArr  }); 
  } 

  renderFollowUp = (questionIndex, followUp_questionId) => 
    (<TextAreaContainer 
      onClick={() => {}} 
      id={`follow-up-${questionIndex}`} // Added ID 
    > 
      <CommonTextArea 
        placeholder="Comment are optional. You may choose to leave this blank." 
        rows="4" 
        name="description" 
        value={ this.state.followUpDescriptionArr[questionIndex] || ""} 
        onChange={(e) => this.onFollowUpChangeInput(e, questionIndex, followUp_questionId)} 
        data-gramm_editor="false" 
        type="text" 
        maxLength="250" 
        style={{ 
          width: "1095px", 
          height: "200px", 
          borderRadius: "6px", 
          backgroundColor: "#F7F5EB", 
          color: "#005c87", 
          placeholderColor: "#649bb3", 
          float: "none", 
        }} 
      /> 
      <ToggleWrapper> 
        <p> 
          {this.state.followUpDescription.length}/250 {"Words"} 
        </p> 
      </ToggleWrapper> 
    </TextAreaContainer> 
    )

  renderQuestionContainer = (questionList) => { 
    const { activeIndex, isFollowUp, isOptionSelected } = this.state; 
    if (activeIndex >= questionList.length) { 
      return <Waiting/> 
    }else{ 
      const question = questionList[this.state.activeIndex]; 
      return ( 
        <RenderSurveyQuestions> 
          <RenderQuestionContainer> 
            <QuestionContainer id={`question-container-${this.state.activeIndex + 1}`}> 
              <div> 
                {isFollowUp 
                  ? "Follow Up Question" 
                  : `Question ${this.state.activeIndex + 1}`} 
              </div> 
              <div style={{ marginBottom: "10px",wordBreak:"break-all",padding:"0px 30px" }}> 
                {isFollowUp ? question.follow_up_question : question?.question} 
              </div> 
            </QuestionContainer> 
            <div id={`question-separator-${this.state.activeIndex + 1}`} 
              style={{ 
                width: "100%", 
                border: "1px solid #669db7", 
                marginBottom: "35px", 
              }} 
            ></div> 
            {question.question_type_name === "likert-scale" && 
              !isFollowUp && 
              this.renderOptions(question.question_answers, question.id)} 
            {question.question_type_name === "yes-no" && 
              !isFollowUp && 
              this.renderYesNo(question.question_answers, question.id)} 
            {question.question_type_name === "multiple-choice" && 
              !isFollowUp && 
              this.renderMultipleChoices( 
                question.question_answers, 
                question.id, 
                question.accept_multiple_responses 
              )} 
            {question.question_type_name === "opinion-scale" && 
              !isFollowUp && 
              this.renderOpinionScale(question.question_answers, question.id)} 
            {question.question_type_name === "short-answer" && 
              !isFollowUp && 
              this.renderShortAnswer(question.question_answers, question.id)} 
            {isFollowUp && 
              this.renderFollowUp( 
                question.id, 
                question.follow_up_question_id 
              )} 
          </RenderQuestionContainer> 

          <ButtonContainer justify={this.state.activeIndex === 0}> 
            {this.state.activeIndex > 0 && ( 
              <CommonButton 
                btnType={"squareIcon"} 
                styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center"}} 
                onClick={() => this.onBack(this.state.activeIndex)} 
                title={t("Go Back")} 
                icon={Arrows()} 
              /> 
            )} 
            {question.question_type_name === "short-answer" ? ( 
              <div style={{ display: "flex", gap: "15px" }}> 

                <CommonButton 
                  btnType={"squareIcon"} 
                  onClick={() => this.onSubmit(this.state.activeIndex)} 
                  title={this.state.activeIndex + 1 === questionList.length? "Submit": "Next"} 
                  styles={{color:"#007AB1"}} 
                  nextIcon={this.state.activeIndex + 1 === questionList.length ? "" : NextArrows("white")} 
                /> 

              </div> 
            ) : isFollowUp ? ( 
              <div style={{ display: "flex", gap: "15px" }}> 

                <CommonButton 
                  btnType={"squareIcon"} 
                  onClick={() => 
                    this.onFollowUpSubmit( 
                      this.state.activeIndex 
                    )} 
                  title={this.state.activeIndex + 1 === questionList.length? "Submit": "Next"} 
                  styles={{color:"#007AB1"}} 
                  nextIcon={this.state.activeIndex + 1 === questionList.length ? "" : NextArrows("white")} 
                /> 

              </div> 
            ) : ( 
              <> 
                <CommonButton 
                  btnType={"squareIcon"} 
                  styles={{color:"#007AB1", marginAuto:'auto'}} 
                  onClick={() => 
                    this.onNext( 
                      this.state.activeIndex, 
                      question.follow_up_question_id 
                    )} 
                  title={this.state.activeIndex + 1 === questionList.length? "Submit": "Next"} 
                  nextIcon={this.state.activeIndex + 1 === questionList.length ? "" : NextArrows("white")} 
                  disabled={!isOptionSelected} 
                /> 
              </> 
            )} 
          </ButtonContainer> 
        </RenderSurveyQuestions> 
      ); 
    } 
  }; 

  renderQuestions = (questionList) => ( 
    <div>{this.renderQuestionContainer(questionList)}</div> 
  ); 

  onNext = (currentindex) => { 
    const { 
      selectedMCQ, 
      opinionValues, 
      textDescriptionArr, 
      selectedOption, 
      selectedYesNoOption, 
      finalPayload, 
      totalQuestions, 
      surveyData, 
      payload, 
      survey_Id, 
      isOptionSelected 
    } = this.state; 

    console.log("isOptionSelected",isOptionSelected) 

    if (isOptionSelected) { 
      const existingIndex = finalPayload.findIndex((item) => item.question_id === payload.question_id); 

      if (existingIndex !== -1) { 
        const updatedFinalPayload = [...finalPayload]; 
        updatedFinalPayload.splice(existingIndex, 1, payload); 
        this.setState({ finalPayload: updatedFinalPayload }); 
      } else { 
        this.setState((prevState) => ({ 
          finalPayload: [...prevState.finalPayload, payload] 
        })); 
      } 

      const nextIndex = currentindex + 1; 
      const isLastQuestion = nextIndex === totalQuestions; 

      this.setState({ activeIndex: nextIndex, isFollowUp: false }, () => { 
        const nextQuestion = surveyData[nextIndex]; 
        if (nextQuestion) { 
          if (nextQuestion.question_type_name === "multiple-choice") { 
            Object.keys(selectedMCQ).length && selectedMCQ[nextQuestion.id] && selectedMCQ[nextQuestion.id].length ? this.setState({ isOptionSelected: true }) : this.setState({ isOptionSelected: false }); 
          } else if (nextQuestion.question_type_name === "opinion-scale") { 
            Object.keys(opinionValues).length && opinionValues[nextQuestion.id] && opinionValues[nextQuestion.id].length ? this.setState({ isOptionSelected: true }) : this.setState({ isOptionSelected: false }); 
          } else if (nextQuestion.question_type_name === "short-answer") { 
            Object.keys(textDescriptionArr).length && textDescriptionArr[nextQuestion.id] && textDescriptionArr[nextQuestion.id].length ? this.setState({ isOptionSelected: true }) : this.setState({ isOptionSelected: false }); 
          } else if (nextQuestion.question_type_name === "likert-scale") { 
            Object.keys(selectedOption).length && selectedOption[nextQuestion.id] ? this.setState({ isOptionSelected: true }) : this.setState({ isOptionSelected: false }); 
          } else if (nextQuestion.question_type_name === "yes-no") { 
            Object.keys(selectedYesNoOption).length && selectedYesNoOption[nextQuestion.id] ? this.setState({ isOptionSelected: true }) : this.setState({ isOptionSelected: false }); 
          } else { 
            this.setState({ isOptionSelected: false }); 
          } 
        } 

        if (isLastQuestion) { 
          const newPayload = { 
            survey_id: survey_Id, 
            answers: this.state.finalPayload 
          }; 
          this.postSurveyResponse(newPayload); 
        } 
      }); 
    } 
  }; 

  onSkip = (currentindex) => { 
    const{selectedMCQ, opinionValues,textDescriptionArr, selectedOption, selectedYesNoOption } = this.state; 
    const answer_id = this.state.surveyData[currentindex]?.question_answers?.map((item) => item.id) 
    const skipPayload = { 
      survey_id: this.state.survey_Id, 
      question_id: this.state.surveyData[currentindex].question_id, 
      answer_id: answer_id[0].toString(), 
      skip: true, 
    }; 
    this.postSurveyResponse(skipPayload) 
    if(this.state.activeIndex+2 > this.state.totalQuestions){ 
      this.props.history.push({ 
        pathname: '/portal', 
        state: { completedSurveyPoint: 0 } 
      }); 
    } 
    this.setState({activeIndex: currentindex + 1}, ()=>{ 
      const question = this.state.surveyData[this.state.activeIndex]; 
      if(question.question_type_name === "multiple-choice"){ 
        Object.keys(selectedMCQ).length && selectedMCQ[question.question_id] && selectedMCQ[question.question_id].length  ? this.setState({isOptionSelected:true}) : this.setState({isOptionSelected:false}) 
      }else if(question.question_type_name === "opinion-scale"){ 
        Object.keys(opinionValues).length && opinionValues[question.question_id] && opinionValues[question.question_id].length  ? this.setState({isOptionSelected:true}) : this.setState({isOptionSelected:false}) 
      } 
      else if(question.question_type_name === "short-answer"){ 
        Object.keys(textDescriptionArr).length && textDescriptionArr[question.question_id] && textDescriptionArr[question.question_id].length  ? this.setState({isOptionSelected:true}) : this.setState({isOptionSelected:false}) 
      } 
      else if(question.question_type_name === "likert-scale"){ 
        Object.keys(selectedOption).length && selectedOption[question.question_id] ? this.setState({isOptionSelected:true}) : this.setState({isOptionSelected:false}) 
      }else if(question.question_type_name === "yes-no"){ 
        Object.keys(selectedYesNoOption).length && selectedYesNoOption[question.question_id] ? this.setState({isOptionSelected:true}) : this.setState({isOptionSelected:false}) 
      }else{ 
        this.setState({isOptionSelected:false}) 
      } 
    }); 
  }; 

  onSkipFollowup = (currentindex) => { 
    this.setState({ activeIndex: currentindex + 1, isOptionSelected: false, isFollowUp:false }) 
  } 

  onSubmit = (currentIndex) => { 
    const { surveyData, activeIndex } = this.state; 
    if (surveyData.length - 1 === activeIndex) { 
      this.handlePayloadUpdate(); 
    } 
    this.setState({ activeIndex: currentIndex + 1 }, () => { 
      const nextQuestion = surveyData[this.state.activeIndex]; 
      const isOptionSelected = this.checkOptionSelected(nextQuestion); 
      this.setState({ isOptionSelected }); 
    }); 
  }; 

  checkOptionSelected = (question) => { 
    if (!question) return false; 
    if (!question?.selectedAnswer) return false; 
    switch (question.question_type_name) { 
    case "multiple-choice": 
      return Array.isArray(question?.selectedAnswer) && question?.selectedAnswer.length > 0; 
    case "opinion-scale": 
      return typeof question?.selectedAnswer === 'string' && question?.selectedAnswer.trim() !== ""; 
    case "likert-scale": 
      return Array.isArray(question?.selectedAnswer) && question?.selectedAnswer.length > 0; 
    case "yes-no": 
      return typeof question?.selectedAnswer === 'string' && question?.selectedAnswer.trim() !== ""; 
    default: 
      return false; 
    } 
  }; 

  onFollowUpSubmit = (currentindex) => {
    // if(isFollowup && !this.state.isFollowUp){
    //   this.setState({isFollowUp: true})
    const question = this.state.surveyData[this.state.activeIndex]
    if(this.state.followUpPutDescriptionPayload.length > 0){
      // this.updateSurveyResponse(this.state.followUpPutDescriptionPayload);
    }else{
      let payload = []
      let newpayload = {
        question_id: question.follow_up_question_id ,
        answer: [{ value:"NA" , points: 0 }],
      }
      payload.push(newpayload)
      // this.updateSurveyResponse(payload)
    }
    this.setState({ activeIndex: currentindex + 1, isFollowUp:false });
    // }else{
    // }
  }

  onBack = (currentindex) => { 
    if(this.state.isFollowUp){ 
      this.setState({isOptionSelected: true, isFollowUp:false}) 
    }else{ 
      this.setState({ activeIndex: currentindex - 1 , isOptionSelected: true}); 
    } 
  }; 

  createStepIndicator = (currentStep, totalSteps) => { 
    const stepIndicator = []; 
    let step; 
    for (step = 0; step < totalSteps; step++) { 
      const isCompleted = step < currentStep; 
      const isActive = step === currentStep; 
      const stepper = ( 
        <StepIndicator 
          id={`step-indicator-${step + 1}`} 
          isActive={isActive} 
          isCompleted={isCompleted} 
        ></StepIndicator> 
      ); 
      stepIndicator.push(stepper); 
    } 
    return ( 
      <StepWrapper style={{ display: "flex", justifyContent: "center" }}> 
        {stepIndicator} 
      </StepWrapper> 
    ); 
  }; 

  render() { 
    return ( 
      <> 
        {this.state.isLoading ? <SkeletonLoder width={"1246px"} height={"100vh"} style={{margin:" 20px auto"}}/> :<div 
          ref={(takeSurvey) => (this.takeSurvey = takeSurvey)} 
          style={{ backgroundColor: "#F7F5EB" }} 
        > 
          {this.state.activeIndex+1 <= this.state.totalQuestions &&<SurveyTitle id="survey-title"> 
            <div>{this.state.surveyCategory}</div> 
            <div 
              style={{ 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center", 
                gap: "10px", 
                marginTop: "10px", 
              }} 
            > 
              {`${this.state.activeIndex + 1}/${this.state.totalQuestions}`} 
              {this.createStepIndicator( 
                this.state.activeIndex, 
                this.state.totalQuestions 
              )} 
            </div> 
          </SurveyTitle>} 
          <MainSurveyContainer id="main-survey-container"> 
            <SurveyQuestionsContainer id="survey-questions-container"> 
              {this.state.surveyData && this.state.surveyData.length > 0 
                ? this.renderQuestions(this.state.surveyData) 
                : null} 
            </SurveyQuestionsContainer> 
          </MainSurveyContainer> 
        </div>} 
      </> 
    ); 
  } 
} 

UserSurveyV1.propTypes = { 
  submitSurvey: PropTypes.func, 
  surveyQuestions: PropTypes.array, 
  surveyData: PropTypes.any, 
  choices: PropTypes.array, 
  hasSections: PropTypes.number, 
  location: PropTypes.object, 
  isLoading: PropTypes.bool, 
  launchSurveyDetails: PropTypes.object, 
  emptySurveyQuestion: PropTypes.func, 
  user: PropTypes.object, 
  staticSurveyStatus: PropTypes.number, 
  history: PropTypes.object 
}; 

export default withRouter(UserSurveyV1);
