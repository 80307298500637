import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Divider,
  PopupHeading,
  ModalContent,
  RadioButton,
  // TemplateImage,
  Button,
  //   Label,
  RadioButtonWrapper,
  StyledModal,
  FieldTitle,
  AdminIconContainer,
  Container,
  TitleContainer,
  CustomCheckbox,
} from "./styles";
import {
  ManagerArrowIcon,
  AdminCloseIcon,
  // ManagerDropDownIcon,
  AdminLeftArrowIcon,
  WhiteDownloadIcon,
} from "../../utils/icons";
import { Select, MenuItem, InputLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";

// const CategoryData = [
//   {
//     id: 1,
//     value: "Top Performing Departments",
//   },
//   {
//     id: 2,
//     value: "Low Performing Departments",
//   },
//   {
//     id: 3,
//     value: "Top Recognized | Departments' Spotlight on Success",
//   },
//   {
//     id: 4,
//     value: "Elite Recognition Boosters | Departments' Spotlight on Success",
//   },
// ];

const DownloadReportsModal = ({ show, onClose }) => {
  const [page, setPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  //   const [selectAll, setSelectAll] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedCoreValue, setSelectedCoreValue] = useState(null);
  const [selectedStartMonth, setSelectedStartMonth] = useState(null);
  const [selectedEndMonth, setSelectedEndMonth] = useState(null);
  const [categoryData] = useState([{
    id: 1,
    value: "Top Performing Departments",
  },
  {
    id: 2,
    value: "Low Performing Departments",
  },
  {
    id: 3,
    value: "Top Recognized | Departments' Spotlight on Success",
  },
  {
    id: 4,
    value: "Elite Recognition Boosters | Departments' Spotlight on Success",
  },
  ]);


  const [departmentsData] = useState([{
    id: 1,
    value: "Product",
  },
  {
    id: 2,
    value: "Tech",
  },
  {
    id: 3,
    value: "Quality Assurance",
  },
  {
    id: 4,
    value: "Customer Success",
  },
  {
    id: 5,
    value: "Sales & Marketing",
  },
  {
    id: 6,
    value: "Operations Team",
  },
  {
    id: 7,
    value: "IT Support Team",
  },
  {
    id: 8,
    value: "Finance Team",
  },
  ]);
  

  const [locationData] = useState([
    { id: 1, value: "Pune, India" },
    { id: 2, value: "New York, USA" },
    { id: 3, value: "Sydney, Australia" },
    { id: 4, value: "London, England" },
    { id: 5, value: "Paris, France" },
  ]);

  const [yearData] = useState([{
    id: 1,
    value: "2024",
  },
  {
    id: 2,
    value: "2023",
  },
  {
    id: 3,
    value: "2022",
  },
  {
    id: 4,
    value: "2021",
  },
  ]);


  const [quaterData] = useState([{
    id: 1,
    value: "Jan-Mar | Q1",
  },
  {
    id: 2,
    value: "Apr-Jun | Q2",
  },
  {
    id: 3,
    value: "Jul-Sep | Q3",
  },
  {
    id: 4,
    value: "Oct-Dec | Q4",
  },
  ]);




  const [MonthData] = useState([{
    id: 1,
    value: "January",
  },
  {
    id: 2,
    value: "February",
  },
  {
    id: 3,
    value: "March",
  },
  {
    id: 4,
    value: "April",
  },
  {
    id: 5,
    value: "May",
  },
  {
    id: 6,
    value: "June",
  },
  {
    id: 7,
    value: "July",
  },
  {
    id: 8,
    value: "August",
  },
  {
    id: 9,
    value: "September",
  },
  {
    id: 10,
    value: "October",
  },
  {
    id: 11,
    value: "November",
  },
  {
    id: 12,
    value: "December",
  },
  ]);

  
  //   const handleSelect = (categortValue) => {
  //     setSelectedCoreValue(categortValue);
  //   };
  
  //   const handleChange = (event) => {
  //     const index = event.target.value;
  //     const selectedData = categoryData?.[index];
  //     setSelectedCoreValue(index)
  //     if (selectedData) {
      
  //       //   setSelectedCoreValue(selectedData.value);
  //       handleSelect(selectedData.value);
  //     }
  //   };


  const handleChange = (event) => {
    const selectedValue = event.target.value; // Get the selected value
    setSelectedCoreValue(selectedValue); // Update the state with the selected value
  };



  const handleMonthChange = (event) => {
    const selectedValue = event.target.value; // Get the selected value
    setSelectedStartMonth(selectedValue); // Update the state with the selected value
  };


  const handleEndMonthChange = (event) => {
    const selectedValue = event.target.value; // Get the selected value
    setSelectedEndMonth(selectedValue); // Update the state with the selected value
  };


  //   useEffect(() => {
  //     setCategoryData(CategoryData)
  //   }, [])
  

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };



  const handleTypeChange = (value) => {
    setSelectedType(value);
  };


  useEffect(() => {}, []);

  const handleNext = () => {
    if (page === 1) setPage(2);
  };

  const handleBack = () => {
    if (page === 2) setPage(1);
  };

  const handleDepartmentChange = (id) => {
    setSelectedDepartments((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  // Handle location selection
  const handleLocationChange = (id) => {
    setSelectedLocations((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  // Handle "Select All" checkbox for departments
  const handleSelectAllDepartments = () => {
    if (selectedDepartments.length === departmentsData.length) {
      setSelectedDepartments([]);
    } else {
      setSelectedDepartments(departmentsData.map((dept) => dept.id));
    }
  };

  // Handle "Select All" checkbox for locations
  const handleSelectAllLocations = () => {
    if (selectedLocations.length === locationData.length) {
      setSelectedLocations([]);
    } else {
      setSelectedLocations(locationData.map((loc) => loc.id));
    }
  };


  const handlePost = () => {
    onClose();
  };

  return (
    <StyledModal show={show} onHide={onClose} centered width="800px">
      <ModalContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 24px",
          }}
        >
          <PopupHeading>Download Analytics Reports</PopupHeading>
          <AdminIconContainer
            isWidth={"32px"}
            isHeight={"32px"}
            style={{ cursor: "pointer" }}
            onClick={onClose}
          >
            {AdminCloseIcon()}
          </AdminIconContainer>
        </div>
        <Divider isMargin={"16px 0px"}></Divider>
        {page === 1 && (
          <Container>
            <FieldTitle  isFont={"Lato"}>
              {"Would you like to download analytics reports by?"}
            </FieldTitle>
            <RadioButtonWrapper>
              <div className="RadioButtonContainer ">
                <RadioButton
                  type="radio"
                  name="option"
                  checked={selectedOption === "departments"}
                  onChange={() => handleOptionChange("departments")}
                />
                <div className="label">Departments</div>
              </div> 
              <div className="RadioButtonContainer ">
                <RadioButton
                  type="radio"
                  name="option"
                  checked={selectedOption ==="location" }
                  onChange={() => handleOptionChange("location")}
                />
                <div className="label">Locations</div>
              </div>
            </RadioButtonWrapper>

            <Divider
              isMargin={"24px 0px"}
            ></Divider>

            <TitleContainer
            //   isPadding={"12px 24px 0px 24px"}
              padding="0px !important"
              heightV="240px"
              margin="0px"
              manager
            >
              <FieldTitle isFont={"Lato"} manager color={"#184A61"}>
              Which reports would you like to download?
              </FieldTitle>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  // marginTop: "12px",
                  borderRadius: "4px",
                  padding: "14px 16px",
                  "  & .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#BDBDBD",
                    },
                    "&:hover fieldset": {
                      borderColor: "#BDBDBD",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#F37A65",
                    },
                  },
                }}
              >
                <InputLabel
                  id="select-recognition-label"
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "16px",
                    marginLeft:"24px",
                    marginTop:"10px",
                    color: "#BDBDBD",
                    "&.Mui-focused": {
                      color: "#F37A65",
                    },
                  }}
                >
                Select category
                </InputLabel>
                <Select
                  labelId="select-recognition-label"
                  id="select-recognition"
                  value={selectedCoreValue}
                  onChange={handleChange}
                  label="Select recognition"
                  sx={{
                    color: "#184A61",
                    width: "100%",
                    height:"48px",
                    padding:"14px 16px",
                    "& .MuiSelect-icon": {
                      color: "#F37A65",
                      top: "50%",
                      right: "12px", 
                      transform: "translateY(-50%)"
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: 200,
                        width: 300,
                        "&::-webkit-scrollbar": {
                          width: "4px",
                          height: "100px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#BDBDBD",
                          borderRadius: "5.5px",
                        },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "white",
                        },
                      },
                    },
                    MenuListProps: {
                      sx: {
                        paddingTop: 0,
                        paddingBottom: 0,
                      },
                    },
                  }}
                >
                  {(Array.isArray(categoryData)
                    ? categoryData
                    : []
                  ).map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.value}
                      sx={{
                        color: "#184A61",
                        padding: "14px 16px",
                        "&:hover": { backgroundColor: "light blue" },
                      }}
                    >
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TitleContainer>

            {(selectedOption === "departments" || selectedOption === "location") ? <Divider
              isMargin={"24px 0px"}
            ></Divider> :null}

            {selectedOption === "departments" && (
              <div style={{ marginBottom: "16px" }}>
                <div style={{display:"flex",flexDirection:"column"}}><FieldTitle  isFont={"Lato"}>
                  Select the departments for which you want to download reports:
                </FieldTitle>
                <div style={{ margin: "8px 0" }}>
                  <label style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <CustomCheckbox
                      type="checkbox"
                      checked={selectedDepartments.length === departmentsData.length}
                      onChange={handleSelectAllDepartments}
                    />
                    <span style={{ color:"#092F41"}}>Select All</span>
                  </label>
                </div>
                </div>
                <Divider isMargin={"12px 0px"}></Divider>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
                  {departmentsData.map((dept) => (
                    <div key={dept.id} style={{ flexBasis: "calc(33.33% - 16px)" }}>
                      <label style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <CustomCheckbox
                          type="checkbox"
                          checked={selectedDepartments.includes(dept.id)}
                          onChange={() => handleDepartmentChange(dept.id)}
                        />
                        <span style={{ color:"#092F41"}}>{dept.value}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {selectedOption === "location" && (
              <div style={{ marginBottom: "16px" }}>
                <div style={{display:"flex",flexDirection:"column"}}><FieldTitle isFont={"Lato"}>
                  Select the locations for which you want to download reports:
                </FieldTitle>
                <div style={{ margin: "8px 0" }}>
                  <label style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <CustomCheckbox
                      type="checkbox"
                      checked={selectedLocations.length === locationData.length}
                      onChange={handleSelectAllLocations}
                    />
                    <span style={{ color:"#092F41"}}>Select All</span>
                  </label>
                </div>
                </div>
                <Divider isMargin={"12px 0px"}></Divider>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
                  {locationData.map((loc) => (
                    <div key={loc.id} style={{ flexBasis: "calc(33.33% - 16px)" }}>
                      <label style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <CustomCheckbox
                          type="checkbox"
                          checked={selectedLocations.includes(loc.id)}
                          onChange={() => handleLocationChange(loc.id)}
                        />
                        <span style={{ color:"#092F41"}} >{loc.value}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
            
            <Divider isMargin={"24px 0px 16px 0px "}></Divider>

            <Button onClick={handleNext}>
              Next{" "}
              <AdminIconContainer isWidth={"16px"} isHeight={"16px"}>
                {ManagerArrowIcon()}
              </AdminIconContainer>
            </Button>
          </Container>
        )}
        {page === 2 && (
          <>
            <Container>
              <TitleContainer
              //   isPadding={"12px 24px 0px 24px"}
                padding="0px !important"
                heightV="240px"
                margin="0px"
                manager
              >
                <FieldTitle isFont={"Lato"} manager color={"#184A61"}>
                For which year would you like to download reports?
                </FieldTitle>
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{
                    // marginTop: "12px",
                    borderRadius: "4px",
                    padding: "14px 16px",
                    "  & .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#BDBDBD",
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#F37A65",
                      },
                    },
                  }}
                >
                  <InputLabel
                    id="select-recognition-label"
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "16px",
                      marginLeft:"24px",
                      marginTop:"10px",
                      color: "#BDBDBD",
                      "&.Mui-focused": {
                        color: "#F37A65",
                      },
                    }}
                  >
                  Select year
                  </InputLabel>
                  <Select
                    labelId="select-recognition-label"
                    id="select-recognition"
                    value={selectedCoreValue}
                    onChange={handleChange}
                    label="Select recognition"
                    sx={{
                      color: "#184A61",
                      width: "100%",
                      height:"48px",
                      padding:"14px 16px",
                      "& .MuiSelect-icon": {
                        color: "#F37A65",
                        top: "50%",
                        right: "12px", 
                        transform: "translateY(-50%)"
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 200,
                          width: 300,
                          "&::-webkit-scrollbar": {
                            width: "4px",
                            height: "100px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#BDBDBD",
                            borderRadius: "5.5px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "white",
                          },
                        },
                      },
                      MenuListProps: {
                        sx: {
                          paddingTop: 0,
                          paddingBottom: 0,
                        },
                      },
                    }}
                  >
                    {(Array.isArray(yearData)
                      ? yearData
                      : []
                    ).map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.value}
                        sx={{
                          color: "#184A61",
                          padding: "14px 16px",
                          "&:hover": { backgroundColor: "light blue" },
                        }}
                      >
                        {item.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TitleContainer>
           
              <Divider isMargin={"16px 24px"} isWidth={"94%"}></Divider>
  
              <FieldTitle  isFont={"Lato"}>
              For which time intervals would you like to download reports?
              </FieldTitle>
              <RadioButtonWrapper>
                <div className="RadioButtonContainer ">
                  <RadioButton
                    type="radio"
                    name="option"
                    checked={selectedType === "monthly"}
                    onChange={() => handleTypeChange("monthly")}
                  />
                  <div className="label">Monthly</div>
                </div> 
                <div className="RadioButtonContainer ">
                  <RadioButton
                    type="radio"
                    name="option"
                    checked={selectedType ==="quarterly" }
                    onChange={() => handleTypeChange("quarterly")}
                  />
                  <div className="label">Quarterly</div>
                </div>
                <div className="RadioButtonContainer ">
                  <RadioButton
                    type="radio"
                    name="option"
                    checked={selectedType ==="annually" }
                    onChange={() => handleTypeChange("annually")}
                  />
                  <div className="label">Annually</div>
                </div>
              </RadioButtonWrapper>

              {selectedType === "monthly" ? (<div
                style={{
                  display: "flex", 
                  gap: "24px", 
                  width: "100%",
                  marginTop:"24px" 
                }}>
                <TitleContainer
                  padding="0px !important"
                  heightV="240px"
                  margin="0px"
                  manager
                  // isWidth={"40% !important"}
                >
                  <FormControl
                    variant="outlined"
                    sx={{
                      // marginTop: "12px",
                      borderRadius: "4px",
                      padding: "14px 16px",
                      "  & .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#BDBDBD",
                        },
                        "&:hover fieldset": {
                          borderColor: "#BDBDBD",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#F37A65",
                        },
                      },
                    }}
                  >
                    <InputLabel
                      id="select-recognition-label"
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "16px",
                        marginLeft:"24px",
                        marginTop:"10px",
                        color: "#BDBDBD",
                        "&.Mui-focused": {
                          color: "#F37A65",
                        },
                      }}
                    >
                     Select start month
                    </InputLabel>
                    <Select
                      labelId="select-recognition-label"
                      id="select-recognition"
                      value={selectedStartMonth}
                      onChange={handleMonthChange}
                      label="Select recognition"
                      sx={{
                        color: "#184A61",
                        width: "100%",
                        height:"48px",
                        padding:"14px 16px",
                        "& .MuiSelect-icon": {
                          color: "#F37A65",
                          top: "50%",
                          right: "12px", 
                          transform: "translateY(-50%)"
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: 200,
                            width: 300,
                            "&::-webkit-scrollbar": {
                              width: "4px",
                              height: "100px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#BDBDBD",
                              borderRadius: "5.5px",
                            },
                            "&::-webkit-scrollbar-track": {
                              backgroundColor: "white",
                            },
                          },
                        },
                        MenuListProps: {
                          sx: {
                            paddingTop: 0,
                            paddingBottom: 0,
                          },
                        },
                      }}
                    >
                      {(Array.isArray(MonthData)
                        ? MonthData
                        : []
                      ).map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item.value}
                          sx={{
                            color: "#184A61",
                            padding: "14px 16px",
                            "&:hover": { backgroundColor: "light blue" },
                          }}
                        >
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TitleContainer>
                 
                <TitleContainer
                //   isPadding={"12px 24px 0px 24px"}
                  padding="0px !important"
                  heightV="240px"
                  margin="0px"
                  manager
                  // isWidth={"40% !important"}
                >
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      // marginTop: "12px",
                      borderRadius: "4px",
                      padding: "14px 16px",
                      "  & .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#BDBDBD",
                        },
                        "&:hover fieldset": {
                          borderColor: "#BDBDBD",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#F37A65",
                        },
                      },
                    }}
                  >
                    <InputLabel
                      id="select-recognition-label"
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "16px",
                        marginLeft:"24px",
                        marginTop:"10px",
                        color: "#BDBDBD",
                        "&.Mui-focused": {
                          color: "#F37A65",
                        },
                      }}
                    >
                  Select end month
                    </InputLabel>
                    <Select
                      labelId="select-recognition-label"
                      id="select-recognition"
                      value={selectedEndMonth}
                      onChange={handleEndMonthChange}
                      label="Select recognition"
                      sx={{
                        color: "#184A61",
                        width: "100%",
                        height:"48px",
                        padding:"14px 16px",
                        "& .MuiSelect-icon": {
                          color: "#F37A65",
                          top: "50%",
                          right: "12px", 
                          transform: "translateY(-50%)"
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: 200,
                            width: 300,
                            "&::-webkit-scrollbar": {
                              width: "4px",
                              height: "100px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#BDBDBD",
                              borderRadius: "5.5px",
                            },
                            "&::-webkit-scrollbar-track": {
                              backgroundColor: "white",
                            },
                          },
                        },
                        MenuListProps: {
                          sx: {
                            paddingTop: 0,
                            paddingBottom: 0,
                          },
                        },
                      }}
                    >
                      {(Array.isArray(MonthData)
                        ? MonthData
                        : []
                      ).map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item.value}
                          sx={{
                            color: "#184A61",
                            padding: "14px 16px",
                            "&:hover": { backgroundColor: "light blue" },
                          }}
                        >
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TitleContainer>
              </div>) :null}


              { selectedType === "quarterly" ?
                <TitleContainer
                //   isPadding={"12px 24px 0px 24px"}
                  padding="0px !important"
                  heightV="240px"
                  margin="24px 0px 0px 0px"
                  manager
                >
               
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      // marginTop: "12px",
                      borderRadius: "4px",
                      padding: "14px 16px",
                      "  & .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#BDBDBD",
                        },
                        "&:hover fieldset": {
                          borderColor: "#BDBDBD",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#F37A65",
                        },
                      },
                    }}
                  >
                    <InputLabel
                      id="select-recognition-label"
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "16px",
                        marginLeft:"24px",
                        marginTop:"10px",
                        color: "#BDBDBD",
                        "&.Mui-focused": {
                          color: "#F37A65",
                        },
                      }}
                    >
                    Select quarter
                    </InputLabel>
                    <Select
                      labelId="select-recognition-label"
                      id="select-recognition"
                      value={selectedCoreValue}
                      onChange={handleChange}
                      label="Select recognition"
                      sx={{
                        color: "#184A61",
                        width: "100%",
                        height:"48px",
                        padding:"14px 16px",
                        "& .MuiSelect-icon": {
                          color: "#F37A65",
                          top: "50%",
                          right: "12px", 
                          transform: "translateY(-50%)"
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: 200,
                            width: 300,
                            "&::-webkit-scrollbar": {
                              width: "4px",
                              height: "100px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#BDBDBD",
                              borderRadius: "5.5px",
                            },
                            "&::-webkit-scrollbar-track": {
                              backgroundColor: "white",
                            },
                          },
                        },
                        MenuListProps: {
                          sx: {
                            paddingTop: 0,
                            paddingBottom: 0,
                          },
                        },
                      }}
                    >
                      {(Array.isArray(quaterData)
                        ? quaterData
                        : []
                      ).map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item.value}
                          sx={{
                            color: "#184A61",
                            padding: "14px 16px",
                            "&:hover": { backgroundColor: "light blue" },
                          }}
                        >
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TitleContainer>: null
              }

            </Container>
            <Divider isMargin={"24px  24px 16px 24px"} isWidth={"94%"}></Divider>
  
            <div style={{ margin: "auto", display: "flex", gap: "16px" }}>
              <Button
                isBg={true}
                onClick={handleBack}
                color="red"
                hoverColor="#5a6268"
              >
                <AdminIconContainer isWidth={"16px"} isHeight={"16px"}>
                  {AdminLeftArrowIcon()}
                </AdminIconContainer>{" "}
                Back
              </Button>
              <Button onClick={handlePost}>
                Download
                <AdminIconContainer isWidth={"16px"} isHeight={"16px"}>
                  {WhiteDownloadIcon()}
                </AdminIconContainer>
              </Button>
            </div>
          </>
        )}
      </ModalContent>
    </StyledModal>
  );
};

DownloadReportsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  //   modalData: PropTypes.func.isRequired,
  //   recognitionType: PropTypes.func.isRequired,
  //   loggedInUser: PropTypes.object.isRequired,
  //   fetchMyTaskData: PropTypes.func.isRequired,
};

export default DownloadReportsModal;
