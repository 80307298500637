import { Modal } from "react-bootstrap";
import styled from "styled-components";

// const RegularFont = 'Rubik-Regular';
// const BoldFont = 'Rubik-Medium';
// const ExtraBold = 'Rubik-Bold';

const MediumFont = "Lato";

export const StyledBody = styled(Modal.Body)`
  padding: 0px !important;
`;
export const StyledModal = styled(Modal)`
  .modal-dialog {
    ${'' /* width: 360px; */}
    width: ${({ isWidth }) => ( isWidth ? "644px" : "360px")};

  }
  .modal-content {
    border-radius: 6px;
    max-height: ${({ isHeight }) => ( isHeight ? "644px" : "508px")};
    ${'' /* min-height: 644px; */}
  }
  .modal-header {
    ${"" /* background:#005C87; */}
    border-radius:6px 6px 0px 0px;
    padding: 16px 16px 0px 16px;
    ${"" /* height:50px; */}
    .close {
      color: white;
      opacity: inherit;
    }
    span {
      color: white;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      font-family: ${MediumFont};
    }
  }
  .modal-backdrop.in {
    opacity: 0.2;
  }
  .modal-dialog {
    transform: translate(-50%, -50%);
  }
  .info-popup {
    top: 30%;
    width: ${({ isWidth }) => ( isWidth ? "468px" : "485px")};
    ${'' /* width: 468px; */}
    margin: 0 auto;
    .modal-header {
      border-bottom: 0px;
    }
  }

  .manager-popup{
    top: 1%;

  }
  @media (max-width: 500px) {
    .modal-dialog {
      width: 80%;
    }
  }
  @media (min-width: 768px) {
    .modal-content {
      margin-left: ${({ height }) => (height ? "-150px" : "0px")};
      margin-top: ${({ height }) => (height ? "-150px" : "0px")};
    }
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1246px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "40px")};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .header-text {
    color: #184a61 !important;
    font-family: "Lato" !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    margin-bottom:16px  !important;
  }

  .report-title{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export const CloseIcon = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

export const RecognitionsContainer = styled.div`
  width: 100%;
  padding: 16px;
  max-height: 485px;
  overflow-y: scroll;

  .top-card {
    padding: 12px 16px 12px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .user-name {
    color: #184a61;
    /* Primary - Lato/Body-Text */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
  }

  .col-one {
    display: flex;
    align-items: center;
  }

  .mid-col {
    margin-left: 12px;
  }

  }

  .total-count {
    color: #184a61;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 150% */
  }

    .recognition-card {
        padding: 8px 12px 8px 8px;
        display: flex;  
        justify-content: space-between;
        align-items: center;

        .col-one{
            display: flex;
            align-items: center;
        }

        .name{
            margin-left: 12px;
            color:  #184A61;

font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
        }

    }
`;

export const DateRangePickerContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
`
