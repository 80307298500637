import styled, { /*css*/ } from "styled-components";

const TabsWrapper = styled.div`
  width: 100%;
  display:flex;
  background: ${({background}) => background ? "transparent" : 'white'};
  padding: ${({padding}) => padding ? padding : '15px'};
  margin: ${({margin}) => margin ? margin : '0'};
  align-items:center;
`;

const TabsStyle = styled.div`
  padding: ${({padding}) => padding ? padding : '12px'};
  width: auto;
  display:flex;
  background: ${({active}) => active ? "rgb(242, 247, 249)" : 'transparent'};
  color: ${({active}) => active ? "rgb(0, 92, 135)" : 'rgb(102, 157, 183)'};
  font-family: ${({active}) => active ? "Rubik-Medium" : 'Rubik-Regular'};
  font-size: ${({active}) => active ? "16px" : '16px'};
  line-height: 19px;
  margin: ${({margin}) => margin ? margin : '0 25px 0 0'};
  justify-content:center;
  align-items:center;
  justify-content:center;
  border-radius:3px;
  cursor:pointer;
  border-radius:6px;
  max-height:37px;
`;

const ToolTip = styled.div`
display:flex;
margin: ${({margin}) => margin ? margin : '0 35px 0 0'};
width: ${({width}) => width ? width : '60px'};
height: ${({height}) => height ? height : '60px'};
cursor:pointer;
}
>div{
  width:40px;
  height:40px;
  display:flex;
  margin:auto;
  align-items: center;
  justify-content: center;
  background: ${({active, activeValue, transparent}) =>transparent?'none': activeValue?activeValue: active ? "#005C87" : '#f2f7f9'};
  border-radius:6px;
  padding-right:${({padding}) => padding ? "5px" : ''};
  >img{
    width:24px;
    height:24px;
  }
  >svg{
    fill-opacity:  ${({activeOpacity}) =>activeOpacity?"0.6" : ''};
    >path{
      fill: ${({ fill }) => fill};
      fill-opacity:  ${({activeOpacity}) =>activeOpacity?"0.6" : ''};
    }
    >svg{
      fill-opacity:  ${({activeOpacity}) =>activeOpacity?"0.6" : ''};
      >path{
        fill: ${({ fill }) => fill};
      }
      >g{
        >g{
          fill: ${({ fill }) => fill};
        }
        >path{
          fill: ${({ fill, active }) => active?"white": fill};
        }
      }
    }
  }
}
`;

const StyledSvg = styled.svg`
  height: ${({height}) => height ? height : '30px'};
  width: ${({width}) => width ? width : '30px'};
  float: left;
  fill: ${({ showCompany, show }) => show?show:showCompany ? 'transparent' : '#619ab5'};
  padding: ${({ padding }) => padding ? padding : '2px 0px 0px'};
  margin: ${({marginTop}) => marginTop && marginTop };
  margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '0px'}
  pointer-events: none;
  >svg{
    >path{
      fill: ${({ active,activeValue }) => activeValue?activeValue:active ? 'white' : '#619ab5'};
    }
    >g{
      >path{
        fill: ${({ active,activeValue }) => activeValue?activeValue:active ? 'white' : '#619ab5'};
      }
      >g{
        fill: ${({ active,activeValue }) => activeValue?activeValue:active ? 'white' : '#619ab5'};
      }
    }
  }
`;

const BuddyTab = styled.div`
display: flex;
min-width: ${({width}) => width ? "auto" : '114px'};
height: 40px;
padding: ${({padding}) => padding ? "10px 15px" : ''};
background: ${({active}) => active ? '#007AB1' : 'white'};
border-radius: 6px;
font-family: ${({active}) => active ? 'Rubik-Medium' : 'Rubik'};
font-size: 16px;
font-weight: 500;
line-height: 20px;
color: ${({active}) => active ? '#FFFFFF' : '#007AB1'};
margin: ${({margin}) => margin ? margin : 'auto auto auto 12px'};
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
justify-content: center;
align-items: center;
cursor: pointer;
:hover{
  background:#005C87;
  color:#ffff;
}
`;

const AboutSection = styled.div`
  width:100:%
  display:block;
  margin-top: 20px;
  .about-heading{
    color: #005C87;
    font-family: Rubik-Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom:12px;
  }
  .details{
    color: #005C87;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: justify;
    margin: 0px;
    >p{
      margin: 0px;
    }
    >b{
      margin: 0px;
    }
  }
`;

const MuiltiDropdown = styled.div`
  display: flex;
  margin: auto;
  margin-right: 0px;
  .dropdown-menu{
    min-width:100px;
  }
  div{
    .dropdown{
      .sub-menu-wrapper{
        width: 172px;
        display:flex;
        position: absolute;
        z-index:99999;
        right:298%;
        top: 60px
        .leftcard{
         width: 10px;
         display:flex;
        }
        .menuData{
          width: 162px;
          background: white;
          z-index:99999;
          left: -10px;
          top: 50px
          border: 1px solid rgba(0,92,135,0.30);
          border-radius: 6px;
          padding: 0px;
          .sub-menu{
            color: #007AB1;
            font-family: Rubik-medium;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            padding: 12px 0px 12px 0px;
            cursor:pointer;
            margin-left: 12px;
          }
          hr{
            margin: 0px 12px;
          }
        }
      }
      button{
        background: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        margin-top: ${(isEvent)=> isEvent && '3px'};
        ${'' /* padding-left: ${(isEvent)=> isEvent && '0px'}; */}
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 0px;
        margin-left: 0px;
        &:active{
          background: none;
          border: none;
        }
      }
      .dropdown-menu.show{
        display: inline-flex;
        padding: 4px;
        justify-content: center;
        align-items: center;
        transform: translate3d(-13px, 50px, 0px);
        border-radius: 4px;
        border: 1px solid rgba(0, 92, 135, 0.30);
        background: #FFF;
        display: flex;
        flex-direction: column;
        margin-left: -140px;
        width: 188px !important;
        a{
          color: #007AB1;
          font-family: Rubik-medium;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          display:flex;
          padding:8px;
          >svg{
            display:flex;
            margin:auto 0 auto auto;
            transform:rotate(0deg);
            margin-right:8px;
          }
          &:active{
            background: none;
          }
        }
        hr{
          width: 90%;
          color: rgba(0, 92, 135, 0.50);
          margin: 4px 0px;
        }
      }
    }
  }
`;

const BrandingTab = styled.div`
display: flex;
padding: ${({padding}) => padding ? "10px 15px" : '10px 16px'};
background: ${({active, background}) => active ? background : 'white'};
border-radius: 6px;
font-family: ${({active}) => active ? 'Lato' : 'Lato'};
font-size: 16px;
font-weight: 500;
line-height: 20px;
color: ${({active}) => active ? 'white' : '#184A61'};
margin: ${({margin}) => margin ? margin : '8px 12px'};
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
.flex-item:not(:nth-child(1n)) {
  margin-left: 0;
}
&:nth-child(1n){
    margin-left: 0;
  }
justify-content: center;
align-items: center;
cursor: pointer;

:hover{
  color:white;
  background: ${({background}) => background ? background : 'CalmTeal/500'};
  ${'' /* font-family:Rubik-Medium; */}
}
}
`;

const TopDataGraphMain = styled.div`
width:100%;
display:flex;
flex-wrap:wrap;
padding: 16px;
background:white;
display:block;
border-radius:12px;
.heading{
  width:100%;
  display:flex;
  color: #184A61;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-bottom:24px;
  border-bottom: 1px solid #EFEFEF;
}
.values-data{
  width:100%;
  display:flex;
  margin-top:20px;
  .value{
    color: #184A61;
    font-family: Lato;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 68px;
    display:flex;
    align-items:center;
    margin-right:8px;
  }
  .text{
    color: #DCDCDC;
    font-family: Lato;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 68px;
    display:flex;
    align-items:center;
  }
}
.category{
  display:flex;
  width:100%;
  margin-top:8px;
  .wrapper{
    display:flex;
    margin-right:20px;
    .color{
      width: 8px;
      height: 8px;
      display:flex;
      background: ${({background}) => background ? background : '#FCC000'};
      border-radius: 50%;
      margin: auto 4px auto auto;
    }
    .name{
      color: #184A61;
      text-align: center;
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      display: flex;
      align-items: center;
    }
  }
}
.graph{
  display:flex;
  width:100%;
  margin-top:28px;
}
.low-performing-section{
  display:flex;
  width:100%;
  justify-content: space-between;
  .text-changes{
    display:block;
    .name{
      display:flex;
      margin-bottom:20px;
      .circle{
        width:8px;
        height:8px;
        background: ${({background}) => background ? background : '#FCC000'};
        margin: auto 4px auto 0;
        display:flex;
        border-radius:50%;
      }
      .text{
        font-family: Lato;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        color: #184A61;
        display:flex;
      }
    }
  }
  .circle{
    width:260px
  }
}

`;

const CircleGraph = styled.div`
width: ${({width}) => width ? `${width}px` : '100px'};
height: ${({width}) => width ? `${width}px` : '100px'};
display:flex;
align-items:center;
justify-content:center;
background: ${({background}) => background ? background : '#FCC000'};
color: ${({color}) => color ? color : '#FCC000'};
font-family: Lato;
font-size: ${({small}) => small ? '24px' : '32px'};
font-style: normal;
font-weight: 700;
line-height: ${({small}) => small ? '36px' : '52px'};
position: relative;
margin: ${({margin}) => margin ? margin : 'auto'};
border-radius:50%;
justify-content: center;
align-items:center;
`;

const BrandingTabContainer = styled.div`
width:100%;
display:flex;
padding: ${({padding}) => padding ? padding : '8px'};
background:white;
border-radius:6px;
margin-bottom: 20px

.wrapper{
  display:flex;
  margin:auto auto auto 0px;
}
.dropdownwrapper{
  display:flex;
  margin:auto 0px auto auto;
}
.rightSection{
  display:flex;
  gap:6px;
}
.compare{
  display:flex;
  ${'' /* margin: auto 16px auto auto; */}
  margin: ${({isCompare}) => isCompare ? isCompare : 'auto 16px auto auto;'};
}
`;

const ContainerMain = styled.div`
  width: 100%;
  padding: 25px;
  .content-wrap{
    width: 100%;
    max-width: 1248px;
    margin: auto;
  }
`;

const ScrollableContainer = styled.div`
  max-height: 400px;
  max-height: ${({ height }) => (height ? height : "400px")};
  overflow-y: ${({ showAllRows }) => (showAllRows ? "auto" : "hidden")};

  ::-webkit-scrollbar {
    width: 4px;
    height: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 4px;
  }
`;

const Table = styled.div`
width:100%;
display:block;
padding: ${({paddingM}) => paddingM ? paddingM : '16px 16px 8px 16px'};
flex-wrap:wrap;
background:white;
border-radius:12px;
margin: ${({margin}) => margin ? margin : '0'};
.wrap{
  display:flex;
  flex-wrap:wrap;
  gap:8px;
}
.manager-wrap{
  height: ${({height}) => height ? height : ''};
  display:flex;
  flex-wrap:wrap;
  overflow-y: ${({scroll}) => scroll ? 'scroll' : ''}  
  ::-webkit-scrollbar {
    width: 5px;
    height: 45px;
  }
  ::-webkit-scrollbar-thumb {
    background: #c6c2c2;
    cursor: pointer;
    border-radius: 2.5px;
  }

  ::-webkit-scrollbar-track {
    background: #f2f0f0;
    border-radius: 2.5px;
  }
}
.heading{
  color: #184A61;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  width:100%;
  padding-bottom:20px
  margin-bottom:20px;
  border-bottom: 1px solid #EFEFEF;
  display:flex;
  align-items:center;

  .icon{
width: 48px;
height: 48px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
background: ${({headingIconBg}) => headingIconBg ? headingIconBg : '#75B084'};
margin-right: 12px; 
}
}

.type{
  width:100%;
  display:flex;
  justify-content:space-between;
  border-radius: 4px;
  background: #F8F8F8;
  margin-bottom:12px;
  padding:4px;
  border-radius:4px;
  .name{
    color: #116D8D;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .value{
    color: #116D8D;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
.item{
  width:100%;
  display:flex;
  padding: ${({padding}) => padding ? padding : '22px 0'};
  border-bottom: 1px solid #EFEFEF;
  color: #184A61;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  justify-content: ${({insight}) => insight ? insight : 'space-between'};
  .profile{
    width:48px;
    height:48px;
    border: 1px solid #DCDCDC;
    border-radius:50%;
    margin-right:8px;
    >img{
      width:48px;
      height:48px;
    }
  }
  .name{
    color: #184A61;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
  }
  .value{
    color: #184A61;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin: ${({insight}) => insight ? 'auto 5px auto auto' : ''};
  }
}
.showmore{
  width:100%;
  display:flex;
  padding: 8px 4px;
  .button{
    color: #184A61;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 6px 12px;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
  }
}
::-webkit-scrollbar {
    width: 4px;
    height: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 4px;
  }
`;



export { TabsStyle,ScrollableContainer, TabsWrapper, ToolTip, StyledSvg, BuddyTab, AboutSection, MuiltiDropdown, BrandingTab, TopDataGraphMain, CircleGraph, BrandingTabContainer, ContainerMain, Table }