import { useEffect, useRef } from 'react';
function useOutsideClick(callback) {
  const ref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      // Check if the clicked element is outside the referenced element
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Add event listeners for both mouse and touch events
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    // Clean up the event listeners on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [callback]);
  // Return the ref to attach to the element you want to detect outside clicks for
  return ref;
}
export default useOutsideClick;