import React, { Component } from 'react';
import PropTypes from "prop-types";
import { CustomCheckbox } from '../ConnectDeviceModal/styles';
import { CustomLabel, Slider, Container, CheckBoxContainer } from './styles';
import { withTranslation } from 'react-i18next';

class MatrixConverionMain extends Component {
  constructor() {
    super();
    this.state = {
      DistanceType: true
    }
  }

  convertInKilometer = (type) => {
    localStorage.setItem("DISTANCE", type);
    this.setState({
      DistanceType: !this.state.DistanceType,
    });
  }

  render() {
    const DistanceUnit = localStorage.getItem('DISTANCE');
    const { t } = this.props;
    return (
      <Container>
        <div className="units">
          {t("Distance")}
        </div>
        <div className="check-box">
          <CheckBoxContainer checked={DistanceUnit === "KILOMETER" ? true : false}>
            <div className="active-unit">
              {t("Miles")}
            </div>
            <div className="icon">
              <CustomLabel className="switch" id={'distance'}>
                <CustomCheckbox
                  type="checkbox"
                  checked={DistanceUnit === "KILOMETER" ? true : false}
                  onClick={DistanceUnit === "KILOMETER" ? () => this.convertInKilometer("MILES") : () => this.convertInKilometer("KILOMETER")}
                />
                <Slider className="slider round" />
              </CustomLabel>
            </div>
            <div className="inactive-unit">
              {t("kilometer")}
            </div>
          </CheckBoxContainer>
        </div>
      </Container>
    )
  }
}

MatrixConverionMain.propTypes = {
  getUserEmailNotifications: PropTypes.func,
  t: PropTypes.func
};

export default (withTranslation()(MatrixConverionMain));
