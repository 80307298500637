/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HealthTab from '../HealthTabV2/HealthTabUpdated/index';
import Assesment from '../HealthTabV2/AssesmentAndCategory/index';
import { setSelectedYear, fetchOverviewTabApi, fetWellbeingCategories, fetchWBAQuestionByCategoryId, fetchWellbeingAssessmentDetails, fetchHealthAndWellbeingAssessmentForFourCard, fetchReportTabAction, getCompanies as getAllCompanies, setCompany, fetchBiometricTabApi,  showBiometricStatus, } from '../../redux/actions';
import { BuddyTabContainer, BuddyTab, YearDropDown } from "../PeopleHomeV2/styles";
import { Layout, Main } from '../AdminTeamCreation/styles';
// import { prototype } from 'react-bootstrap/lib/Accordion';
import { ButtonContainer, ConfirmButton, InitiativesContainer, NoData, StyledModal } from './styles'
import { isUndefined } from 'lodash';
import YearDropdown from '../common/YearDropdown/YearDropdown';
import BiometricTab from '../BiometricTab';
import CompanyInitiativeNew from '../EngagementSectionV2/CompanyInitiative/companyInitiativeNew';
import HoverButton from '../common/HoverButton';
import { /*AdminEventsAddCoachIcon,*/ DownloadDocumentIcon, ErrorDocumentUploadIcon, SuccessDocumentUploadIcon, UploadDocumentIcon, closeIcon } from '../../utils/icons';
import { Modal, Spinner } from 'react-bootstrap';
import { Border } from '../EventDetails/styles';
import { t } from 'i18next';
import { fetchApi } from '../../utils/methods';
import { FetchSampleBiometricDoc, UploadBiometricCsvData, GetCompanyBiometricDetails } from '../../redux/constants/apiConstants';
import { toast } from 'react-toastify';
import CSVList from '../PeopleHomeV2/CSVList';
import CompletedInitiatives from '../EngagementSectionV2/CompanyInitiative/completedInitiatives';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: props.companyInfo.id,
      selectedTabId: 1,
      selectedHealthTabId: 1,
      tabValue: "overview",
      healthTabValue: this.props.history.location.state === 'biometric' ? "biometric" : this.props.history.location.state === "wbareport" ? "wbareport" : "healthdash",
      isHealthDash: true,
      categoryIdValue: 1,
      menuIsOpen: false,
      companyName: props.companyInfo.name,
      showBulkPopup:false,
      showSuccessPopup:false,
      showErrorPopup:false,
      printSampleBiometricCSV:false,
      printErrorLogCSV:false,
      failedCount:0,
      successCount:0,
      spinner:false,
      biometricShowandHideStatus:null
    }
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  setPage = (page)=>{
    page === 'biometric' ? this.setState({healthTabValue:"biometric"}) : page === "wbareport" ?  this.setState({healthTabValue:"wbareport"}) :this.setState({healthTabValue:"healthdash"}) 
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ menuIsOpen: false });
    }
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      menuIsOpen: !prevState.menuIsOpen
    }));
  };
  getMenuItems = () => {
    const { companies } = this.props;
    return companies && companies.map((company) => company.company_name);
  };

  onSelect = (value) => {
    const { companies, setCompany } = this.props;
    const id = companies.filter((company) => company.company_name === value)[0].id;
    const comapanyStartDate = companies.filter((company) => company.company_name === value)[0].company_start_date;
    const biometricStatus = companies.filter((company) => company.company_name === value)[0].show_biometric;
    setCompany(id, value, comapanyStartDate, biometricStatus);
    this.setState({
      companyId: id
    })
  };

  fetchWBAQuestionAnswers = (companyId, categoryId, year) => {
    const { fetchWBAQuestionByCategoryId, fetchWellbeingAssessmentDetails, fetchHealthAndWellbeingAssessmentForFourCard, fetchReportTabAction } = this.props;
    fetchWBAQuestionByCategoryId(companyId, categoryId, year);
    fetchWellbeingAssessmentDetails(companyId, year);
    fetchHealthAndWellbeingAssessmentForFourCard(companyId, year);
    fetchReportTabAction(companyId, year)
  };

  assesmentAPI = (year, categoryID) => {
    this.setState({
      categoryIdValue: categoryID
    }, () => {
      this.updateDropdown(year, categoryID);
    })
  }
  // updateYearDropdown = (year) => {
  //   const { fetchReportTabAction, fetchBiometricTabApi, fetchBiometricStatus } =
  //     this.props;
  //   const { companyId } = this.state;
  //   fetchBiometricTabApi(companyId, year);
  //   fetchReportTabAction(companyId, year);
  //   fetchBiometricStatus(companyId, year);
  // };

  async getCompanyBiometricDetails(companyId, year){
    const result = await fetchApi(`${GetCompanyBiometricDetails}/${companyId}/${year}`, "GET");
    this.setState({
      biometricShowandHideStatus:result
    })
    // window.console.log("line 19 result :- ", result);
  }

  updateDropdown = (year, categoryID) => {
    const { fetchReportTabAction, fetchOverviewTabApi, setSelectedYear,fetchBiometricTabApi, fetchBiometricStatus } = this.props;
    const { companyId, tabValue, categoryIdValue, healthTabValue } = this.state;
    if(healthTabValue === "biometric"){
      fetchBiometricTabApi(companyId, year);
      fetchBiometricStatus(companyId, year);
    }
    if(healthTabValue === "wbareport"){
      fetchReportTabAction(companyId, year);
    }
    if (tabValue === "overview") {
      fetchReportTabAction(companyId, year);
      fetchOverviewTabApi(companyId, year);
    }
    else {
      if (isUndefined(categoryID)) {
        if(healthTabValue === "wbareport"){
          this.fetchWBAQuestionAnswers(companyId, categoryIdValue, year)
        }
      }
      else {
        this.fetchWBAQuestionAnswers(companyId, categoryID, year)
      }
    }
    setSelectedYear(year);
  };

  toggleTab = (active) => {
    this.setState({ selectedTabId: active })
  }

  toggleHealthTab = (active) => {
    this.setState({ selectedHealthTabId: active })
  }

  manageTabs = (status) => {
    const { companyId } = this.state;
    const { selectedYearInfo } = this.props;
    this.setState({ tabValue: status })
    if (status === "assesment") {
      this.fetchWBAQuestionAnswers(companyId, 1, selectedYearInfo);
    }
  }

  manageHealthTabs = (status) => {
    const { companyId } = this.state;
    const { selectedYearInfo, fetchOverviewTabApi, fetWellbeingCategories } = this.props;
    if(status === "healthdash") {
      this.setState({ isHealthDash: true })
    }
    if(status=="wbareport"){
      fetchOverviewTabApi(companyId, selectedYearInfo);
      fetWellbeingCategories();
    }
    if(status === "library") {
      this.setState({ isHealthDash: false })
    }
    this.setState({ healthTabValue: status })
  }

  renderYearDropdown = (yearList, selectedYearInfo) => (
    <div style={{ display: "flex", float: "right" /* marginTop: '10px' */ }}>
      <YearDropdown
        background={"#FFFFFF"}
        refOutside={this.dropdownRef}
        toggleMenu={this.toggleMenu}
        title={
          selectedYearInfo && (
            <div
              className="title"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                color: '#007AB1',
                fontSize: '16px',
                fontFamily: 'Rubik',
                fontWeight:'bold',
                gap:'12px',
              }}
            >
              <div>{selectedYearInfo}</div>{" "}
              <div style={{ marginRight: "3px" }}>
                <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                  style={{
                    transform: this.state.menuIsOpen ? "none" : "none"
                  }}  
                >
                  <path d="M10.5068 12C10.1484 12 9.78997 11.8559 9.51669 11.5684L0.91712 2.51663C0.370075 1.94083 0.370075 1.00725 0.91712 0.431681C1.46394 -0.143894 2.35071 -0.143894 2.89779 0.431681L10.5068 8.44122L18.1159 0.43196C18.6629 -0.143614 19.5496 -0.143614 20.0964 0.43196C20.6437 1.00753 20.6437 1.94111 20.0964 2.51691L11.4969 11.5687C11.2235 11.8563 10.8651 12 10.5068 12Z" fill="#007AB1"/>
                </svg>
              </div>
            </div>
          )
        }
        labelStyle={{ margin: "5px 10px 0px 0px" }}
        data={yearList}
        onSelect={this.updateDropdown}
        style={{
          background: "#ffff",
          lineHeight: "20px",
          color: "rgba(0, 92, 135, 0.6)",
          padding: "0px",
          border: "1px solid rgba(0, 92, 135, 0.3)",
          borderRadius:'4px',
        }}
      />
    </div>
  );

  getCSVHeaders = () => {

    const csvHeaders = [
      { label: "User Email", key: "user_email" },
      { label: "First Name", key: "fname" },
      { label: "Last Name", key: "lname" },
      { label: "Department", key: "department" },
      { label: "Birthday", key: "birthday" },
      { label: "Company Name", key: "company_name" },
      { label: "Phone", key: "phone_number" },
      { label: "City", key: "city" },
      { label: "State", key: "state" },
      { label: "Address", key: "address" },
      { label: "Postal Code", key: "postal_code" },
      { label: "Height (6.5)", key: "" },
      { label: "Weight(70.9) ", key: "" },
      { label: "Circumference", key: "" },
      { label: "BMI", key: "" },
      { label: "Blood Pressure - systolic", key: "" },
      { label: "Blood Pressure – diastolic", key: "" },
      { label: "Fasting", key: "" },
      { label: "Cholestrol Total", key: "" },
      { label: "Cholesrtol – HDL", key: "" },
      { label: "Cholestrol – IDL", key: "" },
      { label: "Triglycerides", key: "" },
      { label: "Glucose", key: "" },
    ];
  
    return csvHeaders;
  };

  getErrorLogCSVHeaders = () => {

    const csvHeaders = [
      { label: "User Email", key: "user_email" },
      { label: "First Name", key: "fname" },
      { label: "Last Name", key: "lname" }
    ];
  
    return csvHeaders;
  };

  printAction = (bool) => {
    this.setState({printSampleBiometricCSV:bool});
  }

  printErrorLogAction = (bool) => {
    this.setState({printErrorLogCSV:bool});
  }

  getBiometricSampleReport = async()=>{
    const sampleBiometricReport = await fetchApi(FetchSampleBiometricDoc+'/'+this.props.companyInfo.id , "GET");

    // getCSVHeaders(sampleBiometricReport.data[0]);
    if(sampleBiometricReport.message){
      toast.error(sampleBiometricReport.message);
      return;
    }else{
      if(sampleBiometricReport.data.length === 0){
        toast.error("No data found");
        return;
      }else{
        this.setState({sampleBiometricReport:sampleBiometricReport.data},()=>{
          this.setState({printSampleBiometricCSV:true})

        });
      }

    }
  }


  validateDocument = (file) => {
    let validTypes = ["csv"];
    let extenstion = file.split('.').pop();
    let isValid = validTypes.includes(extenstion);
    return isValid;
  }

  uploadDocument =  (filesArray) => {
    this.setState({spinner:true});

    if(filesArray?.name){
      if(this.validateDocument(filesArray?.name)){
        const fileReader = new FileReader();
        fileReader.readAsDataURL(filesArray);
        fileReader.onloadend = ()=>{
          this.setState({biometricCsv:fileReader.result},()=>{
            this.showConfirmVisitModal();
          });
        }

      }else{
        toast.error("Invalid file! Please select valid file format");
      }
    }
  }

  

  showConfirmVisitModal = async() => {
    const payload = {biometric_csv:this.state.biometricCsv,company_id:this.props.companyInfo.id};
    
    await fetchApi(`${UploadBiometricCsvData}`, "POST", payload).then((res) => {
      if(res.data.message){
        toast.error(res.data.message);
        this.setState({spinner:false});
      }else if(res.data.failed_count === 0){
        toast.success("Document uploaded successfully");
        this.setState({successCount:res.data.success_count},()=>{
          this.setState({showBulkPopup:false,showSuccessPopup:true,spinner:false});
        });
      }else if(res.data.failed_count > 0){
        toast.error("Document uploaded successfully but some data is not uploaded");
        this.setState({failedCount:res.data.failed_count,successCount:res.data.success_count,failedData:res.data.failed_data},()=>{
          this.setState({showBulkPopup:false,showErrorPopup:true,spinner:false});
        });
      }
      document.getElementById("event-upload-file").value = "";
      
    });

  }

  hideInitiativeModal = () => {
    this.setState((prev) => ({
      showErrorPopup: !prev.showErrorPopup,
    }))
  };


  userInitiativePopup = () => {
    const { showBulkPopup, initiativeData,spinner } = this.state;
    return (
      <StyledModal show={showBulkPopup} width={"400px"} height={"380"}>
        <div className={'modal-container'}>
          <div onClick={() => this.setState({showBulkPopup:false})} style={{ margin: '14px 0px 0px -14px', cursor: 'pointer' }}>
            {closeIcon()}
          </div>
          <div style={{ color: '#005C87', marginTop: '0px', textDecoration: 'none', fontSize: '18px' }}>
            {initiativeData.name}
          </div>
        </div>
        <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>
        <Modal.Body>
          <InitiativesContainer>
            <div className={'details'}>
              {t("Do you want to upload biometric data?")}
            </div>
            <ButtonContainer>
              <ConfirmButton margin="30px auto 5px auto" width="352px" style={{ background: "#007AB1",position:"relative" }}>{spinner ? <Spinner color='white'/> : 
                (<>{t("Upload Document")}<span style={{marginLeft:"12px"}}>{UploadDocumentIcon()}</span></>)}
              <input
                id="event-upload-file"
                type="file"
                name="user"
                accept=".csv"
                multiple={false}
                style={{width:"100%",height:"100%",position:"absolute",opacity:"0",cursor:"pointer"}}
                onChange={(e) => (e.target.files[0] && !spinner) && this.uploadDocument(e.target.files[0])}
              />
              </ConfirmButton>
            </ButtonContainer>
            <ButtonContainer>
              <ConfirmButton onClick={() => this.getBiometricSampleReport()} margin="30px auto 5px auto" width="352px" style={{ background: "white",border:"1px solid #007AB1",color:"#007AB1" }}>{t("Download Sample Document")}<span style={{marginLeft:"12px"}}>{DownloadDocumentIcon()}</span></ConfirmButton>
            </ButtonContainer>
          </InitiativesContainer>
        </Modal.Body>
      </StyledModal>)
  }

  successPopup = () => {
    const { showSuccessPopup,successCount } = this.state;
    return (
      <StyledModal show={showSuccessPopup} width={"400px"} height={"380"}>
        <div className={'modal-container'}>
          <div onClick={() => this.setState({showSuccessPopup:false})} style={{ margin: '14px 0px 0px -14px', cursor: 'pointer' }}>
            {closeIcon()}
          </div>
          <div style={{ color: '#005C87', marginTop: '0px', textDecoration: 'none', fontSize: '18px' }}>
            <span style={{marginRight:"12px"}}>{SuccessDocumentUploadIcon()}</span>{"Sucessfully Uploaded"}
          </div>
        </div>
        <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>
        <Modal.Body>
          <InitiativesContainer>
            <div className={'details'}>
              {t(`You successfully uploaded data for ${successCount} users`)}
            </div>
            <ButtonContainer>
              <ConfirmButton onClick={() => this.setState({showSuccessPopup:false})} margin="30px auto 5px auto" width="352px" style={{ background: "#007AB1" }}>{t("Thank You")}</ConfirmButton>
            </ButtonContainer>
          </InitiativesContainer>
        </Modal.Body>
      </StyledModal>)
  }

  errorPopup = () => {
    const { showErrorPopup,failedCount,successCount } = this.state;
    return (
      <StyledModal show={showErrorPopup} width={"400px"} height={"380"}>
        <div className={'modal-container'}>
          <div onClick={() => this.setState({showErrorPopup:false})} style={{ margin: '14px 0px 0px -14px', cursor: 'pointer' }}>
            {closeIcon()}
          </div>
          <div style={{ color: '#005C87', marginTop: '0px', textDecoration: 'none', fontSize: '18px',alignItems:"center" }}>
            <span style={{marginRight:"12px"}}>{ErrorDocumentUploadIcon()}</span>{"Error"}
          </div>
        </div>
        <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>
        <Modal.Body>
          <InitiativesContainer>
            <div className={'details'}>
              <span style={{width:"84%"}}>{t(`You successfully uploaded data for ${successCount} users, but there was an error with the other ${failedCount} users data.`)}<br/> <br/>
              Click the download button to see the errors in the entries.</span>
            </div>
            <ButtonContainer>
              <ConfirmButton onClick={() => this.setState({printErrorLogCSV:true})} margin="30px auto 5px auto" width="352px" style={{ background: "#007AB1" }}>{t("Download File")}<span style={{marginLeft:"12px"}}>{<DownloadDocumentIcon fill={"white"} width={"24px"} height={"25px"}/>}</span></ConfirmButton>
            </ButtonContainer>
          </InitiativesContainer>
        </Modal.Body>
      </StyledModal>)
  }



  biometricScreeningPresent = this.props?.wellnessInitiatives?.some(
    (initiative) => initiative.initiative_name === "Biometric Screening" && initiative.is_upcoming === 0
  );

  render() {
    const { selectedYearInfo, yearList, assesmentDetails, healthWellbeingCardData, wellnessWins, addressableHealthRisk, reportMessage, showReportTab, wellbeingCategoriesData, WbaQuestionData, healthImprovementOpportunities, companies, companyInfo, setCompany, role, /*history*/ } = this.props;
    const { tabValue, companyId, companyName, /*menuIsOpen,*/ healthTabValue, showBulkPopup, printSampleBiometricCSV, sampleBiometricReport,showSuccessPopup,showErrorPopup,failedData, printErrorLogCSV } = this.state;
    const currentYear = new Date().getFullYear();
    let yearArray = [];
    for(let i = currentYear; i >= companyInfo["companyStartDate"]; i--){
      yearArray.push(i);
    }

    return (
      <Layout>
        <Main>
          {
            <BuddyTabContainer marginBottom="20px" marginRight="0px" style={{display:"flex", justifyContent:"space-between",padding:"0px 10px"}}>
              <div style={{display:"flex"}}>
                <BuddyTab width padding  active={ healthTabValue === "healthdash"} onClick={() => this.manageHealthTabs("healthdash")} >Health Dashboard</BuddyTab>
                <BuddyTab width padding active={ healthTabValue === "wbareport"} onClick={() => this.manageHealthTabs("wbareport")} >WBA Reports</BuddyTab>
                <BuddyTab width padding active={ healthTabValue === "biometric"} onClick={healthTabValue == "biometric" ? null : () => this.manageHealthTabs("biometric")}>Biometrics</BuddyTab>
               
                {<BuddyTab width padding active={ healthTabValue === "completed"} onClick={ () => this.manageHealthTabs("completed")}>Completed</BuddyTab>}

                <BuddyTab width padding active={ healthTabValue === "library"} onClick={healthTabValue == "library" ? null : () => this.manageHealthTabs("library")}>Library</BuddyTab>
              </div>
              <div style={{display:"flex", alignItems:"center"}}>
                {/* {role === "ADMIN" ? (<div style={{marginRight:"10px"}}>
                  <HoverButton svgPath={AdminEventsAddCoachIcon()} activeColor={"#007AB1"} fillOpacity={1} title={"Create Program"} onClick={() => history.push("/company/create-health-program")} />
                  
                </div>):null} */}

                
                {  healthTabValue === "biometric" ||  healthTabValue === "wbareport" ?
                  <>
                    <YearDropDown>
                      {this.renderYearDropdown(yearList, selectedYearInfo)}
                    </YearDropDown> 
                  </>
                  :null}

                {(role === "ADMIN" &&healthTabValue === "biometric" && this.biometricScreeningPresent)   ? (<div style={{ marginRight: "6px" }} >
                  <HoverButton fillOpacity={1} svgPath={<UploadDocumentIcon fill={"#007AB1"} width={"24px"} height={"25px"}/>} activeColor={"#007AB1"} title={"Bulk Upload"} 
                    onClick={()=>this.biometricScreeningPresent && this.setState({showBulkPopup:true,initiativeData:{name:"Bulk Upload"}})} 
                    disabled={!this.biometricScreeningPresent}
                  />
                </div>):null}
              </div>
            </BuddyTabContainer>
          }
          {healthTabValue === "healthdash" && (
            <>
              <CompanyInitiativeNew 
                companies={companies}
                companyInfo={companyInfo}
                setCompany={setCompany}
                role={role}
                yearList={yearArray}
                selectedYearInfo={selectedYearInfo}
                location={this.props.history.location}
                isHealthDash={this.state.isHealthDash}
                history={this.props.history}
                tabSwitch={this.props.history.location.state}
                setPage={this.setPage}
              />
            </>
          )}
          {healthTabValue === "wbareport" && (
            <>
              {
                <BuddyTabContainer width="1248px" pLeft="0px" gap="12px" marginBottom="20px" marginRight="0px" bgUnset="unset" height="40px">
                  <BuddyTab margin={"0px 15px 0px 15px"} active={tabValue == "overview" ? true : false} style={{ background: tabValue == "overview" ? 'rgba(0, 122, 177, 1)' : "unset", color: tabValue == "overview" ? "#fff" : "rgba(0, 122, 177, 1)" }} onClick={() => this.manageTabs("overview")} >Overview</BuddyTab>
                  <BuddyTab margin={"0px 15px 0px 15px"} active={tabValue == "assesment" ? true : false} style={{ background: tabValue == "assesment" ? 'rgba(0, 122, 177, 1)' : "unset", color: tabValue == "assesment" ? "#fff" : "rgba(0, 122, 177, 1)" }} onClick={tabValue == "assesment" ? null : () => this.manageTabs("assesment")}>Assessment</BuddyTab>
                  {/* <div className="year-dropdown" style={{position:"relative", bottom:"68px"}}>
                    <YearDropdown
                      refOutside={this.dropdownRef}
                      toggleMenu={this.toggleMenu}
                      title={selectedYearInfo && <div className="title" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: 'rgba(0, 122, 177, 1)', fontFamily: 'Rubik-Medium', fontSize: '16px' }}><div>{selectedYearInfo}</div> <div style={{ marginRight: '3px' }}><svg
                        width="12" height="20" viewBox="0 0 12 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          transform: menuIsOpen ? "none" : "none"
                        }}
                      >
                        <path d="M12 10C12 10.3585 11.8559 10.7169 11.5684 10.9901L2.51663 19.5897C1.94083 20.1368 1.00725 20.1368 0.431681 19.5897C-0.143894 19.0429 -0.143894 18.1561 0.431681 17.609L8.44122 10L0.43196 2.39098C-0.143614 1.84394 -0.143614 0.957264 0.43196 0.410484C1.00753 -0.136826 1.94111 -0.136826 2.51691 0.410484L11.5687 9.00992C11.8563 9.28333 12 9.64172 12 10Z" fill="rgba(0, 122, 177, 1)"  />
                      </svg></div></div>}
                      data={yearList}
                      onSelect={this.updateDropdown}
                      style={{background: "rgba(247, 245, 235, 0.5)", border: "1px solid rgba(0, 92, 135, 0.3)", lineHeight: "20px", color: 'red', padding: "0px"}}
                    />
                  </div> */}
                </BuddyTabContainer>
              }
              {!showReportTab ?
                <NoData>
                  {reportMessage ? `${reportMessage}` : null}<br />
                  Minimum 10 users should have completed the HRA to view the aggregate health reports
                </NoData> :
                tabValue == "overview" ?
                  <HealthTab assesmentDetails={assesmentDetails} healthWellbeingCardData={healthWellbeingCardData} wellnessWins={wellnessWins} addressableHealthRisk={addressableHealthRisk} healthImprovementOpportunities={healthImprovementOpportunities} companyName={companyName}/> :
                  <Assesment wellbeingCategoriesData={wellbeingCategoriesData} companyId={companyId} selectedYearInfo={selectedYearInfo} assesmentAPI={this.assesmentAPI} WbaQuestionData={WbaQuestionData} healthWellbeingCardData={healthWellbeingCardData} assesmentDetails={assesmentDetails} />}
            </>
          )}
          {healthTabValue === "biometric" && (
            <>
              <BiometricTab 
                // path="/company/biometric"
                // component={BiometricTab}
                companies={companies}
                // parentRoute="/portal"
                // allowedRoles={allowAdminTabs}
                companyInfo={companyInfo}
                setCompany={setCompany}
                role={role}
                // history={this.props.history}
                yearList={yearArray}
                selectedYearInfo={selectedYearInfo}
                biometricShowandHideStatus={this.state.biometricShowandHideStatus}
              />
            </>
          )}
          {healthTabValue === "completed" && (
            <>
              <CompletedInitiatives
                companies={companies}
                companyInfo={companyInfo}
                setCompany={setCompany}
                role={role}
                yearList={yearArray}
                selectedYearInfo={selectedYearInfo}
                location={this.props.history.location}
                isHealthDash={this.state.isHealthDash}
                history={this.props.history}
                tabSwitch={this.props.history.location.state}
                isLibrary={true}
                setPage={this.setPage}
                
              />
            </>
          )}
          {healthTabValue === "library" && (
            <>
              <CompanyInitiativeNew 
                companies={companies}
                companyInfo={companyInfo}
                setCompany={setCompany}
                role={role}
                yearList={yearArray}
                selectedYearInfo={selectedYearInfo}
                location={this.props.history.location}
                isHealthDash={this.state.isHealthDash}
                history={this.props.history}
                tabSwitch={this.props.history.location.state}
                isLibrary={true}
                setPage={this.setPage}
                
              />
            </>
          )}
        </Main>

        {showBulkPopup && this.userInitiativePopup()}
        {showSuccessPopup && this.successPopup()}
        {showErrorPopup && this.errorPopup()}
        {printSampleBiometricCSV && <CSVList Title={`${"Sample Biometric"}`} removeCSVData={this.printAction} headers={this.getCSVHeaders()} data={sampleBiometricReport} />}
        {printErrorLogCSV && <CSVList Title={`${"Biometric Error Logs"}`} removeCSVData={this.printErrorLogAction} headers={this.getErrorLogCSVHeaders()} data={failedData} />}
      </Layout>
    );
  }
}
Reports.defaultProps = {
  companyInfo: { id: 1, name: '', showBiometricStatus: 1 }
}

Reports.propTypes = {
  companies: PropTypes.array,
  companyInfo: PropTypes.object.isRequired,
  setCompany: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  history: PropTypes.object,
  showReportTab: PropTypes.bool,
  selectedYearInfo: PropTypes.number,
  yearList: PropTypes.array,
  setSelectedYear: PropTypes.func,
  showCompaniesDropdown: PropTypes.bool,
  fetchOverviewTabApi: PropTypes.func,
  assesmentDetails: PropTypes.array,
  healthWellbeingCardData: PropTypes.array,
  wellnessWins: PropTypes.array,
  addressableHealthRisk: PropTypes.array,
  reportMessage: PropTypes.string,
  fetWellbeingCategories: PropTypes.func,
  wellbeingCategoriesData: PropTypes.array,
  fetchWBAQuestionByCategoryId: PropTypes.func,
  WbaQuestionData: PropTypes.array,
  fetchHealthAndWellbeingAssessmentForFourCard: PropTypes.func,
  fetchWellbeingAssessmentDetails: PropTypes.func,
  fetchReportTabAction: PropTypes.func,
  healthImprovementOpportunities: PropTypes.array,
  fetchBiometricTabApi: PropTypes.func,
  fetchBiometricStatus: PropTypes.func,
  t: PropTypes.func,
  wellnessInitiatives:PropTypes.array

};

const mapStateToProps = (state) => ({
  selectedYearInfo: state.companyDashboard.selectedYearInfo,
  assesmentDetails: state.adminReports.assesmentDetails,
  healthWellbeingCardData: state.adminReports.healthWellbeingCardData,
  wellnessWins: state.adminReports.wellnessWins,
  addressableHealthRisk: state.adminReports.addressableHealthRisk,
  reportMessage: state.companyDashboard.reportMessage,
  showReportTab: state.companyDashboard.showReportTab,
  wellbeingCategoriesData: state.adminReports.wellbeingCategoriesData,
  WbaQuestionData: state.adminReports.WbaQuestionData,
  healthImprovementOpportunities: state.adminReports.healthImprovementOpportunities,
  companies: state.companyDashboard.companies,
  wellnessInitiatives: state.companyDashboard.wellnessInitiatives,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBiometricTabApi: (companyId, year) =>
    dispatch(fetchBiometricTabApi(companyId, year)),
  setSelectedYear: (year) => dispatch(setSelectedYear(year)),
  fetchOverviewTabApi: (id, year) => dispatch(fetchOverviewTabApi(id, year)),
  fetWellbeingCategories: () => dispatch(fetWellbeingCategories()),
  fetchWBAQuestionByCategoryId: (companyId, categoryId, year) => dispatch(fetchWBAQuestionByCategoryId(companyId, categoryId, year)),
  fetchWellbeingAssessmentDetails: (id, year) => dispatch(fetchWellbeingAssessmentDetails(id, year)),
  fetchHealthAndWellbeingAssessmentForFourCard: (id, year) => dispatch(fetchHealthAndWellbeingAssessmentForFourCard(id, year)),
  fetchReportTabAction: (id, year) => dispatch(fetchReportTabAction(id, year)),
  setCompany: (id, name, companyStartDate, biometricStatus) => dispatch(setCompany(id, name, companyStartDate, biometricStatus)),
  fetchAllCompanies: (year) => dispatch(getAllCompanies(year)),
  fetchBiometricStatus: (id, year) => dispatch(showBiometricStatus(id, year)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);