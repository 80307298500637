import styled from 'styled-components';
import {ActivityBar, ActivityBarFill, ActivityContainer} from "../../HealthTabV2/styles";
const BoldFont = 'Rubik-Bold';
const MediumFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Regular';
const TeamGoalContainer = styled.div`
  width: 100%;
  background-color: #fff;
  float: left;
  margin-top: 20px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
`;
const GroupChallengeContainer = styled.div`
  width: 100%;
  display: block;
  background-color: #4798D9;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 25px;
  @media (max-width: 400px){
    padding: 10px;
  }
  & > div: first-child{
    background-color: #F6F6F8;
    width: 200px;
    border-radius: 20px;
    padding: 7px;
    > p{
      font-size: 18px;
      line-height: 18px;
      color: #52525F;
      margin: 0px;
      font-family: ${MediumFont};
      text-align: center;
    }
  }
  & > div: last-child{
    display: block;
    text-align: center;
  }
  .totalGroupSteps {
    margin: 0 auto;
    > div: first-child{
     color: #fff;
     font-size: 15px;
     font-family: ${MediumFont};
     padding-bottom: 5px;
    }
    >div: last-child{
      color: #fff;
     font-size: 25px;
     font-family: ${BoldFont};
    }
    @media (max-width: 380px){
      text-align: center;
    }
  }
`;
const ActivityContainerV2 = styled(ActivityContainer)`
    margin: 10px 0 5px;
    padding-bottom: 5px;
  > div{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
    @media(max-width: 380px){
      flex-wrap: wrap;
    }
  }
  .chartTitle {
    color: #fff;
    font-size: 17px;
    > span{
      margin-left: 5px;
      font-family: ${RegularFont}
      text-transform: capitalize;
    }
    > span: nth-child(3){
      margin-left: 5px;
      font-family: ${BoldFont}
    }
    @media(max-width: 380px){
      margin-bottom: 10px;
    }
  }
  .chartSubTitle {
    color: #fff;
    font-family: ${BoldFont};
    font-size: 18px;
    >span {
      margin-left: 5px;
      color: #FEFEFE;
      opacity: 0.5;
      font-size: 14px;
      font-family: ${RegularFont};
    }
  }
`;
const ActivityBarV2 = styled(ActivityBar)`
    background-color: #4182B6;
`;
const ActivityBarFillV2 = styled(ActivityBarFill)`
  justify-content: ${({filled}) => filled ? 'center' : 'flex-end'};
  > span {
    color: #4798D9;
  }
`;
const TopContributors = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 30px 15px 25px 15px;
  float: left;
  & > div:first-child{
    > div: first-child{
      font-size: 18px;
      font-family: ${MediumFont};
      opacity: 0.5;
      color: #484856;
    }
    > div: last-child{
      font-size: 14px;
      font-family: ${MediumFont};
      color: #484856;
    }
  }
  & > div: last-child{
    width: 100%;
    margin-top: 15px;
    background-color: #fff;
    float: left;
    display: flex;
    flex-wrap: wrap;
    > div{
      width: calc((100% / 3) - 5px);
      display: inline-block;
      margin: 0 5px 20px 0;
      float: left;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 1px 1.732px 12px rgba(0, 0, 0, 0.18);
      &:last-child {
       margin: 0 0 20px 0;
      }
      @media (max-width: 1318px) and (min-width: 1001px){
        width: calc((100% / 2) - 5px);
      }
      @media (max-width: 638px) and (min-width: 417px){
        width: calc((100% / 2) - 5px);
      }
      @media (max-width: 417px){
        width: 100%;
        text-align: left;
        margin-bottom: 10px;
      }
      > div: first-child{
        background-color: #1E76AB;
        text-align: center;
        color: #fff;
        border-radius: 6px 6px 0px 0px;
        font-family: ${MediumFont};
      }
      > div: last-child{
        width: 100%;
        color: #282C37;
        float: left;
        display: flex;
        align-items: center;
        padding: 8px;
        height: calc(100% - 20px);
        > div{
           width: 45px;
           float: left;
           margin-right: 8px;
         > img{
           width: 45px;
           height: 45px;
           border-radius: 50%;
          }
        }
        > div: last-child{
          font-size: 14px;
          font-family: ${RegularFont};
          text-transform: capitalize;
          color: #6D819C;
          width: calc(100% - 55px);
          > div: first-child{
          font-size: 13px;
          color: #282C37;
          font-family: ${MediumFont};
          line-height: 24px;
         }
          & > span{
            color: #6D819C;
            font-family: ${MediumFont};
          }
          & > div: last-child{
          font-size: 12px;
          font-family: ${RegularFont};
          text-transform: capitalize;
          color: #6D819C;
         }
        }
      }
    }
  }
`;

const ActivityBarFillNew = styled.div`
width: ${(props)=> props.width ? props.width : '0%'};
height: 100%;
border-radius: 20px;
background-color: ${(props) => props.fillColor ? props.fillColor : '#000'};
background: ${({fillColor}) => fillColor ? 'linear-gradient(205.89deg, #F9FD2B 17.04%, #FFC700 41.49%, #F37335 100%)' : '#000'};
display: flex;
align-items: center;
justify-content: center;
padding: ${({padding}) => padding ? padding : '0px 15px 0px 12px'};
position: absolute;
top: 0px;
> span {
 cursor : pointer;
 color: #fff;
 font-size: 11px;
 font-family: ${BoldFont};
 display: inline-block;
}
>img{
  width:24px;
  height:24px;
  display: flex;
  margin: auto;
  margin-right: 1px;
  margin-left: 27px;
  margin-left: ${({imageMargin}) => imageMargin ? imageMargin : '0'};
}
`;

const ActivityBarNew = styled(ActivityBar)`
    background-color: rgba(255, 255, 255, 0.3);
    height:24px;
`;

const ProgressTextCard = styled.div`
width: 100%;
display: flex;
> div: first-child{
width: 50%;
>div: first-child{
width: 100%;
display: flex;
justify-content: flex-start;
font-family: Rubik;
font-size: 14px;
line-height: 17px;
color: #FFFFFF;
}
> div: last-child{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-family: 'Rubik-Medium';
  font-size: 24px;
  line-height: 28px;
  color: #FFF;
  margin-top:5px;
  >span{
    font-family: Rubik;
    font-size: 14px;
    line-height: 17px;
    color: #FFF;
    margin: auto;
    margin-left: 5px;
    margin-bottom: 4px;
  }
}
}
> div: last-child{
  width: 50%;
>div: first-child{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-family: 'Rubik-Medium';
  font-size: 24px;
  line-height: 28px;
  color: #FFF;
  padding-right: 5px;
}
> div: last-child{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-family: Rubik;
  font-size: 14px;
  line-height: 18px;
  color: #FFF;
  margin: auto;
  padding-right: 5px;
  }
}
`;

const GroupChallengeContainerV2 = styled.div`
  width: 100%;
  display: block;
  background-color: #4798D9;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 15px;
  background: linear-gradient(133.03deg, #AF87C1 25.62%, #6781C3 73.49%);
  margin-bottom:15px;
  @media (max-width: 400px){
    padding: 10px;
  }
  & > div: last-child{
    display: block;
    text-align: center;
  }
  .totalGroupSteps {
    margin: 0 auto;
    > div: first-child{
      font-family: 'Rubik-Medium';
      font-size: 14px;
      line-height: 18px;
      opacity: 100%;
      color: #FEFEFE;
      padding-bottom: 10px;
    }
    .goalAchieve{
      font-family: 'Rubik';
      font-size: 16px;
      line-height: 20px;
      opacity: 100%;
      color: #FEFEFE;
    }
    >div: last-child{
      color: #fff;
     font-size: 24px;
     line-height: 18px;
     font-family: ${BoldFont};
     .steps{
      font-family: Rubik;
      font-size: 14px;
      line-height: 18px;
      color:#FFFFFF;
     }
    }
    @media (max-width: 380px){
      text-align: center;
    }
  }
`;

const TopPositionCard = styled.div`
width: 100%;
background: linear-gradient(rgb(30, 100, 129) 0%, rgb(13, 66, 92) 60.94%, rgb(2, 44, 67) 100%);
border-radius: 0px 0px 16px 16px;
margin-top: 15px;
`;

const TopPositionCardText = styled.div`
width: 100%;
display: block;
& > div: first-child{
  width: 100%;
  display: flex;
  justify-content:center;
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 19.8px;
  color: #fff;
  padding-top: 25px;
  margin-bottom 8px;
}
& > div: last-child{
  width: 100%;
  display: flex;
  justify-content:center;
  font-family: Rubik;
  font-size: 16px;
  line-height: 19.8px;
  color: #fff;
  margin-bottom 24px;
}
`;

const ThirdPositionCard = styled.div`
width: 100%;
display: block;
border-radius: 50%;
margin: auto;
margin-top: 57px;

& > div: first-child{
  width: 116px;
  height: 116px;
  border-radius: 50%;
  display: flex;
  margin: auto;
  >img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  border: 3px solid #FD7175;
  border: ${({color}) => color ? '3px solid #FD7175' : '3px solid #9FC989'};
  background: ${({color}) => color ? color : '#9FC989'};
}
>div:nth-child(2){
  width: 100%;
  display: flex;
  justify-content:center;
  position: relative;
  bottom: 25px;
  >div{
    width: 43px;
    height: 43px;
    border-radius: 50%;
    border: 3px solid #F6B479;
    border: ${({color}) => color ? '3px solid #FD7175' : '3px solid #9FC989'};
    align-items: center;
    display: flex;
    justify-content: center;
    background: #9FC989;
    background: ${({color}) => color ? color : '#9FC989'};
  >span{
    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }
 }
}
>div:nth-child(3){
  width: 100%;
  position: relative;
  bottom: 20px;
  padding-bottom: 5px;
  & > div: first-child{
    width: 100%;
    display: flex;
    justify-content:center;
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 19.8px;
    color: #fff;
  }
  & > div: last-child{
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content:center;
    font-family: Rubik;
    font-size: 14px;
    line-height: 19px;
    color: #fff;
  }
}
`;

const FirstPositionCard = styled.div`
width: 100%;
display: block;
border-radius: 50%;
& > div: first-child{
  width: 100%;
  display: flex;
  justify-content:center;
  >div{
    width: 38px;
    height: 37px;
    margin-bottom: 5px;
  >img{
    width: 100%;
    height: 100%;
  }
}
}
>div:nth-child(2){
  width: 146px;
  height: 146px;
  border-radius: 50%;
  display: flex;
  margin: auto;
  >img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  border: 3px solid #F6B479;
  background: #F6B479;
}

>div:nth-child(3){
  width: 100%;
  display: flex;
  justify-content:center;
  position: relative;
  bottom: 25px;
  >div{
    width: 43px;
    height: 43px;
    border-radius: 50%;
    border: 3px solid #F6B479;
    align-items: center;
    display: flex;
    justify-content: center;
    background: #F6B479;
  >span{
    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }
 }
}
>div:nth-child(4){
  width: 100%;
  position: relative;
  bottom: 20px;
  padding-bottom: 5px;
  & > div: first-child{
    width: 100%;
    display: flex;
    justify-content:center;
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 19.8px;
    color: #fff;
  }
  & > div: last-child{
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content:center;
    font-family: Rubik;
    font-size: 14px;
    line-height: 19px;
    color: #fff;
  }
}
`;

const TopPositionProfile = styled.div`
width: 100%;
display: flex;
border-radius: 6px;
padding-top: ${({padding}) => padding ? padding : '0px'};
padding: 0px 15px 5px 15px;
flex-wrap: wrap;
.heading{
  font-family: "Rubik-Medium";
font-size: 18px;
line-height: 19.8px;
margin-top:25px;
margin-bottom 35px;
color: white;
width: 100%;
display: flex;
justify-content: center;
}

`;

const LastPositionMain = styled.div`
width: 100%;
display: block;
margin-top: 15px;
float: left;
width: 100%;
overflow-y: auto;
height: auto;
max-height: calc(820px);

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f2f0f0;
  border-radius: 2.5px;
}

::-webkit-scrollbar-thumb {
  background: #c6c2c2;
  cursor: pointer;
  border-radius: 2.5px;
}
`;

const LastPosition = styled.div`
width: 100%;
display: flex;
height: 80px;
background: white;
margin-bottom: 10px;
border-radius: 6px;
& > div: first-child{
  width: 50%;
  display: flex;
  margin: auto;

  & > div: first-child{
    font-family: 'Rubik-Medium';
    font-size: 14px;
    color: #0D4270;
    margin: auto;
    margin-right: 5px;
    margin-left: 8px;

  }
  >div:nth-child(2){
    width: 60px;
    height: 60px;
    >img{
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
  >div:nth-child(3){
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 19.8px;
  color: #0D4270;

  margin: auto;
  margin-left: 15px;
  }
}
& > div: last-child{
  width: 50%;
display: flex;
justify-content: flex-end;
margin-right-25px;
font-family: 'Rubik-Medium';
font-size: 18px;
line-height: 21px;
color: #0D4270;

margin: auto;
margin-right: 25px;

}
`;

const TeamGoalContainerV2 = styled.div`
  width: 100%;
  float: left;
  margin-top: 0px;
  border-radius: 6px;
  .headingMain{
    width:100%;
    display:flex;
    justify-content:space-between;
    margin:0px 0px 15px 0px;
    .leader{
      font-family: Rubik-Medium;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color:#005C87;

      .sync{
      color:  #005C87;
font-family: Rubik-Regular;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 19.8px;
margin-left: 8px !important;
    }
    }
    .participantsCount{
      font-family: Rubik-Medium;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color:#005C87;
    }
  }
`;

const ClassicLastPosition = styled.div`
width: 100%;
display: flex;
height: 80px;
background: white;
margin-bottom: 10px;
border-radius: 6px;
& > div: first-child{
margin-right: 15px;
display: flex;
& > div: first-child{
font-family: 'Rubik-Medium';
font-size: 14px;
line-height: 17px;
color:#0D4270;
margin-left: 8px;
margin-right: 5px;
align-items: center;
display: flex;
  }
  & > div: last-child{
    width: 60px;
    height: 60px;
    margin: auto;
>img{
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
  }

}
 
& > div: last-child{
width: 100%;
display: block;
padding-right: 25px;
> div: nth-child(1){
  width: 100%;
  margin-bottom: 6px;
  margin-top: 12px;
  display: flex;
  & > div: first-child{
    width: 50%;
font-family: 'Rubik-Medium';
font-size: 18px;
line-height: 19.8px;
color:#0D4270;
display: flex;
justify-content: flex-start;
  }
  & > div: last-child{
    width: 50%;
font-family: 'Rubik-Medium';
font-size: 18px;
line-height: 21px;
color:#0D4270;
display: flex;
justify-content: flex-end;
  }
}
> div: nth-child(2){
width: 100%;
height: 5px;
margin-bottom: 8px;
border-radius: 30px;
}

> div: nth-child(3){
width: 100%;
display: flex;
justify-content: flex-end;
> div: nth-child(1){
  font-family: 'Rubik-Medium';
  font-size: 14px;
  line-height: 17px;
  color: #0D4270;
}
> div: nth-child(2){
  font-family: Rubik;
  font-size: 14px;
  line-height: 17px;
  color: #0D4270;
}
}
}
}
`;

const ContainerWrapper = styled.div`
  width: 100%;
  display: block;
  .headingMain{
    width:100%;
    display:flex;
    justify-content:space-between;
    margin:0px 0px 15px 0px;
    .leader{
      font-family: Rubik-Medium;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color:#005C87;

      .sync{
      color:  #005C87;
font-family: Rubik-Regular;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 19.8px;
margin-left: 8px !important;
    }
    }
    .participantsCount{
      font-family: Rubik-Medium;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color:#005C87;
    }
  }
`;

const LeaderboardCard = styled.div`
width: 100%;
display: flex;
background: ${({background}) => background ? background : '#fff'};
border-radius: 6px;
margin-bottom: 10px;
border: 1px solid #005C874D;
flex-wrap: wrap;
.topPositionCard{
  width: 100%;
  height: 0px;
  position:absolute;
>img{
  width: 30px;
  height: 30px;
  position: relative;
  left: 25px;
  bottom: 10px;
}
}

  .first{
  width: 50%;
  display: flex;
  >div:nth-child(1){
    width: 60px;
    height: 60px;
    margin: 10px 15px 10px 10px;
    border: ${({border}) => border  ? `3px solid ${border}` : ''};
    border-radius:6px;
    background: ${({border}) => border  ? border: ''};
    >img{
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
  >div:nth-child(2){
    display: flex;
    margin: auto;
    margin-left: 0px;
    display: block;
    >div:nth-child(1){
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color: ${({positionColor}) => positionColor ? '#005C87' : '#005C87'};
      margin-bottom: 10px;
    }
    >div:nth-child(2){
      font-family: Rubik;
      font-size: 16px;
      line-height: 20px;
      color: ${({positionColor}) => positionColor ? '#005C87' : '#005C87'};
    }
  }
}
  .last{
  width: 50%;
  display: block;
  padding-right: 15px;
  margin:auto;
  >div:nth-child(1){
    display: flex;
    justify-content: flex-end;
    font-family: "Rubik-Medium";
    font-size: 18px;
    line-height: 24px;
    color: ${({positionColor}) => positionColor ? '#005C87' : '#005C87'};
    margin-bottom: 10px;
    margin: auto;
    .type{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      color: #005C87;
      align-items: center;
      display: flex;
      margin-left: 5px;
    }
  }
  >div:nth-child(2){
    width: 100%;
    display: flex;
    justify-content: flex-end;
    >div:nth-child(1){
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color: ${({positionColor}) => positionColor ? '#005C87' : '#005C87'};
    }
    >div:nth-child(2){
      font-family: 'Rubik';
      font-size: 14px;
      line-height: 20px;
      margin-left: 5px;
      color: ${({positionColor}) => positionColor ? '#005C87' : '#005C87'};
    }
  }
}
`;

const PositionCards = styled.div`
width: 100%;
display: flex;
background: ${({ background }) => background ? background : '#fff'};
border-radius: 6px;
margin-bottom 10px;
border: 1px solid #005C874D;
position: relative;
.topPositionCard{
  ${'' /* width: 100%; */}
  height: 0px;
  position:absolute;
  left:25px;
  top: -4px;
  z-index:2;
>img{
  width: 30px;
  height: 30px;
  ${'' /* left: 12px; */}
  ${'' /* bottom: 24px; */}
  ${'' /* position: relative; */}
}
}
>div:nth-child(1){
  width:50%;
  display: flex;
  >div:nth-child(1){
    width: 60px;
    height: 60px;
    margin: 10px 15px 10px 10px;
    border: ${({border}) => border  ? `3px solid ${border}` : ''};
    border-radius:6px;
    background: ${({border}) => border  ? border: ''};
    >img{
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
  >div:nth-child(2){
    display: block;
    margin: auto;
    margin-left: 0px;
    >div:nth-child(1){
      margin-bottom: 10px;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#005C87;


    }
    >div:nth-child(2){
      font-family: Rubik;
      font-size: 16px;
      line-height: 20px;
      color:#005C87;
    }

  }
}
>div:nth-child(2){
  width: 50%;
  display: flex;
  justify-content: flex-end;
  margin: auto;
  margin-right: 15px;
  >div:nth-child(1){
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 20px;
    color:#005C87;
  }
  >div:nth-child(2){
    font-family: Rubik;
    font-size: 14px;
    line-height: 20px;
    color:#005C87;
  }
}
`;

const FirstPositionImage = styled.div`
width: 100%;
height: 25px;
>img{
  width: 30px;
  height: 30px;
  position: relative;
  left: 3%;
  top: 5px;
}
`;

export {TeamGoalContainer, GroupChallengeContainer, ActivityContainerV2, ActivityBarV2, ActivityBarFillV2, TopContributors, ActivityBarFillNew, ActivityBarNew, ProgressTextCard, GroupChallengeContainerV2, TopPositionCard, TopPositionCardText, ThirdPositionCard, TopPositionProfile, FirstPositionCard, LastPositionMain, LastPosition, TeamGoalContainerV2, ClassicLastPosition, ContainerWrapper, LeaderboardCard, PositionCards, FirstPositionImage};