import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ContainerMain, BrandingTabContainer, BrandingTab } from '../common/commonStyles';
import SnapShot from './snapshot'
import Analyctics from './analytics'
import Insights from './insight'
import Performance from './performance'
import  HoverButton from '../common/HoverButton'
import {AdminDownloadIcon, AdminFilterIcon, AdminSettingIcon, CompareIcon } from '../../utils/icons'
import { ComparisonStyledModal, WrapperYear, YearDataComparisonCard } from "../Recognition/styles";
import { Modal } from "react-bootstrap";
import {NewActivityDropdownCommon} from '../common/CommonDropDown/style';
import { Dropdown } from "react-bootstrap";
import {DropdownArrow, CloseIconBranding, AddIconBranding } from "../../utils/icons";
import { ImageUrl } from "../../utils/constants";
import {ModalTitle, Border, DropdownHeading } from './styles'
import { CommonFilterDropdown } from '../common/CommonYearFilter/CommonYearFilter';
import DownloadReportsModal from './DownloadReportsModal';
import Anniversaries from './anniversaries';
import Library from './library';
import ManageAniBiPopup from './corevalues/ManageAniBiPopup';
import DeptLoc from '../common/CommonDeptLoc/DeptLoc';
const filterOption = ["Monthly", "Quarterly", "Yearly"];
const anniversaryFilterOptions = ["Monthly", "Quarterly"];

const RecognitionMain = ({ /*companyBrandingDetails,*/ history, yearList }) => {
  let date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  const [activeTab, setActiveTab] = useState(1);
  const [isComparisonModal, handleComparisonModal] = useState(false);
  const [isReportsDownloadModal, handleReportsDownloadModal] = useState(false);
  const [comparisonFromYear, getComparisonFromYear]=useState(new Date().getFullYear())
  const [comparisonToYear, getComparisonToYear]=useState(date.getFullYear())
  const [filterOptions, setFilterOptions] = useState(filterOption);
  const [showManageAniBiPopup, setShowManageAniBiPopup] = useState(false);
  const[isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [timeInterval, setTimeInterval] = useState("Monthly");
  const [selectedYear, setSelectedYear] = useState("2025");
  const yearOptions = ["2023", "2024", "2025"];

  useEffect(()=>{
    if(history.location.state){
      setActiveTab(history.location.state.activeTab)
    }
  },[])

  const ReportsDownloadPopup = ()=>{
    handleReportsDownloadModal(!isReportsDownloadModal)
  }

  const manageComparisonPopup = ()=>{
    handleComparisonModal(!isComparisonModal)
  }

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
    if(tabIndex === 5){
      setFilterOptions(anniversaryFilterOptions);
      setTimeInterval("Quarterly");
    }
  };

  // let navIndex = companyBrandingDetails?.color_branding?.length > 0 ? companyBrandingDetails?.color_branding.findIndex((data) => data.custom_name === 'side_menu_color') : -1;
  // let getColor = navIndex !== -1 && companyBrandingDetails?.color_branding[navIndex]?.color_code;
  let getColor = "rgba(243, 122, 101, 1)"

  const tabs = [
    { id: 1, label: 'Snapshot' },
    { id: 2, label: 'Analytics' },
    { id: 3, label: 'Insights' },
    { id: 4, label: 'Performance' },
    { id: 5, label: 'Anniversaries' },
    { id: 6, label: 'Library' }
  ];

  const handleComparisonYear= (fromYear, toYear)=>{
    getComparisonFromYear(fromYear)
    getComparisonToYear(toYear)
  }

  const comparisonModal = () => {
    const EngagementEmployeeData = [
      {
        title: "Total Social Posts",
        value: "1",
        img: "/admin-recognition/icons/SocialBox.svg",
        fromCount: 3,
        toCount: 2,
        totalCount: "Posts",
        color: "#DA92C8",
        borderRadius: "11px",
        difference:1,
        differenceType: 'minus',
      },
      {
        title: "Recognition Received",
        value: "2",
        img: "/admin-recognition/icons/TotalRecognitionimg.svg",
        fromCount: 4,
        toCount: 2,
        totalCount: "People Received",
        color: "#91C49D",
        difference:2,
        differenceType: 'plus',
      },
      {
        title: "Recognition Given",
        value: "3",
        img: "/admin-recognition/icons/PeerRecognitionimg.svg",
        fromCount: 20,
        toCount: 20,
        totalCount: "People Gave",
        color: "#BE7D9C",
        difference:2,
        differenceType: 'plus',

      },
      {
        title: "Exemplifies Company Value",
        value: "4",
        img: "/admin-recognition/icons/Exempifiesimg.svg",
        fromCount: 10,
        toCount: 5,
        totalCount: "People Exemplified",
        color: "#B1AA56",
        difference:1,
        differenceType: 'minus',
      },
    ];
    return (
      <ComparisonStyledModal
        show={isComparisonModal}
        onHide={manageComparisonPopup}
        dialogClassName="modal-90w"
        style={{ overflowY:'hidden'}}
        border="1"
        // padding={"16px 24px"}
      >
        <ModalTitle style={{padding:"16px 24px 0px 24px"}}>
          <div className='title'>Recognition Overview Comparison</div>
          <div className='close' onClick={manageComparisonPopup} >{CloseIconBranding()}</div>
        </ModalTitle>
        <Border margin="16px 0"/>
        <Modal.Body  style={{padding:"16px 24px"}} >
          <DropdownHeading>
            <div className='name'>Select oldest year</div>
            <div className='name'>Select newest year</div>
          </DropdownHeading>
          <WrapperYear style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
            <NewActivityDropdownCommon
              style={{width:"364px"}}
              border
            >
              <Dropdown.Toggle>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",fontFamily: "Lato", fontSize: "16px", fontWeight: "400", lineHeight: "20px", color: "#184A61"}}>
                  <div>{comparisonToYear}</div>
                  {yearList?.length>1?<div>
                    {DropdownArrow()}
                  </div>:null}
                </div>
              </Dropdown.Toggle>
              {yearList?.length>1?<Dropdown.Menu>
                {yearList?.map((list, index) => (
                  comparisonFromYear>list&&
                  <Dropdown.Item
                    key={index}
                    onClick={() => handleComparisonYear(comparisonFromYear,list)}
                  >
                    {list}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>:null}
            </NewActivityDropdownCommon>
            <NewActivityDropdownCommon
              style={{width:"364px"}}
              border
            >
              <Dropdown.Toggle>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",fontFamily: "Lato", fontSize: "16px", fontWeight: "400", lineHeight: "20px", color: "#184A61"}}>
                  <div>{comparisonFromYear}</div>
                  {yearList?.length>1?<div>
                    {DropdownArrow()}
                  </div>:null}
                </div>
              </Dropdown.Toggle>
              {yearList?.length>1?<Dropdown.Menu>
                {yearList?.map((list, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={(yearList.length==index+1)?null:() => handleComparisonYear(list, (comparisonToYear<list?comparisonToYear: yearList[index+1]))}
                  >
                    {list}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>:null}
            </NewActivityDropdownCommon>
          </WrapperYear>
          <Border margin="24px 0 12px 0"/>
          <div style={{ width:'100%'}}>
            {EngagementEmployeeData.map((item,index)=>(
              <YearDataComparisonCard key={index} background={item.color}>
                <div className="image">
                  <img src={ImageUrl +  item.img}/>
                </div>
                <div className="title">
                  {item.title}
                </div>
                <div className="typeValue">
                  <div className="value">{item.toCount}</div>
                  {/* <div className="type">{item.totalCount}</div> */}
                </div>
                {item.difference==0?
                  <div className="arrows" style={{justifyContent:"center"}}>
                    <div className={"percentOrange"}>{item.difference}%</div>
                  </div>
                  :<div className="arrows">
                    <img style={{marginLeft:'5px'}} src={`${ImageUrl}/admin-recognition/${item.differenceType}.svg`}/>
                    {item.differenceType === "plus"?<div className={"percent"}>{item.differenceType === "plus" && "+"}{item.difference}%</div>:<div className={"percentRed"}>{item.differenceType === "plus" && "+"}{item.difference}%</div>}
                  </div>}
                <div className="newData">
                  {item.fromCount}
                  {/* <div className="type">{item.totalCount}</div> */}
                </div>
              </YearDataComparisonCard>))}
          </div>
        </Modal.Body>
      </ComparisonStyledModal>
    );
  };


  return (
    <> 
      <ContainerMain>
        <div className='content-wrap'>
          <BrandingTabContainer isCompare={"0px"}>
            <div className='wrapper'>
              {tabs.map((tab) => (
                <BrandingTab
                  key={tab.id}
                  onClick={() => handleTabClick(tab.id)}
                  active={activeTab === tab.id}
                  background={getColor ? getColor : "#0b4170"}
                >
                  {tab.label}
                </BrandingTab>
              ))}
            </div>
            <div className='rightSection'>
              {activeTab === 1 ?
                <div className='compare'>
                  <HoverButton
                    title={"Recognition Comparison"}
                    svgPath={<CompareIcon />}
                    activeColor= {"#F37A65"}
                    inActiveColor= {"#F37A65"}
                    backgroundColor={"white"}
                    backgroundColorActive={"#F37A65"}
                    onClick={manageComparisonPopup}
                    width={"20px"}
                    height={"20px"}
                  />
                </div> : null
              }
              { activeTab === 5 ?
                <div className='compare'>
                  <HoverButton
                    title={"Setting"}
                    svgPath={<AdminSettingIcon/>}
                    activeColor= {"#F37A65"}
                    inActiveColor= {"#F37A65"}
                    backgroundColor={"white"}
                    backgroundColorActive={"#F37A65"}
                    onClick={()=>setShowManageAniBiPopup(true)}
                    width={"18px"}
                    height={"18px"}
                  />
                </div> : null
              }
              { activeTab === 5 ?
                <div className='compare'>
                  <HoverButton
                    title={"Filter"}
                    svgPath={<AdminFilterIcon/>}
                    activeColor= {"#F37A65"}
                    inActiveColor= {"#F37A65"}
                    backgroundColor={"white"}
                    backgroundColorActive={"#F37A65"}
                    onClick={()=>setIsDropdownOpen(!isDropdownOpen)}
                    width={"18px"}
                    height={"16px"}
                  />
                  <DeptLoc setIsDropdownOpen={setIsDropdownOpen} isDropdownOpen={isDropdownOpen}/>
                </div> : null
              }
              { activeTab !== 1 && activeTab !== 6 ? 
                <div className='compare'>
                  <HoverButton
                    title={"Download"}
                    svgPath={<AdminDownloadIcon/>}
                    activeColor= {"#F37A65"}
                    inActiveColor= {"#F37A65"}
                    backgroundColor={"white"}
                    backgroundColorActive={"#F37A65"}
                    onClick={ReportsDownloadPopup}
                    width={"16px"}
                    height={"20px"}
                  />
                </div> : null
              }
              {activeTab !== 6 && (
                <div>
                  {activeTab === 1 ? (
                    <CommonFilterDropdown
                      options={yearOptions}
                      selectedOption={selectedYear}
                      setSelectedOption={setSelectedYear}
                    />
                  ) : (
                    <CommonFilterDropdown
                      options={filterOptions}
                      selectedOption={timeInterval}
                      setSelectedOption={setTimeInterval}
                      dropdownHeight={"auto"}
                    />
                  )}
                </div>
              )}
              {activeTab==6&&
              <div className='compare'>
                <HoverButton
                  title={"Create Core Value"}
                  svgPath={<AddIconBranding />}
                  activeColor= {"#F37A65"}
                  inActiveColor= {"#F37A65"}
                  backgroundColor={"white"}
                  backgroundColorActive={"#F37A65"}
                  onClick={()=>history.push('/company/recognition/core-value/create')}
                  width={"19.2px"}
                  height={"19.2px"}
                /></div>
              }
            </div>
          </BrandingTabContainer>
          {activeTab==1&&<SnapShot/>}
          {activeTab==2&&<Analyctics getColor={getColor} selectedFilter={timeInterval}/>}
          {activeTab==3&&<Insights getColor={getColor} selectedFilter={timeInterval}/>}
          {activeTab==4&&<Performance getColor={getColor} selectedFilter={timeInterval}/>}
          {isComparisonModal&&comparisonModal()}
          {activeTab==5&&<Anniversaries getColor={getColor} selectedFilter={timeInterval}/>}
          {activeTab==6&&<Library getColor={getColor} history={history}/>}
          <DownloadReportsModal
            show={isReportsDownloadModal}
            onClose={ReportsDownloadPopup}/>

          {showManageAniBiPopup && (
            <ManageAniBiPopup
              showModal={ManageAniBiPopup}
              // detailInitiative={selectedCoreValue}
              onClose={()=>setShowManageAniBiPopup(false)}
              // companyId={this.state.companyId}
            />
          )}
        </div>
      </ContainerMain>
    </>
  );
};

RecognitionMain.propTypes = {
  companyInfo: PropTypes.object.isRequired,
  companyId: PropTypes.number,
  companyBrandingDetails: PropTypes.object,
  yearList: PropTypes.array,
  history:PropTypes.object
};

const mapStateToProps = (state) => ({
  companyBrandingDetails: state.wellnessDashboard.companyBrandingDetails
});

export default connect(mapStateToProps, null)(RecognitionMain);