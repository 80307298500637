/* eslint-disable no-undef*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { CustomButton, CustomOrderedList } from '../MedicalForm/styles';

import QuestionComponent from '../QuestionComponentV2';

import { getStressData, saveStressData } from "../../redux/actions";
import { withTranslation } from 'react-i18next';

class StressForm extends Component {

  componentDidMount() {
    this.props.fetchStressData();
  }

  navigateUser = (e, value) => {
    e.preventDefault();
    const { history } = this.props;
    history.push(value);
  };

  render() {
    const { addStressData, stressData, hraQuestionsAnswersData } = this.props;
    return (
      <form>
        <CustomOrderedList id="stress-form-list">
          {
            hraQuestionsAnswersData['stress'].map((section,index) => {
              let addedAnswers = '';
              stressData && stressData.length > 0 && stressData.map((data) => {
                if(data.question === section.question_id) {
                  addedAnswers = data.answer
                }
              });
              return (
                <QuestionComponent
                  key={section.question}
                  section={section}
                  saveData={addStressData}
                  addedAnswers={addedAnswers}
                  color={"#E48D8B"}
                  questionNo={index+1}
                  id={`stress-question-${index}`}
                />
              )
            })
          }
        </CustomOrderedList>
        <CustomButton left onClick={(e) => this.navigateUser(e, '/wellness-assessment/nutrition')} id="stress-back-button">{this.props.t("back")}</CustomButton>
        <CustomButton
          onClick={(e) => this.navigateUser(e, '/wellness-assessment/workplace')}
          disabled={_.isUndefined(stressData) || stressData.length<hraQuestionsAnswersData['stress'].length}
          id="stress-next-button"
        >
          {this.props.t("next")}
        </CustomButton>
      </form>
    )
  }
}

StressForm.propTypes = {
  addStressData: PropTypes.func.isRequired,
  fetchStressData: PropTypes.func.isRequired,
  stressData: PropTypes.array,
  history: PropTypes.object.isRequired,
  hraQuestionsAnswersData: PropTypes.array,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  stressData: state.wellBeingAssessment.stressData,
});

const mapDispatchToProps = (dispatch) => ({
  addStressData: (question, answer, isMultipleAnswers, count) => dispatch(saveStressData(question, answer, isMultipleAnswers, count)),
  fetchStressData: () => dispatch(getStressData())
});

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(StressForm));