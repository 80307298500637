/* Register and login API constants */
export const LoginApiUrl = '/v1/login';
export const CompanyLogin = '/v1/companyLogin';
export const UserRegister = '/v1/register';
export const LogoutUrl = '/v1/logout';
export const CompanyLocationAPI = '/v2/viewCompanyLocations';
export const CompanyDepartmentAPI = `/v2/viewCompanyDepartments`;
export const VerifyUserMail = "/v1/verifyEmail"
export const ForgotCompany = "/v1/forgotCompanyNamePassword";
export const SsoLogin= "/v1/ssologin";
export const SsoCallback = "/v1/callback?code="
export const Createorganization = "/v1/create-organization"
export const Getorganization = "/v1/get-organization"
export const Updateorganization = "/v1/update-organization"
export const Deleteorganization = "/v1/delete-organization"
export const Createconnection = "/v1/create-connection"
export const Connectioncallback = "/v1/connection-callback"
export const Deleteconnection = "/v1/delete-connection"

// Create Company API's
export const GetCompanyProducts = "/v1/fetchAllProducts"
export const GetCompanyCouponCodes = "/v1/fetchAllCoupons"
export const GetCompanyRegisatrtionDetails = "/v1/getCompanyRegistrationDetailsById"

/* Wellness Dashboard API constants */
export const GetWellnessInterests = '/v1/viewWellnessInterest';
export const GetViewAllWellnessInterest = '/v1/viewAllWellnessInterest';
export const GetFitnessGoals = '/v1/viewFitnessGoal';
export const GetDailyTip = '/v1/getTodayDailyTip';
export const ThanksForTheTip = '/v1/setDailyTipDateForUser';
export const GetMotivationalQuote = '/v1/getTodayMotivationalQuote';
export const GetActivityOverview = '/v2/getActivityOverview';
export const UpdateWellnessGoal = '/v1/updateTopLongTermGoals';
export const GetWorkoutVideo = 'v1/viewDailyWorkoutVideos';
export const GetConnectedDevices = 'v1/getConnectedDevices';
export const ConnectOrRevokeDevice = 'v1/deviceAction';
export const SetDailyGoalAPI = '/v1/updateDailyGoals';
export const GetRequestToken = '/v1/getRequestToken';
export const GetListOfThingsToComplete = '/v2/getListOfThingsToComplete';
export const GetUserRewardPoints = '/v1/getUserRewardPoints';
export const GetMonthlyRewardPoints = '/v1/getUserMonthlyPoints';
export const UpdateAcceptedLegalUpdates = 'v1/updateAcceptedPrivacyPolicy';
export const GetEnrolledUsers = 'v1/getUsersEnrolled';
export const GetEligibleEmployee = 'v1/getEligibleEmployee';
export const GetActiveChallenge = 'v1/getUserActiveChallenges';
export const GetUpcomingChallenges = 'v2/getUpcomingChallenges';
export const GetUserBirthday = 'v1/getAmigoBirthday';
export const GetMonthlyActivityOverview = 'v1/getMonthlyActivityOverview';
export const UpdateDeviceNotificationReadStatus = 'v1/updateDeviceNotificationReadStatus';
export const GetUserRewardDetails = 'v1/getUserRewardDetails';
export const GetUserHistoryPoints = 'v1/getUserHistoryPoints';
export const GetCompanyBrandingDetails = 'v1/getCompanyBrandingDetails'
export const ShowBiometricStatus = 'v2/showBiometricStatus'
export const UserInitiatives = '/v1/userInitiatives';

/* User profile API constants */
export const GetUserProfile = '/v1/getUserProfileDetails';
export const GetUpcomingThemes = '/v1/getUpcomingThemes';
export const GetUserPosts = '/v1/getUserPosts';
export const GetUserPostImages = '/v1/getUserPostImages';
export const GetUserFriends = '/v1/getFriendList';
export const SearchAmigos = '/v1/searchUser';
export const UploadProfile = '/v1/uploadProfilePicture';
export const RemoveProfile = '/v1/removeProfilePicture';
export const GetFriendDetails ='/v1/getFriendDetails';
export const UploadBiometricFormAPI = 'v1/uploadPhysicianForm';
export const GetUserPermissions = 'v1/getUserPermissions';
// export const getUserWellnessInitiatives = '/v1/showWellnessInitiative';
export const ViewPhysicianForm = 'v1/viewPhysicianForm';
export const updateCompanyUserData = 'v1/updateCompanyUserData';
export const getUserMonthlyDetails = 'v1/getUserMonthlyDetails';
export const updateMonthlyGoals = 'v1/updateMonthlyGoals';
export const getListOfThingsToComplete = 'v2/getListOfThingsToComplete';
export const getshowUserInitiatives = 'v1/showUserInitiatives';
export const GetuserInitiatives = 'v2/userInitiatives';
export const PostuserInitiatives = 'v1/userInitiatives';
export const GetUserBiometricData = 'v1/getUserBiometricData';
export const GetWellbeingAssessmentDetailsForUser = 'v1/getWellbeingAssessmentDetailsForUser'
export const ProfileRoles = 'v1/userProfile/roles'
export const ProfileCompanyDetails = 'v1/userProfile/company-details'
export const ProfileActivityData = 'v1/userProfile/activity-data'
export const ProfileChallengeSurveys = 'v1/userProfile/challenge-surveys'
export const ProfileInputActivityGoals = 'v1/userProfile/input-activity-goals'
export const ProfileMonthlyGoals = 'v1/userProfile/monthly-goals'
export const ProfileThemes = 'v1/userProfile/themes'
export const ProfileThemesAll = 'v1/themes/all'
export const ProfileActiveSurveys = 'v1/survey-platform/get-user-surveys'
export const ConnectedDevices = 'v1/userProfile/connected-devices'
export const UserWellnessInterests = 'v1/userProfile/interests'
export const UserProfileReward = 'v1/userProfile/reward'
export const userCompanyDetails = 'v1/userProfile/company-details'
export const deleteInitiative = 'v1/deleteCompanyInitiatives'

/* Challenges API constants */
export const GetChallenges = '/v1/viewChallenges';
export const CreateChallenge = '/v1/addChallenge';
export const GetChallengeById = '/v2/getChallengeDetailsById';
export const JoinOrLeaveChallenge = '/v1/userJoinAndLeaveChallenge';
export const GetCommentsOnChallengeById = '/v1/getChallengeCommentsById';
export const AddCommentOnChallenge = '/v1/addCommentOnChallenge';
export const GetChallengeCompetitors = '/v2/getChallengeCompetitors';
export const AddCommentReplyOnChallenge = '/v1/addCommentReplyOnChallenge';
export const ActiveAndFeaturedChallenges = '/v2/getActiveandUpcomingChallenges';
export const TeamChallenges = '/v1/getTeamChallengeInvitation';
export const LeftTeamChallenge = 'v1/removeTeamInviteForChallenge';
export const GetCompanyHealthDetails = 'v1/getCompanyHealthDetails';
export const GetTeamChallengeCompetitors = 'v1/getTeamChallengeCompetitors';
export const EditChallengeAPI = '/v1/editChallenge';
export const updateDailyTaskAPI = '/v1/updateUserBehaviorTask';
export const CreateBehaviorChallengeAPI = '/v1/addBehaviorChallenge';
export const getBehvaiorChallengeById = '/v1/getBehaviorChallengeById';
export const getUserListForChallenges = '/v2/getUserListForChallenges';
export const getUserLastSyncTime = '/v2/getUserLastSync';
export const GetDownloadCSVStatus = '/v1/getDownloadCSVStatus';
export const GetBehaviorChallengesByCategory = '/v1/getBehaviorChallengesByCategory';
export const UpdateChallengeLiveStatus = '/v1/updateChallengeLiveStatus';
export const GetBehaviorChallengeDetails = '/v1/getBehaviorChallengeDetails';
export const GetUserBehaviorChallenges = '/v1/getUserBehaviorChallenges';
export const AddUserChallenge = '/v1/addUserChallenge';
export const GetActivityChallenges = '/v1/getActivityChallenges';
export const GetActivityChallengeById = '/v1/getActivityChallengeById';
export const UpdateActivityChallengeLiveStatus = '/v1/updateActivityChallengeLiveStatus';
export const AddChallengeLibrary = '/v1/addChallengeLibrary';
export const EditChallengeLibrary = '/v1/editChallengeLibrary';
export const GetChallengeSubcategory = '/v1/getChallengeSubcategory';
export const GetChallengeLibraryBySubcategory = '/v1/getChallengeLibraryBySubcategory';
export const GetUserActivityChallenges = '/v1/getUserActivityChallenges';
export const GetACMEActiveAndFeaturedChallenges = '/v1/getACMEActiveAndFeaturedChallenges';
export const GetACMEChallengeCompetitors = '/v1/getACMEChallengeCompetitors';
export const GetACMEChallengeDetailsById = '/v1/getACMEChallengeDetailsById';
export const GetACMEUserListForChallenges = '/v1/getACMEUserListForChallenges';
export const GetACMEChallengeCommentsById = '/v1/getACMEChallengeCommentsById';
export const GetGroupChallengeCompetitors = '/v1/getGroupChallengeCompetitors';
export const EditBehaviorChallenge = '/v1/editBehaviorChallenge';
export const InviteUserForJoiningChallenge = '/v1/inviteUserForJoiningChallenge';
export const UserChallengeCompletedPopup = '/v2/userChallengeCompletedPopup';
export const UserRecommendedChallenges = '/v2/recommendedChallenges';
export const UserRecommendedWellnessChallenges = '/v2/recommendedWellnessChallenges'
export const RemoveUserInviteForChallenge = '/v1/removeUserInviteForChallenge';
export const GetAdminChallenges = '/v1/getAdminCompletedChallenges';
export const GetChallengeParticipantsCount = '/v1/getChallengeParticipantsCount';
export const GetTeamChallengeDetails = '/v1/getChallengeTeamDetails';
export const DeleteChallengeById = '/v1/deleteChallengeById';
export const GetChallengeByChallengeType = '/v1/getChallengeByChallengeType';
export const GetChallengeTypeCount = '/v1/getChallengeTypeCount';
export const DeleteChallengeLibrary = '/v1/deleteChallengeLibrary';
export const GetBehaviorChallengeByCategory = '/v1/getBehaviorChallengeByCategory';


/* Social feeds API constants */
// export const GetSocialPosts = '/v1/getNewsFeeds';
export const GetSocialPosts = '/v1/getNewsFeedData'
export const GetCommentsOnPostById = '/v1/getPostCommentsByPostId';
export const LikeUnlikePost = '/v1/likeUnlikeInspirationPost';
export const LikeUnlikePostComment = '/v1/likeUnlikePostComment';
export const LikeUnlikeCommentReply = '/v1/likeUnlikeCommentReply';
export const GetFriendRequests = '/v1/getFriendRequestList';
export const AcceptAndRejectFriendRequest = '/v1/acceptAndRejectFriendRequest';
export const GetActivityFeeds = '/v1/getActivityFeeds';
export const DeleteInspirationPost = '/v1/deleteInspirationPost';
export const SendFriendRequest = '/v1/sendFriendRequest';
export const RemoveUserFriend = '/v1/unFriendUser';
export const GetShoutoutCount = 'v1/getUserShoutoutProfile';
export const GetCompanyExemplifiesValues = '/v1/getCompanyExemplifiesValues';
export const GetUserRecognitionsCount = '/v1/getUserRecognitionsCount';
export const GetJoinedChallengesByUser = '/v1/getJoinedChallengesByUser';
export const GetJoinedEventByUser = '/v1/joinedEventByUser';
export const AddInspirationPost = '/v1/addSocialPost';
export const EditInspirationPost = '/v1/editSocialPost';
export const AddCommentOnPost = '/v1/addSocialCommentOnPost';
export const AddCommentReplyOnPost = '/v1/addSocialCommentReplyOnPost';
export const GetPostLikeDetails = '/v1/getPostLikeDetails';
export const UnFriendUser = '/v1/unFriendUser';
export const GetAmigoBirthday= '/v1/getAmigoBirthday';
export const GetAmigoBirthdayForManagerView= '/v1/getTeamMembersBirthday';
export const RejectFriendSuggestion= '/v1/rejectFriendSuggestion';
export const SentRequestList = '/v1/sentRequestList';
export const UpdateCompanyExemplifyValues = '/v1/updateCompanyExemplifyValues';
export const DeleteUserPostComment = '/v1/deleteUserPostComment';
export const GetAnniversaryBirthdayUsers = '/v1/getAnniversaryBirthdayUsers';
export const Socialfeedhighlights = '/v1/socialFeedHighlights';
// export const GetInspirationPostById = '/v1/getInspirationPostById';
export const GetInspirationPostById = '/v1/getInspirationPostDataById';
export const GetAllMyAmigoInspirationPost = '/v1/getAllMyAmigoInspirationPost';
export const GetUserCelebrationData = '/v1/getUserCelebrationData';

/* Events API constants */
export const CreateEvent = '/v1/addEvent';
export const GetPlaces = '/v1/getGooglePlaces';
export const GetEvents = '/v1/getEvents';
export const GetEventDetailsById = '/v1/getEventById';
export const GetCommentsOnEvent = '/v1/getEventCommentsById';
export const AddCommentOnEvent = '/v1/addCommentOnEvent';
export const AddCommentReplyOnEvent = '/v1/addCommentReplyOnEvent';
export const AttendEvent = '/v1/updateUserEvent';
export const GetEventAttendees = '/v1/getEventUsersById';
export const FeaturedEvents = '/v1/getFeaturedEvents';
export const EditEventAPI = '/v1/editEvent';
export const UpdateEventPoints='/v1/updateEventPoints';
export const SearchUserForEvent = '/v1/searchUserForEvent';
export const GetActiveEvents = '/v1/getEventsByStatus/active'
export const GetPastEvents = '/v1/getEventsByStatus/past'
export const GetOndemandEventDetailsById = '/v1/onDemandVideoById';
export const CreateOnDemandFitnessVideo = '/v1/OnDemandFitnessVideo';
export const EditOnDemandVideo = '/v1/editOnDemandVideo';
export const OnDemandCategories = '/v1/getWellbeingCategories';
export const GetOnDemandVideosByCatgoryId = '/v1/getOnDemandVideosByCatgoryId';
export const DeleteOnDemandEventByVideoId = '/v1/deleteOnDemandEventByVideoId';
export const DeleteEventById = '/v1/deleteEventById';
export const GetCompanyEvents = '/v1/getCompanyAndWolibaEvents/company';
export const GetWolibaEvents = '/v1/getCompanyAndWolibaEvents/woliba';
export const GetFriendListToInviteForEvents = '/v1/getFriendListToInviteForEvents';
export const InviteUserForJoiningEvents = '/v1/inviteUserForJoiningEvents';
export const GetUserListForEvents = '/v1/getUserListForEvents';
export const CreateEventAPI = '/v1/addEvent';
export const GetCoachProfile = '/v1/fetch-coach-detail';
export const FetchCoaches = '/v1/fetch-coaches';
export const FetchUpcomingCoachEvent = '/v1/getUpcomingEventsForCoach';
export const GetFeaturedEventsByCoachId = '/v1/getFeaturedEventsByCoachId';

/* Health risk assessment APi constant*/
export const SaveWellbeingAssessment = '/v1/saveUserWellbeingAssessment';

/* SettingsPage API constant */
export const UpdateProfileData = '/v1/updateProfileDetails';
export const UpdateWellnessAPI = '/v1/updateWellnessDetails';

/* EducationPage API constants */
export const GetAdventureCategories = '/v1/getWellnessJourneyCategories';
export const GetThemes = '/v1/themes';
export const GetSelectedAdventureCategories = '/v1/allWellnessJourneyCategories';
export const GetFeaturedWellnessExpert = '/v1/getFeaturedWellnessExpert';
export const FeaturedJourneyAPI = '/v2/getFeaturedJourneys';
export const GetWellnessJourneysByCategory = '/v2/getWellnessJourneysByCategory';
export const GetVideosCategoriesAPI = '/v2/getVideoCategories';
export const GetSelectedVideosCategoriesAPI = '/v1/allVideoCategories';
export const GetVideosLibraryAPI = '/v2/getVideosByCategory';
export const GetRecommendedVideos = '/v1/getRecommendedVideos';
export const GetFeaturedVideos = '/v2/getFeaturedVideos';
export const UpdateUserGlobalPoints = '/v1/updateUserGlobalPoints';
export const UpdateJourneyStatus = '/v1/updateJourneyStatus';
export const AddQuizAPI = '/v1/addQuizzes';
export const EditQuizAPI = '/v1/editJourneyQuizzes';
export const getQuizAPI = '/v1/getQuizzesForJourney';
export const DeleteQuizQuestionAPI = '/v1/deleteJourneyQuiz';
export const fetchAdventureScoreAPI = '/v2/getUserWellnessPointsStats';
export const getWebinarCategories = 'v1/getWebinarCategories';
export const allWebinarCategories = 'v1/allWebinarCategories';
export const getWebinarVideoByCategory = 'v1/getWebinarVideoByCategory';
export const submitmultipleAnswerAPI = '/v1/addJourneyUserQuizChoices';
export const GetFitnessVideoById = '/v2/getFitnessVideoById';
export const EditVideoByCategory = 'v1/editVideoByCategory';
export const LikeUnlikeEducationPost = 'v1/likeUnlikeEducationPost';
export const GetFavoriteVideos = 'v2/getFavoriteVideos';
export const GetFavoriteArticles = 'v2/getFavoriteArticles';
export const GetFavoriteRecipes = 'v2/getFavoriteRecipes';
export const GetUserQuizScore = 'v1/getUserQuizScore';
export const FeaturedRecommendedAPI = '/v2/getRecommendedJourneys';
export const GetWellnessJourneysById = '/v1/getWellnessJourneysById';
export const FetchWellnessCards = '/v1/fetch-wellness-cards';
export const RecommendedWellnessQuizes = '/v1/recommended-wellness-quizes';
export const AddWellnessQuiz = '/v1/add-wellness-quiz';
export const wellnessQuizDetails = '/v1/wellness-quiz-details';
export const EditWellnessQuiz = '/v1/edit-wellness-quiz';

/*User side*/
export const WellnessFeaturedQuizzes = '/v1/wellness-featured-quizzes';
export const WellnessQuizeDetails = '/v1/wellness-quiz-details';
export const GetWellnessQuizesAnswers = '/v1/fetch-resoures-question-answer';
export const SaveUserQuizResponse = '/v1/save-user-quiz-response';
export const GetLeaderboardQuizDetails = '/v1/fetch-launch-quiz-details-for-leaderboard';


 
/* Reset Password */
export const ForgetPasswordAPI = '/v1/forgotPasswordLink';
export const ResetPasswordAPI = '/v1/resetPassword';
export const ChangePasswordAPI = '/v1/resetUserPassword';
export const UpdateUserEmail = '/v1/updateUserEmail';

export const ContactUs = '/v1/contactUs';
export const PricingEnterprise = 'v1/pricingEnterprise';
export const Newsletter = '/v1/subscribeNewsletter';

/* company dashboard API constants */
export const GetWellnessChampions = '/v1/getAllWellnessChampion';
export const GetCompanies = '/v1/getAllCompany';
export const GetTopChallenges = '/v1/getTopChallenges';
export const GetTopEvents = '/v1/getCompanyEvents';
export const GetNewUsers = '/v1/getNewUsers';
export const GetTopActiveUsers = '/v1/getTopActiveUsers';
export const GetLeastActiveUsers = '/v1/getLeastActiveUsers';
export const GetTopFiveFitnessGoals = '/v1/getTopFiveFitnessGoals';
export const GetTopFiveDevices = '/v1/getTopFiveDevices';
export const GetTopInterests = '/v1/getTopInterests';
export const GetExercisePerWeek = '/v1/getExercisePerWeek';
export const GetGroupActivitySummary = '/v1/getGroupActivitySummary';
export const GetTopWellnessInitiatives = '/v2/getCompanyActiveUpcomingInitiatives';
export const GetCompletedInitiatives = '/v1/getCompletedInitiatives';
export const  GetCompanyInitiatives= '/v2/getInitiativeLibrary';
export const  AddCompanyInitiatives= '/v1/addCompanyInitiatives';
export const UpdateCompanyInitiatives = '/v1/updateCompanyInitiatives'
export const GetCompanyBlockDetailsAPI ='/v1/getUsersAndAssessmentTakenCount';
export const GetInitiativeById = '/v1/getInitiativeById';
export const GetInitiativeReport = '/v1/getInitiativesReport';
export const SetReportTabAPI = '/v1/getReportsTabStatus';
export const GetMinutesActiveDetails = '/v1/getMinutesActiveDetails';
export const GetCompanyUserCount = '/v1/getCompanyUserCount';
export const GetAllWellnessAdmin = '/v1/getAllWellnessAdmin'
export const DailyTipsByCategory = '/v1/dailyTipsByCategory'
export const GetDailyTipCount = '/v1/getDailyTipCount';
export const GetDailyTipById = '/v1/getDailyTipById';
export const DeleteDailyTip = '/v1/deleteDailyTip';
export const EditDailyTip = '/v1/editDailyTip';
export const AddDailyTip = '/v1/addDailyTip';
export const GetDailyTipData = '/v1/getDailyTip';
export const GetDailyTipAndQuoteCount = '/v1/getDailyTipAndQuoteCount';
export const UpdateCompanyDetails = '/v1/updateCompanyDetails';
export const GetCompanyInfo = '/v1/userProfile/company-info';
export const RecipeCSVdownload = '/v1/recipeCSVdownload';
export const FitnessvideoCSVdownload = '/v1/fitnessvideoCSVdownload';
export const ArticleCSVdownload = '/v1/articleCSVdownload';


/*Core Value API constants */
export const GetCoreValueIcons = '/v1/fetch-core-value-icons';
export const SubmitCoreValue = '/v1/add-new-core-value';



/* wellbeing API constants */
export const GetHurdlesToEatHealthyDetails= '/v1/getHurdlesToEatHealthyDetails';
export const GetMostStressCauseDetails = '/v1/getMostStressCauseDetails';
export const GetEatFruitAndVegetableDetails ='/v1/getEatFruitAndVegetableDetails';
export const GetEatFoodWithAddedSugarDetails = '/v1/getEatFoodWithAddedSugarDetails';
export const GetFinanceStressDetails = '/v1/getFinanceStressDetails';
export const GetMinutesRelaxDetails = '/v1/getMinutesRelaxDetails';
export const GetHighlyProcessedFoodDetails = '/v1/getHighlyProcessedFoodDetails';
export const GetEatingHealthyDetails = '/v1/getEatingHealthyDetails';

/** Heath Tab Data */
export const getDoYouSmokeAPI = '/v1/getDoYouSmokeDetails';
export const getPhysicalExamAPI = '/v1/getPhysicalExamFrequency';
export const getExperiencePainAPI ='/v1/getExperiencePainDetails';
export const getPrescriptionDrungs = '/v1/getPrescriptionIntake';
export const getWellbeingAssessmentAPI = '/v2/getWellbeingAssessmentDetails';
export const getDailyWaterIntakeAPI = '/v1/getDailyWaterIntake';
export const getMedicalDiagnosisAPI = '/v1/getMedicalDiagnosesDetails';
export const getDailyAverageSleepAPI = '/v1/getDailySleepAverage';
export const getPainLocationAPI = '/v1/getTopPainLocations';
export const getFallSickLastYearAPI = '/v1/getFallSickInLastYear';
export const getAddedSugarIntakeAPI = '/v1/getAddedSugar';
export const getBurnoutFrequencyAPI = '/v1/getBurnoutFrequencies';
export const getAlcoholIntakeAPI = '/v1/getAlcoholIntake';

/**Life Style Tab Constants */
export const getCommunicateWithTeamAPI = '/v1/getCommunicateWithTeam';
export const getDecisionMakingAPI = '/v1/getConfInDecisionMaking';
export const getvaluablePartAPI = '/v1/getValuablePartOfTeam';
export const getWorkInterferesAPI = '/v1/getWorkInterfereDetails';
export const getStandByWorkAPI = '/v1/getHoursStandDetails';
export const getMinutesExerciseAPI = '/v1/get5MinutesExerciseDetails';
export const getWorkInFreeTimeAPI = '/v1/getWorkInFreeTimeDetails';
export const getHealthLimitingPerformance = '/v1/getHealthLimitingPerformance';

/* People API constants */
export const GetSearchPoeple = '/v1/searchPeople';
export const GetUsersToShoutout = 'v1/searchUserData';
export const AddEmployeeAPI = '/v1/addEmployee';
export const EditWellnessJourney = '/v1/editWellnessJourney';
export const AddWellnessJourney = '/v1/addWellnessJourney';
export const GetWellnessGoals = '/v1/getWellnessGoals';
export const GetQuestionByCategoryId = '/v1/getQuestionByCategoryId';
export const addAndUpdateLocation = '/v1/addAndUpdateLocation';
export const addAndUpdateDepartments = '/v1/addAndUpdateDepartments';
export const deleteDepartmentOrLocation = '/v1/deleteDepartmentOrLocation';
export const addBulkUserForLocation = '/v1/addBulkUserForLocation';
export const addBulkUserForDepartment = '/v1/addBulkUserForDepartment';

// export const getNotRegisteredUsersList = '/v1/getNotRegisteredUsersList';
export const getNotRegisteredUsersList= '/v2/searchHRISregisteredUser';
export const sendRemainderByIds = '/v1/sendRemainderByIds';
export const sendReminderToAlluser='/v1/sendRemainderToHrisUsers';
export const searchUnregisteredUser = '/v1/searchUnregisteredUser';
export const getDepartmentDropdownData = '/v1/getHRISDepartments';
export const getLocationDropdownData = '/v1/getHRISLocation';
export const deleteInactiveUserById = '/v1/deleteInactiveUserById';


export const AddCategory = '/v1/addCategory';

export const GetAllCompany = '/v1/getAllCompany';
export const SetOrRemoveRoleAPIConstant = '/v1/setOrRemoveUserRole';
export const DeleteEmployee = '/v1/requestUserDeletion';

export const EmployeeCountAPI = '/v1/searchPeopleCount';
export const GetUserBiometric = '/v1/getUserBiometricDetails';
export const SaveUserBiometric = 'v1/addUserDetails';
export const GetRoles = 'v1/getRoles';
export const SyncUserDeviceData = 'v1/syncUserDeviceData';
export const RemoveACMEUsers = 'v1/removeACMEUsers';
export const GetUserConnectedDevices = 'v1/getUserConnectedDevices';
export const GetUserShoutOutCount = 'v1/getUserShoutOutCount';
export const GetUserInitiativesPeoplePage = 'v1/getUserInitiativesPeoplePage';
export const GetSearchPeopleAdmin = '/v1/searchPeopleAdmin';
/* Search Amigo */
export const FetchAmigoCountAPI = '/v1/searchUserCount';

/* Biometric tab API constants */

export const BodyMass = '/v2/getBodyMassIndex';
export const BadCholesterol = '/v2/getBadCholesterol';
export const GoodCholesterol = '/v2/getGoodCholesterol';
export const Triglycerides = '/v2/getTriglycerides';
export const AverageBiometricData = '/v2/getAverageBiometricData';
export const GetSystolicBloodPressure = '/v2/getSystolicBloodPressure';
export const GetDiastolicBloodPressure = '/v2/getDiastolicBloodPressure';
export const GetFastingUser = 'v2/getFastingData';
export const GetNonFastingUser = 'v2/getNonFastingData';
export const GetTopHealthRisksAndConcerns = '/v1/getTopHealthRisksAndConcerns';

/* Recognition tab API constants */

export const RecognitionDetails = '/v1/getRecognitionDetails';
export const EmployeesOfTheMonthDetails = '/v1/getEmployeesOfTheMonthDetails';
export const MostSocialUsersDetails = '/v1/getMostSocialUsers';
export const GetRecognitionActivity = '/v1/getRecognitionActivity';
export const GetTopRecognizedRecognition = '/v1/getTopRecognizedRecognition';
export const GetTopReceiversRecognition = '/v1/getTopReceiversRecognition';
export const getCompanyRecognitions =  '/v1/getCompanyRecognitions';
export const GetRecognitionForDepartments = '/v1/getRecognitionForDepartments';
export const GetRecognitionForLocations = '/v1/getRecognitionForLocations';
export const GetRecognitionForAllDepartments = '/v1/getRecognitionForAllDepartments';
export const GetRecognitionForAllLocations = '/v1/getRecognitionForAllLocations';
export const EmployeesOfTheMonthlylyDetails = '/v1/getEmployeesOfTheMonthlylyDetails';
export const EmployeesOfTheMonthByQuarterlyDetails = '/v1/getEmployeesOfTheMonthByQuarterlyDetails';


/* Notification API*/
export const GetUserNotification = '/v1/getBellIconNotifications';
export const UpdateNotificationReadStatus = '/v1/updateNotificationReadStatus';
export const GetReadStatusCount = '/v1/getReadStatusCount';
export const ReadAllNotifications = '/v1/readAllNotifications';

/*LOG WORKOUTS*/
export const GetWellnessInterestByUserId = '/v1/getWellnessInterestByUserId';
export const AddUserLogWorkouts = '/v1/addUserLogWorkouts';
export const GetUserLogWorkout = '/v1/getUserLogWorkout';
export const DeleteWorkout='/v1/deleteLogWorkout';

/*Get upcomming events*/
export const GetUpcomingEvents = '/v2/getUpcomingEvents';
export const GetRecommenedThingsToComplete = '/v2/getRecommenedThingsToComplete';

/*Nutrition API*/
export const GetRecipeDetailsById ='v1/getRecipeDetailsById';
export const GetFeaturedRecipes = 'v1/getFeaturedRecipes';
export const GetRecipeCategories = 'v2/getRecipeCategories';
export const AllRecipeCategories = 'v1/allRecipeCategories';
export const CreateRecipe = 'v1/addRecipe';
export const EditRecipe = 'v1/editRecipe';
export const GetRecipesByCategory = 'v2/getRecipesByCategory';
export const GetRecommendedRecipes = 'v2/getRecommendedRecipes';

/*Survey*/
export const GetSurveyLibraries = 'v1/getSurveyLibraries';
export const CreateSurveyLibraries = 'v1/createSurveyLibrary';
export const GetSurveyDetails = 'v1/getSurveyDetailsById';
export const UpdateSurveyLibrary = 'v1/updateSurveyLibrary';
export const getLaunchSurveyDetailsById = 'v1/getLaunchSurveyDetailsById';
export const TakeSurvey = 'v1/takeSurvey';
export const LaunchSurvey = '/v1/launchSurvey';
export const GetSurveyReports = '/v1/getSurveyReport';
export const GetSurveyOverviewDetails = '/v1/getSurveyOverviewDetails';
export const GetTopSurveyLocations = '/v1/getTopSurveyLocations';
export const GetTopSurveyDepartments = '/v1/getTopSurveyDepartments';
export const GetSurveyReportData = '/v1/surveyReportData';

export const GetSurveyCategories = '/v2/get-survey-categories';
export const CreateNewSurvey = '/v1/survey-platform/create';
export const UpdateSurvey = '/v1/survey-platform/update';
export const GetSurveyQuestionTypes = '/v2/get-survey-question-types';
export const CreateSurveyQuestion = "/v1/survey-platform/questions";
export const GetSurveyQuestionAnswers = '/v1/survey-platform/questions';
export const DeleteSurveyQuestionAnswers = '/v1/survey-platform/questions'
export const CreateSurveyAnswer = "/v1/survey-platform/answers";
export const GetSurvey ="/v1/survey-platform/get"
export const GetSurveyDataById = 'v1/survey-platform/questions';
export const CaptureSurveyResponse = 'v1/survey-platform/survey-submit';
export const UpdateSurveyPlatformAnswer = 'v1/survey-platform/answers';
export const DuplicateSurveyFromSurvey = '/v1/survey-platform/duplicate';
export const DeleteSurveyFromSurvey = '/v1/survey-platform/delete';

export const CreateSurveyFromTemplate = '/v1/survey-platform/createFromTemplate'

// survey library
export const GetCategoriesSurvey = '/v1/survey-platform/library/count';
export const SaveSurveyAsTemplate = '/v1/survey-platform/library/createFromSurvey';
export const GetSurveyLibrary = '/v1/survey-platform/library';
export const EditSurveyLibrary = '/v1/survey-platform/library/update';
export const DuplicateSurveyFromLibrary = '/v1/survey-platform/library/duplicate';
export const DeleteSurveyFromLibrary = '/v1/survey-platform/library/delete';
export const GetSurveyLibraryQuestionAnswers = '/v1/survey-platform/questions/library';
export const SurveyPlatformCreateSurvey = '/v1/survey-platform/CreateSurvey';
export const SurveyPlatformUpdateSurvey = '/v1/survey-platform/UpdateSurvey';

// survey dashboard
export const GetRecommendedSurveys = '/v1/survey-platform/recommended-surveys';
export const GetActiveSurveys = '/v1/survey-platform/active-surveys';
export const GetSurveyAnalysis = '/v1/survey-platform/analysis';

//Survey Insights
export const GetSurveyOverview = '/v1/survey-platform/overview';
export const GetSurveyLocations = '/v1/survey-platform/location-breakdown';
export const GetSurveyDepartments = '/v1/survey-platform/department-breakdown';
export const GetSurveyQuestionInsights = '/v1/survey-platform/question-insights';
export const GetSurveyQuestionInsightsDetails = '/v1/survey-platform/question-insights-details';
export const GetSurveyReport = '/v1/survey-platform/get-survey-report-by-id';

export const GetSurveyFeedbackInsights = '/v1/survey-platform/feedback-insights';
export const FeedbackToggleStar = "/v1/survey-platform/response/toggle-star";
export const ShortQuestionsList= "/v1/survey-platform/feedback-questions";
// export const GetSurveyQuestionInsights = '/v1/survey-platform/question-insights';

export const GetTopSurveyWins = '/v1/survey-platform/top-questions';
export const GetTopSurveyRisks = '/v1/survey-platform/low-questions';

//completed surveys
export const GetCompletedSurveys = "/v1/survey-platform/completed-surveys"


/*My monthly checklist */
export const GetMonthlyTasks = 'v1/getMonthlyTasks';
export const AddUserTask = 'v1/addUserTask';

/*Admin Monthly Tasks*/
export const GetViewMonthlyTaskIcons = 'v1/viewMonthlyTaskIcons';
export const AddMonthlyTask = 'v1/addMonthlyTask';
export const GetAdminMonthlyTasks = 'v1/getMonthlyTasks';
export const GetUserTaskStatus = 'v1/getUserTaskStatus';

/*Stripe API*/
export const CompanyInformation = 'v1/company';
export const AddCustomerInfo  = 'v1/customers';
export const GetCompanySummary = 'v1/getCompanySummary';
export const GetAllPlans = 'v1/plans';
export const Subscriptions = 'v1/scheduleSubscription';
export const UpdateSubscriptions = 'v1/updateSubscription';
export const SaveCardDetails = 'v1/cards';
export const Invoices = 'v1/invoices';
export const GetAllAddons = 'v1/getAllAddons';
export const AddAddonsToSubscription = 'v1/addAddonsToSubscription';
export const CancelAddon = 'v1/cancelAddon';
export const GetCompanySubscriptionAndAddons = 'v1/getCompanySubscriptionAndAddons';
export const GetBillingTabStatus = 'v1/getBillingTabStatus';

/*Add Recognitions */
export const GetRecognitionIcons = '/v1/getRecognitionIcons';
export const DeleteCompanyExemplifyValues = '/v1/deleteCompanyExemplifyValues';
export const AddCompanyExemplifiesValues = '/v1/addCompanyExemplifyValues';
export const GetCompanyExemplifies =  '/v1/getCompanyExemplifies';

export const UpdateChallengeSurveyStatus = 'v1/updateChallengeSurveyStatus';

/* My Upcoming Events */
export const GetMyUpcomingEvents = "/v1/joinedEventByUser";
/* CompanyFeature for show monthly task tab */
export const GetCompanyFeatures = "/v1/companyFeatures";
/*Fitness Api*/
export const AddVideoByCategory = 'v1/addVideoByCategory';
/* New Notification API  Day wise*/
export const DayWiseNotifications = '/v1/getNotifications';
/* edit settings notification */
export const EditSettingsNotifications = '/v1/editNotifications';
/* Workout History Calendar API */
export const UserWorkoutHistory = '/v1/userWorkoutHistory';
/* Day wise workout history */
export const DayWiseWorkoutHistory = '/v1/workoutActivities';
/* Workout Count */
export const WorkoutActivityCount = '/v1/workoutActivityCount';
/*for perticular activity */
export const WorkoutActivityByActivityType = '/v1/workoutActivityByActivityType';
/* Suggestions for you */
export const SuggestionsForYou = '/v1/friends/suggestion';
export const SuggestionFriendRequest = '/v1/sendFriendRequest';
/* GetBirthday's list */
export const GetBirthdayList = '/v1/usersBirthday';
export const GetWorkAnniversary = '/v1/getWorkAnniversary';
//For manager View
export const GetWorkAnniversaryForManagerView = '/v1/getWorkAnniversariesOfTeamMembers';
/* AddBuddy */
export const addBuddyOnPost = '/v1/getFriendDetails';
/* sent buddy request */
export const sendFriendRequest = '/v1/sendFriendRequest';
/* remove buddy*/
export const RemoveBuddy = '/v1/unFriendUser';

/* UpdateUserPage API constant */
export const UpdateUserDetailsAPI = '/v1/updateUserDetails';

// Company Rewards
export const GetCompanyRewards = '/v2/companyRewardDetails';
export const GetCompanyRewardsDocuments = '/v1/companyRewardDocuments';

//Admin Create Company
export const CompanyCreate = '/v1/companyCreate';
export const CompanyUpdate = '/v1/editCompanyRegistrationDetails';
export const UserBirthdayAndAnniversary = '/v2/userBirthdayAndAnniversary';
export const BirthdayAndAnniversaryStatus = '/v1/birthdayAndAnniversaryStatus';
export const CompanyRewardPrograms = '/v1/companyRewardPrograms';
export const GetFitnessReport = '/v1/getFitnessReport';
export const RewardsReport = '/v1/getRewardsReport';
export const GetCompanyDetails = '/v1/getCompanyDetails';
//Add Email Notifications
export const UserEmailNotifications = '/v1/getUserEmailNotifications';
export const UpdateEmailNotifications = '/v1/updateEmailNotifications';

//Date Wise Show Data
export const GetMonthlyWorkoutStatus = '/v1/getMonthlyWorkoutStatus';
//Add Language Translation
export const  UserLanguage = '/v1/userLanguage';
export const  UpdateLanguage= '/v1/updateLanguage';
export const  Languages= '/v1/languages';

//Admine Dashboard Api
export const GetChallengesAndWellnessCount = '/v1/getChallengeAndWellnessCount';
export const GetCompanyEducationDetails = '/v1/getCompanyEducationDetails';
export const GetWebinarVideos = '/v1/getWebinarVideos';

//Live Event API
export const GetLiveEvents = '/v2/getLiveEvents';
export const EventPointDetails = '/v1/eventPointDetails';

/* Admin Challenges API constants */
export const ChallengeOverview = '/v1/challengeOverview';
export const GetTopChallengeParticipants = '/v1/getTopChallengeParticipants';
export const GetTopChallengeLeaderboard = '/v1/getTopChallengeLeaderboard';
export const GetActivityChallengeData = '/v1/getActivityChallengeData';
export const GetBehaviorChallengeData = '/v1/getBehaviorChallengeData';
export const GetUserChallengeInvitation = '/v2/getUserChallengeInvitation';
export const AdminChallengeOverview = '/v1/adminChallengeOverview';
export const GetAdminActiveandUpcomingChallenges ='/v1/getAdminActiveandUpcomingChallenges'

/* Laederboard API constants */
export const GetTopFitnessLeaderboardData = '/v1/getTopFitnessLeaderboardData';
export const GetTopSocialUsers = '/v1/getTopSocialUsers';
export const GetTopFitnessUsers = '/v1/getTopFitnessUsers';
export const GetTopWellnessUsers = '/v1/getTopWellnessUsers';
export const GetTopUserTotalPoints = '/v2/getTopUserTotalPoints';
export const GetUserPointsRank = '/v1/getUserPointsRank';
export const GetCompanyTotalTeamPoints = '/v2/getCompanyTotalTeamPoints';
export const GetTotalTeamUsersPoints = '/v2/getTotalTeamUsersPoints';
export const GetTeamsIcons = '/v1/getTeamIcons';
export const GetCompanyTeamsCount = '/v1/getCompanyTeamCount';
export const AddOrUpdateTeamsDetails = '/v1/addOrUpdateTeamDetails';
export const GetCompanyTeamAndMyTeamFlags = '/v1/getCompanyTeamAndMyTeamFlags';
export const GetUserLeaderboardPoints = '/v1/getUserLeaderboardPoints';





/*Rewards Progress Bar*/
export const GetCompanyRewardTier = "/v1/getCompanyRewardTier";
export const GetCompanyRewardTiers = "/v1/fetch-company-tier-for-user-count";
export const GetUserDetails = "/v1/getUserDetails";
export const DeleteWolibaUser = '/v1/deleteWolibaUser';
export const GetUserDetailsPeoplePage = "/v1/getUserDetailsPeoplePage";
export const GetRewardsReport = "/v1/CompanyRewardCsv";

/*Admin Team Creation*/
export const AddTeam = "/v1/addTeam";
export const GetCompanyTeamUsers = "/v2/getCompanyTeamUsers";
export const GetCompanyTeamCount = "/v1/getCompanyTeamCount";
export const GetUsersToAddToTeam = "/v1/usersToAddToTeam";
export const DeleteTeam = "/v1/deleteTeam";
export const GetTeamDetailsById = "/v1/getTeamDetailsById";
export const FetchAddTeamMembers = "/v1/addTeamMembers";
export const AddOrUpdateTeamDetails = "/v1/addOrUpdateTeamDetails";
export const GetTeamIcons="/v1/getTeamIcons";
export const GetTeamUsers =  "/v2/exportCompanyTeamUsers";
export const GetTeams="/v1/getTeams";

/*Admin Reports*/
export const GetWellbeingAssessmentDetails = "/v2/getWellbeingAssessmentDetails";
export const GetHealthAndWellbeingAssessmentForFourCard = "/v2/getHealthAndWellbeingAssessmentForFourCard";
export const GetTopWellnessWins = "/v2/getTopWellnessWins";
export const GetTopAddressableHelthRisk = "/v2/getTopAddressableHelthRisk";
export const GetWellbeingCategories = "/v1/getWellbeingCategories";
export const GetWBAQuestionByCategoryId = "/v1/getWBAQuestionByCategoryId";
export const GetTopHealthImprovementOpportunities = "/v2/getTopHealthImprovementOpportunities";

/*HRA*/
export const GetAllHRAQuestionAnswers = "/v1/getAllHRAQuestionAnswers";

// Admin Recognition Anniverseries
export const GetBirthdayListMonthwise =  "/v1/getBirthdayListMonthwise";
export const GetAnniversaryListMonthwise =  "/v1/getAnniversaryListMonthwise";
export const GetBirthdayAndAnniversaryCardStatus = "/v1/getbirthdayAndAnniversaryCardStatus/";
export const PutBirthdayAndAnniversaryCardToggle = "/v1/birthdayAndAnniversaryCardToggle";
export const GetRecognitionComparison = "/v1/getRecognitionComparison?";
export const GetEmployeeSeprateRecognitionMonthly = "/v1/getEmployeeSeprateRecognitionMonthly";

// Admin Rewards Management 
export const GetCompanyNameToUpdateRewardTire = "/v1/getCompanyNameToUpdateRewardTire";
export const UpdateCompanyRewardPoints = "/v1/updateCompanyRewardPoints";

export const UploadRewardDocuments = "/v1/uploadRewardDocuments";
export const UpdateRewardsDocumentNameForCompany = "/v1/updateCompanyRewardDocName";
export const DeleteRewardsDocumentForCompany = "/v1/deleteCompanyRewardDocument";
export const GetAllRewardsDocumentsForCompany = "/v1/getCompanyRewardDocument";
export const GetRewardsAchievedCount = '/v1/fetch-company-tier-for-user-count';

// Admin Events

export const EventEngagementData  = "/v1/getAdminEventsDashboardData";
export const GetRecommendedEventData = "/v1/getLaunchAndLearnVideos";
export const FetchRecommendedEvents = "/v1/fetch-recommended-events";
export const FetchMostWatchedEventVideos = "/v1/fetch-most-watched-event-videos";
export const FetchRecommendedEventsByCategory = "/v1/fetch-recommended-events-by-category";
export const FeaturedEventsForUser = "/v1/featured-events-for-user";
export const DownloadOnDemandEventCSV = "/v1/downloadOnDemandEventCSV";

// API Dek
export const GetApiDeck = "/v1/createSessionForVaultAccess";
export const GetCurrentIntegrations = "/v1/storeCompanyConnectedVault";
export const UpdateAutoSyncDays = "/v1/updateAutoSyncDays";

//coach API
export const CreateCoach = "/v1/add-coach";
export const GetCoaches = "/v1/fetch-coaches";
export const GetCategoryCoaches = "/v1/fetch-coaches-for-categories";

//Health Program
export const PostHealthProgram = "/v1/createHealthProgram";
export const GetHealthProgramsList = "/v1/getHealthProgramsList";
export const GetHealthProgramDetails = "/v1/getHealthProgramById";

// Health and Data Management API constants
export const GetInitiativePriority = "/v2/getInitiativePriority";
export const AddInitiativePriority = "/v1/addInitiativePriority";
export const GetAllInitiatives = "/v1/getAllInitiatives"
export const GetCompanyBiometricDetails = "/v1/getCompanyBiometricDetails";
export const FetchSampleBiometricDoc = "/v1/fetch-users-for-biometric-csv";
export const UploadBiometricCsvData = "/v1/upload-biometric-csv-data";


//Surveys V2

// survey library
export const CreateSurveyLibraryV2 = "/v2/create-survey-library";
export const EditSurveyLibraryV2 = "/v2/edit-survey-library";
export const EditQuestionAnswersV2 = "/v2/update-survey-question-answers"
export const GetSurveyLibraryDetails = "/v2/fetch-library-details";
export const FetchSurveyLibrariesByStatus = "/v2/fetch-libraries-by-status";
export const DeleteQueAnsV2 ="/v2/remove-survey-question-answers/";
export const ReorderQueV2 = "/v2/update-question-order";
export const DeleteSurveyLibraryV2 = "/v2/archive-survey-library/";
export const CreateSurveyV2 = "/v2/create-survey";
export const UpdateSurveyV2 = '/v2/edit-survey-details'
export const FetchRecommendedSurveysV2 = "/v2/fetch-recommended-surveys";
export const FetchActiveSurveys= "/v2/get-active-upcoming-surveys/";
export const FetchCompletedSurveys = "/v2/fetch-completed-survey/";
export const ArchiveSurveyV2 = "/v2/archive-survey/";
export const FetchSurveyDetails = "/v2/get-survey-details/";
export const FetchSurveyOverview = "/v2/survey-overview/";  
export const FetchSurveyQuestionInsights = "/v2/survey-question-insights/";
export const FetchTopSurveyDepartmentData = "/v2/get-top-departments/";
export const FetchTopSurveyLocationData = "/v2/get-top-locations/";
export const FetchTopRisksWins = "/v2/get-top-wins-and-risks/";
export const FetchSurveyAnalysis = "/v2/get-survey-analysis/";
export const FetchSurveyFeedbackInsights = "/v2/survey-feedbacks/";
export const FetchFeedBackQuestions = "/v2/get-feedback-questions/";
export const ToggleStarFeedback = "/v2/toggle-star/";
export const FetchExcelSurveyReport = "/v2/export-survey-report/";



// User SUrvey
export const FetchActiveUserSurveys = "/v2/fetch-active-user-surveys";
export const SubmitSurveyResponse = "/v2/submit-survey";


//Manager view
export const FetchRecognitionTeamCount = "/v1/getRecognitionCountByTimeInterval";
export const GetMyTasksDetails = "/v1/getManagerTasks";
export const GetMostPraisedMembersDetails = "/v1/getMostPraisedMembers";
export const GetFrequentRecognitionGiverDetails = "/v1/getFrequentRecognitionGivers";
export const GetLeadingSupporterDetails = "/v1/getYourLeadingSupporters";
export const GetTopAcknowledgmentsDetails = "/v1/getYourTopAcknowledgements";
export const GetTeamMembersDetails = "/v1/getTeamMembers";
export const GetTeamMembersData = "/v1/searchTeamMemberData";
export const GetTemplatesWithUserData = "/v1/getTemplatesWithUserDetails";
export const GetTopPerformers = "/v1/getTopPerformingTeamMembers";
export const GetLowPerformers = "/v1/getLowPerformingTeamMembers";
export const GetMembersRecognition = "/v1/getMembersRecognitionsCount"; //getMembersRecognitionsCount/3989/2024-01-01/2024-12-31
export const GetRecognitionGiver = "/v1/getGivenRecognitionCount";
export const GetLeadingSupporter = "/v1/getLeadingSupporterRecognitionCount";
export const GetAcknowledgmentGiver = "/v1/getTopAcknowledgementsRecognitionCount";


// third party integrations
export const RevokeSlackConnection = "/v2/slack/revoke-token"
