import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CoreValuCard, CoreValuContainer } from "./styles";
import { Border } from "../../EventDetails/styles";
import { fetchApi } from "../../../utils/methods";
import { GetCompanyExemplifies } from "../../../redux/constants/apiConstants";
import LazyImage from "../../common/LazyImage/LazyImage";
import { ImageUrl } from "../../../utils/constants";
import { RecognitionEyeIcon, RecognitionPencilIcon } from "../../../utils/icons";
import CoreValuePopup from "../corevalues/CoreValuePopup";
import { ManagerIconContainer } from "../../Manager/styles";


export const ActiveLibraryTab = ({history}) => {

  const [coreValues, setCoreValues]= useState([]);
  const [selectedCoreValue, setSelectedCoreValue]= useState();
  const [showCoreValuePopup, setShowCoreValuePopup]= useState(false);

  useEffect(()=>{
    GetCompanyCoreValues(1)
  },[]);

  const GetCompanyCoreValues = async(companyId)=>{

    const res = await fetchApi(GetCompanyExemplifies + "/" + companyId, "GET");

    if(res.status === "success"){
      setCoreValues(res.data)
    }

  }

  const ShowCoreValueDetails = (coreValue)=>{
    setShowCoreValuePopup(true);
    setSelectedCoreValue(coreValue)
  }
  return (
    <>
      <CoreValuContainer>
        <div className="header">Core Values</div>
        <Border style={{border:"1px solid #EFEFEF",margin:"20px 0px"}}/>

        <div className="core-values">
          {coreValues.map((coreValue, index)=>(
            <>
              <div className="border-btm">
                <CoreValuCard key={index}>
                  <div className="core-value-row">
                    <div className="col-one">
                      <div className="icon" style={{background:coreValue?.color}}>
                        <LazyImage style={{width:"24px"}} src={ImageUrl + "/" + coreValue?.white_icon}/>
                      </div>
                      <div className="title">{coreValue?.core_value}</div>
                    </div>
                    <div className="action">
                      <div className="eye-icon" onClick={()=>ShowCoreValueDetails(coreValue)}><ManagerIconContainer> {RecognitionEyeIcon("#F37A65")}</ManagerIconContainer></div>
                      <div className="pencil-icon" onClick={()=>history.push({pathname:`/company/recognition/core-value/edit`, state:{title:coreValue.core_value, image:coreValue?.white_icon, description:coreValue?.description, points:coreValue?.point, color:coreValue?.color, status:coreValue?.active_status, id: coreValue?.id}})}><ManagerIconContainer>{RecognitionPencilIcon()}</ManagerIconContainer></div>
                    </div>
                  </div>
                </CoreValuCard>
              </div>
            </>
          ))}
          
        </div>

      </CoreValuContainer>

      {showCoreValuePopup && (
        <CoreValuePopup
          showModal={showCoreValuePopup}
          detailInitiative={selectedCoreValue}
          onClose={()=>setShowCoreValuePopup(false)}
          // companyId={this.state.companyId}
        />
      )}
    </>

  );
};

ActiveLibraryTab.propTypes = {
  history:PropTypes.object.isRequired
};
