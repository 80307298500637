/* eslint-disable camelcase */
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';
// import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import momentTZ from 'moment-timezone';
import { DailyFitnessActivity } from './DailyDashboard';
import { TaskToComplete } from './SideBarContiner';
// const DailyTipsV3 = React.lazy(() => import('./../DailyTipsV3'));
import WelcomeModal from '../Content/WelcomeModalV2';
const LegalUpdatesPopup = React.lazy(() => import('../LegalUpdatesPopup'));
const UpdateUserInfoPopup = React.lazy(() => import('../UpdateUserInfoPopup'));
import Waiting from '../Waiting';
import UpComingChallengesSocial from '../ContentV3/UpComingChallenges/challengeSocialfeed';
import UpComingEventsSocial from '../ContentV3/UpcomingEvents/eventsSocialFeed';
import { Arrow, SubContainer, ImageContainer, DataContainer } from '../ContentV2/SideBarContiner/Styles';
import {
  getDailyTip,
  getStatusForBiometricPopUp,
  getMotivationalQuote,
  getUserId,
  getUserProfile,
  getDailyActivityData,
  SetDailyGoal,
  getUseronthlyDetailsAPI,
  revokeDevice,
  getListOfConnectedDevices,
  thanksForTheTipAPI,
  getWellnessAssessment,
  WelcomeBoxAction,
  updateLegalUpdatesAPI,
  getListOfThingsToCompleteAPI,
  updateWellnessGoal,
  getRecommenedThingsToComplete, getCompletedChallenges, deviceNotificationReadStatus, getMonthlyTasks, showDailyTipPopup, FetchSurveyQuestions, updateChallengeSurveyStatus,
  userBirthdayAndAnniversary, getStatusForHRAPopUp, fetchMonthlyRewardsPoint, getUser, getChallengeCompleted, getUserCompanyItitiatives,
} from '../../redux/actions';
import { Activities, SidebarContainer, ConnectDevice, MainContentContainer, WrapperCard, CardContainer, Header, DailyActivityParent, DasboardSubParent, ExploreMain, ExploreHeading, ExploreContainer, TopBar, /*ActiveSurveyCard*/} from './styles';
import { getCountofCalendar, findSmallMonth, findMonth, getActivityDate, fetchApi } from '../../utils/methods';
import { convertDateInTimezone, commaSeperator, convertMilesToKm } from '../../utils/methods';
// const ConnectDeviceModal = React.lazy(() => import("../ConnectDeviceModal"));
const LogWorkoutCategoryV2 = React.lazy(() => import("../LogWorkoutCategoryV2"));
import { ImageUrl, imgPath } from "../../utils/constants";
import AddInputGoals from "../AddInputGoals";
const DeviceNotificationPopup =  React.lazy(() => import("../DeviceNotificationPopup"));
import { FinalGoals, GoalsContainer, SelectedValues } from '../InputGoalsV2/styles';
const ChallengeSurveys = React.lazy(() => import('../ChallengeSurveysPopUp'));
const BirthdayWish = React.lazy(() => import('../BirthdayWishPopup'));
const AnniversaryWish = React.lazy(() => import('../WorkAnniversaryWishPopup'));
import DailyChecklist from '../DailyChecklistNew';
// import ConnectDeviceSideBar from '../ConnectDeviceSideBar';
import { WeeklyMotivationV3 } from '../ContentV3/DailyDashboard';
import ActiveChallengesV2 from '../ContentV3/UpComingChallenges/myActivechallengeV2';
import LiveEvents from '../ContentV3/UpcomingEvents/liveEvents';
import { SliderContainer } from '../ContentV3/SlideShowEducationV2/style';
import Cards from '../ContentV3/SlideShowEducationV2/Cards';
import { Card, ImageWrapper } from '../DailyChecklist/styles';
import { TextStyle } from './DailyDashboard/styles';
const BiometricPopUpV3 = React.lazy(() => import('../BiometricPopUpV3'));
// const HraPopUp = React.lazy(() => import('../HraPopUp'));
// const NewCompletedChallengesPopup = React.lazy(() => import('../CompletedChallengesPopup/NewCompletedChallengePopup'));
import moment from 'moment';
// const InviteChallengePopUp = React.lazy(() => import('./ChallengeInvitationPopup/index'));
import { withTranslation } from 'react-i18next';
import InputGoalV2 from '../../components/InputGoalsV2/index'
// const TeamChallengeInvitePopup = React.lazy(() => import('../ChallengeDashboardV2/TeamChallengeInvitePopup'));
import { toast } from 'react-toastify';
import { http } from '../../redux/http';
import { FetchActiveUserSurveys, JoinOrLeaveChallenge, LeftTeamChallenge ,TeamChallenges} from '../../redux/constants/apiConstants';
import LazyImage from '../common/LazyImage/LazyImage';
// import SurveyPopup from './SurveyPopup';
import { ContentDiv, LogoDiv, ThankButton, TitleDiv } from '../UserSurveyV1/styles';
import { StyledModal } from './SurveyPopup/style';
import SkeletonLoder from '../common/skeletonLoder';
import ConnectDeviceModal from '../ConnectDeviceModal';
import DailyTipsV3 from '../DailyTipsV3';
import HraPopUp from '../HraPopUp';
import { AdminEventsRewardsIcon, CongratulationsEmojiIcon, arrowIconDouble } from '../../utils/icons';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReservedButton } from '../MainEvents/styles';
import parse from 'react-html-parser';
import { CompanyCard, Container, Layout, SliderWrapper } from '../MainEvents/CompanyLiveEvents/styles';
// import { t } from 'i18next';
// const backgroundUrl = `url(${ImageUrl}/images/NewDashboardV2/ActiveSurveyBackground.png)`;

class ContentV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullMonth: '',
      month: '',
      day: '',
      year: '',
      showModal: 'dailyTip',
      showUpdateLightbox: 'userInfo',
      showListOfthings: true,
      showDeviceModal: false,
      showLogWorkoutModal: false,
      challengeModal: 'completedChallenges',
      challengePopupStatus: props.completedPopupData.length !== 0,
      deviceNotificationModal: props.dailyTip && !props.dailyTip.show_tip,
      showChallengeSurveyPopup: props.challengeSurveys.length !== 0,
      showBirthdayWishModal: true,
      showAnniversaryWishModal: true,
      showConnectDevice: false,
      temp: false,
      showHRA: 'HRAPopUp',
      showBiometric: 'Biometric',
      activeBiomatric: 'true',
      activeHRA: 'true',
      activeBirthday: 'true',
      modalName: '',
      showTeamChallnegeInvite:false,
      teamChallenges:[],
      showTeamChallengeInvite:false,
      activePopupTeamChallenge: {title:'', start_date:'', image:'', id:''},
      activeSurveyData:[],
      surveyData:[],
      showSurveyPopup:false,
      isSurveyTaken : false,
      PopupSurveyPoints:null,
      survey_type : "",
      skeletonArray : [0,1,2],
      currentSlide:1,
      activeSurveyDataLoading:false
    };
    this.myref = React.createRef();
  }

  componentDidMount() {
    this.getTeamChallenges();
    if (localStorage.getItem('new_user') === "true") {
      this.props.WelcomeBoxAction(true)
    }
    else (
      this.userRegisterStatus()
    )
    const locationState = this.props.history.location.state;
    if(locationState && (this.state.PopupSurveyPoints === null && this.state.survey_type === "")){
      const completedSurveyPoint = this.props.history.location.state.completedSurveyPoint;
      const Survey_type = this.props.history.location.state.surveyCategory;
      this.setState({isSurveyTaken : true, PopupSurveyPoints: completedSurveyPoint, survey_type:Survey_type})
    }
    const hasTakenSurvey = typeof window !== 'undefined' && window.sessionStorage.getItem('hasTakenSurvey');
    if(hasTakenSurvey){
      this.setState({ showSurveyPopup: false });
    }else{
      // this.getAllActiveSurveys("?launched_in_last_24_hrs=true")
    }
    // this.getAllActiveSurveys();
    this.getSurveys();

  }

  Data = [
    {
      "image": "/images/NewDashboardV2/icon2.svg",
      "FieldName": this.props.t("Log Workout")
    }
  ];

  DataNew = [
    {
      "image": "/images/NewDashboardV2/calender.svg",
      "FieldName": this.props.t("Monthly Task")
    }
  ];


  getSurveys = async() => {
    this.setState({activeSurveyDataLoading:true});
    try {
      const res = await fetchApi(FetchActiveUserSurveys,"GET");

      if(res?.data?.message){
        toast.error(res.data.message);
        this.setState({activeSurveyDataLoading:false});
      }else{
        this.setState({
          activeSurveyData: res.data
        });
        this.setState({activeSurveyDataLoading:false});
      }
    } catch (error) {
      toast.error(error);
      this.setState({activeSurveyDataLoading:false});
    }
  };


  handleBeforeChange = (oldIndex, newIndex) => {
    // Update the current slide index when the slider changes
    this.setState({ currentSlide: newIndex + 1 });
  };


  renderActiveSurveys = () => {
    const { activeSurveyData,activeSurveyDataLoading } = this.state;
    // const lang = localStorage.getItem("lang");
    const{t}=this.props;
    let sliderSetting = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      draggable: true,
      adaptiveHeight: true,
      nextArrow: <div className={"left-arrow slick-next"} />,
      prevArrow: <div className={"left-arrow slick-prev"} />,
      beforeChange: this.handleBeforeChange,
      responsive: [
        {
          breakpoint: 1252,
          settings: {
            dots: true,
            infinite: true,
            speed: 500,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            dots: true,
            infinite: true,
            speed: 500,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
          },
        },
      ],
    };
    return (
      <>
        {activeSurveyDataLoading ? <SkeletonLoder width={"1246px"} height={"374px"} style={{margin:"40px 0px"}}/> : <React.Fragment>
          {activeSurveyData && activeSurveyData.length===0?null:<Layout margin={"40px 0 20px 0"}>
            <div className="headingMain">
              <div className="heading">{this.props.t("Active Surveys")}</div>
              {activeSurveyData.length>1?<div className="arrows">
                <div className="iconBackground" onClick={() => this.slider.slickPrev()} style={{transform:"rotate(180deg)"}}>
                  {arrowIconDouble()}
                </div>
                <div className="text">{this.state.currentSlide} / {activeSurveyData?.length}</div>
                <div className="iconBackground" onClick={() => this.slider.slickNext()} >
                  {arrowIconDouble()}
                </div>
              </div>:null}
            </div>
            <Container>
              { activeSurveyData?.length > 0 && (
                <SliderWrapper
                  display="flex"
                  content="center"
                  shadow="0px 8px 25px 0px #0D427040"
                >
                  <Slider {...sliderSetting} ref={(slider) => (this.slider = slider)}>
                    {
                      activeSurveyData?.length > 0 &&
                  activeSurveyData.map((data, index) => (
                    <div className="card" key={index}>
                      <CompanyCard
                        detailsCardPadding="0 0 10px 20px"
                        width="1246px"
                        onClick={() =>
                          this.props.history.push({pathname:`/survey`, state:{surveyId:data.id}})
                        }
                      >
                        <LazyImage div={"imageCard"}
                          src={`${ImageUrl}/${data.image}`}
                          alt="category"
                        />
                        <div className="detailsCard" style={{flexDirection:"column",justifyContent:"space-between"}}>
                          <div>
                            <div className="title">{data.title}</div>
                            <div className="details">
                              {parse(data.description)}
                            </div>
                            <div className="date">
                              <div className="icon">
                                <img src={ImageUrl+"/event-section/calendarColor.svg"}/>
                              </div>
                              <div className="detail" style={{fontFamily:"Rubik-Regular"}}>
                                {moment(data?.start_date).format(`ddd, Do MMM YYYY`)} - {moment(data?.end_date).format(`ddd, Do MMM YYYY`)}
                              </div>
                            </div>
                            {data?.points ? <div className="date" >
                              <div className="icon">
                                {(AdminEventsRewardsIcon())}
                              </div>
                              <div style={{marginTop:"4px"}}>
                                <span className="detail"  style={{fontFamily:"Rubik-Regular"}}> +{data?.points} Points</span>
                              </div>
                            </div>:null}
                          </div>
   
                          <ReservedButton active={1} onClick={() =>{    this.props.history.push({pathname:`/survey`, state:{surveyId:data.id}})}}>
                            {/* {<div className="icon"><img src={ImageUrl + "/event-section/calendarColor.svg"}/></div>} */}
                            {t("Take this Survey")}
                          </ReservedButton>
                        </div>
                        
                      </CompanyCard>
                    </div>
                  ))}
                  </Slider>
                </SliderWrapper>
              )}
            </Container>
          </Layout>}
        </React.Fragment>}
      </>

    )}



  getTeamChallenges = async() => {
    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    try {
      const res = await http.get(`${TeamChallenges}`, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        this.setState({ teamChallenges: res.challenge_invitation});
        if(res && !isNull(res.challenge_invitation)){
          if(res.challenge_invitation.length > 0){
            for (let i = 0; i < res.challenge_invitation.length; i++) {
              if(res.challenge_invitation[i].is_team_challenge === 1){
                this.setState({ activePopupTeamChallenge: res.challenge_invitation[i] },()=>{
                  this.showTeamChallengeModal();
                });
                break;
              }
            
            }
    
          }
        }
      }
    }catch(error){
      toast.error(error.message);
      // dispatch(networkError(error.message));
    }

  };

  onTeamChallengeModalClose = ()=>{
    this.setState({showTeamChallengeInvite:false})
  }
  showTeamChallengeModal = ()=>{
    this.setState({showTeamChallnegeInvite:true})
  }


  userRegisterStatus = () => {
    const {  getUserId, fetchDailyTip, getStatusForBiometricPopUp, fetchMotivationalQuote, fetchDailyActivityData, wellnessAssessment, getWellnessAssessment, fetchListOfThingsToComplate, getRecommenedThingsToComplete, getCompletedChallenges, getMonthlyTasks, user, showDailyTipPopup, getStatusForHRAPopUp, userBirthdayAndAnniversary, getChallengeCompleted, completedPopupData, getUserCompanyItitiatives } = this.props;
    getUserId();
    // fetchConnectedDevices();
    fetchDailyTip();
    getStatusForBiometricPopUp();
    fetchMotivationalQuote();
    fetchDailyActivityData();
    fetchListOfThingsToComplate();
    userBirthdayAndAnniversary();
    getRecommenedThingsToComplete();
    getStatusForHRAPopUp();
    // getUser();
    getChallengeCompleted();
    getUserCompanyItitiatives();
    if (!wellnessAssessment) getWellnessAssessment();
    const d = new Date;
    const month = findSmallMonth(d.getMonth());
    const fullMonth = findMonth(d.getMonth())
    const day = d.getDate();
    const year = d.getFullYear();
    const monthInNumber = getCountofCalendar(month, year);
    this.getMonth(fullMonth, month, day, year, monthInNumber.month);
    getCompletedChallenges();
    getMonthlyTasks(findMonth(d.getMonth()).toLowerCase());
    if (user && user.user_type === 1 || user && user.user_type === 2) {
      showDailyTipPopup();
    }

    if (completedPopupData && completedPopupData.length > 0)
      this.setState({
        challengePopupStatus: true
      });
  }


  UNSAFE_componentWillReceiveProps() {
    const { user } = this.props;
    let createdAt = isNull(user && user.reward_date) ? user && user.created_at :user &&  user.reward_date;
    if (convertDateInTimezone(createdAt).add(30, 'days')._d < momentTZ()._d) {
      this.setState({
        showListOfthings: false
      })
    }
    if (this.props.completedPopupData && this.props.completedPopupData.length > 0)
      this.setState({
        challengePopupStatus: true
      });
  }

  toggleConnectDevices = () => {
    this.setState((prev) => ({
      showConnectDevice: !prev.showConnectDevice
    }));
  };

  componentDidUpdate(prevProps) {
    if (prevProps.devicesDisconnected !== this.props.devicesDisconnected) {
      if (this.props.devicesDisconnected.length && (this.props.dailyTip && !this.props.dailyTip.show_tip)) {
        this.setState({
          deviceNotificationModal: true
        })
      }
    } if (prevProps.challengeSurveys !== this.props.challengeSurveys) {
      if (this.props.devicesDisconnected.length === 0 && (this.props.dailyTip && !this.props.dailyTip.show_tip)) {
        this.setState({
          showChallengeSurveyPopup: true
        })
      }
    }
  }

  getMonth = (fullMonth, month, day, year, monthInNumber) => {
    const { fetchUseronthlyDetails } = this.props;
    this.setState({
      fullMonth,
      month,
      day,
      year
    }, () => fetchUseronthlyDetails(`${year}-${monthInNumber}-01`));
  };

  hideModal = () => {
    const { thanksForTheTipAPI, dailyTip, showBiometricPopUp, showHRAPopUp } = this.props;
    if (showBiometricPopUp) {
      thanksForTheTipAPI(dailyTip.id, 'biometric');
    }
    else if (showHRAPopUp) {
      thanksForTheTipAPI(dailyTip.id, 'HRA');
    }
    else if (!showBiometricPopUp || !showHRAPopUp) {
      thanksForTheTipAPI(dailyTip.id);
    }
    this.setState({ showModal: '' });
  };

  hideLightboxPopup = () => {
    this.setState({
      showUpdateLightbox: '',
    })
  };

  hideDeviceModal = () => {
    this.setState({ showDeviceModal: false });
  };

  displayDeviceModal = () => {
    this.props.fetchConnectedDevices();
    this.setState({ showDeviceModal: true })
  };

  handleHRAPopUp = () => {
    this.setState({ showHRA: '', activeHRA: 'false' });
  };

  handleBiomtricPopUp = () => {
    this.setState({ showBiometric: 'false', activeBiomatric: "false" });
  };

  showSlideItems = (slideShowThings, history) => (

    <CardContainer color={"rgba(246,180,121,.2)"}>
      <Header marginTop={"0px"} background={"rgba(246,180,121,.2)"}>
        <div>
          <span>{this.props.t("Here's Your Recommended Weekly Wellness Activities")}</span>
          <span>{this.props.t("View All")}</span>
        </div>
      </Header>
      <SliderContainer>
        {slideShowThings && slideShowThings.length > 0 ?
          <div style={{ "display": "flex" }}>
            {
              <Cards data={slideShowThings} history={history} />
            }
          </div>
          : <div style={{ "display": "flex", gap:'25px' }}>
            {this.state.skeletonArray.map((el,i) => (
              <SkeletonLoder key={i} width={"382px"} height={"346px"}/>
            ))}
          </div>
        }
      </SliderContainer>
    </CardContainer>

  );

  showTime = (uname) => {
    let today = new Date();
    let curHr = today.getHours();
    const { t } = this.props;
    const{company_branding}=this.props.companyBranding;
    return (
      <TopBar image={curHr < 12 ? "url('/public/images/NewDashboardV2/morning.png')" : curHr < 18 ? "url('/public/images/NewDashboardV2/afternoon.png')" : "url('/public/images/NewDashboardV2/Evening.png')"} style={{marginBottom:company_branding?.show_education != 1 &&"20px"}}>
        <div>
          <span className="time">{curHr < 12 ? t("Good Morning,") : curHr < 18 ? this.props.t("Good Afternoon,") : t("Good Evening,")}</span>&nbsp;
          <span className="name">{t(uname)}!</span>
        </div>
      </TopBar>
    )
  };

  handleshowMonthlyGoals = () => {
    this.setState((prevState) => ({ showMonthlyGoalsStatus: !prevState.showMonthlyGoalsStatus }))
  };

  renderSideBar = (name) => {
    const { month, fullMonth, temp } = this.state;
    const { SetDailyGoal, inputGoals, history, listOfThingToComplate, companyDetails, themesHeader, dailyTip, wellnessAssessment, showBiometricPopUp, userInitiatives, t } = this.props;
    const isLoading = isNull(listOfThingToComplate) || isNull(userInitiatives) || isUndefined(userInitiatives) ;
    if(isLoading){
      return <div style={{width:"385", height:"1827px"}}>
        <SkeletonLoder width={"385px"} height={"120px"}/>
      </div>
    }
    return (
      <SidebarContainer>
        <DailyChecklist
          thanksForTheTipAPI={thanksForTheTipAPI}
          dailyTip={dailyTip}
          history={history}
          themesHeader={themesHeader}
          wellnessAssessment={wellnessAssessment}
          showBiometricPopUp={showBiometricPopUp}
          companyDetails={companyDetails && companyDetails.show_log_workout === 1 ? companyDetails : null}
          ShowLogWorkout={companyDetails && companyDetails.show_log_workout === 1 ? this.ShowLogWorkout : null}
          handleshowMonthlyGoals={this.handleshowMonthlyGoals}
        />
        {this.state.showMonthlyGoalsStatus && this.showMonthlyGoals()}
        <ConnectDevice>
          <button onClick={this.displayDeviceModal} >
            <span><LazyImage src={ImageUrl + "/images/NewDashboardV2/connectdevice.svg"} alt="icon2" /></span>
            <div>
              <span>{t("Connect Your Device")}</span>
            </div>
          </button>
        </ConnectDevice>
        <WrapperCard>
          {this.Data && this.Data.length > 0 ?
            this.Data.map((field, index) => (
              <Card key={index} onClick={() => this.ShowLogWorkout()}>
                <ImageWrapper margin={field.FieldName === t("Log Workouts") ? 1 : 0}>
                  <LazyImage src={ImageUrl + field.image} />
                </ImageWrapper>
                <div title={t(field.FieldName)}>
                  {t(field.FieldName)}
                </div>
              </Card>
            )) : null
          }
          {this.DataNew && this.DataNew.length > 0 ?
            this.DataNew.map((field, index) => (
              <Card key={index} onClick={() => this.showGoals()}>
                <ImageWrapper margin={field.FieldName === t("Monthly Task") ? 1 : 0}>
                  <LazyImage src={ImageUrl + field.image} />
                </ImageWrapper>
                <div title={t(field.FieldName)}>
                  {t(field.FieldName)}
                </div>
              </Card>
            )) : null
          }
        </WrapperCard>
        {temp ?
          this.showMonthlyGoals() : null
        }
        <ConnectDevice bgcolor onClick={() => history.push('/workout-history')} margin>
          <button style={{ "backgroundColor": "rgba(105, 194, 255, 1)" }}>
            <div style={{ "width": "auto", "padding": "6px", "background": "white" }}>
              <LazyImage src={ImageUrl + "/images/NewDashboardV2/weightlifting.png"} alt="icon2" width="32px" height="32px" />
            </div>
            <div>
              <span>{t("My Workout History")}</span>
            </div>
          </button>
        </ConnectDevice>
        {this.props.companyBranding?.company_branding?.show_challenge === 1 ? <UpComingChallengesSocial history={history} padding={"3"} />:null}
        {this.props.companyBranding?.company_branding?.show_events === 1 ? <UpComingEventsSocial history={history} padding={"3"} /> :null}
        
        <TaskToComplete
          fullMonth={fullMonth}
          month={month}
          SetDailyGoal={SetDailyGoal}
          inputGoals={inputGoals}
          history={history}
          listOfThingToComplate={listOfThingToComplate}
          displayDeviceModal={this.displayDeviceModal}
          padding={0}
          userInitiatives={userInitiatives}
          name={name}
          getUserCompanyItitiatives={this.props.getUserCompanyItitiatives}
          showBiometric={this.props.companyBranding?.company_branding?.show_biometric}
        />
      </SidebarContainer>
    )
  }
  handleBirthdayWishModal = () => {
    this.setState((prev) => ({
      showBirthdayWishModal: !prev.showBirthdayWishModal,
      activeBirthday: false
    }));
  }

  handleAnniversaryWishModal = () => {
    this.setState((prev) => ({
      showAnniversaryWishModal: !prev.showAnniversaryWishModal
    }));
  }
  ShowLogWorkout = () => {
    const { fetchMonthlyRewardsPoint } = this.props;
    this.setState((prevState) => ({ showLogWorkoutModal: !prevState.showLogWorkoutModal }))
    let currentMonth = moment().startOf("month").format('MMMM');
    let currentYear = moment().startOf('year').format('YYYY');
    let obj = getActivityDate(currentMonth.slice(0, 3), currentYear);
    fetchMonthlyRewardsPoint(obj.startDate);
  };
  showAddInputModal = () => {
    this.setState({
      showModal: 'addInputGoal'
    })
  };
  hideDeviceDisconnectedPopup = () => { this.setState({ deviceNotificationModal: false }); }
  hideChallengeModal = () => {
    this.setState({
      challengeModal: '',
      challengePopupStatus: false
    });
  };

  showGoals = () => {
    this.setState({
      temp: !this.state.temp
    });
  }

  showModalData = (name) => {
    this.setState({
      modalName: name,
    });
  };

  hideModalData = () => {
    this.setState({
      modalName: ''
    });
  };

  showMonthlyGoals = () => {
    const { monthly_goals } = this.props.listOfThingToComplate;
    const { monthlyGoal, t } = this.props;
    const { monthly_steps: steps, monthly_calories: calories, monthly_distance: distance } = monthlyGoal;
    const DistanceUnit = localStorage.getItem('DISTANCE');
    if (monthly_goals === 0) {
      return <SubContainer style={{ marginTop: '10px', cursor: 'pointer' }} borderNone={1} onClick={() => this.showModalData('monthly_goals')}>
        <ImageContainer>
          <img src={`${imgPath}/NewDashboardV2/Setmonthlygoalstest.png`} alt="Sync Device" />
        </ImageContainer>

        <DataContainer color={'#FBA887'}>
          <div>
            {`Set Monthly Fitness Goals`}
          </div>
          <div style={{ "color": "#69C2FF" }}>
            {`+${this.props.listOfThingToComplate.monthly_goals_points} pts`}
          </div>
        </DataContainer>

        <Arrow>
          <img src="/public/images/NewDashboardV2/arrow_right.svg" />
        </Arrow>

      </SubContainer>
    } else {
      return <FinalGoals width={1} font={1}>
        <div className="header">{`${t("My Daily Fitness Goals For")} ${findMonth(new Date().getMonth()).toLowerCase()}`}</div>
        <GoalsContainer bgImage={1}>
          <SelectedValues width="22px" height="25px">
            <img src={`${imgPath}/NewDashboardImages/Footprint.png`} alt="Steps" />
            <div className="value">{commaSeperator(steps)}</div>
            <span>{t("Steps")}</span>
          </SelectedValues>
          <SelectedValues stepImage="0 0 22px 0" width="22px" height="30px">
            <img
              src={`${imgPath}/NewDashboardImages/calories_colored.png`}
              alt="Calories Left"
              width="50px"
              height="50px"
            />
            <div className="value">{commaSeperator(calories)}</div>
            <span>{t("Burned")}</span>
          </SelectedValues>
          <SelectedValues stepImage="0 0 29px 0" width="22px" height="23px">
            <img src={`${imgPath}/NewDashboardImages/distanceNew.png`} alt="Distance" />
            <div className="value">{DistanceUnit === "KILOMETER" ? `${commaSeperator(convertMilesToKm(distance))}km` : `${commaSeperator(distance)}mi`}</div>
            <span>{t("Distance")}</span>
          </SelectedValues>
        </GoalsContainer>
      </FinalGoals>
    }
  }
  hideChallengeSurveyPopup = () => { this.setState({ showChallengeSurveyPopup: false }); }

  hideSurveyPopupModal = () => { this.setState({showSurveyPopup : false})}

  exploreRedirection = (data) => {
    const { history } = this.props;
    if (data === 1) {
      history.push('/education/recipes');
    } else if (data === 2) {
      history.push('/education/videos-library');
    } else if (data === 3) {
      history.push('/education/adventure');
    } else if (data === 4) {
      history.push('/challenges');
    }
    else {
      history.push('/events/ondemand/1');
    }
  }

  exploreSection = () => {

    const{company_branding}=this.props.companyBranding;
    return(
      <ExploreMain>
        <ExploreHeading>{this.props.t("Explore")}</ExploreHeading>
        <ExploreContainer>
          <div className='main-container'>
            {company_branding?.show_education === 1  ? <div className='wellnes-container'>
              <div className='wellness-first-row'>
                <div className='wellness-first-card' onClick={() => this.exploreRedirection(1)}>
                  <div className='wellness-card-content'>
                    <div className='wellness-text'>{this.props.t("Start Cooking")}</div>
                    <LazyImage div={'wellness-image'} src={ImageUrl + "/images/NewDashboardV2/startCooking.png"} alt="icon2" />
                  </div>
                </div>
                <div className='wellness-first-card' style={{background:"#69C2FF"}} onClick={() => this.exploreRedirection(2)}>
                  <div className='wellness-card-content'>
                    <div className='wellness-text'>{this.props.t("Get Moving")}</div>
                    <LazyImage div={'wellness-image'} src={ImageUrl + "/images/NewDashboardV2/getMoving.png"} alt="icon2" />
                  </div>
                </div>
              </div>
              <div className='wellness-second-row' onClick={() => this.exploreRedirection(3)}>
                <div className='wellness-text'>{this.props.t("Start Learning")}</div>
                <div className='wellness-image'>
                  <LazyImage div={"image-container"} src={ImageUrl + "/images/NewDashboardV2/startLearning.png"} alt="icon2" />
                </div>
              </div>
            </div>:null}
            {company_branding?.show_challenge === 1 ? <div className='challenge-container' style={{paddingLeft:company_branding?.show_education != 1 && "0px"}} onClick={() => this.exploreRedirection(4)}>
              <div className='challenge-first-row'> 
                <div className='challenge-text' >{this.props.t("Start A Challenge")}</div>
                <LazyImage style={{width:company_branding?.show_education != 1 && "280px",padding:company_branding?.show_education != 1 && "20px"}} div={'challenge-image'} src={ImageUrl + "/images/NewDashboardV2/startChallenge.png"} alt="icon2" />
              </div>
            </div>:null}
          </div>
      
          {company_branding?.show_events === 1 ? <div className='event-container' onClick={() => this.exploreRedirection(5)}>
            <div className='event-text'>{this.props.t("Launch An On-Demand Event")}</div>
            <div className='event-image'>
              <LazyImage div={'image-container'} src={ImageUrl + "/images/NewDashboardV2/launchClass.png"} alt="icon2" />
            </div>
          </div>:null}
        </ExploreContainer>
      </ExploreMain>
    )};

  renderDailyTip = () => {
    const { history, SetDailyGoal, inputGoals, dailyTip, t } = this.props;
    return (
      <Activities>
        {this.props.companyBranding?.company_branding?.show_events === 1 ? <LiveEvents history={history} />:null}
        {this.props.companyBranding?.company_branding?.show_challenge === 1 ? <ActiveChallengesV2 history={history} padding={"1"} />: null}
        {this.exploreSection()}
        <DasboardSubParent>
          <DailyActivityParent>
            <TextStyle>
              {t("Daily Activity")}
            </TextStyle>
            <DailyFitnessActivity
              SetDailyGoal={SetDailyGoal}
              inputGoals={inputGoals}
            />
          </DailyActivityParent>
          <DailyActivityParent>
            <WeeklyMotivationV3 dailyTip={dailyTip} dailyTipStatus={1} />
            <WeeklyMotivationV3 />
          </DailyActivityParent>
        </DasboardSubParent>
      </Activities>
    );
  };

  closeRegistrationPopup = () => {
    this.props.WelcomeBoxAction();
    localStorage.removeItem('new_user');
    this.userRegisterStatus();
  }

  redirectSurveyAssessment = (id) => {
    const { fetchSurveyQuestions, history } = this.props;
    fetchSurveyQuestions(id, history)
  }

  redirectToSurveyDetails = (id, points, surveyCategory) => {
    typeof window !== 'undefined' && window.sessionStorage.setItem('hasTakenSurvey', 'true');
    this.props.history.push({pathname:`/survey`, state:{surveyId:id, surveyPoints:points, surveyCategory:surveyCategory}})
  }

  handleSurveyCompletePopup = () => {
    this.setState({isSurveyTaken : false,PopupSurveyPoints: null})
    this.props.history.replace({ ...this.props.history.location, state: null })
  }

  teamChallnegeInvitePopup = (data) => {
    this.setState({showTeamChallnegeInvite:!this.state.showTeamChallnegeInvite, selectedChallenge:data})
  };

  onNoClick = async()=>{
    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    try {
      const res = await http.post(`${LeftTeamChallenge}`, {challenge_id:this.state.activePopupTeamChallenge.id}, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        this.setState({showTeamChallnegeInvite:false});
        this.getTeamChallenges();
        // this.props.featuredChallengesAPI();
      }
    }catch(error){
      toast.error(error.message);
      // dispatch(networkError(error.message));
    }
    
  }

  onYesClick = async()=>{
    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    
    try {
      const res = await http.post(`${JoinOrLeaveChallenge}`, {challenge_id:this.state.activePopupTeamChallenge.id,is_joined: 1}, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        this.setState({showTeamChallnegeInvite:false});
        this.props.history.push(`/challenges/${this.state.activePopupTeamChallenge.id}`)
        // this.getTeamChallenges();
        // this.props.featuredChallengesAPI();
      }
    }catch(error){
      toast.error(error.message);
      // dispatch(networkError(error.message));
    }

  }

  render() {
    const { showModal, showUpdateLightbox, showDeviceModal, showLogWorkoutModal, deviceNotificationModal, challengePopupStatus, showChallengeSurveyPopup, /*showConnectDevice*/ showHRA, showBiometric, activeBiomatric, activeHRA, activeBirthday, modalName } = this.state;
    const { dailyTip, networkError, userId, user, motivationalQuote, dailyActivityData, thanksForTheTipAPI,
      userMonthlyDetails, devices, showDailyTip, welcomeBoxValue, history, wellnessAssessment,
      revokeDevice, wellnessGoals, updateWellnessGoal, devicesDisconnected, deviceNotificationReadStatus, challengeSurveys, fetchSurveyQuestions, updateChallengeSurveyStatus, showBiometricPopUp, birthdayAnniversaryWishModalStatus, userName, slideShowThings, showHRAPopUp, companyDetails, t, SetDailyGoal, inputGoals, month } = this.props;
    const{company_branding}=this.props.companyBranding;

      
    if (!localStorage.getItem('new_user')) {
      if ((isNull(userId) || isNull(user) || isNull(dailyTip) || isNull(motivationalQuote) || isNull(dailyActivityData) || isNull(userMonthlyDetails))
        && !networkError) {
        return <div style={{margin:'auto', marginTop:'25px'}}>
          <SkeletonLoder style={{margin:'auto'}} width={"1246px"} height={"511px"}/>
          <div style={{display:'flex', gap:'25px',width:"1246px", margin:'auto', marginTop:'25px'}}>
            <div>
              <SkeletonLoder width={"836px"} height={"156px"}/>
              <SkeletonLoder style={{margin:'25px 0px 25px 0px'}} width={"836px"} height={"231px"}/>
              <SkeletonLoder style={{margin:'25px 0px 25px 0px'}} width={"836px"} height={"478px"}/>
              <div style={{display:'flex', gap:'25px', width:'836px'}}>
                <SkeletonLoder width={"405px"} height={"275px"}/>
                <SkeletonLoder width={"431px"} height={"275px"}/>
              </div>
            </div>
            <SkeletonLoder width={"385px"} height={"1827px"}/>
          </div>
        </div>
      }
    }
    // let ChallengeInvitationData = [];
    // if (challengeInvitation && !isUndefined(challengeInvitation) && challengeInvitation.challenge_invitation && !isUndefined(challengeInvitation.challenge_invitation[0])) {
    //   ChallengeInvitationData = challengeInvitation && !isUndefined(challengeInvitation) && challengeInvitation.challenge_invitation[0];
    // }
    return (
      localStorage.getItem('new_user') === "true" ?
        <WelcomeModal showModal={welcomeBoxValue ? true : false} closeModal={this.closeRegistrationPopup} name={user && user.fname} />
        :
        <div >
          {/* {(user && user.company_id !== 1 || user && user.company_id !== 18) && userInitiatives && userInitiatives.length > 0 && userInitiatives.map((userInitiative, index) =>
            userInitiative.type === "SURVEY" && userInitiative.is_taken === 0 ?
              <ActiveSurveyCard key={index} image={backgroundUrl}>
                <div className="wrapper">
                  <div className="message">{t(userInitiative.description)}</div>
                  <div className="button" onClick={() => this.redirectSurveyAssessment(userInitiative.survey_id)}>{t("Take This Survey")}</div>
                </div>
              </ActiveSurveyCard> : null)}
          
          {(user && user.company_id === 1 || user && user.company_id === 18) && this.state.activeSurveyData && this.state.activeSurveyData.length > 0 && 
              <ActiveSurveyCard image={backgroundUrl}>
                <div className="wrapper">
                  <div className="message">{t(this.state.activeSurveyData[0].description)}</div>
                  <div className="button" onClick={() => this.redirectToSurveyDetails(this.state.activeSurveyData[0].id,this.state.activeSurveyData[0].config !== null && this.state.activeSurveyData[0].config.points, this.state.activeSurveyData[0].category.name)}>{t("Take This Survey")}</div>
                </div>
              </ActiveSurveyCard>
          } */}

          {/* { (this.state.activeSurveyData && this.state.activeSurveyData.length > 0) && 
              (<ActiveSurveyCard image={backgroundUrl}>
                <div className="wrapper">
                  <div className="message">{t(this.state.activeSurveyData[0].title)}</div>
                  <div className="button" onClick={() => this.redirectToSurveyDetails(this.state.activeSurveyData[0].id,this.state.activeSurveyData[0].config !== null && this.state.activeSurveyData[0].config.points, this.state.activeSurveyData[0].category.name)}>{t("Take This Survey")}</div>
                </div>
              </ActiveSurveyCard>)} */}
              
          {/* {userInitiatives && userInitiatives.length > 0 && userInitiatives.map((userInitiative, index) =>
            userInitiative.type === "SURVEY" && userInitiative.is_taken === 0 &&
              (<ActiveSurveyCard key={index} image={backgroundUrl}>
                <div className="wrapper">
                  <div className="message">{t(userInitiative.description)}</div>
                  <div className="button" onClick={() => this.redirectSurveyAssessment(userInitiative.survey_id)}>{t("Take This Survey")}</div>
                </div>
              </ActiveSurveyCard>))} */}
          

          {/* {showConnectDevice ? <ConnectDeviceSideBar innerRef={this.myref} devices={devices} revokeDevice={revokeDevice} devicesDisconnectedList={devicesDisconnected} toggleConnectDevices={this.toggleConnectDevices}
            showModal={showConnectDevice} /> : null} */}
          <MainContentContainer>
            {this.showTime(user && user.fname)}
            {company_branding?.show_survey ?  this.renderActiveSurveys() : null}
            {company_branding?.show_education === 1 ? this.showSlideItems(slideShowThings, history) : null}
            {this.renderDailyTip()}
            {this.renderSideBar(user && user.fname)}
            {user && user.accepted_privacy_policy && dailyTip && dailyTip.show_tip && showDailyTip && !challengePopupStatus && /*isEmpty(ChallengeInvitationData) &&*/
              <Suspense fallback={<Waiting/>}>
                <DailyTipsV3
                  thanksForTheTipAPI={thanksForTheTipAPI}
                  showModal={!welcomeBoxValue && showModal === 'dailyTip'}
                  onClose={this.hideModal}
                  dailyTip={dailyTip}
                  history={history}
                  wellnessAssessment={wellnessAssessment}
                  showBiometricPopUp={showBiometricPopUp}
                  userId={userId}
                  t={t}
                />
              </Suspense>
            }
            {/* { ( this.state.showSurveyPopup) &&
              <Suspense fallback={<Waiting/>}>
                <SurveyPopup
                  showModal={this.state.showSurveyPopup}
                  hideModal={this.hideSurveyPopupModal}
                  onClick={() => this.redirectToSurveyDetails(this.state.activeSurveyData[0].id,this.state.activeSurveyData[0].config !== null && this.state.activeSurveyData[0].config.points, this.state.activeSurveyData[0].category.name)}
                  name={user && user.fname}
                />
              </Suspense>
            } */}
            { this.state.isSurveyTaken &&
              <Suspense fallback={<Waiting/>}>
                <StyledModal show={this.state.isSurveyTaken}>
                  <TitleDiv>
                    Survey Rewards
                  </TitleDiv>
                  <ContentDiv>
                    <p><b>{`Hey ${user && user.fname}, Thank You For Your Response!`}</b></p>
                    <p>We want to acknowledge your participation</p>
                    <p><b>{`in ${companyDetails.company_name} | ${this.state.survey_type}.`}</b></p>
                  </ContentDiv>
                  <LogoDiv>
                    <LazyImage src={ImageUrl + "/admin-survey/completed-survey.svg"} alt="icon2" />
                  </LogoDiv>
                  {this.state.PopupSurveyPoints != 0 ? <p style={{color:'#005C87', textAlign:"center", fontSize:'16px', marginTop:"15px", fontWeight:500}}>You recieved <span style={{color:'#76AB78'}}><b>{`+${this.state.PopupSurveyPoints}Pts`}</b></span> for Completing this survey.</p>:<p style={{color:'#005C87', textAlign:"center", fontSize:'16px', marginTop:"15px", fontWeight:500}}>Congratulations! {CongratulationsEmojiIcon()}<br/> You have successfully completed this survey!</p>}
                  <p style={{border:"1px solid #e6eff3"}}></p>
                  <ThankButton onClick={this.handleSurveyCompletePopup}>Thank You</ThankButton>
                </StyledModal>
              </Suspense>
            }
            {
              <Suspense fallback={<SkeletonLoder/>}>
                <LegalUpdatesPopup
                  showModal={user && !user.accepted_privacy_policy ? true : false}
                  dailyTip={dailyTip}
                  updateLegalUpdatesAPI={this.props.updateLegalUpdatesAPI}
                />
              </Suspense>
            }
            
            {
              !isNull(companyDetails) && companyDetails.show_biometric === 1 && dailyTip && dailyTip.show_tip && showBiometricPopUp && activeBiomatric && <Suspense fallback={<SkeletonLoder/>}><BiometricPopUpV3 showModal={showBiometric === 'Biometric'} onHide={this.handleBiomtricPopUp} user={user} history={history} /></Suspense>
            }
            {
              dailyTip && dailyTip.show_tip && showDailyTip && (showBiometric === 'false' || !showBiometricPopUp) && showHRAPopUp && activeHRA && <Suspense fallback={<SkeletonLoder/>}><HraPopUp showModal={showHRA === 'HRAPopUp'} onHide={this.handleHRAPopUp} history={history} /></Suspense>
            }
            {user && user.accepted_privacy_policy && user.rid !== 3 && user.user_type === 0 &&
            <Suspense fallback={<SkeletonLoder/>}>
              <UpdateUserInfoPopup
                showModal={user && user.accepted_privacy_policy && showUpdateLightbox === 'userInfo'}
                companyId={user &&user.company_id}
                userLocation={user && user.city_state_id}
                userDepartment={user && user.department_id}
                onClose={this.hideLightboxPopup}
              />
            </Suspense>
            }
            {user && user.accepted_privacy_policy &&
              <WelcomeModal showModal={welcomeBoxValue ? true : false} closeModal={this.props.WelcomeBoxAction} name={user && user.fname} />
            }
            {/* {
              completedPopupData && completedPopupData.length > 0 && dailyTip && dailyTip.show_tip && showDailyTip && challengePopupStatus &&
              <Suspense fallback={<SkeletonLoder/>}><NewCompletedChallengesPopup completedPopupData={completedPopupData} showModal={challengeModal === 'completedChallenges'} onHide={this.hideChallengeModal} /></Suspense>
            } */}
            {devicesDisconnected && devicesDisconnected.length > 0 && deviceNotificationModal &&
              <Suspense fallback={<SkeletonLoder/>}><DeviceNotificationPopup devicesDisconnected={devicesDisconnected} showModal={deviceNotificationModal} displayDeviceModal={this.displayDeviceModal} deviceNotificationReadStatus={deviceNotificationReadStatus} user={user} onHide={this.hideDeviceDisconnectedPopup} userName={userName} /></Suspense>
            }
            {
              (showBiometric === 'false' || !showBiometricPopUp) && this.state.showBirthdayWishModal && birthdayAnniversaryWishModalStatus && birthdayAnniversaryWishModalStatus.birthday &&dailyTip && !(dailyTip.show_tip) &&
              <Suspense fallback={<Waiting/>}>
                <BirthdayWish
                  onClose={this.handleBirthdayWishModal}
                  showModal={this.state.showBirthdayWishModal}

                  data={userName}
                />
              </Suspense>
            }
            {
              (showBiometric === 'false' || !showBiometricPopUp) && (birthdayAnniversaryWishModalStatus.birthday == null || activeBirthday == 'false') && this.state.showAnniversaryWishModal && birthdayAnniversaryWishModalStatus && birthdayAnniversaryWishModalStatus.work_anniversary &&dailyTip && !(dailyTip.show_tip) && <Suspense fallback={<Waiting/>}><AnniversaryWish
                onClose={this.handleAnniversaryWishModal}
                showModal={this.state.showAnniversaryWishModal}
                anniversaryDate={birthdayAnniversaryWishModalStatus.work_anniversary}
                data={userName}
              />
              </Suspense>
            }
          </MainContentContainer>
          <Suspense fallback={<Waiting/>}>
            {devices && devices.length > 0 && <ConnectDeviceModal
              showModal={showDeviceModal}
              closeModal={this.hideDeviceModal}
              devices={devices}
              revokeDevice={revokeDevice}
              devicesDisconnectedList={devicesDisconnected}
            />}
          </Suspense>
          {
            showLogWorkoutModal && <Suspense fallback={<Waiting/>}><LogWorkoutCategoryV2 title="Add Your Monthly Workout" subContent="Dashboard / monthly workout" showModal={showLogWorkoutModal} onHide={this.ShowLogWorkout} history={history} /></Suspense>
          }
          <AddInputGoals userId={userId} updateWellnessGoal={updateWellnessGoal} wellnessGoals={wellnessGoals} showModal={showModal === 'addInputGoal'} onClose={this.hideModal} />
          {(challengeSurveys.length > 0 &&dailyTip && !dailyTip.show_tip && (devicesDisconnected.length === 0)) ? <Suspense fallback={<Waiting/>}><ChallengeSurveys showModal={showChallengeSurveyPopup} challengeSurveys={challengeSurveys} fetchSurveyQuestions={fetchSurveyQuestions} history={history} name={user.fname} updateChallengeSurveyStatus={updateChallengeSurveyStatus} onHide={this.hideChallengeSurveyPopup} /></Suspense> : null}
          {/* {dailyTip && dailyTip.show_tip && !isEmpty(ChallengeInvitationData) && <Suspense fallback={<SkeletonLoder/>}><InviteChallengePopUp showModal={!isEmpty(ChallengeInvitationData) ? true : false} history={history} challengeInvitation={ChallengeInvitationData} userName={ user && user.fname} /></Suspense>} */}
          {/* {this.state.showTeamChallnegeInvite && (<Suspense fallback={<SkeletonLoder/>}><TeamChallengeInvitePopup showModal={this.state.showTeamChallnegeInvite} onClose={this.teamChallnegeInvitePopup} challenge={this.state.activePopupTeamChallenge} fname={user && user.fname} onNoClick={this.onNoClick} onYesClick={this.onYesClick}/></Suspense>)} */}
          {<InputGoalV2
            showModal={modalName === 'monthly_goals'}
            onHide={this.hideModalData}
            SetDailyGoal={SetDailyGoal}
            inputGoals={inputGoals}
            userName={user && user.fname}
            month={month}
          />}
        </div>
    )
  }
}

ContentV2.propTypes = {
  history: PropTypes.object.isRequired,
  fetchDailyTip: PropTypes.func.isRequired,
  getStatusForBiometricPopUp: PropTypes.func.isRequired,
  fetchMotivationalQuote: PropTypes.func.isRequired,
  dailyTip: PropTypes.object,
  motivationalQuote: PropTypes.object,
  networkError: PropTypes.string,
  userId: PropTypes.number,
  getUserId: PropTypes.func.isRequired,
  SetDailyGoal: PropTypes.func,
  user: PropTypes.object,
  fetchDailyActivityData: PropTypes.func.isRequired,
  dailyActivityData: PropTypes.object,
  inputGoals: PropTypes.object,
  fetchUseronthlyDetails: PropTypes.func,
  userMonthlyDetails: PropTypes.object,
  devices: PropTypes.array,
  revokeDevice: PropTypes.func.isRequired,
  fetchConnectedDevices: PropTypes.func.isRequired,
  thanksForTheTipAPI: PropTypes.func.isRequired,
  welcomeBoxValue: PropTypes.bool,
  showDailyTip: PropTypes.bool,
  wellnessAssessment: PropTypes.object,
  WelcomeBoxAction: PropTypes.func,
  getWellnessAssessment: PropTypes.func.isRequired,
  updateLegalUpdatesAPI: PropTypes.func,
  fetchListOfThingsToComplate: PropTypes.func,
  listOfThingToComplate: PropTypes.object,
  wellnessGoals: PropTypes.object,
  updateWellnessGoal: PropTypes.func.isRequired,
  slideShowThings: PropTypes.array,
  getRecommenedThingsToComplete: PropTypes.func,
  completedChallenges: PropTypes.array,
  getCompletedChallenges: PropTypes.func,
  devicesDisconnected: PropTypes.array,
  deviceNotificationReadStatus: PropTypes.func,
  getMonthlyTasks: PropTypes.func,
  monthlyCheckList: PropTypes.array,
  monthlyGoal: PropTypes.object,
  showDailyTipPopup: PropTypes.func,
  companyDetails: PropTypes.object,
  challengeSurveys: PropTypes.array,
  fetchSurveyQuestions: PropTypes.func,
  updateChallengeSurveyStatus: PropTypes.func,
  birthdayAnniversaryWishModalStatus: PropTypes.object,
  userName: PropTypes.string,
  showBiometricPopUp: PropTypes.bool,
  themesHeader: PropTypes.array,
  reward: PropTypes.object,
  check: PropTypes.bool.isRequired,
  ShowLogWorkout: PropTypes.func,
  showHRAPopUp: PropTypes.bool,
  getStatusForHRAPopUp: PropTypes.func,
  userBirthdayAndAnniversary: PropTypes.func,
  getUser: PropTypes.func.isRequired,
  completedPopupData: PropTypes.array,
  getChallengeCompleted: PropTypes.func.isRequired,
  fetchMonthlyRewardsPoint: PropTypes.func,
  getUserCompanyItitiatives: PropTypes.func,
  userInitiatives: PropTypes.array,
  challengeInvitation: PropTypes.object,
  t: PropTypes.func,
  month: PropTypes.string,
  location : PropTypes.object,
  companyBranding: PropTypes.object,
};

const mapStateToProps = (state) => ({
  dailyTip: state.profileData.dailyTip,
  showBiometricPopUp: state.profileData.showBiometricPopUp,
  motivationalQuote: state.profileData.motivationalQuote,
  userId: state.profileData.userId,
  user: state.profileData.user,
  showDailyTip: state.register.showDailyTip,
  dailyActivityData: state.profileData.dailyActivityData,
  inputGoals: state.profileData.inputGoals,
  userMonthlyDetails: state.profileData.userMonthlyDetails,
  devices: state.wellnessDashboard.devices,
  welcomeBoxValue: state.register.welcomeBoxValue,
  wellnessAssessment: state.profileData.wellnessAssessment,
  listOfThingToComplate: state.profileData.listOfThingToComplate,
  wellnessGoals: state.profileData.wellnessGoals,
  slideShowThings: state.profileData.slideShowThings,
  completedChallenges: state.profileData.completedChallenges,
  devicesDisconnected: state.profileData.devicesDisconnected,
  monthlyCheckList: state.profileData.monthlyCheckList,
  monthlyGoal: state.profileData.monthlyGoal,
  companyDetails: state.profileData.companyDetails,
  challengeSurveys: state.profileData.challengeSurveys,
  birthdayAnniversaryWishModalStatus: state.social.birthdayAnniversaryWishStatus,
  userName: state.profileData.firstName,
  themesHeader: state.profileData.themesHeader,
  reward: state.wellnessDashboard.reward,
  showHRAPopUp: state.profileData.showHRAPopUp,
  completedPopupData: state.profileData.completedPopupData,
  userInitiatives: state.profileData.userInitiatives,
  challengeInvitation: state.profileData.challengeInvitation,
  companyBranding: state.wellnessDashboard.companyBrandingDetails,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDailyTip: () => dispatch(getDailyTip()),
  getStatusForBiometricPopUp: () => dispatch(getStatusForBiometricPopUp()),
  fetchMotivationalQuote: () => dispatch(getMotivationalQuote()),
  getUserId: () => dispatch(getUserId()),
  fetchUserProfile: () => dispatch(getUserProfile()),
  fetchDailyActivityData: () => dispatch(getDailyActivityData()),
  SetDailyGoal: (dailyGoalData) => dispatch(SetDailyGoal(dailyGoalData)),
  fetchUseronthlyDetails: (date) => dispatch(getUseronthlyDetailsAPI(date)),
  fetchConnectedDevices: () => dispatch(getListOfConnectedDevices()),
  revokeDevice: (name) => dispatch(revokeDevice(name)),
  thanksForTheTipAPI: (id) => dispatch(thanksForTheTipAPI(id)),
  getWellnessAssessment: () => dispatch(getWellnessAssessment()),
  WelcomeBoxAction: (bool) => dispatch(WelcomeBoxAction(bool)),
  updateLegalUpdatesAPI: () => dispatch(updateLegalUpdatesAPI()),
  fetchListOfThingsToComplate: () => dispatch(getListOfThingsToCompleteAPI()),
  updateWellnessGoal: (updateDetail, goalDetail) => dispatch(updateWellnessGoal(updateDetail, goalDetail)),
  getRecommenedThingsToComplete: () => dispatch(getRecommenedThingsToComplete()),
  getCompletedChallenges: () => dispatch(getCompletedChallenges()),
  deviceNotificationReadStatus: () => dispatch(deviceNotificationReadStatus()),
  getMonthlyTasks: (month) => dispatch(getMonthlyTasks(month)),
  showDailyTipPopup: () => dispatch(showDailyTipPopup()),
  fetchSurveyQuestions: (id, history) => dispatch(FetchSurveyQuestions(id, history)),
  updateChallengeSurveyStatus: (data) => dispatch(updateChallengeSurveyStatus(data)),
  userBirthdayAndAnniversary: () => dispatch(userBirthdayAndAnniversary()),
  getStatusForHRAPopUp: () => dispatch(getStatusForHRAPopUp()),
  getUser: () => dispatch(getUser()),
  getChallengeCompleted: () => dispatch(getChallengeCompleted()),
  fetchMonthlyRewardsPoint: (startDate) => dispatch(fetchMonthlyRewardsPoint(startDate)),
  getUserCompanyItitiatives: () => dispatch(getUserCompanyItitiatives())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContentV2));
