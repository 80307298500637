import React from "react";
import PropTypes from "prop-types";
import {
  // ActivityDropdown,
  ActivityDropdownOne,
  ActivityTypeContainer,
  // CustomMenuItem,
  CustomMenuItemOne,
  // DropdownArrow,
  FieldTitle,
  NewActivityDropdownCommon,
  StyledLabel,
  TitleContainer,
} from "./style";
import { Dropdown } from "react-bootstrap";

export default function CommonDropdown ({
  type,
  tileContainerStyle,
  // dropdownOpen,
  // dropdownOpen,
  // dropdownStyle,
  labelText,
  isRequired,
  disabled,
  height,
  //activityDropdownStyle,
  placeholder,
  title,
  // id,
  //onClick,
  data,
  onSelect,
  onClick,
  onSelectParameter,
  active,
  //notActive,
  itemValue,
  activeValue,
  displayValue,
  valueString,
  t,
  valueIndex,
  //customMenuItemStyle,
  selectedClassCategory,
  isChallengeWellness,
  createEventUI,
  labelMargin,
  hideArrowIcon,
  // icon
}) {
  return (
    <React.Fragment>
      {type !== "challangeDropdown" && <TitleContainer tileContainerStyle={tileContainerStyle}>
        {labelText ? <StyledLabel labelMargin={labelMargin}>
          {labelText}
          {isRequired ? <span>*</span> : null}
        </StyledLabel> : null}

        {/* New dropdown */}
        <NewActivityDropdownCommon
          onClick={()=>{!disabled && onClick()}}
          onSelect={(index, /*key*/) => {
            if(!disabled){
              if(valueString){
                onSelect(data[index], valueString);
              }else
              {
                onSelect(data[index]);
              }
              
            }

          }
          }
          createEventUI={createEventUI}
          disabled={disabled}
          height={height}
        >
          <Dropdown.Toggle>
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center", color:disabled ? "rgba(0, 92, 135, 0.30)" :(!title || title != 0 || disabled && "#005C874D"),fontFamily:"Lato"}}>
              <div style={{color:disabled ? "rgba(0, 92, 135, 0.60)" :(!title && title != 0) && "#005C874D"}}>{title || title === 0? title:  placeholder?placeholder: "Select Option"}</div>
              {!hideArrowIcon && (
                disabled ? (
                  <div>
                    <img alt="image" src="/public/images/disabledDownArrow.png" />
                  </div>
                ) : (
                  <div>
                    <img alt="image" src="/public/images/teamDownArrow.svg" />
                  </div>
                )
              )}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {data?.map((item, index) => (
              <Dropdown.Item 
                eventKey={index}
                key={index}
                active={item === selectedClassCategory ? true : false}
              >
                { itemValue ? `${item[onSelectParameter[valueIndex]]} ${valueString ? valueString : ""}` : `${item} ${valueString ? valueString : ""}` }
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </NewActivityDropdownCommon>
      </TitleContainer>
      }
      {
        type === "challangeDropdown" &&  
        <ActivityTypeContainer marginTop={isChallengeWellness}>
          <FieldTitle>{labelText}:</FieldTitle>
          <ActivityDropdownOne
            title={title}
            id="dropdown-basic"
            onSelect={(index) =>
            {!disabled && onSelect(onSelectParameter[0],
              itemValue ? data[index][onSelectParameter[1]] : data[index],
              data[index][onSelectParameter[2]])
            }
            }
            disabled={disabled}
          >
            {
              data.map((item, index) => (
                <CustomMenuItemOne
                  eventKey={index}
                  key={index}
                  active={active == (activeValue ? item[onSelectParameter[1]] : item)}
                >
                  { displayValue ? `${t(item[onSelectParameter[valueIndex]])} ` : `${t(item)} ${valueString ? `${t("Weeks")}` : ""}`}
                </CustomMenuItemOne>
              ))
            }
          </ActivityDropdownOne>
        </ActivityTypeContainer>
      }
    </React.Fragment>
  );
}

CommonDropdown.propTypes = {
  type : PropTypes.string,
  labelText: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  height: PropTypes.string,
  dropdownOpen:PropTypes.bool,
  data: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  active: PropTypes.any,
  isRequired :PropTypes.bool,
  onClick: PropTypes.func,
  onSelectParameter: PropTypes.array,
  notActive: PropTypes.any,
  valueString:PropTypes.any,
  itemValue:PropTypes.bool,
  valueIndex:PropTypes.number,
  dropdownStyle:PropTypes.obj,
  tileContainerStyle:PropTypes.obj,
  activityDropdownStyle :PropTypes.obj,
  customMenuItemStyle:PropTypes.any,
  t:PropTypes.any,
  activeValue:PropTypes.bool,
  displayValue:PropTypes.bool,
  disabled:PropTypes.any,
  role:PropTypes.any,
  selectedClassCategory: PropTypes.string,
  isChallengeWellness: PropTypes.bool,
  createEventUI: PropTypes.bool,
  labelMargin:PropTypes.string,
  placeholder: PropTypes.string,
  icon:PropTypes.string,
  hideArrowIcon:PropTypes.bool,
};
