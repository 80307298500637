import PropTypes from "prop-types";
import React, {useState,useEffect} from "react";
import { ModalContentV1, StyledModal, WrapImage } from "./styles";
import {  Description, FeedInformation, HeaderContainer, ImageWrapper, LikeCommentButton, LikeCommentButtonSection, LikeCommentCount, PostCommentsWrapper, RecognitionBirthdayAnniversary, RecognitionImage, RecogntionContainer, SliderContainer, UserInformation, UserInitials, UserInitialsMain } from "../SocialFeedsV2/styles";
import { closeIcon, CongratulationsEmojiIcon } from "../../utils/icons";
import { ImageUrl } from "../../utils/constants";
import { convertUnicode, createSafeHTML } from "../../utils/methods";
import moment from 'moment';
import Slider from "react-slick-16";
import Image from "../Image";
import { useSelector } from "react-redux";
import _ from "lodash";


const SocialPostPopup = ({ isOpen, feed, onClose,profileImage }) => {
  const [showmore, setShowMore] = useState(false);
  const [notBase64,setNotBase64] = useState(false);
  //   let hideCommentText = false;
  const userId = useSelector((state) => state.profileData.userId);

  if (!isOpen) return null;
  let feedMultiImage = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    adaptiveHeight: true,
    nextArrow: <div className={"left-arrow slick-next"} />,
    prevArrow: <div className={"left-arrow slick-prev"} />,
    responsive: [
      {
        breakpoint: 1252,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          draggable: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          draggable: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };


  const showMoreText=()=>{
    setShowMore(true)
  }

  useEffect(() => {
    checkBase64(profileImage)
  }, [])
  
  const handleClose = () => {
    // Ensure onClose only updates the modal visibility and doesn't affect routing.
    onClose(); 
  };
  

  const containsDefaultPng = (str) => {
    const defaultPngFound = str?.includes("default.png");
    return defaultPngFound;
  }

  const getInitials = (inputString) =>{
    const words = inputString.split(' ');
    const initials = words.map((word) => word[0].toUpperCase());
    return initials.join('');
  }

  const checkBase64 = (profileImage) =>{
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        setNotBase64(false)
      }
      else {
        setNotBase64(true)
      }
    }
  }

  const imgSrc = (isOwn) => {
    window.console.log("isOWn",isOwn)
    // const { userId, feed, profileImage } = this.props;
    // const notBase64 = true;
    // const { notBase64 } = this.state;
    if (isOwn) {
      if (!notBase64) {
        return (!containsDefaultPng(profileImage)?<img src={`${ImageUrl}/${profileImage}`} />:<UserInitialsMain>{`${getInitials(feed?.fname)}${getInitials(feed?.lname)}`}</UserInitialsMain>)
      }
      else {
        return (!containsDefaultPng(profileImage)?<Image image={profileImage} />:<UserInitialsMain>{`${getInitials(feed?.fname)}${getInitials(feed?.lname)}`}</UserInitialsMain>)
      }
    } else if (userId == feed.uid) {
      if (!notBase64) {
        return (!containsDefaultPng(profileImage)?<img src={`${ImageUrl}/${profileImage}`} />:<UserInitialsMain>{`${getInitials(feed?.fname)}${getInitials(feed?.lname)}`}</UserInitialsMain>)
      }
      else {
        return (!containsDefaultPng(profileImage)?<Image image={profileImage} />:<UserInitialsMain>{`${getInitials(feed?.fname)}${getInitials(feed?.lname)}`}</UserInitialsMain>)
      }
    }
    else {
      return (!containsDefaultPng(feed?.profile_image)?<img src={`${ImageUrl}/${feed?.profile_image}`} />:<UserInitialsMain>{`${getInitials(feed?.fname)}${getInitials(feed?.lname)}`}</UserInitialsMain>)
    }
  };
  
  
  window.console.log(" feed-----", feed)
  const singleFeedData=_.isEmpty(feed)?feed:feed;

  window.console.log("singleFeedData----", singleFeedData)
  window.console.log("feed images",feed.post_images )

  return (
   
    <StyledModal show={isOpen} onHide={onClose} centered width="1200px">
      <ModalContentV1 isPost isWidth={"1200px"} isPaddingSocial={"0px"} >
        <div style={{ display: "flex", width: "100%",borderRadius:"6px" }}>
          <div style={{ width:"800px",height:"600px",backgroundColor:"black"}}> {feed && feed.post_images && feed.post_images.length >2 &&  feed.mentioned_users.length>2 ?
            <SliderContainer
              display="flex"
              content="center"
              shadow="0px 8px 25px 0px #0D427040">
              <Slider {...feedMultiImage}>
                {feed && feed.post_images && feed.post_images.map((data,index)=>(
                  <WrapImage  key={index}>
                    <img src={`${ImageUrl}/${data?.image}`} alt={data?.body} />
                  </WrapImage>))}
                  
              </Slider>
            </SliderContainer>:
            (feed.post_images && feed.post_images.length === 1 &&
            <WrapImage noMargin  isPadding={feed.core_value !== "Happy Birthday" && feed.core_value !== "Work Anniversary"}>
              {  feed.core_value ?
                (feed.core_value=="Happy Birthday"||feed.core_value=="Work Anniversary")?
                  <RecognitionBirthdayAnniversary margin image={feed.core_value=="Work Anniversary"?"social-feed/anniversaryCover.svg":"social-feed/birthdayCover.svg"}  height="600px">
                    <div className='mainContainer'>
                      <div className='wish'>{feed.core_value=="Work Anniversary"?"Happy Work Anniversary!":"Happy Birthday!"}</div>
                      <div className='wish-name'>{feed?.mentioned_users[0]?.fullname}</div>
                      <div className='image'>
                        <div className='imageWidth'>
                          {feed?.mentioned_users[0]?.profile_image && !containsDefaultPng(feed?.mentioned_users[0]?.profile_image)?<img src={`${ImageUrl}/${feed?.mentioned_users[0]?.profile_image}`}/>:
                            <UserInitials color={feed?.recognition_color_value}>{getInitials(feed?.mentioned_users[0]?.fullname)}</UserInitials>}
                        </div>
                      </div>
                      {feed.core_value=="Work Anniversary"?
                        <div className='message'>Your dedication and hard work have been an inspiration to us <br/> all. Here’s to many more years of success together!</div>:
                        <div className='message'>May this year bring you continued success and fulfillment in <br/>both your personal and professional life.</div>}
                    </div>
                  </RecognitionBirthdayAnniversary>:
                  

                  <RecognitionImage margin image={feed.bg_image} onClick={() => this.props.renderViewCommentAndGetComment(feed, feed.id)} height="540px">
                    <div className='mainContainer'>
                      <div className='image'>
                        <div className='imageWidth'>
                          {feed?.mentioned_users[0]?.profile_image && !containsDefaultPng(feed?.mentioned_users[0]?.profile_image)?<img src={`${ImageUrl}/${feed?.mentioned_users[0]?.profile_image}`}/>:
                            <UserInitials color={feed?.recognition_color_value}>{getInitials(feed?.mentioned_users[0]?.fullname)}</UserInitials>}
                        </div>
                        <RecogntionContainer border={feed?.recognition_color_value}>
                          <div className='recogntionvalue'>
                            <div className='image'>
                              <img src={`${ImageUrl}/${feed?.recognition_image}`}/>
                            </div>
                            <div className='name'>
                              {feed?.core_value}
                            </div>
                          </div>
                        </RecogntionContainer>
                        <div className='recognition'>
                          <span className='textRecognition'>{"Congratulations to"}</span>&nbsp;
                          <span className='boldRecognition'>{feed?.mentioned_users[0]?.fullname}</span>&nbsp;
                          {CongratulationsEmojiIcon()}
                        </div>
                      </div>
                    </div>
                  </RecognitionImage>
                
                :null}
            </WrapImage>)}
          </div>  
          <div style={{ width: "400px", backgroundColor: "white" }}>
            <div id={feed.id} key={feed.id + "key"} style={{background:"white", height:"600px", borderRadius:"0 6px 6px 0"}}>
              <HeaderContainer onModal>
                <ImageWrapper display={1} borderRadius={'50%'}>
                  {imgSrc(false)}
                </ImageWrapper>
                <UserInformation isOwnPost={userId == feed.uid} >
                  <div className='name'>{(feed.fname && feed.lname) ? (feed.fname + ' ' + feed.lname) : feed.fname}</div>
                  <div className='time'>
                    {feed.location}
                  </div>
                  <div className='time'>{(moment((feed.created_at)._d).format('DD MMM YYYY')) } |&nbsp; <img src={ImageUrl+"/social-feed/time.svg"}/></div>
                </UserInformation>
                {
                  <div className='buttondiv'>
                    {(userId === feed.uid) && 
              <>
              
                <div className='closeicon' onClick={()=>handleClose()}>
                  {closeIcon()}
                </div>
              </>}
                  </div>
                }
                {/* {(feed?.friend === 2||feed?.friend === 0 ||feed?.friend === 1) &&
            <div className='buttonWrapper'>
              <div className='closeicon' onClick={()=>onClosePopup()}>
                {closeIcon()}
              </div>
              {feed.friend === 0 ?
                <AddButton onClick={() => this.addBuddyApiCall(feed.uid, feed.id)} >
                  <div className='icons'>
                    <img src={ImageUrl+"/social-feed/add-buddy.svg"}/>
                  </div>
                  <div className='name'>
                    {"Connect"}
                  </div>
                </AddButton>:
                feed.friend === 2 ?
                  <AddButton onClick={() => this.showAddBuddyPopUp(feed.uid, feed.id)}>
                    <div className='icons'>
                      <img src={ImageUrl+"/social-feed/pending.svg"}/>
                    </div>
                    <div className='name'>
                      {"Pending"}
                    </div>
                  </AddButton>:null}
            </div>
            }  */}
              </HeaderContainer>
              <FeedInformation style={{height:"502px"}}>
                <PostCommentsWrapper >
                  <div style={{width:"100%", display:"block"}}>
                    {feed.description != null && feed.description !== '' && <Description width="true" contents paddingmain="0px" text={showmore?'':"60px"}>
                      <div className='truncate'>{createSafeHTML(feed.description ? convertUnicode(feed.description).split('\\n').join('<br />') : '')}</div>
                      {!showmore&& feed.description?.length>144&&<div className='more-text' onClick={()=>showMoreText()}>... more</div>}
                    </Description>}
                  </div>
                  {(singleFeedData?.post_like_users?.length>0)?<LikeCommentCount commentpopup>
                    <div>
                      {
                        singleFeedData?.post_like_users.length > 0 ?
                          singleFeedData?.post_like_users.length === 1 ? (
                            <span id={`like-${singleFeedData.id}-text`} className='simpletext'>{"Liked by"}&nbsp;{singleFeedData.post_like_users[0].fullname}</span>
                          ) : (
                            singleFeedData.post_like_users && singleFeedData.post_like_users.length > 1 ? (
                              <>
                                <span className='simpletext'>{"Liked by"}&nbsp;{`${singleFeedData.post_like_users[0].fullname}`} &nbsp;{("and")}&nbsp;</span> <span className='boldText' /*onClick={this.handleLikePopUp}*/>{`${singleFeedData.post_like_users.length - 1} `+(" others")}</span>
                              </>
                            ) : (
                              <div />
                            )) : null
                      }
                    </div>
                  </LikeCommentCount>:null}
                  {singleFeedData?.post_like_users.length>0?null:<div style={{background:"rgba(0,92,135,0.10)", display:"flex", width:"100%", height:"1px"}}></div>}
                  <LikeCommentButtonSection commentpopup>
                    <div className='commentSection'>
                      <LikeCommentButton>
                        <div className='icon'>
                          {singleFeedData?.own_like_post ?<img src={ImageUrl+"/social-feed/like_filed.svg"} />:<img src={ImageUrl+"/social-feed/like.svg"} />}
                        </div>
                        <div className='text'>Like</div>
                      </LikeCommentButton>
                    </div>
                    <div className='likeSection'>
                      <LikeCommentButton >
                        <div className='icon'>
                          <img src={ImageUrl+"/social-feed/comment.svg"} />
                        </div>
                        <div className='text'>Comment</div>
                      </LikeCommentButton>
                    </div>
                  </LikeCommentButtonSection> 
                 
                  {/* {this.renderCommentArea(commentText)} */}
                  {/* {this.state.commentText?.length>499&&<MaxText>You have exceeded the maximum character limit</MaxText>} */}
                  {/* {this.state.displayEmojiPicker ? <SocialFeedsEmojiPicker comment selectEmoji={this.addInspirationV2} hidePicker={this.showEmojiPicker}></SocialFeedsEmojiPicker> : null} */}
                 
                </PostCommentsWrapper>
              </FeedInformation>
            </div> 
          </div>
        </div>
      </ModalContentV1>
    </StyledModal>
  );
};
SocialPostPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  feed: PropTypes.object.isRequired,
  profileImage: PropTypes.object.isRequired,
};
export default SocialPostPopup;