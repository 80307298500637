
import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";
// Styled components

export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const CustomToggle = styled.div`
  display: flex;
  align-items: center;
  ${'' /* width: 89px; */}
  height: 20px;
  padding: 0 5px;
  font-size: 16px;
  cursor: pointer;
  color: #BDBDBD;
  &::after {
    display: none !important;
  }
`;

export const CustomToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .dropdown-arrow{
    display:flex;
    justify-content:center;
    align-items:center;
    width:16px;
    height:16px;
  }
`;

export const CustomMenu = styled(Dropdown.Menu)`
  border: 1px solid #efefef !important;
  border-radius: 4px !important;
  padding: 0px !important;
  min-width: ${(props) => props.dropdownWidth || "136px"} !important;
  width: ${(props) => props.dropdownWidth || "136px"} !important;
  height: ${(props) => props.dropdownHeight || "144px"} !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  position: absolute !important;
  top: ${({ isTop }) => isTop || "14px"} !important;
  right: ${({ isRight }) => isRight || "14px"} !important;
  z-index: 1050 !important;
`;

export const CustomDropdownItem = styled(Dropdown.Item)`
  font-family: "Lato" !important;
  font-size: 16px !important;
  padding: 14px 16px !important;
  border-radius: 4px !important;
  font-weight: 400;
  line-height: 20px;
  color: ${({ active }) => (active ? "white" : "#184A61")} !important;
  background-color: ${({ active }) =>
    active ? "#EA6149" : "transparent"} !important;

  &:hover {
    border-radius: 4px !important;
    color: white !important;
    background-color: #F37A65 !important;
  }
`;

