/* eslint-disable no-undef */
import React, { Component, Suspense } from 'react';
// import {personaldetails} from '../../../mockData.json';
import withValidations from "../WithValidations";
import {getUserBiometricDetails, saveUserInformation} from "../../redux/actions";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import { ContentWrapper, ButtonWrapper, NavigationBtn, FormWrapper, SectionContainer, StyledBiometricInput, StyledLabel} from '../MultiStepForm/styles'
import RadioButton from ".././CustomRadioButton";
import Waiting from '../Waiting';
const BiometricConfirmPopup = React.lazy(() => import('../BiometricConfirmPopup'));
class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDatePicker: false,
      bloodPressureDia: '',
      bloodPressureSys: '',
      bmi: '',
      cholestrolHdl: '',
      cholestrolLdl: '',
      cholestrolTotal: '',
      fasting: '',
      glucose: '',
      height: '',
      triglycerides: '',
      waistCircumference: '',
      weight: '',
      biometricPopupStatus: false
    }
  }

  personaldetails= [
    {
      "title": "first",
      "inputFields": [
        { "type": "text", "placeholder": "Height", "name": "height", "label": "Height"},
        { "type": "text", "placeholder":  "Weight", "name": "weight", "label": "Weight (LBS)"},
        { "type": "text", "placeholder":  "BMI", "name": "bmi", "label": "BMI"},
        { "type": "text", "placeholder":  "Systolic Blood Pressure", "name": "bloodPressureSys", "label": "Systolic Blood Pressure (mm/Hg)"},
        { "type": "text", "placeholder":  "Diastolic Blood Pressure", "name": "bloodPressureDia", "label": "Diastolic Blood Pressure (mm/Hg)"}
      ]
    },
    {
      "title": "second",
      "inputFields": [
        { "name": "fasting"},
        { "type": "text", "placeholder": "HDL Cholesterol", "name": "cholestrolHdl", "label": "HDL Cholesterol (mg/dl)"},
        {"type": "text", "placeholder": "LDL Cholesterol", "name": "cholestrolLdl", "label": "LDL Cholesterol (mg/dl)"},
        { "type": "text", "placeholder": "Triglycerides", "name": "triglycerides", "label": "Triglycerides (mg/dl)"},
        { "type": "text", "placeholder": "Glucose", "name": "glucose", "label": "Glucose (mg/dl)"},
        { "type": "text", "placeholder":  "Waist Circumference (Optional)", "name": "waistCircumference", "label": "Waist Circumference (IN)"}
      ]
    }
  ]

  isNumberKey = (evt) => {
    let charCode = (evt.which) ? evt.which : event.keyCode;
    if (charCode === 46) {
      if(evt.target.name == 'heightInFt' || evt.target.name == 'heightInInch') {
        evt.preventDefault();
      }
      else{
        if (evt.target.value.indexOf('.') != -1) {
          evt.preventDefault();
        }
      }
    }
    else {
      if (  charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    }
    return true;
  };
  showBiometricSubmitStatusPop = () => {
    this.setState((prevState) => ({biometricPopupStatus: !prevState.biometricPopupStatus}))
  }
  render() {
    const {onChange,  handleChange, values, goBack, submitForm, toggleRadioButton} = this.props;
    // if(!biometricDetails) {
    //   return <Waiting />
    // }
    let isDisabled = false;
    if(values && (values.heightInFt === 0 || values.heightInFt === '' || values.weight === '' || values.bmi === '' || values.bloodPressureSys === '' || values.bloodPressureDia === '' || values.cholestrolHdl === '' || values.cholestrolLdl === '' || values.triglycerides === '' || values.glucose === ''|| values.fasting === '')){
      isDisabled = true;
    }
    const{biometricPopupStatus} = this.state;
    return <ContentWrapper id="personal-details-wrapper">,
      <div id="personal-details-header">
        <span id="personal-details-step-number">2</span>
        <span id="personal-details-title">Biometric Details For</span>
        <span id="personal-details-username">{`${values.fname} ${values.lname}`}</span>
        <div id="personal-details-radio-group">
          <RadioButton
            id="fasting-radio"
            handler={toggleRadioButton}
            name="fasting"
            value={'1'}
            isChecked={values['fasting'] == '1'}
            label={'Fasting'}
            userBiometric={1}
          />
          <RadioButton
            id="non-fasting-radio"
            handler={toggleRadioButton}
            name="fasting"
            value={'2'}
            isChecked={values['fasting'] == '2'}
            label={'Non-Fasting'}
            userBiometric={1}
          />
        </div>
      </div>
      <div id="personal-details-form">
        {this.personaldetails.map((section, index) => (
          <FormWrapper
            key={`${section.title}-${index}`}
            id={`personal-details-form-section-${section.title}`}
            largePadding={section.title === 'first' ? '0 20px 0 30px' : '0 20px 0 30px'}
          >
            {section.inputFields.map((field, fieldIndex) => {
              if (field.name === 'height') {
                return (
                  <SectionContainer
                    key={`${field.name}-${fieldIndex}`}
                    id={`section-container-${field.name}`}
                  >
                    <StyledLabel id={`label-${field.name}`}>{field.label}</StyledLabel>
                    <StyledBiometricInput
                      id="height-ft-input"
                      type={field.type}
                      placeholder="Feet"
                      name="heightInFt"
                      onChange={(e) => onChange(e, handleChange)}
                      maxLength={field.maxLength}
                      value={values['heightInFt'] || ''}
                      onKeyPress={(e) => this.isNumberKey(e)}
                      marginRight={1}
                    />
                    <StyledBiometricInput
                      id="height-in-input"
                      type={field.type}
                      placeholder="Inches"
                      name="heightInInch"
                      onChange={(e) => onChange(e, handleChange)}
                      maxLength={field.maxLength}
                      value={values['heightInInch'] || ''}
                      onKeyPress={(e) => this.isNumberKey(e)}
                    />
                  </SectionContainer>
                );
              }
              if (field.name !== 'fasting') {
                return (
                  <SectionContainer
                    key={`${field.name}-${fieldIndex}`}
                    id={`section-container-${field.name}`}
                  >
                    <StyledLabel id={`label-${field.name}`}>{field.label}</StyledLabel>
                    <StyledBiometricInput
                      id={`input-${field.name}`}
                      type={field.type}
                      placeholder={field.placeholder}
                      name={field.name}
                      onChange={(e) => onChange(e, handleChange)}
                      maxLength={field.maxLength}
                      value={values[field.name] || ''}
                      onKeyPress={(e) => this.isNumberKey(e)}
                      fullWidth={1}
                    />
                  </SectionContainer>
                );
              }
            })}
          </FormWrapper>
        ))}
        <ButtonWrapper id="personal-details-buttons">
          <NavigationBtn
            id="back-button"
            type="submit"
            onClick={goBack}
            style={{ marginRight: '10px' }}
          >
            <i
              className="fas fa-chevron-left"
              style={{ marginRight: '10px', marginLeft: '0px' }}
            />
            Back
          </NavigationBtn>
          <NavigationBtn
            id="submit-button"
            onClick={() => this.showBiometricSubmitStatusPop()}
            float={1}
            disabled={isDisabled}
          >
            Submit
          </NavigationBtn>
        </ButtonWrapper>
      </div>
      <Suspense fallback={<Waiting />}>
        <BiometricConfirmPopup
          id="biometric-confirm-popup"
          showModal={biometricPopupStatus}
          closeModal={this.showBiometricSubmitStatusPop}
          biometricSubmitForm={submitForm}
          userBiometricData={values}
        />
      </Suspense>
    </ContentWrapper>
  }
}
PersonalDetails.propTypes = {
  onChange: PropTypes.func,
  userData: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  saveUserInformation: PropTypes.func,
  getUserBiometricDetails: PropTypes.func,
  biometricDetails: PropTypes.object,
  biometricInfo: PropTypes.object,
  history: PropTypes.object,
  handleChange: PropTypes.func,
  goBack: PropTypes.func,
  submitForm: PropTypes.func,
  values: PropTypes.object,
  toggleRadioButton: PropTypes.func
};
const mapStateToProps = (state) => ({
  userData: state.register.userData,
  biometricDetails: state.peopleHome.biometricDetails,
  biometricInfo: state.peopleHome.biometricInfo
});

const mapDispatchToProps = (dispatch) => ({
  saveUserInformation: (name, value) => dispatch(saveUserInformation(name, value)),
  getUserBiometricDetails: (userId) => dispatch(getUserBiometricDetails(userId))
});

export default withValidations(connect(mapStateToProps, mapDispatchToProps)(PersonalDetails))