/* eslint-disable camelcase*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import {
  StyledInput,
  FieldTitle,
  ImageContainer,
  ImageContentContainer,
  RemovePhotoButton,
  ImageInput,
  TitleContainer,
  CustomMenuItem,
  // CustomMenuItem
} from '../CreateEvent/styles';

import {ImageUrl, WellnessLevels, imageErrorMessage} from "../../utils/constants";
import {getOrientation, resetOrientation, checkImage, fetchApi} from "../../utils/methods";
import {EditorContainer} from "../EditWellnessJourney/styles";
import Editor from "../CKEditor";
import {ActivityDropdown, ActivityTypeContainer} from "../CreateChallengeForm/styles";
import {FirstPart} from "../BehaviorChallenge/styles";
import {RadioButtonContainer} from "../CreateRecipe/styles";
import RadioButton from "../CustomRadioButton";
import {withTranslation} from 'react-i18next';
import CommonButton from '../common/CommonButton/CommonButton';
import { GetQuestionByCategoryId, GetWellnessGoals } from '../../redux/constants/apiConstants';

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.state,
      imageUpdated: false,
      wellnessGoals:[],
      wellness_goal:null,
      wbaQuestions:[],
      showWbaQue:false,
      question:null,
      levels:null,
      level:null
    }
  }

  componentDidMount(){
    this.getWellnessGoals();
  }

  componentDidUpdate(prevProps) {
    if(this.props !== prevProps) {
      this.setState({
        ...this.props.state
      })
    }
  }

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeImage = (e) => {
    let file = e.target.files[0];
    if(checkImage(e.target.files)) {
      if((file?.size/1000000) <= 20){
        let reader = new FileReader();
        reader.readAsDataURL(file);
        const array = document.getElementById('journey-file').value.split("\\");
        reader.onloadend = () => {
          this.setState({
            image: reader.result,
            imageName: array[array.length - 1],
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                image: baseImage,
                imageUpdated: true
              });
            });
          });
        };
      } else {
        toast.error('Please select image file less than 20MB');
        document.getElementById('journey-file').value = ''; 
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById('journey-file').value = '';
    }
    
  };

  removePhoto = (e) => {
    e.preventDefault();
    document.getElementById('journey-file').value = '';
    this.setState({
      image: '',
      imageName: '',
    });
  };

  onChangeDescription = (evt) => {
    const newContent = evt.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, '')
    if (trimmedString.length  === 0 || trimmedString.length === 7){
      this.setState({description: ""})
    }
    else{
      this.setState({
        description: newContent
      })
    }

  };

  onSelect = (key, item) => {
    this.setState({
      [key]: item.id
    });
  };

  onDropDownSelect = (key, item) => {
    this.setState({
      [key]: item
    },()=>{key === "wellness_goal" && this.getWBAQuestions(item.id)});
  };

  // onQueSelect = (key, item) => {
  //   this.setState({
  //     [key]: item
  //   });
  // };

  toggleRadioButton = (e) => {
    this.setState({selectedOption: parseInt(e.target.value,10)}, ()=>{});
  };

  onSelectTheme = (value) => {
    this.setState({
      themeName: value.theme_text,
      themeMonth: value.month,
      themeId: value.id,
    });
  };

  getWellnessGoals = async()=>{
    try {
      const res = await fetchApi(`${GetWellnessGoals}`, "GET",null, {});
      if(res){
        if(this.props.state.selectedWellnessGoal){
          const selectedWellnessGoal = res.data.find((wellnessGoal) => wellnessGoal.id === this.props.state.selectedWellnessGoal);
          this.setState({wellnessGoals:res.data, wellness_goal:selectedWellnessGoal});

        }else{
          this.setState({wellnessGoals:res.data});

        }

        if(this.props.state.selectedQuestionId){
          this.getWBAQuestions(this.props.state.selectedWellnessGoal);
        }

        window.console.log("this.props.state.selectedLevel",this.props.state)

        if(this.props.state.selectedLevel){
          window.console.log("this.props.state.selectedLevel",this.props.state.selectedLevel)
          const selectedLevel = WellnessLevels.find((wellnessLevel) => wellnessLevel.level === this.props.state.selectedLevel);
          this.setState({level:selectedLevel})

        }

      }
      if (res.message) {
        toast.error(res.message);
      }
      
    }catch(error){
      toast.error(error.message);
    }
  }

  getWBAQuestions = async(categoryId)=>{
    try {
      const res = await fetchApi(`${GetQuestionByCategoryId + "/" + categoryId}`, "GET",null, {});
      window.console.log("wba que res.data",res.data)
      if(res.data.length > 0){
        if(this.props.state.selectedQuestionId){
          const selectedQuestion = res.data.find((question) => question.id === this.props.state.selectedQuestionId);
          this.setState({wbaQuestions:res.data, showWbaQue:true, question:selectedQuestion})

        }else{
          this.setState({wbaQuestions:res.data, showWbaQue:true})
        }

      }else{
        this.setState({wbaQuestions:[], showWbaQue:false})

      }
      if (res.message) {
        toast.error(res.message);
      }
      
    }catch(error){
      toast.error(error.message);
    }
  }

  render() {
    const { fields, buttonText, mandatoryFields, onSubmit, /* themes, */ t, themesHeaderAll } = this.props;
    const { imageName, image, imageUpdated, description, selectedOption, themeName, themeMonth } = this.state;

    let isDisabled = false;
    mandatoryFields.map((field) => {
      
      if(this.state.description !== null) {
        if(this.state.description.trim() == '') {
          isDisabled = true; 
        }
      }

      if(!this.state[field]) {
        isDisabled = true;
      }

      else if('title' === field) {
        if(this.state[field].trim() == '') {
          isDisabled = true; 
        }
      }
    });

    return (
      <form onSubmit={(e) => { this.setState({isDisabled: true}); onSubmit(e, this.state);}}>
        {
          fields.map((field) => {
            if(field.type === 'file') {
              return (
                <ImageContainer key={field.name}>
                  <FieldTitle>{t(field.label)} { field.mandatory ? field.mandatory && <span style={{color: 'red'}}>*</span> : ''}</FieldTitle>
                  <div>
                    { imageName &&
                    <div>
                      <img src={imageUpdated ? image : `${ImageUrl}/${image}`} />
                      <ImageContentContainer>
                        <div>{imageName}</div>
                        <RemovePhotoButton type="button" color="#aaaaaa" onClick={(e) => this.removePhoto(e)}>{t("Remove photo")}</RemovePhotoButton>
                      </ImageContentContainer>
                    </div>
                    }
                    <ImageInput htmlFor="journey-file" showAtBottom={imageName !== ''}>
                      {imageName ? t('browse new') : t('browse')}
                      <input
                        id="journey-file"
                        type="file"
                        name="user"
                        accept=".jpeg, .png, .jpg"
                        multiple={false}
                        onChange={(e) => this.onChangeImage(e)}
                        onClick={(e) => e.target.files[0] && this.onChangeImage(e)}
                      />
                    </ImageInput>
                  </div>
                </ImageContainer>
              )
            } else if(field.name === 'description') {
              return (
                <TitleContainer key={field.name}>
                  <FieldTitle>{t(field.label)} { field.mandatory ? field.mandatory && <span style={{color: 'red'}}>*</span> : ''}</FieldTitle>
                  <EditorContainer>
                    <Editor content={t(description)} onChange={this.onChangeDescription} />
                  </EditorContainer>
                </TitleContainer>
              )
            } else if(field.type === 'drop-down') {
              let newTitle = field.placeholder;
              if(this.props[field.key] && this.state[field.key]) {
                const selectedCategory = this.props[field.key].filter((category) => category.id === this.state[field.key]);
                newTitle = selectedCategory ? (selectedCategory[0] && selectedCategory[0].category_name) : field.placeholder;
              }

              return (
                <TitleContainer key={field.name}>
                  <FieldTitle>{t(field.label)} { field.mandatory ? field.mandatory && <span style={{color: 'red'}}>*</span> : ''}</FieldTitle>
                  <ActivityDropdown
                    title={t(newTitle)}
                    id={`dropdown-${field.name}`}
                  >
                    {
                      this.props[field.key].map((item, index) => (
                        <CustomMenuItem
                          eventKey={index}
                          key={index}
                          onClick={() => this.onSelect(field.key, item)}
                          active={this.state[field.key] === item.id}
                        >
                          {t(item.category_name)}
                        </CustomMenuItem>
                      ))
                    }
                  </ActivityDropdown>
                </TitleContainer>
              )
            }else if(field.type === 'wellness_goal') {

              return (
                <TitleContainer key={field.name}>
                  <FieldTitle>{t(field.label)} { field.mandatory ? field.mandatory && <span style={{color: 'red'}}>*</span> : ''}</FieldTitle>
                  <ActivityDropdown
                    title={this.state[field.key] && this.state[field.key].name && this.state[field.key].name || t(field.placeholder)}
                    id={`dropdown-${field.name}`}
                  >
                    {
                      this.state.wellnessGoals?.map((item, index) => (
                        <CustomMenuItem
                          eventKey={index}
                          key={index}
                          onClick={() => this.onDropDownSelect(field.key, item)}
                          active={this.state[field.key] && this.state[field.key].id && this.state[field.key].id === item.id}
                        >
                          {t(item.name)}
                        </CustomMenuItem>
                      ))
                    }
                  </ActivityDropdown>
                </TitleContainer>
              )
            }else if(field.type === 'sub_level' && this.state.showWbaQue) {

              return (
                <TitleContainer key={field.name}>
                  <FieldTitle>{t(field.label)} { field.mandatory ? field.mandatory && <span style={{color: 'red'}}>*</span> : ''}</FieldTitle>
                  <ActivityDropdown
                    title={this.state[field.key] && this.state[field.key].question && this.state[field.key].question || t(field.placeholder)}
                    id={`dropdown-${field.name}`}
                  >
                    {
                      this.state.wbaQuestions.map((item, index) => (
                        <CustomMenuItem
                          eventKey={index}
                          key={index}
                          onClick={() => this.onDropDownSelect(field.key, item)}
                          active={this.state[field.key] && this.state[field.key].id && this.state[field.key].id === item.id}
                        >
                          {t(item.question)}
                        </CustomMenuItem>
                      ))
                    }
                  </ActivityDropdown>
                </TitleContainer>
              )
            }else if(field.type === 'level') {

              return (
                <TitleContainer key={field.name}>
                  
                  <FieldTitle>{t(field.label)} { field.mandatory ? field.mandatory && <span style={{color: 'red'}}>*</span> : ''}</FieldTitle>
                  <ActivityDropdown
                    title={this.state[field.key] && this.state[field.key].level && this.state[field.key].level || t(field.placeholder)}
                    id={`dropdown-${field.name}`}
                  >
                    {
                      WellnessLevels.map((item, index) => (
                        <CustomMenuItem
                          eventKey={index}
                          key={index}
                          onClick={() => this.onDropDownSelect(field.key, item)}
                          active={this.state[field.key] && this.state[field.key].id && this.state[field.key].id === item.id}
                        >
                          {t(item.level)}
                        </CustomMenuItem>
                      ))
                    }
                  </ActivityDropdown>
                </TitleContainer>
              )
            }
            else if(field.type != 'sub_level'){return (
              <TitleContainer key={field.name}>
                <FieldTitle>{t(field.label)} { field.mandatory ? field.mandatory && <span style={{color: 'red'}}>*</span> : ''}</FieldTitle>
                <StyledInput
                  type={field.type}
                  name={t(field.name)}
                  placeholder={field.placeholder}
                  onChange={this.onChangeInput}
                  value={this.state[field.name]}
                />
              </TitleContainer>
            )}
          })
        }

        <ImageContainer>
          <FirstPart>
            <FieldTitle>{t('Add a Theme')}:</FieldTitle>
            <RadioButtonContainer justifyContent={"start"}>
              <RadioButton id="11" handler={this.toggleRadioButton} value={1} 
                isChecked={selectedOption === 1} label={t('Yes')} challengeLeaderBoard={true}/>
              <RadioButton id="12" handler={this.toggleRadioButton} value={0} 
                isChecked={selectedOption === 0} label={t('No')} challengeLeaderBoard={true}/>
            </RadioButtonContainer>
          </FirstPart>
        </ImageContainer>
        {selectedOption === 1 ? <TitleContainer addMargin><ActivityTypeContainer width="100%" noChildWidth={1}>
          <FieldTitle>{t('Select a theme')}:</FieldTitle>
          <ActivityDropdown
            title={<div className="title">{themesHeaderAll.length === 0 ? t('No Themes Available') : (themeName === '' && themeMonth === '') ? t('Select a theme') : `${t(themeMonth)}, ${t(themeName)}`}</div>}
            id={"surveyType"}
          >{
              themesHeaderAll && themesHeaderAll.length > 0 && themesHeaderAll.map((list) => (
                <CustomMenuItem key={list.id} active={themeName == list.theme_text} /* onSelect */ onClick={() => this.onSelectTheme(list)}>{t(list.month)}, {t(list.theme_text)}</CustomMenuItem>
              ))
            }
          </ActivityDropdown>
        </ActivityTypeContainer></TitleContainer> : null}

        <ImageContainer>
          {/* <SaveButton type="submit" addMargin disabled={isDisabled}>{t(buttonText)}</SaveButton> */}
          <CommonButton btnType={"rounded"} type={"submit"} addMargin disabled={isDisabled} title={t(buttonText)} styles={{width:'158px', height:'35px', marginBottom:'55px'}}/>
        </ImageContainer>
      </form>
    )
  }
}

Form.propTypes = {
  fields: PropTypes.array.isRequired,
  state: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  mandatoryFields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  journey_category_id: PropTypes.array,
  themes: PropTypes.array,
  themesHeaderAll: PropTypes.array,
  t: PropTypes.func
};

export default (withTranslation()(Form));