import React from "react";
import styled from "styled-components";
import { SuccessIcon } from "../../utils/icons";
import PropTypes from "prop-types";

const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #F1F8F2;
  border: 1px solid #00c853;
  border-radius: 8px;
  padding: 16px;
  color: #184a61;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8f5e9;
  border-radius: 50%;
  padding: 8px;
  margin-right: 12px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  font-style:Lato;
`;

const Message = styled.div`
  font-size: 14px;
  margin-top: 4px;
  color: #4f4f4f;
  font-style:Lato;
`;

function ToastMessage ({title,message})  {

  return (
    <ToastContainer>
      <IconContainer>
        {SuccessIcon()}
      </IconContainer>
      <ContentContainer>
        <Title>{title}</Title>
        <Message>{message}</Message>
      </ContentContainer>
    </ToastContainer>
  );
}

ToastMessage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default ToastMessage;
