import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";
import { Modal } from "react-bootstrap";

export const CustomToggleV1 = styled(Dropdown.Toggle)`
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  background: none !important;
  border: none !important;

  &:hover {
    color: #00abb6;
  }

  &::after {
    display: none !important;
  }
`;

export const CustomMenuV1 = styled(Dropdown.Menu)`
  ${'' /* background-color: red; */}
  border: 1px solid #EFEFEF !important;
  border-radius: 4px !important;
  padding: 0px !important;
  min-width:136px !important;
  width: 136px !important;
  height: 144px !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;

  position: absolute !important; 
  ${'' /* top: 14px !important;  */}
  top: ${({ isTop }) => (isTop ? `${isTop} !important` : "14px !important")};
  left: auto !important; 
  ${'' /* right: 14px !important; */}
  right: ${({ isRight }) => (isRight ? `${isRight} !important` : "14px !important")}; 
  z-index: 1050 !important; 

`;

export const CustomDropdownItemV1 = styled(Dropdown.Item)`
  font-family: "Lato" !important;
  font-size: 16px !important;
  padding: 14px 16px !important;
  border-radius:4px  !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${({ active }) => (active ? "white" : "#184A61")} !important;
  background-color: ${({ active }) =>
    active ? "#00ABB6" : "transparent"} !important;

  &:hover {
    border-radius: 4px !important;
    color: ${({ active }) => (active ? "white" : "white")} !important;
    background-color: ${({ active }) =>
    active ? "#00ABB6" : "#088A96"} !important;
  }

  &:focus {
    outline: none;
    color: white !important;
    background-color: #088a96 !important;
    border: 2px solid #a1fffb !important;
    border-radius: 4px !important;
  }


  >img{
    height:32px !important;
    width:32px !important;
  }
`;

export const CustomDropdownItem = styled(Dropdown.Item)`
  font-family: "Lato";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #184a61;
  padding: 14px 16px;

  background-color: #ffffff;

  &:hover {
    background-color: #00abb6;
    color: #fff;
  }
`;

export const CustomMenu = styled(Dropdown.Menu)`
  background-color: #f0f8ff;
  border: 1px solid #ccc;
  height: 144px;
  width: 136px;
  overflow-y: auto;
`;

export const MyTaskCard = styled.div`
  display: flex;
  margin-top: 20px;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Light-Card, rgba(254, 254, 254, 0.6));
  width: 1248px;
  // max-height: 580px;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1216px;
  height: 60px;
  border-radius: 8px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ isProfile }) => (isProfile ? "8px" : "16px")};
`;

export const Avatar = styled.div`
  width: ${({ isProfile }) => (isProfile ? "48px" : "60px")};
  height: ${({ isProfile }) => (isProfile ? "48px" : "60px")};
  border-radius: 40px;
  border: 1px solid #dcdcdc;
  background: url(<path-to-image>) lightgray 50% / cover no-repeat;
`;

export const Title = styled.h2`
  font-size: 18px;
  font-family: "Lato";
  font-weight: 700;
  margin: 0;
  color: #184a61;
`;

export const Tabs = styled.div`
  display: flex;
  gap: 20px;
`;

export const Tab = styled.div`
  font-size: 16px;
  font-family: "Lato";
  display: inline-block;
  padding: 10px 0px;
  cursor: pointer;
  position: relative;
  font-weight: 400;
  color: #184a61;

  ${({ active }) =>
    active &&
    `
  color: #184A61; 
    &::after {
      content: '';
      position: absolute;
      bottom: 0px; 
      left: 0px;
      width: 100%;
      border-radius:5px;
      height: 3px;
      background-color: #00ABB6; 
    }
  `}
`;



export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  ${'' /* margin:0px 20px; */}
`;


export const CustomToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; 
`;

export const Icon = styled.div`
  font-size: 24px;
  color: var(--Font-Color-on-Light-theme-500, #184a61);
  cursor: pointer;
`;

export const TaskTypeCard = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 459px;
  height: 28px;
`;

export const TaskTypeTab = styled.div`
  font-size: 16px;
  font-weight: 400;
  padding: 4px 2px;
  border-radius: 4px;
  color: var(--Font-Color-on-Light-theme-500, #184A61); 
  cursor: pointer;
  position: relative;
  color: #184A61; 

  ${({ active }) =>
    active &&
    `
    &::after {
      content: '';
      position: absolute;
      bottom: 0px; /* Distance from the text */
      left: 0px;
      width: 100%;
      border-radius:5px;
      height: 3px; /* Thickness of the underline */
      background-color: #00ABB6; /* Color of the underline */
    }
  `}

`;

export const NameInitials = styled.div`
  width: ${({ isWidth }) => (isWidth ? isWidth : "48px")};
  Height: ${({ isHeight }) => (isHeight ? isHeight : "48px")};
  border-radius: ${({ isRadius }) => (isRadius ? isRadius : "48px")};
  ${'' /* width: 48px; */}
  ${'' /* height: 48px; */}
  ${'' /* border-radius: 48px; */}
  background-color: #efefef;
  color: #116d8d;
  display: flex;
  align-items: center;
  justify-content: center;
  ${'' /* font-size: 16px; */}
  font-size: ${({ isSize }) => (isSize ? isSize : "16px")};
  font-weight: 400;
  font-family: "Lato";
  border: 1px solid #dcdcdc;
`;

export const Divider = styled.div`
  height: 1px;
  width: ${({ isWidth }) => (isWidth ? isWidth : "100%")};
  padding: ${({ isPadding }) => (isPadding ? isPadding : "")};
  background-color: var(--Bg-Color-for-Light-theme-400, #efefef);
  margin: ${({ isMargin }) => isMargin || "0px 0px 10px 0px"};
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  width: 1216px;
  height: 28px;
  border-radius: 4px;
  background: var(--Bg-Color-for-Light-theme-500, #f8f8f8);
  ${"" /* padding: 0 16px; */}
  box-sizing: border-box;
`;

export const DataColumn = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Lato";
  color: ${({ isColor }) => (isColor ? isColor : "#184A61")};
  text-align: left;
  align-items: center;
  display: flex;
  text-transform: ${({ isCapital }) => (isCapital ? "capitalize" : "")};

  &:nth-child(1) {
    width: 431px;
    display: flex;
    > div {
      display: flex;
      width: 48px;
      height: 48px;
      margin-right: 8px;
      border-radius: 40px;
      ${"" /* border: 1px solid var(--Bg-Color-for-Light-theme-300, red); */}
      background: url(<path-to-image>) lightgray -3.158px -17.594px / 141.983%
        141.983% no-repeat;
    }
  }
  &:nth-child(2) {
    ${"" /* width: 223px; */}
    width: ${({ isWidth }) => (isWidth ? isWidth : "223px")};
    justify-content: ${({ isWidth }) => (isWidth ? "end" : "")};
  }
  &:nth-child(3) {
    width: 242px;
  }
  &:nth-child(4) {
    flex: 1;
  }
`;

export const Column = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Lato";
  color: #116d8d;
  text-align: left;
  align-items: center;
  display: flex;
  justify-content: ${({ isLast }) => (isLast ? "flex-end" : "")};

  &:nth-child(1) {
    width: 431px;
    display: flex;
    > div {
      display: flex;
      width: 48px;
      height: 48px;
      margin-right: 8px;
      border-radius: 40px;
      border: 1px solid #dcdcdc;
      background: url(<path-to-image>) lightgray -3.158px -17.594px / 141.983%
        141.983% no-repeat;
    }
  }
  &:nth-child(2) {
    width: ${({ isWidth }) => (isWidth ? isWidth : "223px")};
  }
  &:nth-child(3) {
    width: 242px;
  }
  &:nth-child(4) {
    flex: 1;
  }
`;


export const FieldTitle = styled.span`
  font-size: ${({isFont}) => isFont ? '16px' :'14px'};
  margin-bottom : ${({ mb }) => mb? mb:0};
  letter-spacing: 0px;
  line-height: 24px;
  color: ${({color}) => color ? color :'#005c87'};
  font-family: ${({isFont}) => isFont ? isFont :'Rubik-Regular'};
  ${'' /* font-family: "Rubik-Regular"; */}
  float: ${({manager}) => manager ? '' :'left'};
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  padding:0px 24px;
  .day {
    font-size: 18px;
    color: #159fc9;
  }
  .important{
    color:rgb(253, 113, 117);
    font-size: 18px;
  }
`;





export const ReminderDot = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${({ isReminderColor }) =>
    isReminderColor ? isReminderColor : "black"};
  border-radius: 10px;
  margin-right: 8px;
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  ${"" /* width: 1216px; */}
  width: ${({ isWidth }) => (isWidth ? isWidth : "1216px")};
  height: 48px;
  padding: 0 8px 0 0;
  box-sizing: border-box;
  cursor: pointer;
  cursor: ${({ isCursor }) => (isCursor ? isCursor : "none")};
  ${"" /* margin-top:8px */}
`;



export const CenteredText = styled.div`
  color: var(--Font-Color-on-Light-theme-500, #184a61);
  text-align: center;
  font-family: "Lato";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 68px;
  margin-top: 20px;
`;

export const Card = styled.div`
  display: flex;
  margin: auto;
  min-width: 631px;
  ${'' /* padding: 8px 40px; */}
  justify-content: center;
  align-items: center;
  gap: 28px;
  ${'' /* border-radius: 200px;
  border: 0.999px solid var(--Bg-Color-for-Light-theme-400, #efefef);
  background: var(--Light-Card, rgba(254, 254, 254, 0.6)); */}
`;

export const FilterWrapper = styled.div`
  color: #184a61;
  text-align: center;

  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  padding: 8px 40px;
  align-items: center;
  border-radius: 200px;
  border: 0.999px solid var(--Bg-Color-for-Light-theme-400, #efefef);
  background: var(--Light-Card, rgba(254, 254, 254, 0.6));
`;

export const ScrollableContainer = styled.div`
  max-height: 400px;
  max-height: ${({ height }) => (height ? height : "400px")};
  overflow-y: ${({ showAllRows }) => (showAllRows ? "auto" : "hidden")};

  ::-webkit-scrollbar {
    width: 4px;
    height: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 4px;
  }
`;

export const RecognitionCard = styled.div`
  display: flex;
  padding: 16px 16px 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  margin-top: 20px;
  border-radius: 12px;
  background: var(--Light-Card, rgba(254, 254, 254, 0.6));
  width: 614px;
  // height: 500px;
  margin-bottom: 20px;

  .header{
    display: flex;
    justify-content: space-between; 
    align-items: center;
    width: 100%;
  }

  .download-icon{
    cursor: pointer;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${"" /* align-items: flex-start; */}
  ${"" /* gap: 20px; */}
  ${"" /* margin-top:20px; */}
  margin-top: ${({ isMargin }) => (isMargin ? isMargin : "20px")};
  width: 1248px;
  ${"" /* height: ${({isHeight}) => isHeight ? isHeight : '536px'}; */}
`;

export const RecognitionHeading = styled.div`
  color: var(--Font-Color-on-Light-theme-500, #184a61);
  font-family: "Lato";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
`;

export const RecognitionHeader = styled.div`
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 188px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--Bg-Color-for-Light-theme-500, #f8f8f8);
  width: 574px;
  height: 20px;
`;

export const CardHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  color: #184a61;
  align-self: stretch;
  width: 100%;
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  > div {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CardTitle = styled.h1`
  font-size: 24px;
  margin: 0;
  font-family:'Lato';
  font-weight: 700;
  line-height: 36px;
`;

export const VerticalBar = styled.div`
  width: 0.5px;
  height: 40px;
  background: var(--Bg-Color-for-Light-theme-400, #efefef);
`;

export const FilterIconConatainer = styled.div`
  cursor: pointer;
  font-size: 20px;
  color: #184a61;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

//style for TeamMemberCard file
export const TeamCard = styled.div`
  display: flex;
  padding: 16px 16px 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Light-Card, rgba(254, 254, 254, 0.6));
  width: 1248px;
  margin-top: 20px;
  // max-height: 388px;
`;

export const MemberCard = styled.div`
  display: flex;
  padding: 8px 4px 8px 8px;
  align-items: center;
   justify-content: space-between;
  ${'' /* gap: 12px; */}
  ${'' /* flex: 1 0 0; */}
  border-radius: 8px;
  background: ${({ isBackground }) =>
    isBackground ? isBackground : "#FFF3ED"};
  width: 298px;
  height: 64px;
  cursor:pointer;
`;


export const MemberLeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px; /* Space between avatar and name */
`;

export const TeamCardAvatar = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MemberInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MemberName = styled.div`
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--Font-Color-on-Light-theme-500, #184a61);
`;

export const RowContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
`;

//styles for RecognitionModal file
export const ModalContent = styled.div`
  // height: auto;
  // max-height: ${({ isHeight }) => (isHeight ? isHeight : "488px")};
  ${'' /* width: 800px; */}
  width: ${({ isWidth }) => (isWidth ? isWidth : "800px")};
  display: flex;
  flex-direction: ${({ isPost }) => (isPost ? "row" : "column")};
  ${'' /* flex-direction: column; */}
  background-color: #fff;
  border-radius: 12px;
  padding: 16px 0px;
  ${'' /* padding: ${({ isPaddingSocial }) => (isPaddingSocial ? isPaddingSocial : "16px 0px")}; */}
`;



export const ModalContentV1 = styled.div`
  ${'' /* width: 800px; */}
  width: ${({ isWidth }) => (isWidth ? isWidth : "800px")};
  height:600px;
  display: flex;
  flex-direction: ${({ isPost }) => (isPost ? "row" : "column")};
  ${'' /* flex-direction: column; */}
  background-color: #fff;
  border-radius: 12px;
  ${'' /* padding: 16px 0px; */}
  ${'' /* padding: ${({ isPaddingSocial }) => (isPaddingSocial ? isPaddingSocial : "16px 0px")}; */}
`;


export const TextArea = styled.textarea`
  width: 100%;
  height: auto;
  max-height: 500px;
  padding: 10px;
  color: #184a61;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  margin: 20px 0;
  ${"" /* overflow-y: hidden; */}
  resize: none;

  ::-webkit-scrollbar {
    width: 4px;
    height: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 4px;
  }

  &:hover,
  &:active,
  &:focus {
    border: none;
    outline: none;
  }
`;

export const CharacterCount = styled.div`
  color: ${({ isExceeded }) => (isExceeded ? "red" : "#BDBDBD")};
  font-family: 'Lato';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

// export const Button = styled.button`
//   width: 200px;
//   height: 40px;
//   padding: 12px 16px;
//   gap: 8px;
//   border-radius: 4px;
//   opacity: 1;
//   background-color: ${({ isBg }) => (isBg ? "#ffffff" : "#00ABB6")};
//   color: ${({ isBg }) => (isBg ? "#184A61" : "#fff")};
//   font-size: 16px;
//   cursor: pointer;
//   border: ${({ isBg }) => (isBg ? "1px solid #00ABB6" : "none")};
//   margin: auto;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   &:hover {
//     background-color: ${({ isBg }) => (isBg ? "none" : "#007f91")};
//   }
// `;

export const Button = styled.button`
  width: 200px;
  height: 40px;
  padding: 12px 16px;
  gap: 8px;
  border-radius: 4px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  background-color: ${({ isBg, disabled }) =>
    disabled ? "#EFEFEF" : isBg ? "#ffffff" : "#00ABB6"};
  color: ${({ isBg, disabled }) =>
    disabled ? "#989898" : isBg ? "#184A61" : "#fff"};
  font-size: 16px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border: ${({ isBg, disabled }) =>
    disabled ? "none" : isBg ? "1px solid #00ABB6" : "none"};
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ isBg, disabled }) =>
    disabled ? "none" : isBg ? "none" : "#007f91"};
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  padding:0px 24px;

  > div {
    color: var(--Font-Color-on-Light-theme-500, #184a61);
    text-align: center;
    font-family: "Lato";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    ${'' /* padding:"0px 24px"; */}
  }
`;

export const ManagerIconContainer = styled.div`
  width: ${({ isWidth }) => (isWidth ? isWidth : "32px")};
  Height: ${({ isHeight }) => (isHeight ? isHeight : "32px")};
  display:flex;
  justify-content:center;
  align-items:center;
`;

export const Heading = styled.div`
  color: var(--Font-Color-on-Light-theme-500, #184a61);
  font-family: "Lato";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
`;

export const DropdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  color: #184a61;
  font-family: "Lato";
`;

export const DropdownText = styled.div`
  color: ${({ isColor }) => (isColor ? isColor : "485px")};
  font-size: 14px;
  font-weight: normal;
`;

export const NoteText = styled.div`
  color: ${({ hasError }) => (hasError ? "red" : "#BDBDBD")};
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 4px 24px 0px 24px;
`;

export const DropDownStyledImage = styled.div`
  float: left;
  height: 100%;
  display: table;
  width: ${({ width }) => width};
  background: transparent;
  border-radius: 4px;
  height: 50px;
  border: 1px solid #bdbdbd;
  .middle {
    height: 100%;
    display: ${({ left }) => (left ? "inline-block" : "table-cell")};
    vertical-align: middle;
    min-width: 50px;
    min-height: 40px;
    > img {
      // border-radius: 50%;
      width: 40px;
      height: 40px;
      float: left;
      margin: 5px 10px;
      border-radius: 50%;
    }
  }
`;

export const TemplateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0 8px 0;
  padding:0px 24px;
`;

export const TemplateOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const TemplateImage = styled.div`
  width: 224px;
  height: 224px;
  border-radius: 4px;
  margin-bottom: 8px;
`;

export const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const RadioButton = styled.input`
  cursor: pointer;
  width: 20px;
  height: 20px;
  appearance: none;
  border: 1px solid #7c7c7c;
  border-radius: 50%;
  position: relative;
  outline: none;

  &:checked {
    background-color: white;
    border-color: #00abb6;
  }

  &:checked::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: #00abb6;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Label = styled.label`
  color: var(--Font-Color-on-Light-theme-600, #092f41);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
`;

export const StyledModal = styled(Modal)`
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 100% !important;
    }
  }
  .modal-dialog {
    width: ${({ width }) => (width ? width : "485px")};
    border-radius: 8px;
    // background: linear-gradient(180deg, #FFFFFF 0%,rgba(255,255,255,0) 100%), #FFFFFF;
    // box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    transform: translate(0, 50vh) translate(0, -50%);
    margin: 80px auto;
    border: none;
    @media (min-width: 576px) {
      .modal-dialog {
        max-width: 100% !important;
      }
    }
    .modal-content {
      height: 100%;
      width: 100%;
      border: none;
      background-color: ${({ bgcolor }) =>
    bgcolor || !bgcolor ? "transparent" : "#fff"};
    }
    .close {
      margin-top: -30px;
    }
    ${
  "" /* @media (max-height: 1200px) {
      width: 55%;
    } */}
    @media (max-height: 530px) {
      transform: none !important;
      margin: 30px auto;
    }
    @media (max-width: 766px) {
      width: 95%;
    }
  }
`;

export const StyledInput = styled.input`
  border: none;
  padding: ${({ isPadding }) => (isPadding ? isPadding : " 0px 12px 0px 12px")};
  ${"" /* padding: 0px 20px 0px 20px; */}
  box-shadow: none;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  height: 48px;
  font-family: rubik-regular;
  font-weight: 500;
  width: 100%;
  border-radius: 6px;
  color: ${({ color, disabled }) =>
    color && !disabled
      ? color
      : disabled
        ? "rgba(0, 92, 135, 0.6)"
        : "#005C87"};
  ${
  "" /* border:${({ border }) => border ? border : 'rgba(0, 122, 177, 0.30)'}; */
}
  background-color:${({ bgColor }) => (bgColor ? bgColor : "white")};
  cursor: ${({ disabled }) => disabled && "not-allowed !important"};
  border: 1px solid rgba(0, 122, 177, 0.3);

  &:active {
    ${
  "" /* border:${({ border }) => border ? border : '1px solid rgba(0, 92, 135)'}; */
}
  }
  &::placeholder {
    font-size: 16px;
    color: rgba(0, 92, 135, 0.3);
    color: ${({ pColor }) => (pColor ? pColor : "rgba(0, 92, 135, 0.3)")};
    font-family: rubik;
    font-weight: 400;
    vertical-align: middle;
    line-height: 20px;
  }

  &:focus,
  &:active {
    outline:${({ bordercolor }) =>
    bordercolor ? bordercolor : "1px solid #005C87"};
    border: none !important;
  }
`;

//new
export const LeftContent = styled.div`
  width: 100%;
  etter-spacing: 0px;
  line-height: 24px;
  color: rgb(51, 51, 51);
  font-family: "Rubik-Regular";

  > span.title {
    font-size: 18px;
    color: #005c87;
  }
  > div {
    text-align: left;
    margin-top: 0;
    color: rgb(153, 153, 153);
    text-transform: capitalize;
    > textarea {
      padding: 16px 24px;
      ${'' /* margin: 16px; */}
      resize: none;
      width: 100%;
      font-size: 16px;
      font-family: "Lato";
      border: none;
      overflow-y: auto; /* Add scrolling for content overflow */
      max-height: 320px; /* Set maximum height for the textarea */
      height: auto;
      // height: 65px;
      color: #184a61;
      ${"" /* overflow-y:hidden; */}
      ::placeholder {
        color: #BDBDBD !important;
      }

      :-ms-input-placeholder {
        color: #005c874d !important;
      }

      ::-ms-input-placeholder {
        color: #005c874d !important;
      }

      &:focus-visible {
        border: none;
        outline: none;
      }

      ::-webkit-scrollbar {
        width: 4px;
        height: 100px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #bdbdbd;
        ${"" /* border-radius: 4px; */}
      }

      ::-webkit-scrollbar-track {
        background-color: #ffffff;
        ${"" /* border-radius: 4px; */}
      }
    }
  }
`;
export const ShowMore = styled.div`
  height: ${({ height }) => (height ? height : "48px")};
  width: 100%;
  display: flex;
  color: #184a61;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 12px;
  align-items: center;
  cursor: pointer;
`;


export const WrapImage = styled.div`
  ${'' /* width: 100%; */}
  min-width:800px;
  margin-top: ${({ noMargin }) => noMargin ? '0' : '20px'};
  float: left;
  // padding: 0px 25px 0;          
  cursor: pointer;
  display:flex !important;
  justify-content:center;
  ${'' /* padding:30px 0px; */}
  padding: ${({ isPadding }) => isPadding ? '30px 0px;' : ''};
  min-height:600px;
  div{
    display:flex;
    justify-content:center;
  > img {
    width: 100%;
    max-width: 100%;
    border-radius: 3px;
    float: left;
    ${'' /* object-fit: cover; */}
    ${'' /* max-height:600px; */}
    
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  }
  > img {
    width: auto;
    max-width: 100%;
    border-radius: 3px;
    float: left;
    
    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;
