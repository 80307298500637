import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

// import LazyImage from "../../common/LazyImage/LazyImage";
// import { ImageUrl } from "../../../utils/constants";
import { connect } from "react-redux";
import {
  StyledModal,
  StyledTitle,
  StyledBody,
  //   CircleNew,
  ManageAnniversariesContainer,
  ToggleButton,
} from "./styles";
import { RecognitionAnniversaryIcon, RecognitionBirthdayIcon, RecognitionCloseIcon } from "../../../utils/icons";
import { toast } from "react-toastify";
import { fetchApi } from "../../../utils/methods";
import { PutBirthdayAndAnniversaryCardToggle } from "../../../redux/constants/apiConstants";
// import CircularProgressBar from "../../HealthTabV2/HealthTabUpdated/circularProgressBar";

class ManageAnniversaryPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      isBirthday: 0,
      isAnniversary: 0,
    };
  }

  handleToggleBtn = (e) => {
    window.console.log("e.target.value",e.target.checked)
    e.persist();
    this.setState({
      [e.target.name]: e.target.checked,
    })

  }


  saveChanges = async(e) => {
    // Prevents React from resetting its properties:
    e.persist();

    const { companyId, manageAnniversary } = this.state;
    
    const payload = {company_id:companyId,show_birthday_card: e.target.name ==="isBirthday" ? 1 : manageAnniversary.isBirthday,show_work_anniversary_card: e.target.name ==="isAnniversary" ? 1 : manageAnniversary.isAnniversary};

    try{
      const res = await fetchApi(PutBirthdayAndAnniversaryCardToggle, "PUT",payload);
      if(res.status === 'success'){
        this.setState({
          manageAnniversary: {
            ...this.state.manageAnniversary,
            [e.target.name]: 1,
          },
        })
      }else{
        toast.error(res.data.message);
      }
    
    }catch(error){
      toast.error(error.message);
    }
    
  };

  showDetailInitiative = () => {
    const { isBirthday, isAnniversary } = this.state;
    return (
      <React.Fragment>
        <div className="first-text"> Would you like to manage anniversaries by turning them on or off for your company?</div>
        <div className="second-text">Note: By making changes here, all admins will receive a notification about this update.</div>
        <div className="toggle-container">
          <div className="icon">
            <span>{RecognitionAnniversaryIcon()}</span>
            <span className="name">Work</span>
          </div>
          <div className="toggle">
            <ToggleButton>
              <label className="switch">
                <input
                  type="checkbox"
                  name="isAnniversary"
                  checked={isAnniversary}
                  onChange={this.handleToggleBtn}
                />
                <span className="slider round"></span>
              </label>
            </ToggleButton>
          </div>
        </div>
        <div className="toggle-container">
          <div className="icon">
            <span>{RecognitionBirthdayIcon()}</span>
            <span className="name">Birthdays</span>
          </div>
          <div className="toggle">
            <ToggleButton>
              <label className="switch">
                <input
                  type="checkbox"
                  name="isBirthday"
                  checked={isBirthday}
                  onChange={this.handleToggleBtn}
                />
                <span className="slider round"></span>
              </label>
            </ToggleButton>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { showModal, onClose /*detailInitiative*/ } = this.props;
    return (
      <StyledModal
        transform={1}
        show={showModal}
        newWidth={"400px"}
        newBg={"newBg"}
        // height="50px"
        display="none"
      >
        <Modal.Header
          closeButton
          onHide={() => onClose()}
          style={{ background: "white" }}
        >
          <StyledTitle>Manage Anniversaries</StyledTitle>
          <div
            onClick={() => onClose()}
            style={{cursor:"pointer"}}
          >
            {RecognitionCloseIcon()}
          </div>
        </Modal.Header>
        <StyledBody>
          <ManageAnniversariesContainer>
            {this.showDetailInitiative()}
          </ManageAnniversariesContainer>
        </StyledBody>
      </StyledModal>
    );
  }
}

ManageAnniversaryPopup.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  detailInitiative: PropTypes.object,
  companyId: PropTypes.number,
};

const mapStateToProps = (state) => ({
  userId: state.profileData.userId,
});

export default connect(mapStateToProps, null)(ManageAnniversaryPopup);
