import React from 'react';
import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import { NameInitials } from '../../Manager/styles';
import { getInitials } from '../../../utils/methods';

const Container = styled.div`
  width: 100% !important;
  margin-top:${({ isMargin }) => isMargin ? '' : '30px'};
  ${'' /* margin-top: 30px; */}
  height: 100%;
  .highcharts-crosshair-category {
    display: none !important;
    visibility: hidden !important;
  }

  .highcharts-container {
    width: 100% !important;
    ${'' /* width:${({ isGlucose }) => isGlucose ? '80%' : '100%'}!important; */}
    > svg {
      width: 100%;
    
      g{
     
        &:hover {
          fill:none
      }
      .hover {
        fill:none
    }
        text{
          text-transform: capitalize;
          font-size: 14px;
          font-family: 'Rubik';
          width:8px;
        }
        rect{
       
          width:${({width}) => width ? width : '50px'};
          background-color: rgba(254,254,254,0.60);
          fill:${({barColor}) => barColor && barColor};
          transform:"none",
        &:hover {
            fill:none
        }
        .hover {
          fill:none
      }
        }




      }
    }

    .highcharts-xaxis-labels{
      
      .initials{
        width: ${({ isWidth }) => (isWidth ? isWidth : "48px")};
  Height: ${({ isHeight }) => (isHeight ? isHeight : "48px")};
  border-radius: ${({ isRadius }) => (isRadius ? isRadius : "48px")};
  ${'' /* width: 48px; */}
  ${'' /* height: 48px; */}
  ${'' /* border-radius: 48px; */}
  background-color: #efefef;
  color: #116d8d;
  display: flex;
  align-items: center;
  justify-content: center;
  ${'' /* font-size: 16px; */}
  font-size: ${({ isSize }) => (isSize ? isSize : "16px")};
  font-weight: 400;
  font-family: "Lato";
  border: 1px solid #dcdcdc;

      }

    }
  }
`;

class VerticalBarChart extends React.Component {

  componentDidMount() {
    this.renderGraph(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.renderGraph(this.props);
    }
  }

  renderGraph = (props) => {
    const {
      seriesData,
      xAxisLabel,
      yAxisTitle,
      xAxisTitle,
      title,
      fillColor,
      id,
      xAxisVisible,
      yAxisVisible,
      fontWeight,
      wellBeing,
      wellnessGoals,
      circleColor,
      circleBorderColor,
      widthType
    } = props;
    const maxLimit = 100;
    const GraphData = [];
    const data = [...seriesData];
    
    data.map((series, index) => {
      series.stack = index;
      series.dataLabels = {
        color: wellBeing ? '#fff' : series.color,
        style: {
          textShadow: false,
          textOutline: false,
        },
      };
      GraphData.push({
        stack: index,
        data: [],
        showInLegend: false,
        dataLabels: { enabled: false },
        states: {
          hover: {
            enabled: false,
          },
        },
      });
      GraphData.map((fillSeries) => {
        if (fillSeries.stack === index) {
          series.data.map((value) => {
            fillSeries.data.push(maxLimit - value);
          });
          fillSeries.color = fillColor ? 'transparent' : 'rgba(12, 153, 193, 0.2)';
        }
      });
    });

    Highcharts.chart(id, {
      chart: {
        type: 'column',
        colorByPoint: true,
        className: 'top-circle',
        backgroundColor: 'transparent',
        events: {
          load: function () {
            const chart = this; // Access the chart instance here
            
            // Ensure renderer is available
            if (!chart.renderer) {
              window.console.log("Renderer is undefined. Please ensure Highcharts is imported correctly.");
              return;
            }

            // Draw circles at the top of each bar
            chart.series[0].data.forEach(function (point, index) {
              const circleX = point.plotX + chart.plotLeft;
              const outerHTML = point.series.chart.seriesGroup.element.outerHTML;

              // Create a temporary DOM element to parse the string
              const tempDiv = document.createElement('div');
              tempDiv.innerHTML = outerHTML;
              
              // Query all rect elements with the class "highcharts-point"
              const rects = tempDiv.querySelectorAll('.highcharts-point');
              
              // Extract the y values
              const yValues = Array.from(rects).map((rect) => parseFloat(rect.getAttribute('y')));
              
              // Log the extracted y values
              // window.console.log('Extracted y values:', yValues);

              // const plotY = chart.yAxis[0].toPixels(point.tooltipPos[0]); // Convert Y value to pixel position
              // window.console.log("plotY", plotY);

              let xPosition = widthType === "full" ? 28 :28 ;
              let yPosition = widthType === "full" ? 5 :5 ;

              // Add a circle at the top of each bar
              chart.renderer
                .circle(circleX-xPosition, yValues[index]+yPosition, 6) // Adjust `-10` to control circle position
                .attr({
                  fill: circleColor || '#1B8041', // Circle color
                  stroke: circleBorderColor || '#62C381', // Border color
                  'stroke-width': 2, // Border thickness
                  zIndex: 5,
                })
                .add();
            });
          },
        },
      },
      title: {
        text: title || null
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: xAxisLabel,
        crosshair: wellnessGoals ? false : true,
        className: 'xAxis',
        lineWidth: 0,
        distance:1,
        minorGridLineWidth: 0,
        lineColor: 'red',
        minorTickLength: 0,
        tickLength: 0,
        visible: xAxisVisible,
        title: {
          text: xAxisTitle || null
        },
        labels: {
          x: widthType === "full" ? -26 : -26,
          useHTML: true, // Enables HTML rendering for the labels
          formatter: function () {
            window.console.log("this.value",this.value);
            return this.value.imagePath.includes("profile-Image/default.png") ? `<span class="initials">
              ${getInitials(this.value.fullName)}
            </span>` : `<img src="${this.value.imagePath}" alt="${this.value.imagePath}" style="width:48px;height:48px;border-radius:48px;" />`                     

            // return `<img src="${this.value}" alt="${this.value}" style="width:48px;height:48px;border-radius:48px;" />`;
          },
          style: {
            color: 'rgba(0, 92, 135, 0.60)',
            fontFamily: 'Rubik',
            fontSize: '14px',
            fontWeight: fontWeight ? 400 : 400,
            textTranform: 'capitalize'
          }
        }
      },
      yAxis: {
        // categories:yAxisVisible,
        min: 0,
        max: 100,
        showEmpty: false,
        tickInterval: 25,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        gridLineColor: 'rgba(156, 156, 156, 0.2)',
        gridLineDashStyle: 'Dash', // Add dashed lines
        visible: yAxisVisible,
        labels: {
          formatter: function () {
            return this.value
          },
          style: {
            color: '#184A61',
            fontFamily: 'Rubik',
            fontSize: '14px',
            fontWeight: fontWeight ? 400 : 400,
            textTranform: 'capitalize'
          }
        },
        title: {
          text: yAxisTitle || null
        },
        states: {
          inactive: {
            opacity: 1,
            fill: 'transparent'
          }
        }
      },
      tooltip: {
        style: {
          fontFamily: wellnessGoals ? 'Rubik-Medium' : 'Rubik',
          fontSize: wellnessGoals ? '15px' : '11px',
          fontWeight: 500,
        },
        formatter: function () {
          return `${this.y}%`;
        }
      },
      legends: {
        enabled: false
      },
      plotOptions: {
        groupPadding: 0.1,
        shadow: false,
        column: {
          pointPadding: 0,
          borderWidth: 0,
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              opacity: 1,
            }
          },
          dataLabels: {
            enabled: false,
            crop: false,
            overflow: 'none',
            verticalAlign: 'bottom',
            top: '10%',
            inside: false,
            y: -1,
            formatter: function () {
              return this.y > 9 ? this.y + '%' : ''
            },
            style: {
              fontFamily: wellnessGoals ? 'Rubik-Medium' : 'Rubik',
              fontSize: wellnessGoals ? '15px' : '11px',
              fontWeight: wellnessGoals ? 700 : 500,
            },
            pointPadding: 0,
          },
          animation: false
        },
        series: {
          stacking: 'normal'
        }
      },
      labelStyle: {
        fontSize: "8em",
      },
      series: [
        ...GraphData,
        ...data
      ],

    });
  };


  render() {
    const { id, wellnessGoals, width, isMargin, barColor} = this.props;
    return (
      <Container isMargin={isMargin} id={id} wellnessGoals={wellnessGoals} width={width || "50px"} barColor={barColor}/>
    )
  }
}

VerticalBarChart.defaultProps = {
  xAxisVisible: true,
  yAxisVisible: true,
  id: "chart"
}

VerticalBarChart.propTypes = {
  xAxisTitle: PropTypes.string,
  yAxisTitle: PropTypes.string,
  xAxisLabel: PropTypes.array,
  yAxisLabel: PropTypes.array,
  seriesData: PropTypes.array,
  yAxisVisible: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
  xAxisVisible: PropTypes.bool,
  wellBeing: PropTypes.number,
  wellnessGoals: PropTypes.number,
  width: PropTypes.string,
  isMargin: PropTypes.bool,
  circleColor: PropTypes.string,
  circleBorderColor: PropTypes.string,
  widthType:PropTypes.string,
  barColor: PropTypes.string
};

export default VerticalBarChart;