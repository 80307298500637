import PropTypes from 'prop-types';
import React from 'react'
import { useEffect } from 'react'
import { BarChartContainer, Border } from '../style'
import VerticalBarChart from './VerticalBarChart';
import { RecognitionUpArrow } from '../../../../utils/icons';

const MonthlyBarChart = ({heading="Top Performing Managers | Overall",total=323,totalText="Total Recognitions",widthType="half",data=    [
  {
    "full_name": "Ajay Sharma",
    "total_recognitions": 19,
    image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Arpit Malviya",
    "total_recognitions": 14,
    image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Eshwar Deshmukh",
    "total_recognitions": 59,
    image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Dhairya Vora",
    "total_recognitions": 41,
    image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Dhairya Vora",
    "total_recognitions": 81,
    image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Dhairya Vora",
    "total_recognitions": 91,
    image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Dhairya Vora",
    "total_recognitions": 31,
    image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Dhairya Vora",
    "total_recognitions": 21,
    image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Dhairya Vora",
    "total_recognitions": 41,
    image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Ajay Sharma",
    "total_recognitions": 61,
    image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Ajay Sharma",
    "total_recognitions": 71,
    image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png"
  },
  {
    "full_name": "Ajay Sharma",
    "total_recognitions": 81,
    image:"https://ddf0gygzuiev4.cloudfront.net/profile-Image/Avatars/Avatar-5.png"
  },
],id}) => {

  useEffect(()=>{
    window.console.log("data",data);
    window.console.log("data",heading);
    window.console.log("data",total);
    window.console.log("data",totalText);
  },[]);



  const graphRender = (data, id) => {

    const topActivities = data.map((item, answerIndex) => ({
      y: Math.round(item.total_recognitions),
      color:  getColorByIndex(answerIndex), // You can define getColorByIndex function
      image: item.image
    }));
    const xAxisLabels = ["Jan", "Feb","Mar","April","May", "Jun","Jul","Aug","Sep", "Oct","Nov","Dec"];
    const yAxisLabels = ['25%', '50%', '75%', '100%'];
    const dataValue = [{
      name: '',
      data: topActivities,
      showInLegend: false,
      color: '#DE9444'
    }];
    const splineData = {
      name: '',
      type: 'line',
      color: '#DF8500',
      lineWidth: 2,
      showInLegend: false,
      // yAxis: 1, // Use the second yAxis (the one with offset)
      zones: [
        { value: 20, color: '#CE98B3' },
        { value: 40, color: '#CE98B3' },
        { value: 60, color: '#CE98B3' },
        { value: 80, color: '#CE98B3' },
        { value: 100, color: '#CE98B3' }
      ],
      data: data.map((item) => item.total_recognitions),
      tooltip: {
        pointFormat:
          '<span style="font-weight: bold; color: {series.color}">' +
          '{series.name}</span>: <b>{point.y} recognitions</b>'
      }
    };
    return (
      <div className="bargraph">
        <VerticalBarChart
          id={`${id} ${123}`}
          seriesData={dataValue}
          xAxisLabel={xAxisLabels}
          yAxisVisible={yAxisLabels}
          fillColor={1}
          cultureSurvey={1}
          width={"30px"}
          widthType={widthType}
          splineData={splineData}
        />
      </div>)
  }

  function getColorByIndex(index) {
    // Define an array of colors or generate colors dynamically
    const colors = ["#CE98B3","#CE98B3","#CE98B3","#CE98B3","#CE98B3","#CE98B3","#CE98B3","#CE98B3","#CE98B3","#CE98B3","#CE98B3","#A65C7C"];
  
    // Return the color based on the index, repeating if necessary
    return colors[index % colors.length];
  }
  return (
    <>
      <BarChartContainer>
        <div className='heading'>{heading}</div>
        <Border/>

        <div className='total-row'>
          <span className='total'>{total}</span>
          <span className='total-text'>{totalText}</span>
        </div>
        <div className='compare-row'>
          <div className='stat'><span className='icon'>{RecognitionUpArrow()}</span>{total} % </div>
          <div className='text'>{" vs last month"}</div>
        </div>
        <div>{graphRender(data, id || total)}</div>
          
      </BarChartContainer>
    </>
  )
}

MonthlyBarChart.propTypes = {
  heading: PropTypes.string,
  total: PropTypes.number,
  totalText: PropTypes.string,
  data: PropTypes.array,
  widthType:PropTypes.string,
  id:PropTypes.any
}

export default MonthlyBarChart;
