import React, {  useEffect, useState } from 'react';
import {  DateRangePickerContainer, HeaderContainer, StyledBody, StyledModal } from './style';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { fetchApi } from '../../../utils/methods';
// import { Border } from '../../common/Manager/style';
import { closeIcon } from '../../../utils/icons';
// import dayjs from 'dayjs';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import CustomDateRangePicker from '../../common/DateRangePicker/DateRangePicker';
import { GetFrequentRecognitionGiverDetails, GetLeadingSupporterDetails, GetMostPraisedMembersDetails, GetTopAcknowledgmentsDetails } from '../../../redux/constants/apiConstants';
import CSVList from '../../AttendeesModal/CSVList';
import { toast } from 'react-toastify';
import ToastMessage from '../ToastMessage';
import { ManagerIconContainer } from '../styles';


const ReportPopup = ({showModal,headerText,onClose,selectedCard}) => {

  // const[Recognitions,setRecognitions] = useState([]);
  const[isPrint, setIsPrint]=useState(false);
  const[csvHeaders,setCsvHeaders]=useState([]);
  const[csvData,setCsvData]=useState([]);

  const DownloadReport = (startDate, endDate) => {  
    if(selectedCard.type === 1 && selectedCard.subtype === 1){
      fetchPraisedMembers(startDate, endDate);
    }else if(selectedCard.type === 1 && selectedCard.subtype === 2){
      fetchRecognitionGivers(startDate, endDate);
    }else if(selectedCard.type === 2 && selectedCard.subtype === 1){
      fetchLeadingSupporter(startDate, endDate);
    }else if(selectedCard.type === 2 && selectedCard.subtype === 2){
      fetchAcknowledgmentGiver(startDate, endDate);
    }
  }

  const fetchPraisedMembers = async(startDate, endDate) => {
    // fetch top performers
    const res = await fetchApi(GetMostPraisedMembersDetails + "/" + startDate + "/" + endDate,"GET");
    if(res.status === "success"){
      // setRecognitions(res.data);
      toast(
        <ToastMessage
          title="Successfully Downloaded"
          message="Your report has been downloaded successfully"
        />,
        {
          position: "top-right",
          autoClose: 3000, 
          closeButton: false, 
          hideProgressBar: true,
          pauseOnHover: true,
          draggable: true,
          zIndex:900000,
          style: {
            right: "165px", 
            top: "46px", 
            marginRight:'20px',
            backgroundColor: "transparent", 
            boxShadow: "none", 
            width:'510px',
          },
        },
      );
      printAction(true,res.data);
      // onClose()
    }
  } 

  const fetchRecognitionGivers = async(startDate, endDate) => {
    // fetch top performers
    const res = await fetchApi(GetFrequentRecognitionGiverDetails + "/"  + startDate + "/" + endDate,"GET");
    if(res.status === "success"){
      // setRecognitions(res.data);
      toast(
        <ToastMessage
          title="Successfully Downloaded"
          message="Your report has been downloaded successfully"
        />,
        {
          position: "top-right",
          autoClose: 3000, 
          closeButton: false, 
          hideProgressBar: true,
          pauseOnHover: true,
          draggable: true,
          zIndex:900000,
          style: {
            right: "165px", 
            top: "46px", 
            marginRight:'20px',
            backgroundColor: "transparent", 
            boxShadow: "none", 
            width:'510px',
          },
        },
      );
      printAction(true,res.data);
      // onClose();
    }
  } 

  const fetchLeadingSupporter = async(startDate, endDate) => {
    // fetch top performers
    const res = await fetchApi(GetLeadingSupporterDetails + "/" + startDate + "/" + endDate,"GET");
    if(res.status === "success"){
      toast(
        <ToastMessage
          title="Successfully Downloaded"
          message="Your report has been downloaded successfully"
        />,
        {
          position: "top-right",
          autoClose: 3000, 
          closeButton: false, 
          hideProgressBar: true,
          pauseOnHover: true,
          draggable: true,
          zIndex:900000,
          style: {
            right: "165px", 
            top: "46px", 
            marginRight:'20px',
            backgroundColor: "transparent", 
            boxShadow: "none", 
            width:'510px',
          },
        },
      );
      // setRecognitions(res.data);
      printAction(true,res.data);
      // onClose();
    }
  } 

  const fetchAcknowledgmentGiver = async(startDate, endDate) => {
    // fetch top performers
    const res = await fetchApi(GetTopAcknowledgmentsDetails + "/" + startDate + "/" + endDate,"GET");
    if(res.status === "success"){
      toast(
        <ToastMessage
          title="Successfully Downloaded"
          message="Your report has been downloaded successfully"
        />,
        {
          position: "top-right",
          autoClose: 3000, 
          closeButton: false, 
          hideProgressBar: true,
          pauseOnHover: true,
          draggable: true,
          zIndex:900000,
          style: {
            right: "165px", 
            top: "46px", 
            marginRight:'20px',
            backgroundColor: "transparent", 
            boxShadow: "none", 
            width:'510px',
          },
        },
      );
      // setRecognitions(res.data);
      printAction(true,res.data);
      // onClose();
     
    }
  } 

  const printAction = (bool,data) => {
    setCsvHeaders([{label:"Full Name", key:"full_name"},{label:"Department", key:"department"},{label:"Location", key:"location"},{label:"Total Recognitions", key:"total_recognitions"}])

    if(bool){
      setCsvData(data);
    }else{
      setCsvHeaders([])
      setCsvData([])
    }
    // onClose();
    
    setIsPrint(bool);
    
    
  };

  useEffect(()=>{},[csvData,csvHeaders,isPrint])

  return (
    <>
      <StyledModal
        show={showModal}
        onHide={onClose}
        dialogClassName={"manager-popup"}
        backdropClassName="drop-back"
        // style={{ top: "-20%" }}
        isWidth
        isHeight
      >
        <Modal.Header style={{background:"#F8F8F8"}}>
          <HeaderContainer>
            <div className='report-title'>
              {/* <span className='header-text'>{headerText}</span> */}
              <span className='header-text'>Download Report</span>
            </div>
            <ManagerIconContainer style={{cursor:"pointer"}} onClick={onClose}>{closeIcon("#00ABB6",14,14)}</ManagerIconContainer>
            
          </HeaderContainer>
        </Modal.Header>
        <div style={{ background:"#F8F8F8"}}>
          {/* <Border style={{margin:"20px 0px"}}/> */}
        </div>
        <StyledBody style={{background:"#F8F8F8"}}>
          <DateRangePickerContainer>
            <CustomDateRangePicker DownloadReport={DownloadReport}/>
          </DateRangePickerContainer>

        </StyledBody>
      </StyledModal>

      {isPrint && csvHeaders.length > 0 && csvData.length > 0 &&   <CSVList Title={headerText} removeCSVData={printAction} headers={csvHeaders} data={csvData} />}
    </>
  )
}

ReportPopup.propTypes = {
  showModal: PropTypes.bool.isRequired,
  headerText: PropTypes.string,
  onClose: PropTypes.func,
  timeInterval: PropTypes.string,
  member: PropTypes.object,
  selectedCard: PropTypes.object,

}

export default ReportPopup