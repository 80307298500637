import React from 'react';
import PropTypes from "prop-types";
import { Img } from "react-image";
import { NotificationWrapper, TitleContainer, UserImage, WellnessIntereset, Content, Container, LocationContainer, StyledModal } from './styles';
import { getUser, getWellnessInitiativeAction } from '../../redux/actions';
import { connect } from 'react-redux';
import { ImageUrl } from '../../utils/constants';
// import WellnessInitiatives from './WellnessInitiatives';
// import { ProfileImg } from '../TopBarV3/style';
import { isNull, isUndefined } from 'lodash';
import { withTranslation } from 'react-i18next';
import { fetchApi } from '../../utils/methods';
import { toast } from 'react-toastify';
import { RevokeSlackConnection } from '../../redux/constants/apiConstants';
import { SET_USER_INTEGRATIONS } from '../../redux/actions/actionTypes';

const ProfileArray = [
  {
    "name": "My Profile",
    "image": "/images/NewDashboardV2/myProfile.png",
    "description": "",
    "redirectTo": '/profile'
  },
  {
    "name": "My Rewards",
    "image": "/images/NewDashboardV2/mybadge.png",
    "description": "",
    "redirectTo": '/rewards'
  }
];

const ProfileArrayNew = [
  {
    "name": "Help Center",
    "image": "/public/images/NewDashboardV2/Help_center.png",
    "description": "",
    "redirectTo": 'https://woliba-kb.groovehq.com/help',
    "id":1
  },
  // {
  //   "name": "Add to slack",
  //   "image": "/public/images/NewDashboardV2/slack.png",
  //   "description": "",
  //   "redirectTo": 'https://wolibafit.slack.com/oauth?client_id=330672440560.7023297822388&scope=channels%[…]_scope=1&single_channel=0&install_redirect=&tracked=1&team=',
  //   "id":2
  // }
];

const checkBase64 = (image) => {
  let arr = image.split('/');
  if (arr[0] == 'profile-Image') {
    return `${ImageUrl}/${image}`
  }
  else {
    return image;
  }
};

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfile: false,
      layover: false
    }
    this.myref = React.createRef();
  }
  handleClick = (event) => {

    if (!isNull(this.myref) && !isUndefined(this.myref) && !isNull(this.myref.current) && !isUndefined(this.myref.current) && !(this.myref && this.myref.current && this.myref.current.contains(event.target))) {
      this.setState({ showProfile: false });
    }
  };

  toggleProfileSidebar = () => {
    this.setState((prev) => ({
      showProfile: !prev.showProfile
    }));
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
    const { getUser } = this.props;
    getUser();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }
  async handleRevokeSlackIntegration(){
    try {
      const res = await fetchApi(RevokeSlackConnection , "POST");
      if (res.status === "success") {
        toast.success("Slack integration revoked successfully")
        this.props.updateUserIntegrations(res?.data?.integrations)

      } else {
        toast.error(res?.error);
      }
    } catch (error) {
      window.console.log(error);
    }
    
  }
  render() {
    // const { showProfile } = this.state;
    const { interest, user, /*userWellnessInitiatives,*/ history, logout, userDetails, profileImage, /*getSideBarColor,*/ showProfileImage, closeModal,  t,userIntegrations} = this.props;
    return (
      <StyledModal show={showProfileImage} onHide={() => closeModal()}>
        <div ref={this.myref}>
          {/* <ProfileImg color={getSideBarColor} onClick={this.toggleProfileSidebar}>
            <Img
              alt={userDetails.fname}
              src={checkBase64(profileImage || userDetails.profile_image)}
              unloader={
                <Img
                  src='/public/images/neutral_avatar.svg'
                  alt='avatar'
                />
              }
            />
          </ProfileImg> */}
          {showProfileImage ?

            <NotificationWrapper id={'notificationPopup'}>
              <TitleContainer>
                {t("User Profile")}
              </TitleContainer>
              <div className="wrapper-class">
                <div className="scroll-hide">
                  <UserImage>
                    <div>
                      <Img
                        alt={user.fname}
                        src={checkBase64((profileImage && profileImage) || (userDetails && userDetails.profile_image) || user.profile_image)}
                        unloader={
                          <Img
                            src='/public/images/neutral_avatar.svg'
                            alt='avatar'
                          />
                        }
                      />
                    </div>
                    <div>
                      <div>{user ? `${user.fname} ${user.lname}` : null}</div>
                      {user && !isNull(user.department) ?
                        <div>{t(user.department)}</div>
                        : null}
                      {user && user.user_city ?
                        <LocationContainer>
                          <div>
                            <img src="/public/images/NewDashboardV2/locationOnProfile.png" />
                          </div>
                          <div>
                            {t(user.user_city)}
                          </div>
                        </LocationContainer>
                        : null}
                      <button onClick={() => logout()}>{t("Sign Out")}</button>
                    </div>
                  </UserImage>
                  <WellnessIntereset>
                    <div>
                      <p>
                        {t("Wellness Interest")}
                      </p>
                      <span>
                        <a onClick={() =>{history.push('/settings');closeModal()}}>
                          {t("Edit")}
                        </a>
                      </span>
                    </div>
                    <div>
                      {interest && interest.length > 0 ? interest.slice(0, 6).map((data, index) => (
                        <div key={index}>
                          <img src={`${ImageUrl}/${data.interest_icon}`} />
                        </div>
                      )) : null}
                    </div>
                  </WellnessIntereset>
                  <Container>
                    {ProfileArray.map((data, index) => (
                      <>
                        {(index===0 && this.props.companyBranding?.company_branding?.profile_page) || (index===1 && this.props.companyBranding?.company_branding?.rewards)? <Content key={index} onClick={() => { history.push(data.redirectTo); closeModal()} }>
                          <div>
                            <img src={ImageUrl + data.image} />
                          </div>
                          <div>
                            <div>{t(data.name)}</div>
                            <div>{t(data.description)}</div>
                          </div>
                        </Content> : null}
                      </>
                    ))}
                    {ProfileArrayNew.map((data, index) => (
                      <a href={data.id === 2 ? data.redirectTo  : data.redirectTo} rel="noopener noreferrer" target ="_blank" key={index} style={{"textDecoration":"none",marginBottom:"10px"}} >
                        <Content key={index}  style={{marginBottom:"10px"}}>
                          <div>
                            <img src={data.image} />
                          </div>
                          <div>
                            <div>{t(data.name)}</div>
                            <div>{t(data.description)}</div>
                          </div>
                        </Content>
                      </a>

                    ))}

                    {user.company_id === 1 || user.company_id === 82 ? <Content  style={{marginBottom:"10px"}}>
                      <div>
                        <img src={"/public/images/NewDashboardV2/slack.png"} />
                      </div>
                      <div>
                        {userIntegrations?.slack_connected ? (
                          <>
                            <span
                              className='add-slack'
                              onClick={() => this.handleRevokeSlackIntegration()}
                            >
                              {t("Revoke Slack")}
                            </span>
                          </>
                        ) : (
                          <a
                            href={userIntegrations?.slack_link}
                            rel="noopener noreferrer"
                            target="_blank"
                            className='add-slack'
                          >
                            <div>{t("Add to Slack")}</div>
                          </a>
                        )}
                      </div>
                    </Content>:null}
                  </Container>
                  {/* <WellnessInitiatives userWellnessInitiatives={userWellnessInitiatives} />  */}
                </div>
              </div>
            </NotificationWrapper>

            : null
          }
        </div>
      </StyledModal>
    );
  }
}

UserProfile.propTypes = {
  innerRef: PropTypes.object,
  getUser: PropTypes.func,
  user: PropTypes.object,
  interest: PropTypes.object,
  userWellnessInitiatives: PropTypes.array,
  history: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  userDetails: PropTypes.object.isRequired,
  profileImage: PropTypes.string,
  getSideBarColor: PropTypes.string,
  showProfileImage: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  t: PropTypes.func,
  companyBranding:PropTypes.object,
  userIntegrations:PropTypes.object,
  updateUserIntegrations:PropTypes.func
}
const mapStateToProps = (state) => ({
  user: state.profileData.user,
  interest: state.profileData.interest,
  userIntegrations: state.profileData.userIntegrations,
  userWellnessInitiatives: state.profileData.userWellnessInitiatives,
  companyBranding: state.wellnessDashboard.companyBrandingDetails,
})

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(getUser()),
  getWellnessInitiativeAction: () => dispatch(getWellnessInitiativeAction()),
  updateUserIntegrations: (integrations) =>dispatch({ type: SET_USER_INTEGRATIONS, userIntegrations: integrations})
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserProfile));
